import { Dialog } from '@mui/material'
import { useModal } from '@contexts/Modal'

function ModalComponent() {
  const { component, currentOptions, closeModal } = useModal()

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!component) return <></>

  return (
    <Dialog
      open
      scroll={currentOptions?.scroll || 'paper'}
      onClose={currentOptions?.lockclose ? undefined : closeModal}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: { xs: 'calc(100% - 32px)', md: 1200 },
            margin: { xs: '16px', md: '32px' },
          },
        },
      }}
    >
      {component}
    </Dialog>
  )
}

export default ModalComponent
