import { Stack, Typography } from '@mui/material'

type PasswordField = {
  password: string
}

type PasswordRequirementsProps = {
  password: PasswordField['password']
  showLabel?: boolean
}

function PasswordRequirements({
  password,
  showLabel = true,
}: PasswordRequirementsProps) {
  const requirements = [
    {
      label: 'Com pelo menos 8 caracteres',
      isValid: password?.length >= 8,
    },
    {
      label: 'Com pelo menos 1 número',
      isValid: /\d/.test(password),
    },
    {
      label: 'Com maiúsculas e minúsculas',
      isValid: /[a-z]/.test(password) && /[A-Z]/.test(password),
    },
    {
      label: 'Com caracteres especiais',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
  ]

  return (
    <Stack>
      {showLabel && (
        <Typography
          variant="body2"
          color="textSecondary"
          gutterBottom
          sx={{ lineHeight: 1.2, padding: '4px 0' }}
        >
          Criar uma senha:
        </Typography>
      )}
      {requirements.map((requirement) => (
        <Typography
          key={requirement.label}
          variant="body2"
          color={requirement.isValid ? 'success.main' : 'error.main'}
        >
          {requirement.isValid ? '✓' : '×'} {requirement.label}
        </Typography>
      ))}
    </Stack>
  )
}

export default PasswordRequirements
