import { theme } from '@theme/index'
import { useAuth } from '@contexts/Auth'
import { useMemo, useState } from 'react'
import { Close } from '@mui/icons-material'
import DarkWarningSVG from '@assets/icons/dark-warning.svg'
import { StripePortalLink } from '@containers/StripePortalLink'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { BannerLabel } from './styles'

export function AppBannerPastdue() {
  const { user } = useAuth()
  const localStorageKey = `@NOMOS:hidePastdueBanner`

  const [hideBanner, setHideBanner] = useState<boolean>(
    localStorage.getItem(localStorageKey) === 'true'
  )

  const handleCloseBanner = () => {
    localStorage.setItem(localStorageKey, 'true')
    setHideBanner(true)
  }

  const hidden = useMemo(() => {
    return hideBanner || user?.company?.subscription?.status !== 'past_due'
  }, [hideBanner, user?.company?.subscription?.status])

  //   return null if banner shuld not be displayed
  if (hidden) return null

  return (
    <Box bgcolor="$color-semantic-warning" id="app-banner-pastdue">
      <Stack
        width="88%"
        margin="0 auto"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="55px"
        spacing={3}
      >
        <Stack spacing={2} alignItems="center" direction="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={DarkWarningSVG} alt="Countdown" width={24} height={24} />
            <BannerLabel variant="$font-body-4" color="$color-text-primary">
              Ação necessária: Pagamento pendente
            </BannerLabel>
          </Stack>
          <Divider
            flexItem
            orientation="vertical"
            color={theme.palette['$color-border-dark']}
          />
          <Typography variant="$font-body-2" color="$color-text-primary">
            Regularize sua situação! Acesse a área de assinatura ou entre em
            contato conosco.
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          {!!user?.admin && (
            <StripePortalLink
              title="Atualizar Pagamento"
              text="Atualizar Pagamento"
              size="medium"
            />
          )}
          <IconButton size="medium" onClick={() => handleCloseBanner()}>
            <Close
              fontSize="small"
              sx={{ color: theme.palette['$color-text-primary'] }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}
