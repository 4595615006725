import { styled } from '@mui/material/styles'

import {
  Timeline,
  timelineClasses,
  timelineItemClasses,
  timelineDotClasses,
  timelineConnectorClasses,
} from '@mui/lab'
import { theme } from '@theme'

export const TimelineTramitation = styled(Timeline)(() => ({
  marginBottom: 0,
  [`& .${timelineClasses.root}`]: {
    marginBottom: 0,
  },
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  [`& .${timelineDotClasses.root}`]: {
    width: '6px',
    height: '6px',
    padding: 0,
  },
  [`& .${timelineConnectorClasses.root}`]: {
    width: '1px',
    backgroundColor: theme.palette.grey[300],
  },
}))
