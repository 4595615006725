import { Container, Content } from './styles'
import AppBar from '@containers/AppBar/AppBar'
import { Nomos } from '@components/icons'
import { BaseLayoutSelector } from '@layouts/base'
import React from 'react'
import { createContext } from 'use-context-selector'
import { Outlet } from 'react-router-dom'
import { useAuth } from '@contexts/Auth'
import { AppBarBlank } from '@components/molecules/AppBarBlank'
import FormRegistrationStep2 from '@pages/private/FormRegistrationStep2'

const pages = [
  { name: 'Inicio', path: 'home', options: undefined },
  { name: 'Monitoramento', path: 'monitors', options: [] },
  { name: 'Pesquisas Salvas', path: 'keywords', options: [] },
  { name: 'Minha Agenda', path: 'events', options: [] },
  { name: 'Stakeholders', path: 'stakeholders', options: [] },
  { name: 'Órgãos', path: 'organs', options: [] },
]

export type MonitorLayoutContextProps = {
  paddingTop: number
}

export const MonitorLayoutContext = createContext(
  {} as MonitorLayoutContextProps
)

export default function MonitorLayout() {
  const { user } = useAuth()

  if (user && !user.complete) {
    return <FormRegistrationStep2 />
  }

  const { auth } = useAuth()
  const [paddingTop, setPaddingTop] = React.useState<number>(0)
  const state = React.useMemo(() => ({ paddingTop }), [paddingTop])

  return (
    <BaseLayoutSelector>
      <MonitorLayoutContext.Provider value={state}>
        <Container
          sx={{
            paddingTop: `${paddingTop}px`,
            height: `calc(100% - ${paddingTop}px)`,
          }}
        >
          {auth ? (
            <AppBar
              module="monitor"
              pages={pages}
              logo={<Nomos />}
              onChangeHeight={setPaddingTop}
            />
          ) : (
            <AppBarBlank showWhatsAppContact />
          )}

          <Content>
            {/* Workarround to not send form submission to Hubspot */}
            <span id="CollectedForms-39613680" />
            <Outlet />
          </Content>
        </Container>
      </MonitorLayoutContext.Provider>
    </BaseLayoutSelector>
  )
}
