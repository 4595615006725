import { SVGProps } from 'react'

function ExpandIcon({
  width = 20,
  height = 20,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16675 4.16699H5.83341C4.91294 4.16699 4.16675 4.91318 4.16675 5.83366V14.167C4.16675 15.0875 4.91294 15.8337 5.83341 15.8337H14.1667C15.0872 15.8337 15.8334 15.0875 15.8334 14.167V10.2087M15.8334 4.16699H12.5001M15.8334 4.16699V7.50033M15.8334 4.16699L7.50008 12.5003"
        stroke="#4070F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExpandIcon
