import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { OrganEntity } from '@services/nomos_api/entities/organ'

export const getOrganAPI = async (id?: string) => {
  if (!id) return {} as OrganEntity
  const { data } = await HTTPClient.get(`organs/${id}`)
  return data as OrganEntity
}

export const useGetOrgan = (id?: string) =>
  useQuery(['get-organ', id], () => getOrganAPI(id), {
    refetchOnWindowFocus: false,
  })
