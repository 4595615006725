import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { CardSummarized } from '@components/organisms/Cards'
import { useStakeholderLegislativeProposals } from '@services/nomos_api/resources/stakeholders/legislativeProposals'

export default function LegislativeProposals() {
  const id = useContextSelector(StakeholderDetailContext, (s) => s.id)

  const { data, isFetching } = useStakeholderLegislativeProposals(id || '')

  return (
    <CardSummarized
      title="Propostas legislativas"
      isLoading={isFetching}
      values={[
        {
          count: data?.asAuthor || 0,
          label: 'Autorais',
        },
        {
          count: data?.asRapporteur || 0,
          label: 'Relatadas',
        },
      ]}
    />
  )
}
