import { Alert } from '@mui/material'
import { Info } from '@phosphor-icons/react'
import colors from '@theme/colors'
import { useContextSelector } from 'use-context-selector'
import { UsersContext } from '../../context'

export function SeatAlerts(): JSX.Element | null {
  // get seats counter from controller
  const seats = useContextSelector(UsersContext, (s) => s.seats)

  // not display alert if seats was not loaded
  if (!seats) return null

  // not display alert if company is over trial
  if (seats.isTrial) return null

  // not display alert if seats is enabled (everything ok)
  if (seats.seats.enabled) return null

  // display alert if does not have available seats
  return (
    <Alert
      color="error"
      variant="standard"
      sx={{ color: colors['$color-text-primary'] }}
      icon={<Info color={colors['$color-text-primary']} />}
    >
      Você atingiu o limite de usuários disponíveis para a sua organização. Para
      incluir novos usuários, contrate novos assentos
    </Alert>
  )
}
