import styled from 'styled-components'

export const Dashboard = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding-top: 32px;
  @media (min-width: 768px) {
    gap: 32px;
    flex-direction: row;
  }
`
