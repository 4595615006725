import AllMessages from '../../../../AllMessages'
import NewMessages from '../NewMessages'
import { useMemo, useState } from 'react'
import { TabSelector } from '@components/organisms'
import { useAlerts } from '@hooks/useAlerts'

export default function Selector() {
  const [tab, setTab] = useState('all')
  const { countTotal } = useAlerts(['count_total'])

  const tabs = useMemo(() => {
    return [
      {
        value: 'all',
        label: 'Geral',
      },
      {
        value: 'new',
        label: 'Novas Notificações',
        alerts: countTotal,
      },
    ]
  }, [countTotal])

  return (
    <>
      <TabSelector tabs={tabs} initialTab={tab} onChangeTab={setTab} />
      {tab === 'all' && <AllMessages />}
      {tab === 'new' && <NewMessages />}
    </>
  )
}
