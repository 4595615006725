import { Typography, Stack } from '@mui/material'
import { CardInfo, Info, Status } from './Resume.styles'
import { formatDate } from '@utils/functions/formatter/formatter'
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'
import { PropositionVotingEntity } from '@services/nomos_api/entities/proposition'

export type ResumeProps = {
  votings: PropositionVotingEntity
}

export default function Resume({ votings }: ResumeProps) {
  return (
    <CardInfo>
      <Info sx={{ order: { xs: 2, md: 'unset' } }}>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Proposição votada
        </Typography>
        <Typography variant="$font-title-5" color="$color-text-primary">
          {votings?.proposition?.title || 'Desconhecido'}
        </Typography>
      </Info>

      <Stack
        direction="row"
        gap={{ xs: 4, md: 8 }}
        sx={{
          order: { xs: 4, md: 'unset' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        <Info>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Votos a Favor
          </Typography>
          <Typography variant="$font-title-5" color="$color-semantic-success">
            {votings?.positioning?.positive || '-'}
          </Typography>
        </Info>

        <Info>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Votos Contra
          </Typography>
          <Typography variant="$font-title-5" color="$color-semantic-error">
            {votings?.positioning?.negative || '-'}
          </Typography>
        </Info>

        <Info>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Total
          </Typography>
          <Typography variant="$font-title-5" color="$color-text-primary">
            {votings?.positioning?.total || '-'}
          </Typography>
        </Info>
      </Stack>

      <Info sx={{ order: { xs: 3, md: 'unset' } }}>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Data
        </Typography>
        <Typography variant="$font-title-5" color="$color-text-primary">
          {formatDate(votings?.date)}
        </Typography>
      </Info>

      <Info
        sx={{
          order: { xs: 1, md: 'unset' },
          width: { xs: '100%', md: 'auto' },
        }}
      >
        {votings?.approved ? (
          <Typography variant="$font-title-3" color="$color-semantic-success">
            <Status>
              <CheckCircleOutline />
              Aprovado
            </Status>
          </Typography>
        ) : (
          <Typography variant="$font-title-3" color="$color-semantic-error">
            <Status>
              <HighlightOff />
              Recusado
            </Status>
          </Typography>
        )}
      </Info>
    </CardInfo>
  )
}
