import { TabContext } from '@mui/lab'
import { useContextSelector } from 'use-context-selector'
import { TabSelectorTab, TabSelectorTabs } from './styles'
import { EditSavedSearchContext } from '../../context'

export function TabSelector() {
  const handleUpdateStep = useContextSelector(
    EditSavedSearchContext,
    (s) => s.handleUpdateStep
  )
  const step = useContextSelector(EditSavedSearchContext, (s) => s.step)

  return (
    <TabContext value={step}>
      <TabSelectorTabs onChange={(_, key) => handleUpdateStep(key)}>
        <TabSelectorTab value="settings" label="Geral" disableRipple />
        <TabSelectorTab value="notifications" label="Alerta" disableRipple />
      </TabSelectorTabs>
    </TabContext>
  )
}
