import _ from 'lodash'
import BucketCvm from './cvm'
import BucketIrs from './irs'
import BucketDou from './dou'
import BucketBacen from './bacen'
import BucketSocial from './social'
import BucketEvents from './events'
import BucketNotices from './notices'
import BucketSpeeches from './speeches'
import { Bucket, BucketsEnum } from './type'
import BucketPropositions from './propositions'
import BucketOficialDiary from './oficial_diary'
import BucketRegulatoryAgencies from './regulatory_agencies'
import { Filter } from '@services/nomos_api/entities/saved_searches'

import BucketOrgans from './organs'
import BucketMonitors from './monitors'
import BucketStakeholders from './stakeholders'

export {
  BucketIrs,
  BucketCvm,
  BucketDou,
  BucketBacen,
  BucketEvents,
  BucketSocial,
  BucketSpeeches,
  BucketNotices,
  BucketPropositions,
  BucketOficialDiary,
  BucketRegulatoryAgencies,
}

export const buckets: Record<BucketsEnum, Bucket> = {
  irs: BucketIrs,
  dou: BucketDou,
  cvm: BucketCvm,
  bacen: BucketBacen,
  events: BucketEvents,
  social: BucketSocial,
  organs: BucketOrgans,
  notices: BucketNotices,
  speeches: BucketSpeeches,
  monitors: BucketMonitors,
  propositions: BucketPropositions,
  stakeholders: BucketStakeholders,
  oficial_diary: BucketOficialDiary,
  regulatory_agencies: BucketRegulatoryAgencies,
}

export function getBucketName(bucket: string, def = 'N/D'): string {
  return (_.get(buckets, bucket) as Bucket)?.name || def
}

export function createBlankBucketFilter(filter: Filter) {
  return {
    page: 1,
    limit: 10,
    sort: undefined,
    filter: filter || {},
  }
}

export const bucketkeys = Object.keys(buckets) as BucketsEnum[]

export const monitorableBuckets: BucketsEnum[] = [
  BucketsEnum.propositions,
  BucketsEnum.events,
  BucketsEnum.stakeholders,
  BucketsEnum.organs,
]

export const favoritableBuckets: BucketsEnum[] = [
  BucketsEnum.bacen,
  BucketsEnum.cvm,
  BucketsEnum.dou,
  BucketsEnum.irs,
  BucketsEnum.notices,
  BucketsEnum.oficial_diary,
  BucketsEnum.regulatory_agencies,
  BucketsEnum.social,
  BucketsEnum.speeches,
]

export const searcheableBuckets: BucketsEnum[] = [
  BucketsEnum.propositions,
  BucketsEnum.events,
  BucketsEnum.dou,
  BucketsEnum.oficial_diary,
  BucketsEnum.social,
  BucketsEnum.notices,
  BucketsEnum.speeches,
  BucketsEnum.bacen,
  BucketsEnum.cvm,
  BucketsEnum.irs,
  BucketsEnum.regulatory_agencies,
]
