import styled from 'styled-components'
import theme from '@theme/colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: ${theme['$color-background-primary']};
`

export const LeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`
