import { Container, Content } from './styles'
import AppBar from '@containers/AppBar/AppBar'
import { OrganoGov } from '@components/icons'
import { BaseLayoutSelector } from '@layouts/base'
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function OrganoGovLayout() {
  const [top, setTop] = React.useState<number>(0)
  return (
    <BaseLayoutSelector>
      <Container>
        <AppBar
          module="organogov"
          logo={<OrganoGov width={157} height={33} />}
          onChangeHeight={setTop}
        />
        <Content sx={{ marginTop: `${top}px` }}>
          <Outlet />
        </Content>
      </Container>
    </BaseLayoutSelector>
  )
}
