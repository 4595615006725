import { CardRadioGroup, CardRadioWrapper } from './Radio.styles'
import { Box, Radio, RadioGroup, Stack, Typography } from '@mui/material'

type CardRadioOptionType = {
  title: string | JSX.Element
  value: string
  disabled?: boolean
  description: string | JSX.Element
  legendElement?: JSX.Element | null
}

type CardRadioProps = {
  name: string
  value: string
  disabled?: boolean
  options: CardRadioOptionType[]
  direction?: 'column' | 'row'
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void | undefined
}

export default function CardRadio({
  name,
  value,
  options,
  onChange,
  disabled = false,
  direction = 'column',
}: CardRadioProps) {
  return (
    <RadioGroup
      name={name}
      value={value}
      onChange={onChange}
      sx={{
        gap: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: direction,
      }}
    >
      {options.map((option) => (
        <CardRadioGroup
          spacing={1}
          direction="column"
          key={`radio-option-${option.value}`}
        >
          <CardRadioWrapper
            flexGrow={1}
            direction="row"
            alignItems="center"
            disabled={disabled || option.disabled ? 'true' : 'false'}
          >
            <label htmlFor={`target-${option.value}`}>
              <Radio
                name={name}
                disabled={disabled || option.disabled}
                value={option.value}
                data-testid="radio-name"
                id={`target-${option.value}`}
              />
              <Box ml={2}>
                <Stack direction="column">
                  <Typography
                    variant="$font-body-2"
                    color="$color-text-primary"
                  >
                    {option.title}
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    {option.description}
                  </Typography>
                </Stack>
              </Box>
            </label>
          </CardRadioWrapper>
          {option.legendElement}
        </CardRadioGroup>
      ))}
    </RadioGroup>
  )
}
