import { Stack, Typography } from '@mui/material'
import { TextLink } from '@components/atoms'
import { SigninBoxEmail } from './CreateAccountPrompt.styles'

export function CreateAccountPrompt(): JSX.Element {
  return (
    <Stack direction="column" alignItems="center" sx={{ mt: 2 }}>
      <SigninBoxEmail>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="$font-body-1" color="$color-text-secondary">
            Ainda não possui uma conta?
          </Typography>
          <TextLink href="/signup" text="Criar conta" />
        </Stack>
      </SigninBoxEmail>
    </Stack>
  )
}
