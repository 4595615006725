import { uniqueId } from 'lodash'
import { Typography } from '@mui/material'
import { Shimmer } from '@components/atoms'
import { EmptyState } from '@components/molecules'
import { useNavigate, useParams } from 'react-router-dom'
import { EventEntity } from '@services/nomos_api/entities/event'
import MiniEventCard from '@components/organisms/Cards/MiniEvent/MiniEvent'
import { useCloseEvents } from '@services/nomos_api/resources/search/events'

import { Container, EventsContainer } from './styles'

export default function CloseEvents() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, isFetching } = useCloseEvents(id)

  return (
    <Container>
      <Typography variant="$font-title-4" color="$color-text-primary">
        Eventos nesta comissão
      </Typography>

      <Shimmer height={250} width="100%" isLoading={isFetching}>
        <EventsContainer>
          {data?.results?.map((event: EventEntity) => (
            <MiniEventCard
              key={uniqueId()}
              event={event}
              onClick={() => navigate(`/events/${event.id}`)}
            />
          ))}

          {!isFetching && data?.results?.length === 0 && (
            <EmptyState
              data-cy="empty-state-events"
              text="Sem eventos próximos"
            />
          )}
        </EventsContainer>
      </Shimmer>
    </Container>
  )
}
