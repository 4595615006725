import { Typography } from '@mui/material'
import { calculateRemainingTime } from '@utils/functions/formatter/formatter'
import { useEffect, useMemo, useRef, useState } from 'react'

type TextTimeOutProps = {
  expireTime?: number
  countDownLabelTemplate: string
  timedOutLabelTemplate: string
  onTimedOut?: () => void
}

export function TextTimeOut({
  expireTime,
  timedOutLabelTemplate,
  countDownLabelTemplate,
  onTimedOut,
}: TextTimeOutProps): JSX.Element | null {
  if (!expireTime) return null

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime())

  useEffect(() => {
    timeoutRef.current = setInterval(() => {
      setCurrentTime(new Date().getTime())
    }, 1000)
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
        timeoutRef.current = undefined
      }
    }
  }, [])

  const remainTime = useMemo(() => {
    return expireTime * 1000 - currentTime
  }, [currentTime])

  const remainLabel = useMemo(() => {
    return calculateRemainingTime(currentTime, expireTime * 1000)
  }, [currentTime])

  useEffect(() => {
    if (timeoutRef.current && remainTime <= 0) {
      onTimedOut?.()
      clearInterval(timeoutRef.current)
      timeoutRef.current = undefined
    }
  }, [remainTime])

  return (
    <Typography
      textAlign="left"
      variant="$font-body-3"
      color="$color-text-tertiary"
    >
      {remainTime > 0
        ? countDownLabelTemplate.replace('{{time}}', remainLabel)
        : timedOutLabelTemplate}
    </Typography>
  )
}
