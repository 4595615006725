import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StakeholderDetailContext } from './context'
import { useGetStakeholder } from '@services/nomos_api/resources/stakeholders/getById'

export type StakeholderDetailProps = {
  children: React.ReactNode
}

export default function StakeholderDetailController({
  children,
}: StakeholderDetailProps) {
  const { id } = useParams()
  const [tab, setTab] = useState('activities')
  const { data, isFetching: isLoading } = useGetStakeholder(id || '')

  const store = useMemo(
    () => ({
      id,
      tab,
      isLoading,
      stakeholder: data,
      handleChangeTab: setTab,
    }),
    [id, tab, isLoading, data, setTab]
  )

  return (
    <StakeholderDetailContext.Provider value={store}>
      {children}
    </StakeholderDetailContext.Provider>
  )
}
