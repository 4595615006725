import { Stack } from '@mui/material'
import { Container } from './styles'
import { UsersContext } from '../../context'
import { useDebounce } from '@hooks/useDebounce'
import { useEffect, useRef, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import {
  FilterSelectDropdown,
  DateRangePicker,
  Input,
  Button,
} from '@components/atoms'
import {
  NomosProductCodeEnum,
  NomosProductRoleName,
} from '@enums/NomosProductEnum'

function FilterByName() {
  const firstRender = useRef(true)
  const [name, setName] = useState('')
  const debouncedName = useDebounce(name, 500)

  const handleFilterChange = useContextSelector(
    UsersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({ q: debouncedName })
  }, [debouncedName])

  return (
    <Input
      size="tiny"
      type="search"
      id="users-search-name"
      label="Nome"
      placeholder="Buscar por nome"
      value={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
      }}
    />
  )
}

function FilterByRole() {
  const firstRender = useRef(true)
  const [roles, setRoles] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    UsersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    handleFilterChange({
      admin: roles.length === 1 ? String(roles.includes('true')) : '',
    })
  }, [roles])

  return (
    <FilterSelectDropdown
      values={roles}
      options={[
        {
          label: 'Administrador',
          value: 'true',
        },
        {
          label: 'Membro',
          value: 'false',
        },
      ]}
      onChangeOptions={setRoles}
      label="Perfil"
      data-cy="profile"
      size="tiny"
    />
  )
}

function FilterByProfile() {
  const firstRender = useRef(true)
  const [roles, setRoles] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    UsersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    handleFilterChange({
      role: roles ? roles.join(',') : undefined,
    })
  }, [roles])

  return (
    <FilterSelectDropdown
      values={roles}
      options={[
        {
          label: NomosProductRoleName[NomosProductCodeEnum.MONITOR_PRO],
          value: NomosProductCodeEnum.MONITOR_PRO,
        },
        {
          label: NomosProductRoleName[NomosProductCodeEnum.MONITOR_LIGHT],
          value: NomosProductCodeEnum.MONITOR_LIGHT,
        },
      ]}
      onChangeOptions={setRoles}
      label="Permissão"
      data-cy="role"
      size="tiny"
    />
  )
}

function FilterByCreationDate() {
  const firstRender = useRef(true)
  const [rangeDate, setRangeDate] = useState<{
    startDate?: string
    endDate?: string
  }>()

  const handleFilterChange = useContextSelector(
    UsersContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({
      period_from: rangeDate?.startDate,
      period_to: rangeDate?.endDate,
    })
  }, [rangeDate])

  return (
    <DateRangePicker
      size="tiny"
      startDate={rangeDate?.startDate}
      endDate={rangeDate?.endDate}
      label="Data de criação"
      onChange={(startDate, endDate) => {
        setRangeDate({ startDate, endDate })
      }}
    />
  )
}

export default function Filters() {
  const handleClickNewUser = useContextSelector(
    UsersContext,
    (s) => s.handleClickNewUser
  )

  return (
    <Container>
      <Stack
        gap={2}
        sx={{ flex: 1 }}
        position="relative"
        direction={['column', 'column', 'row']}
      >
        <FilterByName />
        <FilterByRole />
        <FilterByProfile />
        <FilterByCreationDate />
      </Stack>
      <Button text="Novo Usuário" onClick={handleClickNewUser} />
    </Container>
  )
}
