import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Pagination } from '../../entities/pagination'
import { Member } from '@services/nomos_api/entities/user'

export type GetAllUsersResult = {
  pagination: Pagination
  results: Member[]
}

export type GetAllUsersFilters = {
  q?: string
  role?: string
  admin?: string
  period_from?: string
  period_to?: string
}

export type GetAllUsersProps = {
  page: number
  limit: number
  filters?: GetAllUsersFilters
}

export const getAllUsers = async ({
  page,
  limit,
  filters,
}: GetAllUsersProps): Promise<GetAllUsersResult> => {
  const params = Object.entries(filters || {}).reduce(
    (accumulator, [key, value]) => {
      const stringValue = value?.toString()
      return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
    },
    ''
  )

  const { data } = await HTTPClient.get(
    `users?page=${page}&limit=${limit}&${params}`
  )
  return (data || []) as GetAllUsersResult
}

export const useGetAllUsers = (props: GetAllUsersProps) =>
  useQuery(['get-all-users', props], () => getAllUsers(props), {
    refetchOnWindowFocus: false,
  })
