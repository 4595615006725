import { MyAccountView } from './view'
import { MyAccountController } from './controller'

export default function Users() {
  return (
    <MyAccountController>
      <MyAccountView />
    </MyAccountController>
  )
}
