import _ from 'lodash'
import axios from 'axios'

const HTTPClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const typeOfImage = (entry) => entry instanceof File

const isMultipart = (data) =>
    Object.values(data).some((entry) => typeOfImage(entry))

const handleMultipart = (config) => {
  if (!config.data) return config
  if (!isMultipart(config.data)) return config
  _.set(config, 'headers.Content-Type', 'multipart/form-data')
  const formData = new FormData()
  const payload = {}
  for (const field in config.data) {
    if (typeOfImage(config.data[field]))
      formData.append(field, config.data[field], config.data[field].name)
    else
      _.set(payload, field, config.data[field])
  }
  formData.append('data', JSON.stringify(payload))
  config.data = formData
  return config
}

HTTPClient.interceptors.request.use((config) => {
  const updateConfig = handleMultipart(config)
  // resolve authorization token injection
  const jwt = localStorage.getItem('@NOMOS:token')
  if (jwt && jwt !== 'undefined') {
    updateConfig.headers.Authorization = `Bearer ${jwt}`
  }
  return updateConfig
})

HTTPClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
     if(error.response.status === 401){
      const jwt = localStorage.getItem('@NOMOS:token')
      if (jwt && jwt !== 'undefined'){
        localStorage.clear()
        window.location.reload()
      }
    }
    return Promise.reject(error)
})

export default HTTPClient
