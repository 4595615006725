
import { uid } from 'uid'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Close } from '@mui/icons-material'
import { useCallback, useContext } from 'react'
import { EventsCalendarContext } from '../context'

import {
  Popover,
  ModalContent,
  ModalCalendarDateTopDatesDisplay,
  ModalHeader,
  ModalMain,
  ModalMainItem,
} from '../styles'
import { formatTime } from '@utils/functions/formatter/formatter'

function ViewMore(props) {
  const { onClose, data, anchorEl } = props

  const { handleOpenModalDetailsEvent } = useContext(EventsCalendarContext)

  const handleOpenEvent = useCallback((event) => {
    handleOpenModalDetailsEvent(event)
  }, [])

  const date = (data && data[0]?.start) || new Date()

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 'left',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div>
            <Close onClick={onClose} />
          </div>

          <ModalCalendarDateTopDatesDisplay>
            <span>
              {format(date, 'EEE', {
                locale: ptBR,
              }).slice(0, 3)}
            </span>

            <span>{format(date, 'dd')}</span>
          </ModalCalendarDateTopDatesDisplay>
        </ModalHeader>

        <ModalMain>
          {data.map((item) => (
            <ModalMainItem
              onClick={() => handleOpenEvent(item.event)}
              key={uid()}
            >
              <span>
                {formatTime(item.start)} {item.title}
              </span>
            </ModalMainItem>
          ))}
        </ModalMain>
      </ModalContent>
    </Popover>
  )
}

export { ViewMore }
