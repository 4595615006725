import { useMemo } from 'react'
import TextLink from '../TextLink/TextLink'
import { isMobile } from 'react-device-detect'
import { RenderTags } from './components/render-tags'
import { RenderInput } from './components/render-input'
import { InputAutocomplete } from './InputAdvancedSearch.styles'
import { removeQuotes } from '@utils/functions/normalizers/remove_quotes'
import { parseKeywordsToInput } from '@utils/functions/keyword/serialize'
import { Box, FormControl, FormHelperText, Stack, SxProps } from '@mui/material'

import {
  AdvancedSearchType,
  KeywordConditionsType,
} from '@services/nomos_api/entities/advanced_search'

export type MultiSelectAdvancedOption = {
  value: string
  kind: 'and' | 'or' | 'not'
}

type InputAdvancedSearchActionType = {
  label: string
  onClick: () => void
}

type InputAdvancedSearchProps = {
  label?: string
  placeholder?: string
  value?: KeywordConditionsType
  helper?: string
  size?: 'medium' | 'small'
  disabled?: boolean
  disableTooltip?: boolean
  action?: InputAdvancedSearchActionType
  sx?: SxProps
  onChangeSearch?: (search: AdvancedSearchType) => void
}

function InputAdvancedSearch({
  placeholder,
  label,
  value = { or: [], and: [], not: [] },
  helper,
  action,
  size = 'medium',
  disabled = false,
  disableTooltip,
  sx,
  onChangeSearch,
}: InputAdvancedSearchProps): JSX.Element {
  const handleUpdateKeyword = (keywords: MultiSelectAdvancedOption[]) => {
    if (!onChangeSearch) return
    const search: AdvancedSearchType = keywords.reduce(
      (advancedSearch: AdvancedSearchType, item: MultiSelectAdvancedOption) => {
        advancedSearch.keywords[item.kind].push(removeQuotes(item.value))
        return advancedSearch
      },
      { keywords: { or: [], and: [], not: [] } }
    )
    onChangeSearch(search)
  }

  const parsedValue = useMemo(() => {
    return parseKeywordsToInput(value)
  }, [value])

  const disableInput = useMemo(
    () =>
      disabled ||
      parsedValue.some((keyword) => ['and', 'not'].includes(keyword.kind)),
    [disabled, parsedValue]
  )

  return (
    <Stack direction="column" flex={1} sx={sx}>
      <FormControl fullWidth variant="outlined">
        <InputAutocomplete
          disabled={disableInput}
          multiple
          id="global-search"
          value={parsedValue}
          size={size}
          options={[] as MultiSelectAdvancedOption[]}
          autoComplete={false}
          ListboxComponent={Box}
          freeSolo
          selectOnFocus
          clearOnBlur
          sx={{ minWidth: 273 }}
          onChange={(_, values) => {
            const options = values as Array<MultiSelectAdvancedOption | string>
            const keywords = (options || [])?.reduce(
              (
                keywords: MultiSelectAdvancedOption[],
                value: MultiSelectAdvancedOption | string
              ) => {
                if (typeof value === 'string') {
                  String(value)
                    .split(';')
                    .filter((value) => value.length > 0)
                    .forEach((value) =>
                      keywords.push({ value: value.trim(), kind: 'or' })
                    )
                } else {
                  keywords.push(value as unknown as MultiSelectAdvancedOption)
                }
                return keywords
              },
              []
            )
            handleUpdateKeyword(keywords)
          }}
          renderInput={(params) => (
            <RenderInput
              params={params}
              label={label}
              disabled={disableInput}
              disableTooltip={disableTooltip}
              placeholder={disableInput ? undefined : placeholder}
            />
          )}
          renderTags={(values, getTagProps) => (
            <RenderTags
              getTagProps={getTagProps}
              values={values as MultiSelectAdvancedOption[]}
              shouldRenderConnectors={disableInput}
            />
          )}
        />
      </FormControl>
      <Stack
        spacing={2}
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {helper && !isMobile && <FormHelperText>{helper}</FormHelperText>}
        {action && (
          <TextLink
            id="search-options-btn"
            text={action.label}
            onClick={action.onClick}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default InputAdvancedSearch
