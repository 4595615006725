import { FiltersContainer } from './styles'
import { SavedBucketsFilterCategory } from '../SavedBucketsFilterCategory'
import { SavedBucketsFilterPeriod } from '../SavedBucketsFilterPeriod'

export default function SavedBucketsFilter() {
  return (
    <FiltersContainer>
      <SavedBucketsFilterCategory />
      <SavedBucketsFilterPeriod />
    </FiltersContainer>
  )
}
