import styled from 'styled-components'
import { Card } from '@components/atoms'
import { ChevronRight } from '@mui/icons-material'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled as muiStyled,
} from '@mui/material'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  padding: 16px;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 32px;
    gap: 32px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
`

export const HeaderCount = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const DocumentsContainer = styled.div`
  margin-top: 30px;
`

export const AccordionSummaryIcon = muiStyled(ChevronRight)`
  font-size: 24px;
  color: ${(props) =>
    props.theme.palette['$color-action-background-primary-default']};
`

export const DocumentsAccordion = styled(Accordion)`
  box-shadow: none !important;
  overflow: hidden !important;
  margin-top: 0px !important;
  border-radius: 8px !important;
  align-items: center;
`

export const DocumentsSummary = styled(AccordionSummary)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  border-bottom: 1px solid #e5e5f1 !important;
`

export const DocumentsTitle = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const DocumentsList = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

export const Document = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
`
