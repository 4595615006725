/* eslint-disable react/no-array-index-key */
import { Typography } from '@mui/material'
import { Container, Header } from './PrimaryPage.styles'

export type TabbedPageProps = {
  id: string
  title: string
  description: string
  actions?: React.ReactElement
  children: React.ReactChild
}

const defaultProps = {
  actions: null,
}

function PrimaryPage({
  id,
  title,
  description,
  actions,
  children,
}: TabbedPageProps) {
  return (
    <Container data-testid={id}>
      <Header>
        <div>
          <Typography variant="$font-title-3" color="$color-text-primary">
            {title}
          </Typography>

          <Typography variant="$font-body-base" color="$color-text-tertiary">
            {description.split('\n').map((text, index) => {
              return <p key={index}>{text}</p>
            })}
          </Typography>
        </div>

        <div>{actions}</div>
      </Header>
      {children}
    </Container>
  )
}

PrimaryPage.defaultProps = defaultProps

export default PrimaryPage
