export const roles = [
  {
    title: 'Edição',
    description: 'Permite adicionar, editar e remover dados ao painel',
    value: 'EDITOR',
    name: 'editor',
  },
  {
    title: 'Gestão',
    description:
      'Permite adicionar, editar e remover dados ao painel além de administrar os usuarios e informações',
    value: 'ADMIN',
    name: 'admin',
  },
  {
    title: 'Visualização',
    description: 'Permite apenas visualizar os dados do painel',
    value: 'VIEWER',
    name: 'viewer',
  },
]
