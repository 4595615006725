import { useMemo } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { GeneralPanelContext } from './context'
import { AlertEntity } from '@services/nomos_api/entities/alert'
import { useGetAllAlerts } from '@services/nomos_api/resources/alerts/getAll'
import { useGetMonitorById } from '@services/nomos_api/resources/monitor/getById'
import { useListSavedEvents } from '@services/nomos_api/resources/monitor/list-saved-events'
import { useListSavedPropositions } from '@services/nomos_api/resources/monitor/list-saved-propositions'

type GeneralPanelControllerProps = {
  children: React.ReactNode
}

export default function GeneralPanelController({
  children,
}: GeneralPanelControllerProps) {
  const { id } = useParams()

  const { data: monitor, isLoading: isLoadingMonitor } = useGetMonitorById({
    id,
  })

  const currentDate = new Date()
  const prev7Days = new Date()
  prev7Days.setDate(currentDate.getDate() - 7)
  const next7Days = new Date()
  next7Days.setDate(currentDate.getDate() + 7)

  const { data: monitorNextEvents, isLoading: eventsLoading } =
    useListSavedEvents({
      id,
      dateRange: {
        start: format(currentDate, 'yyyy-MM-dd'),
        end: format(next7Days, 'yyyy-MM-dd'),
      },
    })

  const { data: monitorDiscoveredSearches, isLoading: searchesLoading } =
    useGetAllAlerts({
      monitorIds: [String(id)],
      periodFrom: format(prev7Days, 'yyyy-MM-dd'),
      periodTo: format(currentDate, 'yyyy-MM-dd'),
    })

  const {
    data: monitorProceedingPropositions,
    isLoading: propositionsLoading,
  } = useListSavedPropositions({
    id,
    sort: 'proceedingDate',
    sortDirection: 'desc',
  })

  const store = useMemo(
    () => ({
      monitor,
      isLoadingMonitor,
      monitorNextEvents,
      eventsLoading,
      monitorDiscoveredSearches: monitorDiscoveredSearches as AlertEntity[],
      searchesLoading,
      monitorProceedingPropositions,
      propositionsLoading,
    }),
    [
      monitor,
      isLoadingMonitor,
      monitorNextEvents,
      eventsLoading,
      monitorDiscoveredSearches,
      searchesLoading,
      monitorProceedingPropositions,
      propositionsLoading,
    ]
  )

  return (
    <GeneralPanelContext.Provider value={store}>
      {children}
    </GeneralPanelContext.Provider>
  )
}
