/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'

export type SigninEmailContextType = {
  formik: any
  isLoading: boolean
  error: any
}

export const SigninEmailContext = createContext<SigninEmailContextType>(
  {} as SigninEmailContextType
)
