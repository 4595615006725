/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import {
  FormRegistrationStep2Context,
  FormRegistrationStep2FormType,
} from './context'
import {
  officeEmployees,
  name,
  officeSectors,
  officePosition,
} from '@utils/forms/validation'
import { useCookies } from '@contexts/Cookies'
import { useHubspot } from '@hooks/useHubspot'
import { createCompanyAPI } from '@services/nomos_api/resources/company/create'
import { useAuth } from '@contexts/Auth'

export type FormRegistrationStep2ControllerProps = {
  children: React.ReactChild
}

export function FormRegistrationStep2Controller({
  children,
}: FormRegistrationStep2ControllerProps) {
  const { isAuthenticated } = useAuth()
  const { getCookie } = useCookies()
  const { emitHsEvent } = useHubspot()

  const handleTrackSingupCompleted = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any
    if (w.smartlook) {
      w.smartlook('track', 'sign_up_completed')
    }
  }

  const formik = useFormik<FormRegistrationStep2FormType>({
    initialValues: {
      companyName: '',
      aboutSector: '',
      aboutEmployees: '',
      aboutOfficePosition: '',
      metadata: {
        page_url: window.location.href,
        page_title: document.title,
        hubspotutk: getCookie('hubspotutk'),
        utm_source: getCookie('utm_source'),
        utm_medium: getCookie('utm_medium'),
        utm_content: getCookie('utm_content'),
        utm_campaign: getCookie('utm_campaign'),
      },
    },
    validationSchema: Yup.object().shape({
      companyName: name,
      aboutSector: officeSectors,
      aboutEmployees: officeEmployees,
      aboutOfficePosition: officePosition,
    }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: async (values) => {
      formik.setSubmitting(true)
      createCompanyAPI(values)
        .then((userSession) => {
          handleTrackSingupCompleted()
          isAuthenticated(userSession?.user, userSession.jwt)
          emitHsEvent({
            formName: 'nomos-signup',
            payload: {
              email: userSession.user.email,
              phone: userSession.user.phone,
              firstName: userSession.user.name,
              company: userSession.user.company?.name,
              jobtitle: userSession.user.company?.about?.officePosition,
              origem: 'nomos::signup',
              formulario_de_origem: 'nomos',
            },
          })
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  const state = useMemo(() => ({ formik }), [formik])

  return (
    <FormRegistrationStep2Context.Provider value={state}>
      {children}
    </FormRegistrationStep2Context.Provider>
  )
}
