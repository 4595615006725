/* eslint-disable no-plusplus */

import { theme } from '@theme/index'

/* eslint-disable no-bitwise */
export const darkenColor = (hexColor: string, percent: number): string => {
  // Remove the "#" symbol from the hex color code
  const cleanColor = hexColor.replace('#', '')

  // Convert the hex color to RGB format
  const r = parseInt(cleanColor.substring(0, 2), 16)
  const g = parseInt(cleanColor.substring(2, 4), 16)
  const b = parseInt(cleanColor.substring(4, 6), 16)

  // Calculate the darkened RGB values by multiplying them by (100 - percent) / 100
  const darkenedR = Math.round((r * (100 - percent)) / 100)
  const darkenedG = Math.round((g * (100 - percent)) / 100)
  const darkenedB = Math.round((b * (100 - percent)) / 100)

  // Convert the darkened RGB values back to hex format
  const darkenedHex = `#${darkenedR.toString(16)}${darkenedG.toString(
    16
  )}${darkenedB.toString(16)}`

  return darkenedHex
}

export const lightenColor = (hexColor: string, percent: number): string => {
  if (percent === 0) return hexColor

  // Remove the "#" symbol from the color string if present
  const cleanColor = hexColor.replace('#', '')

  // Convert the hexadecimal color to RGB values
  const r = parseInt(cleanColor.substring(0, 2), 16)
  const g = parseInt(cleanColor.substring(2, 4), 16)
  const b = parseInt(cleanColor.substring(4, 6), 16)

  // Calculate the percentage value
  const factor = percent / 100

  // Calculate the new RGB values
  const lightenR = Math.round(r + (255 - r) * factor)
  const lightenG = Math.round(g + (255 - g) * factor)
  const lightenB = Math.round(b + (255 - b) * factor)

  // Convert the new RGB values back to hexadecimal
  const lightenHex = `#${lightenR.toString(16)}${lightenG.toString(
    16
  )}${lightenB.toString(16)}`

  return lightenHex
}

export const textToColor = (str: string) => {
  const colors = [
    theme.palette['$color-extended-blue1'].main,
    theme.palette['$color-extended-blue2'].main,
    theme.palette['$color-extended-yellow1'].main,
    theme.palette['$color-extended-yellow2'].main,
    theme.palette['$color-extended-red1'].main,
    theme.palette['$color-extended-red2'].main,
    theme.palette['$color-extended-green1'].main,
    theme.palette['$color-extended-green2'].main,
    theme.palette['$color-extended-purple1'].main,
    theme.palette['$color-extended-purple2'].main,
  ]

  // Step 1: Calculate the hash code of the input text
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i)
    hash = (hash << 5) - hash + charCode
  }

  // Step 2: Ensure the hash code is positive
  if (hash < 0) {
    hash = -hash
  }

  // Step 3: Map the hash code to the range [0, N)
  const colorIndex = hash % colors.length
  return colors[colorIndex]
}

export const getContrastColor = (hexColor: string): string => {
  // Remove the "#" character if present
  const cleanHexColor = hexColor.replace(/^#/, '')

  // Parse the hexadecimal color to RGB components
  const r = parseInt(cleanHexColor.slice(0, 2), 16)
  const g = parseInt(cleanHexColor.slice(2, 4), 16)
  const b = parseInt(cleanHexColor.slice(4, 6), 16)

  // Calculate the relative luminance using the formula for sRGB colors
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Choose white or black based on the luminance threshold
  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}
