import { createContext } from 'use-context-selector'
import { NoticeEntity } from '@services/nomos_api/entities/notice'

export type NoticeDetailsContextType = {
  notice: NoticeEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const NoticeDetailsContext = createContext<NoticeDetailsContextType>(
  {} as NoticeDetailsContextType
)
