/* eslint-disable no-unused-vars */

import { PrimaryPage } from '@components/templates'
import StakeholdersList from '@containers/StakeholdersList'

export default function Stakeholders() {
  return (
    <PrimaryPage
      id="stakeholders-page"
      title="Stakeholders"
      description="Veja abaixo a lista de stakeholders do Senado Federal e Câmara dos Deputados"
    >
      <StakeholdersList />
    </PrimaryPage>
  )
}
