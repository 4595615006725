import { Button } from '@components/atoms'
import { IconButton, Stack, Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useDrawer } from '@contexts/Drawer'
import { Container, Header, IconClose } from '../../styles'
import {
  ContentBody,
  IconBack,
} from '../form-proposition-status-notification/styles'

export type PropositionsMonitorConfirmationProps = {
  onSubmit: () => void
}

export function PropositionsMonitorConfirmation(
  props: PropositionsMonitorConfirmationProps
): JSX.Element {
  const { closeDrawer, cleanStack } = useDrawer()
  const { onSubmit } = props
  return (
    <Container>
      <Header>
        <IconButton onClick={closeDrawer} size="small">
          <IconBack />
        </IconButton>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Opções de Monitoramento
        </Typography>
        <IconButton onClick={cleanStack}>
          <IconClose />
        </IconButton>
      </Header>
      <ContentBody>
        <Stack
          flex={1}
          spacing={3}
          width="100%"
          direction="column"
          overflow="hidden"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              gap: '8px',
              padding: '10px',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <Stack
              direction="row"
              gap="8px"
              alignItems="center"
              justifyContent="center"
            >
              <ErrorOutlineOutlinedIcon
                sx={{
                  width: '35px',
                  height: '35px',
                  color: '$color-semantic-error',
                }}
              />
              <Typography variant="$font-title-3">Confirmar ação?</Typography>
            </Stack>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              variant="$font-body-base"
              color="$color-text-secondary"
            >
              Ao confirmar esta ação as opções de monitoramento serão aplicadas
              para todos os dados selecionados.
            </Typography>
          </Stack>

          <Stack direction="row" gap="16px">
            <Button
              text="Sair"
              size="large"
              fullWidth
              variant="outlined"
              id="go-to-next-step"
              onClick={cleanStack}
            />
            <Button
              size="large"
              fullWidth
              text="Confirmar"
              data-cy="btn-save-bucket-on-monitor"
              onClick={onSubmit}
            />
          </Stack>
        </Stack>
      </ContentBody>
    </Container>
  )
}
