import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getAllSavedPropositions = async (q: string | undefined) => {
  const { data } = await HTTPClient.get(`saved_propositions?q=${q}`)
  return data.results || []
}

export const useGetAllSavedPropositions = (q: string) =>
  useQuery(['get-all-saved-propositions'], () => getAllSavedPropositions(q))
