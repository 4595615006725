import { UsersView } from './view'
import { UsersController } from './controller'

export default function Users() {
  return (
    <UsersController>
      <UsersView />
    </UsersController>
  )
}
