import styled from 'styled-components'
import { Close } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

export const Container = styled(Box)`
  height: 100%;
  max-height: 100%;
  display: flex;
  max-width: 450px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`

export const ContentBody = styled(Box)`
  flex: 1;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2d3a40;
`

export const IconClose = styled(Close)`
  font-size: 24px;
  color: #6d787d;
`

export const StepContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
`

export const ScrollBox = styled(Box)`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 32px;
  margin-bottom: 32px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`
