/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useState } from 'react'
import { DateRangePicker } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SavedBucketsContext } from '@containers/SavedBucketsList/context'

interface SavedBucketsFilterPeriodProps {}

export function SavedBucketsFilterPeriod(__: SavedBucketsFilterPeriodProps) {
  const handleFilterChange = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleFilterChange
  )

  return (
    <DateRangePicker
      size="tiny"
      label="Data de publicação"
      sx={{ backgroundColor: 'white' }}
      startDate={undefined}
      endDate={undefined}
      onChange={(from, to) => handleFilterChange({ period: { from, to } })}
    />
  )
}
