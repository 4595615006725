import styled from 'styled-components'

export const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FeedbackContainer = styled.div`
  width: 488px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(230, 232, 240, 0.8);
  background-color: #fff;
  border-color: #e6e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`
