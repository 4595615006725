import { OficialDiaryDetailsView } from './view'
import { OficialDiaryDetailsController } from './controller'

function OficialDiaryDetailsPage() {
  return (
    <OficialDiaryDetailsController>
      <OficialDiaryDetailsView />
    </OficialDiaryDetailsController>
  )
}

export default OficialDiaryDetailsPage
