import { SignUpEmailView } from './view'
import SignupController from './controller'
import { SignupProps } from './types'

export default function SignUpEmail(props: SignupProps): JSX.Element {
  return (
    <SignupController {...props}>
      <SignUpEmailView />
    </SignupController>
  )
}
