import { CardWrapper } from './styles'
import { Avatar, TextLink } from '@components/atoms'
import { Description } from '@components/molecules'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { Typography, Stack, Chip, Button } from '@mui/material'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Link } from 'react-router-dom'

export function CardStakeholder({ stakeholder, first=false }) {
  return (
    <CardWrapper sx={{ borderTopWidth: first ? 0 : 1 }}>
      <Stack direction="row" spacing={2}>
        <Avatar
          alt={stakeholder?.name}
          src={stakeholder?.photoUrl}
          width={56}
          height={56}
        />
        <Stack direction="column" spacing={2} flex={1}>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              px={1}
            >
              <Stack direction="row" spacing={3}>
                  <Link to={`/stakeholders/${stakeholder.stakeholderId}`}>
                    <Typography variant="$font-title-5" color="$color-text-primary">
                      {capitalizeAllWords(stakeholder?.name)} 
                    </Typography>
                  </Link>
                {stakeholder.designationDate && (
                  <Description
                    label="Designado em:"
                    value={formatDate(stakeholder.designationDate)}
                  />
                )}
              </Stack>
              {!!stakeholder.current && (
                <Chip
                  size="small"
                  label="Relator Atual"
                  variant="outlined"
                  color="primary"
                />
              )}
            </Stack>
            <Stack direction="row" spacing={2} px={1}>
              <Description
                label="Posição:"
                value={stakeholder?.type || '--'}
              />
              <Description
                label="Partido:"
                value={stakeholder?.acronymParty || '--'}
              />
              <Description
                label="UF:"
                value={stakeholder?.electoralUF || '--'}
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} px={1}>
            <Typography variant="$font-title-5" color="$color-text-primary">
              Gabinete
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={2}>
                <Description
                  label="Endereço:"
                  value={stakeholder?.cabinet?.address || '--'}
                />
                <Description
                  label="Prédio:"
                  value={stakeholder?.cabinet?.building || '--'}
                />
                <Description
                  label="Sala:"
                  value={stakeholder?.cabinet?.floor || '--'}
                />
                <Description
                  label="Andar:"
                  value={stakeholder?.cabinet?.floor || '--'}
                />
                <Description
                  label="Telefone:"
                  value={stakeholder?.contact || '--'}
                />
              </Stack>
            </Stack>
          </Stack>
          {!!stakeholder?.opinion && (
            <Stack direction="row" justifyContent="flex-start">
              <Button variant="text" href={stakeholder?.opinion?.url} target="_blank">
                {stakeholder?.opinion?.text || stakeholder?.opinion?.url}
              </Button>
            </Stack>
          )}          
        </Stack>
      </Stack>
    </CardWrapper>
  )
}
