import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material'

export const ModalHeader = styled(DialogTitle)`
  gap: 24px;
  padding: 24px;
  display: flex;
  min-width: 661px;
  max-width: 661px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
export const ModalBody = styled(DialogContent)`
  min-width: 661px;
  max-width: 661px;
  padding-bottom: 32px;
`
export const ModalFooter = styled(DialogActions)`
  padding: 24px;
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.shadows[2]};
`
