import { MonitoringView } from './view'
import MonitoringController from './controller'
import { MonitoringDrawerProps } from './types'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

function MonitoringDrawer(props: MonitoringDrawerProps) {
  // call bypass hook to block feature if user
  // does not have permission to use it
  useByPassSubscription({
    featureTitle: 'Opções de monitoramento',
    featureSubtitle: `Fique sempre atualizado(a) sobre os dados que afetam seu negócio.`,
    featureDescription: `Adicione dados ao seu painel e comece a monitorar e receber alertas de atualizações do seu interesse.`,
    requiredRole: NomosProductCodeEnum.MONITOR_PRO,
  })
  return (
    <MonitoringController {...props}>
      <MonitoringView />
    </MonitoringController>
  )
}

MonitoringDrawer.defaultProps = {
  onFinish: null,
}

export default MonitoringDrawer
