import { useMutation } from '@tanstack/react-query'

import HTTPClient from '../..'

export const forgotPasswordAPI = async (body) => {
  const { data } = await HTTPClient.post('/forgot-password', body)

  return data
}

export const useForgotPassword = () =>
  useMutation(['forgot-password'], forgotPasswordAPI)
