/* eslint-disable no-inline-styles/no-inline-styles */
import { ptBR } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'
import IconNoData from '@assets/icons/nodata.svg'
import { Stack, Typography } from '@mui/material'
import { HeaderSelect } from './components/Header'
import { addDays, subDays, format } from 'date-fns'
import { getDate } from '@utils/functions/dates/dates'
import { useCallback, useState, useEffect } from 'react'
import { Container, Header, Picker, Content } from './styles'
import { DateSinglePicker } from './components/DateSinglePicker'
import CardEventComponent from '@components/organisms/Cards/Event/Event'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

function EventsCalendarList(props) {
  const navigate = useNavigate()
  const { data, headerLocation, handleUpdateSearch } = props

  const [period, setPeriod] = useState({
    start: getDate(new Date()),
    end: addDays(getDate(new Date()), 30),
  })

  const handleNextPeriod = useCallback(() => {
    setPeriod({
      start: period.end,
      end: addDays(period.end, 30),
    })
  }, [period])

  const handlePreviousPeriod = useCallback(() => {
    setPeriod({
      start: subDays(period.start, 30),
      end: period.start,
    })
  }, [period])

  const handleChangePeriod = useCallback((date) => {
    if (!date) return

    setPeriod({
      start: date,
      end: addDays(date, 30),
    })
  }, [])

  useEffect(() => {
    handleUpdateSearch({
      [`buckets.events.limit`]: 10,
      [`buckets.events.filter.date.from`]: format(period.start, 'yyyy-MM-dd'),
      [`buckets.events.filter.date.to`]: format(period.end, 'yyyy-MM-dd'),
      [`buckets.events.sort`]: 'older',
    })
  }, [period])

  return (
    <Container style={{ marginBottom: 0, paddingBottom: '48px' }}>
      <Header location={headerLocation}>
        <Picker>
          <KeyboardArrowLeft onClick={handlePreviousPeriod} />

          <DateSinglePicker
            text={`${format(period.start, 'dd MMM', { locale: ptBR })} a
              ${format(period.end, 'dd MMM yyyy', { locale: ptBR })}`}
            onChange={handleChangePeriod}
          />

          <KeyboardArrowRight onClick={handleNextPeriod} />
        </Picker>

        <HeaderSelect />
      </Header>

      <Content style={{ gap: '12px' }}>
        {data?.length === 0 && (
          <Stack direction="column" gap={1} sx={{ marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
            <img src={IconNoData} alt="No Data" height={30} />
            <Typography variant="$font-title-5" color="text.disabled">
              No Data
            </Typography>
          </Stack>
        )}

        {data?.map((event, index) => (
          <CardEventComponent event={event} key={index} handleOpenModalDetailsEvent={()=> navigate(`/events/${event.id}`)} />
        ))}
      </Content>
    </Container>
  )
}

export default EventsCalendarList
