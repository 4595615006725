/* eslint-disable no-unused-vars */
import StakeholdersView from './view'
import StakeholdersController from './controller'

export type StakeholdersListProps = {
  monitorId?: string
}

function StakeholdersList(props: StakeholdersListProps) {
  return (
    <StakeholdersController {...props}>
      <StakeholdersView />
    </StakeholdersController>
  )
}

StakeholdersList.defaultProps = {
  monitorId: null,
}

export default StakeholdersList
