import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material'

export const ModalHeader = styled(DialogTitle)`
  gap: 24px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
export const ModalBody = styled(DialogContent)`
  padding-bottom: 32px;
`
export const ModalFooter = styled(DialogActions)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'row',
  backgroundColocar: 'white',
  padding: '24px',
  flexWrap: 'wrap',
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
  },
}))
