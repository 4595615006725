import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  min-height: 305px;
  padding: 16px;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 32px;
    gap: 32px;
  }
`

export const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5f1;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
