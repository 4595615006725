import { createContext } from 'use-context-selector'
import { FeedbackMessage } from '@services/nomos_api/entities/axios'

type AcceptUserContextType = {
  isError: boolean
  isLoading: boolean
  response?: FeedbackMessage
}

export const AcceptUserContext = createContext<AcceptUserContextType>(
  {} as AcceptUserContextType
)
