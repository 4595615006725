import Tabs from '../Tabs/Tabs'
import Organs from '../Organs/Organs'
import Social from '../Social/Social'
import Speeches from '../Speeches/Speeches'
import { Wrapper } from './StatusCard.styles'
import Activities from '../Activities/Activities'
import { useContextSelector } from 'use-context-selector'
import { StakeholderDetailContext } from '../../context'

export default function StatusCard() {
  const tab = useContextSelector(StakeholderDetailContext, (s) => s.tab)

  return (
    <Wrapper>
      <Tabs />

      {tab === 'activities' && <Activities />}
      {tab === 'organs' && <Organs />}
      {tab === 'social' && <Social />}
      {tab === 'speeches' && <Speeches />}
    </Wrapper>
  )
}
