import { useState } from 'react'
import { columns } from './columns'
import { Input } from '@components/atoms'
import { Table } from '@components/organisms'
import { useDebounce } from '@hooks/useDebounce'
import { GridFilterModel } from '@mui/x-data-grid'
import { useContextSelector } from 'use-context-selector'
import { OrganDetailContext } from '@pages/private/OrganDetail/context'
import { useSearchPropositions } from '@services/nomos_api/resources/search/propositions'

import { Container, FiltersContainer, TableContainer } from './styles'

export default function Propositions() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)
  if (!organ) return null

  const [page, setPage] = useState(0)
  const [textSeach, setTextSearch] = useState('')
  const keyword = useDebounce(textSeach, 500)

  const [filters, setFilters] = useState<Record<string, string | string[]>>({
    organs: [organ.id],
  })

  const { data, isFetching } = useSearchPropositions({
    page: page + 1,
    limit: 10,
    keyword,
    filter: filters,
  })

  const handleFilterChange = (filters: GridFilterModel) => {
    const filtersToSet = {} as Record<string, string>
    filters.items.forEach((item) => {
      filtersToSet[item.id || ''] = item.value
    })
    setPage(1)
    setFilters(filtersToSet)
  }

  const rows = data?.results || []

  return (
    <Container>
      <FiltersContainer>
        <Input
          size="small"
          type="search"
          label="Busca"
          value={textSeach}
          sx={{ width: 300 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextSearch(e.target.value)
          }
          placeholder="Buscar por: palavra chave, proposição"
        />
      </FiltersContainer>

      <TableContainer>
        <Table
          paginationMode="server"
          filterMode="server"
          loading={isFetching}
          rowsPerPageOptions={[5]}
          pagination
          rows={rows}
          columns={columns}
          page={page}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 100}
          pageSize={data?.pagination?.limit}
          rowCount={data?.pagination?.total || 0}
          onPageChange={setPage}
          onFilterModelChange={handleFilterChange}
        />
      </TableContainer>
    </Container>
  )
}
