import { styled as styledMaterial } from '@mui/material/styles'

import { Box } from '@mui/material'

export const Wrapper = styledMaterial(Box)(() => ({
  height: '100%',
  maxHeight: '46vh',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  overflowX: 'none',
  overflowY: 'auto',
  padding: '0 40px 0 0',
  '> span': {
    fontWeight: 400,
    fontSize: '14px',
    whiteSpace: 'pre-line',
    lineHeight: '21px',
    color: '#556282',
    textAlign: 'justify',
  },
}))
