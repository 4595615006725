import produce from 'immer'
import { SavedBucketsContext } from './context'
import { useState, useMemo, useCallback } from 'react'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { PagiantedType } from '@services/nomos_api/entities/paginated'
import { deleteSavedBucketsApi } from '@services/nomos_api/resources/saved_buckets/delete'
import {
  FindSavedBucketsFilterProps,
  useFindSavedBuckets,
} from '@services/nomos_api/resources/saved_buckets/getAll'
import { useModal } from '@contexts/Modal'
import { ConfirmModal } from '@components/organisms'

export type SavedBucketsControllerProps = {
  monitorId?: string
  children: React.ReactChild
}

function SavedBucketsController({
  monitorId,
  children,
}: SavedBucketsControllerProps) {
  const { openModal } = useModal()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [isRemoving, setRemoving] = useState<boolean>(false)
  const [selecteds, setSelecteds] = useState<string[]>([])

  const [filters, setFilters] = useState<FindSavedBucketsFilterProps>({
    monitorId,
  })

  const handleFilterChange = useCallback(
    (filter: FindSavedBucketsFilterProps) => {
      setFilters((prevState) => ({
        ...prevState,
        ...filter,
      }))
    },
    []
  )

  const { data, isFetching, refetch } = useFindSavedBuckets({
    page,
    limit,
    filters,
  })

  const handleConfirmDeleteSavedBucket = (ids: string[]) => {
    setRemoving(true)
    deleteSavedBucketsApi(ids)
      .then(() => refetch())
      .finally(() => setRemoving(false))
  }

  const handleDeleteSavedBucket = (ids: string[]) => {
    if (ids.length === 0) return
    openModal(
      <ConfirmModal
        title="Confirmação necessária"
        info="Ao excluir os dados, eles não ficarão mais visíveis no seu painel!"
        confirmText={`Você tem certeza que deseja excluir ${ids.length} dado(s) selecionado(s)?`}
        actionText="Confirmar"
        onConfirm={() => handleConfirmDeleteSavedBucket(ids)}
      />
    )
  }

  const handleToggleAll = useCallback(() => {
    const results =
      (data as unknown as PagiantedType<SavedBucketEntity>).results || []
    if (selecteds.length !== results.length) {
      setSelecteds(results.map((r) => r.id))
    } else {
      setSelecteds([])
    }
  }, [setSelecteds, selecteds, data])

  const handleToggleSelection = useCallback(
    (savedBucket: SavedBucketEntity) => {
      setSelecteds(
        produce(selecteds, (draft) => {
          if (!draft.includes(savedBucket.id)) {
            draft.push(savedBucket.id)
            return draft
          }
          return draft.filter((i) => i !== savedBucket.id)
        })
      )
    },
    [setSelecteds, selecteds]
  )

  const store = useMemo(
    () => ({
      data: data as unknown as PagiantedType<SavedBucketEntity>,
      filters,
      selecteds,
      isRemoving,
      isLoading: isFetching,
      setPage,
      setLimit,
      handleToggleAll,
      handleFilterChange,
      handleToggleSelection,
      handleDeleteSavedBucket,
    }),
    [
      data,
      filters,
      selecteds,
      isRemoving,
      isFetching,
      setPage,
      setLimit,
      handleToggleAll,
      handleFilterChange,
      handleToggleSelection,
      handleDeleteSavedBucket,
    ]
  )

  return (
    <SavedBucketsContext.Provider value={store}>
      {children}
    </SavedBucketsContext.Provider>
  )
}

SavedBucketsController.defaultProps = {
  monitorId: null,
}

export default SavedBucketsController
