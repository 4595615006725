/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from '@mui/material'
import { Wrapper } from './FormExport.styles'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { BucketsEnum } from '@utils/buckets/type'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { useContextSelector } from 'use-context-selector'
import { useDrawer } from '@contexts/Drawer'
import { SearchResultsContext } from '../../context'
import { PropositionsExportModal } from '@components/organisms'
import { useModal } from '@contexts/Modal'
import { pageview } from '@utils/functions/pageview/pageview'

function FormExportComponent() {
  const ids = useContextSelector(SearchResultsContext, (s) => s.selected)
  const pagination = useContextSelector(
    SearchResultsContext,
    (s) => s.pagination
  )
  const keywords = useContextSelector(SearchResultsContext, (s) => s.keywords)
  const dataPropositions = useContextSelector(
    SearchResultsContext,
    (s) => s.dataPropositions
  )
  const { openDrawer } = useDrawer()
  const { openModal } = useModal()

  const titles = Array.from(ids).map((id) => {
    const idx = dataPropositions.results?.find((p: { id: any }) => {
      return p.id === id
    })
    return idx.title
  })

  const getKeywords = () => {
    const formKeywords = new Array<string>()
    keywords?.or.forEach((keyword) => formKeywords.push(keyword))
    keywords?.and.forEach((keyword) => formKeywords.push(keyword))
    keywords?.not.forEach((keyword) => formKeywords.push(keyword))
    return formKeywords
  }

  return (
    <Wrapper>
      <Button
        endIcon={<FileDownloadOutlinedIcon />}
        data-cy="button-monitor-proposition"
        onClick={() => {
          openModal(
            <PropositionsExportModal
              initialForm={{
                fileTypes: [],
                selectedIds: Array.from(ids),
                subtitle: `Exibindo ${pageview(
                  pagination || {}
                )} resultados para:`,
                keywords: getKeywords(),
              }}
            />
          )
        }}
      >
        <Typography noWrap variant="$font-body-2" color="$color-text-primary">
          Exportar selecionados
        </Typography>
      </Button>
      <Button
        endIcon={<VisibilityOutlinedIcon />}
        data-cy="button-monitor-proposition"
        onClick={() =>
          openDrawer(
            <MonitoringDrawer
              id={Array.from(ids).toString()}
              title={titles.toString()}
              origin={BucketsEnum.propositions}
            />
          )
        }
      >
        <Typography noWrap variant="$font-body-2" color="$color-text-primary">
          Monitorar selecionados
        </Typography>
      </Button>
    </Wrapper>
  )
}

export default FormExportComponent
