import { TagManagerCreateView } from './view'
import { TagManagerCreateProps } from './types'
import { TagManagerCreateController } from './controller'

export function TagManagerCreate(props: TagManagerCreateProps): JSX.Element {
  return (
    <TagManagerCreateController {...props}>
      <TagManagerCreateView />
    </TagManagerCreateController>
  )
}
