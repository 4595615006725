import { MouseEvent, useState } from 'react'
import PaperSVG from '@assets/icons/paper.svg'
import { useNavigate } from 'react-router-dom'
import { MonitorsContext } from '../../context'
import SearchSVG from '@assets/icons/search.svg'
import IconNoData from '@assets/icons/nodata.svg'
import { Pagination } from '@components/molecules'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import CalendarSVG from '@assets/icons/calendar.svg'
import { useContextSelector } from 'use-context-selector'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ContentCopy, LaunchOutlined } from '@mui/icons-material'
import { MonitorEntity } from '@services/nomos_api/entities/monitor'

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import {
  EmptyData,
  Card,
  List,
  CardHeader,
  CardContent,
  CardItem,
  LoadingContainer,
  CardFooter,
} from './ListMonitors.styles'

export default function ListMontitors() {
  const navigate = useNavigate()
  const [id, setId] = useState<string>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (id: string, event?: MouseEvent<HTMLElement>) => {
    setId(id)
    setAnchorEl(anchorEl ? null : event?.currentTarget || null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const type = useContextSelector(MonitorsContext, (s) => s.type)
  const setPage = useContextSelector(MonitorsContext, (s) => s.setPage)
  const setLimit = useContextSelector(MonitorsContext, (s) => s.setLimit)
  const monitors = useContextSelector(MonitorsContext, (s) => s.monitors)
  const isLoading = useContextSelector(MonitorsContext, (s) => s.isLoading)

  return (
    <>
      {type === 'suggested' && (
        <Box
          sx={{ p: '24px', bgcolor: 'background.paper', marginBottom: '40px' }}
        >
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Painéis criados e curados pelo time de analistas da Nomos. Com o
            objetivo de otimizar seu processo de busca e pesquisa, criamos
            painéis com dados monitorados de acordo com o segmento de mercado da
            sua organização. Você pode clonar os painéis do seu interesse para{' '}
            <b>Meus Painéis</b> e editá-lo de acordo com as suas preferências.
          </Typography>
        </Box>
      )}

      {isLoading && (
        <LoadingContainer>
          <CircularProgress size={25} />
        </LoadingContainer>
      )}

      {!isLoading && monitors && (
        <List container rowSpacing={4} columnSpacing={8}>
          {monitors?.results?.map((item: MonitorEntity) => (
            <Grid key={item.id} item>
              <Card>
                <CardHeader>
                  <Typography
                    data-testid="monitor-title"
                    variant="$font-title-5"
                    color="$color-text-primary"
                  >
                    {item.name}
                  </Typography>

                  {type === 'suggested' ? (
                    <IconButton
                      onClick={(e) => handleClick(item.id, e)}
                      sx={{ left: '15px' }}
                    >
                      <GridMoreVertIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ left: '15px' }}
                      onClick={() => navigate(`/monitors/${item.id}`)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  )}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate(`/monitors/${id}?clone=true`)
                      }}
                    >
                      <ContentCopy />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                        sx={{ marginLeft: 2 }}
                      >
                        Clonar para meus paineis
                      </Typography>
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/monitors/${id}`)}>
                      <LaunchOutlined />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                        sx={{ marginLeft: 2 }}
                      >
                        Ver detalhes
                      </Typography>
                    </MenuItem>
                  </Menu>
                </CardHeader>

                <CardContent>
                  <CardItem>
                    <div>
                      <img src={PaperSVG} alt="Proposiçoes" />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                      >
                        Proposições
                      </Typography>
                    </div>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      {item.count?.saved_propositions || 0}
                    </Typography>
                  </CardItem>

                  <CardItem>
                    <div>
                      <img src={CalendarSVG} alt="Eventos" />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                      >
                        Eventos
                      </Typography>
                    </div>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      {item.count?.saved_events || 0}
                    </Typography>
                  </CardItem>

                  <CardItem>
                    <div>
                      <img src={SearchSVG} alt="Pesquisas salvas" />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                      >
                        Pesquisa Salvas
                      </Typography>
                    </div>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      {item.count?.saved_searches || 0}
                    </Typography>
                  </CardItem>

                  <CardFooter>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Criado por:
                    </Typography>

                    {item.cloned && (
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-primary"
                      >
                        Clonado de {` `}
                        {item.cloned.fromCompanyName || 'Desconhecido'}
                      </Typography>
                    )}

                    {!item.cloned && (
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-primary"
                      >
                        {item.suggested?.enabled === true &&
                        type === 'suggested'
                          ? item.company?.companyName || 'Desconhecido'
                          : item.ownerName || 'Desconhecido'}
                      </Typography>
                    )}
                  </CardFooter>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </List>
      )}

      <Pagination
        handleUpdatePage={setPage}
        handleUpdateLimit={setLimit}
        pagination={monitors?.pagination}
      />

      {monitors?.results?.length === 0 && !isLoading && (
        <EmptyData>
          <img src={IconNoData} alt="No Data" />
          <span>Ainda não há paineis criados para monitoramento</span>
        </EmptyData>
      )}
    </>
  )
}
