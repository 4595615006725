import { useContextSelector } from 'use-context-selector'
import { FilterAccordionChip } from './SideFilterSelect.styles'
import { SideFilterSelectContext } from './SideFilterSelectContext'

export function SideFilterSelectCounter(): JSX.Element {
  const selecteds = useContextSelector(
    SideFilterSelectContext,
    (s) => s.selecteds
  )
  if (selecteds.length <= 0) return <div />
  return <FilterAccordionChip size="small" label={selecteds.length} />
}
