import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { AuthResponseSignAuthType } from '@services/nomos_api/types/auth_response_signauth_type'

export type ResendConfirmationEmailProps = {
  email: string
}

export const ResendConfirmationEmailAPI = async (
  body: ResendConfirmationEmailProps
) => {
  const { data } = await HTTPClient.post<AuthResponseSignAuthType>(
    '/resendconfirmation',
    body
  )
  return data
}

export const useResendConfirmationEmail = () => {
  return useMutation<
    AuthResponseSignAuthType,
    AxiosErrorResponse,
    ResendConfirmationEmailProps
  >(['resend-email'], ResendConfirmationEmailAPI)
}
