import { EditUserView } from './view'
import { EditUserController } from './controller'

export default function Users() {
  return (
    <EditUserController>
      <EditUserView />
    </EditUserController>
  )
}
