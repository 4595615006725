import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { CvmEntity } from '@services/nomos_api/entities/cvm'

export const getCvmByIdAPI = async (id: string): Promise<CvmEntity> => {
  const { data } = await HTTPClient.get(`cvm/${id}`)
  return data as CvmEntity
}

export const useGetCvmDetails = (id: string) =>
  useQuery(['get-cvm-details'], () => getCvmByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
