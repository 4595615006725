/* eslint-disable react/function-component-definition */
import InputRadioComponent from './InputRadio'
import { ComponentMeta, ComponentStory } from '@storybook/react'

export default {
  title: 'Components/Atoms/InputRadio',
  component: InputRadioComponent,
} as ComponentMeta<typeof InputRadioComponent>

const Template: ComponentStory<typeof InputRadioComponent> = (args) => (
  <InputRadioComponent {...args} />
)

export const InputRadio = Template.bind({})
InputRadio.args = {
  name: 'optionname',
}
