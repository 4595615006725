/* eslint-disable no-restricted-syntax */
import { MultiSelectAdvancedOption } from '@components/atoms/InputAdvancedSearch/InputAdvancedSearch'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'
import { Buffer } from 'buffer'

export function encodeKeywords(keywords: KeywordConditionsType): string {
  return Buffer.from(JSON.stringify(keywords)).toString('base64')
}

export function decodeKeywords(keywords: string): KeywordConditionsType {
  try {
    return JSON.parse(
      Buffer.from(keywords, 'base64').toString('utf8')
    ) as KeywordConditionsType
  } catch (error) {
    return { and: [], or: [], not: [] }
  }
}

export function parseKeywordsToInput(
  keywords: KeywordConditionsType
): MultiSelectAdvancedOption[] {
  const search: MultiSelectAdvancedOption[] = []
  for (const keyword of keywords?.or || []) {
    search.push({ kind: 'or', value: keyword })
  }
  for (const keyword of keywords?.and || []) {
    search.push({ kind: 'and', value: keyword })
  }
  for (const keyword of keywords?.not || []) {
    search.push({ kind: 'not', value: keyword })
  }
  return search
}
