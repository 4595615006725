import AddSchedule from '../AddSchedule/AddSchedule'
import EventsFilter from '../EventFilters/EventFilters'

import { FilterContainer } from './SideFilter.styles'

export default function SideFilter() {
  return (
    <FilterContainer>
      <EventsFilter />
      <AddSchedule />
    </FilterContainer>
  )
}
