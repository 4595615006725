import { theme } from '@theme/index'
import styled from 'styled-components'
import { Close } from '@mui/icons-material'
import { Box, RadioGroup } from '@mui/material'

export const Container = styled(Box)`
  height: 100%;
  max-height: 100%;
  display: flex;
  width: 450px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px;
  max-width: 100%;
`

export const ContentBody = styled(Box)`
  flex: 1;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

export const IconClose = styled(Close)`
  font-size: 16px !important;
  color: ${theme.palette['$color-border-dark']};
`

export const StepContainer = styled(Box)`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
`

export const Roles = styled(RadioGroup)`
  display: flex;
  gap: 16px;
  flex-direction: row !important;
`
