import styledEmotion from '@emotion/styled'
import { Box, styled } from '@mui/material'
import { theme } from '@theme/index'

export const HiddenInput = styledEmotion.input`
  display: none;
`

export const Touchable = styledEmotion.label`
  cursor: pointer;
  user-select: none;
  & input[type='radio']:checked + * {
    border-color: ${theme.palette['$color-action-border']}
  }
`

export const ColorItem = styled(Box)<{ color: string; selected: boolean }>`
  width: 100%;
  cursor: pointer;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  aspect-ratio: 16/10;
  border-color: ${(props) => props.theme.palette['$color-border-primary']};
  background-color: ${(props) => props.color};
`
