import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { ReportMonitorType } from '@pages/private/Monitor/containers/ModalReport/types'

export const reportMonitorAPI = async ({
  monitorId,
  ...payload
}: ReportMonitorType) => {
  const endpoint = `reports/monitor/${monitorId}`
  const response = await HTTPClient.post(endpoint, payload)
  return response.data
}

export const useReportMonitor = () =>
  useMutation(['report-monitor'], reportMonitorAPI)
