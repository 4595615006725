import { Alert, Box } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { EditBucketSearchContext } from '../../context'

export function FormAlert() {
  const form = useContextSelector(EditBucketSearchContext, (s) => s.form)

  if (form?.errors && Object.keys(form?.errors || {}).length > 0) {
    return (
      <Box sx={{ mb: 3 }}>
        <Alert severity="error">
          Revise os campos do formulário para salvar a pesquisa
        </Alert>
      </Box>
    )
  }

  return <div />
}
