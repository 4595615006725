import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { TagEntity } from '@services/nomos_api/entities/tag'

export const createTagApi = async (
  body: Pick<TagEntity, 'name' | 'color'>
): Promise<TagEntity[]> => {
  const { data } = await HTTPClient.post(`tags`, body)
  return data
}

export const useCreateTags = () => {
  return useMutation(
    ['create-tag'],
    (body: Pick<TagEntity, 'name' | 'color'>) => createTagApi(body)
  )
}
