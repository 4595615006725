import { useGetSummary } from '@services/nomos_api/resources/dashboard/summary'
import Monitors from './Monitors'
import SavedSearches from './SavedSearches'
import { Stack } from '@mui/material'

export default function Dashboard() {
  const { data, isFetching } = useGetSummary()

  return (
    <Stack spacing={3}>
      <SavedSearches saved={data?.saved_search} isLoading={isFetching} />
      <Monitors monitors={data?.monitors} isLoading={isFetching} />
    </Stack>
  )
}
