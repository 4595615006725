import { SignInForm } from './FormSigninEmail.styles'
import { Stack, Typography } from '@mui/material'
import { Button, Input, TextLink } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SigninEmailContext } from '../../context'

export function FormSignEmail(): JSX.Element {
  const formik = useContextSelector(SigninEmailContext, (s) => s.formik)
  const isLoading = useContextSelector(SigninEmailContext, (s) => s.isLoading)
  const error = useContextSelector(SigninEmailContext, (s) => s.error)

  return (
    <SignInForm>
      <Stack direction="column" spacing={2} alignItems="center">
        <Typography
          textAlign="center"
          variant="body2"
          color="$color-text-primary"
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            lineHeight: '1.5',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          Acessar conta
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <Typography
          variant="body2"
          color="$color-text-primary"
          sx={{ fontWeight: 'normal', lineHeight: '1.5' }}
        >
          E-mail corporativo
        </Typography>
        <Input
          label="E-mail"
          type="email"
          name="email"
          size="small"
          sx={{ mb: 1 }}
          error={
            (formik.touched.email && Boolean(formik.errors.email)) ||
            (!isLoading && error)
          }
          helpertext={
            (formik.touched.email && formik.errors.email) ||
            error?.response?.data?.message
          }
          disabled={isLoading}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Typography
          variant="body2"
          color="$color-text-primary"
          sx={{ fontWeight: 'normal', lineHeight: '1.5', mt: 20 }}
        >
          Senha de acesso
        </Typography>
        <Input
          label="Senha"
          type="password"
          name="password"
          size="small"
          error={
            (formik.touched.password && Boolean(formik.errors.password)) ||
            (!isLoading && error)
          }
          helpertext={
            (formik.touched.password && formik.errors.password) ||
            error?.response?.data?.message
          }
          disabled={isLoading}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </Stack>
      <Button
        type="submit"
        text="Entrar"
        size="large"
        loading={formik.isSubmitting}
        disabled={!(formik.isValid && formik.dirty)}
        onClick={() => formik.handleSubmit()}
        sx={{ width: '260px' }}
      />
      <TextLink
        href="/forgot-password"
        text="Esqueci minha senha"
        underline="none"
      />
    </SignInForm>
  )
}
