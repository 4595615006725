/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import * as yup from 'yup'
import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '@contexts/Auth'
import { MyAccountContext } from './context'
import { useSnackbar } from '@contexts/Snackbar'
import {
  nameSurname,
  phone,
  password,
  passwordConfirmation,
} from '@utils/forms/validation'
import { updateUser } from '@services/nomos_api/resources/user/update'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { ConfirmPhoneModal } from './containers/ConfirmPhoneModal'
import { useModal } from '@contexts/Modal'

export type UsersProps = {
  children: React.ReactNode
}

export function MyAccountController({ children }: UsersProps) {
  const { user, updateUserData } = useAuth()
  const { openModal, cleanStack } = useModal()
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()

  const [selectedMenuOption, setSelectedMenuOption] = useState('option1')
  const [slim, setSlim] = useState<boolean>(
    () => typeof window !== 'undefined' && window.innerWidth < 1200
  )

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    window.addEventListener('resize', () => {
      setSlim(window.innerWidth < 1200)
    })
  }, [])

  const validationSchema = yup.object().shape({
    name: nameSurname,
    phone,
    password: password.notRequired(),
    password_confirmation: passwordConfirmation.notRequired(),
  })

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      admin: user?.admin || false,
      phone: user?.phone || '',
      password: undefined,
      password_confirmation: undefined,
    },
    validationSchema,
    onSubmit: (values: any) => {
      const updatedValues = { ...values }
      // do not send phone to request if it was not updated
      if (updatedValues.phone === user?.phone) _.unset(updatedValues, 'phone')

      if (!updatedValues.password) _.unset(updatedValues, 'password')
      if (!updatedValues.password_confirmation)
        _.unset(updatedValues, 'password_confirmation')

      // update user with provided form data
      updateUser(updatedValues)
        .then(({ user, token_confirmation }) => {
          if (user.name || user.email) {
            updateUserData({ name: user.name, email: user.email })
          }
          if (token_confirmation) {
            openModal(
              <ConfirmPhoneModal
                token={token_confirmation}
                onUpdatedUser={(user) => {
                  cleanStack()
                  updateUserData({ phone: user.phone })
                  showSnackbarSuccess('Telefone confirmado com sucesso!')
                }}
              />,
              {
                lockscroll: true,
                lockclose: true,
              }
            )
          }
          showSnackbarSuccess('Dados atualizados com sucesso!')
        })
        .catch((error) => {
          const e = error as AxiosErrorResponse
          showSnackbarError(
            'Não foi possível atualizar os seus dados',
            e?.response?.data?.message ||
              'Verifique todos os campos e tente novamente'
          )
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
  })

  const store = useMemo(
    () => ({
      formik,
      selectedMenuOption,
      setSelectedMenuOption,
      slim,
    }),
    [formik, selectedMenuOption, slim]
  )

  return (
    <MyAccountContext.Provider value={store}>
      {children}
    </MyAccountContext.Provider>
  )
}
