import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 800px;
  height: 710px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Footer = styled.div`
  display: flex;
  padding: 32px 32px 32px 686px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0px 0px 2px 2px;
  background: var(--fundo-color-background-primary, #f9fafc);
`
