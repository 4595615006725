import { OrganEntity } from '@services/nomos_api/entities/organ'
import { createContext } from 'use-context-selector'

type OrganDetailContextType = {
  organ?: OrganEntity
  isLoading: boolean
}

export const OrganDetailContext = createContext<OrganDetailContextType>(
  {} as OrganDetailContextType
)
