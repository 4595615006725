import { MonitorsContext } from './context'
import React, { useCallback, useMemo, useState } from 'react'
import { useListMonitors } from '@services/nomos_api/resources/monitor/list'

type MonitorsControllerProps = {
  children: React.ReactNode
}

export default function MonitorsController({
  children,
}: MonitorsControllerProps) {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(6)
  const [type, setType] = useState('mine')
  const [textSearch, setTextSearch] = useState('')

  const handleTextChange = useCallback((text: string) => {
    setPage(1)
    setTextSearch(text)
  }, [])

  const handleTypeChange = useCallback((type: string) => {
    setPage(1)
    setType(type)
  }, [])

  const { data, isFetching, refetch } = useListMonitors({
    page,
    limit,
    type,
    q: textSearch,
  })

  const handleRefetchMonitors = useCallback(() => {
    refetch()
  }, [])

  const store = useMemo(
    () => ({
      isLoading: isFetching,
      monitors: data,
      type,
      setPage,
      setLimit,
      handleTypeChange,
      handleTextChange,
      handleRefetchMonitors,
    }),
    [
      isFetching,
      data,
      type,
      setPage,
      setLimit,
      handleTypeChange,
      handleTextChange,
      handleRefetchMonitors,
    ]
  )

  return (
    <MonitorsContext.Provider value={store}>
      {children}
    </MonitorsContext.Provider>
  )
}
