import { SVGProps } from 'react'

function DividerIcon({
  width = 1,
  height = 102,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 1 102" fill={fill}>
      <line
        x1="0.5"
        y1="-1.75534e-08"
        x2="0.500006"
        y2="102"
        stroke="#E5E5F1"
      />
    </svg>
  )
}

export default DividerIcon
