import HTTPClient from '../..'
import moment from 'moment-timezone'
import { searchEvent } from '@utils/metrics'
import { useMutation, useQuery } from '@tanstack/react-query'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'

export const searchEventsAPI = async (props) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=1000`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort || 'newest'}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(
    `/search/events?${params.join('&')}`,
    {...props.filter, keywords: removeEmptyArrays(props.keywords) }
  )
  return data
}

export const searchScheduleAPI = async (filter, sort) => {
  if(!filter.stakeholders) return { results: [] }
  const { data } = await HTTPClient.post(`/search/events?sort=${sort}`, { ...filter })
  return data
}

export const searchCloseEventsAPI = async (organId) => {
  const { data } = await HTTPClient.post(`/search/events?sort=older`, { 
    organs: [organId], 
    date: {
      from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      to: moment().add(7, 'days').format('YYYY-MM-DD'),
    } 
  })
  return data
}

export const useSchedule = (filter, sort= 'newest') =>
  useQuery(['search-schedule', filter, sort], ()=> searchScheduleAPI(filter, sort), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

export const useCloseEvents = (filter) =>
  useQuery(['search-close-events', filter], ()=> searchCloseEventsAPI(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

export const useSearchEventsMutation = () =>
  useMutation(['search-events-mutation'], searchEventsAPI)
