import { Input } from '@components/atoms'
import { useEffect, useState } from 'react'
import { MonitorsContext } from '../../context'
import { useDebounce } from '@hooks/useDebounce'
import { TabSelector } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'

import { HeaderContent } from './Header.styles'

export default function Header() {
  const [textSearch, setTextSearch] = useState('')
  const debounceTextSearch = useDebounce(textSearch, 500)

  const handleTextChange = useContextSelector(
    MonitorsContext,
    (s) => s.handleTextChange
  )

  const type = useContextSelector(MonitorsContext, (s) => s.type)

  const handleTypeChange = useContextSelector(
    MonitorsContext,
    (s) => s.handleTypeChange
  )

  useEffect(() => {
    handleTextChange(debounceTextSearch)
  }, [debounceTextSearch])

  return (
    <HeaderContent>
      <TabSelector
        tabs={[
          { value: 'mine', label: 'Meus Painéis' },
          { value: 'public', label: 'Painéis Publicos' },
          { value: 'suggested', label: 'Painéis Sugeridos' },
        ]}
        initialTab={type}
        onChangeTab={handleTypeChange}
      />

      <Input
        size="small"
        type="search"
        id="panel-search"
        label="Pesquisar Painel"
        value={textSearch}
        sx={{ width: '384px', maxWidth: '100%' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTextSearch(e.target.value)
        }
      />
    </HeaderContent>
  )
}
