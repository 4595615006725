/* eslint-disable no-unused-vars, no-restricted-syntax, @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import produce from 'immer'
import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import { useDrawer } from '@contexts/Drawer'
import { useSnackbar } from '@contexts/Snackbar'
import { EditBucketSearchContext } from './context'
import React, { useState, useMemo, useCallback } from 'react'
import { buckets, createBlankBucketFilter } from '@utils/buckets'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { patchMonitorSearchAPI } from '@services/nomos_api/resources/monitor_search/patch'
import { deleteSavedSearchesBucketAPI } from '@services/nomos_api/resources/monitor_search/delete-saved-searches-bucket'
import { BucketsEnum } from '@utils/buckets/type'

export type EditBucketSearchControllerProps = {
  id: string
  monitorId: string
  selectedBucket: BucketsEnum
  search: SavedSearchEntity
  children: React.ReactChild
  refetchBuckets: () => void
  onSuccess: (response: AxiosResponse) => void
}

export function EditBucketSearchController({
  id,
  monitorId,
  search: savedSearch,
  children,
  onSuccess,
  selectedBucket,
  refetchBuckets,
}: EditBucketSearchControllerProps) {
  const { closeDrawer } = useDrawer()
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()
  const [isLoading, setLoading] = useState(false)

  const formattedFilter = useMemo(() => {
    const defaultFilter = {}

    savedSearch.searches.forEach((item) => {
      const { bucket, filter } = item
      _.set(defaultFilter, `buckets.${bucket}`, createBlankBucketFilter(filter))
    })

    return defaultFilter
  }, [savedSearch])

  const [bucket] = useState(selectedBucket)
  const [search, setSearch] = useState<any>(formattedFilter)

  const bucketItems = useMemo(() => {
    return buckets[bucket]
  }, [bucket])

  const handleUpdateSearch = useCallback(
    (object) => {
      const updatedSearch = produce(search, (draft: any) => {
        for (const key of Object.keys(object)) {
          _.set(draft, key, _.get(object, key))
        }
      })
      setSearch(updatedSearch)
    },
    [search, selectedBucket]
  )

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: search,
    onSubmit: async () => {
      try {
        setLoading(true)

        const payload = {
          id,
          monitorId,
          buckets: {} as any,
        }

        payload.buckets[selectedBucket] = search.buckets[selectedBucket].filter

        const response = await patchMonitorSearchAPI(payload)
        showSnackbarSuccess('Pesquisa atualizada com sucesso')
        closeDrawer()
        if (onSuccess) {
          onSuccess(response)
        }
      } catch (error: any) {
        showSnackbarError(
          error?.response?.data?.message ||
            'Erro ao tentar atualizar os filtros da pesquisa salva'
        )
      } finally {
        setLoading(false)
        setTimeout(closeSnackbar, 3000)
      }
    },
  })

  const handleSubmit = React.useCallback(() => {
    form.handleSubmit()
  }, [form])

  const handleDeleteBucket = () => {
    setLoading(true)

    deleteSavedSearchesBucketAPI({ monitorId, id, bucket })
      .then(() => {
        showSnackbarSuccess('Categoria removida com sucesso')
        closeDrawer()
        refetchBuckets()
      })
      .catch((error) => {
        showSnackbarError(
          error?.response?.data?.message ||
            'Erro ao tentar remover a categoria da pesquisa salva'
        )
        closeDrawer()
      })
      .finally(() => {
        setLoading(false)
        setTimeout(closeSnackbar, 3000)
      })
  }

  const store = useMemo(
    () => ({
      form,
      isLoading,
      search,
      bucket,
      selectedBucket,
      bucketItems,
      handleSubmit,
      handleUpdateSearch,
      handleDeleteBucket,
    }),
    [
      form,
      isLoading,
      search,
      bucket,
      selectedBucket,
      bucketItems,
      handleSubmit,
      handleUpdateSearch,
      handleDeleteBucket,
    ]
  )

  return (
    <EditBucketSearchContext.Provider value={store}>
      {children}
    </EditBucketSearchContext.Provider>
  )
}
