/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filter } from '@utils/buckets/type'
import { createContext } from 'use-context-selector'
import { GetAllSavedEventsProps } from '@services/nomos_api/resources/saved_event/getAll'

export type ScheduleContextProps = {
  events: any[]
  isLoading: boolean
  handleUpdateFilters: (filters: GetAllSavedEventsProps | Filter) => void
  handleUpdateSchedule: (filters: any) => void
  handleUpdateCalendar: (filters: Record<string, string>) => void
}

export const ScheduleContext = createContext<ScheduleContextProps>(
  {} as ScheduleContextProps
)
