import HTTPClient from '../..'
import { activateEvent } from '@utils/metrics'
import { useQuery } from '@tanstack/react-query'
import { UserAuth } from '@services/nomos_api/entities/user'

export const activeAccountAPI = async (token: string) => {
  const { data } = await HTTPClient.get(`activate/${token}`)
  activateEvent()
  return data as UserAuth
}

export const useActivateUserAccount = (token: string) =>
  useQuery(['activate-account'], () => activeAccountAPI(token), {
    retry: 0,
    refetchOnWindowFocus: false,
  })
