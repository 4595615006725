import { BodyContainerFill } from '../styles'
import { CircularProgress, Stack, Typography } from '@mui/material'

export function NoteContentLoading(): JSX.Element {
  return (
    <BodyContainerFill>
      <Stack direction="row" spacing={1} alignItems="center">
        <CircularProgress size={14} />
        <Typography variant="$font-body-2" color="$color-text-secondary">
          Carregando notas...
        </Typography>
      </Stack>
    </BodyContainerFill>
  )
}
