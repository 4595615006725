import { IconButton, Typography } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { FormAlert } from './containers/FormAlert'
import { StepSelector } from './containers/StepSelector'
import { Container, Header, IconClose, ContentBody } from './styles'

export function EditSavedSearchView() {
  const { closeDrawer } = useDrawer()
  return (
    <Container>
      <Header>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Editar pesquisa
        </Typography>
        <IconButton onClick={closeDrawer}>
          <IconClose />
        </IconButton>
      </Header>
      <FormAlert />
      <ContentBody>
        <StepSelector />
      </ContentBody>
    </Container>
  )
}
