import HTTPClient from '../..'

type Props = {
  file: File
}

export const importMonitor = async (props: Props) => {
  const endpoint = `monitor/import`
  const response = await HTTPClient.post(
    endpoint,
    { document: props.file },
    { responseType: 'blob' }
  )
  return response.data
}
