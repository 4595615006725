import { StepContainer } from '../../styles'
import { FormNotification } from '../notification'
import { FormAlertMsg } from '../alert-msg'
import { FormPropositionStatus } from '../proposition-situation-status'
import { FormNotificationChannel } from '../notification-channel'
import { FormNotificationFrequency } from '../notification-frequency'
import { useContextSelector } from 'use-context-selector'
import { Button, ContainerScroll } from '@components/atoms'
import { PropositionStatusNotificationContext } from '../../context'
import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'

export function ManageContent() {
  // select context data/actions from controller
  const setEnabledNotification = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.setEnabledNotification
  )
  const disableDirty = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.disableDirty
  )
  const formSavedBucket = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.formSavedBucket
  )
  const monitors = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.monitors
  )
  const handleSubmit = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.handleSubmit
  )
  const enabledNotification = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.enabledNotification
  )
  const isSavingBucket = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.isSavingBucket
  )

  // define local state controls
  const [isLoading, setIsLoading] = useState(false)
  const [isEnabledNotification, setIsEnabledNotification] = useState(
    enabledNotification === true
  )

  // define side effects to trigger outer functions
  useEffect(() => {
    setIsLoading(isSavingBucket)
  }, [isSavingBucket])
  useEffect(() => {
    setEnabledNotification(isEnabledNotification)
  }, [isEnabledNotification])

  return (
    <StepContainer data-testid="proposition-status-notification">
      <FormNotification
        onToggle={(active) => {
          setIsEnabledNotification(active)
        }}
      />
      <Stack
        direction="column"
        py={2}
        flex={1}
        sx={{ height: '100%', overflow: 'hidden' }}
      >
        <FormAlertMsg disabled={!isEnabledNotification} />
        <ContainerScroll
          spacing={24}
          disabled={String(!isEnabledNotification) as 'true' | 'false'}
          data-cy="form-proposition-status-notification"
        >
          <FormPropositionStatus disabled={!isEnabledNotification} />
          <FormNotificationChannel disabled={!isEnabledNotification} />
          <FormNotificationFrequency disabled={!isEnabledNotification} />
        </ContainerScroll>
      </Stack>
      <Button
        text="Salvar"
        size="large"
        variant="contained"
        sx={{ p: '12px 0', mt: 1 }}
        id="go-to-next-step"
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          handleSubmit({ monitors }).finally(() => setIsLoading(false))
        }}
        disabled={
          !(formSavedBucket.isValid && (disableDirty || formSavedBucket.dirty))
        }
      />
    </StepContainer>
  )
}
