import styled from 'styled-components'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 3;
  justify-content: space-between;
`

export const LeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`
