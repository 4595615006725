import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Resource } from '@services/nomos_api/entities'

export const getAllResourcesAPI = async (): Promise<Resource[]> => {
  const { data } = await HTTPClient.get('/resources')
  return (data?.results || []) as Resource[]
}

export const useResourcesList = () =>
  useQuery(['search-count'], () => getAllResourcesAPI())
