import { WhatsAppPaymentLinkController } from './controller'
import { WhatsAppPaymentLinkProps } from './types'
import { WhatsAppPaymentLinkView } from './view'

export function WhatsAppPaymentLink(
  props: WhatsAppPaymentLinkProps
): JSX.Element {
  return (
    <WhatsAppPaymentLinkController {...props}>
      <WhatsAppPaymentLinkView {...props} />
    </WhatsAppPaymentLinkController>
  )
}
