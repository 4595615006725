type Props = {
  page?: number
  limit?: number
  total?: number
}
const pageview = ({ page = 0, limit = 0, total = 0 }: Props) => {
  const from = (page - 1) * limit + 1
  const to = (page - 1) * limit + limit
  return `${from} - ${to} de ${total}`
}

export { pageview }
