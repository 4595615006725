import { styled, Box, BoxProps } from '@mui/material'
import colors from '@theme/colors'

export interface PropositionStatusProps extends BoxProps {
  isApproved: boolean
}

export const PropositionStatus = styled(Box)(
  ({ isApproved }: PropositionStatusProps) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    fontWeight: 600,
    color: `${isApproved ? '#0BB07B' : '#F03D3D'};`,
  })
)

export const AccordionContentItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderBottom: '1px solid #E7E9ED',
  padding: '24px 0px',
  '&:last-child': {
    borderBottom: '0px',
  },
}))

export const ChipMonitors = styled(Box)`
  gap: 6px;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  background-color: ${colors['$color-extended-green4']};
`
