import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import { useModal } from '@contexts/Modal'
import { BackgroundIcon } from '../styles'
import { Button } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { ImportPropositionModalContext } from '../context'
import { LoadingButton } from '@mui/lab'
import { ErrorOutlineOutlined } from '@mui/icons-material'

export function StateModalError(): JSX.Element {
  const { closeModal } = useModal()

  const file = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.file
  ) as File

  const [numSuccess, numErrors] = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.affectedRows as number[]
  )

  const handleResetForm = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.handleResetForm
  )

  const messages = [
    `Um erro ocorreu ao tentar importar o arquivo ${file.name}..`,
  ]
  if (numSuccess === 1) {
    messages.push(`Apenas uma linha do arquivo foi importada com sucesso`)
  } else if (numSuccess > 1) {
    messages.push(
      `Apenas ${numSuccess} linhas do arquivo foram importadas com sucesso`
    )
  }
  messages.push(
    `${numErrors} linhas do arquivo não correspondem ao formato esperado.`
  )

  return (
    <>
      <DialogTitle sx={{ p: 3 }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="start"
        >
          <BackgroundIcon backgroundColor="#F03D3D20">
            <ErrorOutlineOutlined color="error" />
          </BackgroundIcon>
          <Typography variant="$font-title-4" color="$color-text-primary">
            Erro ao importar proposição
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography variant="$font-title-5" color="$color-text-primary">
            {messages.join('. ')}
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Um arquivo XLS foi gerado com as {numErrors} linhas que contém erro
            para que sejam corrigidas e importadas novamente.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
          <LoadingButton
            onClick={handleResetForm}
            variant="contained"
            color="primary"
          >
            Importar correção
          </LoadingButton>
        </Stack>
      </DialogActions>
    </>
  )
}
