import { useMutation } from '@tanstack/react-query'

import HTTPClient from '../..'

export const updateMonitorSearchAPI = async (data) => {
  const { data: responseData } = await HTTPClient.put(
    `/monitor/${data.monitorId}/searches/${data.id}`,
    data
  )

  return responseData
}

export const useUpdateMonitorSearch = () =>
  useMutation(['update-monitor-search'], updateMonitorSearchAPI)
