import React from 'react'
import FileSaver from 'file-saver'
import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'
import { ImportPropositionModalContext } from './context'
import { ImportpropositionModalControllerProps } from './types'
import { importPropositions } from '@services/nomos_api/resources/monitor/import-propositions'
import { importMonitor } from '@services/nomos_api/resources/monitor/import-monitor'
import _ from 'lodash'
import { AxiosError } from 'axios'

export function ImportPropositionModalController({
  children,
  ...props
}: ImportpropositionModalControllerProps): JSX.Element {
  const [submiting, setSubmiting] = React.useState<boolean>(false)
  const [affectedRows, setAffectedRows] = React.useState<number[] | undefined>(
    undefined
  )
  const [file, setFile] = React.useState<File | undefined>(undefined)

  const { closeModal } = useModal()
  const { showSnackbarSuccess, showSnackbarWarning } = useSnackbar()

  const handleErrorImportDocument = (error: AxiosError) => {
    // show warning message
    showSnackbarWarning('Importação concluída com revisões')
    // specify number of errors on document
    setAffectedRows([
      Number.parseInt(
        String(_.get(error.response?.headers, 'x-nomos-success-count', 0)),
        10
      ),
      Number.parseInt(
        String(_.get(error.response?.headers, 'x-nomos-review-count', 0)),
        10
      ),
    ])
    // build export filename
    const filename = _.get(
      error?.response?.headers,
      'content-disposition',
      '[REVISÃO]_unknown.xlsx'
    ).split('filename=')[1]

    // save exported review on local machine
    FileSaver.saveAs(
      error.response?.data as Blob,
      filename ? filename.replace(/^"(.+)"$/, '$1') : '[REVISÃO]_unknown.xlsx'
    )
    if (props.onImportFinished) props.onImportFinished()
  }

  const handleUploadedDocument = () => {
    closeModal()
    showSnackbarSuccess('Importação concluída')
    if (props.onImportFinished) props.onImportFinished()
  }

  const handleSubmitFile = () => {
    if (!file) return
    setAffectedRows(undefined)
    setSubmiting(true)

    if (props.monitorId) {
      // import proposition on
      // monitor if monitorId
      // is provided throught params
      importPropositions({ file, monitorId: props.monitorId })
        .then(() => handleUploadedDocument())
        .catch((error) => handleErrorImportDocument(error))
        .finally(() => setSubmiting(false))
    } else {
      // import propositions and
      // create monitor if no monitor
      // is provided throught params
      importMonitor({ file })
        .then(() => handleUploadedDocument())
        .catch((error) => handleErrorImportDocument(error))
        .finally(() => setSubmiting(false))
    }
  }

  const handleImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e?.target?.files?.[0])
  }

  const modelLink = props.monitorId
    ? '/uploads/modelo-importacao-proposicao.xlsx'
    : '/uploads/modelo-importacao-painel.xlsx'

  const handleResetForm = () => {
    setAffectedRows(undefined)
    setFile(undefined)
  }

  const state = React.useMemo(
    () => ({
      file,
      setFile,
      affectedRows,
      submiting,
      modelLink,
      handleResetForm,
      handleSubmitFile,
    }),
    [file, submiting, handleSubmitFile, handleImportFile]
  )

  return (
    <ImportPropositionModalContext.Provider value={state}>
      {children}
    </ImportPropositionModalContext.Provider>
  )
}
