import { PageContainer } from './styles'
import StakeholdersList from './components/StakeholdersList'
import StakeholdersFilter from './components/StakeholdersFilter'

function StakeholdersView() {
  return (
    <PageContainer>
      <StakeholdersFilter />
      <StakeholdersList />
    </PageContainer>
  )
}

export default StakeholdersView
