import { useModal } from '@contexts/Modal'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { Container, Content, Header } from './ModalStandard.styles'

type Props = {
  id?: string
  title: string
  children: JSX.Element
  closeable?: boolean | undefined
  maxWidth?: number
}

function ModalStandard({
  id,
  title,
  children,
  closeable = true,
  maxWidth,
}: Props): JSX.Element {
  const { closeModal } = useModal()
  return (
    <Container sx={{ maxWidth }} data-cy={id || 'modal-standard'}>
      <Header>
        <Typography variant="$font-title-3" color="$color-text-primary">
          {title}
        </Typography>
        {closeable === true && (
          <IconButton onClick={() => closeModal()} size="small">
            <Close fontSize="small" />
          </IconButton>
        )}
      </Header>
      <Content>{children}</Content>
    </Container>
  )
}

ModalStandard.defaultProps = {
  closeable: undefined,
}

export default ModalStandard
