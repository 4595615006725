import { useParams } from 'react-router-dom'
import { SecondaryPage } from '@components/templates'
import OrgansList from '@containers/OrgansList'

export function OrgansPanel() {
  const { id } = useParams()

  return (
    <SecondaryPage
      id="organs-monitor-page"
      title="Órgãos Monitorados"
      description="Veja abaixo a lista de órgãos que estão sendo monitorados"
    >
      <OrgansList monitorId={id} />
    </SecondaryPage>
  )
}
