/* eslint-disable no-restricted-syntax */
import colors from '@theme/colors'
import { useModal } from '@contexts/Modal'
import { useCallback, useMemo } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { Badge, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import SavedSearch from '@containers/SharedDrawers/SaveSearch'
import EqualizerIcon from '@components/icons/Equalizer/Equalizer'
import { SearchHelperContainer } from './FormInputKeyword.styles'
import { Button, InputAdvancedSearch, Tooltip } from '@components/atoms'
import { AdvancedSearchModal, ConfirmModal } from '@components/organisms'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import BellNotificationIconComponent from '@components/icons/BellNotification/BellNotification'
import { AdvancedSearchOutputType } from '@components/organisms/AdvancedSearchModal/AdvancedSearchModal.types'

function FormInputKeywordComponent() {
  const { openModal } = useModal()
  const { openDrawer } = useDrawer()
  const search = useContextSelector(SearchResultsContext, (s) => s.search)

  const modes = useContextSelector(SearchResultsContext, (s) => s.modes)
  const buckets = useContextSelector(SearchResultsContext, (s) => s.buckets)
  const keywords = useContextSelector(SearchResultsContext, (s) => s.keywords)
  const saving = useContextSelector(SearchResultsContext, (s) => s.saving)

  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )

  const toggleFilter = useContextSelector(
    SearchResultsContext,
    (s) => s.toggleFilter
  )

  const handleMountBucketState = useContextSelector(
    SearchResultsContext,
    (s) => s.handleMountBucketState
  )

  const filterVisible = useContextSelector(
    SearchResultsContext,
    (s) => s.filterVisible
  )

  const filterCount = useMemo(() => {
    return Object.values(
      search?.buckets?.[selectedBucket]?.filter || {}
    ).reduce((counter: number, filter) => {
      return counter + (Object.values(filter || {}).length > 0 ? 1 : 0)
    }, 0)
  }, [search, buckets])

  const executeUpdateSearch = useCallback(
    (advanced: AdvancedSearchOutputType) => {
      // update search state
      handleUpdateSearch({
        buckets: handleMountBucketState(advanced.buckets),
        keywords: advanced.keywords,
        modes: advanced.modes,
      })
    },
    [search]
  )

  const handleUpdateSavedSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSavedSearch
  )

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="flex-start" gap={2} flexWrap="wrap">
        {!filterVisible && (
          <Button
            variant="outlined"
            size="large"
            id="toggle-filters"
            onClick={toggleFilter}
            sx={{ order: { xs: 2, md: 1 } }}
            startIcon={
              <EqualizerIcon color={colors['$color-action-text-secondary']} />
            }
            endIcon={
              filterCount ? (
                <Badge
                  badgeContent={filterCount}
                  color="primary"
                  sx={{ ml: 2, mr: 1 }}
                />
              ) : undefined
            }
          />
        )}

        <InputAdvancedSearch
          value={keywords}
          size="small"
          placeholder="Digite a palavra que deseja buscar"
          onChangeSearch={(k) => handleUpdateSearch({ keywords: k.keywords })}
          helper="Clique ENTER para inserir mais de uma palavra chave"
          sx={{
            order: { xs: 1, md: 2 },
            flex: { xs: 'unset', md: 1 },
            width: '100%',
          }}
          action={{
            label: 'Pesquisa Avançada',
            onClick: () => {
              openModal(
                <AdvancedSearchModal
                  initialForm={{
                    buckets,
                    keywords,
                    modes,
                  }}
                  onSaveOptions={executeUpdateSearch}
                />,
                { scroll: 'body' }
              )
            },
          }}
        />

        <SearchHelperContainer sx={{ order: 3, marginTop: 0 }}>
          {search.id && (
            <Button
              text="Atualizar Pesquisa"
              size="large"
              variant="outlined"
              loading={saving}
              endIcon={
                <BellNotificationIconComponent
                  color={
                    saving
                      ? colors['$color-border-tertiary']
                      : colors['$color-action-text-secondary']
                  }
                />
              }
              onClick={() =>
                openModal(
                  <ConfirmModal
                    title="Atualizar pesquisa?"
                    info="Ao realizar esta ação só será possível reverte-la editando novamente a pesquisa"
                    confirmText="Você tem certeza que deseja atualizar a pesquisa?"
                    actionText="Atualizar"
                    onConfirm={() => handleUpdateSavedSearch()}
                  />
                )
              }
            />
          )}

          {!search.id && (
            <Tooltip
              title={
                <Stack direction="column" spacing={1}>
                  <Typography variant="caption" color="$color-text-primary">
                    Esta ação permite retornar rápidamente a uma pesquisa
                    realizada anteriormente. Além disso, você pode optar por ser
                    notificado quanto às novas proposições, agendas, discuros e
                    atos cadastrados na plataforma que possuirem os mesmos
                    parâmetros que os filtros definidos na pesquisa salva.
                  </Typography>
                  <Typography variant="caption" color="$color-text-primary">
                    Antes salvar a pesquisa certifique-se de que cada categoria
                    de dados, estão com os filtros préviamente definidos.
                  </Typography>
                </Stack>
              }
            >
              <Button
                text="Salvar Pesquisa"
                variant="outlined"
                size="large"
                id="create-saved-search-button"
                sx={{ order: 3 }}
                onClick={() => {
                  const savedSearch = <SavedSearch search={search} />

                  openDrawer(savedSearch)
                }}
                endIcon={
                  <BellNotificationIconComponent
                    color={colors['$color-action-text-secondary']}
                  />
                }
              />
            </Tooltip>
          )}
        </SearchHelperContainer>
      </Stack>
    </Stack>
  )
}

export default FormInputKeywordComponent
