import HTTPClient from '../..'
import { saveSearchEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'

export const createMonitorSearchAPI = async (body) => {
  saveSearchEvent()
  const { data } = await HTTPClient.post('/monitors/searches', body)
  return data
}

export const useCreateMonitorSearch = () =>
  useMutation(['create-monitor-search'], createMonitorSearchAPI)
