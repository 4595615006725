import { EditUserContext } from './context'
import UserInfo from '@containers/UserUpsert'
import { CrumbedPage } from '@components/templates'
import { useContextSelector } from 'use-context-selector'
import { Shimmer } from '@components/atoms'

export function EditUserView() {
  const user = useContextSelector(EditUserContext, (s) => s.user)
  const isLoading = useContextSelector(EditUserContext, (s) => s.isLoading)

  return (
    <CrumbedPage
      id="edit-user-page"
      title={user?.name || 'Editar usuário'}
      description=""
      paths={[
        {
          name: 'Início',
          location: '/',
        },
        {
          name: 'Usuários',
          location: '/users',
        },
        {
          name: user?.name || 'Usuário',
          location: `/users/${user?.id}`,
        },
        {
          name: 'Editar',
          location: `/users/${user?.id}/edit`,
        },
      ]}
    >
      <>
        <Shimmer width="100%" height="80em" isLoading={isLoading} />
        {!isLoading && <UserInfo user={user} />}
      </>
    </CrumbedPage>
  )
}
