import { SavedSearchesListView } from './view'
import SavedSearchesListController from './controller'

export type SavedSearchesListProps = {
  monitorId?: string
}

export function SavedSearches(props: SavedSearchesListProps) {
  return (
    <SavedSearchesListController {...props}>
      <SavedSearchesListView />
    </SavedSearchesListController>
  )
}

SavedSearches.defaultProps = {
  monitorId: null,
}
