import styled from 'styled-components'
import {
  styled as styledMaterial,
  Card as MUICard,
  CardContent as MUICardContent,
  Popover,
} from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 0;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 38px;

  width: 100%;
  gap: 24px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const Card = styledMaterial(MUICard)(() => ({
  border: 0,
  backgroundColor: '#fff',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: '#fff !important',
  },
}))

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
`

export const CardTitle = styled.div`
  margin-top: 10px;
  svg: { color: '#4070F4' },
`

export const CardFilters = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const CardContent = styledMaterial(MUICardContent)(({ theme }) => ({
  padding: '0px',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '32px',
  [theme.breakpoints.up('md')]: {
    padding: '0 20px',
  },
}))

export const TagPopover = styledMaterial(Popover)`
  max-height: 600px !important;
  display: flex !important;
  & .MuiPopover-paper {
    box-shadow: #0B1F4D05 0px 2px 2px, #00000014 0px 2px 12px;
    display: flex !important;
    flex: 1 !important;
    overflow: hidden !important;
    max-width: 400px !important;
  }
  & .sized-component-class {
    flex: 1 !important;
    display: flex !important;
    overflow: hidden !important;
  }
`
