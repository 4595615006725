import { Bucket, BucketsEnum } from './type'

const BucketStakeholders: Bucket = {
  key: BucketsEnum.stakeholders,
  name: 'Stakeholders',
  mode: 'monitor',
  sorts: [],
  periods: [],
  filters: [],
}

export default BucketStakeholders
