import { useAlerts } from '@hooks/useAlerts'
import { useDrawer } from '@contexts/Drawer'
import { Badge, IconButton } from '@mui/material'
import Notification from '@containers/SharedDrawers/Notification'
import BellNotificationIconComponent from '@components/icons/BellNotification/BellNotification'

export function NotificationBadge() {
  const { openDrawer } = useDrawer()
  const { countTotal } = useAlerts(['count_total'])

  const handleClickNotifications = () => {
    openDrawer(<Notification />)
  }

  return (
    <IconButton color="secondary" onClick={handleClickNotifications}>
      <Badge
        color="error"
        variant="standard"
        overlap="circular"
        badgeContent={countTotal}
      >
        <BellNotificationIconComponent />
      </Badge>
    </IconButton>
  )
}
