/* eslint-disable no-param-reassign */
import { Button } from '@components/atoms'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

import { X } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'
import { useModal } from '@contexts/Modal'
import { PropositionsExportModalContext } from './PropositionsExportModal.context'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from './PropositionsExportModal.styles'
import colors, { ExtendedPaletteColors } from '@theme/colors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export function PropositionsExportModalView(): JSX.Element {
  const { closeModal } = useModal()

  const handleExport = useContextSelector(
    PropositionsExportModalContext,
    (s) => s.handleExport
  )

  const pdf = useContextSelector(PropositionsExportModalContext, (s) => s.pdf)
  const setPdf = useContextSelector(
    PropositionsExportModalContext,
    (s) => s.setPdf
  )

  const xls = useContextSelector(PropositionsExportModalContext, (s) => s.xls)
  const setXls = useContextSelector(
    PropositionsExportModalContext,
    (s) => s.setXls
  )

  const docx = useContextSelector(PropositionsExportModalContext, (s) => s.docx)
  const setDocx = useContextSelector(
    PropositionsExportModalContext,
    (s) => s.setDocx
  )

  return (
    <>
      <ModalHeader>
        <Stack direction="column" spacing={1} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="$font-title-4" color="$color-text-primary">
              Exportar dados selecionados
            </Typography>
            <IconButton size="small" onClick={closeModal}>
              <X size={16} />
            </IconButton>
          </Stack>
          <Stack direction="column">
            <Typography variant="$font-body-base" color="$color-text-secondary">
              Selecione abaixo o formato que deseja exportar os dados
              selecionados
            </Typography>
          </Stack>
        </Stack>
      </ModalHeader>
      <ModalBody
        sx={{
          gap: '16px',
        }}
      >
        <FormGroup
          sx={{
            flexDirection: 'row',
          }}
        >
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: '$font-body-base',
                color: '$color-text-primary',
              },
            }}
            control={
              <Checkbox
                onClick={() => setXls(!xls)}
                checked={xls}
                sx={{
                  '&.MuiCheckbox-root': {
                    color: xls ? '$color-action-border' : colors.scale.gray20,
                  },
                }}
              />
            }
            label=".xls"
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: '$font-body-base',
                color: '$color-text-primary',
              },
            }}
            control={
              <Checkbox
                onClick={() => setPdf(!pdf)}
                checked={pdf}
                sx={{
                  '&.MuiCheckbox-root': {
                    color: pdf ? '$color-action-border' : colors.scale.gray20,
                  },
                }}
              />
            }
            label=".pdf"
          />
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: '$font-body-base',
                color: '$color-text-primary',
              },
            }}
            control={
              <Checkbox
                onClick={() => setDocx(!docx)}
                checked={docx}
                sx={{
                  '&.MuiCheckbox-root': {
                    color: docx ? '$color-action-border' : colors.scale.gray20,
                  },
                }}
              />
            }
            label=".docx"
          />
        </FormGroup>
        <Stack
          direction="row"
          padding="8px"
          gap="8px"
          alignItems="center"
          sx={{
            backgroundColor: ExtendedPaletteColors['$color-extended-yellow5'],
          }}
        >
          <InfoOutlinedIcon
            sx={{
              color: ExtendedPaletteColors['$color-extended-yellow1'],
            }}
          />
          <Typography variant="$font-body-3" color="$color-text-secondary">
            Serão exportados apenas os resultados listados nesta página.
          </Typography>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack direction="row" spacing={2} justifyContent="end" width="100%">
          <Button text="Cancelar" variant="outlined" onClick={closeModal} />
          <Button text="Exportar" onClick={handleExport} />
        </Stack>
      </ModalFooter>
    </>
  )
}
