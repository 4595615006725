import {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  ReactElement,
  useCallback,
} from 'react'

import useScrollBlock from '@hooks/useScrollBlock'
import produce from 'immer'
import styled from '@emotion/styled'

type ModalOptions = {
  scroll?: 'body' | 'paper'
  lockclose?: boolean
  lockscroll?: boolean
}

type ContextType = {
  currentOptions: ModalOptions | undefined
  component: ReactElement | JSX.Element | undefined
  openModal: (component: JSX.Element, options?: ModalOptions) => void
  closeModal: () => void
  cleanStack: () => void
}

const Context = createContext<ContextType>({} as ContextType)

type ModalProviderProps = {
  children: React.ReactNode
}

type ModalComponentStackType = {
  component: JSX.Element
  options?: ModalOptions
}

const DisplayNone = styled.div`
  display: none;
`

const DisplayFlex = styled.div<{ lockscroll: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => (props.lockscroll === true ? 'hidden' : 'scroll')};
`

function ModalProvider({ children }: ModalProviderProps) {
  const [stack, setStack] = useState<ModalComponentStackType[]>([])
  const [blockScroll, allowScroll] = useScrollBlock()

  // <!-- add component on last position of stack -->
  // <!-- following the FILO approach -->
  const pushStack = useCallback(
    (component: JSX.Element, options?: ModalOptions) => {
      setStack(
        produce(stack, (draft) => {
          draft.push({ component, options })
        })
      )
    },
    [setStack, stack]
  )

  // <!-- remove the last item from stack -->
  // <!-- following the FILO approach -->
  const popStack = useCallback(() => {
    setStack(
      produce(stack, (draft) => {
        draft.pop()
      })
    )
  }, [setStack, stack])

  // <!-- expose a function to close all stack modals -->
  const cleanStack = () => setStack([])

  // <!-- expose open modal to be used throughth hooks -->
  const openModal = useCallback(
    (component: JSX.Element, options?: ModalOptions) =>
      pushStack(component, options),
    [pushStack]
  )

  // <!-- expose close modal to be used throughth hooks -->
  const closeModal = useCallback(() => popStack(), [popStack])

  useEffect(() => (stack.length > 0 ? blockScroll() : allowScroll()), [stack])

  const currentOptions = useMemo(() => {
    if (stack.length <= 0) return undefined
    return stack[stack.length - 1].options
  }, [stack])

  // <!-- mount display stack component to be rendered on modal -->
  const component = useMemo(() => {
    // <!-- return undefined component to prevent render modal -->
    // <!-- if does not exists any component on stack -->
    if (stack.length <= 0) return undefined
    // <!-- return stack components, but display only the last one -->
    // * must to envolve the components with a <div>
    // - to prevent component from lost state and
    // - reset the stack component render
    return (
      <>
        {stack.map((stackItem, index) => {
          const stackIndex = `modal-stack-${index}`
          if (index < stack.length - 1) {
            return (
              <DisplayNone key={stackIndex}>{stackItem.component}</DisplayNone>
            )
          }
          return (
            <DisplayFlex
              key={stackIndex}
              lockscroll={stackItem.options?.lockscroll === true}
            >
              {stackItem.component}
            </DisplayFlex>
          )
        })}
      </>
    )
  }, [stack, currentOptions])

  const modalProviderValues = useMemo(
    () => ({ component, currentOptions, openModal, closeModal, cleanStack }),
    [component, currentOptions, openModal, closeModal, cleanStack]
  )

  return (
    <Context.Provider value={modalProviderValues}>{children}</Context.Provider>
  )
}

const useModal = () => {
  const context = useContext(Context)
  return context
}

export { ModalProvider, useModal }
