import { Box, List, FormControlLabel, ListItemText } from '@mui/material'
import { styled as styledMaterial } from '@mui/material/styles'

import theme from '@theme/colors'
import styled from 'styled-components'

export const UsersListWrapper = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  margin: '12px 20px 0',
}))

export const ListScroller = styledMaterial(List)(() => ({
  height: '290px',
  overflow: 'auto',

  '&.MuiButtonBase-root-MuiCheckbox-root': {
    borderRadius: '4px',
    marginLeft: '2px',
    width: '16px',
    height: '16px',
    border: '1px solid #B6BCC9',
    backgroundColor: 'transparent',
  },
}))

export const UserLabel = styledMaterial(ListItemText)(() => ({
  color: theme.brand.black,
  fontWeight: '400',
}))

export const InputCheckboxLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 17px !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    white-space: nowrap !important;
    color: '#B6BCC9',
    borderRadius: 4px;
  }
`
export const BpIcon = styledMaterial('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}))

export const BpCheckedIcon = styledMaterial(BpIcon)({
  backgroundColor: '#4070F4',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4070F4',
  },
})
