import { Container, ContentBody } from './styles'
import { StepSelector } from './containers/StepSelector'

export function SavedSearchView() {
  return (
    <Container>
      <ContentBody>
        <StepSelector />
      </ContentBody>
    </Container>
  )
}
