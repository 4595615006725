/* eslint-disable no-unused-vars, prettier/prettier, @typescript-eslint/no-explicit-any */
import { uid } from 'uid'
import { isEmpty } from 'lodash'
import { useModal } from '@contexts/Modal'
import { useDrawer } from '@contexts/Drawer'
import { NoDataIcon } from '@components/icons'
import { useNavigate } from 'react-router-dom'
import { getBucketName } from '@utils/buckets'
import PencilSVG from '@assets/icons/pencil.svg'
import TrashSVG from '@assets/icons/trash-red.svg'
import { Pagination } from '@components/molecules'
import { SavedSearchesListContext } from './context'
import RemoveSearchModal from '../RemoveSearchModal'
import SearchSVG from '@assets/icons/search-black.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useCallback, useContext, useState } from 'react'
import { KeywordRender, Shimmer } from '@components/atoms'
import { Typography, Menu, MenuItem } from '@mui/material'
import { EditSavedSearch } from '@containers/SharedDrawers'
import { pageview } from '@utils/functions/pageview/pageview'
import { EditBucketSavedSearch } from '@containers/SharedDrawers'
import { formatDate } from '@utils/functions/formatter/formatter'

import {
  Filter,
  SavedSearchEntity,
} from '@services/nomos_api/entities/saved_searches'

import {
  Container,
  Content,
  Footer,
  CardItemRow,
  CardItemColumn,
  Chip,
  Counter,
  ViewMore,
  Tooltip,
  SavedSearchCard,
} from './styles'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'
import { encodeKeywords } from '@utils/functions/keyword/serialize'
import { InlineTagsContainer } from '@containers/TagManager/styles'

export function SavedSearchesListView() {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { openDrawer } = useDrawer()

  const {
    savedSearches,
    isLoading,
    handleExcludeSearch,
    refetch,
    setPage,
    setLimit,
  } = useContext(SavedSearchesListContext)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [selectedSavedSearch, setSelectedSavedSearch] = useState(
    {} as SavedSearchEntity
  )

  const clearLocalStorageFilters = () => {
    const search = localStorage.getItem('@NOMOS:defaultFilters')
    if (search) localStorage.removeItem('@NOMOS:defaultFilters')
  }

  const handleNavigateToResults = useCallback(() => {
    clearLocalStorageFilters()

    const buckets = [] as string[]
    const filters: Record<string, Filter> = {}
    const modes = (selectedSavedSearch.modes || []).join(',')
    const keywords: KeywordConditionsType = selectedSavedSearch.keywords
      ? selectedSavedSearch.keywords
      : { or: [selectedSavedSearch.keyword], and: [], not: [] }

    selectedSavedSearch?.searches?.forEach((item) => {
      buckets.push(item.bucket)
      if (!isEmpty(item.filter)) {
        filters[item.bucket] = item.filter
      }
    })

    navigate(
      `/search-results?keyword=${encodeKeywords(
        keywords
      )}&buckets=${buckets.join(',')}&searchId=${
        selectedSavedSearch?.id
      }&monitorId=${selectedSavedSearch.monitorId}&modes=${modes}`,
      {
        state: { filters },
      }
    )
  }, [selectedSavedSearch])

  const settings = [
    {
      id: 1,
      title: 'Resultados da Pesquisa',
      icon: SearchSVG,
      onClick: () => handleNavigateToResults(),
      cy: 'resultados',
    },
    {
      id: 2,
      title: 'Editar Pesquisa',
      icon: PencilSVG,
      onClick: () => {
        openDrawer(
          <EditSavedSearch
            search={selectedSavedSearch}
            onSuccess={() => refetch()}
          />
        )
        setAnchorEl(null)
      },
      cy: 'editar',
    },
    {
      id: 3,
      title: 'Excluir Pesquisa Salva',
      icon: TrashSVG,
      onClick: () => {
        openModal(
          <RemoveSearchModal
            onConfirm={() => {
              handleExcludeSearch(selectedSavedSearch)
              setAnchorEl(null)
            }}
          />
        )
        setAnchorEl(null)
      },
      cy: 'excluir',
    },
  ]

  return (
    <Container>
      <Shimmer isLoading={isLoading} height={154} width="100%">
        <Content>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Exibindo {pageview(savedSearches?.pagination || {})} resultados
          </Typography>

          {savedSearches?.results?.map((item) => {
            const buckets = item.searches?.map((search) => (
              <Tooltip
                title={`${Object.keys(search.filter).length} filtros aplicados`}
                placement="top"
                key={uid()}
              >
                <Chip
                  data-cy="chip"
                  color="primary"
                  onClick={() => {
                    openDrawer(
                      <EditBucketSavedSearch
                        id={item.id}
                        monitorId={item.monitorId}
                        search={item}
                        selectedBucket={search.bucket}
                        onSuccess={() => refetch()}
                        refetchBuckets={refetch}
                      />
                    )
                  }}
                >
                  <Typography
                    variant="$font-body-3"
                    color="$color-action-text-secondary"
                    fontWeight={600}
                  >
                    {getBucketName(search.bucket)}
                  </Typography>

                  <Counter>{Object.keys(search.filter).length}</Counter>
                </Chip>
              </Tooltip>
            ))

            return (
              <SavedSearchCard key={item.id} data-cy="saved-search-card">
                <CardItemColumn>
                  <CardItemRow>
                    <Typography
                      variant="$font-title-5"
                      color="$color-text-primary"
                    >
                      {item.name}
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Data de Criação:
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      {formatDate(item.createdAt)}
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Painel associado:
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      {item.monitorName}
                    </Typography>
                  </CardItemRow>

                  <CardItemRow>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Palavra Chave:
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-primary"
                    >
                      <KeywordRender
                        keyword={item.keyword}
                        value={{ keywords: item.keywords }}
                      />
                    </Typography>
                  </CardItemRow>

                  <CardItemRow>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Categorias Associadas:
                    </Typography>
                    <InlineTagsContainer>{buckets}</InlineTagsContainer>
                  </CardItemRow>
                </CardItemColumn>

                <ViewMore>
                  <MoreVertIcon
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget)
                      setSelectedSavedSearch(item)
                    }}
                  />
                </ViewMore>
              </SavedSearchCard>
            )
          })}
        </Content>
      </Shimmer>

      <Footer>
        <Pagination
          pagination={savedSearches?.pagination}
          handleUpdatePage={setPage}
          handleUpdateLimit={setLimit}
        />
      </Footer>

      {!isLoading && savedSearches?.results?.length === 0 && (
        <NoDataIcon height={36} text="Não foram encontradas pesquisas salvas" />
      )}
      <Menu
        sx={{ mt: '45px' }}
        data-cy="settings-menu"
        id={`menu-saved-search-${uid()}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {settings.map((setting) => (
          <MenuItem
            onClick={setting.onClick}
            sx={{ gap: '16px' }}
            key={uid()}
            data-cy={`settings-menu-${setting.cy}`}
          >
            <img src={setting.icon} alt={setting.title} />

            <Typography variant="$font-body-3" color="#556282">
              {setting.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  )
}
