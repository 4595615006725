import { useState, useEffect } from 'react'

const getStorageData = (key: string, defaultValue: string | object) => {
  const savedItem: string | null = localStorage.getItem(key)
  const parsedItem = savedItem != null ? JSON.parse(savedItem) : null
  return parsedItem || defaultValue
}

function useLocalStorage(key: string, initialValue: string | object) {
  const [value, setValue] = useState(() => {
    return getStorageData(key, initialValue)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export { useLocalStorage }
