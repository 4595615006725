import { InputMultiSelectChip } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { PropositionStatusNotificationContext } from '../../context'

type Props = {
  disabled: boolean
}

export function FormPropositionStatus({ disabled }: Props) {
  const unselectedStatus = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.unselectedStatus
  )
  const selectedStatus = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.selectedStatus
  )
  const setTextSearchStatus = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.setTextSearchStatus
  )
  const handleChangeOptions = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.handleChangeOptions
  )

  return (
    <Stack direction="column">
      <Stack direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="$font-title-5" color="$color-text-secondary">
            Definição de alerta
          </Typography>
        </Stack>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Se desejar, selecione em quais status de tramitação deseja ser
          alertado(a)
        </Typography>
      </Stack>
      <InputMultiSelectChip
        id="panel-selection"
        label="Status de tramitação"
        options={unselectedStatus || []}
        value={selectedStatus}
        loading={false}
        maxRenderTags={3}
        disabled={disabled}
        onChangeTextSearch={setTextSearchStatus}
        onChangeOptions={handleChangeOptions}
        groupAllSelectedOptions="Todas as tramitações"
        sx={{ mt: '24px', mb: '12px' }}
      />
    </Stack>
  )
}
