import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Wrapper = styled(Card)`
  padding: 16px;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #e6e8f0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    border: unset;
    gap: unset;
    display: block;
  }
`
export const ValuesContainer = styled.div`
  @media (min-width: 768px) {
    padding-top: 34px;
  }
`

export const Values = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`

export const ValueSideBySide = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

export const ValueUnique = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (min-width: 768px) {
    text-align: center;
    align-items: center;
  }
`
