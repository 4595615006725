import styled from 'styled-components'
import { Close } from '@mui/icons-material'
import { Box, Typography, Stack } from '@mui/material'

export const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2d3a40;
`

export const IconClose = styled(Close)`
  font-size: 24px;
  color: #6d787d;
`

export const ContentAction = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 31px;
`

export const DrawerBottom = styled(Stack)`
  width: 100%;
`
