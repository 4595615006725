import { createContext } from 'use-context-selector'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'

type StakeholderDetailContextType = {
  id?: string
  tab: string
  isLoading: boolean
  stakeholder: StakeholderEntity
  handleChangeTab: (tab: string) => void
}

export const StakeholderDetailContext =
  createContext<StakeholderDetailContextType>(
    {} as StakeholderDetailContextType
  )
