import { SVGProps } from 'react'

function SearchIcon({
  width = 32,
  height = 32,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill}>
      <circle
        cx="13.3335"
        cy="13.3335"
        r="8"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3335 19.3335L25.3335 25.3335"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
