export enum PaymentStatusEnum {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  paused = 'paused',
}

export type PaymentStatusEnumKeys = keyof typeof PaymentStatusEnum
