import { Box, styled } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  height: 'auto',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  padding: '32px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '32px 48px 32px 48px',
  },
}))
