import { useDrawer } from '@contexts/Drawer'
import { MonitoringContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { IconButton, Stack, Typography } from '@mui/material'
import { MonitoringTabsPanels } from './containers/tabs-panels'
import { Container, Content, Header, IconClose } from './styles'
import { MonitoringTabsSelector } from './containers/tabs-selector'
import { useMemo } from 'react'
import { BucketsEnum } from '@utils/buckets/type'
import { MonitoringTab } from './types'
import { PropositionsAlert } from './containers/propositions-alert'

export function MonitoringView() {
  const { cleanStack } = useDrawer()

  const ids = useContextSelector(MonitoringContext, (s) => s.id)?.split(',')
  const isSaved = useContextSelector(MonitoringContext, (s) => s.isSaved)
  const origin = useContextSelector(MonitoringContext, (s) => s.origin)
  const selectedTab = useContextSelector(
    MonitoringContext,
    (s) => s.selectedTab
  )

  const title = useMemo(() => {
    if (
      [
        BucketsEnum.propositions,
        BucketsEnum.stakeholders,
        BucketsEnum.events,
        BucketsEnum.organs,
      ].includes(origin)
    ) {
      return 'Opções de Monitoramento'
    }
    return 'Favoritar'
  }, [origin])

  switch (selectedTab) {
    case MonitoringTab.alerta:
      return <PropositionsAlert />
    default:
      return (
        <Container>
          <Content>
            <Header>
              <Typography variant="$font-title-3" color="$color-text-primary">
                {title}
              </Typography>
              <IconButton onClick={cleanStack}>
                <IconClose />
              </IconButton>
            </Header>

            {isSaved && ids.length === 1 && <MonitoringTabsPanels />}

            <Stack
              direction="column"
              overflow="hidden"
              sx={{
                paddingTop: isSaved && ids.length === 1 ? 2 : 0,
                width: '100%',
              }}
              spacing={3}
              flex={1}
            >
              <MonitoringTabsSelector />
            </Stack>
          </Content>
        </Container>
      )
  }
}
