import {
  AutoUploadFileIconWrap,
  AutoUploadFileName,
  AutoUploadFileWrapper,
} from './AutoUploadFile.styles'
import { CheckCircle, File, X } from '@phosphor-icons/react'
import { AutoUploadFileProps } from './AutoUploadFile.types'
import { IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { AutoUploadFileContext } from './AutoUploadFile.context'
import { useMemo } from 'react'
import { theme } from '@theme/index'
import { formatBytes } from '@utils/functions/size/size'

export function AutoUploadFileView({
  file,
  onCancel,
}: AutoUploadFileProps): JSX.Element | null {
  if (!file) return null

  const error = useContextSelector(AutoUploadFileContext, (s) => s.error)
  const loading = useContextSelector(AutoUploadFileContext, (s) => s.loading)
  const status = useContextSelector(AutoUploadFileContext, (s) => s.status)
  const textcolor = useMemo(() => {
    return error ? '$color-semantic-error' : '$color-text-secondary'
  }, [error])

  return (
    <Stack alignItems="flex-start">
      <AutoUploadFileWrapper spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <AutoUploadFileIconWrap
            error={String(error) as unknown as 'true' | 'false'}
          >
            <File weight="bold" size={16} />
          </AutoUploadFileIconWrap>
          <Stack direction="column">
            <AutoUploadFileName variant="$font-body-3" color={textcolor}>
              {file?.name}
            </AutoUploadFileName>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="$font-body-3" color={textcolor}>
                {formatBytes(file?.size || 0)}
              </Typography>
              {status && (
                <>
                  <Typography variant="$font-body-3" color={textcolor}>
                    -
                  </Typography>
                  <Typography variant="$font-body-3" color={textcolor}>
                    {status}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          <IconButton
            size="small"
            onClick={() => onCancel?.()}
            sx={{ width: 24, height: 24 }}
          >
            <X fontSize={12} />
          </IconButton>
          {status === 'Completo' && (
            <CheckCircle
              size={24}
              weight="duotone"
              color={theme.palette['$color-semantic-success']}
            />
          )}
        </Stack>
        {loading && <LinearProgress />}
      </AutoUploadFileWrapper>
    </Stack>
  )
}
