import { Propositionsiew } from './view'
import { PropositionPositioningProps } from './types'
import { PropositionPositioningController } from './controller'

export function Propositions(props: PropositionPositioningProps): JSX.Element {
  return (
    <PropositionPositioningController {...props}>
      <Propositionsiew {...props} />
    </PropositionPositioningController>
  )
}
