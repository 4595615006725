import React from 'react'
import { uid } from 'uid/single'
import { NoDataIcon } from '@components/icons'
import Accordion from '@mui/material/Accordion'
import { CardStakeholder } from '../CardStakeholder'
import { CardStakeholderMobile } from '../CardStakeholderMobile'
import { CardTab } from '@components/organisms/Cards'
import { PropositionDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Chip, Container, Grid, Stack, Typography } from '@mui/material'
import colors from '@theme/colors'

export function PanelRapporteurs({ visible = false }) {
  const detectMob = () => window.innerWidth <= 800;

  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )
  
  const groups = React.useMemo(() => {
    if(!(proposition?.rapporteurs instanceof Array)) return []
    return Array.from(
      proposition?.rapporteurs?.slice().reverse().reduce((group, item, index) => {
        const rapporteur = {...item, current: index === 0}
        const organName = `${rapporteur.organ?.name} (${rapporteur.organ?.initials})`
        const itemIndex = group.findIndex(item => item.organ === organName)
        if(itemIndex !== -1) {
          group[itemIndex].rapporteurs.push(rapporteur)
        }else{
          group.push({
            id: uid(),
            organ: organName,
            organInitials: rapporteur.organ?.initials,
            rapporteurs: [rapporteur]
          })
        }
        return group;
      }, []),
    )
  }, [proposition])

  return (
    <CardTab
      title="Relatores"
      subtitle="Veja abaixo os relatores por comissão"
      color={colors['$color-background-secondary']}
    >
      <Stack  spacing={2} sx={{ display: visible ? 'flex' : 'none', marginTop: '33px' }}>
      {groups?.length === 0 && (
              <Container sx={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                <NoDataIcon
                  height={36}
                  text="Não foram encontradas relatores para a proposição "
                />
              </Container>
            )}

          {groups.map(group => (

            <Accordion
              key={`organ-${group.id}`}
              elevation={0}
              sx={{
                py: {xs: 0, md: 3 },
                px: {xs: 0, md: 4},
                borderRadius: 0,
                ':before': { display: "none"},
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ boxShadow: 'none', borderWidth: 0, minHeight: "32px !important"}}
              >
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Typography variant="$font-title-4" color="$color-text-primary">
                    {group.organ}
                  </Typography>
                  {proposition?.lastProceeding?.organ === group.organInitials && (
                    <Chip
                      size="small"
                      label="Proposição tramitando nesta comissão"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ py: 0  }}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="$font-body-base" color="$color-text-secondary">
                    Veja abaixo quem são os relatores da {proposition?.title} nesta comissão
                  </Typography>
                  
                  {(group?.rapporteurs || [])
                    .map((item, i) => (
                      detectMob() ? ( 
                      <CardStakeholderMobile
                        key={`stakeholder-${uid()}`}
                        stakeholder={item}
                        first={i === 0}
                      />
                      ) :( 
                      <CardStakeholder
                        key={`stakeholder-${uid()}`}
                        stakeholder={item}
                        first={i === 0}
                      />
                      ) 
                     
                    ))}
                </Stack>
              </AccordionDetails>
            </Accordion>    
          ))}
      </Stack>
    </CardTab>
  )
}
