import { Button } from '@components/atoms'
import { Typography } from '@mui/material'
import { HouseInfo } from '@components/molecules'
import { OrganEntity } from '@services/nomos_api/entities/organ'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'

import { CardContainer, CardContent, OrganName } from './Organ.styles'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'

export type OrganProps = {
  organ: OrganEntity
}

export default function Organ({ organ }: OrganProps) {
  const { id, initials, name, openDataResource } = organ
  const { url } = useSearchResultsUrl(`/organs/${id}`)

  return (
    <CardContainer data-testid="organ-card">
      <CardContent>
        <HouseInfo text={openDataResource} />

        <OrganName>
          <Typography
            variant="$font-title-5"
            color="$color-text-primary"
            data-testid="organ-initials"
          >
            {initials?.toUpperCase()}
          </Typography>
          <Typography
            variant="$font-body-1"
            color="$color-text-primary"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            -
          </Typography>
          <Typography
            variant="$font-body-1"
            color="$color-text-primary"
            data-testid="organ-name"
          >
            {capitalizeAllWords(name)}
          </Typography>
        </OrganName>
      </CardContent>

      <Button
        text="Detalhes"
        size="small"
        icon="arrow"
        sx={{ height: '34px', width: 120 }}
        href={url}
        target="_blank"
      />
    </CardContainer>
  )
}
