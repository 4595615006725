/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { useSnackbar } from '@contexts/Snackbar'
import { MonitoringContext } from '../../context'
import { TabRemoveFromMonitorContext } from './context'
import { getOrganAPI } from '@services/nomos_api/resources/organs/find'
import { getEventById } from '@services/nomos_api/resources/event/getById'
import { getPropositionApi } from '@services/nomos_api/resources/proposition/get'
import { getStakeholderAPI } from '@services/nomos_api/resources/stakeholders/getById'
import { deleteSavedEventsApi } from '@services/nomos_api/resources/saved_event/delete'
import { deleteSavedPropositionsApi } from '@services/nomos_api/resources/saved_proposition/delete'
import { deleteSavedStakeholdersApi } from '@services/nomos_api/resources/saved_stakeholder/delete'
import { deleteSavedOrgansApi } from '@services/nomos_api/resources/saved_organ/delete'
import { TabRemoveFromMonitorControllerProps } from './types'
import { useContextSelector } from 'use-context-selector'
import { findSavedBucketsApi } from '@services/nomos_api/resources/saved_buckets/getAll'
import { deleteSavedBucketsApi } from '@services/nomos_api/resources/saved_buckets/delete'
import { BucketsEnum } from '@utils/buckets/type'

const getInfo = {
  [BucketsEnum.propositions]: {
    api: getPropositionApi,
    attribute: 'savedPropositions',
  },
  [BucketsEnum.events]: {
    api: getEventById,
    attribute: 'savedEvents',
  },
  [BucketsEnum.stakeholders]: {
    api: getStakeholderAPI,
    attribute: 'savedStakeholders',
  },
  [BucketsEnum.organs]: {
    api: getOrganAPI,
    attribute: 'savedOrgans',
  },
}

const removeFromMonitorAPI = {
  [BucketsEnum.propositions]: deleteSavedPropositionsApi,
  [BucketsEnum.events]: deleteSavedEventsApi,
  [BucketsEnum.stakeholders]: deleteSavedStakeholdersApi,
  [BucketsEnum.organs]: deleteSavedOrgansApi,
}

export function RemovePropositionTabController({
  children,
}: TabRemoveFromMonitorControllerProps) {
  const { closeDrawer } = useDrawer()
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const id = useContextSelector(MonitoringContext, (s) => s.id)
  const origin = useContextSelector(MonitoringContext, (s) => s.origin)
  const onRemove = useContextSelector(MonitoringContext, (s) => s.onRemove)

  const [monitoring, setMonitoring] = useState<Record<string, unknown>[]>([])
  const [dataToUnmonitor, setDataToUnmonitor] = useState<string[]>([])
  const [isRemoving, setRemoving] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (!id) return () => undefined
    setLoading(true)

    const handler = _.get(getInfo, origin, {
      api: (id: string) => findSavedBucketsApi({ filters: { originId: id } }),
      attribute: 'originId',
    })

    handler
      .api(id)
      .then((res: any) => {
        if (res instanceof Array) {
          setMonitoring(
            res.reduce((list, i) => {
              list.push({ _id: i.id, monitorName: i.monitorName })
              return list
            }, [])
          )
        } else {
          setMonitoring(res[handler.attribute])
        }
      })
      .catch((error: never) =>
        showSnackbarError(
          'Ocorreu um problema ao salvar os dados',
          _.get(error, 'message')
        )
      )
      .finally(() => setLoading(false))
    return () => undefined
  }, [])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const isChecked = event.target.checked

    if (isChecked) {
      setDataToUnmonitor([...dataToUnmonitor, value as unknown as string])
    } else {
      setDataToUnmonitor(dataToUnmonitor.filter((item) => item !== value))
    }
  }

  const handleRemoveData = () => {
    setRemoving(true)
    const handler = _.get(removeFromMonitorAPI, origin, deleteSavedBucketsApi)
    handler(dataToUnmonitor)
      .then(() => {
        if (onRemove) onRemove(dataToUnmonitor)
        showSnackbarSuccess('Removido com sucesso')
        closeDrawer()
      })
      .catch(() => {
        showSnackbarError(
          'Não foi possível realizar a remoção do monitor desejado'
        )
      })
      .finally(() => {
        setRemoving(false)
        setTimeout(closeSnackbar, 3000)
      })
  }

  const store = useMemo(
    () => ({
      monitoring,
      dataToUnmonitor,
      handleCheck,
      handleRemoveData,
      isLoading,
      isRemoving,
    }),
    [
      monitoring,
      dataToUnmonitor,
      handleCheck,
      handleRemoveData,
      isLoading,
      isRemoving,
    ]
  )

  return (
    <TabRemoveFromMonitorContext.Provider value={store}>
      {children}
    </TabRemoveFromMonitorContext.Provider>
  )
}
