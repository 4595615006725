import { Button } from '@components/atoms'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NoDataIcon } from '@components/icons'
import resourceTypes from '@utils/resources/types'
import LinkTo from '@components/atoms/LinkTo/LinkTo'
import { CardTab } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'
import { Chip, CircularProgress, Typography } from '@mui/material'
import { HighlightOff, CheckCircleOutline } from '@mui/icons-material'
import { PropositionVoteEntity } from '@services/nomos_api/entities/proposition'
import { getPropositionVotings } from '@services/nomos_api/resources/proposition/get_votings'

import {
  ContentContainer,
  LeftContent,
  CenterContent,
  RightContent,
  Status,
} from './styles'
import { Link } from 'react-router-dom'
import { formatDateTime } from '@utils/functions/formatter/formatter'

export function PanelVotings() {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )
  const [isLoading, setLoading] = useState(true)
  const [votings, setVotings] = useState<PropositionVoteEntity[]>([])

  useEffect(() => {
    if (proposition?.id) {
      getPropositionVotings(proposition?.id)
        .then((data) => {
          setVotings(data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [proposition])

  const navigate = useNavigate()
  const handleClickVote = (id: string) =>
    navigate(`/proposition/${proposition?.id}/voting/${id}`)

  return (
    <CardTab title="Votações" subtitle="Veja abaixo as votações por órgão">
      <>
        <CenterContent>
          {isLoading && <CircularProgress size={25} />}
        </CenterContent>

        {votings?.map((vote: PropositionVoteEntity) => (
          <ContentContainer key={vote.id}>
            <LeftContent>
              <Typography variant="$font-body-2" color="$color-text-primary">
                {resourceTypes[vote.openDataResource]} -{' '}
                {formatDateTime(vote.date)}
              </Typography>
              <Status>
                <Link to={`/organs/${vote.organ?.id}`}>
                  <Typography
                    variant="$font-title-4"
                    color="$color-text-primary"
                  >
                    {vote.organ?.name}
                  </Typography>
                </Link>

                {vote.isCurrentOrgan && (
                  <Chip
                    label="Comissão Atual"
                    variant="outlined"
                    size="small"
                    color="primary"
                  />
                )}
              </Status>

              <Typography variant="$font-body-base" color="$color-text-primary">
                {vote.proposition?.type} {vote.proposition?.number}/
                {vote.proposition?.year}
              </Typography>

              <Typography variant="$font-body-base" color="$color-text-primary">
                {vote.proposition?.summary}
              </Typography>

              {vote.rapporteurVoteUrl && (
                <LinkTo
                  title="Visualizar parecer do relator"
                  to={vote.rapporteurVoteUrl}
                />
              )}
            </LeftContent>

            <RightContent>
              {vote.approved ? (
                <Typography
                  variant="$font-title-5"
                  color="$color-semantic-success"
                >
                  <Status>
                    <CheckCircleOutline />
                    Aprovado
                  </Status>
                </Typography>
              ) : (
                <Typography
                  variant="$font-title-5"
                  color="$color-semantic-error"
                >
                  <Status>
                    <HighlightOff />
                    Rejeitado
                  </Status>
                </Typography>
              )}

              {vote.isNominalVoting && (
                <Button
                  icon="arrow"
                  type="button"
                  text="Detalhes"
                  onClick={() => handleClickVote(vote.id)}
                />
              )}
            </RightContent>
          </ContentContainer>
        ))}

        <CenterContent>
          {!isLoading && votings?.length === 0 && (
            <NoDataIcon
              height={36}
              text="Não foram encontradas votações para a proposição "
            />
          )}
        </CenterContent>
      </>
    </CardTab>
  )
}
