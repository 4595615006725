import React from 'react'
import { useModal } from '@contexts/Modal'
import { StripePortalLinkContext } from './context'
import { StripePortalLinkControllerProps } from './types'
import { StripeModalError } from '@containers/StripeModalError'
import { generateStripeManageSubscriptionLink } from '@services/payment/resources/manage_subscription_link'
import { UrlType } from '@type/url'

export function StripePortalLinkController({
  children,
}: StripePortalLinkControllerProps): JSX.Element {
  const { openModal } = useModal()
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const handleError = (error: unknown) => {
    openModal(<StripeModalError error={error} />)
  }

  const handleOnStripeManageSubscriptionLink = (res: UrlType) => {
    window.location.replace(res.url)
  }

  const handleGenerateStripePortalLink = () => {
    setLoading(true)
    generateStripeManageSubscriptionLink()
      .then(handleOnStripeManageSubscriptionLink)
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const state = React.useMemo(
    () => ({
      isLoading,
      handleGenerateStripePortalLink,
    }),
    [isLoading, handleGenerateStripePortalLink]
  )

  return (
    <StripePortalLinkContext.Provider value={state}>
      {children}
    </StripePortalLinkContext.Provider>
  )
}
