import { SaveInMonitorView } from './view'
import { SaveInMonitorController } from './controller'
import { SaveInMonitorProps } from './types'

function SaveInMonitor(props: SaveInMonitorProps) {
  return (
    <SaveInMonitorController {...props}>
      <SaveInMonitorView />
    </SaveInMonitorController>
  )
}

export default SaveInMonitor
