import { EventsCalendarContext } from '../context'
import { TextField, MenuItem } from '@mui/material'
import { useState, useCallback, useContext } from 'react'

function HeaderSelect() {
  const { eventsMode, setEventsMode } = useContext(EventsCalendarContext)

  const listViewTypes = [
    {
      key: 'Dia',
      active: false,
    },
    {
      key: 'Semana',
      active: false,
    },
    {
      key: 'Mês',
      active: false,
    },
    {
      key: 'Lista',
      active: true,
    },
  ]

  const [mode, setMode] = useState(listViewTypes)

  const handleChangeMode = useCallback((type) => {
    setMode((prevState) => {
      const updatedData = prevState.map((item) => {
        if (item.key === type) {
          setEventsMode(item.key)
          return { ...item, active: true }
        }
        return { ...item, active: false }
      })

      return updatedData
    })
  }, [])

  return (
    <TextField
      select
      size="tiny"
      label="Visualizar por"
      value={eventsMode}
      onChange={(e) => {
        handleChangeMode(e.target.value)
      }}
      sx={{ width: 205, backgroundColor: 'white' }}
    >
      {mode.map((option) => (
        <MenuItem
          key={option.key}
          value={option.key}
          sx={{ fontSize: '14px', color: '#556282' }}
        >
          {option.key}
        </MenuItem>
      ))}
    </TextField>
  )
}

export { HeaderSelect }
