/* eslint-disable react/destructuring-assignment */
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSnackbar } from '@contexts/Snackbar'
import { AuthPage } from '@components/templates'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '@components/atoms'
import { password, passwordConfirmation } from '@utils/forms/validation'
import { useResetPassword } from '@services/nomos_api/resources/reset_password'
import PasswordRequirements from '@components/atoms/PasswordRequirements'
import { Content, ResetPasswordCard, RequirementsContainer } from './styles'
import { useAuth } from '@contexts/Auth'

type ResetPasswordProps = {
  initial?: boolean
}

function ResetPasswordPage(props: ResetPasswordProps) {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const { token } = useParams()

  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()

  const { isLoading, data, error, mutate } = useResetPassword()

  const [tokenResetPassword, setTokenResetPassword] = useState('')

  const validationSchema = Yup.object().shape({
    password,
    password_confirmation: passwordConfirmation,
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit: (values) => mutate({ ...values, token: tokenResetPassword }),
  })

  useEffect(() => {
    if (!isLoading && data) {
      showSnackbarSuccess('Senha criada com sucesso')
      if (props.initial) isAuthenticated(data.user, data.jwt)
      navigate('/')
    }
  }, [isLoading, data])

  useEffect(() => {
    if (!isLoading && error) {
      showSnackbarError(error?.response?.data.message || '')
    }
  }, [isLoading, error])

  useEffect(() => {
    if (token) {
      setTokenResetPassword(token)
    }
  }, [token])

  const getSubtitle = () => {
    if (props.initial) return `Altere sua senha inicial para uma de sua escolha`
    return `Insira sua nova senha e volte a ter acesso ao Nomos`
  }

  return (
    <AuthPage>
      <Content>
        <ResetPasswordCard>
          <Typography
            data-testid="title"
            variant="$font-title-2"
            sx={{
              fontSize: '32px',
              lineHeight: '40px',
            }}
          >
            Crie sua nova senha
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: '#606162',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              marginBottom: '16px',
            }}
          >
            {getSubtitle()}
          </Typography>

          <Input
            id="password"
            label="Senha"
            type="password"
            name="password"
            size="small"
            error={
              (formik.touched.password && Boolean(formik.errors.password)) ||
              (!isLoading && error)
            }
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}
          />

          <Input
            id="password_confirmation"
            label="Confirmar Senha"
            type="password"
            name="password_confirmation"
            size="small"
            error={
              (formik.touched.password_confirmation &&
                Boolean(formik.errors.password_confirmation)) ||
              (!isLoading && error)
            }
            helpertext={
              (formik.touched.password_confirmation &&
                formik.errors.password_confirmation) ||
              error?.response?.data?.message
            }
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
          />

          <Button
            type="submit"
            text="Confirmar"
            loading={isLoading}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={() => formik.handleSubmit()}
            sx={{ height: '48px' }}
          />

          {formik.values.password?.length > 0 && (
            <RequirementsContainer>
              <PasswordRequirements password={formik.values.password} />
            </RequirementsContainer>
          )}
        </ResetPasswordCard>
      </Content>
    </AuthPage>
  )
}

export default ResetPasswordPage
