import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export const Container = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '40px !important',
  lineHeight: '40px !important',
  color: '#6D7994',
  margin: '-10px 0 24px 0 !important',
}))

export const Description = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '24px !important',
  lineHeight: '24px !important',
  color: '#6D7994',
  marginBottom: '40px !important',
}))
