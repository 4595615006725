export const sizeconfig = {
  medium: {
    legendFontSize: 12,
    inputFontSize: 16,
    fieldsetHeight: 60,
    fieldsetHeightFocus: 58,
  },
  small: {
    legendFontSize: 10,
    inputFontSize: 14,
    fieldsetHeight: 54,
    fieldsetHeightFocus: 52,
  },
}
