/* eslint-disable @typescript-eslint/no-explicit-any, no-console */

import * as Sentry from '@sentry/react'
import * as amplitude from '@amplitude/analytics-browser'

const w = window as any
w.Sentry = Sentry

const host = document.location.host.split('.')

const isProduction = host.length === 2

export const initMetrics = () => {
  if (host[0].indexOf('localhost') === -1) {
    Sentry.init({
      environment: isProduction ? 'production' : host[0],
      dsn: 'https://ca68aef951254befb97f56bde902be5c@o4505081155092480.ingest.sentry.io/4505081294290944',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  if (isProduction) {
    amplitude.init('4627384100f4992a1ecd393bf9a8de04')
  }
}

export const signupEvent = (data: any) => {
  try {
    const { name, email, phone } = data
    if (isProduction) {
      w.gtag('sign_up', { name, email, phone })
      amplitude.track('sign_up', { name, email, phone })
    }
  } catch (error) {
    console.log(error)
  }
}

export const activateEvent = () => {
  try {
    if (isProduction) {
      w.gtag('activate')
      amplitude.track('activate')
    }
  } catch (error) {
    console.log(error)
  }
}

export const acceptEvent = () => {
  try {
    if (isProduction) {
      w.gtag('accept')
      amplitude.track('accept')
    }
  } catch (error) {
    console.log(error)
  }
}

export const loginEvent = (data: any) => {
  try {
    if (isProduction) {
      w.gtag('login', { email: data.email })
      amplitude.track('login', { email: data.email })
    }
  } catch (error) {
    console.log(error)
  }
}

export const createCompanyEvent = (data: any) => {
  try {
    if (isProduction) {
      w.gtag('create_company', data)
      amplitude.track('create_company', data)
    }
  } catch (error) {
    console.log(error)
  }
}

export const pageViewEvent = (isPublic = false) => {
  try {
    if (isProduction) {
      if (isPublic) {
        w.gtag('public_page_view')
        amplitude.track('public_page_view')
      } else {
        w.gtag('private_page_view')
        amplitude.track('private_page_view')
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const startSearchEvent = () => {
  try {
    if (isProduction) {
      amplitude.track('start_search')
      w.gtag('start_search')
    }
  } catch (error) {
    console.log(error)
  }
}

export const searchEvent = () => {
  try {
    if (isProduction) {
      amplitude.track('search')
      w.gtag('search')
    }
  } catch (error) {
    console.log(error)
  }
}

export const createMonitorEvent = () => {
  try {
    if (isProduction) {
      amplitude.track('create_monitor')
      w.gtag('create_monitor')
    }
  } catch (error) {
    console.log(error)
  }
}

export const saveSearchEvent = () => {
  try {
    if (isProduction) {
      amplitude.track('save_search')
      w.gtag('save_search')
    }
  } catch (error) {
    console.log(error)
  }
}

export const monitorEvent = (name: string) => {
  try {
    if (isProduction) {
      amplitude.track('monitor')
      w.gtag('monitor')
      amplitude.track(`monitor_${name}`)
      w.gtag('monitor')
    }
  } catch (error) {
    console.log(error)
  }
}

export const signatureEvent = () => {
  try {
    if (isProduction) {
      amplitude.track('signature')
      w.gtag('signature')
    }
  } catch (error) {
    console.log(error)
  }
}
