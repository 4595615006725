import { Grid, Stack, Typography } from '@mui/material'
import { TabContent, TabContentInner } from './Tab.styles'

export type CardTabProps = {
  title: string
  subtitle: string
  visible?: boolean
  color?: string
  children: React.ReactChild
}

const defaultProps = {
  visible: true,
  color: null,
}

function CardTab({ title, subtitle, visible, color, children }: CardTabProps) {
  return (
    <Grid container spacing={2} sx={{ display: visible ? 'flex' : 'none' }}>
      <Grid item xs={12}>
        <TabContent color={color}>
          <TabContentInner>
            <Stack spacing={3} direction="column">
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="column" spacing={1}>
                  <Typography
                    variant="$font-title-3"
                    color="$color-text-primary"
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {subtitle}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {children}
          </TabContentInner>
        </TabContent>
      </Grid>
    </Grid>
  )
}

CardTab.defaultProps = defaultProps

export default CardTab
