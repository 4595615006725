import { SvgIcon } from '@mui/material'

type Props = {
  color?: string
}
function BellNotification({ color = '#0A2156' }: Props) {
  return (
    <SvgIcon data-testid="bell-notification-icon-component">
      <path
        d="M6.21976 14.2231L5.67602 13.7065L6.21976 14.2231ZM8.14502 9.28906C8.14502 6.94185 10.0478 5.03906 12.395 5.03906V3.53906C9.21938 3.53906 6.64502 6.11342 6.64502 9.28906H8.14502ZM8.14502 12.0454V9.28906H6.64502V12.0454H8.14502ZM6.14502 16.2891C6.14502 15.6883 6.37951 15.1438 6.76349 14.7397L5.67602 13.7065C5.03779 14.3783 4.64502 15.2886 4.64502 16.2891H6.14502ZM7.39502 16.5391H6.39502V18.0391H7.39502V16.5391ZM17.395 16.5391H7.39502V18.0391H17.395V16.5391ZM18.395 16.5391H17.395V18.0391H18.395V16.5391ZM18.0266 14.7397C18.4105 15.1438 18.645 15.6883 18.645 16.2891H20.145C20.145 15.2886 19.7523 14.3783 19.114 13.7065L18.0266 14.7397ZM16.645 9.28906V12.0454H18.145V9.28906H16.645ZM12.395 5.03906C14.7422 5.03906 16.645 6.94185 16.645 9.28906H18.145C18.145 6.11343 15.5707 3.53906 12.395 3.53906V5.03906ZM19.114 13.7065C18.5121 13.073 18.145 12.5585 18.145 12.0454H16.645C16.645 13.2276 17.4608 14.1442 18.0266 14.7397L19.114 13.7065ZM4.64502 16.2891C4.64502 17.2556 5.42852 18.0391 6.39502 18.0391V16.5391C6.25695 16.5391 6.14502 16.4271 6.14502 16.2891H4.64502ZM18.395 18.0391C19.3615 18.0391 20.145 17.2556 20.145 16.2891H18.645C18.645 16.4271 18.5331 16.5391 18.395 16.5391V18.0391ZM6.64502 12.0454C6.64502 12.5585 6.27791 13.073 5.67602 13.7065L6.76349 14.7397C7.32926 14.1442 8.14502 13.2276 8.14502 12.0454H6.64502Z"
        fill={color}
      />

      <path
        d="M14.1926 20.1658C14.0262 20.5069 13.7662 20.7937 13.443 20.9925C13.1197 21.1914 12.7466 21.2942 12.3671 21.2889C11.9876 21.2836 11.6175 21.1704 11.2999 20.9626C10.9823 20.7548 10.7304 20.4609 10.5737 20.1153"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M12.395 3.28906V4.28906"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default BellNotification
