import { UrlType } from '@type/url'
import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { SharePaymentLinkProps } from './types'

export const sharePaymentLink = async (
  props: SharePaymentLinkProps
): Promise<UrlType> => {
  const { data } = await HTTPClient.post<UrlType>('share', props)
  return data
}

export const useSharePaymentLink = () =>
  useMutation(['share-payment-link'], (props: SharePaymentLinkProps) =>
    sharePaymentLink(props)
  )
