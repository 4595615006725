import { Box, Stack, styled } from '@mui/material'

export const Wrapper = styled(Box)(() => ({
  // height: '100%',
  backgroundColor: '#F9FAFC',
}))

export const WrapperContent = styled(Stack)(() => ({
  margin: '0 auto',
  padding: '40px 0 48px 0',
  minHeight: '82vh',
  backgroundColor: '#F9FAFC',
  maxWidth: '88%',
}))
