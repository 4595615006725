import { Avatar, Card } from '@components/atoms'
import { Divider, Typography } from '@mui/material'
import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { formatDate } from '@utils/functions/formatter/formatter'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'

import {
  CardContent,
  CardHeader,
  CardInfo,
  Info,
} from './StakeholderCard.styles'

export default function StakeholderCard() {
  const stakeholder = useContextSelector(
    StakeholderDetailContext,
    (s) => s.stakeholder
  )

  const {
    photoUrl,
    name,
    situation,
    acronymParty,
    email,
    phones,
    address,
    birthDate,
    birthCity,
    birthUF,
    openDataResource,
  } = stakeholder || {}

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <Avatar
            src={photoUrl}
            sx={{ width: { xs: 90, md: 130 }, height: { xs: 90, md: 130 } }}
            width={0}
            height={0}
          />
          <Typography
            variant="$font-body-base"
            color="$color-action-text-secondary"
          >
            {openDataResource === 'senado' ? 'Senador' : 'Deputado Federal'}
          </Typography>
          <Typography variant="$font-title-5" color="$color-text-primary">
            {capitalizeAllWords(name)}
          </Typography>
          <Typography variant="$font-body-1" color="$color-text-primary">
            Partido: {acronymParty}
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            {situation}
          </Typography>
        </CardHeader>
        <Divider
          sx={{ borderColor: '$color-border-primary', marginTop: '24px' }}
        />

        <CardInfo>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Nome Civil:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {capitalizeAllWords(name)}
            </Typography>
          </Info>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Email:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {email ? (
                <a href={`mailto:${email}`}>{email}</a>
              ) : (
                'Não disponível'
              )}
            </Typography>
          </Info>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Telefone:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {phones && phones.length > 0
                ? phones.join(' | ')
                : 'Não disponível'}
            </Typography>
          </Info>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Endereço:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {address || 'Não disponível'}
            </Typography>
          </Info>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Nascimento:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {formatDate(birthDate)}
            </Typography>
          </Info>
          <Info>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Naturalidade:
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {birthCity} - {birthUF}
            </Typography>
          </Info>
        </CardInfo>
      </CardContent>
    </Card>
  )
}
