import { ScheduleContext } from '../../context'
import { CircularProgress } from '@mui/material'
import { CalendarContainer } from './Calendar.styles'
import { useContextSelector } from 'use-context-selector'
import { EventsCalendar } from '@containers/EventsCalendar'

export default function Calendar() {
  const isLoading = useContextSelector(ScheduleContext, (s) => s.isLoading)

  const events = useContextSelector(ScheduleContext, (s) => s.events)

  const handleUpdateCalendar = useContextSelector(
    ScheduleContext,
    (s) => s.handleUpdateCalendar
  )

  return (
    <>
      {isLoading && <CircularProgress color="primary" size={15} />}

      <CalendarContainer>
        <EventsCalendar
          events={events}
          headerLocation="space-between"
          handleUpdateSearch={handleUpdateCalendar}
        />
      </CalendarContainer>
    </>
  )
}
