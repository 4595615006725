import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { KeyValuePair } from '@utils/buckets/type'

export const searchOptionsAPI = async (
  bucket: string,
  field: string,
  resources: string[],
  pagination?: {
    page?: number | undefined
    limit?: number | undefined
    text?: string | undefined
  }
): Promise<KeyValuePair[]> => {
  const querystring: string[] = []
  if (resources && resources.length > 0)
    querystring.push(`resources=${resources.join(',')}`)
  if (pagination?.page) querystring.push(`page=${pagination.page}`)
  if (pagination?.limit) querystring.push(`limit=${pagination.limit}`)
  if (pagination?.text) querystring.push(`q=${pagination.text}`)
  const { data } = await HTTPClient.get(
    `/search/options/${bucket}/${field}?${querystring.join('&')}`
  )

  return data as KeyValuePair[]
}

export const useSearchOptions = (
  bucket: string,
  field: string,
  resources: string[]
) =>
  useQuery(
    ['search-options', bucket, field, resources.join('-')],
    () => searchOptionsAPI(bucket, field, resources),
    {
      refetchOnWindowFocus: false,
    }
  )
