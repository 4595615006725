import _ from 'lodash'
import { ContentPaths } from './styles'
import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { BucketsEnum } from '@utils/buckets/type'
import { Button, Shimmer } from '@components/atoms'
import { EventDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { Stack, Typography, Tooltip } from '@mui/material'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { OpenDataResourceEnum } from '@enums/OpenDataResourceEnum'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'

import NotesIcon from '@assets/icons/notes.svg'

import {
  ArrowForwardIos,
  LaunchOutlined,
  ShareOutlined,
  FileDownloadOutlined,
} from '@mui/icons-material'

function Header() {
  const { auth } = useAuth()
  const event = useContextSelector(EventDetailsContext, (s) => s.event)
  const buckets = useContextSelector(EventDetailsContext, (s) => s.buckets)
  const keyword = useContextSelector(EventDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(EventDetailsContext, (s) => s.isLoading)

  const handleGetCalendar = useContextSelector(
    EventDetailsContext,
    (s) => s.handleGetCalendar
  )
  const handleCopyToClipboard = useContextSelector(
    EventDetailsContext,
    (s) => s.handleCopyToClipboard
  )

  const { openDrawer } = useDrawer()

  const params = `?buckets=${getSortedBucket(
    buckets,
    'events'
  )}&keyword=${keyword}`

  const formatText = (text: string | undefined) => {
    if (!text || text === 'undefined') return '--'
    return text
  }

  return (
    <Stack direction="column" spacing={1}>
      {auth && (
        <ContentPaths>
          <NavLink to={isMobile ? '/search-results' : '/home'}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Inicio
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Agenda
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <Shimmer isLoading={isLoading} height={18} width={80}>
            <Tooltip title={formatText(event?.description)} placement="top">
              <Typography
                maxWidth="21rem"
                variant="$font-body-base"
                color="$color-text-primary"
                noWrap
              >
                {formatText(event?.description || event?.type)}
              </Typography>
            </Tooltip>
          </Shimmer>
        </ContentPaths>
      )}
      <Shimmer isLoading={isLoading} height={36} width={240}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {formatText(
            [
              event?.organ?.name,
              event?.type ||
                _.get(
                  OpenDataResourceEnum,
                  String(event?.openDataResource),
                  'Não informado'
                ),
            ]
              .filter((i) => !!i)
              .join(' - ')
          )}
        </Typography>
      </Shimmer>

      <Stack
        direction={['column', 'column', 'row']}
        gap={3}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Shimmer isLoading={isLoading} height={30}>
          <Tooltip
            title={formatText(event?.description || event?.type)}
            placement="top"
          >
            <Typography
              maxWidth="27rem"
              variant="$font-title-4"
              color="muted.dark"
              noWrap
            >
              {formatText(event?.description || event?.type)}
            </Typography>
          </Tooltip>
        </Shimmer>

        <Stack
          width="100%"
          justifyContent="space-between"
          direction={['column', 'column', 'row']}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          gap={2}
        >
          <Stack
            direction={['column', 'row', 'row']}
            gap={2}
            justifyContent={{ xs: 'flex-start', md: 'center' }}
            mr={4}
          >
            <Button
              variant="text"
              text="Compartilhar evento"
              endIcon={<ShareOutlined />}
              disabled={isLoading}
              onClick={handleCopyToClipboard}
              sx={{ justifyContent: 'flex-start' }}
            />

            <Button
              variant="text"
              text="Salvar na minha agenda"
              endIcon={<FileDownloadOutlined />}
              disabled={isLoading}
              onClick={handleGetCalendar}
              sx={{ justifyContent: 'flex-start' }}
            />

            {event?.link && !isMobile && (
              <Button
                href={event?.link}
                target="_blank"
                rel="noreferrer"
                variant="text"
                text="Acompanhe na Íntegra"
                disabled={isLoading}
                endIcon={<LaunchOutlined />}
              />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            <Button
              text="Opções de Monitoramento"
              variant="outlined"
              disabled={isLoading}
              onClick={() =>
                openDrawer(
                  <MonitoringDrawer
                    id={event?.id as unknown as string}
                    origin={BucketsEnum.events}
                    isSaved={(event?.savedEvents || [])?.length > 0}
                  />
                )
              }
            />
            <Button
              text="Notas"
              color="$color-extended-red1"
              variant="contained"
              endIcon={<img src={NotesIcon} alt="Notas" />}
              onClick={() => {
                openDrawer(
                  <NoteManagerDrawer
                    id={event?.id as unknown as string}
                    bucket={BucketsEnum.events}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Header
