import HTTPClient from '../..'

type Props = {
  monitorId: string
  fields?: string[]
  resources?: string[]
  format: 'xlsx' | 'csv' | 'pdf'
}

export const exportPropositions = async (props: Props) => {
  const queryparams = []

  if (props.format) {
    queryparams.push(`format=${props.format}`)
  }

  if (props.fields) {
    queryparams.push(`fields=${props.fields.join(',')}`)
  }

  if (props.resources) {
    queryparams.push(`resources=${props.resources.join(',')}`)
  }

  const endpoint = `monitor/${props.monitorId}/export?${queryparams.join('&')}`
  const response = await HTTPClient.get(endpoint, { responseType: 'blob' })
  return response.data as Blob
}
