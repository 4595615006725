const getSortedBucket = (buckets: string, firstBucket: string) => {
  if (!buckets || buckets?.length === 0) return firstBucket

  const sortedBuckets = buckets
    .split(',')
    .filter((item) => item !== firstBucket)
  sortedBuckets.unshift(firstBucket)
  return sortedBuckets.join(',')
}

export { getSortedBucket }
