import { useEffect, useState } from 'react'
import { Button } from '@components/atoms'
import Tag from '@components/atoms/Tag/Tag'
import { useDrawer } from '@contexts/Drawer'
import { InlineTagsContainer } from './styles'
import { Stack, Typography } from '@mui/material'
import { TagManager } from '@containers/TagManager'
import { useContextSelector } from 'use-context-selector'
import { TagEntity } from '@services/nomos_api/entities/tag'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'

export function MonitorFormPropositionTags(): JSX.Element {
  const { openDrawer } = useDrawer()
  const [tags, setTags] = useState<TagEntity[]>([])

  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )

  const handleOpenTagManager = () => {
    openDrawer(<TagManager onTags={setTags} initialTags={tags} />, {
      lockscroll: true,
    })
  }

  useEffect(() => {
    formSavedBucket.setFieldValue(
      'tagIds',
      tags.map((tag) => tag.id)
    )
  }, [tags])

  return (
    <Stack direction="column" spacing={3} data-cy="monitoring-bucket-tags-area">
      <Stack direction="column" spacing={1}>
        <Typography variant="$font-title-5" color="$color-text-secondary">
          Crie e Relacione tags personalizadas
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Relacione o projeto monitorado à categorias/temas específicos ao seu
          contexto de monitoramento
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        {tags.length > 0 && (
          <InlineTagsContainer>
            {tags?.slice(0, 5).map((tag) => (
              <Tag key={`proposition-tag-${tag.id}`} {...tag} size="small" />
            ))}
            {tags.length > 5 && (
              <Button
                size="tiny"
                variant="text"
                text="ver todos"
                onClick={handleOpenTagManager}
              />
            )}
          </InlineTagsContainer>
        )}
        <Button
          id="btn-tag-manager"
          variant="outlined"
          text="Tag"
          onClick={handleOpenTagManager}
        />
      </Stack>
    </Stack>
  )
}
