import { Header, TabSelector } from '@components/organisms'
import { Container, Wrapper, Content } from './TabbedPage.styles'
import { HeaderProps } from '@components/organisms/Header/Header'
import { TabSelectorProps } from '@components/organisms/TabSelector/TabSelector'

export type TabbedPageProps = {
  header: HeaderProps
  tabSelector: TabSelectorProps
  children: React.ReactChild
}

function TabbedPage({ header, tabSelector, children }: TabbedPageProps) {
  return (
    <Container>
      <Wrapper direction="column" maxWidth="lg">
        <Header {...header} />

        <TabSelector {...tabSelector} />

        <Content>{children}</Content>
      </Wrapper>
    </Container>
  )
}

export default TabbedPage
