import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ShareCheckoutModalContext } from './context'
import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'
import { StripeModalError } from '@containers/StripeModalError'
import { sharePaymentLink } from '@services/payment/resources/share_link'
import {
  ShareCheckoutModalControllerProps,
  ShareCheckoutModalFormType,
} from './types'

export function ShareCheckoutModalController({
  children,
}: ShareCheckoutModalControllerProps): JSX.Element {
  const { closeModal, openModal } = useModal()
  const { showSnackbarSuccess } = useSnackbar()

  const handleOnSharedSuccessfully = (email: string) => {
    closeModal()
    showSnackbarSuccess(
      'Link compartilhado',
      `O link de pagamento foi compartilhado com ${email}`
    )
  }

  const handleError = (error: unknown) => {
    openModal(<StripeModalError error={error} />)
  }

  const formik = useFormik<ShareCheckoutModalFormType>({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Deve ser um email válido')
        .required('Email obrigatório'),
    }),
    onSubmit: (data) => {
      return sharePaymentLink(data)
        .then(() => handleOnSharedSuccessfully(data.email))
        .catch(handleError)
    },
  })

  const state = React.useMemo(() => ({ formik }), [formik])

  return (
    <ShareCheckoutModalContext.Provider value={state}>
      {children}
    </ShareCheckoutModalContext.Provider>
  )
}
