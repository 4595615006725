import { Button } from '@components/atoms'
import { Typography } from '@mui/material'

import { Container } from './styles'

export type EmptyStateProps = {
  text: string
  action?: string
  onClick?: () => void
}

function EmptyState({ text, action, onClick }: EmptyStateProps) {
  return (
    <Container>
      <Typography variant="$font-body-2" color="$color-text-secondary">
        Sem dados a serem exibidos ainda.
      </Typography>

      <Typography variant="$font-body-3" color="$color-text-secondary">
        {text}
      </Typography>

      {action && (
        <Button
          variant="outlined"
          color="primary"
          text={action}
          sx={{ marginTop: '17px' }}
          onClick={onClick}
        />
      )}
    </Container>
  )
}

EmptyState.defaultProps = {
  action: null,
  onClick: null,
}
export default EmptyState
