import { useMemo } from 'react'
import { BlockedFeatureContext } from './context'
import { BlockedFeatureControllerProps } from './types'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'

export function BlockedFeatureController({
  children,
  requiredRole = NomosProductCodeEnum.MONITOR_PRO,
}: BlockedFeatureControllerProps) {
  const store = useMemo(() => ({ requiredRole }), [requiredRole])
  return (
    <BlockedFeatureContext.Provider value={store}>
      {children}
    </BlockedFeatureContext.Provider>
  )
}
