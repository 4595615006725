import colors from '@theme/colors'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { styled as styledMui } from '@mui/material/styles'

import { AppBar, Box, BoxProps, MenuItem, Button } from '@mui/material'
import { HTMLAttributeAnchorTarget } from 'react'

export const MenuItemWrapper = styledMui(MenuItem)`
  display: flex;
  gap: 10px;
`

export const ApplicationBar = styledMui(AppBar)`
  box-shadow: none;
  background-color: white;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.scale.gray10};
`

export const LogoContainer = styledMui((props: BoxProps) => (
  <Box {...props} sx={{ display: { xs: 'none', md: 'flex' } }} />
))`
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
`

export const BoxMobile = styledMui((props: BoxProps) => (
  <Box {...props} sx={{ display: { xs: 'flex', md: 'none' } }} />
))({
  flexGrow: 1,
})

export const BoxProducts = styledMui((props: BoxProps) => (
  <Box {...props} sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} />
))({})

export const Menus = styled.div`
  display: flex;
  gap: 10px;
`

export const MenuContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const LinkNav = styledMui(NavLink)`
  text-decoration: none;
  &.active {
    text-decoration: none;
    border-top: 2px solid transparent;
    border-bottom: 2px solid #4070F4;
  }
`

export const ProductLink = styledMui(Link)`
  display: flex;
  gap: 10px;
  text-decoration: none;
  align-items: center;
`

export const MenuButton = styledMui(Button)<{
  target?: HTMLAttributeAnchorTarget
}>`
  width: auto;
  min-width: auto;
  min-height: 35px;
  max-height: 35px;
  border-radius: 4px;
`
