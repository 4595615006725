import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AcceptUserContext } from './context'
import { useAcceptUserAccount } from '@services/nomos_api/resources/user/accept'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'

export type AcceptUserProps = {
  children: React.ReactNode
}

export function AcceptUserController({ children }: AcceptUserProps) {
  const { token } = useParams()
  const { data, isFetching, isError, error } = useAcceptUserAccount(token || '')

  const axiosError = error as AxiosErrorResponse

  const store = useMemo(
    () => ({
      isError,
      isLoading: isFetching,
      response: axiosError?.response?.data || data,
    }),
    [data, isFetching]
  )

  return (
    <AcceptUserContext.Provider value={store}>
      {children}
    </AcceptUserContext.Provider>
  )
}
