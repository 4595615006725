import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { PropositionAttached } from '@services/nomos_api/entities/proposition'

export const getPropositionAttacheds = async (
  propositionId: string
): Promise<PropositionAttached[]> => {
  const { data } = await HTTPClient.get(
    `propositions/${propositionId}/listing/attached`
  )
  return data as PropositionAttached[]
}

export const useGetPropositionAttacheds = (propositionId: string) =>
  useQuery(
    ['get-proposition-attacheds'],
    () => getPropositionAttacheds(propositionId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
