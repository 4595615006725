import { createContext } from 'use-context-selector'
import { FormikProps } from 'formik'

export type FormRegistrationStep2FormType = {
  aboutOfficePosition: string
  companyName: string
  aboutSector: string
  aboutEmployees: string
  metadata?: {
    page_url?: string
    page_title?: string
    hubspotutk?: string
    utm_source?: string
    utm_medium?: string
    utm_content?: string
    utm_campaign?: string
  }
}

export type FormRegistrationStep2ContextProps = {
  formik: FormikProps<FormRegistrationStep2FormType>
}

export const FormRegistrationStep2Context = createContext(
  {} as FormRegistrationStep2ContextProps
)
