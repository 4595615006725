import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const IFrameContainer = styled(Box)`
  display: flex;
  flex: 1;
  & iframe {
    border-width: 0px;
  }
`
