import { useModal } from '@contexts/Modal'
import { useCallback, useMemo, useState } from 'react'
import { PropositionsExportModalControllerProps } from './PropositionsExportModal.types'
import { PropositionsExportModalContext } from './PropositionsExportModal.context'
import HTTPClient from '@services/nomos_api'
import { useSnackbar } from '@contexts/Snackbar'
import { get } from 'lodash'

export function PropositionsExportModalController({
  children,
  initialForm,
}: PropositionsExportModalControllerProps): JSX.Element {
  const { closeModal } = useModal()
  const [pdf, setPdf] = useState<boolean>(false)
  const [xls, setXls] = useState<boolean>(false)
  const [docx, setDocx] = useState<boolean>(false)
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()

  const handleExport = useCallback(async () => {
    const selectedFileTypes = []

    if (xls) selectedFileTypes.push('XLSX')
    if (pdf) selectedFileTypes.push('PDF')
    if (docx) selectedFileTypes.push('DOCX')

    const payload = {
      formats: selectedFileTypes,
      propositionIds: initialForm.selectedIds,
      keywords: initialForm.keywords,
      subtitle: initialForm.subtitle,
    }

    try {
      const response = await HTTPClient.post('/reports/propositions', payload)

      if (response.status === 200 && response.data?.url) {
        showSnackbarSuccess(
          'Relatório gerado com sucesso',
          'O relatório foi gerado com sucesso e deve abrir em uma nova aba do seu navegador.'
        )
        if (response.data.url) {
          const urls = response.data.url as Array<string>
          urls.forEach((url) => {
            window.open(url, '_blank')
          })
        }
      } else {
        showSnackbarError(
          'Erro ao gerar relatório',
          'Não foi possível gerar o relatório para as proposições selecionadas.'
        )
      }
    } catch (error) {
      showSnackbarError(
        'Erro ao conectar com o backend:',
        get(
          error,
          'response.data.message',
          'Ocorreu um erro inesperado ao tentar conectar com o backend.'
        )
      )
    }

    closeModal()
  }, [
    xls,
    pdf,
    docx,
    initialForm.selectedIds,
    showSnackbarError,
    showSnackbarSuccess,
    closeModal,
  ])

  const state = useMemo(
    () => ({
      pdf,
      setPdf,
      xls,
      setXls,
      docx,
      setDocx,
      handleExport,
    }),
    [pdf, setPdf, xls, setXls, docx, setDocx, handleExport]
  )

  return (
    <PropositionsExportModalContext.Provider value={state}>
      {children}
    </PropositionsExportModalContext.Provider>
  )
}
