import { useDrawer } from '@contexts/Drawer'
import Selector from './containers/Selector'
import { IconButton, Typography } from '@mui/material'

import {
  Container,
  ContentBody,
  HeaderTitle,
  Header,
  IconClose,
} from './styles'

export function NotificationView() {
  const { closeDrawer } = useDrawer()

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Notificações
          </Typography>
          <IconButton data-testid="close-save-search-btn" onClick={closeDrawer}>
            <IconClose />
          </IconButton>
        </HeaderTitle>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Veja abaixo as notificações referente aos dados encontrados de acordo
          com as suas pesquisas salvas
        </Typography>
      </Header>
      <ContentBody>
        <Selector />
      </ContentBody>
    </Container>
  )
}
