import feedbacks from './types'
import { useMemo } from 'react'
import { FeedbackContext } from './context'
import { useParams, useLocation } from 'react-router-dom'

export type FeedbackProps = {
  children: React.ReactNode
}

export function FeedbackController({ children }: FeedbackProps) {
  const { type } = useParams()
  const location = useLocation()
  const email = location.state?.email

  const store = useMemo(() => {
    const feedback = feedbacks[type || 'default']
    const updatedSubtitle =
      feedback.subtitle && email
        ? feedback.subtitle.replace('{email}', email)
        : feedback.subtitle || undefined

    return {
      data: {
        ...feedback,
        subtitle: updatedSubtitle,
      },
      type,
    }
  }, [type, email])

  return (
    <FeedbackContext.Provider value={store}>
      {children}
    </FeedbackContext.Provider>
  )
}
