import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { SocialEntity } from '@services/nomos_api/entities/social'

export const getSocialByIdAPI = async (id: string): Promise<SocialEntity> => {
  const { data } = await HTTPClient.get(`social/${id}`)
  return data as SocialEntity
}

export const useGetSocialDetails = (id: string) =>
  useQuery(['get-social-details'], () => getSocialByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
