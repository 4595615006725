import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'

export const Container = styled(Stack)`
  margin: 20px 0;
  padding: 24px;
  border-radius: 8px;
  justify-content: space-between;
  gap: 16px;
`

export const TypographyDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Table = styled.table`
  width: 100%;
`
export const Tr = styled.tr`
  :last-child td {
    padding-top: 12px;
  }
  td:first-child {
    width: 0px;
    vertical-align: top;
  }
  td:last-child {
    padding-left: 24px;
  }
`
export const Td = styled.td`
  text-align: left;
`
