import { Stack, Typography } from '@mui/material'
import { InputPickMonitor } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'

export function MonitoringFormManagePanels(): JSX.Element {
  const refInputMonitor = useContextSelector(
    SaveInMonitorContext,
    (s) => s.refInputMonitor
  )
  const selectedPanels = useContextSelector(
    SaveInMonitorContext,
    (s) => s.selectedPanels
  )

  const setSelectedPanels = useContextSelector(
    SaveInMonitorContext,
    (s) => s.setSelectedPanels
  )

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="$font-title-5" color="$color-text-secondary">
            Selecione ou crie um Painel
          </Typography>
          <Typography variant="$font-title-5" color="$color-semantic-error">
            *
          </Typography>
        </Stack>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Selecione ou crie um painel para <b>salvar</b> o dado selecionado.
        </Typography>
      </Stack>
      <InputPickMonitor
        ref={refInputMonitor}
        placeholder="Selecionar Painel"
        initialMonitors={selectedPanels}
        onChangeOptions={setSelectedPanels}
      />
    </Stack>
  )
}
