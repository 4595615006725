/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  useMemo,
  useState,
  forwardRef,
  useCallback,
  SyntheticEvent,
  useImperativeHandle,
  useEffect,
} from 'react'
import { InputPickMonitorContext } from './InputPickMonitor.context'
import {
  InputPickMonitorControllerProps,
  InputPickMonitorRef,
} from './InputPickMonitor.types'
import { useGetAllMonitorsOnDemand } from '@services/nomos_api/resources/monitor/list'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'
import { createMonitorsAPI } from '@services/nomos_api/resources/monitor/create'
import { MonitorEntity } from '@entities/MonitorEntity'

// Define the type for the exposed functions

function InputPickMonitorController(
  {
    children,
    initialMonitors,
    onChangeOptions,
  }: InputPickMonitorControllerProps,
  ref: React.Ref<InputPickMonitorRef>
): JSX.Element {
  const [textSearchMonitor, setTextSearchMonitor] = useState('')

  const {
    data: monitors,
    refetch: fetchMonitors,
    isLoading,
  } = useGetAllMonitorsOnDemand({
    roles: 'ADMIN,EDITOR',
  })

  const [selectedMonitors, setSelectedMonitors] = useState<
    MultiSelectChipOption[]
  >(initialMonitors || [])

  const unselectedMonitors: MultiSelectChipOption[] = useMemo(() => {
    return monitors?.map((m) => ({ key: m.id, label: m.name })) || []
  }, [monitors])

  const handleChangeOptions = useCallback(
    (_: SyntheticEvent<Element, Event>, options: MultiSelectChipOption[]) => {
      setSelectedMonitors(options)
    },
    [selectedMonitors, setSelectedMonitors]
  )

  const createPendingMonitors = useCallback((): Promise<
    MultiSelectChipOption[]
  > => {
    return new Promise((resolve) => {
      const existents = selectedMonitors.filter((monitor) => !!monitor.key)
      const pendings = selectedMonitors.filter((monitors) => !monitors.key)
      if (pendings.length === 0) {
        resolve(existents)
      } else {
        createMonitorsAPI(
          pendings.map((monitor) => ({
            name: monitor.label,
            public: false,
          }))
        ).then((newMonitors: MonitorEntity[]) => {
          const newData = [
            ...existents,
            ...newMonitors.map((monitor: MonitorEntity) => ({
              key: String(monitor.id),
              label: String(monitor.name),
            })),
          ]
          setSelectedMonitors(newData)
          resolve(newData)
        })
      }
    })
  }, [selectedMonitors, setSelectedMonitors])

  // Expose the internalFunction to the ref
  useImperativeHandle(ref, () => ({
    createPendingMonitors,
  }))

  useEffect(() => {
    onChangeOptions?.(selectedMonitors)
  }, [selectedMonitors])

  const state = useMemo(
    () => ({
      isLoading,
      monitors,
      selectedMonitors,
      unselectedMonitors,
      textSearchMonitor,
      setTextSearchMonitor,
      setSelectedMonitors,
      handleChangeOptions,
      fetchMonitors,
    }),
    [
      isLoading,
      monitors,
      selectedMonitors,
      unselectedMonitors,
      textSearchMonitor,
      setTextSearchMonitor,
      setSelectedMonitors,
      handleChangeOptions,
      fetchMonitors,
    ]
  )
  return (
    <InputPickMonitorContext.Provider value={state}>
      {children}
    </InputPickMonitorContext.Provider>
  )
}

export default forwardRef(InputPickMonitorController)
