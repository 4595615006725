import { createContext } from 'use-context-selector'
import { IrsEntity } from '@services/nomos_api/entities/irs'

export type IrsDetailsContextType = {
  irs: IrsEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const IrsDetailsContext = createContext<IrsDetailsContextType>(
  {} as IrsDetailsContextType
)
