import { SVGProps } from 'react'

function UsersIcon({
  width = 16,
  height = 16,
  fill = 'none',
  stroke = '#606162',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill={fill}>
      <path
        d="M1.535 11.25v-.75a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.75m0-6a2.25 2.25 0 0 0 0-4.5m4.5 10.5v-.75a3 3 0 0 0-3-3h-.375M8.285 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UsersIcon
