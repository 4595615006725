import styled from 'styled-components'
import { Box, Stack, styled as styledMaterial } from '@mui/material'

export const Container = styledMaterial(Box)(() => ({
  backgroundColor: '#F9FAFC',
}))

export const Wrapper = styledMaterial(Stack)(() => ({
  margin: '0 auto',
  padding: '40px 0 48px 0',
  minHeight: '76vh',
  height: '100%',
  backgroundColor: '#F9FAFC',
}))

export const Content = styled.div``
