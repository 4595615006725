import { Stack, styled, Box } from '@mui/material'
import { theme } from '@theme/index'

export const FilterContainer = styled(Stack)`
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;
  & > * {
    @media (min-width: 768px) {
      flex: 1;
    }
  }
`

export const CardProposition = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${theme.palette['$color-border-primary']};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
