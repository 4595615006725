import { AxiosResponse } from 'axios'
import { EditSavedSearchView } from './view'
import { EditSavedSearchController } from './controller'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'

export type EditSavedSearchProps = {
  search: SavedSearchEntity
  onSuccess: (response: AxiosResponse) => void
}

export default function EditSavedSearch(props: EditSavedSearchProps) {
  return (
    <EditSavedSearchController {...props}>
      <EditSavedSearchView />
    </EditSavedSearchController>
  )
}
