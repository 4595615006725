import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

import {
  OrganDocCountEntity,
  OrganDocEntity,
} from '@services/nomos_api/entities/organ'

export const getOrganDocumentsCountAPI = async (
  id?: string
): Promise<OrganDocCountEntity[]> => {
  if (!id) return [] as OrganDocCountEntity[]
  const { data } = await HTTPClient.get(`organs/${id}/docs/count`)
  return data as OrganDocCountEntity[]
}

export const useGetOrganDocumentsCount = (id?: string) =>
  useQuery(['get-organ-docs-count', id], () => getOrganDocumentsCountAPI(id), {
    refetchOnWindowFocus: false,
  })

export const getOrganDocumentsAPI = async (
  id?: string,
  type?: string | null
) => {
  if (!id || !type) return [] as OrganDocEntity[]
  const { data } = await HTTPClient.get(`organs/${id}/docs/${type}`)
  return data as OrganDocEntity[]
}

export const useGetOrganDocuments = (id?: string, type?: string | null) =>
  useQuery(['get-organ-docs', id, type], () => getOrganDocumentsAPI(id, type), {
    refetchOnWindowFocus: false,
  })
