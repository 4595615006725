import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import { ErrorOutline, Close } from '@mui/icons-material'
import { Container, Content, Footer, Header } from './styles'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export type RemoveSearchModalProps = {
  onConfirm: () => void
}

export default function RemoveSearchModal({
  onConfirm,
}: RemoveSearchModalProps) {
  useByPassSubscription()

  const { closeModal } = useModal()

  const handleExclude = () => {
    if (onConfirm) {
      onConfirm()
    }
    closeModal()
  }

  return (
    <Container>
      <Header>
        <Close onClick={closeModal} />
      </Header>

      <Content>
        <div>
          <ErrorOutline />
          <Typography variant="$font-title-3" color="$color-text-primary">
            Excluir Pesquisa Salva?
          </Typography>
        </div>

        <Typography
          variant="$font-body-base"
          color="$color-text-primary"
          maxWidth={420}
        >
          Ao excluir a pesquisa, automáticamente você deixará de receber os
          alertas programados para ela quando um novo dado for encontrado
        </Typography>

        <Typography variant="$font-body-2" color="muted.dark">
          Você tem certeza que deseja excluir esta pesquisa?
        </Typography>
      </Content>

      <Footer>
        <Stack direction="row" spacing={2}>
          <Button
            text="Excluir"
            variant="outlined"
            onClick={() => handleExclude()}
          />

          <Button text="Cancelar" onClick={closeModal} />
        </Stack>
      </Footer>
    </Container>
  )
}
