import { RemovePropositionTabController } from './controller'
import { TabRemoveFromMonitorProps } from './types'
import { RemovePropositionTabView } from './view'

function RemovePropositionTabPage(props: TabRemoveFromMonitorProps) {
  return (
    <RemovePropositionTabController {...props}>
      <RemovePropositionTabView />
    </RemovePropositionTabController>
  )
}

export default RemovePropositionTabPage
