import styled from 'styled-components'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '32px',
  marginBottom: '32px',
})
