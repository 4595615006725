import StakeholderDetailView from './view'
import StakeholderDetailController from './controller'

export default function StakeholderDetail() {
  return (
    <StakeholderDetailController>
      <StakeholderDetailView />
    </StakeholderDetailController>
  )
}
