import { Card } from '@components/atoms'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 66px;
`

export const FiltersContainer = styled.div`
  display: flex;
  gap: 29px;
  flex-wrap: wrap;
`

export const TableContainer = styled(Card)`
  padding: 16px;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 32px;
    gap: 32px;
  }
`
