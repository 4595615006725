import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 32px;
`

export const Content = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`
