import { Bucket, BucketsEnum } from './type'

const BucketNotices: Bucket = {
  key: BucketsEnum.notices,
  name: 'Notícias',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'resources',
      label: 'Fonte',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [
        { key: 'g1', value: 'G1' },
        { key: 'r7', value: 'R7' },
        { key: 'cnn', value: 'CNN' },
        { key: 'stj', value: 'STJ' },
        { key: 'gazeta', value: 'Gazeta do Povo' },
        { key: 'folha', value: 'Folha de São Paulo' },
        { key: 'camara', value: 'Camara dos Deputados' },
        { key: 'senado', value: 'Senado Federal' },
      ],
    },
  ],
}

export default BucketNotices
