import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { Shimmer } from '@components/atoms'
import { Button } from '@components/atoms'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { Stack, Typography } from '@mui/material'
import { SpeechDetailsContext } from '../../context'
import { ArrowForwardIos } from '@mui/icons-material'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { Container, ContentPaths, TextLabel, TextDescription } from './styles'

import NotesIcon from '@assets/icons/notes.svg'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()
  const speech = useContextSelector(SpeechDetailsContext, (s) => s.speech)
  const buckets = useContextSelector(SpeechDetailsContext, (s) => s.buckets)
  const keyword = useContextSelector(SpeechDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(SpeechDetailsContext, (s) => s.isLoading)

  const title = speech
    ? `${speech?.speaker}, ${speech?.acronymParty} - ${speech?.uf}`
    : ''

  const params = `?buckets=${getSortedBucket(
    buckets,
    'speeches'
  )}&keyword=${keyword}`

  return (
    <Container>
      {auth && (
        <ContentPaths>
          <NavLink to={isMobile ? "/search-results" : "/home"}>
            <TextLabel>Inicio</TextLabel>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <TextLabel>Discursos</TextLabel>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <Shimmer isLoading={isLoading} width={80} height={18}>
            <TextDescription>Discurso {speech?.camara?.code}</TextDescription>
          </Shimmer>
        </ContentPaths>
      )}
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flexWrap='wrap'>
        <Shimmer isLoading={isLoading} width={240} height={38}>
          <Typography variant="$font-title-2" color="$color-text-primary">
            {title}
          </Typography>
        </Shimmer>
        <Button
          text="Notas"
          color="$color-extended-red1"
          variant="contained"
          endIcon={<img src={NotesIcon} alt="Notas" />}
          onClick={() => {
            openDrawer(<NoteManagerDrawer id={speech.id} bucket="speeches" />)
          }}
        />
      </Stack>

    </Container>
  )
}

export default HeaderComponent
