import colors from '@theme/colors'
import { Stack, styled } from '@mui/material'

export const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 656px;
`

export const Header = styled(Stack)`
  margin: 40px 0;

  flex-direction: row;
  color: #b2bbbf;
  flex-wrap: wrap;
  gap: 1rem;
  svg {
    color: ${colors['$color-text-tertiary']};
  }

  label {
    color: ${colors.scale.gray60};
  }

  @media (min-width: 768px) {
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`

export const Content = styled(Stack)`
  display: flex;
  gap: 32px;

  padding-right: 16px;
  margin-bottom: 45px;

  overflow-y: auto;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 5px;
  },

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  },

  &::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  },

  &::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  },
`

export const CheckBoxContainer = styled(Stack)`
  flex-direction: row;
  gap: 10px;
  padding-right: 25px;
`
export const CheckBox = styled(Stack)`
  flex-direction: row;
  gap: 5px;
`
