import styled from 'styled-components'
import { Card } from '@components/atoms'

export const AppBar = styled(Card)<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ height }) => `${height}px`};
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
`
