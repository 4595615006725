import { Bucket, BucketsEnum } from './type'

const BucketOrgans: Bucket = {
  key: BucketsEnum.organs,
  name: 'Orgãos',
  mode: 'monitor',
  sorts: [],
  periods: [],
  filters: [],
}

export default BucketOrgans
