import { UrlType } from '@type/url'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const generateStripeManageSubscriptionLink =
  async (): Promise<UrlType> => {
    const { data } = await HTTPClient.get<UrlType>('manage-subscription')
    return data
  }

export const useStripeManageSubscriptionLink = () =>
  useQuery(
    ['stripe-manage-subscription-link'],
    () => generateStripeManageSubscriptionLink(),
    {
      refetchOnWindowFocus: false,
    }
  )
