import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'

export type OrganMembersCount = {
  holders: number
  substitutes: number
  total: number
}

export const getOrganMembersCountAPI = async (id?: string) => {
  if (!id) return {} as OrganMembersCount
  const { data } = await HTTPClient.get(`organs/${id}/members/count`)
  return data as OrganMembersCount
}

export const useGetOrganMembersCount = (id?: string) =>
  useQuery(['get-organ-members-count', id], () => getOrganMembersCountAPI(id), {
    refetchOnWindowFocus: false,
  })

export const getOrganMembersAPI = async (id?: string, role?: string) => {
  if (!id) return [] as StakeholderEntity[]
  const { data } = await HTTPClient.get(`organs/${id}/members?role=${role}`)
  return data as StakeholderEntity[]
}

export const useGetOrganMembers = (id?: string, role?: string) =>
  useQuery(
    ['get-organ-members', id, role],
    () => getOrganMembersAPI(id, role),
    {
      refetchOnWindowFocus: false,
    }
  )
