import styled from 'styled-components'

export const FiltersContainer = styled.div`
  display: flex;
  gap: 29px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
