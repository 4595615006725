import { useContext } from 'react'
import { Button, Input } from '@components/atoms'
import { CompanyInfoModalContext } from './context'

import sectors from '@utils/forms/sectors.json'
import positions from '@utils/forms/positions.json'

import {
  Chip,
  Grid,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormHelperText,
} from '@mui/material'

import {
  Chips,
  Header,
  Container,
  FormContainer,
  SelectContainer,
} from './styles'

export default function CompanyInfoModaView() {
  const { review, formik, submitting } = useContext(CompanyInfoModalContext)

  const handleChangeEmployees = (value: string) => {
    formik.setFieldValue('aboutEmployees', value)
  }

  return (
    <Container>
      <Header>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Antes de iniciar, vamos confirmar algumas informações
        </Typography>
        <Typography variant="$font-body-1" color="$color-text-secondary">
          Essas informações nos ajudará a personalizar sua experiência{' '}
        </Typography>
      </Header>

      <form onSubmit={formik.handleSubmit}>
        <FormContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="$font-title-4" color="$color-text-primary">
                  Quantas pessoas trabalham na sua empresa?
                </Typography>
                <Chips>
                  <Chip
                    label="1-5"
                    variant={
                      formik.values.aboutEmployees === '1'
                        ? 'filled'
                        : 'outlined'
                    }
                    color="primary"
                    onClick={() => handleChangeEmployees('1-5')}
                  />

                  <Chip
                    label="5-25"
                    variant={
                      formik.values.aboutEmployees === '5-25'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('5-25')}
                    color="primary"
                  />
                  <Chip
                    label="25-50"
                    variant={
                      formik.values.aboutEmployees === '25-50'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('25-50')}
                    color="primary"
                  />
                  <Chip
                    label="50-100"
                    variant={
                      formik.values.aboutEmployees === '50-100'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('50-100')}
                    color="primary"
                  />
                  <Chip
                    label="100-500"
                    variant={
                      formik.values.aboutEmployees === '100-500'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('100-500')}
                    color="primary"
                  />
                  <Chip
                    label="500-1000"
                    variant={
                      formik.values.aboutEmployees === '500-1000'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('500-1000')}
                    color="primary"
                  />
                  <Chip
                    label="1000+"
                    variant={
                      formik.values.aboutEmployees === '1000+'
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => handleChangeEmployees('1000+')}
                    color="primary"
                  />
                </Chips>
                <FormHelperText error>
                  {(formik.touched.aboutEmployees &&
                    formik.errors.aboutEmployees) ||
                    review?.errors?.aboutEmployees}
                </FormHelperText>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={2}>
                <Typography variant="$font-title-4" color="$color-text-primary">
                  Qual o seu cargo?
                </Typography>
                <SelectContainer>
                  <InputLabel>Cargo</InputLabel>
                  <Select
                    id="aboutOfficePosition"
                    name="aboutOfficePosition"
                    size="small"
                    label="Cargo"
                    value={formik.values.aboutOfficePosition}
                    error={
                      (formik.touched.aboutOfficePosition &&
                        Boolean(formik.errors.aboutOfficePosition)) ||
                      review?.errors?.aboutOfficePosition
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {positions.map((position) => (
                      <MenuItem value={position}>{position}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {(formik.touched.aboutOfficePosition &&
                      formik.errors.aboutOfficePosition) ||
                      review?.errors?.aboutOfficePosition}
                  </FormHelperText>
                </SelectContainer>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={2}>
                <Typography variant="$font-title-4" color="$color-text-primary">
                  Em qual setor sua empresa atua?
                </Typography>
                <SelectContainer>
                  <InputLabel>Setor de atuação</InputLabel>
                  <Select
                    id="aboutSector"
                    name="aboutSector"
                    size="small"
                    label="Setor de atuação"
                    value={formik.values.aboutSector}
                    error={
                      (formik.touched.aboutSector &&
                        Boolean(formik.errors.aboutSector)) ||
                      review?.errors?.aboutSector
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {Object.keys(sectors).map((sector) => (
                      <MenuItem value={sector}>{sector}</MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {(formik.touched.aboutSector &&
                      formik.errors.aboutSector) ||
                      review?.errors?.aboutSector}
                  </FormHelperText>
                </SelectContainer>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="$font-title-4" color="$color-text-primary">
                  Informe o nome da sua empresa
                </Typography>
                <Input
                  id="companyName"
                  name="companyName"
                  label="Nome"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.name && Boolean(formik.errors.name)) ||
                    review?.errors?.name
                  }
                  helpertext={
                    (formik.touched.name && formik.errors.name) ||
                    review?.errors?.name
                  }
                />
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography variant="$font-title-4" color="$color-text-primary">
                Qual o CNPJ da empresa?
              </Typography>
              <Input
                id="document"
                name="document"
                label="CNPJ"
                sx={{ width: 300 }}
                value={formik.values.document}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.document && Boolean(formik.errors.document)
                }
                helpertext={formik.touched.document && formik.errors.document}
              />
            </Grid> */}
          </Grid>

          <Button
            type="submit"
            size="medium"
            text="Concluir"
            loading={submitting}
            disabled={!(formik.isValid && formik.dirty)}
            sx={{ width: 104, alignSelf: 'flex-end' }}
          />
        </FormContainer>
      </form>
    </Container>
  )
}
