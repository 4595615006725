import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BacenDetailsContext } from './context'
import { useGetBacenDetails } from '@services/nomos_api/resources/bacen/getById'

type SpeechDetailsControllerProps = {
  children: React.ReactNode
}

export function SpeechDetailsController({
  children,
}: SpeechDetailsControllerProps) {
  const { id } = useParams()
  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const { data: bacen, isLoading } = useGetBacenDetails(id || '')

  const state = useMemo(
    () => ({
      bacen,
      keyword,
      buckets,
      isLoading,
    }),
    [bacen, isLoading, keyword, buckets]
  )

  return (
    <BacenDetailsContext.Provider value={state}>
      {children}
    </BacenDetailsContext.Provider>
  )
}
