import { ExpandMore } from '@mui/icons-material'
import { Button } from '@components/atoms'
import {
  FilterWrapper,
  FilterAccordion,
  FilterAccordionTitleContainer,
  FilterAccordionTitle,
  FilterAccordionSummary,
  FilterAccordionDetails,
  FilterAccordionInline,
  FilterAccordionLabel,
} from './SideFilterSelect.styles'
import { useContextSelector } from 'use-context-selector'
import { SideFilterSelectContext } from './SideFilterSelectContext'
import { SideFilterSelectOptions } from './SideFilterSelectOptions'
import { SideFilterSelectLoading } from './SideFilterSelectLoading'
import { SideFilterSelectCheckAll } from './SideFilterSelectCheckAll'
import { SideFilterSelectCounter } from './SideFilterSelectCounter'
import { SideFilterSelectInputSearch } from './SideFilterSelectInputSearch'

export function SideFilterSelectView(): JSX.Element {
  const filter = useContextSelector(SideFilterSelectContext, (s) => s.filter)

  const setExpanded = useContextSelector(
    SideFilterSelectContext,
    (s) => s.setExpanded
  )

  const handleRemoveAll = useContextSelector(
    SideFilterSelectContext,
    (s) => s.handleRemoveAll
  )

  return (
    <FilterWrapper>
      <FilterAccordion
        data-cy={`side-filter-select-component-${filter.label}`}
        onChange={(_, expanded) => setExpanded(expanded)}
      >
        <FilterAccordionSummary expandIcon={<ExpandMore fontSize="small" />}>
          <FilterAccordionTitleContainer>
            <FilterAccordionTitle>{filter.label}</FilterAccordionTitle>
            <SideFilterSelectCounter />
          </FilterAccordionTitleContainer>
        </FilterAccordionSummary>
        <FilterAccordionDetails>
          <SideFilterSelectInputSearch />
          <FilterAccordionInline>
            <FilterAccordionLabel>Selecionados</FilterAccordionLabel>
            <Button
              text="Limpar"
              size="small"
              variant="text"
              onClick={() => handleRemoveAll()}
            />
          </FilterAccordionInline>
          <SideFilterSelectCheckAll />
          <SideFilterSelectLoading />
          <SideFilterSelectOptions />
        </FilterAccordionDetails>
      </FilterAccordion>
    </FilterWrapper>
  )
}
