import { PropositionStatusNotificationControllerProps } from './types'
import { PropositionStatusNotificationContext } from './context'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'
import { useSearchOptions } from '@services/nomos_api/resources/search/options'

export function PropositionStatusNotificationController({
  children,
  monitors,
  alertChannel,
  alertFrequency,
  enabledNotification,
  formSavedBucket,
  propositionStatus,
  setAlertChannel,
  setAlertFrequency,
  setEnabledNotification,
  setPropositionStatus,
  handleSubmit,
  house,
  isSavingBucket,
  disableDirty,
}: PropositionStatusNotificationControllerProps) {
  const [, setTextSearchStatus] = useState('')
  const [selectedStatus, setSelectedStatus] = useState<MultiSelectChipOption[]>(
    []
  )

  const handleChangeOptions = useCallback(
    (_: SyntheticEvent<Element, Event>, options: MultiSelectChipOption[]) => {
      setSelectedStatus(options)
    },
    [selectedStatus, setSelectedStatus]
  )
  const { data: situations } = useSearchOptions(
    'propositions',
    'situations',
    house !== undefined ? [house] : []
  )

  useEffect(() => {
    setPropositionStatus(selectedStatus.map((status) => status.key))
  }, [selectedStatus, setPropositionStatus])

  const unselectedStatus: MultiSelectChipOption[] = useMemo(() => {
    if (!situations) return []
    return situations.map((m) => ({ key: m.key, label: m.value }))
  }, [situations, selectedStatus])

  useEffect(() => {
    if (situations) {
      setSelectedStatus(
        situations
          .filter((i) => propositionStatus.includes(i.key))
          .map((i) => ({
            key: i.key,
            label: i.value,
          }))
      )
    }
  }, [situations])

  // expose shared states
  const store = useMemo(
    () => ({
      monitors,
      alertChannel,
      alertFrequency,
      enabledNotification,
      formSavedBucket,
      propositionStatus,
      setAlertChannel,
      setAlertFrequency,
      setEnabledNotification,
      setPropositionStatus,
      selectedStatus,
      unselectedStatus,
      handleChangeOptions,
      setTextSearchStatus,
      handleSubmit,
      isSavingBucket,
      disableDirty,
    }),
    [
      monitors,
      alertChannel,
      alertFrequency,
      enabledNotification,
      formSavedBucket,
      propositionStatus,
      setAlertChannel,
      setAlertFrequency,
      setEnabledNotification,
      setPropositionStatus,
      selectedStatus,
      unselectedStatus,
      handleChangeOptions,
      setTextSearchStatus,
      handleSubmit,
      isSavingBucket,
      disableDirty,
    ]
  )

  return (
    <PropositionStatusNotificationContext.Provider value={store}>
      {children}
    </PropositionStatusNotificationContext.Provider>
  )
}

// export default PropositionStatusNotificationController
