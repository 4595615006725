import { Card } from '@components/atoms'
import { styled as StyledMui, Box } from '@mui/material'

export const FilterableCard = StyledMui(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '490px',
  padding: '16px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
    padding: '24px',
  },
}))

export const ContainerColumn = StyledMui(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export const ContainerRow = StyledMui(Box)(() => ({
  display: 'flex',
  gap: '4px',
}))

export const ContainerEnd = StyledMui(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '24px',
}))

export const Checkbox = StyledMui(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}))

export const Header = StyledMui(Box)(({ theme }) => ({
  display: 'flex',

  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const List = StyledMui(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '22px',
  overflow: 'auto',
  minHeight: '100px',
  gap: '32px',
}))

export const Guideline = StyledMui(Box)(() => ({
  display: 'flex',
  width: '308px',
  justifyContent: 'space-between',
}))

export const NoData = StyledMui(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
