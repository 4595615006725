import { HomeView } from './view'
import { HomeController } from './controller'

function HomePage() {
  return (
    <HomeController>
      <HomeView />
    </HomeController>
  )
}

export default HomePage
