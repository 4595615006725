import { AvatarProps } from '@mui/material'
import { AvatarWrapper } from './Avatar.styles'

export interface AvatarComponentProps extends AvatarProps {
  width: number
  height: number
}

export default function AvatarComponent(props: AvatarComponentProps) {
  return <AvatarWrapper {...props} />
}
