import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  databaseURL: process.env.REACT_APP_REALTIME_DB_URL,
}

const app = initializeApp(firebaseConfig)

export const db = getDatabase(app)
