import { DataFoundController } from './controller'
import { DataFoundView } from './view'

export type DataFoundProps = {
  monitorId?: string
}

function DataFound(props: DataFoundProps) {
  return (
    <DataFoundController {...props}>
      <DataFoundView />
    </DataFoundController>
  )
}

DataFound.defaultProps = {
  monitorId: null,
}

export default DataFound
