/* eslint-disable no-unused-vars */
import { uid } from 'uid/single'
import { useContextSelector } from 'use-context-selector'
import { EventsCalendar } from '@containers/EventsCalendar'
import { CardSocial } from '@components/organisms/Cards/CardSocial'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import { CardDou } from '../../../../../components/organisms/Cards/CardDou'
import { CardIrs } from '../../../../../components/organisms/Cards/CardIrs'
import { CardCvm } from '../../../../../components/organisms/Cards/CardCvm'
import { CardBacen } from '../../../../../components/organisms/Cards/CardBacen'
import { CardNotice } from '../../../../../components/organisms/Cards/CardNotice'
import { CardSpeeches } from '../../../../../components/organisms/Cards/CardSpeech'
import { CardProposition } from '../../../../../components/organisms/Cards/CardProposition'
import { CardOficialDiary } from '../../../../../components/organisms/Cards/CardOficialDiary'
import { CardRegulatoryAngency } from '../../../../../components/organisms/Cards/CardRegulatoryAngency'

function FormListResultsComponent() {
  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const dataDous = useContextSelector(SearchResultsContext, (s) => s.dataDous)

  const dataCvm = useContextSelector(SearchResultsContext, (s) => s.dataCvm)

  const dataIrs = useContextSelector(SearchResultsContext, (s) => s.dataIrs)

  const dataRegulatoryAgencies = useContextSelector(SearchResultsContext, (s) => s.dataRegulatoryAgencies)

  const dataBacen = useContextSelector(SearchResultsContext, (s) => s.dataBacen)

  const dataEvents = useContextSelector(
    SearchResultsContext,
    (s) => s.dataEvents
  )

  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )

  const dataPropositions = useContextSelector(
    SearchResultsContext,
    (s) => s.dataPropositions
  )

  const dataOficialDiary = useContextSelector(
    SearchResultsContext,
    (s) => s.dataOficialDiary
  )

  const dataSpeeches = useContextSelector(
    SearchResultsContext,
    (s) => s.dataSpeeches
  )

  const dataNotices = useContextSelector(
    SearchResultsContext,
    (s) => s.dataNotices
  )

  const dataSocial = useContextSelector(
    SearchResultsContext,
    (s) => s.dataSocial
  )

  const selected = useContextSelector(
    SearchResultsContext,
    (s) => s.selected
  )

  const setSelected = useContextSelector(
    SearchResultsContext,
    (s) => s.setSelected
  )

  const handleSelected = (id, state) => {
    if (state) {
      setSelected(set => new Set(set.add(id)))
    } else {
      setSelected(set => {const ns = new Set(set); ns.delete(id); return ns;})}
  }

  if (selectedBucket === 'dou'){
    return dataDous?.results?.map((dou) => (
      <CardDou dou={dou} key={uid()} />
    ))
  }

  if (selectedBucket === 'bacen'){
    return dataBacen?.results?.map((bacen) => (
      <CardBacen bacen={bacen} key={uid()} />
    ))
  }

  if (selectedBucket === 'cvm'){
    return dataCvm?.results?.map((cvm) => (
      <CardCvm cvm={cvm} key={uid()} />
    ))
  }

  if (selectedBucket === 'irs'){
    return dataIrs?.results?.map((irs) => (
      <CardIrs irs={irs} key={uid()} />
    ))
  }

  if (selectedBucket === 'regulatory_agencies'){
    return dataRegulatoryAgencies?.results?.map((regulatoryAgency) => (
      <CardRegulatoryAngency regulatoryAgency={regulatoryAgency} key={uid()} />
    ))
  }

  if (selectedBucket === 'events'){
    return <EventsCalendar
      handleUpdateSearch={handleUpdateSearch}
      events={dataEvents?.results || []}
    />
  }

  if (selectedBucket === 'propositions'){
    return dataPropositions?.results?.map((proposition) => (
      <CardProposition proposition={proposition} selected={selected.has(proposition.id)} setSelected={(state) => handleSelected(proposition.id, state)} key={uid()} />
    ))
  }


  if (selectedBucket === 'oficial_diary'){
    return dataOficialDiary?.results?.map((diary) => (
      <CardOficialDiary diary={diary} key={uid()} />
    ))
  }

  if (selectedBucket === 'speeches'){
    return dataSpeeches?.results?.map((speech) => (
      <CardSpeeches speech={speech} key={uid()} />
    ))
  }

  if (selectedBucket === 'notices'){
    return dataNotices?.results?.map((notice) => (
      <CardNotice notice={notice} key={uid()} />
    ))
  }

  if (selectedBucket === 'social'){
    return dataSocial?.results?.map((social) => (
      <CardSocial social={social} key={uid()} />
    ))
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

export default FormListResultsComponent
