import colors from '@theme/colors'
import { Avatar } from '@mui/material'
import { AvatarComponentProps } from './Avatar'
import { styled } from '@mui/material/styles'

export const AvatarWrapper = styled(Avatar)`
  width: ${(props: AvatarComponentProps) => props.width}px;
  height: ${(props: AvatarComponentProps) => props.height}px;
  border: 1px solid ${colors['$color-action-border']};
`
