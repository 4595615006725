import { useState } from 'react'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Table } from '@components/organisms'
import { columns } from '../../Votings/columns'
import { Shimmer, TextLink } from '@components/atoms'
import { useGetAllVotings } from '@services/nomos_api/resources/votings/read'

import { Container, SeeMore, TableContainer } from './styles'

export default function LastVotings() {
  const { id } = useParams()
  const [page, setPage] = useState(0)

  const { data, isFetching } = useGetAllVotings({
    page: page + 1,
    limit: 5,
    filters: {
      organ: id || '',
    },
  })

  const rows = data?.results || []

  return (
    <Container>
      <Typography variant="$font-title-4" color="$color-text-primary">
        Últimas Votações de Propostas Legislativas{' '}
      </Typography>

      <TableContainer>
        <Shimmer height={400} width="100%" isLoading={isFetching}>
          <Table
            loading={isFetching}
            rowsPerPageOptions={[5]}
            rows={rows}
            columns={columns}
            page={page}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 100}
            onPageChange={setPage}
            hideFooterPagination
          />
        </Shimmer>

        <SeeMore>
          <TextLink text="Ver mais" href="?tab=votings" />
        </SeeMore>
      </TableContainer>
    </Container>
  )
}
