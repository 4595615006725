import HTTPClient from '../..'

type SaveBucketApiProps = {
  bucket: string
  originId: string
  monitors: string[]
}

export const createSavedBucketApi = async (body: SaveBucketApiProps) => {
  const { data } = await HTTPClient.post('/favorites', body)
  return data
}
