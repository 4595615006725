import { createContext } from 'use-context-selector'
import { RegulatoryAgencyEntity } from '@services/nomos_api/entities/regulatory_agency'

export type RegulatoryAgencyDetailsContextType = {
  regulatoryAgency: RegulatoryAgencyEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const RegulatoryAgencyDetailsContext =
  createContext<RegulatoryAgencyDetailsContextType>(
    {} as RegulatoryAgencyDetailsContextType
  )
