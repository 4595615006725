/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useState, useEffect, useContext } from 'react'

import {
  isSameDay,
  addDays,
  subDays,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  endOfMonth,
  differenceInWeeks,
  isSameMonth,
} from 'date-fns'

import { ptBR } from 'date-fns/locale'

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

import { uid } from 'uid'

import {
  getDate,
  getEndOfWeek,
  getStartOfWeek,
} from '@utils/functions/dates/dates'

import { HeaderSelect } from './components/Header'
import { ViewMore } from './components/ViewMore'
import { DateSinglePicker } from './components/DateSinglePicker'

import {
  Container,
  Header,
  Picker,
  Content,
  CalendarDateTopDisplay,
  CalendarDateTopDatesDisplay,
  CalendarDateCenterDisplay,
  CalendarDateContent,
  CalendarDateSquareHeader,
  CalendarDateSquareListItem,
  CalendarDateSquareList,
  CalendarDateSquareMonth,
} from './styles'
import { EventsCalendarContext } from './context'
import { formatTime } from '@utils/functions/formatter/formatter'

function EventsCalendarMonth({ data, headerLocation, handleUpdateSearch }) {
  const [selectedEvents, setSelectedEvents] = useState([])
  const [selectedPopover, setSelectedPopover] = useState({
    date: null,
    anchorEl: null,
  })

  const [period, setPeriod] = useState({
    start: getStartOfWeek(startOfMonth(getDate(new Date()))),
    end: getEndOfWeek(endOfMonth(getDate(new Date()))),
  })

  const firstDay = startOfMonth(addDays(period.start, 10))

  const { handleOpenModalDetailsEvent } = useContext(EventsCalendarContext)

  const handleOpenEvent = useCallback((event) => {
    handleOpenModalDetailsEvent(event)
  }, [])

  const formattedData = data
    .filter(
      (item) =>
        isAfter(item.start, period.start) && isBefore(item.start, period.end)
    )
    .sort((a, b) => a.start - b.start)

  const handleNextPeriod = useCallback(() => {
    const start = addDays(period.end, 10)

    setPeriod({
      start: getStartOfWeek(startOfMonth(start)),
      end: getEndOfWeek(endOfMonth(start)),
    })
  }, [period])

  const handlePreviousPeriod = useCallback(() => {
    const start = subDays(period.start, 10)

    setPeriod({
      start: getStartOfWeek(startOfMonth(start)),
      end: getEndOfWeek(endOfMonth(start)),
    })
  }, [period])

  const handleChangePeriod = useCallback((date) => {
    if (!date) return
    const start = startOfMonth(date)

    setPeriod({
      start: getStartOfWeek(start),
      end: getEndOfWeek(endOfMonth(start)),
    })
  }, [])

  const handlePopoverOpen = (event, date, data) => {
    setSelectedPopover({
      date,
      anchorEl: event.target,
    })

    setSelectedEvents(data)
  }

  const handlePopoverClose = () => {
    setSelectedPopover({
      date: null,
      anchorEl: null,
    })
  }

  useEffect(() => {
    handleUpdateSearch({
      [`buckets.events.limit`]: 300,
      [`buckets.events.filter.date.from`]: format(period.start, 'yyyy-MM-dd'),
      [`buckets.events.filter.date.to`]: format(period.end, 'yyyy-MM-dd'),
    })
  }, [period])

  const grid = 7
  const squareSize = 115

  const rows = differenceInWeeks(period.end, period.start) + 1

  return (
    <Container>
      <Header location={headerLocation}>
        <Picker>
          <KeyboardArrowLeft onClick={handlePreviousPeriod} />
          <DateSinglePicker
            text={format(addDays(period.start, 12), 'MMMM @@ yyyy', {
              locale: ptBR,
            }).replace('@@', '-')}
            onChange={handleChangePeriod}
          />

          <KeyboardArrowRight onClick={handleNextPeriod} />
        </Picker>

        <HeaderSelect />
      </Header>

      <Content>
        <CalendarDateTopDisplay grid={grid} style={{ marginLeft: '0px' }}>
          {Array.from(Array(7)).map((_, index) => (
            <CalendarDateTopDatesDisplay key={uid()}>
              <span>
                {format(addDays(period.start, index), 'EEE', {
                  locale: ptBR,
                }).slice(0, 3)}
                .
              </span>
            </CalendarDateTopDatesDisplay>
          ))}
        </CalendarDateTopDisplay>

        <CalendarDateCenterDisplay>
          <CalendarDateContent grid={grid}>
            {Array.from(Array(rows * grid)).map((_, index) => (
              <CalendarDateSquareMonth size={squareSize} key={uid()}>
                <CalendarDateSquareHeader
                  currentMonth={isSameMonth(
                    firstDay,
                    addDays(period.start, index)
                  )}
                  currentDay={isSameDay(
                    new Date(),
                    addDays(period.start, index)
                  )}
                >
                  <span>{format(addDays(period.start, index), 'dd')}</span>
                </CalendarDateSquareHeader>

                <CalendarDateSquareList>
                  {formattedData
                    .filter((item) =>
                      isSameDay(item.start, addDays(period.start, index))
                    )
                    .map((item, position) => {
                      const filteredData = formattedData.filter((item) =>
                        isSameDay(item.start, addDays(period.start, index))
                      )

                      const quantity = filteredData.length

                      if (quantity !== 3 && quantity > 2 && position === 2) {
                        return (
                          <CalendarDateSquareListItem
                            style={{ fontWeight: 600 }}
                            key={uid()}
                            onClick={(e) =>
                              handlePopoverOpen(e, item.start, filteredData)
                            }
                          >
                            Mais {quantity - 2}
                          </CalendarDateSquareListItem>
                        )
                      }

                      if (quantity > 2 && position > 2) {
                        return <div key={uid()}></div>
                      }

                      return (
                        <CalendarDateSquareListItem
                          onClick={() => handleOpenEvent(item.event)}
                          key={uid()}
                        >
                          {formatTime(item.start)} {item.title}
                        </CalendarDateSquareListItem>
                      )
                    })}
                </CalendarDateSquareList>
              </CalendarDateSquareMonth>
            ))}
          </CalendarDateContent>
        </CalendarDateCenterDisplay>
      </Content>

      <ViewMore
        open={!!selectedPopover?.anchorEl}
        onClose={() => handlePopoverClose()}
        data={selectedEvents}
        anchorEl={selectedPopover?.anchorEl}
      />
    </Container>
  )
}

export default EventsCalendarMonth
