import { Filter } from '@utils/buckets/type'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'
import { getAllStakeholders } from '@services/nomos_api/resources/stakeholders/stakeholders'

const filterStakeholders = async ({
  page,
  limit,
  text,
}: {
  limit: number | undefined
  page: number | undefined
  text: string
}) => {
  const stakeholders = await getAllStakeholders({
    name: text,
    page,
    limit,
    active: 'true',
  })
  return stakeholders.map((stakeholder: StakeholderEntity) => ({
    key: stakeholder.id,
    value: capitalizeAllWords(stakeholder.name),
  }))
}

export default [
  {
    label: 'Stakeholders',
    attr: 'stakeholders',
    enabled_online_filter: true,
    enabled_input: true,
    options: filterStakeholders,
  },
] as Filter[]
