import colors from '@theme/colors'
import { TrashCan } from '@components/icons'
import { Button, Input } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { Stack, Typography, CircularProgress } from '@mui/material'
import { SavedBucketsContext } from '@containers/SavedBucketsList/context'

export function SavedBucketsAction(): JSX.Element | null {
  const selecteds = useContextSelector(SavedBucketsContext, (s) => s.selecteds)
  const data = useContextSelector(SavedBucketsContext, (s) => s.data)

  const isRemoving = useContextSelector(
    SavedBucketsContext,
    (s) => s.isRemoving
  )

  const handleDeleteSavedBucket = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleDeleteSavedBucket
  )

  const handleToggleAll = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleToggleAll
  )

  if ((data?.results || []).length === 0) return null

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Stack py={1}>
        <Input
          type="checkbox"
          label="Selecionar Todos"
          checked={selecteds.length === (data?.results || []).length}
          onChange={() => handleToggleAll()}
        />
      </Stack>
      {(isRemoving || selecteds.length > 0) && (
        <Button
          variant="text"
          disabled={isRemoving}
          text={
            <Typography
              variant="$font-body-base"
              color={colors['$color-semantic-error']}
            >
              {isRemoving ? 'Removendo...' : 'Remover Selecionados'}
            </Typography>
          }
          startIcon={
            isRemoving ? (
              <CircularProgress size={12} thickness={6} color="error" />
            ) : (
              <TrashCan />
            )
          }
          onClick={() => handleDeleteSavedBucket(selecteds)}
        />
      )}
    </Stack>
  )
}
