import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Pagination } from '../../entities/pagination'
import { VotingEntity } from '@services/nomos_api/entities/voting'

export type GetAllVotingsResult = {
  pagination: Pagination
  results: VotingEntity[]
}

export type GetAllVotingsFilters = {
  q?: string
  organ?: string
  rapporteur?: string
  approved?: string
}

export type GetAllVotingsProps = {
  page: number
  limit: number
  filters?: GetAllVotingsFilters
}

export const getAllVotings = async ({
  page,
  limit,
  filters,
}: GetAllVotingsProps): Promise<GetAllVotingsResult> => {
  const params = Object.entries(filters || {}).reduce(
    (accumulator, [key, value]) => {
      const stringValue = value?.toString()
      return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
    },
    ''
  )

  const { data } = await HTTPClient.get(
    `votings?page=${page}&limit=${limit}&${params}`
  )
  return (data || []) as GetAllVotingsResult
}

export const useGetAllVotings = (props: GetAllVotingsProps) =>
  useQuery(['get-all-votings', props], () => getAllVotings(props), {
    refetchOnWindowFocus: false,
  })
