import { Bucket, BucketsEnum, FilterOptionsProps } from './type'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'
import { searchOptionsAPI } from '@services/nomos_api/resources/search/options'
import { getAllStakeholdersPagination } from '@services/nomos_api/resources/stakeholders/stakeholders'

const BucketSocial: Bucket = {
  key: BucketsEnum.social,
  name: 'Redes Sociais',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'resources',
      label: 'Rede Social',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [{ key: 'twitter', value: 'Twitter' }],
    },
    {
      attr: 'parties',
      label: 'Partido',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        return searchOptionsAPI('propositions', 'parties', [], {
          page,
          limit,
          text,
        })
      },
    },
    {
      attr: 'stakeholders',
      label: 'Stakeholders',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const stakeholders = await getAllStakeholdersPagination({
          page,
          limit,
          filters: { name: text },
        })
        return {
          pagination: stakeholders.pagination,
          results: stakeholders.results.map(
            (stakeholder: StakeholderEntity) => ({
              key: stakeholder.id,
              value: `${capitalizeAllWords(stakeholder.name)} - ${
                stakeholder.title
              }`,
            })
          ),
        }
      },
    },
  ],
}

export default BucketSocial
