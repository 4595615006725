import { useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useSnackbar } from '@contexts/Snackbar'
import { InputNoteContext } from './InputNote.context'
import { createNoteApi } from '@services/nomos_api/resources/notes/create'
import { InputNoteControllerProps, InputNoteFormProps } from './InputNote.types'

export function InputNoteController({
  children,
  bucket,
  originId,
  onNoteCreated,
}: InputNoteControllerProps) {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const [submiting, setSubmiting] = useState<boolean>(false)
  const [focused, setFocused] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [inputCleaner, setInputCleaner] = useState<number>(0)

  const form = useFormik<InputNoteFormProps>({
    initialValues: {
      note: '',
      public: true,
      file: undefined,
    },
    onSubmit: (data) => {
      setSubmiting(true)
      createNoteApi({
        bucket,
        originId,
        note: data.note,
        file: data.file,
        fileName: data.file ? file?.name : undefined,
        public: data.public,
      })
        .then((note) => {
          form.resetForm()
          setFile(undefined)
          setFocused(false)
          setInputCleaner((prev) => prev + 1)
          showSnackbarSuccess('Anotação Criada', 'Anotação criada com sucesso')
          onNoteCreated?.(note)
        })
        .catch((error) => {
          showSnackbarError(
            'Falha ao criar anotação',
            error.response.data.message || 'Não foi póssível criar a anotação'
          )
        })
        .finally(() => {
          setSubmiting(false)
        })
    },
  })

  const state = useMemo(
    () => ({
      file,
      form,
      submiting,
      focused,
      inputCleaner,
      setFile,
      setFocused,
    }),
    [file, setFile, form, submiting, focused, inputCleaner, setFocused]
  )

  return (
    <InputNoteContext.Provider value={state}>
      {children}
    </InputNoteContext.Provider>
  )
}
