import _ from 'lodash'
import produce from 'immer'
import { useModal } from '@contexts/Modal'
import { useCallback, useMemo, useState } from 'react'
import { AdvancedSearchModalContext } from './AdvancedSearchModal.context'
import {
  AdvancedSearchModalControllerProps,
  AdvancedSearchOutputType,
} from './AdvancedSearchModal.types'
import { BucketsEnum } from '@utils/buckets/type'

export function AdvancedSearchModalController({
  children,
  initialForm,
  onSaveOptions,
}: AdvancedSearchModalControllerProps): JSX.Element {
  const [form, setForm] = useState<AdvancedSearchOutputType>(initialForm)

  const { closeModal } = useModal()

  const handleToggleSensitive = useCallback(() => {
    setForm({
      ...form,
      modes: !form.modes.includes('sensitive') ? ['sensitive'] : [],
    })
  }, [form, setForm])

  const handleSetBuckets = useCallback(
    (buckets: BucketsEnum[]) => {
      setForm({ ...form, buckets })
    },
    [form, setForm]
  )

  const handleSetKeywordsOR = useCallback(
    (keywords: string[]) => {
      setForm(
        produce(form, (draft) => {
          _.set(draft, 'keywords.or', keywords)
        })
      )
    },
    [form, setForm]
  )

  const handleSetKeywordsAND = useCallback(
    (keywords: string[]) => {
      setForm(
        produce(form, (draft) => {
          _.set(draft, 'keywords.and', keywords)
        })
      )
    },
    [form, setForm]
  )

  const handleSetKeywordsNOT = useCallback(
    (keywords: string[]) => {
      setForm(
        produce(form, (draft) => {
          _.set(draft, 'keywords.not', keywords)
        })
      )
    },
    [form, setForm]
  )

  const handleClear = useCallback(() => {
    setForm({
      modes: form.modes,
      buckets: form.buckets,
      keywords: { and: [], not: [], or: [] },
    })
  }, [form, setForm])

  const handleApplyAdvancedSearch = useCallback(() => {
    closeModal()
    if (onSaveOptions) onSaveOptions(form)
  }, [form])

  const state = useMemo(
    () => ({
      form,
      handleClear,
      handleSetBuckets,
      handleSetKeywordsOR,
      handleSetKeywordsAND,
      handleSetKeywordsNOT,
      handleToggleSensitive,
      handleApplyAdvancedSearch,
    }),
    [
      form,
      handleClear,
      handleSetBuckets,
      handleSetKeywordsOR,
      handleSetKeywordsAND,
      handleSetKeywordsNOT,
      handleToggleSensitive,
      handleApplyAdvancedSearch,
    ]
  )
  return (
    <AdvancedSearchModalContext.Provider value={state}>
      {children}
    </AdvancedSearchModalContext.Provider>
  )
}
