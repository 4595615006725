// export const paymentLink = (email?: string) =>
//   `https://buy.stripe.com/eVaaGLaxG6v88mcdQQ?prefilled_email=${email || ''}`

import _ from 'lodash'
import axios from 'axios'

const HTTPClient = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
})

HTTPClient.interceptors.request.use((config) => {
  // resolve authorization token injection
  const jwt = localStorage.getItem('@NOMOS:token')
  if (jwt && jwt !== 'undefined') {
    _.set(config, 'headers.Authorization', `Bearer ${jwt}`)
  }
  return config
})

HTTPClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      const jwt = localStorage.getItem('@NOMOS:token')
      if (jwt && jwt !== 'undefined') {
        localStorage.clear()
        window.location.reload()
      }
    }
    return Promise.reject(error)
  }
)

export default HTTPClient
