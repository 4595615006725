import styled from 'styled-components'

export const StakeholdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2db;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }

  max-height: 400px;

  @media (min-width: 768px) {
    max-height: unset;
  }
`
