/* eslint-disable no-unused-vars */
import EventsPanelController from './controller'
import { EventsPanelView } from './view'

export function EventsPanel() {
  return (
    <EventsPanelController>
      <EventsPanelView />
    </EventsPanelController>
  )
}
