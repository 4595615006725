/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react'
import { DataFoundContext } from '../../context'
import { useDebounce } from '@hooks/useDebounce'
import { FilterSelectDropdown } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { useGetInfinitySavedSearches } from '@services/nomos_api/resources/saved_searches/getAll'

export function FilterSavedSearch(): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce(searchValue, 300)
  const monitorId = useContextSelector(DataFoundContext, (s) => s.monitorId)
  const selectedMonitors = useContextSelector(
    DataFoundContext,
    (s) => s.selectedMonitors
  )

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useGetInfinitySavedSearches({
    q: debounceSearchValue,
    only: monitorId ? undefined : ['owner'],
    monitorId: selectedMonitors.join(','),
  })

  const handleFetchNextPage = (): Promise<any> => {
    return fetchNextPage()
  }

  const setSelectedRelatedSavedSearches = useContextSelector(
    DataFoundContext,
    (s) => s.setSelectedRelatedSavedSearches
  )

  const selectedRelatedSavedSearches = useContextSelector(
    DataFoundContext,
    (s) => s.selectedRelatedSavedSearches
  )

  const originalOptions = useMemo(() => {
    return (
      data?.pages?.reduce(
        (previous, current) => previous.concat(current?.results || []),
        [] as SavedSearchEntity[]
      ) || []
    )
  }, [data])

  const displayOptions = useMemo(
    () => originalOptions?.map((i) => ({ label: i.name, value: i.id })) || [],
    [originalOptions]
  )

  return (
    <FilterSelectDropdown
      size="tiny"
      onFilter={setSearchValue}
      onOpen={refetch}
      options={displayOptions}
      hasNextPage={hasNextPage}
      fetchNextPage={handleFetchNextPage}
      data-cy="relatedSavedSearches"
      label="Pesquisa salva relacionada"
      values={selectedRelatedSavedSearches}
      onChangeOptions={setSelectedRelatedSavedSearches}
      loading={isLoading || isFetching || isFetchingNextPage}
    />
  )
}
