import { useMemo } from 'react'
import { OrganDetailContext } from './context'
import { useParams } from 'react-router-dom'
import { useGetOrgan } from '@services/nomos_api/resources/organs/find'
import { OrganEntity } from '@services/nomos_api/entities/organ'

export type OrganDetailProps = {
  children: React.ReactNode
}

export function OrganDetailController({ children }: OrganDetailProps) {
  const { id } = useParams()

  const { data, isFetching } = useGetOrgan(id)

  const store = useMemo(
    () => ({
      isLoading: isFetching,
      organ: data || ({ id } as OrganEntity),
    }),
    [data, isFetching]
  )

  return (
    <OrganDetailContext.Provider value={store}>
      {children}
    </OrganDetailContext.Provider>
  )
}
