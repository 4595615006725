/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutocompleteRenderGetTagProps, Typography } from '@mui/material'
import { MultiSelectAdvancedOption } from '../InputAdvancedSearch'
import {
  AdaptiveChip,
  ChipConnector,
  ChipIconDelete,
  Wrapper,
} from './render-tags.styles'

type Props = {
  shouldRenderConnectors: boolean
  values: MultiSelectAdvancedOption[]
  getTagProps: AutocompleteRenderGetTagProps
}

export function RenderTags({
  shouldRenderConnectors,
  values = [] as MultiSelectAdvancedOption[],
  getTagProps,
}: Props): JSX.Element {
  const components: JSX.Element[] = []

  for (const [index, tag] of values.entries()) {
    // render intial explicit NOT if starts with NOT condition
    if (index === 0 && shouldRenderConnectors && tag.kind === 'not') {
      components.push(<ChipConnector>NÃO</ChipConnector>)
    }
    // render other connectors
    if (index > 0 && shouldRenderConnectors) {
      if (tag.kind === 'or') {
        components.push(<ChipConnector>OU</ChipConnector>)
      }
      if (tag.kind === 'and') {
        components.push(<ChipConnector>E</ChipConnector>)
      }
      if (tag.kind === 'not') {
        components.push(<ChipConnector>E NÃO</ChipConnector>)
      }
    }

    const props = getTagProps({ index })
    components.push(
      <AdaptiveChip
        {...props}
        size="small"
        deleteIcon={<ChipIconDelete />}
        onDelete={shouldRenderConnectors ? undefined : props.onDelete}
        disabled={false}
        disableStyle={String(shouldRenderConnectors) as 'true' | 'false'}
        label={
          <Typography
            data-cy="search-keyword"
            variant="$font-body-base"
            color="$color-text-primary"
          >
            {tag.value}
          </Typography>
        }
      />
    )
  }

  return <Wrapper>{components}</Wrapper>
}
