import { Input } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SideFilterSelectContext } from './SideFilterSelectContext'

export function SideFilterSelectCheckAll(): JSX.Element {
  const options = useContextSelector(SideFilterSelectContext, (s) => s.options)

  const selecteds = useContextSelector(
    SideFilterSelectContext,
    (s) => s.selecteds
  )

  const handleToggleAll = useContextSelector(
    SideFilterSelectContext,
    (s) => s.handleToggleAll
  )

  return (
    <Input
      type="checkbox"
      label="Selecionar todos"
      value={-1}
      checked={options.length === selecteds.length}
      onChange={() => handleToggleAll()}
    />
  )
}
