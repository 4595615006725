import { ChipProps } from '@mui/material'
import { ChipWrapper, ChipIconDelete } from './Chip.style'

function ChipComponent(props: ChipProps) {
  return (
    <ChipWrapper {...props} deleteIcon={<ChipIconDelete color="warning" />} />
  )
}

export default ChipComponent
