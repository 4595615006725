import { Box, styled } from '@mui/material'
import styledComponent from 'styled-components'

export const CardWrapper = styledComponent.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e7e9ed;
  padding-bottom: 24px;
  width: 100%
`

export const CardContent = styled(Box)(({ theme }) => ({
  gap: '16px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}))

export const CardActions = styled(Box)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'row-reverse',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
}))

export const OptionalCardActions = styled(Box)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  alignItems: 'flex-start',
  flexDirection: 'row-reverse',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
}))

export const CardBaseline = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '24px',
  [theme.breakpoints.up('md')]: {
    gap: '60px',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}))
