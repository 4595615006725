import { theme } from '@theme/index'
import { X } from '@phosphor-icons/react'
import { Box, IconButton } from '@mui/material'
import { ChipSelect } from '@components/atoms'
import { TabSelector } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'
import { SearchResultsContext } from '@pages/private/SearchResults/context'

function SelectedBucketsComponent() {
  const activeBuckets = useContextSelector(
    SearchResultsContext,
    (s) => s.activeBuckets
  )

  const handleChangeBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.handleChangeBucket
  )

  const handleRemoveBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.handleRemoveBucket
  )

  const handleAddBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.handleAddBucket
  )

  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const inactiveBuckets = useContextSelector(
    SearchResultsContext,
    (s) => s.inactiveBuckets
  )

  const handleClickClose  = (e, bucketKey) => {
    e.stopPropagation()
    handleRemoveBucket(bucketKey)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: "center", gap: 3, paddingTop: 0 }}>
      <TabSelector
        initialTab={selectedBucket}
        onChangeTab={(bucketKey) => handleChangeBucket(bucketKey)}
        tabs={activeBuckets.map((bucket) => ({ 
          value: bucket.key, 
          label: bucket.name, 
          action: (
            <IconButton size="small" onClick={(e) => handleClickClose(e, bucket.key)}>
              <X color={theme.palette['$color-border-tertiary']} size={12} weight="bold" />
            </IconButton>
          )
        }))}
      />
  
      {inactiveBuckets.length > 0 && (
        <ChipSelect
          size="medium"
          selectedFilters={activeBuckets}
          otherFilters={inactiveBuckets}
          actionAdd={handleAddBucket}
          actionRemove={handleRemoveBucket}
        />
      )}
    </Box>
  )
}

export default SelectedBucketsComponent
