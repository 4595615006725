import React from 'react'
import { useMemo } from 'react'
import { theme } from '@theme/index'
import { useAuth } from '@contexts/Auth'
import { Close } from '@mui/icons-material'
import ClockSVG from '@assets/icons/clock.svg'
import differenceInDays from 'date-fns/differenceInDays'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'

function AppBannerFreeTrialExpiration() {
  const { user } = useAuth()

  const localStorageKey = `@NOMOS:hideTrialBanner`
  const [hideTrialBanner, setHideTrialBanner] = React.useState<boolean>(
    localStorage.getItem(localStorageKey) === 'true'
  )

  const daysLeft = useMemo(() => {
    if (user?.company?.subscription) return undefined
    if (!user?.company?.trialExpireAt) return undefined
    return differenceInDays(new Date(user.company?.trialExpireAt), new Date())
  }, [user?.company, hideTrialBanner])

  const handleCloseBanner = () => {
    localStorage.setItem(localStorageKey, 'true')
    setHideTrialBanner(true)
  }

  const visible = useMemo(() => {
    return !hideTrialBanner && !!daysLeft
  }, [daysLeft, hideTrialBanner])

  const message = useMemo(() => {
    if (!daysLeft) {
      return undefined
    }
    if (daysLeft === 0) {
      return 'Seu período de avaliação acaba hoje'
    }
    if (daysLeft < 0) {
      return `O período de avaliação expirou a ${Math.abs(daysLeft)} dias`
    }
    if (daysLeft > 0) {
      return `Restam ${daysLeft} dias para finalizar o período de
      avalição`
    }
    return undefined
  }, [daysLeft])

  if (!visible) return null

  return (
    <Box bgcolor="$color-background-dark">
      <Stack
        width="88%"
        margin="0 auto"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: { md: '55px' }, py: { xs: 2, md: 0 } }}
      >
        <Stack spacing={2} alignItems="center" direction="row">
          <img src={ClockSVG} alt="Countdown" width={24} height={24} />
          <Stack
            spacing={{ xs: 0, md: 2 }}
            alignItems="center"
            direction="row"
            flexWrap="wrap"
          >
            <Typography variant="$font-body-4" color="$color-text-light">
              {message}
            </Typography>
            <Divider
              flexItem
              orientation="vertical"
              color={theme.palette['$color-border-primary']}
            />
            <Typography variant="$font-body-base" color="$color-text-light">
              Assine agora e tenha acesso ilimitado a todos os recursos que o
              Nomos oferece
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          {!!user?.admin && (
            <WhatsAppPaymentLink
              title="Assinar"
              text="Assinar"
              size="medium"
              loadingLight
            />
          )}
          <IconButton size="medium" onClick={() => handleCloseBanner()}>
            <Close
              fontSize="small"
              sx={{ color: theme.palette['$color-border-light'] }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default AppBannerFreeTrialExpiration
