import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 64px;
  padding-right: 64px;
  gap: 48px;
`

export const About = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Monitors = styled.div`
  display: flex;
  gap: 16px;
`
