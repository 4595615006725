import General from './General'
import Members from './Members'
import Votings from './Votings'
import { useState } from 'react'
import Schedule from './Schedule'
import Documents from './Documents'
import Propositions from './Propositions'
import { TabSelector } from '@components/organisms'
import { Container, Content } from './styles'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

const tabs = [
  {
    value: 'general',
    label: 'Visão Geral',
  },
  {
    value: 'propositions',
    label: 'Propostas legislativas',
  },
  {
    value: 'members',
    label: 'Membros',
  },
  {
    value: 'documents',
    label: 'Documentos',
  },
  {
    value: 'schedule',
    label: 'Agenda',
  },
  {
    value: 'votings',
    label: 'Votações',
  },
]

export default function Tabs() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const initialTab = searchParams.get('tab')
  const [tab, setTab] = useState(initialTab || 'general')

  const handleChangeTab = (tab: string) => {
    setTab(tab)

    navigate(
      {
        search: `${createSearchParams({ tab })}`,
      },
      {
        replace: true,
      }
    )
  }

  return (
    <Container>
      <TabSelector tabs={tabs} initialTab={tab} onChangeTab={handleChangeTab} />

      <Content>
        {tab === 'general' && <General />}
        {tab === 'propositions' && <Propositions />}
        {tab === 'members' && <Members />}
        {tab === 'documents' && <Documents />}
        {tab === 'schedule' && <Schedule />}
        {tab === 'votings' && <Votings />}
      </Content>
    </Container>
  )
}
