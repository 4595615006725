import Events from '../Events/Events'
import { Container } from '@mui/system'
import { Typography } from '@mui/material'
import PartyLoyalty from '../PartyLoyalty/PartyLoyalty'
import RollCallVotes from '../RollCallVotes/RollCallVotes'
import LegislativeProposals from '../LegislativeProposals/LegislativeProposals'

import { Dashboard } from './Activities.styles'

export default function Activities() {
  return (
    <>
      <Dashboard>
        <LegislativeProposals />
        <RollCallVotes />
        <PartyLoyalty />
      </Dashboard>

      <Container sx={{ marginTop: { xs: '16px', md: '49px' } }}>
        <Typography variant="$font-title-5" color="$color-text-primary">
          Próximos Eventos que participará:
        </Typography>

        <Events />
      </Container>
    </>
  )
}
