/* eslint-disable no-unused-vars */
import OrgansView from './view'
import OrgansController from './controller'

export type OrgansListProps = {
  monitorId?: string
}

function OrgansList(props: OrgansListProps) {
  return (
    <OrgansController {...props}>
      <OrgansView />
    </OrgansController>
  )
}

OrgansList.defaultProps = {
  monitorId: null,
}

export default OrgansList
