import {
  styled,
  Tooltip as TooltipComponent,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <TooltipComponent {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background?.default,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background?.default,
    color: theme.palette.muted?.main,
    boxShadow: theme.shadows[6],
    padding: 12,
  },
}))
