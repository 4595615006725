import { Tooltip } from './styles'
import colors from '@theme/colors'
import { IconButton, SxProps } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { Star } from '@phosphor-icons/react'
import { BucketsEnum } from '@utils/buckets/type'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'

type Props = {
  id: string
  bucket: BucketsEnum
  openDataResource: string
  isSaved?: boolean
  sx?: SxProps
  onCreatedSavedBucket?: (savedBuckets: SavedBucketEntity[]) => void
  onRemovedSavedBucket?: (removedIds: string[]) => void
}

export function SaveBucketButton({
  id,
  bucket,
  openDataResource,
  isSaved = false,
  sx,
  onCreatedSavedBucket,
  onRemovedSavedBucket,
}: Props): JSX.Element {
  const { openDrawer } = useDrawer()

  return (
    <Tooltip arrow title="Favoritar" placement="top-end" sx={sx}>
      <IconButton
        data-cy="button-favorite"
        onClick={() =>
          openDrawer(
            <MonitoringDrawer
              id={id}
              isSaved={isSaved}
              house={openDataResource}
              origin={bucket}
              onFinish={onCreatedSavedBucket}
              onRemove={onRemovedSavedBucket}
            />
          )
        }
      >
        <Star
          weight={isSaved ? 'fill' : 'regular'}
          color={colors['$blue1-medium-dark']}
        />
      </IconButton>
    </Tooltip>
  )
}
