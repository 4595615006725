import { PropositionVoteEntity } from '@services/nomos_api/entities/proposition'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getPropositionVotings = async (
  propositionId: string
): Promise<PropositionVoteEntity[]> => {
  const { data } = await HTTPClient.get(`propositions/${propositionId}/votes`)
  return data as PropositionVoteEntity[]
}

export const useGetPropositionVotings = (propositionId: string) =>
  useQuery(
    ['get-proposition-votings'],
    () => getPropositionVotings(propositionId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
