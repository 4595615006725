import { OrganDetailView } from './view'
import { OrganDetailController } from './controller'

export default function OrganDetail() {
  return (
    <OrganDetailController>
      <OrganDetailView />
    </OrganDetailController>
  )
}
