import { theme } from '@theme/index'
import { styled, Box } from '@mui/material'

export const TabContent = styled(Box)((props) => ({
  backgroundColor: props.color? props.color : theme.palette.common.white,
  borderRadius: 6,
  height: 'auto',
  overflowY: 'auto',
  overflowX: 'none',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#4a8eff20',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#4a8eff50',
  },
}))

export const TabContentInner = styled(Box)(({theme}) => ({
  padding: 16,
  [theme.breakpoints.up("md")]: {
    paddingLeft: '47px',
    paddingRight: '47px',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
}))
