/* eslint-disable no-unused-vars */
import MonitorsView from './view'
import MonitorsController from './controller'

export default function Monitors() {
  return (
    <MonitorsController>
      <MonitorsView />
    </MonitorsController>
  )
}
