import { CardMedia } from '@mui/material'
import { Button } from '@components/atoms'
import Icon404 from '@assets/icons/404.svg'
import { useNavigate } from 'react-router-dom'

import { Container, Title, Description } from './styles'

function NotFoundPage() {
  const navigate = useNavigate()

  return (
    <Container>
      <CardMedia
        image={Icon404}
        title="Icon 404"
        sx={{ height: '300px', width: '430px' }}
      />

      <Title>Ops! Página não Encontrada</Title>

      <Description>
        A página que você esta tentando acessar não foi encontrada
      </Description>

      <Button
        text="Voltar"
        onClick={() => {
          navigate(-3)
        }}
        sx={{ height: '48px', width: '148px' }}
      />
    </Container>
  )
}

export default NotFoundPage
