import { Stack, styled } from '@mui/material'

export const CardRadioGroup = styled(Stack)`
  flex: 1;
`

export const CardRadioWrapper = styled(Stack)<{ disabled: 'true' | 'false' }>`
  flex: 1;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(ctx) => ctx.theme.palette['$color-border-secondary']};
  ${(ctx) =>
    ctx.disabled !== 'true'
      ? `
    &:hover { border: 1px solid ${ctx.theme.palette['$color-border-tertiary']}; }
  `
      : ''};
  & > label {
    width: 100%;
    display: flex;
    padding: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    opacity: ${(ctx) => (ctx.disabled === 'true' ? '0.5' : '1')};
  }
`
