import { Alert, Box } from '@mui/material'

type props = {
  disabled: boolean
}

export function FormDisabledAlertMsg({ disabled }: props) {
  if (disabled)
    return (
      <Box pb={1}>
        <Alert severity="warning" data-testid="warn-alert">
          Ao executar esta ação você não será alertado sobre as atualizações
          deste alerta.
        </Alert>
      </Box>
    )

  return <div />
}
