import {
  Button,
  ContainerScroll,
  Input,
  InputColorPicker,
} from '@components/atoms'
import {
  ActionContainer,
  Content,
  Header,
  IconBack,
  IconClose,
  InputGroup,
  Wrapper,
} from '@containers/TagManager/styles'
import { useDrawer } from '@contexts/Drawer'
import { TagManagerCreateContext } from './context'
import { IconButton, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { TagManagerContext } from '@containers/TagManager/context'

export function TagManagerCreateView(): JSX.Element {
  const { closeDrawer } = useDrawer()

  const form = useContextSelector(TagManagerCreateContext, (s) => s.form)

  const isLoading = useContextSelector(
    TagManagerCreateContext,
    (s) => s.isLoading
  )

  const compact = useContextSelector(TagManagerContext, (s) => s.compact)

  const setViewmode = useContextSelector(
    TagManagerContext,
    (s) => s.setViewmode
  )

  const handleClose = useContextSelector(
    TagManagerContext,
    (s) => s.handleClose
  )

  return (
    <Wrapper data-cy="tag-manager-creating-area" compact={String(compact)}>
      <Header>
        <IconButton
          onClick={() => setViewmode('listing')}
          size={compact ? 'small' : 'medium'}
        >
          <IconBack />
        </IconButton>
        <Typography
          variant={compact ? '$font-body-2' : '$font-title-3'}
          color="$color-text-primary"
        >
          Criar Tag
        </Typography>
        <IconButton
          onClick={handleClose || closeDrawer}
          size={compact ? 'small' : 'medium'}
        >
          <IconClose />
        </IconButton>
      </Header>
      <Content>
        <ContainerScroll auto="true" spacing={24} pr={compact ? 0 : undefined}>
          <InputGroup>
            <Typography variant="$font-title-5" color="$color-text-secondary">
              Nome
            </Typography>
            <Input
              type="text"
              size={compact ? 'tiny' : 'large'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                form.setFieldValue('name', e.target.value)
              }
            />
          </InputGroup>
          <InputGroup>
            <Typography variant="$font-title-5" color="$color-text-secondary">
              Selecione uma cor
            </Typography>
            <InputColorPicker
              name="color"
              lighten={80}
              onChange={(value) => form.setFieldValue('color', value)}
            />
          </InputGroup>
        </ContainerScroll>
        <ActionContainer>
          <Button
            text="Criar"
            fullWidth
            loading={isLoading}
            size={compact ? 'medium' : 'large'}
            onClick={() => form.submitForm()}
            disabled={!(form.isValid && form.dirty)}
          />
        </ActionContainer>
      </Content>
    </Wrapper>
  )
}
