import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export type ReadNoteFileProps = {
  id: string
}

export type ReadNoteFileUrl = { url: string }

export const readNoteFileApi = async (
  props: ReadNoteFileProps
): Promise<ReadNoteFileUrl> => {
  const { data } = await HTTPClient.get(`/notefile/${props.id}`)
  return data as ReadNoteFileUrl
}

export const useReadNoteFile = (props: ReadNoteFileProps) =>
  useQuery(['read-note-file'], () => readNoteFileApi(props))
