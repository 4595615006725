import _ from 'lodash'
import { uid } from 'uid/single'
import { useContextSelector } from 'use-context-selector'
import { Box, Grid, TextField, MenuItem, Stack } from '@mui/material'
import { DateRangePicker, DateSinglePicker } from '@components/atoms'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import { useCallback, useContext } from 'react'
import { InputRadio } from '@components/atoms/InputRadio/InptuRadio.stories'
import { HelpIcon } from '@components/icons'
import Tooltip from '@mui/material/Tooltip';

function FormFiltersComponent() {
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)
  const search = useContextSelector(SearchResultsContext, (s) => s.search)
  const toggleResumeSearch = useContextSelector(SearchResultsContext, (s) => s.toggleResumeSearch)
  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )
  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )
   
  const keyPrefix = `buckets.${selectedBucket}`

  const toolTipText = "Selecione onde deseja buscar a palavra-chave. Isso é necessário para otimizar sua busca. Esta ação não afeta a pesquisa salva, você será alertado(a) se a palavra chave for encontrada na ementa ou inteiro teor."

  const handleChangeDateRangePicker = useCallback((from, to, period) => {
    if (!from || !to) {
      handleUpdateSearch({
        [`${keyPrefix}.filter.${period.attr}`]: undefined,
      })
    } else {
      handleUpdateSearch({
        [`${keyPrefix}.filter.${period.attr}.from`]: from,
        [`${keyPrefix}.filter.${period.attr}.to`]: to,
      })
    }
  }, [handleUpdateSearch])

  const handleChangeSinglePicker = useCallback((date, period) => {
    if (!date) {
      handleUpdateSearch({
        [`${keyPrefix}.filter.${period.attr}`]: undefined,
      })
    } else {
      handleUpdateSearch({
        [`${keyPrefix}.filter.${period.attr}.from`]: date,
        [`${keyPrefix}.filter.${period.attr}.to`]: date,
      })
    }
  }, [handleUpdateSearch])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: {xs:2, md: 4}, flexDirection: {xs:'column-reverse', md:'row'} }}>
      <Grid
        container
        columns={bucket?.periods?.length > 1 ? bucket?.periods?.length : 2}
        columnSpacing={2}
        sx={{ flex: 1 }}
      >
        {(bucket?.periods || []).map((period) => (
          <Grid key={`period-${uid()}`} item width={{xs: bucket?.periods?.length > 1 ? "50%" : '100%', md:"38%"}}>
            {period.range === true ? (
              <DateRangePicker
                size="tiny"
                label={period.label}
                startDate={_.get(
                  search,
                  `${keyPrefix}.filter.${period.attr}.from`
                )}
                endDate={_.get(search, `${keyPrefix}.filter.${period.attr}.to`)}
                onChange={(from, to)=> handleChangeDateRangePicker(from, to, period)}
              />
            ) : (
              <DateSinglePicker
                size="tiny"
                label={period.label}
                date={_.get(search, `${keyPrefix}.filter.${period.attr}.from`)}
                onChange={(date)=> handleChangeSinglePicker(date, period)}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Stack direction="row" spacing={4}>
        <InputRadio
          name="resumeSearch"
          value='0'
          options={{
            0: 'Buscar na Ementa',
            1: 'Buscar no Inteiro teor',
          }}
          onChange={(value) => {
            toggleResumeSearch(value)
          }
          }
        />
      <Tooltip arrow title={toolTipText} placement="top-end">
        <div>
          <HelpIcon />
        </div>
      </Tooltip>
      </Stack>
      </Box>
    </Box>
  )
}

export default FormFiltersComponent
