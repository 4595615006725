import { DouEntity } from '@services/nomos_api/resources/dou/getById'
import { createContext } from 'use-context-selector'

export type DouDetailsContextType = {
  dou: DouEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const DouDetailsContext = createContext<DouDetailsContextType>(
  {} as DouDetailsContextType
)
