import { useModal } from '@contexts/Modal'
import { Close } from '@mui/icons-material'
import { Button } from '@components/atoms'
import { Container, Content } from './styles'
import { IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Parliamentarian } from '@services/nomos_api/entities/proposition'

type Props = {
  authors: Parliamentarian[]
}

export default function PropositionAuthorsModal({ authors }: Props) {
  const { cleanStack, closeModal } = useModal()
  const navigate = useNavigate()

  const handleOpenStakeholder = (stakeholderId: string) => {
    cleanStack()
    navigate(`/stakeholders/${stakeholderId}`)
  }

  return (
    <Container>
      <Stack
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Autores
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Veja os {authors.length} desta proposição
          </Typography>
        </Stack>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Stack>
      <Content>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          {authors?.map((author) => (
            <Button
              key={`stakeholder-${author.stakeholderId}`}
              variant="text"
              text={
                [author.name, author.acronymParty, author.uf]
                  .filter((i) => !!i)
                  .join(' - ') || '--'
              }
              onClick={() => handleOpenStakeholder(author.stakeholderId)}
            />
          ))}
        </Stack>
      </Content>
    </Container>
  )
}
