import { useMemo } from 'react'
import { NewUserContext } from './context'

export type UsersProps = {
  children: React.ReactNode
}

export function NewUserController({ children }: UsersProps) {
  const store = useMemo(() => ({}), [])

  return (
    <NewUserContext.Provider value={store}>{children}</NewUserContext.Provider>
  )
}
