import UserInfo from '@containers/UserUpsert'
import { CrumbedPage } from '@components/templates'

export function NewUserView() {
  return (
    <CrumbedPage
      id="users-page"
      title="Novo usuário"
      description=""
      paths={[
        {
          name: 'Início',
          location: '/',
        },
        {
          name: 'Usuários',
          location: '/users',
        },
        {
          name: 'Novo Usuário',
          location: '/new-user',
        },
      ]}
    >
      <UserInfo />
    </CrumbedPage>
  )
}
