import { SVGProps } from 'react'

function TrashCanIcon({
  width = 21,
  height = 21,
  fill = 'none',
  stroke = '#F03D3D',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33179 9.31006V14.3101"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6653 9.31006V14.3101"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33179 5.97705H17.6651"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99854 5.97705H10.9985H15.9985V14.6437C15.9985 16.3006 14.6554 17.6437 12.9985 17.6437H8.99854C7.34168 17.6437 5.99854 16.3006 5.99854 14.6437V5.97705Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49854 4.64355C8.49854 3.53898 9.39397 2.64355 10.4985 2.64355H11.4985C12.6031 2.64355 13.4985 3.53899 13.4985 4.64355V5.97689H8.49854V4.64355Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrashCanIcon
