export enum OpenDataResourceEnum {
  cvm = 'CVM',
  senado = 'Senado Federal',
  camara = 'Camara dos Deputados',
  bacen = 'Banco Central do Brasil',
  almg = 'Assembleia Legislativa de Minas Gerais',
  alesp = 'Assembleia Legislativa do Estado de São Paulo',
  aleac = 'Assembleia Legislativa do Estado do Acre',
  aleal = 'Assembleia Legislativa do Estado do Alagoas',
  aleam = 'Assembleia Legislativa do Estado do Amazonas',
  alero = 'Assembleia Legislativa do Estado do Rondônia',
  alepe = 'Assembleia Legislativa do Estado do Pernambuco',
  alepa = 'Assembleia Legislativa do Estado do Pará',
  alerj = 'Assembleia Legislativa do Estado do Rio de Janeiro',
  alrs = 'Assembleia Legislativa do Estado do Rio Grande do Sul',
  alesc = 'Assembleia Legislativa do Estado de Santa Catarina',
  alego = 'Assembleia Legislativa do Estado de Goiás',
  alba = 'Assembleia Legislativa da Bahia',
  alema = 'Assembleia Legislativa do Estado do Maranhão',
  alepi = 'Assembleia Legislativa do Estado do Piauí',
  aleto = 'Assembleia Legislativa do Estado do Tocantins',
  alerr = 'Assembleia Legislativa do Estado do Roraima',
  eagendas = 'Sistema Eletrônico de Agendas do Poder Executivo Federal',
}

export type OpenDataResourceEnumKeys = keyof typeof OpenDataResourceEnum
