export default {
  family: {
    title: 'Inter',
    body: 'Inter',
    button: 'Inter',
  },
  size: {
    display: '56px',
    h1: '40px',
    h2: '32px',
    h3: '24px',
    h4: '18px',
    g: '16px',
    base: '14px',
    p: '12px',
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  height: {
    fontelinha4xg: '64px',
    fontelinha3xg: '56px',
    fontelinha2xg: '40px',
    fontelinhaxg: '32px',
    fontelinhag: '24px',
    fontelinham: '20px',
    fontelinhap: '16px',
  },
}
