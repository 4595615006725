import { styled, Box } from '@mui/material'
import colors from '@theme/colors'

export const CardWrapper = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: colors['$color-border-primary'],
  borderWidth: 1,
  padding: 16,  
  borderRadius: 8
}))
