import { Typography } from '@mui/material'
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryIcon,
  AccordionSummaryStyled,
  AccordionWrapper,
} from './Accordion.styles'

type AccordionProps = {
  title: string
  children: JSX.Element
  expanded?: boolean
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
}

function Accordion({
  title,
  children,
  expanded,
  onChange,
}: AccordionProps): JSX.Element {
  return (
    <AccordionWrapper>
      <AccordionStyled
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={expanded}
        onChange={onChange}
      >
        <AccordionSummaryStyled expandIcon={<AccordionSummaryIcon />}>
          <Typography variant="$font-title-4" color="$color-text-primary">
            {title}
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
      </AccordionStyled>
    </AccordionWrapper>
  )
}

Accordion.defaultProps = {
  expanded: false,
}

export default Accordion
