import { useEffect, useState } from 'react'
import { FilterSelectDropdown } from '@components/atoms'
import { useFindTags } from '@services/nomos_api/resources/tags/find'
import { useDebounce } from '@hooks/useDebounce'

type Props = {
  initialFilter?: string[]
  onChangeFilter?: (selectedFilters: string[]) => void
}

export function PropositionPositioningFilterTags({
  initialFilter,
  onChangeFilter,
}: Props): JSX.Element {
  const [filter, setFilter] = useState<string>('')
  const debounceFilter = useDebounce(filter, 300)
  const { data, isLoading } = useFindTags(debounceFilter)
  const [selectedTags, setSelectedTags] = useState<string[]>(
    initialFilter || []
  )

  useEffect(() => {
    onChangeFilter?.(selectedTags)
  }, [selectedTags])

  return (
    <FilterSelectDropdown
      values={selectedTags}
      options={(data || []).map((tag) => ({ label: tag.name, value: tag.id }))}
      onChangeOptions={setSelectedTags}
      label="Tags"
      size="tiny"
      data-cy="filter-tags"
      loading={isLoading}
      onFilter={setFilter}
    />
  )
}
