import { CardWrapper } from './styles'
import { Avatar } from '@components/atoms'
import { Description } from '@components/molecules'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { Typography, Stack, Chip, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export function CardStakeholderMobile({ stakeholder, first=false }) {
  return (
    <CardWrapper sx={{borderTopWidth: first ? 0 : 1}}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            alt={stakeholder?.name}
            src={stakeholder?.photoUrl}
            width={56}
            height={56}
          />           
          <Stack spacing={0}>
              <Link to={`/stakeholders/${stakeholder.stakeholderId}`}>
                <Typography variant="$font-title-5" color="$color-text-primary">
                  {capitalizeAllWords(stakeholder?.name)} 
                </Typography>
              </Link>
              {!!stakeholder.current && (
                <Typography variant="$font-body-2" color="$color-text-primary">
                  Relator Atual
                </Typography>                
                )}         
          </Stack>          
        </Stack>
        <Stack flexDirection="row" gap={2} >
            <Description
                label="Partido:"
                value={stakeholder?.acronymParty || '--'}
            />   
            <Description
              label="UF:"
              value={stakeholder?.electoralUF || '--'}
            />  
        </Stack>   
        <Stack flexDirection="row" gap={2} >
          <Description
            label="Endereço:"
            value={stakeholder?.cabinet?.address || '--'}
          />
          <Description
            label="Prédio:"
            value={stakeholder?.cabinet?.building || '--'}
          />
          <Description
            label="Sala:"
            value={stakeholder?.cabinet?.floor || '--'}
          />
        </Stack>   
        <Description
            label="Telefone:"
            value={stakeholder?.contact || '--'}
        />   
        {!!stakeholder?.opinion && (
          <Stack direction="row" justifyContent="flex-start">
            <Button variant="text" href={stakeholder?.opinion?.url} target="_blank" sx={{padding: 0}}>
              {stakeholder?.opinion?.text || stakeholder?.opinion?.url}
            </Button>
          </Stack>
        )}      
      </Stack>          
    </CardWrapper>
  )
}
