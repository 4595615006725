import React from 'react'
import { useFormik } from 'formik'
import { useModal } from '@contexts/Modal'
import Input from '@components/atoms/Input/Input'
import { Button } from '@components/atoms'
import { StateModalHeader } from './StateModalHeader'
import { useContextSelector } from 'use-context-selector'
import { ExportPropositionModalContext } from '../context'
import { Stack, DialogContent, DialogActions } from '@mui/material'

export function StateModalFilter() {
  const { closeModal } = useModal()

  const resources = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.resources
  )

  const setResources = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.setResources
  )

  const form = useFormik({
    initialValues: {
      resources: {
        camara: resources?.camara || true,
        senado: resources?.senado || true,
        congresso: resources?.congresso || true,
      },
    },
    onSubmit: (values) => setResources(values.resources),
  })

  return (
    <>
      <StateModalHeader description="Selecione abaixo as proposições que deseja exportar" />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          paddingY={1}
          spacing={2}
        >
          <Stack direction="row">
            <Input
              type="checkbox"
              name="resources.camara"
              label="Câmara dos Deputados"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.resources.camara}
              checked={form.values.resources.camara === true}
            />
          </Stack>
          <Stack direction="row">
            <Input
              type="checkbox"
              name="resources.senado"
              label="Senado Federal"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.resources.senado}
              checked={form.values.resources.senado === true}
            />
          </Stack>
          <Stack direction="row">
            <Input
              type="checkbox"
              name="resources.congresso"
              label="Congresso Nacional"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.resources.congresso}
              checked={form.values.resources.congresso === true}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
          <Button
            text="Próximo"
            variant="contained"
            onClick={() => form.handleSubmit()}
          />
        </Stack>
      </DialogActions>
    </>
  )
}
