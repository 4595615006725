import _ from 'lodash'
import { uid } from 'uid'
import { HomeContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { MenuItem, CircularProgress } from '@mui/material'
import { KeywordRender } from '@components/atoms'
import { SavedSearchCountEntity } from '@services/nomos_api/entities/saved_searches'

import {
  Content,
  Title,
  TextTiny,
  // HelperIcon,
  // HelperContainer,
  CountResultIcon,
  CountResultContainer,
  // TextTinyHelper,
} from './styles'
import { useMemo } from 'react'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'

function SearchResults() {
  const search = useContextSelector(HomeContext, (s) => s.search)
  const searchCount = useContextSelector(HomeContext, (s) => s.searchCount)
  const isFetching = useContextSelector(HomeContext, (s) => s.isFetching)

  const handleExecuteSearch = useContextSelector(
    HomeContext,
    (s) => s.handleExecuteSearch
  )

  const keywordResults = [{ keywords: search, ...searchCount }]

  const hasTags = useMemo(() => {
    return (
      (search?.keywords?.or || []).length > 0 ||
      (search?.keywords?.and || []).length > 0 ||
      (search?.keywords?.not || []).length > 0
    )
  }, [search])

  const savedSearches = useMemo(
    () => searchCount?.saved_searches || [],
    [searchCount]
  )

  return (
    <Content>
      <Title color="$color-text-primary">Resultado da pesquisa</Title>

      {(keywordResults || []).map((result) => (
        <MenuItem
          key={uid()}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={() => handleExecuteSearch(search)}
        >
          <CountResultContainer>
            <CountResultIcon />
            <KeywordRender value={result.keywords} />
          </CountResultContainer>

          {!isFetching && <TextTiny>{result._total || 0} resultados</TextTiny>}

          {hasTags && isFetching && (
            <CircularProgress color="primary" size={14} />
          )}
        </MenuItem>
      ))}

      {
        !isFetching &&
          !savedSearches.some((item: SavedSearchCountEntity) => {
            return _.isEqual(
              removeEmptyArrays(item.keywords),
              removeEmptyArrays(search.keywords)
            )
          })
        // && (
        //   <HelperContainer>
        //     <HelperIcon />
        //     <div>
        //       <KeywordRender value={search} envolve={['"', '"']} tiny inline />
        //       {/* SPLIT PHRASE WORDS TO FIX INLINE TEXT WITH KEYWORD */}
        //       {`não faz parte de "pesquisas salvas", se desejar, adicione-a na próxima página clicando em “salvar pesquisa”`
        //         .split(' ')
        //         .map((word) => (
        //           <TextTinyHelper>{word}</TextTinyHelper>
        //         ))}
        //     </div>
        //   </HelperContainer>
        // )
      }
    </Content>
  )
}

export default SearchResults
