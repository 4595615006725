import { PropositionVoteEntity } from '@services/nomos_api/entities/proposition'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getPropositionRelateds = async (
  propositionId: string
): Promise<PropositionVoteEntity[]> => {
  const { data } = await HTTPClient.get(
    `propositions/${propositionId}/listing/related`
  )
  return data as PropositionVoteEntity[]
}

export const useGetPropositionRelateds = (propositionId: string) =>
  useQuery(
    ['get-proposition-relateds'],
    () => getPropositionRelateds(propositionId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
