import Link from './LinkTo.styles'
import { Typography } from '@mui/material'
import { LinkProps } from 'react-router-dom'

export interface LinkToProps extends LinkProps {
  title: string
}

export default function LinkTo({ title, to, target }: LinkToProps) {
  return (
    <Typography variant="$font-body-base" color="$color-action-link">
      <Link to={to} target={target}>
        {title}
      </Link>
    </Typography>
  )
}
