import { PageContainer } from './styles'
import OrgansList from './components/OrgansList'
import OrgansFilter from './components/OrgansFilter'

function OrgansView() {
  return (
    <PageContainer>
      <OrgansFilter />
      <OrgansList />
    </PageContainer>
  )
}

export default OrgansView
