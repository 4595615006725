import { IconDone } from '../../styles'
import { TextError } from '../TextError'
import { getBucketName } from '@utils/buckets'
import { Stack, Box, Chip } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { SavedSearchFormNotificationProps } from '../FormNotification'
import { FormNotificationBucketWarning } from '../FormNotificationBucketWarning'

export function FormNotificationBucket({
  form,
  availableBuckets,
  disabled,
}: SavedSearchFormNotificationProps) {
  const [selectedBuckets, setSelectedBuckets] = useState<string[]>(
    form.values?.buckets || []
  )

  const isSelected = useCallback(
    (
      bucket: string,
      trueValue: string | JSX.Element | undefined,
      falseValue: string | JSX.Element | undefined
    ) => {
      const buckets = form.values?.buckets || []
      return buckets.includes(bucket) ? trueValue : falseValue
    },
    [form.values?.buckets]
  )

  const handleBucketClick = (bucket: string) => {
    const updatedBuckets = selectedBuckets.includes(bucket)
      ? selectedBuckets.filter((b) => b !== bucket)
      : [...selectedBuckets, bucket]
    setSelectedBuckets(updatedBuckets)
  }

  useEffect(() => {
    form.setFieldValue('buckets', selectedBuckets)
  }, [selectedBuckets])

  return (
    <Stack direction="column">
      <FormNotificationBucketWarning
        allBuckets={availableBuckets}
        allSelectedBuckets={form.values?.buckets || []}
      />
      <Box>
        {Object.keys(availableBuckets).map((bucket) => (
          <Chip
            key={`bucket-${bucket}`}
            label={getBucketName(bucket)}
            disabled={disabled}
            variant={
              isSelected(bucket, 'filled', 'outlined') as unknown as
                | 'filled'
                | 'outlined'
            }
            color={
              isSelected(bucket, 'primary', 'default') as unknown as
                | 'primary'
                | 'default'
            }
            icon={
              isSelected(bucket, <IconDone />, undefined) as unknown as
                | JSX.Element
                | undefined
            }
            onClick={() => handleBucketClick(bucket)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onDelete={() => {}}
            deleteIcon={<div>&nbsp;</div>}
            sx={{ cursor: 'pointer', marginRight: 1, marginBottom: 1 }}
          />
        ))}
      </Box>
      <TextError value={form?.errors?.buckets as unknown as string} />
    </Stack>
  )
}
