import OrgansList from '@containers/OrgansList'
import { PrimaryPage } from '@components/templates'

export default function Organs() {
  return (
    <PrimaryPage
      id="organs-page"
      title="Órgãos"
      description="Veja abaixo a lista de órgãos "
    >
      <OrgansList />
    </PrimaryPage>
  )
}
