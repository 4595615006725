import React from 'react'
import produce from 'immer'
import { useAuth } from '@contexts/Auth'
import { useSnackbar } from '@contexts/Snackbar'
import { AlertEntity } from '@services/realtime_db/entities/alert'
import { deleteAlerts } from '@services/nomos_api/resources/alerts/delete'
import { listenAlertAdded } from '@services/realtime_db/resources/alerts/listenAlertAdded'
import { listenCountTotal } from '@services/realtime_db/resources/alerts/listenCountTotal'
import { listAlertsPaginated } from '@services/realtime_db/resources/alerts/listAlertsPaginated'

type UseAlertsListeners = 'count_total' | 'addeds'

export const useAlerts = (listeners: UseAlertsListeners[]) => {
  const { user } = useAuth()
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()

  const [countTotal, setCountTotal] = React.useState<number>(0)
  const [removing, setRemoving] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [alerts, setAlerts] = React.useState<AlertEntity[]>([])
  const [page, setPage] = React.useState<string | undefined>(undefined)

  // append alerts to the beginning of the list
  const prepend = React.useCallback(
    (newAlerts: AlertEntity[]) => {
      setAlerts(
        produce(alerts, (draft) => {
          draft.unshift(...newAlerts)
        })
      )
    },
    [alerts, setAlerts]
  )

  // append alerts to the end of the list
  const append = React.useCallback(
    (newAlerts: AlertEntity[]) => {
      setAlerts(
        produce(alerts, (draft) => {
          draft.push(...newAlerts)
        })
      )
    },
    [alerts, setAlerts]
  )

  // handle remove all
  const handleRemoveAll = React.useCallback(() => {
    setRemoving(true)
    deleteAlerts()
      .then(() => {
        showSnackbarSuccess('Novas mensagens marcadas como lida')
        setAlerts([])
      })
      .catch(() => {
        showSnackbarError('Erro ao tentar marcar mensagens como lida')
      })
      .finally(() => {
        setRemoving(false)
      })
  }, [])

  // execute fetch for paginated results
  const handlePaginate = React.useCallback((): Promise<void> | null => {
    if (!user?.id) return null
    setLoading(true)
    return listAlertsPaginated(String(user?.id), page)
      .then((props) => {
        append(props.alerts)
        setPage(props.nextPage)
      })
      .finally(() => setLoading(false))
  }, [user, page, append, setPage])

  // listen alert added definition
  const fnListenAlertAdded = React.useCallback(() => {
    if (!listeners.includes('addeds')) return () => undefined
    if (!user?.id) return () => undefined
    return listenAlertAdded(user.id, (props) => {
      prepend(props.alerts)
    })
  }, [listeners, user?.id, prepend])

  // listen alert count total definition
  const fnListCountTotal = React.useCallback(() => {
    if (!listeners.includes('count_total')) return () => undefined
    if (!user?.id) return () => undefined
    return listenCountTotal(user.id, setCountTotal)
  }, [listeners, user?.id, setCountTotal])

  // execute component subscription for alert added
  React.useEffect(() => {
    return fnListenAlertAdded()
  }, [fnListenAlertAdded])

  // execute component subscription for count total
  React.useEffect(() => {
    return fnListCountTotal()
  }, [fnListCountTotal])

  // calc loaded all alerts
  const loadedAll = React.useMemo(() => {
    return alerts.length === countTotal
  }, [alerts, countTotal])

  return {
    page,
    alerts,
    loading,
    removing,
    loadedAll,
    countTotal,
    handlePaginate,
    handleRemoveAll,
  }
}
