import { ReactNode } from 'react'
import { Stack } from '@mui/material'
import { CardListWrapper } from './Crud.styles'
import VerticalActions from '@components/atoms/VerticalActions/VerticalActions'

type CardListProps = {
  children: ReactNode
  actionEdit: () => void
  actionRemove: () => void
}

export default function CardList({
  children,
  actionEdit,
  actionRemove,
}: CardListProps) {
  return (
    <CardListWrapper>
      <Stack direction="row" spacing={6}>
        {children}
      </Stack>

      <Stack display="flex" justifyContent="center">
        <VerticalActions actionEdit={actionEdit} actionRemove={actionRemove} />
      </Stack>
    </CardListWrapper>
  )
}
