import { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useSnackbar } from '@contexts/Snackbar'

import { useDrawer } from '@contexts/Drawer'

import { CreateMonitorContext } from './context'

import { useListMonitors } from '@services/nomos_api/resources/monitor/list'
import { useCreateMonitor } from '@services/nomos_api/resources/monitor/create'

export function CreateMonitorController({ onSavedMonitor, children }) {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const { closeDrawer } = useDrawer()
  const { error: errorMonitors, refetch: refetchDataMonitors } =
    useListMonitors()
  const { data: dataCreateMonitor, mutate: mutateCreateMonitor, isLoading } =
    useCreateMonitor()

  const validationSchemaPainelName = Yup.object().shape({
    name: Yup.string()
      .max(30, 'Deve ter no máximo 30 caracteres')
      .required('Nome do painel obrigatório'),
  })

  const formikPainel = useFormik({
    initialValues: {
      name: undefined,
      public: false,
    },
    validationSchema: validationSchemaPainelName,
    onSubmit: (values) => {
      mutateCreateMonitor(values)
    },
  })

  const handleDisableFormCreateNewPainel = () => {
    formikPainel.resetForm()
  }

  useEffect(() => {
    if (errorMonitors) {
      console.log(errorMonitors)
      showSnackbarError(errorMonitors?.response?.data?.message)
    }
  }, [errorMonitors])

  useEffect(() => {
    if (dataCreateMonitor) {
      showSnackbarSuccess(
        'Painel criado com sucesso',
        'Painel foi criado com sucesso'
      )
      if (onSavedMonitor) onSavedMonitor(dataCreateMonitor)
      formikPainel.resetForm()
      handleDisableFormCreateNewPainel()
      closeDrawer()
      refetchDataMonitors()
    }
  }, [dataCreateMonitor, onSavedMonitor])

  const store = useMemo(
    () => ({
      isLoading,
      formikPainel,
      handleDisableFormCreateNewPainel,
    }),
    [isLoading, formikPainel, handleDisableFormCreateNewPainel]
  )

  return (
    <CreateMonitorContext.Provider value={store}>
      {children}
    </CreateMonitorContext.Provider>
  )
}
