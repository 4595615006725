import { Input } from '@components/atoms'
import { DataFoundContext } from './context'
import DataFoundCard from './containers/DataFoundCard'
import { FilterPeriod } from './containers/FilterPeriod'
import { useContextSelector } from 'use-context-selector'
import { FilterBuckets } from './containers/FilterBuckets'
import { FilterMonitors } from './containers/FilterMonitors'
import { pageview } from '@utils/functions/pageview/pageview'
import { EmptyState, Pagination } from '@components/molecules'
import { FilterSavedSearch } from './containers/FilterSavedSearch'
import { CircularProgress, Stack, Typography } from '@mui/material'

export function DataFoundView() {
  const data = useContextSelector(DataFoundContext, (s) => s.data)
  const setPage = useContextSelector(DataFoundContext, (s) => s.setPage)
  const setLimit = useContextSelector(DataFoundContext, (s) => s.setLimit)
  const isLoading = useContextSelector(DataFoundContext, (s) => s.isLoading)

  const textSearchKeyword = useContextSelector(
    DataFoundContext,
    (s) => s.textSearchKeyword
  )

  const setTextSearchKeyword = useContextSelector(
    DataFoundContext,
    (s) => s.setTextSearchKeyword
  )

  return (
    <Stack>
      <Stack gap={3}>
        <Input
          size="tiny"
          type="search"
          label="Buscar por palavra chave"
          aria-label="Buscar por palavra chave"
          value={textSearchKeyword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextSearchKeyword(e.target.value)
          }
        />
        <Stack
          direction={['column', 'column', 'row']}
          gap={2}
          position="relative"
        >
          <FilterMonitors />
          <FilterSavedSearch />
          <FilterBuckets />
          <FilterPeriod />
        </Stack>
      </Stack>

      <Stack mt="40px" data-cy="list-alerts">
        {!isLoading && !((data?.results?.length ?? 0) > 0) ? (
          <EmptyState text="Não há dados a serem exibidos" />
        ) : (
          <>
            <Typography variant="$font-body-base" color="$color-text-secondary">
              Exibindo {pageview(data?.pagination || {})} resultados
            </Typography>

            {data?.results?.map((data) => (
              <DataFoundCard {...data} key={data.id} />
            ))}

            <Pagination
              pagination={data?.pagination}
              handleUpdatePage={setPage}
              handleUpdateLimit={setLimit}
            />
          </>
        )}
        {isLoading && (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
