import IconNoData from '@assets/icons/nodata.svg'
import { Stack, Typography } from '@mui/material'

export type NoDataIconProps = {
  height: number
  text: string
}

function NoDataIcon({ text, height }: NoDataIconProps) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <img src={IconNoData} alt="No Data" height={height} />
      <Typography variant="$font-body-base" color="$color-text-tertiary">
        {text || 'No data'}
      </Typography>
    </Stack>
  )
}

export default NoDataIcon
