import { styled as styledMaterial } from '@mui/material/styles'
import { Stack } from '@mui/material'

export const Container = styledMaterial(Stack)(({ theme }) => ({
  height: 'auto',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '32px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '32px 48px 32px 48px',
  },
}))
