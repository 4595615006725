import { useContext } from 'react'
import { Container, Content, Header } from './styles'
import { EventsCalendar } from '@containers/EventsCalendar'
import { CircularProgress, Typography } from '@mui/material'
import { EventsPanelContext, EventsPanelContextType } from './context'

export function EventsPanelView() {
  const { events, isLoading, handleUpdateSearch } = useContext(
    EventsPanelContext
  ) as EventsPanelContextType

  return (
    <Container>
      <Header>
        <Typography variant="$font-title-3" color="#24365E">
          Eventos Monitorados
        </Typography>

        <Typography variant="$font-body-base" color="#858FA6">
          Esse painel possuí <b>{events?.length || 0} eventos monitorados</b>
        </Typography>
      </Header>

      <Content>
        {isLoading && <CircularProgress color="primary" size={15} />}

        <EventsCalendar
          events={events || []}
          headerLocation="right"
          handleUpdateSearch={handleUpdateSearch}
        />
      </Content>
    </Container>
  )
}
