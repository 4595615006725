/* eslint-disable no-inline-styles/no-inline-styles */
import { useContextSelector } from 'use-context-selector'
import { MyAccountContext } from '../../context'
import { CrumbedPage } from '@components/templates'
import UserData from './UserData/UserData'

export function SideContent() {
  const selectedMenuOption = useContextSelector(
    MyAccountContext,
    (context) => context.selectedMenuOption
  )

  const renderContent = () => {
    switch (selectedMenuOption) {
      case 'option1':
        return <UserData />
      default:
        return <UserData />
    }
  }

  return (
    <CrumbedPage
      id="my-account-page"
      data-cy="my-account-page"
      title=""
      description=""
      paths={[
        { name: 'Página Inicial', location: '/' },
        { name: 'Minha Conta', location: '/my-account' },
      ]}
      style={{
        paddingLeft: '0px',
        paddingTop: '0px',
      }}
    >
      {renderContent()}
    </CrumbedPage>
  )
}
