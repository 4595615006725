import { IconButton, useTheme } from '@mui/material'
import {
  SearchContainer,
  SearchTitleContainer,
  SearchTitleHilight,
  SearchTitleMuted,
} from './FormBreadcrumb.styles'
import { useNavigate } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { SearchResultsContext } from '../../context'
import { CaretLeft } from '@phosphor-icons/react'

function FormBreadcrumbComponent(): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()
  const search = useContextSelector(SearchResultsContext, (s) => s.search)
  return (
    <SearchContainer>
      <SearchTitleContainer>
        <IconButton
          size="small"
          sx={{ width: 21, height: 21 }}
          onClick={() => navigate('/home', { replace: true })}
        >
          <CaretLeft
            fontSize="small"
            color={theme.palette['$color-text-primary']}
          />
        </IconButton>
        <SearchTitleMuted>
          {search.id ? 'Pesquisas salvas' : 'Página inicial'}/
        </SearchTitleMuted>
        <SearchTitleHilight>Resultados</SearchTitleHilight>
      </SearchTitleContainer>
    </SearchContainer>
  )
}

export default FormBreadcrumbComponent
