import { Stack, Typography } from '@mui/material'
import { TextLink } from '@components/atoms'
import { LoginContainer } from './SigninPrompt.styles'

export function SignInPrompt(): JSX.Element {
  return (
    <Stack direction="column" alignItems="center" sx={{ mt: 2 }}>
      <LoginContainer>
        <Typography variant="$font-body-1" color="$color-text-secondary">
          Já tem uma conta?
        </Typography>

        <TextLink href="/" text="Entrar" />
      </LoginContainer>
    </Stack>
  )
}
