import {
  SignInWrapper,
  SignInMainForm,
  SignInFormContainer,
  SignInFormWrapper,
  SignInFormContainerPadding,
  SignInFormSpacer,
} from './styles'

import { CreateAccountPrompt } from './containers/CreateAccountPrompt/CreateAccountPrompt'
import { FormSignEmail } from './containers/FormSigninEmail/FormSigninEmail'
import LogoNomosArkoSvg from '../../../assets/logos/logo-nomos-arko.svg'
import { Presentation } from '@components/organisms/Presentation/Presentation'

export function SigninEmailView() {
  return (
    <SignInWrapper>
      <Presentation />
      <SignInMainForm>
        <SignInFormSpacer />
        <SignInFormWrapper>
          <img
            height={54}
            src={LogoNomosArkoSvg}
            alt="Logotipo Nomos com slogan Arko"
          />
          <SignInFormContainer>
            <SignInFormContainerPadding>
              <FormSignEmail />
            </SignInFormContainerPadding>
          </SignInFormContainer>
          <CreateAccountPrompt />
        </SignInFormWrapper>
        <SignInFormSpacer />
      </SignInMainForm>
    </SignInWrapper>
  )
}
