/* eslint-disable no-else-return */
import moment from 'moment-timezone'

export const getDateSP = (date: string) => {
  const momentDate = moment(date, moment.ISO_8601)
  if (!momentDate.isValid()) throw new Error('Data inválida')
  return momentDate
}

export const getDate = (date: string) => {
  return getDateSP(date).startOf('day').toDate()
}

export const getStartOfWeek = (date: string) => {
  return getDateSP(date).startOf('week').toDate()
}

export const getEndOfWeek = (date: string) => {
  return getDateSP(date).endOf('week').toDate()
}
