import { createContext } from 'use-context-selector'
import { BacenEntity } from '@services/nomos_api/entities/bacen'

export type BacenDetailsContextType = {
  bacen: BacenEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const BacenDetailsContext = createContext<BacenDetailsContextType>(
  {} as BacenDetailsContextType
)
