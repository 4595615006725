import { ShareCheckoutModalController } from './controller'
import { ShareCheckoutModalProps } from './types'
import { ShareCheckoutModalView } from './view'

export default function ShareCheckoutModal(
  props: ShareCheckoutModalProps
): JSX.Element {
  return (
    <ShareCheckoutModalController {...props}>
      <ShareCheckoutModalView {...props} />
    </ShareCheckoutModalController>
  )
}
