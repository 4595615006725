import React from 'react'
import { CardWrapper } from './styles'
import { Avatar, TextLink } from '@components/atoms'
import { Description } from '@components/molecules'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { Typography, Stack, Chip, Button } from '@mui/material'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Link } from 'react-router-dom'
import { formatDateTime } from '@utils/functions/formatter/formatter'
import { PropositionDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'

export function CardInfosProposition({ proposition }) {
  const [open, setOpen] = React.useState(false)

  const appendQueryString = useContextSelector(
    PropositionDetailsContext,
    (s) => s.appendQueryString
  )

  const authorId = proposition?.authors?.[0]?.id
  const author = [
    proposition?.authors?.[0]?.name,
    proposition?.authors?.[0]?.acronymParty,
    proposition?.authors?.[0]?.uf,
  ]
    .filter((i) => !!i)
    .join(' - ')

  return (
    <CardWrapper>
      <Stack spacing={2}>
        {proposition?.id ? (
          <Link data-cy="list-item-proposition" to={`/propositions/${proposition?.id}${appendQueryString}`}>
            <Typography variant="$font-body-base" color="primary">
              {proposition?.title || '--'}
            </Typography>
          </Link>
        ) : (
          <Typography variant="$font-body-base" color="$color-text-primary">
            {proposition?.title || '--'}
          </Typography>
        )}      
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant="$font-body-4" color="$color-text-primary">
            Autor:
          </Typography>
          {authorId ? (
            <Link to={`/stakeholders/${authorId}`}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                {`${author}`}
              </Typography>
            </Link>
          ) : (
            <Typography variant="$font-body-base" color="$color-text-primary">
              {`${author}`}
            </Typography>
          )}
        </Stack>   
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant="$font-body-4" color="$color-text-primary">
            Orgão:
          </Typography>
          {proposition?.lastProceeding ? (
            <Link to={`/organs/${proposition?.lastProceeding?.organ?.id}`}>
              <Typography variant="$font-body-base" color="primary">
                {proposition?.lastProceeding?.organ?.initials || '--'}
              </Typography>
            </Link>            
          ):(
            <Typography variant="$font-body-base" color="$color-text-primary">
              {proposition?.lastProceeding?.organ?.initials || '--'}
            </Typography>
          )}
        </Stack>
        <Typography variant="$font-body-base" color="$color-text-primary">
          {proposition?.summary}
        </Typography>  
      </Stack>      
    </CardWrapper>
  )
}
