import { ContainerScroll, Input } from '@components/atoms'
import Tag from '@components/atoms/Tag/Tag'
import { EmptyState } from '@components/molecules'
import { LinearProgress, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { TagManagerContext } from '@containers/TagManager/context'

export function TagManagerListItems(): JSX.Element {
  const tags = useContextSelector(TagManagerContext, (s) => s.tags)
  const compact = useContextSelector(TagManagerContext, (s) => s.compact)
  const selecteds = useContextSelector(TagManagerContext, (s) => s.selecteds)
  const isLoading = useContextSelector(TagManagerContext, (s) => s.isLoading)
  const handleTagSelection = useContextSelector(
    TagManagerContext,
    (s) => s.handleTagSelection
  )

  if (isLoading) return <LinearProgress />

  if (!tags || tags.length === 0) {
    return (
      <Stack flex={1}>
        <EmptyState text="Não há tags cadastradas. Crie uma tag para relacioná-la á proposição" />
      </Stack>
    )
  }

  return (
    <Stack direction="column" spacing={2} flex={1} overflow="hidden">
      <Typography variant="$font-body-base" color="$color-text-secondary">
        Todas
      </Typography>
      <ContainerScroll pr={compact ? 12 : undefined}>
        <Stack direction="column" spacing={compact ? 1 : 2}>
          {tags.map((tag) => (
            <Stack key={`item-tag-${tag.id}`} direction="row" spacing={1}>
              <Input
                type="checkbox"
                checked={selecteds.some((selected) => selected.id === tag.id)}
                onChange={(
                  _: React.SyntheticEvent<Element, Event>,
                  checked: boolean
                ) => handleTagSelection(tag, checked)}
              />
              <Tag {...tag} fullwidth />
            </Stack>
          ))}
        </Stack>
      </ContainerScroll>
    </Stack>
  )
}
