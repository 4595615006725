import {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  ReactElement,
  useCallback,
} from 'react'

import useScrollBlock from '@hooks/useScrollBlock'
import produce from 'immer'

type DrawerProviderProps = {
  children: React.ReactNode
}

type DrawerOptions = {
  lockclose?: boolean
  lockscroll?: boolean
}

type DrawerComponentStackType = {
  component: JSX.Element | ReactElement
  options?: DrawerOptions
}

type ContextType = {
  stack: DrawerComponentStackType[]
  replaceDrawer: (component: JSX.Element, options?: DrawerOptions) => void
  openDrawer: (component: JSX.Element, options?: DrawerOptions) => void
  closeDrawer: () => void
  cleanStack: () => void
}

const Context = createContext<ContextType>({} as ContextType)

function DrawerProvider({ children }: DrawerProviderProps) {
  const [blockScroll, allowScroll] = useScrollBlock()

  const [stack, setStack] = useState<DrawerComponentStackType[]>([])

  // <!-- add component on last position of stack -->
  // <!-- following the FILO approach -->
  const pushStack = useCallback(
    (component: JSX.Element, options?: DrawerOptions) => {
      setStack(
        produce(stack, (draft) => {
          draft.push({ component, options })
        })
      )
    },
    [setStack, stack]
  )

  // <!-- remove the last item from stack -->
  // <!-- following the FILO approach -->
  const popStack = useCallback(() => {
    setStack(
      produce(stack, (draft) => {
        draft.pop()
      })
    )
  }, [setStack, stack])

  // <!-- expose a function to close all stack modals -->
  const cleanStack = () => setStack([])

  // <!-- expose open modal to be used throughth hooks -->
  const openDrawer = useCallback(
    (component: JSX.Element, options?: DrawerOptions) =>
      pushStack(component, options),
    [pushStack]
  )

  // <!-- expose close drawer to be used throughth hooks -->
  const closeDrawer = useCallback(() => popStack(), [popStack])

  // <!-- expose replace drawer to be used throughth hooks -->
  const replaceDrawer = useCallback(
    (component: JSX.Element, options?: DrawerOptions) => {
      setStack([{ component, options }])
    },
    [setStack]
  )

  useEffect(() => (stack.length > 0 ? blockScroll() : allowScroll()), [stack])

  const drawerProviderValues = useMemo(
    () => ({ stack, openDrawer, closeDrawer, cleanStack, replaceDrawer }),
    [stack, openDrawer, closeDrawer, cleanStack, replaceDrawer]
  )

  return (
    <Context.Provider value={drawerProviderValues}>{children}</Context.Provider>
  )
}

const useDrawer = () => {
  const context = useContext(Context)
  return context
}

export { DrawerProvider, useDrawer }
