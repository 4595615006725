import { AcceptUserView } from './view'
import { AcceptUserController } from './controller'

export default function AcceptUser() {
  return (
    <AcceptUserController>
      <AcceptUserView />
    </AcceptUserController>
  )
}
