import { ContentPaths } from './styles'
import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { BucketsEnum } from '@utils/buckets/type'
import { Stack, Typography } from '@mui/material'
import { IrsDetailsContext } from '../../context'
import { Button, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { ArrowForwardIos, Print, LaunchOutlined } from '@mui/icons-material'

import NotesIcon from '@assets/icons/notes.svg'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()
  const irs = useContextSelector(IrsDetailsContext, (s) => s.irs)
  const keyword = useContextSelector(IrsDetailsContext, (s) => s.keyword)
  const buckets = useContextSelector(IrsDetailsContext, (s) => s.buckets)
  const isLoading = useContextSelector(IrsDetailsContext, (s) => s.isLoading)

  const params = `?buckets=${getSortedBucket(
    buckets,
    'irs'
  )}&keyword=${keyword}`

  return (
    <Stack direction="column" className="notPrintable" spacing={2}>
      {auth && (
        <ContentPaths direction="row" alignItems="center" spacing={1}>
          <NavLink to={isMobile ? '/search-results' : '/home'}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Inicio
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Receita Federal
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <ShimmerText isLoading={isLoading} height={18} words={4}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {`${irs?.type} ${irs?.number}`}
            </Typography>
          </ShimmerText>
        </ContentPaths>
      )}

      <ShimmerText isLoading={isLoading} height={38} words={5}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {`${irs?.type} ${irs?.number}`}
        </Typography>
      </ShimmerText>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            text="Imprimir"
            startIcon={<Print />}
            onClick={() => window.print()}
          />

          {irs?.link && !isMobile && (
            <Button
              variant="text"
              href={irs?.link}
              target="_blank"
              rel="noreferrer"
              text="Acessar na integra"
              startIcon={<LaunchOutlined />}
            />
          )}
        </Stack>
        {irs?.id && (
          <Button
            text="Notas"
            color="$color-extended-red1"
            variant="contained"
            endIcon={<img src={NotesIcon} alt="Notas" />}
            onClick={() => {
              openDrawer(
                <NoteManagerDrawer id={irs.id} bucket={BucketsEnum.irs} />
              )
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default HeaderComponent
