/* eslint-disable camelcase */
import _ from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import { ObjectShape } from 'yup/lib/object'

const validateMonitorReportCategoryPeriod = (validate = false) => {
  const shape: ObjectShape = {
    enabled: Yup.boolean().required(),
  }
  if (validate) {
    shape.period = Yup.object({
      from: Yup.string(),
      to: Yup.string(),
    })
  }
  const YupPeriod = Yup.object(shape)
  if (validate) {
    return YupPeriod.test(
      'is-period-required',
      'selecione o período para a categoria',
      ({ enabled, period }) => {
        return !(
          enabled === true &&
          (!moment(_.get(period, 'from', null)).isValid() ||
            !moment(_.get(period, 'to', null)).isValid())
        )
      }
    )
  }
  return YupPeriod
}

export const validateMonitorReportSchema = Yup.object({
  monitorId: Yup.string().required('Identificador do monitor não encontrado'),
  format: Yup.string()
    .required('Formato de relatório obrigatório')
    .oneOf(['pdf', 'xls'], 'Selecione PDF ou XLS'),
  categories: Yup.object({
    events: validateMonitorReportCategoryPeriod(true),
    organs: validateMonitorReportCategoryPeriod(false),
    propositions: validateMonitorReportCategoryPeriod(true),
    stakeholders: validateMonitorReportCategoryPeriod(false),
    search_data_found: validateMonitorReportCategoryPeriod(true),
  }).test(
    'at-least-one-category',
    'habilite ao menos uma categoria',
    ({ events, organs, propositions, stakeholders, search_data_found }) => {
      return (
        _.get(organs, 'enabled', false) ||
        _.get(events, 'enabled', false) ||
        _.get(stakeholders, 'enabled', false) ||
        _.get(propositions, 'enabled', false) ||
        _.get(search_data_found, 'enabled', false)
      )
    }
  ),
})
