import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Pagination } from '@services/nomos_api/entities'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'

export type GetAllStakeholdersProps = {
  page?: number
  limit?: number
  uf?: string
  name?: string
  active?: string
  monitor?: string
  situation?: string
  acronymParty?: string
  openDataResource?: string[]
  following?: string
}

export const getAllStakeholders = async (
  filters: GetAllStakeholdersProps
): Promise<StakeholderEntity[]> => {
  const params = Object.entries(filters).reduce((accumulator, [key, value]) => {
    const stringValue = value?.toString()
    return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
  }, '')

  const { data } = await HTTPClient.get(`stakeholders?${params}`)
  return (data?.results || data || []) as StakeholderEntity[]
}

export const useGetAllStakeholders = (props: GetAllStakeholdersProps) =>
  useQuery(
    ['use-get-all-stakeholders', props],
    () => getAllStakeholders(props),
    {
      refetchOnWindowFocus: false,
    }
  )

export type GetAllStakeholdersResult = {
  pagination: Pagination
  results: StakeholderEntity[]
}

export type GetAllStakeholdersPaginationFilters = {
  uf?: string
  name?: string
  situation?: string
  active?: string
  acronymParty?: string
  openDataResource?: string[]
  following?: string
}

export type GetAllStakeholdersPaginationProps = {
  page?: number
  limit?: number
  monitor?: string
  filters: GetAllStakeholdersPaginationFilters
}

export const getAllStakeholdersPagination = async ({
  page,
  limit,
  monitor,
  filters,
}: GetAllStakeholdersPaginationProps): Promise<GetAllStakeholdersResult> => {
  const params = Object.entries(filters || {}).reduce(
    (accumulator, [key, value]) => {
      const stringValue = value?.toString()
      return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
    },
    ''
  )

  const { data } = await HTTPClient.get(
    `stakeholders?page=${page}&limit=${limit}&monitor=${
      monitor || ''
    }&${params}`
  )
  return data as GetAllStakeholdersResult
}

export const useGetAllStakeholdersPagination = (
  props: GetAllStakeholdersPaginationProps
) =>
  useQuery(
    ['use-get-all-stakeholders-pagination', props],
    () => getAllStakeholdersPagination(props),
    {
      refetchOnWindowFocus: false,
    }
  )
