import { GridCellParams } from '@mui/x-data-grid'
import { Tooltip, Typography } from '@mui/material'
import { TextLink, TextWrap } from '@components/atoms'
import { formatDate } from '@utils/functions/formatter/formatter'

export const columns = [
  {
    field: 'propositions',
    headerName: 'Proposição',
    width: 160,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      const propositions =
        params.row.propositions && params.row.propositions.length > 0
          ? params.row.propositions
          : params.row.affectedPropositions

      const value =
        propositions && propositions.length > 0 ? propositions[0] : {}

      return (
        <Tooltip title={value.summary}>
          <span>
            <TextLink text={value.title} href={`/propositions/${value.id}`} />
          </span>
        </Tooltip>
      )
    },
  },
  {
    field: 'summary',
    headerName: 'Ementa',
    width: 300,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      const propositions =
        params.row.propositions && params.row.propositions.length > 0
          ? params.row.propositions
          : params.row.affectedPropositions

      const value =
        propositions && propositions.length > 0 ? propositions[0] : {}

      return (
        <TextWrap lines={2}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            {value.summary}
          </Typography>
        </TextWrap>
      )
    },
  },
  {
    field: 'author',
    headerName: 'Autor',
    width: 300,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      const propositions =
        params.row.propositions && params.row.propositions.length > 0
          ? params.row.propositions
          : params.row.affectedPropositions

      const value =
        propositions && propositions.length > 0 ? propositions[0] : {}

      const author =
        value.authors && value.authors.length > 0 ? value.authors[0] : {}

      return <TextLink text={author.name} href={`/stakeholders/${author.id}`} />
    },
  },
  {
    field: 'date',
    headerName: 'Data de votação',
    width: 240,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return (
        <Typography variant="$font-body-base" color="$color-text-primary">
          {formatDate(params.value)}
        </Typography>
      )
    },
  },
  {
    field: 'approved',
    headerName: 'Resultado',
    width: 130,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return (
        <Typography
          variant="$font-body-2"
          color={
            params.value ? '$color-semantic-success' : '$color-semantic-error'
          }
        >
          {params.value ? 'Aprovado' : 'Rejeitado'}
        </Typography>
      )
    },
  },
]
