import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { NavLink as DomNavLink } from 'react-router-dom'

export const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '36px',
}))

export const LeftSide = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}))

export const ContentPaths = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '10px',
}))

export const RightSide = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gap: '16px',
}))

export const NavLink = styled(DomNavLink)({
  textDecoration: 'none',
  marginTop: '-2px',
})

export const TextLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.muted.main,
}))
