import React, { useMemo } from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import { AlertEntity } from '@services/realtime_db/entities/alert'
import { formatDate } from '@utils/functions/formatter/formatter'
import {
  Card,
  CardElements,
  Container,
  DotIcon,
  Title,
  TitleContainer,
} from './Message.styles'
import { theme } from '@theme/index'
import { TextLink } from '@components/atoms'
import { getBucketName } from '@utils/buckets'

const defaultProps = {
  color: 'primary',
  prevAlert: undefined,
}

export type CardMessageProps = {
  alert: AlertEntity
  prevAlert?: AlertEntity
  color?:
    | 'primary'
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined
}

function CardMessage({ prevAlert, alert, color }: CardMessageProps) {
  const displayCreatedAt = React.useMemo(() => {
    return formatDate(prevAlert?.createdAt) !== formatDate(alert.createdAt)
  }, [prevAlert, alert])

  const link = useMemo(() => {
    if (['social', 'cvm'].includes(alert.bucket)) return alert.link
    return `/${alert.bucket}/${alert.originId}`
  }, [alert])

  const removerUrls = (texto: string) => {
    const texto1 = texto.replace(/\[[^\]]*\]/g, '')

    return texto1.replace(/\[[^\]]*\.\.\./g, '')
  }

  return (
    <Stack direction="column" spacing={2} width="100%">
      {displayCreatedAt && (
        <Typography variant="$font-body-3" color="$color-text-secondary">
          {formatDate(alert.createdAt)}
        </Typography>
      )}
      <Container>
        <DotIcon color={color} />
        <Card>
          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="$font-body-base" color="$color-text-secondary">
              Pesquisa salva:
            </Typography>
            <Typography variant="$font-body-2" color="$color-text-primary">
              {alert.name || alert.keyword || '--'}
            </Typography>
          </Stack>
          <CardElements>
            <TitleContainer>
              <Title>
                <TextLink
                  href={link}
                  target="_blank"
                  underline="hover"
                  text={alert.title}
                />
              </Title>
              <Chip
                size="small"
                sx={{
                  px: 1,
                  backgroundColor: theme.palette['$color-background-tertiary'],
                }}
                label={
                  <Typography
                    variant="$font-body-3"
                    color="$color-text-primary"
                  >
                    {getBucketName(alert.bucket)}
                  </Typography>
                }
              />
            </TitleContainer>
            <Typography
              variant="$font-body-base"
              color="$color-text-secondary"
              sx={{ textWrap: 'wrap' }}
            >
              {removerUrls(alert.content)}
            </Typography>
          </CardElements>
        </Card>
      </Container>
    </Stack>
  )
}

CardMessage.defaultProps = defaultProps

export default CardMessage
