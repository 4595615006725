import { SVGProps } from 'react'

function ScheduleIcon({
  width = 32,
  height = 32,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill}>
      <path
        d="M26.6666 13.3333V8.66667C26.6666 7.5621 25.7712 6.66667 24.6666 6.66667H7.33325C6.22868 6.66667 5.33325 7.5621 5.33325 8.66667V13.3333M26.6666 13.3333V26C26.6666 27.1046 25.7712 28 24.6666 28H7.33325C6.22868 28 5.33325 27.1046 5.33325 26V13.3333M26.6666 13.3333H5.33325M10.6666 4V9.33333M21.3333 4V9.33333"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ScheduleIcon
