/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

function useInfinityScroll(
  ref: React.RefObject<HTMLDivElement> | undefined,
  callback: () => Promise<any> | null
) {
  const [shouldCallNextPage, setShouldCallNextPage] = useState(false)

  const handleScroll = () => {
    if (!ref?.current) return
    const { clientHeight, scrollTop, scrollHeight } = ref.current
    if (clientHeight + scrollTop + 1 >= scrollHeight) {
      setShouldCallNextPage(true)
    }
  }

  useEffect(() => {
    if (shouldCallNextPage) {
      const goToNextPage = callback()
      if (goToNextPage) {
        goToNextPage.then(() => {
          setShouldCallNextPage(false)
        })
      } else {
        setShouldCallNextPage(false)
      }
    }
  }, [shouldCallNextPage])

  useEffect(() => {
    if (!ref?.current) return () => undefined
    ref.current.addEventListener('scroll', handleScroll, true)
    return () => {
      ref?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, ref])
}

export { useInfinityScroll }
