import _ from 'lodash'
import { DataFoundContext } from '../../context'
import { DateRangePicker } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'

export function FilterPeriod(): JSX.Element {
  const periodFilter = useContextSelector(
    DataFoundContext,
    (s) => s.periodFilter
  )

  const setPeriodFilter = useContextSelector(
    DataFoundContext,
    (s) => s.setPeriodFilter
  )
  return (
    <DateRangePicker
      sx={{ width: '100%', background: 'white' }}
      size="tiny"
      label="Período"
      startDate={_.get(periodFilter, 'from')}
      endDate={_.get(periodFilter, 'to')}
      aria-label="Período"
      onChange={(from: string | undefined, to: string | undefined) =>
        setPeriodFilter({ from: from!, to: to! })
      }
    />
  )
}
