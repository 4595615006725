import { SVGProps } from 'react'

function HelpIcon({
  width = 24,
  height = 24,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} fill={fill}>
      <path
        d="M9.00049 10C9.00049 9.40666 9.17644 8.82664 9.50608 8.33329C9.83572 7.83994 10.3043 7.45542 10.8524 7.22836C11.4006 7.0013 12.0038 6.94189 12.5858 7.05764C13.1677 7.1734 13.7023 7.45912 14.1218 7.87868C14.5414 8.29824 14.8271 8.83279 14.9428 9.41473C15.0586 9.99667 14.9992 10.5999 14.7721 11.1481C14.5451 11.6962 14.1605 12.1648 13.6672 12.4944C13.1739 12.8241 12.5938 13 12.0005 13V14M21.0005 12C21.0005 16.9706 16.9711 21 12.0005 21C7.02993 21 3.00049 16.9706 3.00049 12C3.00049 7.02944 7.02993 3 12.0005 3C16.9711 3 21.0005 7.02944 21.0005 12Z"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.0005" cy="17" r="1" fill="#202122" />
    </svg>
  )
}

export default HelpIcon
