import { ContentPaths } from './styles'
import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { BucketsEnum } from '@utils/buckets/type'
import { DouDetailsContext } from '../../context'
import { Stack, Typography } from '@mui/material'
import { Button, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { ArrowForwardIos, Print, LaunchOutlined } from '@mui/icons-material'

import NotesIcon from '@assets/icons/notes.svg'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()
  const dou = useContextSelector(DouDetailsContext, (s) => s.dou)
  const keyword = useContextSelector(DouDetailsContext, (s) => s.keyword)
  const buckets = useContextSelector(DouDetailsContext, (s) => s.buckets)
  const isLoading = useContextSelector(DouDetailsContext, (s) => s.isLoading)

  const params = `?buckets=${getSortedBucket(
    buckets,
    'dou'
  )}&keyword=${keyword}`

  return (
    <Stack direction="column" className="notPrintable" spacing={2}>
      {auth && (
        <ContentPaths
          direction="row"
          alignItems="center"
          gap={1}
          flexWrap="wrap"
        >
          <NavLink to={isMobile ? '/search-results' : '/home'}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Inicio
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Diário Oficial da União
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <ShimmerText isLoading={isLoading} height={18} words={4}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {dou?.title}
            </Typography>
          </ShimmerText>
        </ContentPaths>
      )}

      <ShimmerText isLoading={isLoading} height={38} words={5}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {dou?.title}
        </Typography>
      </ShimmerText>

      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            text="Imprimir"
            startIcon={<Print />}
            onClick={() => window.print()}
          />

          <Button
            variant="text"
            href={dou?.file}
            target="_blank"
            rel="noreferrer"
            text="Acessar página Diário Oficial"
            startIcon={<LaunchOutlined />}
          />
        </Stack>
        <Button
          text="Notas"
          color="$color-extended-red1"
          variant="contained"
          endIcon={<img src={NotesIcon} alt="Notas" />}
          onClick={() => {
            openDrawer(
              <NoteManagerDrawer
                id={dou?.id as unknown as string}
                bucket={BucketsEnum.dou}
              />
            )
          }}
        />
      </Stack>
    </Stack>
  )
}

export default HeaderComponent
