import { createContext } from 'use-context-selector'
import { FeedbackMessage } from '@services/nomos_api/entities/axios'

type EmailConfirmationContextType = {
  isError: boolean
  isLoading: boolean
  response?: FeedbackMessage
}

export const EmailConfirmationContext =
  createContext<EmailConfirmationContextType>(
    {} as EmailConfirmationContextType
  )
