import { Button, ContainerScroll } from '@components/atoms'
import { StepContainer } from '../../styles'
import { useState, useCallback } from 'react'
import { SaveSearchContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { SaveSearchHeader } from '../../components/SaveSearchHeader'

export function StepIntroduction() {
  const [checked, setChecked] = useState(false)

  const handleUpdateStep = useContextSelector(
    SaveSearchContext,
    (s) => s.handleUpdateStep
  )

  const handleNextStep = useCallback(() => {
    if (checked) localStorage.setItem('@NOMOS:save-search-warning', 'true')
    handleUpdateStep('settings')
  }, [checked])

  const items = [
    'Insira pelo menos um filtro para cada categoria de dados na sua pesquisa. (isso evitará que receba um número excessivo de alertas)',
    'Em "Configurações de Alerta", selecione apenas as categoria de dados que são importantes acompanhar',
    'Todos os novos dados encontrados são exibidos em Pesquisas salvas > Dados encontrados, mas você só será alertado para as categorias selecionadas',
  ]

  return (
    <StepContainer data-testid="introduction-step">
      <SaveSearchHeader />
      <ContainerScroll spacing={24} auto="true">
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Salve suas pesquisas no Nomos para monitorar dados relevantes. Você
          será alertado quando novos dados relacionados à sua pesquisa forem
          encontrados.
        </Typography>

        <Stack direction="row" spacing={1} alignItems="center">
          <InfoOutlinedIcon color="primary" />
          <Typography variant="$font-title-5" color="$color-text-primary">
            Importante Saber
          </Typography>
        </Stack>

        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {index + 1}. {item}
            </Typography>
          </div>
        ))}

        <FormControlLabel
          onChange={() => setChecked(!checked)}
          control={<Checkbox checked={checked} />}
          data-testid="not-display-message-checkbox"
          label={
            <Typography variant="$font-body-base" color="$color-text-secondary">
              Não exibir esta mensagem novamente
            </Typography>
          }
        />
      </ContainerScroll>
      <Button
        text="Próximo"
        size="large"
        variant="contained"
        sx={{ p: '12px 0', mt: 3 }}
        id="go-to-next-step"
        onClick={handleNextStep}
      />
    </StepContainer>
  )
}
