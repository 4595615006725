/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'
import { Member, Monitor } from '@services/nomos_api/entities/user'
import { SeatCountType } from '@services/nomos_api/entities/seat_count'

type UserUpsertContextType = {
  formik: any
  page: number
  user?: Member
  isLoading?: boolean
  isEditting?: boolean
  loadingSeats?: boolean
  seats: SeatCountType | undefined
  setPage: (page: number) => void
  handleSubmit: (monitors: Monitor[]) => void
}

export const UserUpsertContext = createContext<UserUpsertContextType>(
  {} as UserUpsertContextType
)
