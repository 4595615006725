import HTTPClient from '../..'
import { loginEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { AuthResponseSignAuthType } from '@services/nomos_api/types/auth_response_signauth_type'

export type LoginProps = {
  email: string
  password: string
}

export const SignInEmailAPI = async (body: LoginProps) => {
  const { data } = await HTTPClient.post<AuthResponseSignAuthType>(
    '/signinemail',
    body
  )
  loginEvent(body)
  return data
}

export const useEmailLogin = () => {
  return useMutation<AuthResponseSignAuthType, AxiosErrorResponse, LoginProps>(
    ['login'],
    SignInEmailAPI
  )
}
