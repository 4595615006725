import React from 'react'
import { LinearProgress } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SearchResultsContext } from '@pages/private/SearchResults/context'

function FormLoadingComponent() {
  const isLoading = useContextSelector(SearchResultsContext, (s) => s.isLoading)
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isLoading) return <></>
  return <LinearProgress />
}
export default FormLoadingComponent
