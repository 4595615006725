import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { PropositionVotingEntity } from '@services/nomos_api/entities/proposition'

export const getVoting = async (
  propositionId: string,
  votingId: string
): Promise<PropositionVotingEntity> => {
  const { data } = await HTTPClient.get(
    `/propositions/${propositionId}/votes/${votingId}`
  )
  return data as PropositionVotingEntity
}

export const useGetVoting = (propositionId: string, votingId: string) =>
  useQuery(['get-voting'], () => getVoting(propositionId, votingId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
