import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import HTTPClient from '../..'
import { Pagination } from '../../entities/pagination'

import { SavedSearchEntity } from '../../entities/saved_searches'
import { MonitorOnwerFilterType } from '@services/nomos_api/entities/monitor'

export type GetAllSavedSearchesResult = {
  pagination: Pagination
  results: SavedSearchEntity[]
}

export type GetAllSavedSearchesProps = {
  q?: string
  page: number
  limit: number
  monitorId?: string
  only?: MonitorOnwerFilterType[]
}

export const getAllSavedSearches = async ({
  q,
  only,
  page,
  limit,
  monitorId,
}: GetAllSavedSearchesProps): Promise<GetAllSavedSearchesResult> => {
  const params = []
  if (q) params.push(`q=${q}`)
  if (page) params.push(`page=${page}`)
  if (limit) params.push(`limit=${limit}`)
  if (only) params.push(`only=${only.join(',')}`)
  if (monitorId) params.push(`monitorId=${monitorId}`)
  const { data } = await HTTPClient.get(`saved-searches?${params.join('&')}`)
  return (data || []) as GetAllSavedSearchesResult
}

export const useGetAllSavedSearches = (props: GetAllSavedSearchesProps) =>
  useQuery(
    ['get-all-saved-searches', props],
    () => getAllSavedSearches(props),
    {
      refetchOnWindowFocus: false,
    }
  )

export const useGetInfinitySavedSearches = (
  props?: Partial<Pick<GetAllSavedSearchesProps, 'q' | 'monitorId' | 'only'>>
) =>
  useInfiniteQuery(
    ['get-infinity-saved-searches', props],
    ({ pageParam = 1 }) =>
      getAllSavedSearches({
        page: pageParam,
        limit: 10,
        q: props?.q,
        only: props?.only,
        monitorId: props?.monitorId,
      }),
    {
      retry: false,
      refetchOnMount: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        if (
          lastPage.pagination.total / lastPage.pagination.limit >
          pages.length
        ) {
          return pages.length + 1
        }
        return undefined
      },
    }
  )
