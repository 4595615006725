import {
  styled,
  Badge,
  Autocomplete,
  badgeClasses,
  autocompleteClasses,
} from '@mui/material'

export const AdvancedBadgeCount = styled(Badge)`
  color: white;
  display: flex;
  position: relative;
  & .${badgeClasses.badge} {
    right: -18px;
    top: 18px;
  }
`

export const InputAutocomplete = styled(Autocomplete)`
  cursor: text !important;
  & .${autocompleteClasses.popper} {
    display: none !important;
  }
  & .${autocompleteClasses.input} {
    cursor: text !important;
  }
  & .${autocompleteClasses.root} {
    cursor: text !important;
  }
  & > .MuiFormControl-root {
    height: auto;
  }
  & > .MuiFormControl-root > .MuiInputBase-root {
    height: auto;
    min-height: 48px;
  }
`
