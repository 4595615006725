import { Card } from '@components/atoms'
import { styled as StyledMui, Box } from '@mui/material'

export const FilterableCard = StyledMui(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '820px',
  padding: 16,
  [theme.breakpoints.up('md')]: {
    width: '50%',
    height: '490px',
    padding: '24px',
  },
}))

export const ContainerColumn = StyledMui(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
}))

export const ContainerRow = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  marginRight: '16px',
  marginBottom: '8px',
  [theme.breakpoints.up('md')]: {
    marginRight: 'unset',
    marginBottom: 'unset',
  },
}))

export const ContainerGap = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    gap: '24px',
  },
}))

export const ContainerEnd = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}))

export const Checkbox = StyledMui(Box)(() => ({
  display: 'flex',
  gap: '8px',
}))

export const Header = StyledMui(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}))

export const List = StyledMui(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '22px',
  overflow: 'auto',
  minHeight: '100px',
  gap: '32px',
}))

export const Stakeholder = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}))

export const NoData = StyledMui(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
