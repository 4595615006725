import React from 'react'
import * as Yup from 'yup'
import { TagManagerCreateContext } from './context'
import { TagManagerCreateControllerProps } from './types'
import { FormikProps, useFormik } from 'formik'
import { TagManagerFormType } from '@containers/TagManager/types'
import { DefaultInputColorPickerProps } from '@components/atoms/InputColorPicker/InputColorPicker'
import { TagManagerContext } from '@containers/TagManager/context'
import { useContextSelector } from 'use-context-selector'
import { createTagApi } from '@services/nomos_api/resources/tags/create'
import { TagEntity } from '@services/nomos_api/entities/tag'
import { useSnackbar } from '@contexts/Snackbar'

export function TagManagerCreateController({
  children,
}: TagManagerCreateControllerProps): JSX.Element {
  const { showSnackbarError } = useSnackbar()
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const refetchTags = useContextSelector(
    TagManagerContext,
    (s) => s.refetchTags
  )

  const setViewmode = useContextSelector(
    TagManagerContext,
    (s) => s.setViewmode
  )

  const handleCreateTag = (
    body: Pick<TagEntity, 'color' | 'name'>,
    onSuccess: () => void
  ) => {
    setLoading(true)
    createTagApi(body)
      .then(onSuccess)
      .catch((error) =>
        showSnackbarError('Não foi possível criar a tag', error.message)
      )
      .finally(() => setLoading(false))
  }

  const form: FormikProps<TagManagerFormType> = useFormik({
    initialValues: {
      name: undefined as unknown as string,
      color: undefined as unknown as string,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Define um nome para a nova tag'),
      color: Yup.mixed()
        .oneOf(DefaultInputColorPickerProps)
        .required('Selecione uma cor válida'),
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: (values) => {
      handleCreateTag(values, () => {
        form.resetForm()
        setViewmode('listing')
        refetchTags()
      })
    },
  })

  const state = React.useMemo(
    () => ({
      form,
      isLoading,
      setViewmode,
    }),
    [form, isLoading, setViewmode]
  )
  return (
    <TagManagerCreateContext.Provider value={state}>
      {children}
    </TagManagerCreateContext.Provider>
  )
}
