import { Card, styled as styledMaterial } from '@mui/material'
import { theme } from '@theme/index'

export const CardListWrapper = styledMaterial(Card)(() => ({
  padding: '32px 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  marginTop: '1rem',
  background: `${theme.palette.common.white}`,
}))
