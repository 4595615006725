import { useCallback, useMemo } from 'react'
import { InputMultiSelectChip } from '@components/atoms'
import { getBucketName, searcheableBuckets } from '@utils/buckets'
import { BucketsEnum, KeyValuePair } from '@utils/buckets/type'

type BucketSelectorProps = {
  label?: string
  buckets: string[]
  onChange: (buckets: BucketsEnum[]) => void
}

function BucketSelector({ label, onChange, buckets }: BucketSelectorProps) {
  const availableBuckets = searcheableBuckets.map((bucketKey) => {
    return {
      key: bucketKey,
      label: getBucketName(bucketKey),
    }
  })

  const activeBuckets = useMemo(
    () => availableBuckets.filter((bucket) => buckets.includes(bucket.key)),
    [buckets]
  )

  const handleChangeOptions = useCallback(
    (_, options) => {
      onChange(options.map((o: KeyValuePair) => o.key))
    },
    [buckets]
  )

  return (
    <InputMultiSelectChip
      id="buckets"
      label={label || 'Buscar em'}
      options={availableBuckets}
      value={activeBuckets}
      maxRenderTags={1}
      onChangeOptions={handleChangeOptions}
      sx={{ width: '100%', backgroundColor: 'white' }}
      disabled={false}
      loading={false}
    />
  )
}

export default BucketSelector
