import { Typography, Link } from '@mui/material'

export type FeedbackPromptMessageProps = {
  type: string
}

export enum FeedbackMessageType {
  SignupSuccess = 'signup-success',
  ResetSuccess = 'reset-success',
  ResetError = 'reset-error',
}

export function FeedbackPromptMessage({ type }: FeedbackPromptMessageProps) {
  if (
    type === FeedbackMessageType.ResetSuccess ||
    type === FeedbackMessageType.SignupSuccess
  ) {
    return (
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Já confirmou o e-mail?{' '}
        <Link href="/" underline="always">
          Entrar
        </Link>
      </Typography>
    )
  }

  if (type === FeedbackMessageType.ResetError) {
    return (
      <Link
        href="/forgot-password"
        underline="always"
        variant="subtitle1"
        sx={{ mt: 2 }}
      >
        Informar um novo email
      </Link>
    )
  }

  return null
}

export default FeedbackPromptMessage
