import styled from '@emotion/styled'
import { theme } from '@theme/index'
import { Mention, MentionsInput } from 'react-mentions'
import { Stack, styled as styledmui } from '@mui/material'

export const AttachmentButton = styled.label`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: ${theme.palette['$color-border-tertiary']};
  &:hover {
    background-color: ${theme.palette['$color-background-tertiary']};
  }
  & input[type='file'] {
    display: none;
  }
`

export const MentionInputWrapper = styledmui(Stack)`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${theme.palette['$color-border-tertiary']};
`

export const MentionsInputStyle = styled(MentionsInput)`
  font-size: 14px;

  & textarea {
    border-width: 0px;
    outline: none;
  }

  & .mentions_input__suggestions__list {
    border: 0px solid black;
    border-radius: 3px;
    overflow: hidden;
    padding: 8px 0px !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.15) 0px 0px 0px 1px;
    max-height: 200px;
    overflow: auto;
  }

  & .mentions_input__suggestions__item {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    font-family: Inter;
    color: #202122;
  }

  & .mentions_input__suggestions__item--focused {
    background-color: #f4f4f4;
  }
`

export const MentionStyled = styled(Mention)`
  font-size: 14px;
  line-height: 1.2em;
  background-color: #e5e5f1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`
