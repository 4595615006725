import { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useSnackbar } from '@contexts/Snackbar'

import { useDrawer } from '@contexts/Drawer'

import { EditMonitorContext } from './context'

import { updateMonitorAPI } from '@services/nomos_api/resources/monitor/update'
import { useListMonitors } from '@services/nomos_api/resources/monitor/list'

export function EditMonitorController({ onSavedMonitor, monitor, children }) {
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()
  const { closeDrawer } = useDrawer()

  const { refetch: refetchDataMonitors } = useListMonitors()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchemaPainelName = Yup.object().shape({
    name: Yup.string()
      .max(30, 'Deve ter no máximo 30 caracteres')
      .required('Nome do painel obrigatório'),
  })

  const formikPainel = useFormik({
    initialValues: {
      name: monitor?.name,
      public: monitor?.public,
    },
    validationSchema: validationSchemaPainelName,
    onSubmit: (values) => {
      setIsLoading(true)
      updateMonitorAPI(monitor?.id, values)
        .then((data) => {
          formikPainel.resetForm()
          showSnackbarSuccess('Painel editado com sucesso')
          if (onSavedMonitor) onSavedMonitor(data)
          setIsLoading(false)
          closeDrawer()
          refetchDataMonitors()
        })
        .catch((error) => {
          showSnackbarError(error?.response?.data?.message)
        })
        .finally(() => {
          setIsLoading(false)
          setTimeout(closeSnackbar, 3000)
        })      
    },
  })

  const handleDisableFormCreateNewPainel = () => {
    formikPainel.resetForm()
  }

  const store = useMemo(
    () => ({
      isLoading,
      formikPainel,
      handleDisableFormCreateNewPainel,
    }),
    [isLoading, formikPainel, handleDisableFormCreateNewPainel]
  )

  return (
    <EditMonitorContext.Provider value={store}>
      {children}
    </EditMonitorContext.Provider>
  )
}
