import React from 'react'
import { useFormik } from 'formik'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { StateModalHeader } from './StateModalHeader'
import { useContextSelector } from 'use-context-selector'
import { ExportPropositionModalContext } from '../context'
import {
  Stack,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

export function StateModalFormat() {
  const { closeModal } = useModal()

  const format = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.format
  )

  const setFormat = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.setFormat
  )

  const form = useFormik({
    initialValues: {
      format: format || 'xlsx',
    },
    onSubmit: (values) => setFormat(values.format),
  })

  return (
    <>
      <StateModalHeader description="Selecione abaixo o formato que deseja exportar as propisições." />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          paddingY={1}
          spacing={2}
        >
          <RadioGroup
            row
            name="format"
            onBlur={form.handleBlur}
            defaultValue="xlsx"
            value={form.values.format || 'xlsx'}
            onChange={form.handleChange}
          >
            <FormControlLabel
              value="xlsx"
              label="XLSX"
              control={<Radio size="small" />}
            />
            <FormControlLabel
              value="csv"
              label="CSV"
              disabled
              control={<Radio size="small" />}
            />
            <FormControlLabel
              value="pdf"
              label="PDF"
              disabled
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
          <Button
            text="Próximo"
            variant="contained"
            onClick={() => form.handleSubmit()}
          />
        </Stack>
      </DialogActions>
    </>
  )
}
