/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
import _ from 'lodash'
import { buckets } from '@utils/buckets'
import { useEffect, useState } from 'react'
import { FilterSelectDropdown } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SavedBucketsContext } from '@containers/SavedBucketsList/context'

interface SavedBucketsFilterCategoryProps {}

export function SavedBucketsFilterCategory(
  __: SavedBucketsFilterCategoryProps
) {
  const filters = useContextSelector(SavedBucketsContext, (s) => s.filters)
  const [bucketsFilter, setBucketsFilter] = useState<string[]>(
    filters.buckets || []
  )

  const handleFilterChange = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleFilterChange
  )

  useEffect(() => {
    handleFilterChange({ buckets: bucketsFilter })
  }, [bucketsFilter])

  return (
    <FilterSelectDropdown
      size="tiny"
      label="Categoria de Dado"
      data-cy="filter-categories"
      values={bucketsFilter}
      options={Object.values(buckets)
        .filter((bucket) => bucket.mode === 'favorite')
        .map((savedBucket) => ({
          label: savedBucket.name,
          value: savedBucket.key,
        }))}
      onChangeOptions={setBucketsFilter}
    />
  )
}
