import styled from 'styled-components'

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  padding-bottom: 36px;
  justify-content: space-between;
  border-bottom: 1px solid #e7e9ed;
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 80%;
`

export const RightContent = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
`

export const Status = styled.div`
  display: flex;
  gap: 12px;
`
