import { TabContext } from '@mui/lab'
import { MonitorContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { TabSelectorTab, TabSelectorTabs } from './styles'

export function TabSelector() {
  const selectedTab = useContextSelector(MonitorContext, (s) => s.selectedTab)
  const handleChangeTab = useContextSelector(
    MonitorContext,
    (s) => s.handleChangeTab
  )

  return (
    <TabContext value={selectedTab}>
      <TabSelectorTabs 
        onChange={(_, key) => handleChangeTab(key)} 
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        <TabSelectorTab value="general" label="Dados Gerais" disableRipple />
        <TabSelectorTab value="proposition" label="Proposições" disableRipple />
        <TabSelectorTab value="stakeholders" label="Stakeholders" disableRipple />
        <TabSelectorTab value="organs" label="Órgãos" disableRipple />
        <TabSelectorTab value="events" label="Eventos" disableRipple />
        <TabSelectorTab
          value="savedsearch"
          label="Pesquisas Salvas"
          disableRipple
        />
        <TabSelectorTab
          value="savedbuckets"
          label="Dados Favoritados"
          disableRipple
        />
      </TabSelectorTabs>
    </TabContext>
  )
}
