import { Shimmer } from '@components/atoms'
import { UsersContext } from '../../context'
import { Status } from '@components/molecules'
import { Stack, useTheme } from '@mui/material'
import { useContextSelector } from 'use-context-selector'

export function SeatIndicator(): JSX.Element {
  const theme = useTheme()
  const seats = useContextSelector(UsersContext, (s) => s.seats)
  const loadingSeats = useContextSelector(UsersContext, (s) => s.loadingSeats)
  const countSeats = (seats?.seats.quantity || 0) - (seats?.seats?.members || 0)
  const showSeats = !seats?.isTrial
  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Shimmer isLoading={loadingSeats} width={180} height={35}>
        <Status
          color={theme.palette['$color-extended-red1'].main}
          text={`Assentos utilizados - ${seats?.seats.members || 0}`}
        />
      </Shimmer>
      {showSeats && (
        <Shimmer isLoading={loadingSeats} width={180} height={35}>
          <Status
            color={theme.palette['$color-extended-green1'].main}
            text={`Assentos disponíveis - ${countSeats}`}
          />
        </Shimmer>
      )}
    </Stack>
  )
}
