import { theme } from '@theme/index'
import styledEmotion from '@emotion/styled'
import {
  Box,
  BoxProps,
  styled,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from '@mui/material'

export const Wrapper = styled((props: BoxProps) => (
  <Box {...props} sx={{ display: { xs: 'none', md: 'flex' } }} />
))`
  height: 80px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  margin-left: 88px;
  margin-right: 24px;
`

export const HeaderTabsWrapper = styled(Box)`
  gap: 10px;
  height: 80px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`

export const HeaderTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  height: '100%',
  '& .MuiTabs-root ': { display: 'flex', overflow: 'hidden' },
  '& .MuiTabs-flexContainer': { height: '100%' },
})

export const HeaderTabOuter = styledEmotion.div<{ visible: 'true' | 'false' }>`
  display: ${(props) => (props.visible === 'true' ? 'inline-flex' : 'none')};
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  overflow: hidden;
  white-space: normal;
  text-align: center;
`

export const HeaderTab = styled(Tab)({
  textTransform: 'none',
  ...theme.typography['$font-body-2'],
  color: theme.palette['$color-text-primary'],
  opacity: 1,
})

export const LinkNav = styled(Typography)<{ active: 'true' | 'false' }>`
  text-decoration: none;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid
    ${(props) => (props.active === 'true' ? '#4070f4' : 'transparent')};
`
