import { uniqueId } from 'lodash'
import { ReactElement } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { BucketsEnum } from '@utils/buckets/type'
import { Stack, Typography } from '@mui/material'
import { Button, Shimmer } from '@components/atoms'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { Container, Header, Subtitle, Title } from './DetailsPage.styles'
import Breadcrumb, { Path } from '@components/molecules/Breadcrumb/Breadcrumb'

import NotesIcon from '@assets/icons/notes.svg'

export type TabbedPageProps = {
  id: string
  bucket: BucketsEnum
  title: string
  isLoading: boolean
  isMonitored: boolean
  paths: Path[]
  children: React.ReactChild
  actions?: { name: string; url: string; icon: ReactElement }[]
}

const defaultProps = {
  actions: null,
}

function DetailsPage({
  id,
  bucket,
  title,
  isLoading,
  isMonitored,
  paths,
  actions,
  children,
}: TabbedPageProps) {
  const { openDrawer } = useDrawer()

  return (
    <Container data-testid={id}>
      <Header>
        <Breadcrumb paths={paths} isLoading={isLoading} />

        <Title>
          <Shimmer height={50} width={500} isLoading={isLoading}>
            <Typography
              variant="$font-title-2"
              color="$color-text-primary"
              sx={{ maxWidth: '80%' }}
            >
              {title}
            </Typography>
          </Shimmer>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              text="Opções de Monitoramento"
              variant="outlined"
              disabled={isLoading}
              sx={{ height: 40 }}
              onClick={() =>
                openDrawer(
                  <MonitoringDrawer
                    id={id}
                    origin={bucket}
                    isSaved={isMonitored}
                  />
                )
              }
            />
            <Button
              text="Notas"
              color="$color-extended-red1"
              variant="contained"
              endIcon={<img src={NotesIcon} alt="Notas" />}
              onClick={() => {
                openDrawer(<NoteManagerDrawer id={id} bucket={bucket} />)
              }}
            />
          </Stack>
        </Title>

        {actions?.map((action) => (
          <Subtitle key={uniqueId()} onClick={() => window.open(action.url)}>
            <Typography variant="$font-body-2" color="$color-action-link">
              {action.name}
            </Typography>
            {action.icon}
          </Subtitle>
        ))}
      </Header>

      {children}
    </Container>
  )
}

DetailsPage.defaultProps = defaultProps

export default DetailsPage
