/* eslint-disable no-restricted-syntax */
export const matchUnorderedPhrases = (
  mainPhrase: string,
  testPhrase: string
): boolean => {
  const words1 = mainPhrase.toLowerCase().trim().split(/\s+/) // Split phrase1 into words
  const words2 = testPhrase.toLowerCase().trim().split(/\s+/) // Split phrase2 into words

  for (const word2 of words2) {
    const regex = new RegExp(`${word2}`, 'i') // Create a case-insensitive word boundary regex
    let found = false
    for (const word1 of words1) {
      if (regex.test(word1)) {
        // If any part of a word from phrase1 matches phrase2, set found to true
        found = true
        break
      }
    }
    if (!found) {
      // If no part of any word from phrase1 matches phrase2, return false
      return false
    }
  }

  // At least one part of a word from phrase1 matches phrase2
  return true
}
