import { useState } from 'react'
import { UsersContext } from '../../context'
import { TrashCan } from '@components/icons'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'
import { Input, VerticalActions } from '@components/atoms'
import { Chip, CircularProgress, Stack, Typography } from '@mui/material'
import { formatDate } from '@utils/functions/formatter/formatter'

import {
  CardInfo,
  Container,
  DeleteUserContainer,
  LoadingContainer,
  UserCard,
} from './styles'
import { NomosProductCode, NomosProductRoleName } from '@enums/NomosProductEnum'

export default function ListUsers() {
  const navigate = useNavigate()
  const [ids, setIds] = useState<string[]>([])

  const handleSetIdToDelete = (id: string) => {
    if (ids.includes(id)) {
      setIds(ids.filter((i) => i !== id))
    } else {
      setIds([...ids, id])
    }
  }

  const users = useContextSelector(UsersContext, (s) => s.users)
  const isLoading = useContextSelector(UsersContext, (s) => s.isLoading)

  const setPage = useContextSelector(UsersContext, (s) => s.setPage)
  const setLimit = useContextSelector(UsersContext, (s) => s.setLimit)
  const handleDelete = useContextSelector(UsersContext, (s) => s.handleDelete)

  const deleteUsers = async () => {
    await handleDelete(ids)
    setIds([])
  }

  return (
    <Container>
      <LoadingContainer>
        {isLoading && <CircularProgress size={25} />}
      </LoadingContainer>

      {ids.length > 0 && (
        <DeleteUserContainer onClick={deleteUsers}>
          <TrashCan />
          <Typography variant="$font-body-2" color="$color-semantic-error">
            Deletar
          </Typography>
        </DeleteUserContainer>
      )}

      {users?.results?.map((user) => (
        <UserCard key={user.id}>
          <Input
            type="checkbox"
            checked={ids.includes(user.id)}
            onClick={() => {
              handleSetIdToDelete(user.id)
            }}
          />

          <CardInfo>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Nome
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="$font-body-base" color="$color-text-primary">
                {user.name}
              </Typography>
              {user.admin && (
                <Chip
                  size="small"
                  label={
                    <Typography
                      variant="$font-body-3"
                      color="$color-text-primary"
                    >
                      Admin
                    </Typography>
                  }
                  variant="filled"
                  color="$color-extended-purple5"
                />
              )}
            </Stack>
          </CardInfo>

          <CardInfo>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Email
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {user.email}
            </Typography>
          </CardInfo>
          <CardInfo>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Permissão
            </Typography>
            <Typography
              variant="$font-body-base"
              color={user.role ? '$color-text-primary' : '$color-text-tertiary'}
            >
              {NomosProductRoleName[user.role as NomosProductCode] || 'Nenhuma'}
            </Typography>
          </CardInfo>

          <CardInfo>
            <Typography variant="$font-body-2" color="$color-text-secondary">
              Data de Criação
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {formatDate(user.createdAt)}
            </Typography>
          </CardInfo>

          <VerticalActions
            actionEdit={() => {
              navigate(`/users/${user.id}`)
            }}
            actionRemove={() => {
              handleDelete([user.id])
            }}
          />
        </UserCard>
      ))}

      <Pagination
        pagination={users?.pagination}
        handleUpdatePage={setPage}
        handleUpdateLimit={setLimit}
      />
    </Container>
  )
}
