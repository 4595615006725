import { SeatCountType } from '@services/nomos_api/entities/seat_count'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getSeats = async () => {
  const { data } = await HTTPClient.get(`seats`)
  return data as unknown as SeatCountType
}

export const useGetSeats = () =>
  useQuery(['get-seats'], getSeats, { retry: 0, refetchOnWindowFocus: false })
