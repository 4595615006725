import React from 'react'
import { Link } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { KeyboardArrowUp, KeyboardArrowRight } from '@mui/icons-material'
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material'
import { formatDateTime } from '@utils/functions/formatter/formatter'
import { PropositionDetailsContext } from '../../context'

export function CollapseTableRow({ proposition }) {
  //   const { row } = props
  const [open, setOpen] = React.useState(false)

  const appendQueryString = useContextSelector(
    PropositionDetailsContext,
    (s) => s.appendQueryString
  )

  const authorId = proposition?.authors?.[0]?.id
  const author = [
    proposition?.authors?.[0]?.name,
    proposition?.authors?.[0]?.acronymParty,
    proposition?.authors?.[0]?.uf,
  ]
    .filter((i) => !!i)
    .join(' - ')

  return (
    <>
      <TableRow
        sx={{
          borderBottom: '2px solid white',
          '& > *': {
            borderBottom: '0px solid white',
          },
        }}
      >
        <TableCell>
          {proposition?.id ? (
            <Link data-cy="list-item-proposition" to={`/propositions/${proposition?.id}${appendQueryString}`}>
              <Typography variant="$font-body-base" color="primary">
                {proposition?.title || '--'}
              </Typography>
            </Link>
          ) : (
            <Typography variant="$font-body-base" color="$color-text-primary">
              {proposition?.title || '--'}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {proposition?.lastProceeding ? (
            <Link to={`/organs/${proposition?.lastProceeding?.organ?.id}`}>
              <Typography variant="$font-body-base" color="primary">
                {proposition?.lastProceeding?.organ?.initials || '--'}
              </Typography>
            </Link>            
          ):(
            <Typography variant="$font-body-base" color="$color-text-primary">
              {proposition?.lastProceeding?.organ?.initials || '--'}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {authorId ? (
            <Link to={`/stakeholders/${authorId}`}>
              <Typography variant="$font-body-base" color="primary">
                {`${author}`}
              </Typography>
            </Link>
          ) : (
            <Typography variant="$font-body-base" color="$color-text-primary">
              {`${author}`}
            </Typography>
          )}
          
        </TableCell>
        <TableCell>
          <Typography variant="$font-body-base" color="$color-text-primary">
            {formatDateTime(proposition?.datePresentation)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUp color="primary" />
            ) : (
              <KeyboardArrowRight color="primary" />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { paddingTop: 0, paddingBottom: 0 } }}>
        <TableCell colSpan={5}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ paddingBottom: 2 }}
          >
            <Typography variant="$font-body-base" color="$color-text-primary">
              {proposition?.summary}
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
