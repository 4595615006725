import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
export const Content = styled(Box)`
  overflow: auto;
  height: 100%;
`
