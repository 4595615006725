import { useState } from 'react'
import { useModal } from '@contexts/Modal'
import { useParams } from 'react-router-dom'
import { useDebounce } from '@hooks/useDebounce'
import { CardCrud } from '@components/organisms/Cards'
import { Button, Input } from '@components/atoms'
import IconNoData from '@assets/icons/nodata.svg'
import { CircularProgress, Grid } from '@mui/material'
import { useGetMonitorUsers } from '@services/nomos_api/resources/monitor_users/get-monitors-users'

import {
  HeaderMonitor,
  AddMonitorUser,
  EditMonitorUser,
  RemoveMonitorUser,
} from './containers'

import {
  Container,
  Wrapper,
  SearchBox,
  Loader,
  EmptyData,
  CardListItem,
  CardListTitle,
  CardListContent,
} from './styles'

const USER_ROLES_MAP: Record<string, string> = {
  ADMIN: 'Gestão',
  VIEWER: 'Visualização',
  EDITOR: 'Editor',
}

export default function MonitorUsersView() {
  const { id } = useParams()
  const { openModal } = useModal()
  const [textSearch, setTextSearch] = useState('')

  const debounceTextSearch = useDebounce(textSearch, 500)

  const {
    data: users,
    isLoading,
    refetch,
  } = useGetMonitorUsers({ id, q: debounceTextSearch })

  return (
    <Container id="monitor-users-page">
      <Wrapper direction="column" maxWidth="lg">
        <HeaderMonitor
          id={id!}
          actions={
            <Button
              id="add-monitor-user-btn"
              text="Adicionar Usuário"
              onClick={() =>
                openModal(<AddMonitorUser id={id!} refetch={refetch} />)
              }
            />
          }
        />

        <SearchBox>
          <Input
            size="medium"
            type="search"
            label="Busque por nome"
            id="monitor-users-search"
            defaultValue={textSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTextSearch(e.target.value)
            }
          />
        </SearchBox>

        <Grid>
          {users?.map((user) => (
            <CardCrud
              key={user.id}
              actionEdit={() =>
                openModal(
                  <EditMonitorUser user={user} id={id!} refetch={refetch} />
                )
              }
              actionRemove={() =>
                openModal(
                  <RemoveMonitorUser user={user} id={id!} refetch={refetch} />
                )
              }
            >
              <CardListItem>
                <CardListTitle color="text.secondary">Nome</CardListTitle>
                <CardListContent>{user.name}</CardListContent>
              </CardListItem>
              <CardListItem>
                <CardListTitle>Email</CardListTitle>
                <CardListContent>{user.email}</CardListContent>
              </CardListItem>
              <CardListItem>
                <CardListTitle>Permissão de Acesso</CardListTitle>
                <CardListContent>{USER_ROLES_MAP[user.role]}</CardListContent>
              </CardListItem>
            </CardCrud>
          ))}

          {users?.length === 0 && !isLoading && (
            <EmptyData>
              <img src={IconNoData} alt="No Data" />
              <span>Não foram encontrados usuários para a busca realizada</span>
            </EmptyData>
          )}

          {isLoading && (
            <Loader>
              <CircularProgress />
            </Loader>
          )}
        </Grid>
      </Wrapper>
    </Container>
  )
}
