import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { IrsEntity } from '@services/nomos_api/entities/irs'

export const getIrsByIdAPI = async (id: string): Promise<IrsEntity> => {
  const { data } = await HTTPClient.get(`irs/${id}`)
  return data as IrsEntity
}

export const useGetIrsDetails = (id: string) =>
  useQuery(['get-irs-details'], () => getIrsByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
