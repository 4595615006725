import { BacenDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function BacenDetailsPage() {
  return (
    <SpeechDetailsController>
      <BacenDetailsView />
    </SpeechDetailsController>
  )
}

export default BacenDetailsPage
