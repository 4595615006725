import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { NoteEntity } from '@services/nomos_api/entities/note'

type PresignedUrlNoteProps = Pick<NoteEntity, 'bucket' | 'originId'> & {
  extension: string
}

export type PresignedUrlResponse = {
  url: string
  fields: Record<string, string>
}

export const presignedUrlNoteFileApi = async (
  props: PresignedUrlNoteProps
): Promise<PresignedUrlResponse> => {
  const { data } = await HTTPClient.post(
    `/notes/${props.bucket}/${props.originId}/upload`,
    { extension: props.extension }
  )
  return data
}

export const usePresignedUrlNoteFile = (props: PresignedUrlNoteProps) =>
  useMutation(['update-note'], () => presignedUrlNoteFileApi(props))
