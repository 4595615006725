import styled from 'styled-components'
import theme from '@theme/colors'

export const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 20px 0px 0px;

  svg {
    height: 16px;
    width: 16px;
    color: '#6D7994';
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-top: 24px;
  text-align: center;
  gap: 16px;

  div {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 12px;
    margin-bottom: 8px;

    svg {
      width: 35px;
      height: 35px;
      color: #f03d3d;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background-color: '${theme.brand.offwhite}';
  padding: 20px;
`
