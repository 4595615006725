import { Pagination } from '@services/nomos_api/entities'

export enum BucketsEnum {
  'irs' = 'irs',
  'dou' = 'dou',
  'cvm' = 'cvm',
  'bacen' = 'bacen',
  'events' = 'events',
  'social' = 'social',
  'organs' = 'organs',
  'notices' = 'notices',
  'speeches' = 'speeches',
  'monitors' = 'monitors',
  'propositions' = 'propositions',
  'stakeholders' = 'stakeholders',
  'oficial_diary' = 'oficial_diary',
  'regulatory_agencies' = 'regulatory_agencies',
}

export type Sort = {
  attr: string
  label: string
}

export type Period = {
  range: boolean
  attr: string
  label: string
}

export type KeyValuePair = {
  key: string
  value: string
}

export type FilterOptionsProps = {
  text?: string
  page?: number
  limit?: number
}

export type PaginationKeyValuePair = {
  pagination: Pagination
  results: KeyValuePair[]
}

export type Subtitle = {
  color: string
  label: string
}

export type Filter = {
  attr: string
  label: string
  enabled_input?: boolean
  enabled_pagination?: boolean
  enabled_online_filter?: boolean
  options: (
    props: FilterOptionsProps
  ) => Promise<PaginationKeyValuePair | KeyValuePair[]>
}

export type Mode = 'monitor' | 'favorite'

export type Bucket = {
  key: BucketsEnum
  mode: Mode
  name: string
  sorts: Sort[]
  periods: Period[]
  filters: Filter[]
  subtitles?: Subtitle[]
}
