import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Pagination } from '../../entities/pagination'

import { OrganEntity } from '../../entities/organ'

export type GetAllOrgansResult = {
  pagination: Pagination
  results: OrganEntity[]
}

export type GetAllOrgansFilters = {
  q?: string
  resource?: string[]
  level?: string[]
  power?: string[]
  active?: string
}

export type GetAllOrgansProps = {
  page?: number
  limit?: number
  monitorId?: string
  filters?: GetAllOrgansFilters
}

export const getOrgansAPI = async ({
  page,
  limit,
  monitorId,
  filters,
}: GetAllOrgansProps): Promise<GetAllOrgansResult> => {
  const params = Object.entries(filters || {}).reduce(
    (accumulator, [key, value]) => {
      const stringValue = value?.toString()
      return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
    },
    ''
  )

  const { data } = await HTTPClient.get(
    `organs?page=${page}&limit=${limit}&monitor=${monitorId || ''}&${params}`
  )
  return data as GetAllOrgansResult
}

export const getAllOrgansAPI = async (
  filters: GetAllOrgansFilters
): Promise<OrganEntity[]> => {
  const params = Object.entries(filters || {}).reduce(
    (accumulator, [key, value]) => {
      const stringValue = value?.toString()
      return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
    },
    ''
  )

  const { data } = await HTTPClient.get(`organs?${params}`)
  return (data || []) as OrganEntity[]
}

export const useGetOrgans = (props: GetAllOrgansProps) =>
  useQuery(['get-organs', props], () => getOrgansAPI(props), {
    refetchOnWindowFocus: false,
  })

export const useGetAllOrgans = (filters: GetAllOrgansFilters) =>
  useQuery(['get-all-organs', filters], () => getAllOrgansAPI(filters), {
    refetchOnWindowFocus: false,
  })
