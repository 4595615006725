import { useCallback } from 'react'
import { Header } from './containers'
import { Stack, Box } from '@mui/system'
import { Typography } from '@mui/material'
import { TabPanels } from './containers/TabPanels'
import { Container, Wrapper, Content } from './styles'
import { TabSelector } from './containers/TabSelector'
import { Button, TooltipHelper } from '@components/atoms'
import { useNavigate, useParams } from 'react-router-dom'
import UserGroupIcon from '@assets/icons/user-group.svg'
import { Actions } from './containers/Actions'
import { isMobile } from 'react-device-detect'

export default function MonitorView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const handleMonitorMembers = useCallback(() => {
    navigate(`/monitors/${id}/users`)
  }, [id])

  return (
    <Container>
      <Wrapper direction="column">
        <Header />

        <Stack direction="row" gap={4} flexWrap="wrap" width="100%">
          <TabSelector />

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ flex: 1, width: 100 }}
          >
            {!isMobile && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  id="users-page-link"
                  size="medium"
                  variant="text"
                  text="Membros"
                  sx={{ px: 2 }}
                  onClick={handleMonitorMembers}
                  startIcon={<img src={UserGroupIcon} alt="Membros" />}
                />
                <Box marginLeft={2}>
                  <TooltipHelper>
                    <Typography variant="caption" color="$color-text-primary">
                      Defina os usuários desta organização que poderão
                      visualizar e editar este painel
                    </Typography>
                  </TooltipHelper>
                </Box>
              </Stack>
            )}
            <Actions />
          </Stack>
        </Stack>

        <Content>
          <TabPanels />
        </Content>
      </Wrapper>
    </Container>
  )
}
