import { Bucket, BucketsEnum, FilterOptionsProps } from './type'
import { OrganEntity } from '@services/nomos_api/entities/organ'
import { getOrgansAPI } from '@services/nomos_api/resources/organs/organs'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'
import { searchOptionsAPI } from '@services/nomos_api/resources/search/options'
import { fetchCasaOptions } from '@services/dados_abertos/resources/casa_options'
import { fetchEstadoOptions } from '@services/dados_abertos/resources/estado_options'
import { fetchResultadoOptions } from '@services/dados_abertos/resources/resultado_options'
import { getAllStakeholdersPagination } from '@services/nomos_api/resources/stakeholders/stakeholders'
import { fetchTipoTramitacaoOptions } from '@services/dados_abertos/resources/tipo_tramitacao_options'
import { PropositionProcessingLegend } from '@services/nomos_api/entities/proposition'

const BucketPropositions: Bucket = {
  key: BucketsEnum.propositions,
  name: 'Proposição',
  mode: 'monitor',
  subtitles: [
    {
      color: PropositionProcessingLegend.true.color,
      label: PropositionProcessingLegend.true.text,
    },
    {
      color: PropositionProcessingLegend.false.color,
      label: PropositionProcessingLegend.false.text,
    },
  ],
  sorts: [
    {
      attr: 'recently_updated',
      label: 'Atualizados recentemente',
    },
    {
      attr: 'newest',
      label: 'Criados recentemente',
    },
    {
      attr: 'relevance',
      label: 'Mais relevantes',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'created_at',
      label: 'Período de criação',
    },
    {
      range: true,
      attr: 'updated_at',
      label: 'Período de atualização',
    },
  ],
  filters: [
    {
      attr: 'resources',
      label: 'Casa',
      enabled_input: false,
      enabled_online_filter: false,
      options: fetchCasaOptions,
    },
    {
      attr: 'types',
      label: 'Tipo proposição',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        return searchOptionsAPI('propositions', 'types', [], {
          page,
          limit,
          text,
        })
      },
    },
    // {
    //   attr: 'situations',
    //   label: 'Situação',
    //   enabled_input: true,
    //   enabled_pagination: true,
    //   enabled_online_filter: true,
    //   options: async ({ text, page, limit }: FilterOptionsProps) => {
    //     return searchOptionsAPI('propositions', 'situations', [], {
    //       page,
    //       limit,
    //       text,
    //     })
    //   },
    // },
    {
      attr: 'processing',
      label: 'Status de tramitação',
      enabled_input: false,
      enabled_online_filter: false,
      options: fetchTipoTramitacaoOptions,
    },
    {
      attr: 'regimes',
      label: 'Regime de tramitação',
      enabled_input: false,
      enabled_online_filter: false,
      options: () => searchOptionsAPI('propositions', 'regimes', []),
    },
    {
      attr: 'authors',
      label: 'Autor',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const stakeholders = await getAllStakeholdersPagination({
          page,
          limit,
          filters: { name: text },
        })
        return {
          pagination: stakeholders.pagination,
          results: stakeholders.results.map(
            (stakeholder: StakeholderEntity) => ({
              key: stakeholder.id,
              value: `${capitalizeAllWords(stakeholder.name)} - ${
                stakeholder.title
              }`,
            })
          ),
        }
      },
    },
    {
      attr: 'rapporteurs',
      label: 'Relator',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const stakeholders = await getAllStakeholdersPagination({
          page,
          limit,
          filters: { name: text },
        })
        return {
          pagination: stakeholders.pagination,
          results: stakeholders.results.map(
            (stakeholder: StakeholderEntity) => ({
              key: stakeholder.id,
              value: `${capitalizeAllWords(stakeholder.name)} - ${
                stakeholder.title
              }`,
            })
          ),
        }
      },
    },
    {
      attr: 'parties',
      label: 'Partido',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        return searchOptionsAPI('propositions', 'parties', [], {
          page,
          limit,
          text,
        })
      },
    },
    {
      attr: 'states',
      label: 'UF',
      enabled_input: true,
      enabled_online_filter: false,
      options: fetchEstadoOptions,
    },
    {
      attr: 'approved',
      label: 'Resultado',
      enabled_input: false,
      enabled_online_filter: false,
      options: fetchResultadoOptions,
    },
    // {
    //   attr: 'themes',
    //   label: 'Tema',
    //   enabled_input: true,
    //   enabled_pagination: true,
    //   enabled_online_filter: true,
    //   options: async ({ text, page, limit }: FilterOptionsProps) => {
    //     return searchOptionsAPI('propositions', 'themes', [], {
    //       page,
    //       limit,
    //       text,
    //     })
    //   },
    // },
    {
      attr: 'appreciations',
      label: 'Forma de apreciação',
      enabled_input: false,
      enabled_pagination: false,
      enabled_online_filter: false,
      options: () => searchOptionsAPI('propositions', 'appreciations', []),
    },
    {
      attr: 'organs',
      label: 'Órgãos',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const organs = await getOrgansAPI({ page, limit, filters: { q: text } })
        return {
          pagination: organs.pagination,
          results: organs.results.map((organ: OrganEntity) => ({
            key: organ.id,
            value: capitalizeAllWords(organ.name),
          })),
        }
      },
    },
  ],
}

export default BucketPropositions
