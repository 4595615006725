import { Button } from '@components/atoms'
import { TagManagerContext } from '@containers/TagManager/context'
import { ActionContainer } from '@containers/TagManager/styles'
import { useContextSelector } from 'use-context-selector'

export function TagManagerListActions(): JSX.Element {
  const compact = useContextSelector(TagManagerContext, (s) => s.compact)

  const setViewmode = useContextSelector(
    TagManagerContext,
    (s) => s.setViewmode
  )

  const handleSubmitSelectedTags = useContextSelector(
    TagManagerContext,
    (s) => s.handleSubmitSelectedTags
  )

  return (
    <ActionContainer>
      <Button
        text="Criar nova tag"
        fullWidth
        size={compact ? 'medium' : 'large'}
        variant="outlined"
        onClick={() => setViewmode('editing')}
      />
      <Button
        text="Salvar Alterações"
        fullWidth
        size={compact ? 'medium' : 'large'}
        onClick={handleSubmitSelectedTags}
      />
    </ActionContainer>
  )
}
