import colors from '@theme/colors'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${colors['$color-background-primary']};
  width: 88%;
  margin: 0 auto;
  padding: 0 1rem;
  padding-top: 40px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 29px;
  }

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`
