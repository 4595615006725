import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { MonitorEntity } from '../../entities/monitor'
import { Pagination } from '@services/nomos_api/entities'

export type ListMonitorProps = {
  page?: number
  limit?: number
  roles?: string
  q?: string
  all?: boolean
  type?: string
}

export type ListMonitorResult = {
  results: MonitorEntity[]
  pagination: Pagination
}

export const listMonitorsAPI = async (
  props: ListMonitorProps
): Promise<ListMonitorResult> => {
  const filters = props
    ? Object.entries(props).reduce((accumulator, [key, value]) => {
        const stringValue = value?.toString()
        return stringValue
          ? `${accumulator}&${key}=${stringValue}`
          : accumulator
      }, '')
    : ''

  const endpoint = `monitor?${filters}`
  const { data } = await HTTPClient.get(endpoint)
  return data as ListMonitorResult
}

export const getAllMonitorsAPI = async (
  props: ListMonitorProps
): Promise<MonitorEntity[]> => {
  const filters = Object.entries(props).reduce((accumulator, [key, value]) => {
    const stringValue = value?.toString()
    return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
  }, '')

  const endpoint = `monitor?${filters}`
  const { data } = await HTTPClient.get(endpoint)
  return data as MonitorEntity[]
}

export const useListMonitors = (props: ListMonitorProps) =>
  useQuery(['list-monitors', props], () => listMonitorsAPI(props), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

export const useGetAlltMonitors = (props: ListMonitorProps) =>
  useQuery(['get-all-monitors', props], () => getAllMonitorsAPI(props), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  })

export const useGetAllMonitorsOnDemand = (props: ListMonitorProps) =>
  useQuery(
    ['get-all-monitors-ondemand', props],
    () => getAllMonitorsAPI(props),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      refetchOnMount: false,
    }
  )
