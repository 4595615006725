import styled from 'styled-components'
import { styled as styledMui, Box } from '@mui/material'

export const CardContent = styledMui(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    minWidth: '380px',
  },
}))

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const CardInfo = styled.div`
  padding-left: 24px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const Info = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 32px;
  flex-wrap: wrap;
`
