import { Box } from '@mui/material'
import styled from 'styled-components'

export const BoxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`

export const Indicator = styled.div`
  height: 8px;
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props) => `${props.color}50`};
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: flex;
    background-color: ${(props) => props.color};
  }
`
