import styled from 'styled-components'

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px;
  flex-wrap: wrap;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16px;
`
