import { db } from '../..'
import {
  onChildAdded,
  ref,
  query,
  Unsubscribe,
  orderByChild,
  startAfter,
} from 'firebase/database'
import { AlertEntity } from '@services/realtime_db/entities/alert'
import { AlertEventType } from '@services/realtime_db/types'

export const listenAlertAdded = (
  userId: string,
  callback: (data: AlertEventType) => void
): Unsubscribe => {
  // add filter by orderByChild and startAfter
  // to avoid initial data fetch for old records
  const currentTimestamp = new Date().getTime()
  return onChildAdded(
    query(
      ref(db, `alerts/${userId}`),
      orderByChild('createdAt'),
      startAfter(currentTimestamp)
    ),
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val() as AlertEntity
        callback({ alerts: [data], nextPage: undefined })
      } else {
        callback({ alerts: [], nextPage: undefined })
      }
    }
  )
}
