import { useAuth } from '@contexts/Auth'
import { BlockedFeatureContext } from '../context'
import { useContextSelector } from 'use-context-selector'
import { BlockedFeatureStateMissingRole } from './state-missing-role'
import { BlockedFeatureStateMissingSubscription } from './state-missing-subscription'
import { BlockedFeatureStateUserUnauthenticated } from './state-unauthenticated'

export function BlockedFeatureSelectorMessage(): JSX.Element {
  const { auth, user } = useAuth()
  const requiredRole = useContextSelector(
    BlockedFeatureContext,
    (s) => s.requiredRole
  )

  if (!auth) return <BlockedFeatureStateUserUnauthenticated />

  if (
    user?.company?.subscription &&
    !user?.company?.subscription?.products?.some?.(
      (product) => product.code === requiredRole
    )
  ) {
    return <BlockedFeatureStateMissingSubscription />
  }
  return <BlockedFeatureStateMissingRole />
}
