import { useQuery } from '@tanstack/react-query'

import HTTPClient from '@services/nomos_api'
import { MonitorUsers } from '@services/nomos_api/entities'

type MonitorUserProps = {
  id: string | undefined
  q?: string | undefined
}

export const getMonitorUsersApi = async (
  props: MonitorUserProps
): Promise<MonitorUsers[]> => {
  const searchByName = props.q ? `&q=${props?.q}` : ''

  const data = await HTTPClient.get(`users?monitor=${props?.id}${searchByName}`)

  return (data?.data || []) as MonitorUsers[]
}

export const useGetMonitorUsers = (props: MonitorUserProps) =>
  useQuery(['get-monitor-users', props], () => getMonitorUsersApi(props))
