import { InputRadio } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'

export function MonitorFormPropositionPriority(): JSX.Element {
  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="$font-title-5" color="$color-text-secondary">
            Prioridade
          </Typography>
        </Stack>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Qual a prioridade da proposição monitorada para você.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <InputRadio
          name="priority"
          options={{
            0: 'Alta',
            1: 'Média',
            2: 'Baixa',
          }}
          onChange={(value) => {
            formSavedBucket.setFieldValue('priority', value)
          }}
        />
      </Stack>
    </Stack>
  )
}
