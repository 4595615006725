import styled from 'styled-components'
import { Card } from '@components/atoms'
import { Tooltip as MUITooltip, tooltipClasses } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const CardItemRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
`

export const CardItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SavedSearchCard = styled(Card)`
  display: flex;
  padding: 24px;
  justify-content: space-between;
`

export const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 16px;
  width: fit-content;

  border: 1px solid #4070f4;
  border-radius: 22px;
  color: #4070f4;
  cursor: pointer;
`

export const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: #4070f4;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
`

export const ViewMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto 0;

  svg {
    color: #6d7994;
    cursor: pointer;
  }
`

export const Tooltip = styled(({ ...props }) => (
  <MUITooltip
    placement="top"
    {...props}
    classes={{ popper: props.className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px 12px',
    backgroundColor: '#F9FAFC',
    color: '#6D7994',
    borderRadius: '4px',
    maxWidth: '216px',
    boxShadow: '0px 5px 3.6px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F9FAFC',
  },
}))

export const Footer = styled.div``
