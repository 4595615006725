import React from 'react'
import { Popover } from '@mui/material'
import { parse } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { DateSinglePicker as DatePicker, Label } from './styles'

export function DateSinglePicker({ onChange, date, text }) {
  // <!-./stylesler -->
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleOpen = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? 'daterange-popover' : undefined

  // <!-- defines initial data -->
  const [selection, setSelection] = React.useState(
    date && parse(date, 'yyyy-MM-dd', new Date())
  )

  // <!-- watch selection changed to dispach -->
  React.useEffect(() => {
    if (!selection) {
      handleClose()
    } else if (onChange && selection) {
      handleClose()
      onChange(selection)
    }
  }, [selection])

  return (
    <>
      <Label onClick={handleOpen}>{text}</Label>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
      >
        <DatePicker
          locale={ptBR}
          showPreview={false}
          showDateDisplay={false}
          showSelectionPreview={false}
          showMonthAndYearPickers={false}
          moveRangeOnFirstSelection={false}
          weekdayDisplayFormat="EEEEE"
          onChange={(date) => setSelection(date)}
        />
      </Popover>
    </>
  )
}
