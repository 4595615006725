import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Page } from '@containers/AppBar/AppBar'
import { BoxMobile, LinkNav } from '@containers/AppBar/AppBar.styles'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'

type Props = { pages: Page[] }

export function MobileMenu({ pages }: Props): JSX.Element {
  const [anchorElNav, setAnchorElNav] = useState<Element | null | undefined>(
    null
  )

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <BoxMobile>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon color="primary" />
      </IconButton>

      <Menu
        data-testid="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {pages?.map((page, index) => (
          <MenuItem key={page.path} onClick={handleCloseNavMenu}>
            <LinkNav to={page.path} data-testid={`menu-tab-${index}`}>
              <Typography variant="$font-body-2" color="$color-text-primary">
                {page.name}
              </Typography>
            </LinkNav>
          </MenuItem>
        ))}
      </Menu>
    </BoxMobile>
  )
}
