import { SVGProps } from 'react'

function MonitorProductIcon({
  width = 279,
  height = 279,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 279 279" fill={fill}>
      <rect width="279" height="279" rx="40" fill="#4070F4" />
      <circle cx="67.7097" cy="67.7126" r="17.5544" fill="white" />
      <circle cx="103.272" cy="104.172" r="14.4036" fill="white" />
      <circle cx="174.388" cy="176.19" r="14.4036" fill="white" />
      <circle cx="137.929" cy="138.831" r="17.5544" fill="white" />
      <circle cx="209.226" cy="211.749" r="17.5544" fill="white" />
      <circle cx="209.668" cy="175.635" r="14.4127" fill="white" />
      <circle cx="67.2417" cy="139.281" r="13.5034" fill="white" />
      <circle cx="208.759" cy="139.281" r="13.5034" fill="white" />
      <circle cx="68.132" cy="174.39" r="12.6032" fill="white" />
      <circle
        cx="103.268"
        cy="139.281"
        r="11.7029"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="173.94"
        cy="139.731"
        r="11.2528"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="173.935"
        cy="212.649"
        r="10.3526"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="137.929"
        cy="174.84"
        r="11.2528"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="103.719"
        cy="174.84"
        r="9.45237"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="138.382"
        cy="212.199"
        r="6.30158"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="138.382"
        cy="66.3629"
        r="6.30158"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="103.268"
        cy="212.199"
        r="4.50113"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="174.389"
        cy="66.3628"
        r="4.50113"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="137.929"
        cy="103.722"
        r="11.2528"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="174.391"
        cy="104.172"
        r="9.90248"
        fill="white"
        fillOpacity="0.5"
      />
      <circle
        cx="103.721"
        cy="66.8127"
        r="10.3526"
        fill="white"
        fillOpacity="0.5"
      />
      <circle cx="209.651" cy="103.272" r="12.6032" fill="white" />
      <circle cx="67.6734" cy="211.749" r="10.3526" fill="white" />
      <circle cx="209.191" cy="66.8127" r="10.3526" fill="white" />
      <circle cx="68.151" cy="103.981" r="14.4127" fill="white" />
    </svg>
  )
}
export default MonitorProductIcon
