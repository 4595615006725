import styled from 'styled-components'
import { Box, Typography } from '@mui/material'

export const Wrapper = styled(Box)`
  display: flex;
  height: calc(100% - 48px);
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 16px;
  background-color: #f9fafc;
  flex-shrink: 0;
  & .muidrawer-paper {
    box-sizing: border-box;
  }
`

export const FilterContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
`

export const FilterTitle = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #24365e;
`

export const ScheduleLabelContainer = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
`

export const ScheduleLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ColoredIcon = styled.div`
  width: 15px;
  height: 8px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`
