import { useMutation } from '@tanstack/react-query'
import HTTPClient from '../..'
import { PropositionPositioningKeys } from '@enums/PropositionPositioningEnum'

type Props = {
  tagIds?: string[]
  priority?: number
  positioning?: PropositionPositioningKeys
  savedPropositionId: string
  muteuser?: boolean
  situations?: string[]
  alert?: {
    destinations?: string[]
    frequency?: string
  }
}

export const updateSavedPropositionAPI = async ({
  tagIds,
  priority,
  positioning,
  savedPropositionId,
  muteuser,
  situations,
  alert,
}: Props) => {
  const payload: Pick<
    Props,
    'tagIds' | 'positioning' | 'priority' | 'muteuser' | 'situations' | 'alert'
  > = {}
  if (tagIds) payload.tagIds = tagIds
  if (priority !== null && priority !== undefined) payload.priority = priority
  if (positioning) payload.positioning = positioning
  if (muteuser !== undefined && muteuser !== null) payload.muteuser = muteuser
  if (situations) payload.situations = situations
  if (alert) payload.alert = alert
  const endpoint = `saved_propositions/${savedPropositionId}`
  const response = await HTTPClient.put(endpoint, payload)
  return response.data
}

export const useUpdateSavedProposition = () =>
  useMutation(['update-saved-proposition'], updateSavedPropositionAPI)
