import { createRef, useEffect } from 'react'
import { Wrapper } from './SizedComponent.styles'

type OnSizeChangeProps = { width: number; height: number }

type Props = {
  fullwidth?: boolean
  children: JSX.Element
  onChange: (props: OnSizeChangeProps) => void
}

export default function SizedComponent({
  fullwidth = false,
  children,
  onChange,
}: Props): JSX.Element {
  const ref = createRef<HTMLDivElement>()
  const resizeObserver = new ResizeObserver(([element]) => {
    if (element) {
      onChange({
        width: element.contentRect.width,
        height: element.contentRect.height,
      })
    }
  })

  useEffect(() => {
    if (!ref.current) return () => undefined
    resizeObserver.observe(ref.current)
    return () => {
      if (ref.current) resizeObserver.unobserve(ref.current)
    }
  }, [ref])

  if (fullwidth === true) {
    return (
      <Wrapper ref={ref} id="sized-component" className="sized-component-class">
        {children}
      </Wrapper>
    )
  }
  return (
    <div ref={ref} id="sized-component" className="sized-component-class">
      {children}
    </div>
  )
}
