/* eslint-disable no-nested-ternary */
/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable no-unused-vars */
import { ptBR } from 'date-fns/locale'
import { ViewMore } from './components/ViewMore'
import { EventsCalendarContext } from './context'
import { HeaderSelect } from './components/Header'
import { getDate } from '@utils/functions/dates/dates'
import { DateSinglePicker } from './components/DateSinglePicker'
import { useCallback, useState, useEffect, useContext } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

import {
  isSameDay,
  addDays,
  subDays,
  format,
  differenceInSeconds,
  getMinutes,
  isSameHour,
} from 'date-fns'

import {
  Container,
  Header,
  Picker,
  Content,
  CalendarDateTopDisplay,
  CalendarDateTopDatesDisplay,
  CalendarDateCenterDisplay,
  CalendarDateLeftDisplay,
  CalendarDateContent,
  CalendarDateDay,
  CalendarSingleDateItem,
} from './styles'

function EventsCalendarDay(props) {
  const { data, headerLocation, handleUpdateSearch } = props

  const [selectedEvents, setSelectedEvents] = useState([])
  const [selectedPopover, setSelectedPopover] = useState({
    date: null,
    anchorEl: null,
  })

  const [period, setPeriod] = useState({
    start: getDate(new Date()),
    end: null,
  })

  const { handleOpenModalDetailsEvent } = useContext(EventsCalendarContext)

  const handleOpenEvent = useCallback(
    (id) => {
      const selectedEvent = data.find((event) => event.id === id)

      handleOpenModalDetailsEvent(selectedEvent.event)
    },
    [data]
  )

  const formattedData = data.sort((a, b) => a.start - b.start)

  const handleNextPeriod = useCallback(() => {
    const start = addDays(period.start, 1)
    setPeriod({
      start,
      end: null,
    })
  }, [period])

  const handlePreviousPeriod = useCallback(() => {
    const start = subDays(period.start, 1)
    setPeriod({
      start,
      end: null,
    })
  }, [period])

  const handleChangePeriod = useCallback((date) => {
    if (!date) return

    setPeriod({
      start: date,
      end: null,
    })
  }, [])

  const handlePopoverOpen = (event, date, data) => {
    setSelectedPopover({
      date,
      anchorEl: event.target,
    })

    setSelectedEvents(data)
  }

  const handlePopoverClose = () => {
    setSelectedPopover({
      date: null,
      anchorEl: null,
    })
  }
  useEffect(() => {
    handleUpdateSearch({
      [`buckets.events.limit`]: 100,
      [`buckets.events.filter.date.from`]: format(period.start, 'yyyy-MM-dd'),
      [`buckets.events.filter.date.to`]: format(period.start, 'yyyy-MM-dd'),
    })
  }, [period])

  const grid = 1
  const squareSize = 700
  const hours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ]

  const rows = hours.length

  return (
    <Container>
      <Header location={headerLocation}>
        <Picker>
          <KeyboardArrowLeft onClick={handlePreviousPeriod} />

          <DateSinglePicker
            text={format(period.start, 'dd MMMM yyyy', { locale: ptBR })}
            onChange={handleChangePeriod}
          />
          <KeyboardArrowRight onClick={handleNextPeriod} />
        </Picker>

        <HeaderSelect />
      </Header>

      <Content>
        <CalendarDateTopDisplay grid={7}>
          {Array.from(Array(1)).map((_, index) => (
            <CalendarDateTopDatesDisplay
              key={index}
              current={isSameDay(addDays(period.start, index), new Date())}
            >
              <span>
                {format(addDays(period.start, index), 'EEE', {
                  locale: ptBR,
                }).slice(0, 3)}
                .
              </span>

              <span style={{ padding: '4px' }}>
                {format(addDays(period.start, index), 'dd')}
              </span>
            </CalendarDateTopDatesDisplay>
          ))}
        </CalendarDateTopDisplay>

        <CalendarDateCenterDisplay>
          <CalendarDateLeftDisplay>
            {hours.map((item, index) => (
              <div key={index}>
                <span>{item}</span>
              </div>
            ))}
          </CalendarDateLeftDisplay>

          <CalendarDateContent grid={grid}>
            {Array.from(Array(rows * grid)).map((_, index) => (
              <CalendarDateDay key={index} size={squareSize} />
            ))}

            {formattedData.map((item, index) => {
              const diff = differenceInSeconds(item.start, getDate(item.start)) + 3600 * 3
            
              const secondsPerSquare = 3600
              const topCalc = (diff / secondsPerSquare) * 40
              const widthSquare = 98 * 7
              const heightSquare = 37

              const minutes = getMinutes(item.start)

              const formattedMinutes = heightSquare * (minutes / 60)

              const filteredData = formattedData.filter((data) =>
                isSameHour(item.start, data.start)
              )

              const quantity = filteredData.length

              const size = formattedData.length

              const prev = index > 0 ? formattedData[index - 1] : undefined
              const next = index < size ? formattedData[index + 1] : undefined

              const isPrevious =
                quantity !== 1 && !isSameHour(item.start, prev?.start)

              const isMiddle =
                quantity !== 1 &&
                isSameHour(item.start, prev?.start) &&
                isSameHour(item.start, next?.start)

              const hasMoreThan3 = !isMiddle && quantity >= 3 && !isPrevious

              const leftCalc =
                quantity === 1 || isPrevious
                  ? 0
                  : quantity === 2 && !isPrevious
                    ? widthSquare / 2
                    : quantity >= 3 && isMiddle
                      ? widthSquare / 3
                      : quantity >= 3 && !isPrevious
                        ? widthSquare - widthSquare / 3
                        : 0

              if (hasMoreThan3) {
                return (
                  <CalendarSingleDateItem
                    style={{
                      top: topCalc,
                      left: leftCalc,
                      zIndex: index,
                      alignItems: 'center',
                    }}
                    key={index}
                    width={widthSquare / 3}
                    background={item.background}
                    borderColor={item.borderColor}
                    height={heightSquare - formattedMinutes}
                    onClick={(e) =>
                      handlePopoverOpen(e, item.start, filteredData)
                    }
                  >
                    + {quantity - 2}
                  </CalendarSingleDateItem>
                )
              }

              return (
                <CalendarSingleDateItem
                  style={{
                    top: topCalc,
                    left: leftCalc,
                    zIndex: index,
                  }}
                  background={item.background}
                  borderColor={item.borderColor}
                  width={widthSquare - leftCalc}
                  height={heightSquare - formattedMinutes}
                  onClick={() => handleOpenEvent(item.id)}
                >
                  <span>{item.title}</span>
                </CalendarSingleDateItem>
              )
            })}
          </CalendarDateContent>
        </CalendarDateCenterDisplay>
      </Content>

      <ViewMore
        open={!!selectedPopover?.anchorEl}
        onClose={() => handlePopoverClose()}
        data={selectedEvents}
        anchorEl={selectedPopover?.anchorEl}
      />
    </Container>
  )
}

export default EventsCalendarDay
