import AccordionSummary from '@mui/material/AccordionSummary'

import {
  styled,
  Box,
  Grid,
  Stack,
  Accordion as MUIAccordion,
} from '@mui/material'

export const Container = styled(Stack)(({ theme }) => ({
  height: 'auto',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '32px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '32px 48px 32px 48px',
  },
}))

export const ScrollGuests = styled(Grid)`
  height: 100%;
  width: 100%;
  max-height: 32vh;
  padding: 0px 0px 16px;
  overflow-x: none;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4a8eff20;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #4a8eff50;
  }
`

export const Accordion = styled(MUIAccordion)`
  border: 0px;
  background-color: #f9fafc;
  box-shadow: none;
  &:before {
    background-color: transparent !important;
  }
`

export const AccordionHeader = styled(AccordionSummary)`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  svg {
    color: #4070f4;
  }
`

export const EmptyData = styled(Box)`
  gap: 8px;
  width: 100%;
  display: flex;
  color: #9ea5b8;
  margin-top: -8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
