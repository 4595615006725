import HTTPClient from '../..'
import { monitorEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'

export const saveEventAPI = async (body) => {
  monitorEvent('Event')
  const { data } = await HTTPClient.post('/events/save', body)
  return data
}

export const useSaveEvent = () => useMutation(['save-event'], saveEventAPI)
