import { useMemo } from 'react'
import { useAuth } from '@contexts/Auth'
import { isMobile } from 'react-device-detect'
import { EmailConfirmationContext } from './context'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { useActivateUserAccount } from '@services/nomos_api/resources/user/activate'

export type EmailConfirmationProps = {
  children: React.ReactNode
}

export function EmailConfirmationController({
  children,
}: EmailConfirmationProps) {
  const { token } = useParams()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  const { data, isFetching, isError, error } = useActivateUserAccount(
    token || ''
  )

  const axiosError = error as AxiosErrorResponse

  if (!isFetching && data) {
    isAuthenticated(data?.user, data.jwt)
    navigate(isMobile ? 'search-results' : '/home')
  }

  const store = useMemo(
    () => ({
      isError: isError && axiosError?.response?.status !== 401,
      isLoading: isFetching,
      response: axiosError?.response?.data,
    }),
    [axiosError, isError, isFetching]
  )

  return (
    <EmailConfirmationContext.Provider value={store}>
      {children}
    </EmailConfirmationContext.Provider>
  )
}
