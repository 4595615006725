import { SVGProps } from 'react'

function HouseIcon({
  width = 21,
  height = 21,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59049 7.73046V14.3971M8.34049 7.73046V14.3971M12.5072 7.73046V14.3971M16.2572 7.73046V14.3971M2.92383 15.7305L2.92383 16.3971C2.92383 16.8638 2.92383 17.0972 3.01466 17.2755C3.09455 17.4323 3.22203 17.5597 3.37884 17.6396C3.5571 17.7305 3.79045 17.7305 4.25716 17.7305H16.5905C17.0572 17.7305 17.2906 17.7305 17.4688 17.6396C17.6256 17.5597 17.7531 17.4323 17.833 17.2755C17.9238 17.0972 17.9238 16.8638 17.9238 16.3971V15.7305C17.9238 15.2638 17.9238 15.0304 17.833 14.8521C17.7531 14.6953 17.6256 14.5679 17.4688 14.488C17.2906 14.3971 17.0572 14.3971 16.5905 14.3971H4.25716C3.79045 14.3971 3.5571 14.3971 3.37884 14.488C3.22203 14.5679 3.09455 14.6953 3.01466 14.8521C2.92383 15.0304 2.92383 15.2638 2.92383 15.7305ZM10.1346 2.79474L3.96792 4.16511C3.59537 4.2479 3.40909 4.28929 3.27005 4.38947C3.1474 4.47783 3.0511 4.59788 2.99145 4.73677C2.92383 4.89424 2.92383 5.08506 2.92383 5.46669L2.92383 6.39713C2.92383 6.86384 2.92383 7.0972 3.01466 7.27546C3.09455 7.43226 3.22203 7.55974 3.37884 7.63964C3.5571 7.73046 3.79045 7.73046 4.25716 7.73046H16.5905C17.0572 7.73046 17.2906 7.73046 17.4688 7.63964C17.6256 7.55974 17.7531 7.43226 17.833 7.27546C17.9238 7.0972 17.9238 6.86384 17.9238 6.39713V5.46669C17.9238 5.08506 17.9238 4.89424 17.8562 4.73677C17.7966 4.59788 17.7003 4.47783 17.5776 4.38947C17.4386 4.2893 17.2523 4.2479 16.8797 4.16511L10.7131 2.79474C10.6051 2.77075 10.5512 2.75876 10.4966 2.75398C10.4482 2.74973 10.3995 2.74973 10.351 2.75398C10.2965 2.75876 10.2425 2.77075 10.1346 2.79474Z"
        stroke="#4070F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HouseIcon
