import HTTPClient from '../..'
import { searchEvent } from '@utils/metrics'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'

export const searchSpeechesAPI = async (props) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=${props.limit || 10}`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(
    `/search/speeches?${params.join('&')}`,
    {...props.filter, keywords: removeEmptyArrays(props.keywords) }
  )
  return data
}

export const useSearchSpeeches = () =>
  useMutation(['search-speeches'], searchSpeechesAPI)

export const useGetSpeechesByAuthorId = (filter, props) =>
  useInfiniteQuery(['get-speechs-author', filter, props], ({ pageParam = 1 }) => searchSpeechesAPI(
    { filter, page: pageParam, ...props }
  ), 
  {
    refetchOnWindowFocus: false,
    getNextPageParam: ({ pagination }) => {
      if (
        pagination.total - pagination.limit > 0 &&
        pagination.page < pagination.total
      ) {
        return pagination.page + 1
      }

      return undefined
    }
  })
