import { createContext } from 'use-context-selector'
import { Member } from '@services/nomos_api/entities/user'

type UserDetailContextType = {
  isLoading: boolean
  user?: Member
}

export const UserDetailContext = createContext<UserDetailContextType>(
  {} as UserDetailContextType
)
