import { TextLink, TextWrap } from '@components/atoms'
import { Tooltip, Typography } from '@mui/material'
import { formatDate } from '@utils/functions/formatter/formatter'
import { getGridStringOperators, GridCellParams } from '@mui/x-data-grid'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

export const columns = [
  {
    field: 'title',
    headerName: 'Proposição',
    width: 160,
    sortable: false,
    filterOperators,
    renderCell: (params: GridCellParams) => {
      return (
        <Tooltip title={params.row.summary}>
          <span>
            <TextLink
              text={params.value}
              href={`/propositions/${params.rowNode.id}`}
            />
          </span>
        </Tooltip>
      )
    },
  },
  {
    field: 'summary',
    headerName: 'Ementa',
    width: 300,
    sortable: false,
    filterOperators,
    renderCell: (params: GridCellParams) => {
      return (
        <TextWrap lines={2}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            {params.value}
          </Typography>
        </TextWrap>
      )
    },
  },
  {
    field: 'authors',
    headerName: 'Autor',
    width: 200,
    sortable: false,
    filterOperators,
    renderCell: (params: GridCellParams) => {
      const stakeholder =
        params.value && params.value.length > 0
          ? params.value[0]
          : { name: 'N/A' }
      return (
        <TextLink
          text={stakeholder.name}
          href={`/stakeholders/${stakeholder.stakeholderId}`}
        />
      )
    },
  },
  {
    field: 'lastProceeding',
    headerName: 'Situação',
    width: 300,
    sortable: false,
    filterOperators,
    renderCell: (params: GridCellParams) => {
      return (
        <Typography>
          {params.value.situation || params.value.description}
        </Typography>
      )
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Ultima atualização',
    width: 160,
    sortable: false,
    filterOperators,
    renderCell: (params: GridCellParams) => {
      return (
        <Typography>{formatDate(params.row.lastProceeding.date)}</Typography>
      )
    },
  },
]
