import { Box, Stack, styled } from '@mui/material'

export const Container = styled((props) => {
  return <Stack maxWidth={640} spacing={3} direction="column" {...props} />
})``

export const Header = styled((props) => {
  return (
    <Stack
      px={4}
      pt={4}
      spacing={6}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    />
  )
})``

export const Content = styled(Box)`
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
`
