import Tooltip from '@mui/material/Tooltip'
import { TextLink } from '@components/atoms'
import { useSnackbar } from '@contexts/Snackbar'

type PropositionCellLinkProps = {
  url: string
  value: string
  enabledLink: boolean
  tooltipValue?: string
}

export function PropositionCellLink({
  url,
  value,
  enabledLink,
  tooltipValue,
}: PropositionCellLinkProps): JSX.Element {
  const { showSnackbarError } = useSnackbar()
  return (
    <Tooltip title={tooltipValue || value || ''}>
      <span>
        <TextLink
          maxlines={2}
          text={value}
          underline="none"
          href={enabledLink ? url : undefined}
          onClick={() => {
            if (!enabledLink) {
              showSnackbarError(
                'Informação sem link de referência',
                'No momento não é possível direcioná-lo para a página dessa informação.',
                { position: 'center' }
              )
            }
          }}
        />
      </span>
    </Tooltip>
  )
}
