/* eslint-disable react/jsx-no-useless-fragment */
import { Button, ContainerScroll } from '@components/atoms'
import { IconButton, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { useDrawer } from '@contexts/Drawer'
import { MonitoringContext } from '../../context'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useCallback, useState } from 'react'
import SaveInMonitor from '../tab-save-in-monitor'
import {
  Container,
  ContentBody,
  Header,
  IconBack,
  IconClose,
} from '../form-proposition-status-notification/styles'
import { MonitoringTab } from '../../types'

export function PropositionsAlert(): JSX.Element {
  const { cleanStack } = useDrawer()
  const ids = useContextSelector(MonitoringContext, (s) => s.id)?.split(',')
  const title = useContextSelector(MonitoringContext, (s) => s.title)?.split(
    ','
  )
  const [step, setStep] = useState(MonitoringTab.alerta)
  const getNames = () => {
    const comps = new Array<JSX.Element>()
    title!.forEach((t) => {
      comps.push(<Typography key={t}>{t}</Typography>)
    })
    return comps
  }
  const handleNextStep = useCallback(async () => {
    switch (step) {
      case MonitoringTab.alerta:
        setStep(MonitoringTab.monitor)
        break
      default:
        break
    }
  }, [setStep, step])
  const handleLastStep = useCallback(async () => {
    switch (step) {
      case MonitoringTab.monitor:
        setStep(MonitoringTab.alerta)
        break
      default:
        break
    }
  }, [setStep, step])

  switch (step) {
    case MonitoringTab.alerta:
      return (
        <Container>
          <Header>
            {step !== MonitoringTab.alerta && (
              <IconButton onClick={handleLastStep} size="small">
                <IconBack />
              </IconButton>
            )}
            <Typography variant="$font-title-3" color="$color-text-primary">
              Opções de Monitoramento
            </Typography>
            <IconButton onClick={cleanStack}>
              <IconClose />
            </IconButton>
          </Header>
          <ContentBody>
            <Stack
              flex={1}
              spacing={3}
              width="100%"
              direction="column"
              overflow="hidden"
            >
              <ContainerScroll auto="true">
                <Stack
                  sx={{
                    backgroundColor: '$color-semantic-warninglight',
                    gap: '8px',
                    padding: '10px',
                  }}
                >
                  <Stack direction="row" gap="8px">
                    <InfoOutlinedIcon
                      sx={{
                        color: '$color-semantic-warning',
                      }}
                    />
                    <Typography variant="$font-title-4">Atenção</Typography>
                  </Stack>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    As opções de monitoramento serão aplicadas para todos os
                    dados selecionados. Deseja prosseguir com esta ação?
                  </Typography>
                </Stack>
                <Stack gap="16px">
                  <Typography variant="$font-title-5">
                    {ids.length} Proposições selecionadas
                  </Typography>
                  {ids.length > 0 && getNames()}
                </Stack>
              </ContainerScroll>

              <Stack direction="row" gap="16px">
                <Button
                  text="Sair"
                  size="large"
                  fullWidth
                  variant="outlined"
                  id="go-to-next-step"
                  onClick={cleanStack}
                />
                <Button
                  size="large"
                  fullWidth
                  text="Prosseguir"
                  data-cy="btn-save-bucket-on-monitor"
                  onClick={handleNextStep}
                />
              </Stack>
            </Stack>
          </ContentBody>
        </Container>
      )

    case MonitoringTab.monitor:
      return (
        <Container>
          <Header>
            <IconButton onClick={handleLastStep} size="small">
              <IconBack />
            </IconButton>
            <Typography variant="$font-title-3" color="$color-text-primary">
              Opções de Monitoramento
            </Typography>
            <IconButton onClick={cleanStack}>
              <IconClose />
            </IconButton>
          </Header>
          <ContentBody>
            <SaveInMonitor />
          </ContentBody>
        </Container>
      )

    default:
      return <></>
  }
}
