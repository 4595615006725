import { styled as styledMaterial } from '@mui/material/styles'
import { Box } from '@mui/material'

export const Container = styledMaterial(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '36px',
}))

export const ContentPaths = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '10px',
  a: {
    textDecoration: 'none',
  },
}))

export const TextLabel = styledMaterial(Box)(() => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#6D7994',
}))

export const TextDescription = styledMaterial(Box)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#2D3A40',
}))
