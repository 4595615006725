import { useMemo } from 'react'
import { TabSelector } from '@components/organisms'
import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'

export default function Tabs() {
  const handleChangeTab = useContextSelector(
    StakeholderDetailContext,
    (s) => s.handleChangeTab
  )

  const tabs = useMemo(
    () => [
      { label: 'Atividades', value: 'activities', cy: 'activities-tab' },
      { label: 'Orgãos', value: 'organs', cy: 'organs-tab' },
      { label: 'Rede social', value: 'social', cy: 'social-tab' },
      { label: 'Discursos', value: 'speeches', cy: 'speeches-tab' },
    ],
    []
  )

  return (
    <TabSelector
      tabs={tabs}
      initialTab="activities"
      onChangeTab={handleChangeTab}
    />
  )
}
