import styled from 'styled-components'
import {
  Box,
  Typography,
  List,
  FormControlLabel,
  ListItemText,
  Stack,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled as styledMaterial } from '@mui/material/styles'
import theme from '@theme/colors'

export const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2d3a40;
`

export const IconClose = styled(Close)`
  font-size: 24px;
  color: #6d787d;
`

export const TextLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#2d3a40',

  '&.MuiTypography-root': {
    marginBottom: '8px',
  },
})

export const TextDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#9D9E9F',
})

export const ContentAction = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 31px;
`

export const DrawerBottom = styledMaterial(Stack)`
  width: 100%;
`

export const CheckListWrapper = styledMaterial(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}))

export const ListScroller = styledMaterial(List)(() => ({
  maxHeight: '180px',
  overflow: 'auto',
  '&.MuiButtonBase-root-MuiCheckbox-root': {
    borderRadius: '4px',
    marginLeft: '2px',
    width: '16px',
    height: '16px',
    border: '1px solid #B6BCC9',
    backgroundColor: 'transparent',
  },
}))

export const UserLabel = styledMaterial(ListItemText)(() => ({
  color: theme['$color-text-primary'],
  fontWeight: '400',
  fontSize: '14px',
}))

export const InputCheckboxLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 17px !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    white-space: nowrap !important;
    color: '#B6BCC9',
    borderRadius: 4px;
    border: 1px solid #B6BCC9;
  }
`
export const BpIcon = styledMaterial('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  border: '1px solid #B6BCC9',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}))
export const BpCheckedIcon = styledMaterial(BpIcon)({
  border: '1px solid #B6BCC9',
  backgroundColor: '#4070F4',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4070F4',
  },
})
