import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
