import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { TabContent, TabContentInner } from '../../styles'
import { TramitationsDetailed } from '../TramitationsDetailed'
import { PropositionDetailsContext } from '../../context'

export function PanelProceedings({ visible = false }) {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )
  return (
    <Grid container spacing={2} sx={{ display: visible ? 'flex' : 'none' }}>
      <Grid item xs={12}>
        <TabContent>
          <TabContentInner>
            <Stack direction="column" spacing={2}>
              <Typography variant="$font-title-3" color="$color-text-primary">
                Detalhes Tramitação
              </Typography>
              <Typography variant="$font-body-base" color="$color-text-primary">
                {`Acompanhe as tramitações que ocorreram na ${proposition?.title}`}
              </Typography>
              <Stack direction="column" spacing={1}>
                <TramitationsDetailed />
              </Stack>
            </Stack>
          </TabContentInner>
        </TabContent>
      </Grid>
    </Grid>
  )
}
