import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { SpeechDetailsContext } from './context'
import { useGetSpeechDetails } from '@services/nomos_api/resources/speech/getById'

type SpeechDetailsControllerProps = {
  children: React.ReactNode
}

export function SpeechDetailsController({
  children,
}: SpeechDetailsControllerProps) {
  const { id } = useParams()
  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const { data: speech, isLoading } = useGetSpeechDetails(id || '')

  const state = useMemo(
    () => ({
      isLoading,
      keyword,
      buckets,
      speech,
    }),
    [isLoading, keyword, buckets, speech]
  )

  return (
    <SpeechDetailsContext.Provider value={state}>
      {children}
    </SpeechDetailsContext.Provider>
  )
}
