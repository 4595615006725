import { useMemo } from 'react'

export const useSearchResultsUrl = (url: string) => {
  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const targetUrl = useMemo(() => {
    return `${url}?keyword=${keyword}&buckets=${buckets}`
  }, [url, keyword, buckets])

  return { url: targetUrl }
}
