import { Stack } from '@mui/material'
import { Wrapper, Container } from './styles'
import { HeaderComponent, ContentTextComponent } from './containers'

export function RegulatoryAgencyDetailsView() {
  return (
    <Wrapper>
      <Container className="print-content-box">
        <Stack direction="column" spacing={4}>
          <HeaderComponent />
          <ContentTextComponent />
        </Stack>
      </Container>
    </Wrapper>
  )
}
