import { SVGProps } from 'react'

function DatabaseIcon({
  width = 24,
  height = 24,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
    >
      <path
        d="M4 18V6M20 6V18"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 12C20 14.21 16.418 16 12 16C7.582 16 4 14.21 4 12M20 18C20 20.21 16.418 22 12 22C7.582 22 4 20.21 4 18M12 10C16.418 10 20 8.21 20 6C20 3.79 16.418 2 12 2C7.582 2 4 3.79 4 6C4 8.21 7.582 10 12 10Z"
        stroke="#202122"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default DatabaseIcon
