/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components'
import { Popover as MUIPopover } from '@mui/material'
import { styled as styledMui } from '@mui/material/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  heigth: 100%;
  margin-bottom: 46px;
`

export const Header = styled.div`
  display: flex;
  grid: 100px;
  margin-bottom: 38px;
  margin-top: 32px;
  gap: 16px;
  flex-wrap: wrap;
  
  @media (min-width:768px){
    margin-top: -24px;
    gap: ${props => props.location ? '60px' : '0px'};
    justify-content: ${props => props.location || 'space-between'};
  }
`

export const Picker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
  cursor: pointer;

  svg {
    size: 32px;
    color: #4070F4;
    cursor: pointer;
  }

  span {
    font-weight: 400;
    font-size: 18px;

    color: #3c4c70;
    text-transform: capitalize;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: #6d7994;
`

export const CalendarDateTopDisplay = styled.div`
  ${({ grid }) => css`
    display: grid;
    grid-template-columns: repeat(${grid}, 1fr);
    margin-left: 76px;
    padding-bottom: 8px;
  `}
`

export const CalendarDateTopDatesDisplay = styled.div`
  ${({ current = false }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    text-transform: uppercase;

    span:nth-child(1) {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }

    span:nth-child(2) {
      color: ${current ? 'white' : '#4070F4'};
      background-color: ${current ? '#4070F4' : 'white'};
      text-align: center;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      font-weight: 600;
      font-size: 20px;
    }
  `}
`

export const CalendarDateCenterDisplay = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #b2bbbf;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`

export const CalendarDateLeftDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 75px;
  height: 100%;

  > div {
    padding: 0 16px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    outline: 1px solid transparent;
  }
`

export const CalendarDateContent = styled.div`
  ${({ grid }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${grid}, 1fr);
    position: relative;
  `}
`

export const CalendarDateSquare = styled.div`
  ${({ size = 100 }) => css`
    height: ${size ? `${size}px;` : '100px;'}
    outline: 1px solid #e7e9ed;
    display: flex;
    justify-content: center;
  `}
`

export const CalendarDateSquareItem = styled.div`
    width: ${(props) => props.width || 90}px;
    height: ${(props) => props.height || 90}px;

    margin: 0px 6px;
    margin-top: 4px;
    background-color: ${(props) => props.background || '#0A2156'};
    border-left: 6px solid ${(props) => props.borderColor || '#4A8EFF'};
    border-radius: 2px;

    display: flex;
    justify-content: center;
    position: absolute;
    color: white;
    
    cursor: pointer;

    -webkit-box-shadow: inset -2px 2px 8px 5px rgba(214,214,214,0.22);
    -moz-box-shadow: inset -2px 2px 8px 5px rgba(214,214,214,0.22);
    box-shadow: inset -2px 2px 8px 5px rgba(214,214,214,0.22);

    span {
      height: fit-content;
      padding: 2px 6px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
`

// Day

export const CalendarDateDay = styled.div`
  ${({ size = 100 }) => css`

    width: ${size ? `${size}px;` : '100px;'}
    height: 40px;
    outline: 1px solid #e7e9ed;

    display: flex;
    justify-content: center;
  `}
`

// Month

export const CalendarDateSquareHeader = styled.div`
  ${({ currentMonth = true, currentDay = false }) => css`
    color: ${currentMonth ? `#4070F4;` : '#858FA6;'};
    height: 32px;
    width: 100%;
    text-align: center;
    padding-top: 12px;

    span {
      color: ${!currentMonth ? '#858FA6;' : currentDay ? 'white' : '#4070F4'};
      background-color: ${currentDay ? '#4070F4' : 'white'};
      text-align: center;
      width: 24px;
      height: 24px;
      border-radius: 32px;
      padding: 6px;
    }
  `}
`

export const CalendarDateSquareMonth = styled(CalendarDateSquare)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CalendarDateSquareList = styled.div`
  padding: 0px 8px;
`

export const CalendarDateSquareListItem = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0a2156;
  font-weight: 400;
  cursor: pointer;
  width: 100px;
  font-size: 13px;
  padding: 2px 0;
`

export const CalendarSingleDateItem = styled(CalendarDateSquareItem)`
  border-left-width: 12px;
  justify-content: start;
  align-items: center;
  background-color: ${(props) => props.background || '#0A2156'};
  border-left: 6px solid ${(props) => props.borderColor || '#4A8EFF'};
  
  span {
    padding-left: 8px;
  }
`

// Popover more itens

export const Popover = styledMui((props) => <MUIPopover {...props} />)({
  '& .MuiPopover-paper': {
    boxShadow:
      '0px 2px 12px 2px rgba(0, 0, 0, 0.04), 0px 8px 16px 2px rgba(11, 31, 77, 0.06) !important',
  },
})

export const ModalContent = styled.div`
  width: 250px;
  height: 230px;
  background-color: white;

  border-radius: 4px;

  span {
    color: #3c4c70;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  > div:nth-child(1) {
    display: flex;
    justify-content: end;
    background-color: #f9fafc;
    padding-right: 12px;
    border-radius: 4px 4px 0 0;
  }

  > div {
    gap: 2px !important;
  }

  svg {
    cursor: pointer;
    color: #6d7994;
  }
`

export const ModalCalendarDateTopDatesDisplay = styled(
  CalendarDateTopDatesDisplay
)`
  display: flex;
  height: 74px;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #e7e9ed;

  span:nth-child(1) {
    margin-top: 2px;
    color: #9ea5b8;
    font-size: 16px;
  }

  span:nth-child(2) {
    height: 100%;
    width: 100%;
    font-size: 36px;
  }
`

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 12px 0;

  height: 95px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #b2bbbf;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`

export const ModalMainItem = styled.div`
  display: flex;

  width: 220px;
  margin-bottom: 4px;
  cursor: pointer;
  padding: 1px 0;

  &:hover {
    background-color: #f1f4f5;
  }

  span {
    padding: 0 4px;
    width: 100%;
    display: block;
    white-space: normal;
    text-overflow: ellipsis;
    color: #0a2156;
    font-size: 13px;
  }
`
