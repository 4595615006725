import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  gap: 24px;
  height: 100%;
  display: flex;
  overflow: scroll;
  margin-top: 24px;
  position: relative;
  flex-direction: column;
`

export const MessagesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
