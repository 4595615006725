import Overview from './containers/Overview'
import { DashboardWrapper, DashboardHero, InputWrapper } from './styles'
import { BucketHandler, InputKeyword, KeywordResults } from './containers/index'

export function HomeView() {
  return (
    <DashboardWrapper data-testid="home-page">
      <DashboardHero>
        <InputWrapper>
          <InputKeyword />
          <BucketHandler />
        </InputWrapper>
        <KeywordResults />
      </DashboardHero>
      <Overview />
    </DashboardWrapper>
  )
}
