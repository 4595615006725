import { Container } from './styles'
import Filters from './containers/Filters'
import ListUsers from './containers/ListUsers'
import { CrumbedPage } from '@components/templates'
import { SeatManager } from './containers/SeatManager'
import { SeatAlerts } from './containers/SeatAlerts'

export function UsersView() {
  return (
    <CrumbedPage
      id="users-page"
      data-cy="users-page"
      title="Usuários"
      description="Gerencie os usuários que possuem acesso ao Nomos"
      paths={[
        {
          name: 'Início',
          location: '/',
        },
        {
          name: 'Usuários',
          location: '/users',
        },
      ]}
    >
      <Container>
        <SeatAlerts />
        <SeatManager />
        <Filters />
        <ListUsers />
      </Container>
    </CrumbedPage>
  )
}
