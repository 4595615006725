import { parseISO } from 'date-fns'
import { Button, TextLink } from '@components/atoms'
import { useState, useCallback, useMemo } from 'react'
import { HouseInfo, Status } from '@components/molecules'
import { IconButton, List, Typography, Stack } from '@mui/material'
import { OpenDataResourceEnum } from '@enums/OpenDataResourceEnum'
import { capitalize } from '@utils/functions/capitalize/capitalize'
import { icsCalendar } from '@utils/functions/calendar/icsCalendar'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import {
  formatDate,
  formatTime,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'

import {
  Close,
  RoomOutlined,
  CalendarTodayRounded,
  AccessTimeOutlined,
  AttachFile,
  ExpandMore,
  LaunchOutlined,
  PeopleAlt,
  StickyNote2Outlined,
} from '@mui/icons-material'

import {
  ModalContainer,
  ModalToolbar,
  ModalContent,
  Title,
  Separator,
  ContentIconText,
  TextLabel,
  TextDescription,
  TextParagraph,
  TextChip,
  ContainerRow,
  ContentRow,
  ContentAccordion,
  ModalAccordion,
  ModalAccordionSummary,
  ModalAccordionDetails,
  FilterListVirtualized,
  FilterListVirtualizedItem,
  ContentColumn,
  ModalFooter,
  TextFooter,
  HighlightedSchedule,
} from './styles'
import _ from 'lodash'
import { EventModalProps } from './types'
import { useModal } from '@contexts/Modal'
import { SavedPropositionScheduled } from './components/SavedPropositionScheduled'
import { useContextSelector } from 'use-context-selector'
import { EventModalContext } from './context'
import { EventScheduleMatch } from '@containers/Modals/EventScheduleMatch'

export function EventModalView({ event }: EventModalProps) {
  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword')
  const buckets = query.get('buckets')
  const { openModal, cleanStack } = useModal()

  const params = `?buckets=${getSortedBucket(
    buckets || '',
    'events'
  )}&keyword=${keyword}`

  const countSavedPropositions = useContextSelector(
    EventModalContext,
    (s) => s.countSavedPropositions
  )

  const [expanded, setExpanded] = useState<string | boolean>(false)

  const handleChangeAccordion = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleOpenEvent = () => {
    window.open(`/events/${event?.id}${params}`, '_blank')
  }

  const handleGetCalendar = useCallback(() => {
    icsCalendar({
      startDate: parseISO(event.dateStart),
      endDate: parseISO(event.dateEnd),
      summary: event.type,
      description: event.description,
    })
  }, [event])

  const date = formatDate(event.dateStart)

  const weekday = !event.dateStart
    ? '--'
    : parseISO(event.dateStart).toLocaleDateString('pt-BR', {
        weekday: 'long',
      })

  const hour = formatTime(event.dateStart)

  const rulingLink =
    event?.openDataResource === 'camara'
      ? `https://www.camara.leg.br/evento-legislativo/${event?.openDataId}`
      : `https://www25.senado.leg.br/web/atividade/sessao-plenaria/-/pauta/${event?.openDataId}`

  const description = useMemo(() => {
    return formatterHighlight(event, ['description'], 'Não Informado')
  }, [])

  const hasFoundedInSchedule = useMemo(() => {
    return (event.highlights || []).some((i) => i.path.startsWith('schedule'))
  }, [])

  return (
    <ModalContainer>
      <ModalToolbar>
        <IconButton onClick={() => handleOpenEvent()}>
          <LaunchOutlined sx={{ color: '#6D7994', fontSize: '16px' }} />
        </IconButton>

        <IconButton onClick={() => cleanStack()}>
          <Close sx={{ color: '#6D7994', fontSize: '16px' }} />
        </IconButton>
      </ModalToolbar>

      <ModalContent>
        <ContentColumn>
          <Title>
            {[
              event?.organs?.[0]?.name,
              event?.type ||
                _.get(
                  OpenDataResourceEnum,
                  event?.openDataResource,
                  'Não informado'
                ),
            ]
              .filter((i) => !!i)
              .join(' - ')}
          </Title>
        </ContentColumn>

        <Separator />

        <Stack px={2}>
          <HouseInfo text={event.openDataResource} />
        </Stack>

        {countSavedPropositions > 0 && (
          <Status
            text={`${countSavedPropositions} proposições favoritadas estão em pauta na agenda`}
            color="#0BB07B"
            sx={{ padding: '0 20px', marginTop: '8px' }}
          />
        )}
        <ContentColumn>
          <ContentIconText>
            <RoomOutlined sx={{ color: '#556282', fontSize: '14px' }} />

            <TextDescription>
              {event.local?.name || 'Não Informado'}
            </TextDescription>
          </ContentIconText>
        </ContentColumn>

        <ContainerRow>
          <ContentRow>
            <ContentIconText>
              <CalendarTodayRounded
                sx={{ color: '#556282', fontSize: '14px' }}
              />

              <TextDescription>
                {capitalize(weekday)} - {date}
              </TextDescription>
            </ContentIconText>

            <ContentIconText>
              <AccessTimeOutlined sx={{ color: '#556282', fontSize: '14px' }} />

              <TextDescription>{hour}</TextDescription>
            </ContentIconText>
          </ContentRow>

          {event?.situation?.length && (
            <TextChip
              variant="outlined"
              label={event.situation}
              color="primary"
            />
          )}
        </ContainerRow>

        {(event?.parliamentarians || []).length > 0 && (
          <ContentAccordion expanded={expanded === 'guests'}>
            <ModalAccordion
              expanded={expanded === 'guests'}
              onChange={(_, expanded) =>
                handleChangeAccordion('guests', expanded)
              }
            >
              <ModalAccordionSummary number expandIcon={<ExpandMore />}>
                <ContentIconText>
                  <PeopleAlt
                    sx={{
                      color: '#556282',
                      fontSize: '14px',
                    }}
                  />

                  <TextDescription>
                    {event?.parliamentarians?.length} Convidados
                  </TextDescription>
                </ContentIconText>
              </ModalAccordionSummary>

              <ModalAccordionDetails>
                <FilterListVirtualized
                  itemData={event.parliamentarians}
                  innerElementType={List}
                  itemCount={event?.parliamentarians?.length || 0}
                  itemSize={30}
                  height={120}
                  width={460}
                >
                  {({ data, index, style }) => (
                    <TextDescription style={style}>
                      {_.get(data, `${index}.name`)}
                    </TextDescription>
                  )}
                </FilterListVirtualized>
              </ModalAccordionDetails>
            </ModalAccordion>
          </ContentAccordion>
        )}

        <ContentColumn>
          <TextLabel>Tema:</TextLabel>
          <TextParagraph>{description}</TextParagraph>
        </ContentColumn>

        {hasFoundedInSchedule && (
          <ContentColumn>
            <HighlightedSchedule>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Palavra-chave encontrada nos itens da pauta.{' '}
                <Button
                  size="tiny"
                  variant="text"
                  text="visualizar"
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    openModal(<EventScheduleMatch event={event} />)
                  }}
                />
              </Typography>
            </HighlightedSchedule>
          </ContentColumn>
        )}

        {(event?.schedule || []).length > 0 && (
          <ContentAccordion expanded={expanded === 'propositions'}>
            <ModalAccordion
              expanded={expanded === 'propositions'}
              onChange={(_, expanded) =>
                handleChangeAccordion('propositions', expanded)
              }
            >
              <ModalAccordionSummary number expandIcon={<ExpandMore />}>
                <ContentIconText>
                  <StickyNote2Outlined
                    sx={{
                      color: '#556282',
                      fontSize: '14px',
                    }}
                  />

                  <TextDescription>Proposições em pauta</TextDescription>
                </ContentIconText>
              </ModalAccordionSummary>

              {event?.schedule?.length && (
                <ModalAccordionDetails>
                  <FilterListVirtualized
                    itemData={event?.schedule}
                    innerElementType={List}
                    itemCount={event?.schedule.length}
                    itemSize={30}
                    height={120}
                    width={460}
                  >
                    {({ data, index, style }) => (
                      <FilterListVirtualizedItem customStyle={style}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {_.get(data, `${index}.propositionId`) ? (
                            <TextLink
                              text={_.get(data, `${index}.title`)}
                              href={`/propositions/${_.get(
                                data,
                                `${index}.propositionId`
                              )}`}
                            />
                          ) : (
                            <Typography
                              variant="$font-text-link"
                              color="$color-text-secondary"
                            >
                              {_.get(data, `${index}.title`)}
                            </Typography>
                          )}

                          {_.get(data, `${index}.relatedTitle`) && (
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <Typography
                                variant="$font-text-link"
                                color="$color-text-secondary"
                              >
                                apensada a:
                              </Typography>
                              {_.get(data, `${index}.relatedPropositionId`) ? (
                                <TextLink
                                  target="_blank"
                                  text={_.get(data, `${index}.relatedTitle`)}
                                  href={`/propositions/${_.get(
                                    data,
                                    `${index}.relatedPropositionId`
                                  )}`}
                                />
                              ) : (
                                <Typography
                                  variant="$font-text-link"
                                  color="$color-text-secondary"
                                >
                                  {_.get(data, `${index}.relatedTitle`)}
                                </Typography>
                              )}
                            </Stack>
                          )}
                          <SavedPropositionScheduled
                            event={event}
                            data={data}
                            index={index}
                          />
                        </Stack>
                      </FilterListVirtualizedItem>
                    )}
                  </FilterListVirtualized>
                </ModalAccordionDetails>
              )}
            </ModalAccordion>
          </ContentAccordion>
        )}

        <ContentColumn>
          <ContentIconText>
            <AttachFile
              sx={{
                color: '#556282',
                fontSize: '14px',
                transform: 'rotate(40deg)',
              }}
            />

            <TextDescription>Anexos:</TextDescription>
          </ContentIconText>

          <TextLink text="Pauta" href={rulingLink} target="_blank" />
        </ContentColumn>
      </ModalContent>

      <ModalFooter>
        <TextFooter onClick={handleGetCalendar}>
          Salvar evento em minha agenda pessoal
        </TextFooter>
      </ModalFooter>
    </ModalContainer>
  )
}
