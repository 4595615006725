import { SignUpContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { FormSignUpEmail } from '../FormSignupEmail/FormSignupEmail'
import { SignInPrompt } from '../SigninPrompt/SigninPrompt'
import LogoNomosArkoSvg from '../../../../../assets/logos/logo-nomos-arko.svg'
import {
  SignUpFormContainer,
  SignUpFormContainerPadding,
  SignUpFormSpacer,
  SignUpFormWrapper,
  SignUpMainForm,
  SignUpWrapper,
} from '../../styles'
import { EmailConfirmation } from '../Confirmation'
import { Presentation } from '@components/organisms/Presentation/Presentation'

export function SignupFormSelector(): JSX.Element | null {
  const step = useContextSelector(SignUpContext, (s) => s.step)
  if (step === 'confirmation') return <EmailConfirmation />

  if (step === 'registration_step_1')
    return (
      <SignUpWrapper>
        <Presentation />
        <SignUpMainForm>
          <SignUpFormSpacer />
          <SignUpFormWrapper>
            <img
              height={54}
              src={LogoNomosArkoSvg}
              alt="Logotipo Nomos com slogan Arko"
            />
            <SignUpFormContainer>
              <SignUpFormContainerPadding>
                <FormSignUpEmail />
              </SignUpFormContainerPadding>
            </SignUpFormContainer>
          </SignUpFormWrapper>
          <SignInPrompt />
          <SignUpFormSpacer />
        </SignUpMainForm>
      </SignUpWrapper>
    )
  return null
}
