import { Typography } from '@mui/material'
import EmptySVG from '@assets/icons/empty.svg'
import { Container } from './EmptyState.styles'

export type EmptyStateProps = {
  text: string
  'data-cy'?: string
  iconHeight?: number
  spacing?: number
}

function EmptyState({
  text,
  'data-cy': dataCy,
  iconHeight = 128,
  spacing = 1,
}: EmptyStateProps) {
  const iconWidth = (160 * iconHeight) / 128
  return (
    <Container
      data-cy={dataCy || 'empty-state'}
      alignItems="center"
      justifyContent="center"
      spacing={spacing}
    >
      <img
        src={EmptySVG}
        alt="Sem dados"
        width={iconWidth}
        height={iconHeight}
      />
      <Typography variant="$font-body-1" color="$color-text-tertiary">
        {text}
      </Typography>
    </Container>
  )
}

export default EmptyState
