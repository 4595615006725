import HTTPClient from '@services/nomos_api'
import { MonitorUsers } from '@services/nomos_api/entities'
import { MonitorUserPayload } from '@services/nomos_api/entities/monitor'

export const putMonitorUsersApi = async (
  data: MonitorUserPayload[],
  id: string
) => {
  const response = await HTTPClient.put(`monitor/${id}/users`, data)

  return (response?.data || []) as MonitorUsers[]
}
