import { CardProps } from '@mui/material'
import { CardWrapper } from './Card.styles'

export interface CardWrapperProps extends CardProps {
  type?: string
  width?: number | string
  height?: number | string
}

function Card(props: CardWrapperProps) {
  return <CardWrapper {...props} />
}

Card.defaultProps = {
  type: null,
  width: 'auto',
  height: 'auto',
}

export default Card
