import { Grid } from '@mui/material'
import { useEffect, useRef } from 'react'
import { lightenColor } from '@utils/functions/colors/colors'
import { ColorItem, HiddenInput, Touchable } from './InputColorPicker.styles'

type InputColorPickerProps = {
  name: string
  value?: string
  lighten: number
  options?: string[]
  onChange?: (value: string | undefined) => void
}

export const DefaultInputColorPickerProps: string[] = [
  '#4562E5',
  '#E5BB45',
  '#41D99A',
  '#E5455E',
  '#9A45E5',
  '#E55CC3',
  '#D97757',
  '#5F9AA8',
  '#082B5B',
  '#B28D53',
  '#52081E',
  '#757575',
]

function InputColorPicker({
  name,
  value,
  lighten,
  onChange,
  options = DefaultInputColorPickerProps,
}: InputColorPickerProps): JSX.Element {
  const optionsRef = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    optionsRef.current = optionsRef.current.slice(0, options?.length)
  }, [options])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const index in optionsRef.current) {
      if (optionsRef.current[index].value === value) {
        optionsRef.current[index].checked = true
      }
    }
  }, [value])

  return (
    <Grid container spacing={2}>
      {options?.map((option, i) => {
        const key = `form-${name}-${option}`
        return (
          <Grid key={key} item xs={2}>
            <Touchable>
              <HiddenInput
                id={key}
                name={name}
                type="radio"
                value={option}
                ref={(el) => {
                  optionsRef.current[i] = el as HTMLInputElement
                }}
                onChange={(e) => onChange?.(e.target.value)}
              />
              <ColorItem color={lightenColor(option, lighten)} selected />
            </Touchable>
          </Grid>
        )
      })}
    </Grid>
  )
}

InputColorPicker.defaultProps = {
  value: undefined,
  onChange: undefined,
  options: DefaultInputColorPickerProps,
}

export default InputColorPicker
