import { Input } from '@components/atoms'
import { useState, ChangeEvent } from 'react'
import { useDebounce } from '@hooks/useDebounce'
import { MonitorCompany } from '@services/nomos_api/entities'
import { Alert, Box, Checkbox, LinearProgress, ListItem } from '@mui/material'
import { useGetCompanylUsers } from '@services/nomos_api/resources/monitor_users/get-company-users'

import {
  UsersListWrapper,
  ListScroller,
  UserLabel,
  BpIcon,
  BpCheckedIcon,
} from './styles'

export function UsersList({
  handleCheck,
}: {
  handleCheck: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const [textSearch, setTextSearch] = useState('')
  const debounceTextSearch = useDebounce(textSearch, 500)

  const { data: companyUsers, isLoading } = useGetCompanylUsers({
    q: debounceTextSearch,
  })

  return (
    <UsersListWrapper>
      {isLoading && <LinearProgress />}

      <Box>
        <Input
          fullWidth
          size="tiny"
          type="search"
          label="Pesquisar"
          value={textSearch}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTextSearch(e.target.value)
          }
        />

        {companyUsers?.length === 0 ? (
          <Box marginTop={2}>
            <Alert severity="warning">Nenhum resultado</Alert>
          </Box>
        ) : (
          <ListScroller>
            {companyUsers?.map((user: MonitorCompany) => (
              <ListItem key={user.id} disablePadding>
                <Checkbox
                  sx={{
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  name={user.name}
                  value={user.id}
                  onChange={handleCheck}
                />

                <UserLabel id={user.name} primary={user.name} />
              </ListItem>
            ))}
          </ListScroller>
        )}
      </Box>
    </UsersListWrapper>
  )
}
