import { createContext } from 'use-context-selector'
import { FeedbackProps } from '@components/organisms/Cards/Feedback/Feedback'

type FeedbackContextType = {
  data: FeedbackProps
  type?: string
}

export const FeedbackContext = createContext<FeedbackContextType>(
  {} as FeedbackContextType
)
