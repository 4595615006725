import React from 'react'
import { uid } from 'uid/single'
import { theme } from '@theme/index'
import { Button } from '@components/atoms'
import { isMobile } from 'react-device-detect'
import { Description } from '@components/molecules'
import { Chip, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'
import { formatDateTime } from '@utils/functions/formatter/formatter'

import {
  TimelineItem,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
} from '@mui/lab'

import { TimelineTramitation } from './styles'

export function TramitationsDetailed() {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  const proceedings = React.useMemo(() => {
    if (!proposition || !proposition.proceedings) return []
    return (proposition.proceedings || []).slice(0).reverse()
  }, [proposition])

  return (
    <TimelineTramitation>
      {(proceedings || []).map((proceeding, i) => (
        <TimelineItem key={`proceeding-${uid()}`}>
          <TimelineSeparator>
            <TimelineDot variant="filled" color="$color-background-dark" sx={{ backgroundColor: theme.palette['$color-background-dark']}} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
              <Stack direction={{xs:"column-reverse", md: "row"}} spacing={2} alignItems='flex-start'>
                <Typography variant="$font-title-5" color="$color-text-primary">
                  {formatDateTime(proceeding?.date)}
                </Typography>
                {i === 0 && (
                  <Chip
                    label={
                      <Typography variant="caption" color="$color-text-primary">
                        Ultima ação legislativa
                      </Typography>
                    }
                    size="small"
                  />
                )}
              </Stack>
              <Stack direction="row">
                <Description label="Local:" value={proceeding?.organ?.initials || '--'} />
              </Stack>
              <Stack direction={{xs:"column", md:"row"}} alignItems={{xs:"flex-start" , md:"flex-end"}} spacing={1}>
                <Typography variant="$font-body-base" color="$color-text-primary">
                  {proceeding?.dispatch || proceeding?.description || '--'}
                </Typography>
                {proceeding?.url && !isMobile && (
                  <Button
                    variant="text"
                    size="tiny"
                    sx={{p:0, whiteSpace: "nowrap"}}
                    text="Inteiro Teor"
                    href={proceeding?.url}
                    target="_blank"
                  />
                )}
              </Stack>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineTramitation>
  )
}
