import { EventDetailedEntity } from '@entities/EventDetailedEntity'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getEventById = async (id: string) => {
  const { data } = await HTTPClient.get<EventDetailedEntity>(`events/${id}`)
  return data
}

export const useGetEventDetails = (id: string) =>
  useQuery(['get-event-details'], () => getEventById(id))
