import React from 'react'
import { Input } from '@components/atoms'
import { EmptyContent } from '../EmptyContent'
import { useDebounce } from '@hooks/useDebounce'
import { CollapseTableRow } from '../CollapseTableRow'
import { PropositionDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { formatDateTime } from '@utils/functions/formatter/formatter'
import { TabContent, TabContentInner, NomosTable } from '../../styles'

import {
  Grid,
  Stack,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton
} from '@mui/material'

export function PanelAttacheds({ visible = false }) {
  const detectMob = () => window.innerWidth <= 800;

  const isLoadingRelateds = useContextSelector(
    PropositionDetailsContext,
    (s) => s.isLoadingRelateds
  )

  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  const attacheds = useContextSelector(
    PropositionDetailsContext,
    (s) => s.attacheds
  )

  const handleLoadAttacheds = useContextSelector(
    PropositionDetailsContext,
    (s) => s.handleLoadAttacheds
  )

  React.useEffect(() => {
    if(proposition) {
      handleLoadAttacheds()    
    }
  }, [proposition])

  const [value, setValue] = React.useState('')
  const debounceTextSearch = useDebounce(value, 500)

  const records = React.useMemo(() => {
    if (!debounceTextSearch) return attacheds
    const txtSearch = String(debounceTextSearch).toLowerCase()
    return attacheds.filter(record => {
      return (
        String(record?.title).toLowerCase().includes(txtSearch) ||
        String(record?.authors?.[0]?.name).toLowerCase().includes(txtSearch) ||
        String(record?.authors?.[0]?.acronymParty).toLowerCase().includes(txtSearch) ||
        String(record?.authors?.[0]?.uf).toLowerCase().includes(txtSearch) ||
        String(record?.lastProceeding?.organ?.initials).toLowerCase().includes(txtSearch) ||
        String(formatDateTime(record?.datePresentation)).toLowerCase().includes(txtSearch) ||
        String(record?.summary).toLowerCase().includes(txtSearch)
      )
    })
  }, [attacheds, debounceTextSearch])

  return (
    <Grid container spacing={2} sx={{ display: visible ? 'flex' : 'none' }}>
      <Grid item xs={12}>
        <TabContent>
          <TabContentInner>
          <Stack spacing={3} direction="column">
              <Stack
                spacing={2}
                direction={{xs:'column', md: 'row'}}
                alignItems={{xs:'start', md: 'center'}}
                justifyContent="space-between"
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="$font-title-3" color="$color-text-primary">
                    Proposições Apensadas
                  </Typography>
                  <Typography variant="$font-body-base" color="$color-text-primary">
                    {`Veja as proposições apensadas à ${proposition?.title}`}
                  </Typography>
                </Stack>
                <Input
                  size="small"
                  type="search"
                  sx={{ maxWidth: {md: 300} }}
                  label="Digite as palavras que deseja buscar"
                  defaultValue={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Stack>

              {detectMob() ?  
                 (records || []).map((related) => (
                  <CardInfosProposition
                    key={`related-proposition-${related.openDataId}`}
                    proposition={related}
                  />
                )) :
                <NomosTable loading={isLoadingRelateds}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Proposição</TableCell>
                      <TableCell>Orgão</TableCell>
                      <TableCell>Autor</TableCell>
                      <TableCell>Apresentação</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(records || []).map((attached) => (
                      <CollapseTableRow
                        key={`attached-proposition-${attached.openDataId}`}
                        proposition={attached}
                      />
                    ))}
                  </TableBody>
                </NomosTable>
              }
              
              {(!isLoadingRelateds && (records || []).length === 0) && (
                <EmptyContent
                  message={`Não há proposições apensadas à ${proposition?.title}`}
                />
              )}
              {(!!isLoadingRelateds) && (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
                  <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
                  <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
                  <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
                  <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
                </Stack>
              )}


            </Stack>
          </TabContentInner>
        </TabContent>
      </Grid>
    </Grid>
  )
}
