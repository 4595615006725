import HTTPClient from '../..'

type Props = {
  monitorId: string
  id: string
  bucket: string
}

export const deleteSavedSearchesBucketAPI = async ({
  monitorId,
  id,
  bucket,
}: Props) => {
  const response = await HTTPClient.delete(
    `/monitor/${monitorId}/searches/${id}/buckets/${bucket}`
  )

  return response.data
}
