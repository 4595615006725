import { Bucket, BucketsEnum } from './type'

const BucketCvm: Bucket = {
  key: BucketsEnum.cvm,
  name: 'CVM',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'type',
      label: 'Tipo de documento',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [
        { key: 'Resoluções', value: 'Resoluções' },
        { key: 'Instruções', value: 'Instruções' },
        { key: 'Deliberações', value: 'Deliberações' },
        { key: 'Ofícios Circulares', value: 'Ofícios Circulares' },
        { key: 'Notas Explicativas', value: 'Notas Explicativas' },
        { key: 'Pareceres de Orientação', value: 'Pareceres de Orientação' },
        { key: 'Decisões Conjuntas', value: 'Decisões Conjuntas' },
        { key: 'Leis e Decretos', value: 'Leis e Decretos' },
      ],
    },
  ],
}

export default BucketCvm
