import { NoticeDetailsView } from './view'
import { NoticeDetailsController } from './controller'

function NoticeDetailsPage() {
  return (
    <NoticeDetailsController>
      <NoticeDetailsView />
    </NoticeDetailsController>
  )
}

export default NoticeDetailsPage
