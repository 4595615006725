/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
export function removeEmptyArrays(obj: any) {
  const newObj: any = {}

  for (const key in obj) {
    if (
      !Array.isArray(obj[key]) ||
      (Array.isArray(obj[key]) && obj[key].length > 0)
    ) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}
