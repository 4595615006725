import HTTPClient from '../..'
import { signupEvent } from '@utils/metrics'
import { UserCreation } from '@services/nomos_api/entities/user'
import { SignUpEmailResponseType } from '@services/nomos_api/types/signup_email_response_type'

export const signup = async (user: UserCreation) => {
  const { data } = await HTTPClient.post<SignUpEmailResponseType>(
    `registration`,
    user
  )
  signupEvent(user)
  return data
}
