import { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'
import { TabSelector } from '@components/organisms'

export function TabSelectorComponent() {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )
  const selectedTab = useContextSelector(
    PropositionDetailsContext,
    (s) => s.selectedTab
  )

  const handleChangeTab = useContextSelector(
    PropositionDetailsContext,
    (s) => s.handleChangeTab
  )

  
  const tabs = useMemo(() => {
    const list = []
    list.push({"label": "Dados Gerais", "value": "general", cy: "tab-general"})
    list.push({"label": "Relatores", "value": "relators", cy: "tab-relators"})
    list.push({"label": "Relacionadas", "value": "relateds", cy: "tab-relateds"})
    list.push({"label": "Detalhes da Tramitação", "value": "tramitations", cy: "tab-tramitations"})
    list.push({"label": "Apensadas", "value": "attacheds", cy: "tab-attacheds"})
    if((proposition?.attachments || []).length > 0) {
      list.push({"label": "Anexos", "value": "attachments", cy: "tab-attachments"})
    }
    list.push({"label": "Votações", "value": "votings", cy: "tab-votings"})
    return list
  }, [proposition?.attachments])


  return (
    <TabSelector
      initialTab={selectedTab}
      onChangeTab={(tab) => handleChangeTab(tab)}
      tabs={tabs}
    />
  )
}
