import { useMemo } from 'react'
import { EventModalContext } from './context'
import { EventModalControllerProps } from './types'

export function EventModalController({
  children,
  event,
}: EventModalControllerProps): JSX.Element {
  const { savedPropositions } = event

  const countSavedPropositions = useMemo((): number => {
    return (savedPropositions || []).reduce<Map<string, number>>(
      (counter, item) => {
        counter.set(
          item.propositionId,
          (counter.get(item.propositionId) || 0) + 1
        )
        return counter
      },
      new Map<string, number>()
    ).size
  }, [savedPropositions])

  const state = useMemo(
    () => ({ countSavedPropositions }),
    [countSavedPropositions]
  )

  return (
    <EventModalContext.Provider value={state}>
      {children}
    </EventModalContext.Provider>
  )
}
