import { Bucket, BucketsEnum } from './type'

const BucketMonitors: Bucket = {
  key: BucketsEnum.monitors,
  name: 'Painéis',
  mode: 'monitor',
  sorts: [],
  periods: [],
  filters: [],
}

export default BucketMonitors
