/* eslint-disable react/jsx-no-useless-fragment */
import _ from 'lodash'
import { useMemo } from 'react'
import { getBucketName } from '@utils/buckets'
import { Alert, Box, Stack, Typography } from '@mui/material'
import { SavedSearchBucketsEntity } from '@services/nomos_api/entities/saved_searches'

type Props = {
  allBuckets: SavedSearchBucketsEntity
  allSelectedBuckets: string[]
}

export function FormNotificationBucketWarning({
  allBuckets,
  allSelectedBuckets,
}: Props) {
  // check if all selected buckets
  // has at least one filter applied
  const unfilteredBuckets = useMemo(() => {
    return Object.keys(allBuckets).reduce<string[]>(
      (aggregation: string[], current: string) => {
        if (
          allSelectedBuckets.includes(current) &&
          Object.keys(_.get(allBuckets, `${current}.filter`, {})).length === 0
        ) {
          aggregation.push(getBucketName(current))
        }
        return aggregation
      },
      []
    )
  }, [allSelectedBuckets, allBuckets])

  // return nothing if all selected
  // buckets has filters applied
  if (unfilteredBuckets.length === 0) return <></>

  // return warning if some of selected buckets
  // does not has at least one filter applied
  return (
    <Box mb={2}>
      <Alert severity="warning" data-testid="warn-alert">
        <Stack direction="column">
          <Typography variant="$font-title-5" color="$color-text-primary">
            Atenção
          </Typography>
          <Stack direction="column" spacing={1}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              As categorias selecionadas &quot;
              <b>{unfilteredBuckets.join(', ')}</b>&quot; não possuem nenhum
              filtro relacionado. Isso pode gerar uma quantidade excessiva de
              notificações.
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              É indicado que retorne a tela de pesquisa e atribua filtros para
              estas categorias.
            </Typography>
          </Stack>
        </Stack>
      </Alert>
    </Box>
  )
}
