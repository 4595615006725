import { createContext } from 'use-context-selector'

import {
  GetAllUsersFilters,
  GetAllUsersResult,
} from '@services/nomos_api/resources/users/read'
import { SeatCountType } from '@services/nomos_api/entities/seat_count'

type UsersContextType = {
  users?: GetAllUsersResult
  seats: SeatCountType | undefined
  isLoading: boolean
  loadingSeats: boolean
  handleClickNewUser: () => void
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleDelete: (ids: string[]) => void
  handleFilterChange: (filters: GetAllUsersFilters) => void
}

export const UsersContext = createContext<UsersContextType>(
  {} as UsersContextType
)
