import colors from '../../../theme/colors'
import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/system'
import { Box } from '@mui/material'

import CircleGraphismPatternSvg from '../../../assets/patterns/circle-grafirsm.svg'
import SignInAppUsageSvg from '../../../assets/illustrations/signin-app-usage.svg'

export const SignInPresenterWrapper = muiStyled((props) => (
  <Box {...props} sx={{ display: { xs: 'none', md: 'flex' } }} />
))`
  flex: 1;
  z-index: 1;
  height: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors['$color-background-dark']};
  max-width: 540px;
  min-width: 400px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-image: url('${CircleGraphismPatternSvg}');
  padding-left: 30px;
  margin-left: 50px;
`

export const SignInPresenterDescContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-top: 64px;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 32px;
`

export const SignInPresenterDescItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SignInPresenterDescItem = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const SignInPresenterHilight = styled.span`
  background-color: ${colors['$color-action-background-primary-default']};
`

export const SignInPresenterIllustration = styled.div`
  width: 130%;
  height: 100%;
  max-height: 374px;
  background-size: contain;
  background-image: url('${SignInAppUsageSvg}');
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-left: -100px;
`
