import { styled as styledMaterial } from '@mui/material/styles'
import { Box } from '@mui/material'

export const ContentPaths = styledMaterial(Box)(() => ({
  gap: '10px',
  display: 'flex',
  marginBottom: '10px',
  alignItems: 'center',
  a: { textDecoration: 'none' },
}))
