export enum SavedOrganUpdatesEnum {
  // 'start_of_meetings' = 'Início de reuniões',
  // 'updated_meeting_agenda' = 'Atualização na pauta de reuniões',
  'member_movement' = 'Movimentação de membros',
  'new_scheduled_events' = 'Novos eventos agendados',
  'new_documents_attached' = 'Novos documentos anexados',
  'updated_proposition_proceeding' = 'Entrada de proposições',
}

export type SavedOrganUpdatesKeys = keyof typeof SavedOrganUpdatesEnum
