/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import { useDrawer } from '@contexts/Drawer'
import { useSnackbar } from '@contexts/Snackbar'
import { EditSavedSearchContext } from './context'
import React, { useState, useMemo, useCallback } from 'react'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { patchMonitorSearchAPI } from '@services/nomos_api/resources/monitor_search/patch'
import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'

const validationSchemaPainelName = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Deve ter no mínimo 3 caracteres')
    .required('Nome da pesquisa obrigatória'),
  alert: Yup.object({
    destinations: Yup.array()
      .min(1, 'Selecione ao menos um canal de notificação')
      .required('Selecione ao menos um canal de notificação'),
    frequency: Yup.string().required('A seleção da frequência é obrigatória'),
  }),
})

export type EditSavedSearchControllerProps = {
  search: SavedSearchEntity
  onSuccess: (response: AxiosResponse) => void
  children: React.ReactChild
}

export function EditSavedSearchController({
  search: savedSearch,
  children,
  onSuccess,
}: EditSavedSearchControllerProps) {
  useByPassSubscription({
    featureTitle: 'Editar pesquisa',
    featureSubtitle: `Altere informações relevantes para a sua pesquisa`,
    featureDescription: `Altere o nome da pesquisa e defina quando e como você quer ser alertado sobre atualizações.`,
    requiredRole: NomosProductCodeEnum.MONITOR_PRO,
  })

  const { closeDrawer } = useDrawer()
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const [step, setStep] = useState('settings')
  const [isLoading, setLoading] = useState(false)

  const newSavedSearch = {
    ...savedSearch,
    keywordMode: false,
    sensitiveMode: false,
  }

  newSavedSearch.modes?.forEach((mode) => {
    if (mode === 'keyword') {
      newSavedSearch.keywordMode = true
    }

    if (mode === 'sensitive') {
      newSavedSearch.sensitiveMode = true
    }
  })

  const [search] = useState(newSavedSearch)

  const handleUpdateStep = useCallback((step) => {
    setStep(step)
  }, [])

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      ...search,
      buckets: search.searches.reduce((agg, { bucket, alert }) => {
        if (alert.frequency !== 'muted') agg.push(bucket)
        return agg
      }, [] as string[]),
      muteuser: search.muteuser || false,
    },
    validationSchema: validationSchemaPainelName,
    onSubmit: async ({
      id,
      name,
      alert,
      buckets,
      searches,
      muteuser,
      monitorId,
    }) => {
      setLoading(true)

      // create default payload
      const payload = {
        id,
        name,
        alert,
        muteuser,
        monitorId,
        buckets: searches?.reduce((agg, { bucket, filter }) => {
          const alert = (buckets || []).includes(bucket)
          _.set(agg, bucket, { alert, filter })
          return agg
        }, {} as Record<string, { alert: boolean; filter: Record<string, unknown> }>),
      }

      try {
        const response = await patchMonitorSearchAPI(payload)
        showSnackbarSuccess('Pesquisa atualizada com sucesso')
        closeDrawer()
        if (onSuccess) onSuccess(response)
      } catch (error: any) {
        showSnackbarError(
          error?.response?.data?.message ||
            'Não foi possível atualizar a pesquisa'
        )
      } finally {
        setLoading(false)
        setTimeout(closeSnackbar, 3000)
      }
    },
  })

  const handleSubmit = React.useCallback(() => {
    form.handleSubmit()
  }, [form])

  const store = useMemo(
    () => ({
      form,
      step,
      search,
      isLoading,
      handleSubmit,
      handleUpdateStep,
    }),
    [form, step, search, isLoading, handleSubmit, handleUpdateStep]
  )

  return (
    <EditSavedSearchContext.Provider value={store}>
      {children}
    </EditSavedSearchContext.Provider>
  )
}
