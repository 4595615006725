import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '36px',
}))

export const LeftSide = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}))

export const RightSide = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gap: '24px',
}))
