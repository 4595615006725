import styled from 'styled-components'
import { Box, Typography } from '@mui/material'

export const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`
export const FilterTitle = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #24365e;
`
