import { styled, Box } from '@mui/material'

export const BackgroundIcon = styled(Box)(
  ({ backgroundColor }: { backgroundColor?: string }) => ({
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: backgroundColor || '#D6EDF9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
)
