/* eslint-disable no-unused-vars */
import EventsCalendarController from './controller'
import { EventsCalendarView } from './view'

export type Event = {
  dateStart: string
  dateEnd: string | null
  description: string
  id: string
  situation: string
  type: string
  organs: {
    initials: string
  }[]
}

type EventsCalendarProps = {
  events: Event[]
  headerLocation: string
  handleUpdateSearch: (data: Record<string, string>) => void
}

export function EventsCalendar({
  events,
  headerLocation,
  handleUpdateSearch,
}: EventsCalendarProps) {
  return (
    <EventsCalendarController>
      <EventsCalendarView
        events={events}
        headerLocation={headerLocation}
        handleUpdateSearch={handleUpdateSearch}
      />
    </EventsCalendarController>
  )
}
