import { useState } from 'react'
import { uniqueId } from 'lodash'
import { NoDataIcon } from '@components/icons'
import { TabSelector } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'
import { CircularProgress, Typography } from '@mui/material'
import { CardStakeholder } from '@components/organisms/Cards'
import { OrganDetailContext } from '@pages/private/OrganDetail/context'
import { useGetOrganMembers } from '@services/nomos_api/resources/organs/members'

import { Container } from './styles'

export default function Members() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)
  if (!organ) return null

  const [tab, setTab] = useState('holder')

  const { data: adms, isFetching: isLoadingAdms } = useGetOrganMembers(
    organ.id,
    'other'
  )

  const { data: members, isFetching: isLoadingMembers } = useGetOrganMembers(
    organ.id,
    tab
  )

  return (
    <Container>
      <Typography variant="$font-title-3" color="$color-text-primary">
        Administrativo
      </Typography>

      {adms?.map((stakeholder) => (
        <CardStakeholder key={uniqueId()} stakeholder={stakeholder} />
      ))}

      {isLoadingAdms && (
        <CircularProgress size={30} sx={{ alignSelf: 'center' }} />
      )}

      {!isLoadingAdms && adms && adms?.length === 0 && (
        <NoDataIcon
          height={30}
          text="Não foram encontrados dados sobre a administração do orgão"
        />
      )}

      <Typography variant="$font-title-3" color="$color-text-primary">
        Membros
      </Typography>

      <TabSelector
        initialTab={tab}
        tabs={[
          { value: 'holder', label: 'Titulares' },
          { value: 'substitute', label: 'Suplentes' },
        ]}
        onChangeTab={setTab}
      />

      {members?.map((stakeholder) => (
        <CardStakeholder key={uniqueId()} stakeholder={stakeholder} />
      ))}

      {isLoadingAdms && (
        <CircularProgress size={30} sx={{ alignSelf: 'center' }} />
      )}

      {!isLoadingMembers && members && members?.length === 0 && (
        <NoDataIcon
          height={30}
          text="Não foram encontrados dados sobre os membros do orgão"
        />
      )}
    </Container>
  )
}
