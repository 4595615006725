import { createContext } from 'use-context-selector'
import { Member } from '@services/nomos_api/entities/user'

type EditUserContextType = {
  isLoading: boolean
  user?: Member
}

export const EditUserContext = createContext<EditUserContextType>(
  {} as EditUserContextType
)
