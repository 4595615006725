import filters from './filters'
import { Input } from '@components/atoms'
import { useEffect, useState } from 'react'
import { List, ListItem } from '@mui/material'
import { ScheduleContext } from '../../context'
import { useDebounce } from '@hooks/useDebounce'
import { SideFilterSelect } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'

import {
  SearchContainer,
  FilterContainer,
  FilterTitle,
} from './EventFilters.styles'

function TextFilter() {
  const [textSearch, setTextSearch] = useState('')
  const deboucnedTextSearch = useDebounce(textSearch, 500)

  const handleUpdateFilters = useContextSelector(
    ScheduleContext,
    (s) => s.handleUpdateFilters
  )

  useEffect(() => {
    handleUpdateFilters({ q: deboucnedTextSearch })
  }, [deboucnedTextSearch])

  return (
    <SearchContainer>
      <Input
        size="small"
        type="search"
        id="event-search"
        label="Buscar por palavra"
        defaultValue={textSearch}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTextSearch(e.target.value)
        }
      />
    </SearchContainer>
  )
}

function EventsFilter() {
  const handleUpdateFilters = useContextSelector(
    ScheduleContext,
    (s) => s.handleUpdateFilters
  )

  return (
    <>
      <TextFilter />

      <FilterContainer>
        <FilterTitle>Filtre os resultados desejados por:</FilterTitle>

        <List>
          {filters?.map((filter) => (
            <ListItem key={filter.attr} disablePadding>
              <SideFilterSelect
                filter={filter}
                handleUpdateSearch={handleUpdateFilters}
              />
            </ListItem>
          ))}
        </List>
      </FilterContainer>
    </>
  )
}

export default EventsFilter
