import { List, ListItem } from '@mui/material'
import { SearchResultsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { SideFilterSelect } from '@components/organisms'

function SideFilterListComponent() {
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)
  const search = useContextSelector(SearchResultsContext, (s) => s.search)

  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )

  return (
    <List>
      {bucket?.filters?.map((filter) => (
        <ListItem key={filter.attr} disablePadding>
          <SideFilterSelect
            filter={filter}
            search={search}
            selectedBucket={selectedBucket}
            handleUpdateSearch={handleUpdateSearch}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default SideFilterListComponent
