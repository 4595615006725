import colors from '@theme/colors'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ClearRounded } from '@mui/icons-material'

export const ChipWrapper = styled(Chip)(() => ({
  adding: 0,
  maxWidth: 300,
  height: 34,
  fontSize: 14,
  border: `1px solid ${colors['$color-action-border']}`,
  color: colors['$color-action-border'],
  backgroundColor: colors['$color-background-secondary'],
}))

export const ChipIconDelete = styled(ClearRounded)`
  &.MuiSvgIcon-root {
    font-size: 14px !important;
    color: ${colors['$color-action-background-primary-default']} !important;
    margin-right: 10px !important;
    &:hover {
      color: ${colors['$color-action-background-primary-hover']} !important;
    }
  }
`
