/* eslint-disable no-param-reassign */
import {
  Button,
  Input,
  InputAdvancedSearch,
  TextLink,
  TooltipHelper,
} from '@components/atoms'
import colors from '@theme/colors'
import { TrashCan } from '@components/icons'
import { IconButton, Stack, Typography } from '@mui/material'
import { BucketSelector } from '@components/molecules'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from './AdvancedSearchModal.styles'
import { X } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'
import { useModal } from '@contexts/Modal'
import { AdvancedSearchModalContext } from './AdvancedSearchModal.context'
import { useCallback } from 'react'

export function AdvancedSearchModalView(): JSX.Element {
  const { closeModal } = useModal()

  const form = useContextSelector(AdvancedSearchModalContext, (s) => s.form)

  const handleSetBuckets = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleSetBuckets
  )

  const handleSetKeywordsOR = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleSetKeywordsOR
  )

  const handleSetKeywordsAND = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleSetKeywordsAND
  )

  const handleSetKeywordsNOT = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleSetKeywordsNOT
  )

  const handleToggleSensitive = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleToggleSensitive
  )

  const handleClear = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleClear
  )

  const handleApplyAdvancedSearch = useContextSelector(
    AdvancedSearchModalContext,
    (s) => s.handleApplyAdvancedSearch
  )

  const BucketSelectorCb = useCallback(() => {
    return (
      <BucketSelector
        buckets={form.buckets}
        onChange={(buckets) => handleSetBuckets(buckets)}
      />
    )
  }, [form])

  return (
    <>
      <ModalHeader>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" justifyContent="between">
            <Typography variant="$font-title-3" color="$color-text-primary">
              Pesquisa Avançada
            </Typography>
            <IconButton size="small" onClick={closeModal}>
              <X size={16} />
            </IconButton>
          </Stack>
          <Stack direction="column">
            <Typography variant="$font-body-base" color="$color-text-secondary">
              Utilize os campos abaixo para compor sua pesquisa e obter
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                resultados mais precisos
              </Typography>
              <TextLink
                target="_blank"
                text="Saiba mais"
                href="https://www.nomosapp.com.br/pesquisa-global/funcionalidade-de-pesquisa"
              />
            </Stack>
          </Stack>
        </Stack>
      </ModalHeader>
      <ModalBody>
        <Typography variant="$font-body-1" color="$color-text-primary">
          Selecione uma ou mais bases de dados para buscar a palavra-chave
        </Typography>
        <Stack direction="column" spacing={2} py={4}>
          <BucketSelectorCb />

          <InputAdvancedSearch
            value={{ or: form.keywords.or, and: [], not: [] }}
            label="Qualquer uma destas palavras"
            onChangeSearch={(values) => handleSetKeywordsOR(values.keywords.or)}
            helper="Exemplo: Etanol  Álcool - retorna resultados que contém “Etanol” ou “Álcool” (ou ambos)"
          />
          <InputAdvancedSearch
            value={{ or: form.keywords.and, and: [], not: [] }}
            label="Todas estas palavras"
            onChangeSearch={(values) =>
              handleSetKeywordsAND(values.keywords.or)
            }
            helper="Exemplo: Petróleo  Petrobras - retorna os resultados que contenham ambas palavras ”Petróleo” e ”Petrobras”"
          />
          <InputAdvancedSearch
            value={{ or: form.keywords.not, and: [], not: [] }}
            label="Nenhuma destas palavras"
            onChangeSearch={(values) =>
              handleSetKeywordsNOT(values.keywords.or)
            }
            helper="Exemplo: Doce  Criança - retorna resultados que não contém ”Agricultura” e não contém ”Criança”"
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Input
            type="checkbox"
            key="sensitive"
            label="Considerar acentuação, maiúsculas e minúsculas"
            checked={form.modes?.includes?.('sensitive')}
            onChange={() => handleToggleSensitive()}
          />
          <TooltipHelper placement="top" color={colors['$color-border-dark']}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Esta opção busca por todas as palavras exatamente da forma que
                elas foram digitadas.
              </Typography>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Exemplo: ”VALE” irá retornar somente os dados que conterem a
                palavra ”VALE” em letras maiúsculas
              </Typography>
            </Stack>
          </TooltipHelper>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="text"
          color="inherit"
          onClick={handleClear}
          text="Limpar todos os campos"
          startIcon={<TrashCan stroke={colors['$color-text-primary']} />}
        />
        <Stack direction="row" spacing={2}>
          <Button text="Sair" variant="text" onClick={closeModal} />
          <Button text="Aplicar" onClick={handleApplyAdvancedSearch} />
        </Stack>
      </ModalFooter>
    </>
  )
}
