import { Close } from '@mui/icons-material'
import { Alert, styled, alertClasses, Stack, Box } from '@mui/material'

export const AlertWrapper = styled(Alert)`
  display: flex;
  flex-direction: row;
  border-width: 0;
  width: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 445px;
  max-width: 100%;
  background-color: white;
  box-shadow: #0b1f4d10 0px 8px 16px 0px, #00000010 0px 2px 12px 0px;
  & .${alertClasses.icon} {
    margin-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  & .${alertClasses.message} {
    width: 100%;
    gap: 24px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const AlertContent = styled(Stack)`
  flex-direction: column;
  gap: 8px;
  margin: 0px;
  padding: 0px;
`

export const AlertClose = styled(Box)``

export const IconClose = styled(Close)`
  font-size: 12px;
  color: ${(props) => props.theme.palette['$color-border-dark']};
`
