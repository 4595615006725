import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { SignUpContext } from './context'
import { SignupControllerProps, SignupSteps } from './types'
import { useSnackbar } from '@contexts/Snackbar'
import { signup } from '@services/nomos_api/resources/user/signup'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'

import {
  email,
  nameSurname,
  password,
  phone,
  termsOfUse,
} from '@utils/forms/validation'
import _ from 'lodash'

export default function SignupController({
  children,
}: SignupControllerProps): JSX.Element {
  const [step, setStep] = useState<SignupSteps>('registration_step_1')
  const { showSnackbarError } = useSnackbar()

  const handleNextStep = useCallback(() => {
    switch (step) {
      case 'registration_step_1':
        return setStep('confirmation')
      default:
        return null
    }
  }, [step])

  const validationSchema = yup.object({
    name: nameSurname,
    email,
    phone,
    password,
    termsOfUse,
  })

  const formikSU = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      termsOfUse: false,
      active: false,
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      signup(
        Object.assign(_.cloneDeep(values), {
          name: `${values.name}`,
        })
      )
        .then((response) => {
          handleNextStep()
          if (response.kind === 'ACTIVATE_ACCOUNT')
            formikSU.setFieldValue('exp', response.exp)
        })
        .catch((error) => {
          const e = error as AxiosErrorResponse
          showSnackbarError(
            'Não foi possível criar a sua conta',
            e?.response?.data?.message ||
              'Verifique todos os campos e tente novamente'
          )
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const store = useMemo(
    () => ({
      formikSU,
      step,
      handleNextStep,
    }),
    [formikSU, step, handleNextStep]
  )

  return (
    <SignUpContext.Provider value={store}>{children}</SignUpContext.Provider>
  )
}
