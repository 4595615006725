import React from 'react'
import { useContextSelector } from 'use-context-selector'
import { Grid, List, Stack, Typography } from '@mui/material'
import { TabContent, TabContentInner } from '../../styles'
import { PropositionDetailsContext } from '../../context'
import { AttachmentItem } from './styles'

export function PanelAttachments({ visible = false }) {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  return (
    <Grid container spacing={2} sx={{ display: visible ? 'flex' : 'none' }}>
      <Grid item xs={12}>
        <TabContent>
          <TabContentInner>
            <Stack spacing={3} direction="column">
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="$font-title-3" color="$color-text-primary">
                    Anexos e Referenciados
                  </Typography>
                  <Typography variant="$font-body-base" color="$color-text-primary">
                    {`Veja todos os documentos anexados e referenciados à ${proposition?.title}`}
                  </Typography>
                </Stack>
              </Stack>

              <List>
                {(proposition?.attachments || []).map((attachment) => (
                  <AttachmentItem key={`attachment-${attachment.label}`}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="$font-body-base" color="$color-text-primary">
                        Documento:
                      </Typography>
                      <a
                        href={attachment.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography
                          variant="$font-body-base"
                          color="info"
                          className="link-attachment"
                        >
                          {attachment.label}
                        </Typography>
                      </a>
                    </Stack>
                  </AttachmentItem>
                ))}
              </List>
            </Stack>
          </TabContentInner>
        </TabContent>
      </Grid>
    </Grid>
  )
}
