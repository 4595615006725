import { createContext } from 'use-context-selector'
import { SpeechEntity } from '@services/nomos_api/resources/speech/getById'

export type SpeechDetailsContextType = {
  speech: SpeechEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const SpeechDetailsContext = createContext<SpeechDetailsContextType>(
  {} as SpeechDetailsContextType
)
