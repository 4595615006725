import * as Yup from 'yup'
import { useDrawer } from '@contexts/Drawer'
import { useFormik, FormikProps } from 'formik'
import { useSnackbar } from '@contexts/Snackbar'
import { SaveInMonitorContext } from './context'
import { MonitoringContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { saveEventAPI } from '@services/nomos_api/resources/event/save'
import { useState, useEffect, useMemo, createRef, useCallback } from 'react'
import { saveOrganAPI } from '@services/nomos_api/resources/saved_organ/save'
import { createSavedBucketApi } from '@services/nomos_api/resources/saved_buckets/save'
import { savePropositionAPI } from '@services/nomos_api/resources/saved_proposition/save'
import { saveStakeholderAPI } from '@services/nomos_api/resources/saved_stakeholder/save'
import { InputPickMonitorRef } from '@components/organisms/InputPickMonitor/InputPickMonitor.types'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'

import {
  FormProps,
  SaveInMonitorControllerProps,
  SavedBucketMonitorActionType,
} from './types'

import {
  SavedOrganUpdatesEnum,
  SavedOrganUpdatesKeys,
} from '@enums/SavedOrganUpdatesEnum'

const saveInMonitor: SavedBucketMonitorActionType = {
  propositions: {
    api: savePropositionAPI,
    attribute: 'propositionId',
  },
  events: {
    api: saveEventAPI,
    attribute: 'eventId',
  },
  stakeholders: {
    api: saveStakeholderAPI,
    attribute: 'stakeholderId',
  },
  organs: {
    api: saveOrganAPI,
    attribute: 'organId',
  },
} as unknown as SavedBucketMonitorActionType

export function SaveInMonitorController({
  children,
}: SaveInMonitorControllerProps) {
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()
  const { cleanStack } = useDrawer()

  const id = useContextSelector(MonitoringContext, (s) => s.id)

  const origin = useContextSelector(MonitoringContext, (s) => s.origin)
  const onFinish = useContextSelector(MonitoringContext, (s) => s.onFinish)

  // define controller states
  const refInputMonitor = createRef<InputPickMonitorRef>()
  const [isSavingBucket, setIsSavingBucket] = useState<boolean>(false)
  const [selectedPanels, setSelectedPanels] = useState<MultiSelectChipOption[]>(
    []
  )

  // define api query mutator for selected bucket
  // otherelse define (favorite) approach
  const saveBucket = saveInMonitor[origin] || {
    api: createSavedBucketApi,
    attribute: 'originId',
  }

  const formSavedBucket: FormikProps<FormProps> = useFormik({
    initialValues: {
      bucket: origin,
      [saveBucket.attribute]: undefined as unknown as string,
      monitors: [] as string[],
      trackedUpdates: [] as unknown as SavedOrganUpdatesKeys[],
      trackedPropositionTypes: undefined as unknown as string[],
    },
    validationSchema: Yup.object().shape({
      [saveBucket.attribute]: Yup.string().required(),
      monitors: Yup.array().min(1).required('Selecione ao menos um monitor'),
      trackedUpdates: Yup.mixed().when('bucket', {
        is: 'organs',
        then: Yup.array()
          .of(Yup.string().oneOf(Object.keys(SavedOrganUpdatesEnum)))
          .min(1, 'Selecione ao menos um tipo de alteração')
          .required('Alertas de alteração obrigatório.'),
      }),
    }),
    onSubmit: () => undefined,
  })

  const handleSubmit = useCallback(
    (form: Partial<FormProps>): Promise<void> => {
      return new Promise((resolve) => {
        formSavedBucket.validateForm().then(() => {
          setIsSavingBucket(true)
          saveBucket
            .api({
              ...formSavedBucket.values,
              ...form,
            })
            .then((data) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if (onFinish) onFinish(data as any)
              showSnackbarSuccess('Favoritado com sucesso')
              cleanStack()
              setTimeout(closeSnackbar, 3000)
            })
            .catch(() => {
              showSnackbarError(
                'Não foi possível associar o dado aos monitores desejados'
              )
              setTimeout(closeSnackbar, 3000)
            })
            .finally(() => {
              setIsSavingBucket(false)
              resolve()
            })
        })
      })
    },
    [formSavedBucket]
  )

  // update saved bucket form always
  // the selected monitors changes
  useEffect(() => {
    formSavedBucket.setFieldValue(
      'monitors',
      selectedPanels.map((panel) => panel.key)
    )
  }, [selectedPanels])

  // update saved bucket form always
  // the monitor id has changed
  useEffect(() => {
    formSavedBucket.setFieldValue(saveBucket.attribute, id)
  }, [id])

  const store = useMemo(
    () => ({
      refInputMonitor,
      formSavedBucket,
      isSavingBucket,
      selectedPanels,
      setSelectedPanels,
      handleSubmit,
    }),
    [
      refInputMonitor,
      formSavedBucket,
      formSavedBucket,
      selectedPanels,
      setSelectedPanels,
      handleSubmit,
    ]
  )

  return (
    <SaveInMonitorContext.Provider value={store}>
      {children}
    </SaveInMonitorContext.Provider>
  )
}
