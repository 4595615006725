/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import { addHours, parseISO } from 'date-fns'
import { EventsCalendarContext } from './context'
import EventsCalendarDay from './EventsCalendarDay'
import EventsCalendarWeek from './EventsCalendarWeek'
import EventsCalendarList from './EventsCalendarList'
import EventsCalendarMonth from './EventsCalendarMonth'

export function EventsCalendarView({ events, headerLocation, handleUpdateSearch }) {
  const { eventsMode } = useContext(EventsCalendarContext)

  const formattedEvents = events?.map((item) => ({
    id: item.id || item.id,
    title: [item?.organs?.[0]?.initials, item.type || item.openDataResource.toUpperCase()].filter(i => !!i).join(" - "),
    start: parseISO(item.dateStart),
    end: addHours(parseISO(item.dateStart), 1),
    event: item,
    background: item.background,
    borderColor: item.borderColor
  })) || []

  const components = {
    Dia: <EventsCalendarDay
      data={formattedEvents}
      headerLocation={headerLocation}
      handleUpdateSearch={handleUpdateSearch}
    />,
    Semana: <EventsCalendarWeek
      data={formattedEvents}
      headerLocation={headerLocation}
      handleUpdateSearch={handleUpdateSearch}
    />,
    Mês: <EventsCalendarMonth
      data={formattedEvents}
      headerLocation={headerLocation}
      handleUpdateSearch={handleUpdateSearch}
    />,
    Lista: <EventsCalendarList
      data={events}
      headerLocation={headerLocation}
      handleUpdateSearch={handleUpdateSearch}
    />,
  }

  return (
    <>
      {components[eventsMode]}
    </>
  )
}