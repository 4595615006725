import React from 'react'
import { useModal } from '@contexts/Modal'
import { PurchaseSeatModalContext } from './context'
import { PurchaseSeatModalControllerProps } from './types'
import { StripeModalError } from '@containers/StripeModalError'
import { generateStripeManageSubscriptionLink } from '@services/payment/resources/manage_subscription_link'
import { UrlType } from '@type/url'

export function PurchaseSeatModalController({
  children,
}: PurchaseSeatModalControllerProps): JSX.Element {
  const { openModal } = useModal()
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const handleError = (error: unknown) => {
    openModal(<StripeModalError error={error} />)
  }

  const handleOnStripeManageSubscriptionLink = (res: UrlType) => {
    window.location.replace(res.url)
  }

  const handleGenerateStripeSeatModal = () => {
    setLoading(true)
    generateStripeManageSubscriptionLink()
      .then(handleOnStripeManageSubscriptionLink)
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const state = React.useMemo(
    () => ({
      isLoading,
      handleGenerateStripeSeatModal,
    }),
    [isLoading, handleGenerateStripeSeatModal]
  )

  return (
    <PurchaseSeatModalContext.Provider value={state}>
      {children}
    </PurchaseSeatModalContext.Provider>
  )
}
