/* eslint-disable no-unused-vars */

import { Wrapper } from './styles'
import SavedResults from './SavedResults'
import SearchResults from './SearchResults'
import { HomeContext } from '../../context'
import { useContextSelector } from 'use-context-selector'

function Keywords() {
  const search = useContextSelector(HomeContext, (s) => s.search)
  if (
    (search?.keywords?.and || []).length === 0 &&
    (search?.keywords?.or || []).length === 0 &&
    (search?.keywords?.not || []).length === 0
  ) {
    return <div />
  }

  return (
    <Wrapper>
      <SearchResults />
      <SavedResults />
    </Wrapper>
  )
}

export default Keywords
