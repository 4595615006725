import _ from 'lodash'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Button } from '@components/atoms'
import { useModal } from '@contexts/Modal'
import Typography from '@mui/material/Typography'

type Props = {
  error: unknown
}

export function StripeModalError({ error }: Props): JSX.Element {
  const { closeModal } = useModal()
  return (
    <Box p={4} maxWidth={480}>
      <Stack spacing={4} direction="column" alignItems="flex-end">
        <Stack spacing={1} direction="column" alignItems="flex-start">
          <Typography variant="$font-title-3" color="$color-text-primary">
            Erro
          </Typography>
          <Typography variant="body1" color="$color-text-secondary">
            {_.get(error, 'response.data.message', _.get(error, 'message'))}
          </Typography>
        </Stack>
        <Button onClick={() => closeModal()} text="Fechar" />
      </Stack>
    </Box>
  )
}
