import {
  styled,
  Tooltip as MUITooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'

export const Tooltip = styled(({ ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: props.className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: 8px;
    background-color: #0d3549;
    border-radius: 4px;
  }
  & .${tooltipClasses.arrow} {
    color: #0d3549;
  }
`
