import { RegulatoryAgencyDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function RegulatoryAgencyDetailsPage() {
  return (
    <SpeechDetailsController>
      <RegulatoryAgencyDetailsView />
    </SpeechDetailsController>
  )
}

export default RegulatoryAgencyDetailsPage
