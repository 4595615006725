import { useMemo } from 'react'
import { NotificationContext } from './context'

export type NotificationProps = {
  children: React.ReactNode
}

export function NotificationController({ children }: NotificationProps) {
  const store = useMemo(() => ({}), [])
  return (
    <NotificationContext.Provider value={store}>
      {children}
    </NotificationContext.Provider>
  )
}
