import { Box, Tab, TabProps } from '@mui/material'
import { theme } from '@theme/index'
import { styled } from '@mui/material/styles'
import { TabList, TabListProps } from '@mui/lab'

export const BadgeWrapper = styled(Box)`
  padding-left: 12px;
`

export const TabSelectorTabs = styled((props: TabListProps) => (
  <TabList
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  '& .MuiTabs-root ': { display: 'flex' },
  '& .MuiTabs-flexContainer': {
    height: '47px',
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  '.MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
})

export const TabSelectorTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))({
  opacity: 1,
  marginRight: 0,
  color: theme.palette['$color-text-secondary'],
  height: '46px',
  minHeight: '46px',
  maxHeight: '46px',
  minWidth: 'auto',
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
})
