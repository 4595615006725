import { BucketsEnum } from '@utils/buckets/type'
import { createContext } from 'use-context-selector'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import {
  Filter,
  SearchCountEntity,
} from '@services/nomos_api/entities/saved_searches'

export type HomeContextType = {
  modes: 'sensitive'[]
  search: AdvancedSearchType
  buckets: BucketsEnum[]
  isFetching: boolean
  searchCount?: SearchCountEntity
  setModes: (modes: 'sensitive'[]) => void
  setSearch: React.Dispatch<React.SetStateAction<AdvancedSearchType>>
  setBuckets: React.Dispatch<React.SetStateAction<BucketsEnum[]>>
  handleExecuteSearch: (
    keywords?: AdvancedSearchType,
    filters?: Filter,
    searchId?: string,
    monitorId?: string
  ) => void
}

export const HomeContext = createContext<HomeContextType>({} as HomeContextType)
