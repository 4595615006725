import { createRef } from 'react'
import { Container, Content } from './Organs.styles'
import { CardOrgan } from '@components/organisms/Cards'
import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { OrganEntity } from '@services/nomos_api/entities/organ'

export default function Speeches() {
  const ref = createRef<HTMLDivElement>()

  const stakeholder = useContextSelector(
    StakeholderDetailContext,
    (s) => s.stakeholder
  )

  const organs = stakeholder.organs
    ? stakeholder.organs.filter((o) => o.active === true)
    : []

  return (
    <Container>
      <Content ref={ref} data-cy="organs-peechs">
        {organs?.map((organ: OrganEntity) => (
          <CardOrgan key={organ.id} organ={organ} />
        ))}
      </Content>
    </Container>
  )
}
