import CardSum from '../../CardSum'
import EmptyState from '../../EmptyState'
import { TextLink } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { DividerIcon, SearchIcon } from '@components/icons'
import { CircularProgress, Typography } from '@mui/material'
import { SavedSearchDashboard } from '@services/nomos_api/entities/dashboard'

import {
  MonitorCard,
  CardHeader,
  CardTitle,
  CardContentSum,
  CardContent,
} from '../../styles'

export type SavedSearchesProps = {
  saved?: SavedSearchDashboard
  isLoading: boolean
}

function SavedSearches({ saved, isLoading }: SavedSearchesProps) {
  const navigate = useNavigate()

  return (
    <MonitorCard type="bordored" height={140}>
      <CardHeader>
        <CardTitle>
          <SearchIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Pesquisas Salvas
          </Typography>
          {isLoading && <CircularProgress size={15} />}
        </CardTitle>

        <TextLink text="Ver todas" href="/keywords" />
      </CardHeader>

      <CardContent>
        {!isLoading && saved?.count_saved_searches === 0 ? (
          <EmptyState
            text="As pesquisas salvas e os dados encontrados relacionados a elas aparecerão aqui após sua primeira pesquisa salva."
            action="Pesquisar"
            onClick={() =>
              navigate(
                '/search-results?keyword=&buckets=propositions%2Cdou%2Cevents%2Cspeeches%2Cnotices'
              )
            }
          />
        ) : (
          <CardContentSum>
            <CardSum
              count={saved?.count_saved_searches || 0}
              label="Total de pesquisas salvas"
            />
            <DividerIcon />
            <CardSum
              count={saved?.count_last_data_found || 0}
              label="Dados encontrados nos últimos 7 dias"
            />
          </CardContentSum>
        )}
      </CardContent>
    </MonitorCard>
  )
}

SavedSearches.defaultProps = {
  saved: null,
}

export default SavedSearches
