import { Stack } from '@mui/material'
import { useAuth } from '@contexts/Auth'
import { Button } from '@components/atoms'
import { Logout } from '@mui/icons-material'
import { AppBar, Container, NomosLogo, Content } from './LockPage.styles'

export type AuthPageProps = {
  children: React.ReactChild
}

export default function LockPage({ children }: AuthPageProps) {
  const { isLogout } = useAuth()
  return (
    <>
      <AppBar type="bordored" height={80}>
        <NomosLogo />
      </AppBar>
      <Container>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="text"
              color="secondary"
              text="Sair"
              endIcon={<Logout />}
              onClick={isLogout}
            />
          </Stack>
          <Content>{children}</Content>
        </Stack>
      </Container>
    </>
  )
}
