/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import Cookies from 'js-cookie'
import { useSearchParams } from 'react-router-dom'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { removeUndefineds } from '@utils/functions/parser/parser'

type ContextType = {
  setCookies: (
    data: Record<string, string>,
    options?: Cookies.CookieAttributes | undefined
  ) => void
  getCookie: (cookieName: string) => string | undefined
}
type CookiesProviderProps = { children: React.ReactNode }

const Context = createContext<ContextType>({} as ContextType)

function CookiesProvider({ children }: CookiesProviderProps) {
  const [searchParams] = useSearchParams()
  const setCookies = (
    data: Record<string, string>,
    options?: Cookies.CookieAttributes | undefined
  ): void => {
    for (const item in data) {
      Cookies.set(item, data[item], options)
    }
  }
  const getCookie = (cookieName: string): string | undefined => {
    return Cookies.get(cookieName)
  }

  // handle initial utm cookies
  const handleUTMCookies = () => {
    const utmCampaign = searchParams.get('utm_campaign')
    const utmContent = searchParams.get('utm_content')
    const utmMedium = searchParams.get('utm_medium')
    const utmSource = searchParams.get('utm_source')
    setCookies(
      removeUndefineds({
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_medium: utmMedium,
        utm_source: utmSource,
      })
    )
  }

  // handle initial cookies
  useEffect(() => {
    handleUTMCookies()
  }, [])

  const state = useMemo(
    () => ({
      setCookies,
      getCookie,
    }),
    [setCookies, getCookie]
  )
  return <Context.Provider value={state}>{children}</Context.Provider>
}

const useCookies = () => {
  const context = useContext(Context)
  return context
}

export { CookiesProvider, useCookies }
