import React from 'react'
import { useContextSelector } from 'use-context-selector'
import { StateModalDownload } from './components/StateModalDownload'
import { StateModalFields } from './components/StateModalFields'
import { StateModalFilter } from './components/StateModalResources'
import { StateModalFormat } from './components/StateModalFormat'
import { ExportPropositionModalContext } from './context'

export function ExportPropositionModalView(): JSX.Element {
  const fields = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.fields
  )

  const resources = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.resources
  )

  const format = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.format
  )

  if (!resources) return <StateModalFilter />
  if (!format) return <StateModalFormat />
  if (!fields) return <StateModalFields />
  return <StateModalDownload />
}
