import { createContext } from 'use-context-selector'

import {
  GetAllOrgansFilters,
  GetAllOrgansResult,
} from '@services/nomos_api/resources/organs/organs'

export type OrgansContextType = {
  isLoading: boolean
  organs?: GetAllOrgansResult
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleFilterChange: (filter: GetAllOrgansFilters) => void
}

export const OrgansContext = createContext<OrgansContextType>(
  {} as OrgansContextType
)
