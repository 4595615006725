import { SVGProps } from 'react'

function StakeholdersIcon({
  width = 32,
  height = 32,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill}>
      <path
        d="M4 25.3332V23.9998C4 21.0543 6.38781 18.6665 9.33333 18.6665H14.6667C17.6122 18.6665 20 21.0543 20 23.9998V25.3332M20 14.6665C22.2091 14.6665 24 12.8756 24 10.6665C24 8.45736 22.2091 6.6665 20 6.6665M28 25.3332V23.9998C28 21.0543 25.6122 18.6665 22.6667 18.6665H22M16 10.6665C16 12.8756 14.2091 14.6665 12 14.6665C9.79086 14.6665 8 12.8756 8 10.6665C8 8.45736 9.79086 6.6665 12 6.6665C14.2091 6.6665 16 8.45736 16 10.6665Z"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StakeholdersIcon
