import { useQuery } from '@tanstack/react-query'
import {
  PropositionPositioningEnum,
} from '@enums/PropositionPositioningEnum'

import HTTPClient from '../..'

export const listSavedPropositionsAPI = async (props) => {
  let endpoint = `monitor/${props.id}/propositions?limit=${props?.limit || 5}`

  if (props?.q) {
    endpoint += `&q=${props.q}`
  }

  if (props?.page) {
    endpoint += `&page=${props.page}`
  }

  if (props?.house) {
    endpoint += `&resource=${props.house}`
  }

  if(props?.sort){
    endpoint += `&sort=${props.sort}`
  }

  if(props?.sortDirection){
    endpoint += `&sortDirection=${props.sortDirection}`
  }

  if(props?.positioning){
    endpoint += `&positioning=${props.positioning}`
  }

  if(props?.tags){
    endpoint += `&tags=${props.tags}`
  }

  if(props?.period?.from){
    endpoint += `&fromPeriod=${props.period.from}`
  }

  if(props?.period?.to){
    endpoint += `&toPeriod=${props.period.to}`
  }

  if (props?.filter && props?.filter?.items) {
    const items = props?.filter?.items;
    items.forEach(element => {
      if (element.value) {
        let value = element.value
        const lowerCaseValue = value.trim().toLowerCase();
        const isAgainst = lowerCaseValue === PropositionPositioningEnum.against.toLowerCase();
        const isInFavor = lowerCaseValue === PropositionPositioningEnum.infavor.toLowerCase();

        if (isAgainst) {
          value = 'against';
        } else if (isInFavor) {
          value = 'infavor';
        }
        endpoint += `&${element.columnField}=${value}`
      }
    })
  }

  const { data } = await HTTPClient.get(endpoint)
  return data || []
}

export const useListSavedPropositions = (props) =>
  useQuery(
    ['list-saved-propositions', props],
    () => listSavedPropositionsAPI(props),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
