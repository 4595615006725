import { DataFoundContext } from '../../context'
import { buckets, getBucketName } from '@utils/buckets'
import { FilterSelectDropdown } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'

export function FilterBuckets(): JSX.Element {
  const selectedBuckets = useContextSelector(
    DataFoundContext,
    (s) => s.selectedBuckets
  )

  const setSelectedBuckets = useContextSelector(
    DataFoundContext,
    (s) => s.setSelectedBuckets
  )

  const bucketsFormatter = Object.keys(buckets).map((bucket) => ({
    label: getBucketName(bucket),
    value: bucket,
  }))

  return (
    <FilterSelectDropdown
      values={selectedBuckets}
      options={bucketsFormatter}
      onChangeOptions={setSelectedBuckets}
      label="Categoria de dado"
      data-cy="buckets"
      size="tiny"
    />
  )
}
