import { useMemo } from 'react'
import { List } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { FilterListVirtualized } from './SideFilterSelect.styles'
import { SideFilterSelectOption } from './SideFilterSelectOption'
import { SideFilterSelectContext } from './SideFilterSelectContext'
import { matchUnorderedPhrases } from '@utils/functions/string/match_unordered_phrases'

export function SideFilterSelectOptions(): JSX.Element {
  const options = useContextSelector(SideFilterSelectContext, (s) => s.options)
  const loading = useContextSelector(SideFilterSelectContext, (s) => s.loading)

  const selecteds = useContextSelector(
    SideFilterSelectContext,
    (s) => s.selecteds
  )

  const handleIncrementPage = useContextSelector(
    SideFilterSelectContext,
    (s) => s.handleIncrementPage
  )

  const dispatchTextFilter = useContextSelector(
    SideFilterSelectContext,
    (s) => s.dispatchTextFilter
  )

  // <!-- filter unselected options width text input filter -->
  const filteredOptions = useMemo(() => {
    return dispatchTextFilter.length === 0
      ? options
      : options.filter((option) => {
          return matchUnorderedPhrases(option.value, dispatchTextFilter)
        })
  }, [options, dispatchTextFilter])

  // <!-- filter options for unmatch text input filter -->
  const unfilteredOptions = filteredOptions
    .filter(
      (option) => !selecteds.some((selected: string) => selected === option.key)
    )
    .map((option) => ({
      kind: 'option',
      option: { ...option, selected: false },
    }))

  // <!-- build the rest of the render list -->
  const buildOptions = []
  if (unfilteredOptions.length > 0) {
    // <!-- add unselected options input filter macthes -->
    buildOptions.push({ kind: 'label', value: 'Outros' })
    buildOptions.push(...unfilteredOptions)
  }

  if (!loading && selecteds.length === 0 && unfilteredOptions.length === 0) {
    buildOptions.push({ kind: 'alert', value: 'Nenhum resultado' })
  }

  const displayOptions = [
    ...selecteds.map((selected: string) => ({
      kind: 'option',
      option: {
        key: selected,
        value: options.find((i) => i.key === selected)?.value,
        selected: true,
      },
    })),
    ...buildOptions,
  ]

  return (
    <FilterListVirtualized
      itemData={displayOptions}
      innerElementType={List}
      itemCount={displayOptions.length}
      itemSize={40}
      width="100%"
      onScroll={(props) => {
        if (
          displayOptions.length > 5 &&
          props.scrollDirection === 'forward' &&
          displayOptions.length * 40 - props.scrollOffset < 200
        ) {
          handleIncrementPage()
        }
      }}
      height={displayOptions.length > 5 ? 200 : displayOptions.length * 48}
    >
      {SideFilterSelectOption}
    </FilterListVirtualized>
  )
}
