import { PropositionsExportModalController } from './PropositionsExportModal.controller'
import { PropositionsExportModalProps } from './PropositionsExportModal.types'
import { PropositionsExportModalView } from './PropositionsExportModal.view'

export default function PropositionsExportModal(
  props: PropositionsExportModalProps
) {
  return (
    <PropositionsExportModalController {...props}>
      <PropositionsExportModalView />
    </PropositionsExportModalController>
  )
}
