import HTTPClient from '../..'
import { monitorEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'

export const saveStakeholderAPI = async (body: {
  monitors: string[]
  propositionId: string
}) => {
  monitorEvent('Stakeholder')
  const { data } = await HTTPClient.post('/saved_stakeholders', body)
  return data
}

export const useSaveStakeholder = () =>
  useMutation(['save-stakeholder'], saveStakeholderAPI)
