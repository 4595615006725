import { uid } from 'uid/single'
import { Status } from '@components/molecules'
import { Box, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SearchResultsContext } from '@pages/private/SearchResults/context'

function FormSubtitlesComponent() {
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if ((bucket?.subtitles?.length || 0) === 0) return <></>

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="$font-title-5" color="$color-text-primary">
          Legenda:
        </Typography>

        {bucket?.subtitles.map((subtitle) => (
          <Status
            text={subtitle.label}
            color={subtitle.color}
            key={uid()}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default FormSubtitlesComponent
