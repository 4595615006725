import { useSnackbar } from '@contexts/Snackbar'
import { SavedSearchesListContext } from './context'
import { useCallback, useMemo, useState } from 'react'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { deleteSearchByIdAPI } from '@services/nomos_api/resources/monitor_search/delete'
import { useGetAllSavedSearches } from '@services/nomos_api/resources/saved_searches/getAll'

type SavedSearchPanelControllerProps = {
  monitorId?: string
  children: React.ReactNode
}

function SavedSearchesListController({
  children,
  monitorId,
}: SavedSearchPanelControllerProps) {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const {
    data: savedSearches,
    isFetching: isLoading,
    refetch,
  } = useGetAllSavedSearches({
    page,
    limit,
    monitorId,
    // just filter 'only' if monitorId is not provided
    // because monitorId can be a suggested monitor
    only: monitorId ? undefined : ['owner'],
  })

  const handleExcludeSearch = useCallback(
    async (savedSearch: SavedSearchEntity) => {
      try {
        await deleteSearchByIdAPI({
          monitorId: savedSearch.monitorId,
          searchId: savedSearch.id,
        })
        showSnackbarSuccess('Pesquisa excluida com sucesso')
        refetch()
      } catch (error) {
        const err = error as { response: { data: { message: string } } }
        showSnackbarError(
          err?.response?.data?.message || 'Erro ao excluir pesquisa salva'
        )
      } finally {
        setTimeout(closeSnackbar, 3000)
      }
    },
    []
  )

  const store = useMemo(
    () => ({
      isLoading,
      savedSearches,
      refetch,
      setPage,
      setLimit,
      handleExcludeSearch,
    }),
    [isLoading, savedSearches, refetch, setPage, setLimit, handleExcludeSearch]
  )

  return (
    <SavedSearchesListContext.Provider value={store}>
      {children}
    </SavedSearchesListContext.Provider>
  )
}

SavedSearchesListController.defaultProps = {
  monitorId: null,
}

export default SavedSearchesListController
