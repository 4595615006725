import { KeywordInputText, TooltipDisabledAlert } from './render-input-styles'
import { AutocompleteRenderInputParams } from '@mui/material'

type Props = {
  label?: string
  disabled: boolean
  placeholder?: string
  params: AutocompleteRenderInputParams
  disableTooltip?: boolean
}

export function RenderInput({
  label,
  params,
  disabled,
  placeholder,
  disableTooltip,
}: Props): JSX.Element {
  return (
    <TooltipDisabledAlert
      title="Para editar as palavras desta pesquisa, vá em pesquisa avançada."
      placement="top"
      arrow
      disableHoverListener={!disabled || disableTooltip}
      disableFocusListener={!disabled || disableTooltip}
      disableTouchListener={!disabled || disableTooltip}
      disableInteractive={!disabled || disableTooltip}
    >
      <KeywordInputText label={label} placeholder={placeholder} {...params} />
    </TooltipDisabledAlert>
  )
}
