import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PropositionsPanelContext } from './context'

type PropositionsPanelControllerProps = {
  children: React.ReactNode
}

export default function PropositionsPanelController({
  children,
}: PropositionsPanelControllerProps) {
  const { id } = useParams()

  const store = useMemo(() => ({ id }), [id])

  return (
    <PropositionsPanelContext.Provider value={store}>
      {children}
    </PropositionsPanelContext.Provider>
  )
}
