import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SignUpContext } from '../../context'
import { TextTimeOut } from '@components/atoms/TextTimeOut/TimeOut'
import { Feedback } from '@components/organisms/Cards'
import IconRecoverySuccess from '@assets/icons/recovery-email-success.svg'
import { SignInEmailAPI } from '@services/nomos_api/resources/signin_email'
import { useAuth } from '@contexts/Auth'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { useSnackbar } from '@contexts/Snackbar'
import { Button } from '@components/atoms'
import { AuthPage } from '@components/templates'
import { Container } from './styles'

export function EmailConfirmation(): JSX.Element {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { showSnackbarError, showSnackbarInfo } = useSnackbar()
  const formikSU = useContextSelector(SignUpContext, (s) => s.formikSU)

  const resendConfirmationLink = () => {
    showSnackbarInfo(
      'E-mail de confirmação',
      'Se os dados fornecidos estiverem corretos, você deve receber um novo e-mail de confirmação.'
    )
  }

  const CheckVerified = () => {
    SignInEmailAPI({
      email: formikSU.values.email,
      password: formikSU.values.password,
    })
      .then((response) => {
        if (response.kind === 'AUTHENTICATION') {
          const lastPage = localStorage.getItem('@NOMOS:lastPage')
          isAuthenticated(response.data.user, response.data.jwt)
          navigate(lastPage || (isMobile ? 'search-results' : '/home'))
        } else if (response.kind === 'ACTIVATE_ACCOUNT') {
          navigate('/')
          showSnackbarError(
            'Erro ao fazer login',
            'Identificamos que você não confirmou seu e-mail através do link enviado.',
            undefined,
            {
              text: 'Reenviar link de confirmação',
              onClick: resendConfirmationLink,
            }
          )
        }
      })
      .catch((error) => {
        navigate('/')
        const e = error as AxiosErrorResponse
        showSnackbarError(
          'Erro de Autenticação',
          e?.response?.data?.message ||
            'Erro ao tentar autenticar. Verifique seus dados e tente novamente.'
        )
      })
  }

  return (
    <AuthPage>
      <Container>
        <Feedback
          icon={IconRecoverySuccess}
          title="Enviamos um e-mail de confirmação para"
          subtitle={formikSU.values.email}
          text="Siga as instruções no e-mail para ativar sua conta"
          sx={{
            title: {
              color: '#202122',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
            },
            subtitle: {
              color: '#606162',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
            },
            text: {
              color: '#606162',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
            },
          }}
        />
        <Stack alignItems="center">
          <Typography variant="subtitle1" sx={{ mt: 2, color: '#606162' }}>
            Já confirmou o e-mail?{' '}
            <Button
              text="Entrar"
              variant="text"
              sx={{ px: 1, textDecoration: 'underline' }}
              onClick={CheckVerified}
            />
          </Typography>
          <TextTimeOut
            onTimedOut={CheckVerified}
            expireTime={formikSU.values.exp}
            countDownLabelTemplate="Sua sessão expira em {{time}}"
            timedOutLabelTemplate="Sua sessão expirou"
          />
        </Stack>
      </Container>
    </AuthPage>
  )
}
