import styled from 'styled-components'

export const Container = styled.div`
  align-items: start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-top: 24px;
  gap: 16px;

  div {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
  }
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  align-items: start !important;
`

export const Option = styled.div`
  display: flex;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  background-color: #f9fafc;

  padding: 20px;
`
