import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export interface MultiSelectProps extends SelectProps {
  options: { label: string; value: string }[]
  onChangeOptions: (values: string[]) => void
}

export default function MultiSelect({
  id,
  size,
  label,
  options,
  onChangeOptions,
}: MultiSelectProps) {
  const [values, setValues] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event

    const valuesToSet = typeof value === 'string' ? value.split(',') : value
    setValues(valuesToSet)
    onChangeOptions(valuesToSet)
  }

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        data-testid={id || 'multi-select-component'}
        size={size}
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          options
            .filter((option) => selected.includes(option.value))
            .map((option) => option.label)
            .join(',')
        }
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              data-testid={`${id}-${option.value}`}
              checked={values.indexOf(option.value) > -1}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
