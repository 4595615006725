import { ReactChild } from 'react'

import { Header } from '@components/organisms'

export function HeaderMonitor({
  actions,
  id,
}: {
  actions: ReactChild
  id: string
}) {
  return (
    <Header
      paths={[
        {
          name: 'Monitoramento',
          location: '/monitors',
        },
        {
          name: 'Painel',
          location: `/monitors/${id}`,
        },
        {
          name: 'Usuários',
          location: `/monitors/${id}/users`,
        },
      ]}
      title="Usuários"
      description="Gerencie os usuários que possuem acesso a esse painel"
      isLoading={false}
      actions={actions}
    />
  )
}
