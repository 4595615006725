import { EmptyState } from '@components/molecules'
import { BodyContainerFill } from '../styles'

export function NoteContentEmpty(): JSX.Element {
  return (
    <BodyContainerFill>
      <EmptyState spacing={0} text="Ainda não há anotações a serem exibidas" />
    </BodyContainerFill>
  )
}
