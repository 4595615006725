import _ from 'lodash'
import { useDebounce } from '@hooks/useDebounce'
import { EmptyState } from '@components/molecules'
import { CircularProgress, Stack } from '@mui/material'
import { createRef, useCallback, useState } from 'react'
import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { DateRangePicker, Input } from '@components/atoms'
import { useInfinityScroll } from '@hooks/useInfinityScroll'
import { SpeechEntity } from '@services/nomos_api/resources/speech/getById'
import { CardSpeeches } from '@components/organisms/Cards/CardSpeech'
import { useGetSpeechesByAuthorId } from '@services/nomos_api/resources/search/speeches'

import {
  CheckBoxContainer,
  // CheckBox,
  Container,
  Content,
  Header,
} from './Speeches.styles'

type periodType = {
  from: string
  to: string
}

export default function Speeches() {
  const ref = createRef<HTMLDivElement>()

  const id = useContextSelector(StakeholderDetailContext, (s) => s.id)

  // const [checkFilter, setCheckFilter] = useState('')
  const [dateValueSeach, setDateValueSeach] = useState<periodType | null>(null)
  const [keywordValueSeach, setKeywordValueSeach] = useState<string>('')
  const updateKeywordValueSeach = useDebounce(keywordValueSeach, 500)

  const { data, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetSpeechesByAuthorId(
      {
        stakeholders: [id],
        speech_at: dateValueSeach || '',
      },
      { ...(keywordValueSeach && { keyword: updateKeywordValueSeach }) }
    )

  const speeches =
    data?.pages.reduce(
      (previous, current) => [...previous, ...current.results],
      []
    ) || []

  useInfinityScroll(ref, () => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      return fetchNextPage()
    }

    return null
  })

  const handleUpdateSchedule = useCallback((period: periodType) => {
    if (!period.from && !period.to) return setDateValueSeach(null)

    return setDateValueSeach((prevState: periodType | null) => ({
      ...prevState,
      ...period,
    }))
  }, [])

  // const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     setCheckFilter(e.target.value)
  //   } else {
  //     setCheckFilter('')
  //   }
  // }

  return (
    <Container>
      <Header>
        <Input
          type="search"
          size="small"
          label="O que está procurando?"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setKeywordValueSeach(e.target.value)
          }
        />
        <DateRangePicker
          sx={{ maxWidth: '178px' }}
          size="small"
          label="Data"
          startDate={_.get(dateValueSeach, 'from')}
          endDate={_.get(dateValueSeach, 'to')}
          onChange={(from: string | undefined, to: string | undefined) =>
            handleUpdateSchedule({ from: from!, to: to! })
          }
        />
        <CheckBoxContainer>
          {/* <CheckBox>
            <Input
              type="checkbox"
              value="commissions"
              checked={checkFilter === 'commissions'}
              onChange={handleCheckbox}
            />
            Comissões
          </CheckBox>
          <CheckBox>
            <Input
              type="checkbox"
              value="plenary"
              checked={checkFilter === 'plenary'}
              onChange={handleCheckbox}
            />
            Plenário
          </CheckBox> */}
        </CheckBoxContainer>
      </Header>
      <Content ref={ref} data-cy="lists-peechs">
        {speeches?.map((speech: SpeechEntity) => (
          <CardSpeeches speech={speech} key={speech.id} />
        ))}
        {!isFetching && speeches?.length === 0 && (
          <EmptyState text="Não há discursos a serem exibidos" />
        )}
        {isFetching && (
          <Stack alignSelf="center">
            <CircularProgress size={30} />
          </Stack>
        )}
      </Content>
    </Container>
  )
}
