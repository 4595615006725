import React from 'react'
import {
  PropositionPositioningKeys,
  PropositionPositioningEnum,
} from '@enums/PropositionPositioningEnum'
import { useUpdateSavedProposition } from '@services/nomos_api/resources/monitor/update-saved-proposition'

import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

type Props = {
  positioning: PropositionPositioningKeys
  savedPropositionId: string
}

export function PropositionCellPositioning({
  positioning,
  savedPropositionId,
}: Props): JSX.Element {
  const [value, setValue] =
    React.useState<PropositionPositioningKeys>(positioning)
  const { isLoading, mutate } = useUpdateSavedProposition()

  const handleUpdateValue = (newValue: PropositionPositioningKeys) => {
    if (newValue === value) return
    setValue(newValue)
    mutate({ positioning: newValue, savedPropositionId })
  }

  return (
    <Select
      size="tiny"
      fullWidth
      value={value}
      disabled={isLoading}
      onChange={(event) =>
        handleUpdateValue(
          event.target.value as unknown as PropositionPositioningKeys
        )
      }
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        '& fieldset': { border: 'none' },
      }}
      endAdornment={
        isLoading && (
          <InputAdornment position="end" sx={{ pr: 3 }}>
            <CircularProgress size={16} />
          </InputAdornment>
        )
      }
    >
      <MenuItem value="neutral">
        <Typography color="semantic.warning.main">
          {PropositionPositioningEnum.neutral}
        </Typography>
      </MenuItem>
      <MenuItem value="infavor">
        <Typography color="semantic.success.main">
          {PropositionPositioningEnum.infavor}
        </Typography>
      </MenuItem>
      <MenuItem value="against">
        <Typography color="semantic.error.main">
          {PropositionPositioningEnum.against}
        </Typography>
      </MenuItem>
    </Select>
  )
}
