import { TabList } from '@mui/lab'
import { theme } from '@theme/index'
import { styled } from '@mui/material/styles'
import { Tab, TabProps, TabsProps } from '@mui/material'

export interface TabSelectorTabsProps extends TabsProps {
  onChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void
}

export const TabSelectorTabs = styled((props: TabSelectorTabsProps) => (
  <TabList
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  '& .MuiTabs-root ': { display: 'flex' },
  '& .MuiTabs-flexContainer': {
    height: 46,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
  },
})

export interface TabSelectorTabProps extends TabProps {
  value: string
}

export const TabSelectorTab = styled((props: TabSelectorTabProps) => (
  <Tab disableRipple {...props} />
))({
  opacity: 1,
  fontSize: 14,
  marginRight: 0,
  fontWeight: 400,
  color: theme.palette.muted.main,
  height: '46px',
  minWidth: 'auto',
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
})
