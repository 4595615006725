import { PropositionDetailsView } from './view'
import { PropositionDetailsController } from './controller'

function PropositionDetailsPage() {
  return (
    <PropositionDetailsController>
      <PropositionDetailsView />
    </PropositionDetailsController>
  )
}

export default PropositionDetailsPage
