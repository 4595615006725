import { StyledLink } from './Link.styles'
import { useNavigate } from 'react-router-dom'
import { MouseEvent, HTMLAttributeAnchorTarget } from 'react'
import { SxProps, Theme } from '@mui/material'

export interface LinkProps {
  href?: string
  label?: string | JSX.Element
  target?: HTMLAttributeAnchorTarget
  underline?: 'none' | 'hover' | 'always' | undefined
  onClick?: () => void
  sx?: SxProps<Theme>
  [`data-cy`]?: string
  [`data-testid`]?: string
}

export default function Link({
  label,
  href,
  target,
  onClick,
  ...rest
}: LinkProps) {
  const navigate = useNavigate()

  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    // execute custom onclick if available
    if (onClick) onClick()
    // return if href is not setted
    if (!href) return
    const absoluteURLPattern = /^(?:[a-z]+:)?\/\//i
    // Create a RegExp object using the pattern
    const regex = new RegExp(absoluteURLPattern)
    // Check if the URL matches the regular expression
    const isAbsolute = regex.test(href)
    if ((!target && !e.ctrlKey) || isAbsolute) {
      e.preventDefault()
      if (isAbsolute) window.open(href, '_blank')
      else navigate(href)
    }
  }

  return (
    <StyledLink href={href} target={target} onClick={handleOnClick} {...rest}>
      {label}
    </StyledLink>
  )
}
