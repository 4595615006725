import HTTPClient from '../..'
import { acceptEvent } from '@utils/metrics'
import { useQuery } from '@tanstack/react-query'
import { FeedbackMessage } from '@services/nomos_api/entities/axios'

export const acceptAccountAPI = async (token: string) => {
  const { data } = await HTTPClient.get(`accept/${token}`)
  acceptEvent()
  return data as FeedbackMessage
}

export const useAcceptUserAccount = (token: string) =>
  useQuery(['accept-user-account'], () => acceptAccountAPI(token), {
    retry: 0,
    refetchOnWindowFocus: false,
  })
