import { PropositionStatusNotificationController } from './controller'
import { PropositionStatusNotificationProps } from './types'
import { PropositionStatusNotificationView } from './view'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export function PropositionStatusNotification(
  props: PropositionStatusNotificationProps
) {
  useByPassSubscription()
  return (
    <PropositionStatusNotificationController {...props}>
      <PropositionStatusNotificationView />
    </PropositionStatusNotificationController>
  )
}
