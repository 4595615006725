import { EditMonitorDrawerView } from './view'
import { EditMonitorController } from './controller'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

function EditMonitor(props) {
  useByPassSubscription()
  return (
    <EditMonitorController {...props}>
      <EditMonitorDrawerView {...props} />
    </EditMonitorController>
  )
}

export default EditMonitor
