import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { NavLink as DomNavLink } from 'react-router-dom'

export const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '36px',
}))

export const ContentPaths = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '10px',
}))

export const ContentActions = styled(Box)(({theme}) => ({
  display: 'flex',  
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '25px',

  [theme.breakpoints.up("md")]: {
    alignItems: 'center',
    flexDirection: 'row'
  },
}))

export const ContentActionsSide = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up("md")]: {
    gap: '25px',
    justifyContent: 'flex-start',
    flexDirection: 'row'
  },
}))

export const NavLink = styled(DomNavLink)({
  textDecoration: 'none',
})

export const TextLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.muted.main,
}))
