import { uid } from 'uid'
import { useMemo } from 'react'
import { HomeContext } from '../../context'
import { MenuItem, Stack, Typography } from '@mui/material'
import { SavedSearchCountEntity } from '@services/nomos_api/entities/saved_searches'
import { useContextSelector } from 'use-context-selector'

import { Content, Title, CountResultIcon } from './styles'
import { KeywordRender } from '@components/atoms'

function SavedResults() {
  const search = useContextSelector(HomeContext, (s) => s.search)
  const searchCount = useContextSelector(HomeContext, (s) => s.searchCount)
  const setSearch = useContextSelector(HomeContext, (s) => s.setSearch)

  const handleExecuteSearch = useContextSelector(
    HomeContext,
    (s) => s.handleExecuteSearch
  )

  const savedSearches = useMemo(
    () => searchCount?.saved_searches || [],
    [searchCount]
  )

  if (
    (search?.keywords?.and || []).length === 0 &&
    (search?.keywords?.or || []).length === 0 &&
    (search?.keywords?.not || []).length === 0
  )
    return <div />

  if (!savedSearches || savedSearches.length === 0) return <div />

  return (
    <Content>
      {savedSearches.length > 0 && (
        <Title color="$color-text-primary">Pesquisas salvas</Title>
      )}
      {savedSearches.map((result: SavedSearchCountEntity) => {
        const keywords = result.keywords
          ? { keywords: result.keywords }
          : { keywords: { or: [result.keyword], and: [], not: [] } }
        return (
          <MenuItem
            key={uid()}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={() => {
              setSearch(keywords)
              handleExecuteSearch(
                keywords,
                result.filters,
                result.groupId,
                result.monitorId
              )
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <CountResultIcon />
              <Stack direction="column" alignItems="flex-start">
                <KeywordRender value={keywords} tiny />
                <Typography variant="caption" color="$color-text-primary">
                  @ {result.name}
                </Typography>
              </Stack>
            </Stack>

            {/* <TextTiny>{result.total || 0} resultados</TextTiny> */}
          </MenuItem>
        )
      })}
    </Content>
  )
}

export default SavedResults
