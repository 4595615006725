import { Box } from '@mui/material'
import styled from 'styled-components'

export const FilterContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-left: 0px;
  padding-right: 32px;
  padding-bottom: 26px;

  overflow-y: scroll;
  overflow-x: hidden;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`
