import { BlockedFeatureView } from './view'
import { BlockedFeatureController } from './controller'
import { BlockedFeatureDrawerProps } from './types'

export default function BlockedFeature(props: BlockedFeatureDrawerProps) {
  return (
    <BlockedFeatureController {...props}>
      <BlockedFeatureView {...props} />
    </BlockedFeatureController>
  )
}
