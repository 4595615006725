import {
  CardWrapper,
  CardContent,
  CardBaseline,
  CardActions,
  OptionalCardActions,
} from './styles'

type Props = {
  cy?: string
  content: JSX.Element | JSX.Element[]
  actions?: JSX.Element | JSX.Element[]
  optionalActions?: JSX.Element | JSX.Element[]
}

export function CardTemplate(props: Props) {
  const { cy, content, actions, optionalActions } = props
  return (
    <CardWrapper data-cy={cy}>
      <CardBaseline>
        <CardContent>{content}</CardContent>
        {actions && <CardActions>{actions}</CardActions>}
        {optionalActions && (
          <OptionalCardActions>{optionalActions}</OptionalCardActions>
        )}
      </CardBaseline>
    </CardWrapper>
  )
}
