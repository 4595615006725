import { theme as teste } from '@theme/index'
import styled from 'styled-components'
import { Box, Typography, styled as styledMui } from '@mui/material'
import { ArrowBack, Close, Done } from '@mui/icons-material'

export const Container = styledMui(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  maxwidth: '100%',
  flexDirection: 'column',
  padding: '32px',
  gap: '16px',
  flex: 1,
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    width: '450px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))

export const ContentBody = styled(Box)`
  flex: 1;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2d3a40;
`

export const IconDone = styled(Done)`
  font-size: 14px !important;
  color: ${teste.palette.common.white};
  padding-left: 6px;
`

export const IconClose = styled(Close)`
  font-size: 16px !important;
  color: ${teste.palette['$color-border-dark']};
`

export const IconBack = styled(ArrowBack)`
  font-size: 16px !important;
  color: ${teste.palette['$color-border-dark']};
`

export const StepContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  justify-content: space-between;
  marginbottom: 50px;
`

export const ScrollBox = styled(Box)`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 32px;
  margin-bottom: 32px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`

export const Content = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 216px;
`
