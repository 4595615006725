import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { ErrorOutline, Close } from '@mui/icons-material'
import { useModal } from '@contexts/Modal'
import { Button as Button } from '@components/atoms'
import { Container, Content, Footer, Header } from './styles'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export default function RemoveMonitorModal({ onConfirm }) {
  useByPassSubscription()
  const { closeModal } = useModal()
  const [loading, setLoading] = useState(false)

  const handleExclude = async () => {
    if (onConfirm) {
     setLoading(true)
     await onConfirm()
    }

    setLoading(false)
    closeModal()
  }

  return (
    <Container>
      <Header>
        <Close onClick={closeModal} />
      </Header>

      <Content>
        <div>
          <ErrorOutline />
          <Typography variant="$font-title-3" color="$color-text-primary">
            Excluir Painel?
          </Typography>
        </div>

        <Typography variant="$font-body-base" color="$color-text-primary" maxWidth={436}>
          Ao excluir o painel automaticamente você deixará de seguir as
          proposições, eventos e stekeholders vinculadas a ele!
        </Typography>

        <Typography variant="$font-body-2" color="muted.dark">
          Você tem certeza que deseja excluir este painel?
        </Typography>
      </Content>

      <Footer>
        <Stack direction="row" spacing={2}>
          <Button
            id="delete-panel-confirm-btn"
            text="Excluir"
            variant="outlined"
            loading={loading}
            onClick={() => handleExclude()}
          />

          <Button text="Cancelar" onClick={closeModal} />
        </Stack>
      </Footer>
    </Container>
  )
}
