import moment from 'moment'
import { useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { EventDetailsContext } from './context'
import { useSnackbar } from '@contexts/Snackbar'
import { icsCalendar } from '@utils/functions/calendar/icsCalendar'
import { useGetEventDetails } from '@services/nomos_api/resources/event/getById'

type EventDetailsControllerProps = {
  children: React.ReactNode
}

export function EventDetailsController({
  children,
}: EventDetailsControllerProps) {
  const { id } = useParams()
  const { showSnackbarSuccess, closeSnackbar } = useSnackbar()

  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const { data: event, isLoading } = useGetEventDetails(id || '')

  const handleGetCalendar = useCallback(() => {
    icsCalendar({
      summary: event?.type,
      description: event?.description,
      startDate: ((event?.dateStart &&
        moment(event?.dateStart, 'YYYY-MM-DD').toDate()) ||
        undefined) as undefined,
      endDate: ((event?.dateEnd &&
        moment(event?.dateEnd, 'YYYY-MM-DD').toDate()) ||
        undefined) as undefined,
    })
  }, [event])

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(document.location.href)
    showSnackbarSuccess('Evento copiado para a área de transferência')
    setTimeout(closeSnackbar, 3000)
  }, [showSnackbarSuccess, closeSnackbar])

  const state = useMemo(
    () => ({
      isLoading,
      keyword,
      buckets,
      event,
      handleGetCalendar,
      handleCopyToClipboard,
    }),
    [
      isLoading,
      keyword,
      buckets,
      event,
      handleGetCalendar,
      handleCopyToClipboard,
    ]
  )

  return (
    <EventDetailsContext.Provider value={state}>
      {children}
    </EventDetailsContext.Provider>
  )
}
