/* eslint-disable  @typescript-eslint/no-explicit-any */
import ptBR from 'date-fns/locale/pt-BR'
import { lightFormat, parse } from 'date-fns'
import { CalendarMonth, Close } from '@mui/icons-material'
import React, { useEffect, useMemo, useState } from 'react'
import { DateSinglePicker } from './DateSinglePicker.styles'
import { InputAdornment, Popover, TextField, IconButton } from '@mui/material'

export type DateRangeSinglePickerProps = {
  size: any
  label: string
  date?: string
  onChange: (date: string | undefined) => void
}

function DateSinglePickerComponent({
  size,
  label,
  onChange,
  date,
}: DateRangeSinglePickerProps) {
  // <!-- define popopver controller -->
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? 'daterange-popover' : undefined

  // <!-- defines initial data -->
  const [selection, setSelection] = useState(
    date && parse(date, 'yyyy-MM-dd', new Date())
  )

  // <!-- watch selection changed to dispach -->
  useEffect(() => {
    if (selection === '') {
      handleClose()
      onChange(undefined)
    } else if (selection && onChange) {
      handleClose()
      onChange(lightFormat(selection, 'yyyy-MM-dd'))
    }
  }, [selection])

  // <!-- mount display value -->
  const displayValue = useMemo(() => {
    if (!selection) return ''
    return `${lightFormat(selection, 'dd/MM/yyyy')}`
  }, [selection])

  const handleClickIcon = () => {
    setSelection('')
  }

  const handleChangeDatePicker = (date: Date) => {
    setSelection(date)
  }

  return (
    <>
      <TextField
        fullWidth
        size={size}
        label={label}
        type="text"
        variant="outlined"
        value={displayValue}
        onClick={handleOpen}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: '-8px' }}>
              {displayValue.length > 0 ? (
                <IconButton size="small" onClick={handleClickIcon}>
                  <Close fontSize={size} sx={{ color: '#B6BCC9' }} />
                </IconButton>
              ) : (
                <IconButton size="small">
                  <CalendarMonth fontSize={size} sx={{ color: '#B6BCC9' }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            marginLeft: '1px',
            marginTop: '8px',
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.15) 0px 0px 0px 1px',
          },
        }}
      >
        <DateSinglePicker
          locale={ptBR}
          showPreview={false}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          weekdayDisplayFormat="EEEEE"
          onChange={handleChangeDatePicker}
        />
      </Popover>
    </>
  )
}

DateSinglePickerComponent.defaultProps = {
  date: undefined,
}

export default DateSinglePickerComponent
