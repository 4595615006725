import { useMemo } from 'react'
import colors from '@theme/colors'
import { Status } from '@components/molecules'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { SeatCountOccupiedType } from '@services/nomos_api/entities/seat_count'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

type Props = {
  loading?: boolean
  isTrial?: boolean
  seats?: SeatCountOccupiedType
  role: NomosProductCodeEnum.MONITOR_PRO | NomosProductCodeEnum.MONITOR_LIGHT
}
export function RoleSeatCTA({
  loading,
  seats,
  isTrial,
  role,
}: Props): JSX.Element | null {
  if (isTrial) return null

  const availableSeats = (seats?.quantity || 0) - (seats?.members || 0)
  const label = useMemo(() => {
    if (availableSeats > 0) {
      return [
        String(availableSeats),
        `Assento${availableSeats > 1 ? 's' : ''}`,
        `disponíve${availableSeats > 1 ? 'is' : 'l'}`,
      ].join(' ')
    }
    return 'Não há assentos disponíveis'
  }, [availableSeats])

  if (loading) {
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        <CircularProgress size={12} thickness={8} />
        <Typography variant="$font-body-2" color="$color-text-secondary">
          Verificando assentos...
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Status
        color={
          availableSeats > 0
            ? colors['$color-semantic-success']
            : colors['$color-background-alternative']
        }
        text={label}
        variant="$font-body-2"
      />
      <WhatsAppPaymentLink
        size="small"
        method={role}
        variant="text"
        text="contratar"
        title="contratar"
      />
    </Stack>
  )
}
