/* eslint-disable react/no-array-index-key */
import { Stack, Typography } from '@mui/material'
import { TooltipHelper } from '@components/atoms'
import { Container, Header } from './SecondaryPage.styles'

export type TabbedPageProps = {
  id: string
  title: string
  description: string
  helper?: string
  actions?: React.ReactElement
  children: React.ReactChild
}

const defaultProps = {
  actions: null,
}

function SecondaryPage({
  id,
  title,
  helper,
  description,
  actions,
  children,
}: TabbedPageProps) {
  return (
    <Container data-testid={id}>
      <Header>
        <div>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              variant="$font-title-3"
              color="$color-text-primary"
              lineHeight={1}
            >
              {title}
            </Typography>
            {helper && (
              <TooltipHelper>
                <Typography variant="caption" color="$color-text-primary">
                  {helper}
                </Typography>
              </TooltipHelper>
            )}
          </Stack>

          <Typography variant="$font-body-base" color="$color-text-tertiary">
            {description.split('\n').map((text, index) => {
              return <p key={index}>{text}</p>
            })}
          </Typography>
        </div>

        <div>{actions}</div>
      </Header>

      {children}
    </Container>
  )
}

SecondaryPage.defaultProps = defaultProps

export default SecondaryPage
