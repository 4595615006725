import { NoDataIcon } from '@components/icons'
import { Pagination } from '@components/molecules'
import { SavedBucketsContext } from '../../context'
import { CenterContent, CardsContainer } from './styles'
import { useContextSelector } from 'use-context-selector'
import { CircularProgress, Typography } from '@mui/material'
import { pageview } from '@utils/functions/pageview/pageview'
import { SavedBucketsCard } from '@containers/SavedBucketsList/components/SavedBucketsCard'
import { SavedBucketsAction } from '../SavedBucketsActions'

export default function SavedBucketsList() {
  const data = useContextSelector(SavedBucketsContext, (s) => s.data)
  const setPage = useContextSelector(SavedBucketsContext, (s) => s.setPage)
  const setLimit = useContextSelector(SavedBucketsContext, (s) => s.setLimit)
  const isLoading = useContextSelector(SavedBucketsContext, (s) => s.isLoading)

  return (
    <>
      <CardsContainer data-cy="stakeholders-list">
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Exibindo {pageview(data?.pagination || {})} resultados
        </Typography>

        <SavedBucketsAction />

        {data?.results?.map((savedBucket) => {
          return (
            <SavedBucketsCard key={savedBucket.id} savedBucket={savedBucket} />
          )
        })}

        {data?.pagination && (
          <Pagination
            pagination={data.pagination}
            handleUpdatePage={setPage}
            handleUpdateLimit={setLimit}
          />
        )}
      </CardsContainer>

      <CenterContent>
        {isLoading && (
          <CircularProgress data-testid="loading" color="primary" size={15} />
        )}
      </CenterContent>

      {!isLoading && data?.results?.length === 0 && (
        <CenterContent>
          <NoDataIcon
            height={36}
            text="Não foram encontrados dados favoritados"
          />
        </CenterContent>
      )}
    </>
  )
}
