import { useQuery } from '@tanstack/react-query'

import HTTPClient from '../..'

export const listMonitorsEventsAPI = async (props) => {
  let endpoint = `monitor/${props.id}/events`

  if (props.dateRange?.start && props.dateRange?.end) {
    const { start, end } = props.dateRange;
    endpoint += `?fromDate=${start}&toDate=${end}`
  }

  const { data } = await HTTPClient.get(endpoint)
  return data || []
}

export const useListSavedEvents = (props) =>
  useQuery(
    ['list-saved-events', props],
    () => listMonitorsEventsAPI(props),
    {
      refetchOnWindowFocus: false,
    }
  )
