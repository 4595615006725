import { PropositionEntity, Pagination } from '@services/nomos_api/entities'
import { createContext } from 'use-context-selector'

export type Result = {
  results: PropositionEntity[]
  pagination: Pagination
}

export type PropositionsPanelContextType = {
  id: string | undefined
}

export const PropositionsPanelContext =
  createContext<PropositionsPanelContextType | null>(null)
