import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { PagiantedType } from '@services/nomos_api/entities/paginated'
import { FindSavedBucketsFilterProps } from '@services/nomos_api/resources/saved_buckets/getAll'
import { createContext } from 'use-context-selector'

export type SavedBucketsContextType = {
  isLoading: boolean
  isRemoving: boolean
  filters: FindSavedBucketsFilterProps
  data: PagiantedType<SavedBucketEntity>
  selecteds: string[]
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleToggleAll: () => void
  handleDeleteSavedBucket: (ids: string[]) => void
  handleToggleSelection: (savedBucket: SavedBucketEntity) => void
  handleFilterChange: (filter: FindSavedBucketsFilterProps) => void
}

export const SavedBucketsContext = createContext<SavedBucketsContextType>(
  {} as SavedBucketsContextType
)
