import { StepSettings } from '../StepSettings'
import { SaveSearchContext } from '../../context'
import { StepIntroduction } from '../StepIntroduction'
import { StepNotification } from '../StepNotification'
import { useContextSelector } from 'use-context-selector'

export function StepSelector() {
  const step = useContextSelector(SaveSearchContext, (s) => s.step)

  if (step === 'introduction') return <StepIntroduction />
  if (step === 'settings') return <StepSettings />
  if (step === 'notification') return <StepNotification />

  return <div />
}
