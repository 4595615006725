import { ReactNode } from 'react'
import colors from '@theme/colors'
import { TooltipWrapper } from './TooltipHelper.styles'
import { HelpOutlineRounded } from '@mui/icons-material'
import { TooltipProps } from '@mui/material'

type TooltipHelperProps = {
  color?: string
  children: NonNullable<ReactNode>
} & Partial<Pick<TooltipProps, 'placement'>>

export default function TooltipHelper({
  color,
  children,
  placement,
}: TooltipHelperProps) {
  return (
    <TooltipWrapper
      data-testid="tooltip-helper"
      title={children}
      placement={placement || 'right'}
      arrow
    >
      <HelpOutlineRounded
        fontSize="small"
        htmlColor={color || colors.scale.gray30}
      />
    </TooltipWrapper>
  )
}
