/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { TagConnector, TagLabel } from './KeywordRender.styles'
import _ from 'lodash'

type KeywordRenderProps = {
  value: Partial<AdvancedSearchType>
  envolve?: [string, string]
  tiny?: boolean
  inline?: boolean
  keyword?: string
}

function KeywordRender({
  value,
  tiny,
  envolve,
  inline,
  keyword,
}: KeywordRenderProps): JSX.Element {
  // add invidividual v1 keyword to advanced keywords
  // if it exists on document
  if (keyword && !value?.keywords?.or?.includes(keyword)) {
    if (!value.keywords?.or) _.set(value, 'keywords.or', [])
    value.keywords?.or.unshift(keyword)
  }

  const components: JSX.Element[] = []

  if (envolve) components.push(<TagLabel tiny={tiny}>{envolve[0]}</TagLabel>)

  for (const [index, keyword] of (value?.keywords?.or || []).entries()) {
    if (index > 0) components.push(<TagConnector tiny={tiny}>OU</TagConnector>)
    components.push(<TagLabel tiny={tiny}>{keyword}</TagLabel>)
  }

  if (
    ((!envolve && components.length > 0) ||
      (envolve && components.length > 1)) &&
    (value?.keywords?.and || []).length > 0
  ) {
    components.push(<TagConnector tiny={tiny}>E</TagConnector>)
  }

  for (const [index, keyword] of (value?.keywords?.and || []).entries()) {
    if (index > 0) components.push(<TagConnector tiny={tiny}>E</TagConnector>)
    components.push(<TagLabel tiny={tiny}>{keyword}</TagLabel>)
  }

  for (const keyword of value?.keywords?.not || []) {
    components.push(
      <TagConnector tiny={tiny}>
        {((!envolve && components.length > 0) ||
          (envolve && components.length > 1)) &&
          'E '}
        NÃO
      </TagConnector>
    )
    components.push(<TagLabel tiny={tiny}>{keyword}</TagLabel>)
  }

  if (envolve) components.push(<TagLabel tiny={tiny}>{envolve[1]}</TagLabel>)

  if (inline) return <>{components}</>
  return <div>{components}</div>
}

export default KeywordRender
