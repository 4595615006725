/* eslint-disable no-unused-vars */
import { useContext, useCallback } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { CreateMonitorContext } from './context'
import { ContainerScroll } from '@components/atoms'
import { CardRadio } from '@components/organisms/Cards'
import { IconButton, Stack, Typography } from '@mui/material'
import { Button as Button, Input as Input } from '@components/atoms'

import {
  Container,
  Content,
  Header,
  Title,
  TextLabel,
  IconClose,
  ContentAction,
} from './styles'

export function CreateMonitorDrawerView(props) {
  const { isLoading, formikPainel } = useContext(CreateMonitorContext)
  const { onClose } = props
  const { closeDrawer } = useDrawer()

  const handleClose = useCallback(() => {
    if (onClose) onClose()
    closeDrawer()
  }, [onClose, closeDrawer])

  return (
    <Container>
      <Content>
        <Header>
          <Title variant="$font-title-3">Criar Novo Painel</Title>
          <IconButton onClick={() => handleClose()}>
            <IconClose />
          </IconButton>
        </Header>
        <ContainerScroll pt={15} pr={5} auto="true" style={{ width: "100%"}}>
          <Stack direction="column" spacing={3}>
            <Stack direction="column">
              <TextLabel variant="$font-body-1">Crie um novo painel</TextLabel>
              <Typography variant="$font-body-base" color="$color-text-secondary">
                Crie um painel e passe a monitorar proposições, agendas e stakeholders
                de acordo com seu contexto
              </Typography>
            </Stack>
            <Input
              id="panel-name"
              label="Nome do Painel"
              name="name"
              error={
                formikPainel.touched.name &&
                Boolean(formikPainel.errors.name)
              }
              helpertext={
                (formikPainel.touched.name && formikPainel.errors.name) ||
                'Nomeie o painel para criá-lo'
              }
              disabled={isLoading}
              onBlur={formikPainel.handleBlur}
              value={formikPainel.values.name}
              onChange={formikPainel.handleChange}
            />

            <Stack direction="column">
              <Typography variant="$font-body-1" color="$color-neutral-gray-100">
                Tipo de Permissão
              </Typography>
              <Typography variant="$font-body-base" color="$color-text-secondary">
                Defina o tipo de permissão do painel
              </Typography>
            </Stack>
                
            <CardRadio
              name="public"
              disabled={isLoading}
              value={formikPainel.values.public}
              onChange={(_e, value) => { formikPainel.setFieldValue('public',value) }}
              options={[
                {
                  title: 'Público',
                  value: "true",
                  description: (
                    <Typography variant="$font-body-3" color="$color-text-secondary">
                      Todos os membros visualizam e editam o painel
                    </Typography>
                  ),
                },
                {
                  title: 'Privado',
                  value: "false",
                  description: (
                    <Typography variant="$font-body-3" color="$color-text-secondary">
                      Você decide quem terá acesso ao painel
                    </Typography>
                  ),
                },
              ]}
            />
          </Stack>
        </ContainerScroll>
        <ContentAction>
          <Button
            fullWidth
            text="Salvar"
            size="large"
            id="save-panel-btn"
            loading={isLoading}
            disabled={!(formikPainel.isValid && formikPainel.dirty) || isLoading}
            onClick={formikPainel.handleSubmit}
          />
        </ContentAction>
      </Content>
    </Container>
  )
}
