import { useState } from 'react'
import { columns } from './columns'
import { Input } from '@components/atoms'
import { Table } from '@components/organisms'
import { useDebounce } from '@hooks/useDebounce'
import { useContextSelector } from 'use-context-selector'
import { OrganDetailContext } from '@pages/private/OrganDetail/context'
import { useGetAllVotings } from '@services/nomos_api/resources/votings/read'

import { Container, FiltersContainer, TableContainer } from './styles'

export default function Votings() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)
  if (!organ) return null

  const [page, setPage] = useState(0)
  const [textSeach, setTextSearch] = useState('')
  const [approved, setApproved] = useState('')
  const keyword = useDebounce(textSeach, 500)

  const { data, isFetching } = useGetAllVotings({
    page: page + 1,
    limit: 10,
    filters: {
      organ: organ.id,
      q: keyword,
      approved,
    },
  })

  return (
    <Container>
      <FiltersContainer>
        <Input
          size="small"
          type="search"
          label="Busca"
          value={textSeach}
          sx={{ width: 300 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextSearch(e.target.value)
          }
          placeholder="Buscar por proposição"
        />

        <Input
          size="small"
          type="checkbox"
          label="APROVADO"
          value={textSeach}
          checked={approved === 'true'}
          sx={{ width: 300 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setApproved(e.target.checked ? 'true' : '')
          }
        />

        <Input
          size="small"
          type="checkbox"
          label="REJEITADO"
          value={textSeach}
          checked={approved === 'false'}
          sx={{ width: 300 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setApproved(e.target.checked ? 'false' : '')
          }
        />
      </FiltersContainer>

      <TableContainer>
        <Table
          paginationMode="server"
          filterMode="server"
          loading={isFetching}
          rowsPerPageOptions={[5]}
          pagination
          rows={data?.results || []}
          columns={columns}
          page={page}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 100}
          pageSize={data?.pagination?.limit}
          rowCount={data?.pagination?.total || 0}
          onPageChange={setPage}
        />
      </TableContainer>
    </Container>
  )
}
