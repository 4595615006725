import { Box, Typography, styled } from '@mui/material'
import { Close } from '@mui/icons-material'

export const IconClose = styled(Close)`
  font-size: 14px;
  color: ${(props) => props.theme.palette['$color-border-dark']};
`
export const Wrapper = styled(Box)`
  display: flex;
  height: calc(100% - 42px);
  flex-direction: column;
  padding: 24px 2px 0px 0px;
  justify-content: flex-end;
  background-color: #f9fafc;
  flex-shrink: 0;
  & .muidrawer-paper {
    box-sizing: border-box;
  }
`

export const FilterContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 26px;
  padding: 0 16px 24px;
  overflow-y: auto;
  overflow-x: none;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
    border-radius: 8px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ced2db;
    border-radius: 8px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a9b0bf;
    border-radius: 8px;
  }
`
export const FilterTitle = styled(Typography)`
  margin-bottom: 24;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #24365e;
`
