import React from 'react'
import { useContextSelector } from 'use-context-selector'
import { ImportPropositionModalContext } from './context'
import { StateModalStandard } from './components/StateModalStandard'
import { StateModalUpload } from './components/StateModalUpload'
import { StateModalError } from './components/StateModalError'

export function ImportPropositionModalView(): JSX.Element {
  const file = useContextSelector(ImportPropositionModalContext, (s) => s.file)

  const affectedRows = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.affectedRows
  )

  if (affectedRows && file) return <StateModalError />

  if (file) return <StateModalUpload />

  return <StateModalStandard />
}
