import { IconButton, Stack, Typography } from '@mui/material'
import { useModal } from '@contexts/Modal'
import { Close } from '@mui/icons-material'
import { Container, Content } from './styles'

type EventThemeModalProps = {
  themeDescription: string
}

export default function EventThemeModal({
  themeDescription,
}: EventThemeModalProps) {
  const { closeModal } = useModal()

  return (
    <Container>
      <Stack
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Descrição completa do evento
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Veja abaixo mais detalhes deste evento
          </Typography>
        </Stack>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Stack>
      <Content>
        <Typography
          whiteSpace="break-spaces"
          variant="$font-body-base"
          color="$color-text-secondary"
          fontWeight={500}
        >
          {themeDescription}
        </Typography>
      </Content>
    </Container>
  )
}
