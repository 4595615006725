import { useMutation } from '@tanstack/react-query'

import HTTPClient from '../..'

export const updateMonitorAPI = async (id, data) => {
  const response = await HTTPClient.put(`/monitor/${id}`, data)

  return response?.data
}

export const useUpdateMonitor = () =>
  useMutation(['update-monitor'], updateMonitorAPI)
