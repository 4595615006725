import { SVGProps } from 'react'

function PushPin({
  width = 28,
  height = 28,
  fill = 'none',
  color = '#606162',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8019 5.4165H9.33301C8.91879 5.4165 8.58301 5.08072 8.58301 4.6665C8.58301 4.25229 8.91879 3.9165 9.33301 3.9165H11.6667H16.3333H18.6663C19.0806 3.9165 19.4163 4.25229 19.4163 4.6665C19.4163 5.08072 19.0806 5.4165 18.6663 5.4165H17.1981L18.128 11.9257C18.5925 12.1736 19.0297 12.4654 19.4299 12.7989C20.8934 14.0185 21.75 15.7051 21.75 17.4998C21.75 17.9141 21.4142 18.2498 21 18.2498H14L7 18.2498C6.58579 18.2498 6.25 17.9141 6.25 17.4998C6.25 15.7051 7.10659 14.0185 8.57011 12.7989C8.97035 12.4654 9.40748 12.1736 9.87203 11.9257L10.8019 5.4165ZM12.3171 5.4165L11.3015 12.526C11.2658 12.7759 11.1067 12.9911 10.8783 13.0986C10.3851 13.3306 9.93142 13.617 9.53039 13.9512C8.59322 14.7322 8.00963 15.7165 7.8189 16.7498L14 16.7498H20.1811C19.9904 15.7165 19.4068 14.7322 18.4696 13.9512C18.0686 13.617 17.6149 13.3306 17.1217 13.0986C16.8933 12.9911 16.7342 12.7759 16.6985 12.526L15.6829 5.4165H12.3171Z"
        fill={color}
      />
      <path
        d="M14 17.5V23.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PushPin
