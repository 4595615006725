import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from '@hooks/useDebounce'
import { KeyValuePair, PaginationKeyValuePair } from '@utils/buckets/type'
import {
  SideFilterSelectContextType,
  SideFilterSelectControllerProps,
} from './SideFilterSelectTypes'
import { SideFilterSelectContext } from './SideFilterSelectContext'
import produce from 'immer'

export function SideFilterSelectController({
  filter,
  search,
  children,
  selectedBucket,
  handleUpdateSearch,
}: SideFilterSelectControllerProps): JSX.Element {
  // define references
  const block = useRef<boolean>(false)
  const increment = useRef<boolean>(false)
  const pages = useRef<number | undefined>(undefined)

  // define states
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [options, setOptions] = useState<KeyValuePair[]>([])
  const fieldName = selectedBucket
    ? `buckets.${selectedBucket}.filter.${filter.attr}`
    : filter.attr
  const [selecteds, setSelecteds] = useState(_.get(search, fieldName, []))
  const dispatchTextFilter = useDebounce(textFilter, 500)
  const dispatchFilter = useDebounce(selecteds, 500)

  // define a function to toggle all options
  const handleToggleAll = () => {
    if (options.length === selecteds.length) setSelecteds([])
    else setSelecteds(options.map((option) => option.key))
  }

  // define a function to clear all selected options
  const handleRemoveAll = () => setSelecteds([])

  // define a function to append an options
  // always it is checked
  const handleAddSelectOption = (key: string) => {
    setSelecteds(
      produce(selecteds, (draft: string[]) => {
        const index = draft.findIndex((item) => item === key)
        if (index >= 0) draft.splice(index, 1)
        else draft.push(key)
      })
    )
  }

  // define a function to identify if
  // results is paginated or not, parse
  // it and then update state options
  const handleParseOptions = useCallback(
    (props: PaginationKeyValuePair | KeyValuePair[]) => {
      if (_.has(props, 'results')) {
        pages.current = _.get(props, 'pagination.pages', 1)
        if (_.get(props, 'pagination.page') === 1) {
          setOptions(_.get(props, 'results', []))
        } else {
          setOptions(options.concat(_.get(props, 'results', [])))
        }
      } else if (props instanceof Array) {
        setOptions(props as KeyValuePair[])
      }
    },
    [options, page, dispatchTextFilter]
  )

  // define a function to load options
  // * if filter has pagination enabled
  // then pagination rules will be applied
  const handleLoadPage = (newPage: number, textSearch?: string) => {
    if (block.current === true) return
    setPage(newPage)
    setLoading(true)
    block.current = true

    filter
      .options({
        text: filter.enabled_online_filter ? textSearch : undefined,
        limit: filter.enabled_pagination ? 10 : undefined,
        page: filter.enabled_pagination ? newPage || 1 : undefined,
      })
      .then(handleParseOptions)
      .finally(() => {
        block.current = false
        setLoading(false)
      })
  }

  // define a function that increments
  // pagination if pagination is enabled
  // * only increment page if:
  // + it is enabled on this filter;
  // + total pages results is defined;
  // + page does not reaches the total pages;
  const handleIncrementPage = useCallback(() => {
    // return if is already incrementing
    if (increment.current === true) return

    // enable increment scroll
    // to prevent multiple event fires
    // when reaches the end of options list
    increment.current = true
    // increment page if necessary
    if (
      filter.enabled_pagination &&
      pages.current !== undefined &&
      (page || 0) < pages.current
    ) {
      handleLoadPage((page || 0) + 1, dispatchTextFilter)
    }

    // disable increment flag
    // to allow future scrolls
    setTimeout(() => {
      increment.current = false
    }, 1000)
  }, [options, page, dispatchTextFilter])

  // define a function to emit an event to props
  // when a new filter is processed to be applied
  useEffect(() => {
    if (expanded && handleUpdateSearch) {
      handleUpdateSearch({
        [fieldName]: selecteds.length === 0 ? undefined : selecteds,
      })
    }
  }, [dispatchFilter])

  // <!-- 1 - run the first fetch to load options -->
  // <!-- 2 - reset page always text changes -->
  useEffect(() => {
    if (expanded) handleLoadPage(1, dispatchTextFilter)
  }, [expanded, dispatchTextFilter])

  // define accessible states provided to childrens
  const state: SideFilterSelectContextType = useMemo(
    () => ({
      filter,
      loading,
      options,
      expanded,
      fieldName,
      selecteds,
      dispatchTextFilter,
      textFilter,
      setTextFilter,
      setExpanded,
      handleRemoveAll,
      handleToggleAll,
      handleIncrementPage,
      handleAddSelectOption,
    }),
    [
      filter,
      loading,
      options,
      expanded,
      fieldName,
      selecteds,
      dispatchTextFilter,
      textFilter,
      setTextFilter,
      setExpanded,
      handleRemoveAll,
      handleToggleAll,
      handleIncrementPage,
      handleAddSelectOption,
    ]
  )

  return (
    <SideFilterSelectContext.Provider value={state}>
      {children}
    </SideFilterSelectContext.Provider>
  )
}
