import { styled } from '@mui/material/styles'
import { TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import { theme } from '@theme'

export const TabSelectorTabs = styled((props) => (
  <TabList
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  '& .MuiTabs-root ': { display: 'flex' },
  '& .MuiTabs-flexContainer': {
    height: 46,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
})

export const TabSelectorTab = styled((props) => (
  <Tab disableRipple {...props} />
))({
  opacity: 1,
  fontSize: 14,
  marginRight: 0,
  fontWeight: 400,
  color: theme.palette.muted.main,
  height: '46px',
  minWidth: 'auto',
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
})
