import { SVGProps } from 'react'

function KeyIcon({
  width = 16,
  height = 16,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} fill={fill}>
      <path
        d="M11.889 5.667a1.55 1.55 0 0 0-.456-1.1 1.55 1.55 0 0 0-1.1-.456m0 6.222A4.667 4.667 0 1 0 5.71 6.294c.045.338.068.507.052.614a.663.663 0 0 1-.09.269c-.053.094-.146.187-.332.373l-3.975 3.974c-.134.135-.201.202-.25.28a.777.777 0 0 0-.093.226C1 12.119 1 12.213 1 12.404v1.352c0 .435 0 .653.085.82a.777.777 0 0 0 .34.34c.166.084.384.084.82.084H4.11v-1.556h1.556V11.89h1.555L8.45 10.66c.186-.186.279-.279.373-.331a.662.662 0 0 1 .27-.091c.106-.016.275.007.613.052.205.028.415.042.627.042Z"
        stroke="#606162"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default KeyIcon
