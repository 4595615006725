import { SigninEmailView } from './view'
import SignupController from './controller'

export default function SigninEmail() {
  return (
    <SignupController>
      <SigninEmailView />
    </SignupController>
  )
}
