import { ICS } from 'ics-tsc'

const icsCalendar = ({
  startDate = new Date(),
  endDate = new Date(),
  filename = 'invite',
  summary = '',
  description = '',
}) => {
  const timestamp = new Date().getTime()
  const ics = new ICS(
    filename,
    timestamp,
    startDate.getTime(),
    endDate.getTime(),
    summary,
    description
  )

  ics.exportIcs()
}

export { icsCalendar }
