import {
  Box,
  Snackbar,
  Typography,
  AlertColor,
  IconButton,
} from '@mui/material'

import { useSnackbar } from '@contexts/Snackbar'
import AlertInfoSVG from '@assets/icons/alert-info.svg'
import AlertErrorSVG from '@assets/icons/alert-error.svg'
import AlertSuccessSVG from '@assets/icons/alert-success.svg'
import AlertWarningSVG from '@assets/icons/alert-warning.svg'
import {
  AlertClose,
  AlertContent,
  AlertWrapper,
  IconClose,
} from './Snackbar.styles'

const icons: Record<AlertColor, string> = {
  info: AlertInfoSVG,
  error: AlertErrorSVG,
  success: AlertSuccessSVG,
  warning: AlertWarningSVG,
}

function SnackbarComponent() {
  const { snackbar, closeSnackbar } = useSnackbar()
  const { show, type, title, message, color, position, action } = snackbar

  return (
    <Box>
      <Snackbar
        open={show}
        autoHideDuration={10000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: position || 'right',
        }}
      >
        <AlertWrapper
          severity={type}
          variant="outlined"
          onClose={undefined}
          icon={<img src={icons[type]} alt={type} width={24} height={24} />}
        >
          <AlertContent>
            <Typography variant="$font-title-5" sx={{ color }}>
              {title}
            </Typography>
            {!!message && (
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                {message}
              </Typography>
            )}
            {action ? (
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={action.onClick}
                variant="$font-body-base"
                color="$color-action-link"
              >
                {action.text}
              </Typography>
            ) : null}
          </AlertContent>
          <AlertClose>
            <IconButton size="small" onClick={() => closeSnackbar()}>
              <IconClose />
            </IconButton>
          </AlertClose>
        </AlertWrapper>
      </Snackbar>
    </Box>
  )
}

export default SnackbarComponent
