/* eslint-disable no-console */
import { Stack } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { SaveInMonitorContext } from './context'
import { MonitoringContext } from '../../context'
import { useCallback, useEffect, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { Button, ContainerScroll } from '@components/atoms'
import { MonitorFormOrganUpdates } from '../form-organ-updates'
import { MonitoringFormManagePanels } from '../form-manage-panels'
import { MonitorFormPropositionTags } from '../form-proposition-tags'
import { MonitorFormPropositionPositioning } from '../form-proposition-positioning'
import { PropositionStatusNotification } from '../form-proposition-status-notification'
import { MonitorFormOrganPropositionProceeding } from '../form-organ-proposition-proceeding'

import { DrawerBottom } from './styles'
import { MonitorFormPropositionPriority } from '../form-proposition-priority'
import { FormProps } from './types'
import { PropositionsMonitorConfirmation } from '../propositions-confirmation'

export function SaveInMonitorView() {
  const { openDrawer } = useDrawer()

  // define the use state for PropositionStatusNotificationView
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [enabledNotification, setEnabledNotification] = useState<boolean>(true)
  const [alertChannel, setAlertChannel] = useState<string[]>(['email'])
  const [alertFrequency, setAlertFrequency] = useState<string>('realtime')
  const [propositionStatus, setPropositionStatus] = useState<string[]>([])

  const refInputMonitor = useContextSelector(
    SaveInMonitorContext,
    (s) => s.refInputMonitor
  )
  const ids = useContextSelector(MonitoringContext, (s) => s.id)?.split(',')

  const origin = useContextSelector(MonitoringContext, (s) => s.origin)
  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )
  const isSavingBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.isSavingBucket
  )
  const handleSubmit = useContextSelector(
    SaveInMonitorContext,
    (s) => s.handleSubmit
  )

  const house = useContextSelector(MonitoringContext, (s) => s.house)

  useEffect(() => {
    if (enabledNotification) {
      formSavedBucket.setFieldValue('alert.destinations', alertChannel)
      formSavedBucket.setFieldValue('alert.frequency', alertFrequency)
      formSavedBucket.setFieldValue('situations', propositionStatus)
      formSavedBucket.setFieldValue('muteuser', false)
    } else {
      formSavedBucket.setFieldValue('muteuser', true)
    }
  }, [enabledNotification, alertChannel, alertFrequency, propositionStatus])

  // eslint-disable-next-line consistent-return
  const handleConfirmationStep = useCallback(
    async (form: Partial<FormProps>) => {
      openDrawer(
        <PropositionsMonitorConfirmation
          onSubmit={() => {
            handleSubmit(form)
          }}
        />,
        {
          lockscroll: true,
        }
      )
    },
    [openDrawer, handleSubmit]
  )

  // eslint-disable-next-line consistent-return
  const handleNextStep = useCallback(
    async (monitors: string[]) => {
      if (!formSavedBucket.isValid && formSavedBucket.dirty) {
        formSavedBucket.setFieldError('alert', undefined)
      } else {
        openDrawer(
          <PropositionStatusNotification
            monitors={monitors}
            formSavedBucket={formSavedBucket}
            handleSubmit={
              origin === 'propositions' && ids.length > 1
                ? handleConfirmationStep
                : handleSubmit
            }
            enabledNotification={enabledNotification}
            setEnabledNotification={setEnabledNotification}
            alertChannel={alertChannel}
            setAlertChannel={setAlertChannel}
            alertFrequency={alertFrequency}
            setAlertFrequency={setAlertFrequency}
            propositionStatus={propositionStatus}
            setPropositionStatus={setPropositionStatus}
            house={house}
            isSavingBucket={isSavingBucket}
          />,
          {
            lockscroll: true,
          }
        )
      }
    },
    [formSavedBucket, openDrawer, handleSubmit]
  )

  const handleNext = () => {
    setIsLoading(true)
    refInputMonitor.current
      ?.createPendingMonitors()
      .then((monitors) => {
        if (origin === 'propositions') {
          handleNextStep(monitors.map((i) => i.key))
        } else {
          handleSubmit({ monitors: monitors.map((i) => i.key) })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Stack
      flex={1}
      spacing={3}
      width="100%"
      direction="column"
      overflow="hidden"
    >
      <ContainerScroll auto="true">
        <Stack direction="column" width="100%" spacing={5}>
          <MonitoringFormManagePanels />
          {origin === 'propositions' && <MonitorFormPropositionPriority />}
          {origin === 'propositions' && <MonitorFormPropositionPositioning />}
          {origin === 'propositions' && <MonitorFormPropositionTags />}
          {origin === 'organs' && <MonitorFormOrganUpdates />}
          {origin === 'organs' && <MonitorFormOrganPropositionProceeding />}
        </Stack>
      </ContainerScroll>

      <DrawerBottom>
        {origin === 'propositions' ? (
          <Button
            text="Próximo"
            size="large"
            variant="outlined"
            id="go-to-next-step"
            onClick={handleNext}
            loading={isLoading}
            disabled={!(formSavedBucket.isValid && formSavedBucket.dirty)}
          />
        ) : (
          <Button
            size="large"
            loading={isLoading || isSavingBucket}
            text="Adicionar ao Painel"
            data-cy="btn-save-bucket-on-monitor"
            onClick={handleNext}
            disabled={!(formSavedBucket.isValid && formSavedBucket.dirty)}
          />
        )}
      </DrawerBottom>
    </Stack>
  )
}
