import { OrgansContext } from '../../context'
import { NoDataIcon } from '@components/icons'
import { Pagination } from '@components/molecules'
import { CardOrgan } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import { CircularProgress, Typography } from '@mui/material'
import { pageview } from '@utils/functions/pageview/pageview'

import { CenterContent, CardsContainer } from './styles'

function Results() {
  const organs = useContextSelector(OrgansContext, (s) => s.organs)

  return (
    <Typography variant="$font-body-base" color="$color-text-secondary">
      Exibindo {pageview(organs?.pagination || {})} resultados
    </Typography>
  )
}

export default function OrgansList() {
  const isLoading = useContextSelector(OrgansContext, (s) => s.isLoading)

  const organs = useContextSelector(OrgansContext, (s) => s.organs)

  const setPage = useContextSelector(OrgansContext, (s) => s.setPage)

  const setLimit = useContextSelector(OrgansContext, (s) => s.setLimit)

  return (
    <>
      <CardsContainer>
        <Results />

        {organs?.results?.map((organ) => {
          return <CardOrgan key={organ.id} organ={organ} />
        })}

        <Pagination
          pagination={organs?.pagination}
          handleUpdatePage={setPage}
          handleUpdateLimit={setLimit}
        />
      </CardsContainer>

      <CenterContent>
        {isLoading && (
          <CircularProgress data-testid="loading" color="primary" size={15} />
        )}
      </CenterContent>

      <CenterContent>
        {!isLoading && organs?.results?.length === 0 && (
          <NoDataIcon
            height={36}
            text="Não foram encontrados orgãos para a busca realizada"
          />
        )}
      </CenterContent>
    </>
  )
}
