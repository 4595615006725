import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  height: 1250px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justfy-content: center;
  padding: 0px 10px;
`
