import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { TagEntity } from '@services/nomos_api/entities/tag'

export const findTagsApi = async (
  filterName?: string
): Promise<TagEntity[]> => {
  const query = []
  if (filterName) query.push(`name=${filterName}`)
  const { data } = await HTTPClient.get(`tags?${query.join('&')}`)
  return data
}

export const useFindTags = (filterName?: string) => {
  return useQuery(
    [`find-tags-${filterName || 'default'}`],
    () => findTagsApi(filterName),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
}
