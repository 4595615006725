import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'
import { InputMultiSelectChip } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'
import { SavedOrganUpdatesEnum } from '@enums/SavedOrganUpdatesEnum'
import _ from 'lodash'
import React from 'react'

export function MonitorFormOrganUpdates(): JSX.Element {
  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )

  // load all options
  const options = React.useMemo(() => {
    return Object.keys(SavedOrganUpdatesEnum).map((i) => ({
      key: i,
      label: _.get(SavedOrganUpdatesEnum, i),
    }))
  }, [])

  // handle selected options (all selecteds by default)
  const [selecteds, setSelecteds] =
    React.useState<MultiSelectChipOption[]>(options)

  // update form when selected changes
  React.useEffect(() => {
    formSavedBucket.setFieldValue(
      'trackedUpdates',
      selecteds.map((i) => i.key)
    )
  }, [selecteds])

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <Typography variant="$font-title-5" color="$color-text-secondary">
          Defina as informações que deseja monitorar
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Selecione as informações que deseja ser alertado(a) sempre que a mesma
          for atualizada no Órgão.
        </Typography>
      </Stack>
      <InputMultiSelectChip
        id="organ-updates"
        label="Desejo ser alertado sobre"
        options={options}
        value={selecteds}
        loading={false}
        disabled={false}
        groupAllSelectedOptions="Todas as atualizações"
        onChangeOptions={(_, options) => setSelecteds(options)}
      />
    </Stack>
  )
}
