import { OrgansContext } from './context'
import { useState, useMemo, useCallback } from 'react'

import {
  GetAllOrgansFilters,
  useGetOrgans,
} from '@services/nomos_api/resources/organs/organs'

export type OrgansControllerProps = {
  monitorId?: string
  children: React.ReactChild
}

function OrgansController({ monitorId, children }: OrgansControllerProps) {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const [filters, setFilters] = useState<GetAllOrgansFilters>({
    active: 'true',
  })

  const handleFilterChange = useCallback(
    (filter: GetAllOrgansFilters) => {
      setFilters((prevState) => ({
        ...prevState,
        ...filter,
      }))
    },
    [setFilters]
  )

  const { data, isFetching } = useGetOrgans({
    page,
    limit,
    monitorId,
    filters,
  })

  const store = useMemo(
    () => ({
      isLoading: isFetching,
      organs: data,
      setPage,
      setLimit,
      handleFilterChange,
    }),
    [isFetching, data, setPage, setLimit, handleFilterChange]
  )

  return (
    <OrgansContext.Provider value={store}>{children}</OrgansContext.Provider>
  )
}

OrgansController.defaultProps = {
  monitorId: null,
}

export default OrgansController
