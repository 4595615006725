import { Card } from '@components/atoms'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 66px;
`

export const FiltersContainer = styled.div`
  display: flex;
  gap: 29px;
`

export const TableContainer = styled(Card)`
  padding: 46px;
`
