import { NoticeEntity } from '@services/nomos_api/entities/notice'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getNoticeByIdAPI = async (id: string): Promise<NoticeEntity> => {
  const { data } = await HTTPClient.get(`notices/${id}`)
  return data as NoticeEntity
}

export const useGetNoticeDetails = (id: string) =>
  useQuery(['get-notice-details'], () => getNoticeByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
