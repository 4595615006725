import { useState } from 'react'
import { Container } from './styles'
import { BacenDetailsContext } from '../../context'
import { HighlightedText } from '@components/molecules'
import { Shimmer, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'

function ContentTextComponent() {
  const bacen = useContextSelector(BacenDetailsContext, (s) => s.bacen)
  const keyword = useContextSelector(BacenDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(BacenDetailsContext, (s) => s.isLoading)
  const [highlighted] = useState(keyword || '')

  return (
    <Container direction="column" spacing={3}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={2} flex={1}>
          <Box>
            <Shimmer isLoading={isLoading} height={24} width={80}>
              <Chip
                size="small"
                color="primary"
                label={bacen?.type}
                variant="outlined"
                className="notPrintable"
              />
            </Shimmer>
          </Box>
          <ShimmerText isLoading={isLoading} words={6}>
            <Typography variant="$font-title-3" color="$color-text-primary">
              {bacen?.title}
            </Typography>
          </ShimmerText>

          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Data de publicação:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {formatDate(bacen?.date, 'Á definir')}
                </Typography>
              </Shimmer>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Responsavel:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {bacen?.responsible || 'Não Informado'}
                </Typography>
              </Shimmer>
            </Stack>
            {bacen?.dou && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  Diário Oficial:
                </Typography>
                <Shimmer isLoading={isLoading} height={18} width={80}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {bacen?.dou}
                  </Typography>
                </Shimmer>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <ShimmerText isLoading={isLoading} height={14}>
        <HighlightedText
          text={bacen?.content}
          highlight={highlighted}
          className="print-content"
        />
      </ShimmerText>
    </Container>
  )
}

export default ContentTextComponent
