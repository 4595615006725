import { DouDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function DouDetailsPage() {
  return (
    <SpeechDetailsController>
      <DouDetailsView />
    </SpeechDetailsController>
  )
}

export default DouDetailsPage
