import { Typography } from '@mui/material'
import IconNoData from '@assets/icons/nodata.svg'
import { Wrapper } from './styles'

export function EmptyContent({ message }) {
  return (
    <Wrapper>
      <img src={IconNoData} alt="no data" />
      <Typography variant="$font-body-base" color="$color-text-primary">
        {message}
      </Typography>
    </Wrapper>
  )
}
