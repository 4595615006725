import { EventModalController } from './controller'
import { EventModalProps } from './types'
import { EventModalView } from './view'

export function EventModal(props: EventModalProps) {
  return (
    <EventModalController {...props}>
      <EventModalView {...props} />
    </EventModalController>
  )
}
