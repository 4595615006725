import { ReactNode } from 'react'
import { Alert, IconButton } from '@mui/material'

type AlertActionProps = {
  icon: ReactNode
  children: React.ReactChild
  onClick: () => void
  size: 'small' | 'medium' | 'large'
  type: 'error' | 'info' | 'success' | 'warning'
}

export default function AlertAction({
  type,
  icon,
  onClick,
  children,
  size = 'small',
}: AlertActionProps) {
  return (
    <Alert
      severity={type}
      icon={false}
      action={
        <IconButton
          size={size}
          onClick={onClick}
          data-testid="alert-icon-action-button"
        >
          {icon}
        </IconButton>
      }
    >
      {children}
    </Alert>
  )
}
