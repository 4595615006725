/* eslint-disable camelcase */
import { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { ModalMonitorReportContext } from './context'
import { ModalMonitorReportControllerProps, ReportMonitorType } from './types'
import { validateMonitorReportSchema } from './validate'
import { useReportMonitor } from '@services/nomos_api/resources/reports/monitor'
import { useSnackbar } from '@contexts/Snackbar'
import { useModal } from '@contexts/Modal'
import _ from 'lodash'

export function ModalMonitorReportController({
  children,
  monitorId,
}: ModalMonitorReportControllerProps): JSX.Element {
  const { cleanStack } = useModal()
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const { mutate, isLoading, status, data, error } = useReportMonitor()

  const form = useFormik<ReportMonitorType>({
    initialValues: {
      format: 'pdf',
      monitorId: String(monitorId),
      categories: {},
    },
    validationSchema: validateMonitorReportSchema,
    onSubmit: (payload) => mutate(payload),
  })

  useEffect(() => {
    if (status === 'success' && data) {
      showSnackbarSuccess(
        'Relatório gerado com sucesso',
        'O relatório foi gerado com sucesso e deve abrir em uma nova aba do seu navegador.'
      )
      if (data?.url) {
        const urls = data?.url as Array<string>
        urls.forEach((url) => {
          window.open(url, '_blank')
        })
      }
      cleanStack()
    } else if (status === 'error') {
      showSnackbarError(
        'Erro ao gerar relatório',
        _.get(
          error,
          'response.data.message',
          'Não foi possível gerar o relatório para as configurações fornecidas.'
        )
      )
    }
  }, [status, data])

  const state = useMemo(() => ({ form, isLoading }), [form, isLoading])
  return (
    <ModalMonitorReportContext.Provider value={state}>
      {children}
    </ModalMonitorReportContext.Provider>
  )
}
