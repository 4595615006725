export default function OrganoGov() {
  return (
    <iframe
      title="Organo Gov"
      data-cy="organogov"
      src="https://kumu.io/embed/330fda64b971f1a98ed4af10499c4329?password=Arko"
      frameBorder="0"
      height="100%"
      width="100%"
    />
  )
}
