import { Box, styled } from '@mui/material'

export const SearchHelperContainer = styled(Box)`
  gap: 6px;
  display: flex;
  margin-top: 12px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`
