import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { BacenEntity } from '@services/nomos_api/entities/bacen'

export const getBacenByIdAPI = async (id: string): Promise<BacenEntity> => {
  const { data } = await HTTPClient.get(`bacen/${id}`)
  return data as BacenEntity
}

export const useGetBacenDetails = (id: string) =>
  useQuery(['get-bacen-details'], () => getBacenByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
