import { useState, ChangeEvent } from 'react'
import { Close } from '@mui/icons-material'
import { Card, Grid, Stack, Typography } from '@mui/material'

import { Button } from '@components/atoms'
import { KeyIcon, UsersIcon } from '@components/icons'

import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'

import { MonitorUserPayload } from '@services/nomos_api/entities/monitor'
import { putMonitorUsersApi } from '@services/nomos_api/resources/monitor_users/put'

import { UsersList } from '../UsersList'
import { UserRoles } from '../UserRoles'

import theme from '@theme/colors'
import { ModalContent, ModalToolbar, GroupHeader, Footer } from './styles'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export function AddMonitorUser({
  id,
  refetch,
}: {
  id: string
  refetch: () => void
}) {
  useByPassSubscription()
  const { closeModal } = useModal()

  const [checked, setChecked] = useState<string[]>([])
  const [selectedRole, setSelectedRole] = useState('')
  const [loading, setLoading] = useState(false)

  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const isChecked = event.target.checked

    if (isChecked) {
      setChecked([...checked, value])
    } else {
      const filteredCheckList = checked.filter((item: string) => item !== value)

      setChecked(filteredCheckList)
    }
  }

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value)
  }

  const handleCreate = async () => {
    setLoading(true)
    let payload: MonitorUserPayload[]

    if (selectedRole.length > 0 && checked.length > 0) {
      payload = checked.map((item) => {
        return { userId: item, delete: false, role: selectedRole }
      })
    }

    await putMonitorUsersApi(payload!, id)
      .then(() => {
        showSnackbarSuccess('Usuário adicionado com sucesso')
        refetch()
      })
      .catch((error) => {
        const err = error as { response: { data: { message: string } } }

        showSnackbarError(
          err?.response?.data?.message || 'Erro ao adicionar usuário'
        )
      })
      .finally(() => {
        setTimeout(closeSnackbar, 3000)
        setLoading(false)
      })

    closeModal()
  }

  return (
    <>
      <ModalContent>
        <ModalToolbar>
          <Typography
            variant="$font-body-base"
            fontSize="24px"
            fontWeight={600}
            color={theme.brand.black}
          >
            Adicionar usuário ao Painel
          </Typography>

          <Close onClick={closeModal} />
        </ModalToolbar>

        <Typography
          variant="$font-body-base"
          fontWeight={400}
          color="text.secondary"
          margin="10px 0"
          maxWidth={348}
          lineHeight="21px"
        >
          Defina os usuários desta organização que poderão visualizar e editar
          este painel
        </Typography>

        <Grid container spacing={2} marginTop={4} marginBottom={4}>
          <Grid item xs={4}>
            <Card variant="outlined">
              <Stack minHeight="400px">
                <GroupHeader>
                  <UsersIcon />
                  <Typography
                    variant="$font-body-base"
                    fontWeight={500}
                    color="text.secondary"
                    marginLeft="14px"
                  >
                    Selecione os usuários
                  </Typography>
                </GroupHeader>

                <UsersList handleCheck={handleCheck} />
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={8}>
            <Card variant="outlined">
              <Stack minHeight="400px">
                <GroupHeader>
                  <KeyIcon />
                  <Typography
                    variant="$font-body-base"
                    fontWeight={500}
                    color="text.secondary"
                    marginLeft="14px"
                  >
                    Defina a permissão de acesso
                  </Typography>
                </GroupHeader>

                <UserRoles
                  handleRadioChange={handleRadioChange}
                  selectedRole={selectedRole}
                />
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </ModalContent>
      <Footer>
        <Stack direction="row" spacing={2}>
          <Button
            id="cancel-btn"
            text="Cancelar"
            onClick={closeModal}
            variant="outlined"
          />

          <Button
            id="edit-panel-confirm-btn"
            text="Salvar"
            onClick={handleCreate}
            disabled={selectedRole.length === 0}
            loading={loading || undefined}
          />
        </Stack>
      </Footer>
    </>
  )
}
