/* eslint-disable react/no-array-index-key */
import _ from 'lodash'
import { Wrapper } from './HighlightedText.styles'

function HighlightedText(props) {
  const { text = '', highlight = '' } = props

  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
  const parts = text.split(regex)

  const TextBox = !highlight.trim() ? (
    <span>{text}</span>
  ) : (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <b key={i}>{part}</b> : <span key={i}>{part}</span>
        )}
    </span>
  )

  return <Wrapper {...props}>{TextBox}</Wrapper>
}

export default HighlightedText
