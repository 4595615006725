import { SidePage } from '@components/templates'
import { SideContent } from './containers/SideContent/SideContent'
import { SideMenu } from './containers/SideMenu/SideMenu'
import { useContextSelector } from 'use-context-selector'
import { MyAccountContext } from './context'

export function MyAccountView() {
  const slim = useContextSelector(MyAccountContext, (context) => context.slim)

  return (
    <SidePage
      id="my-account-page"
      sideComponent={<SideMenu />}
      leftVisible={!slim}
      isTransparent
    >
      <SideContent />
    </SidePage>
  )
}
