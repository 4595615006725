/* eslint-disable no-unused-vars */
import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { StepContainer } from '../../styles'
import { SaveSearchContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { SaveSearchHeader } from '../../components/SaveSearchHeader'
import { SavedSearchFormNotification } from '../../components/FormNotification'

export function StepNotification() {
  const form = useContextSelector(SaveSearchContext, (s) => s.form)
  const search = useContextSelector(SaveSearchContext, (s) => s.search)
  const savingMonitorSearch = useContextSelector(
    SaveSearchContext,
    (s) => s.savingMonitorSearch
  )

  const handleSubmit = useContextSelector(
    SaveSearchContext,
    (s) => s.handleSubmit
  )

  const handleUpdateStep = useContextSelector(
    SaveSearchContext,
    (s) => s.handleUpdateStep
  )

  const handlePrevStep = React.useCallback(() => {
    handleUpdateStep('settings')
  }, [])

  return (
    <StepContainer data-testid="notification-step">
      <SaveSearchHeader onClickBack={handlePrevStep} />
      <SavedSearchFormNotification
        form={form}
        availableBuckets={search.buckets}
      />
      <LoadingButton
        size="large"
        data-testid="save-btn"
        variant="contained"
        color="primary"
        sx={{ p: '12px 0', mt: 3 }}
        onClick={handleSubmit}
        loading={savingMonitorSearch}
        disabled={!(form.isValid && form.dirty)}
      >
        <Typography variant="body1" color="common.white">
          Salvar
        </Typography>
      </LoadingButton>
    </StepContainer>
  )
}
