import { isMobile } from 'react-device-detect'

export function useWhatsapp() {
  return {
    handleSendMessage: (number: string, message: string) => {
      if (isMobile) {
        const URL = 'https://wa.me'
        const cleanNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '')
        const url = [`${URL}/${cleanNumber}`]
        if (message) url.push(`?text=${encodeURI(message)}`)
        window.open(url.join(''))
      } else {
        const cleanNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '')
        const url = [
          'https://web.whatsapp.com/send?type=phone_number&app_absent=0',
        ]
        url.push(`&phone=${cleanNumber}`)
        if (message) url.push(`&text=${encodeURI(message)}`)
        window.open(url.join(''), 'wind1')
      }
    },
  }
}
