import { Stack, Typography } from '@mui/material'
import { HiddenInput, Radio, Touchable } from './InputRadio.styles'
import { useEffect, useRef } from 'react'

type InputRadioProps = {
  name: string
  value?: string
  spacing?: number
  size?: '12' | '16'
  options: Record<string, string>
  onChange?: (value: string | undefined) => void
  outlined?: boolean
}

function InputRadio({
  name,
  value,
  options,
  outlined,
  onChange,
  size = '16',
  spacing = 3,
}: InputRadioProps): JSX.Element {
  const optionsRef = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    optionsRef.current = optionsRef.current.slice(
      0,
      Object.keys(options).length
    )
  }, [options])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const index in optionsRef.current) {
      if (optionsRef.current[index].value === value) {
        optionsRef.current[index].checked = true
      }
    }
  }, [value])

  return (
    <Stack direction="row" spacing={spacing}>
      {Object.keys(options).map((option, i) => {
        const key = `form-${name}-${option}`
        return (
          <Touchable
            key={key}
            htmlFor={key}
            outlined={outlined ? 'true' : 'false'}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <div>
                <HiddenInput
                  id={key}
                  name={name}
                  type="radio"
                  value={option}
                  ref={(el) => {
                    optionsRef.current[i] = el as HTMLInputElement
                  }}
                  onChange={(e) => onChange?.(e.target.value)}
                />
                <Radio size={size} />
              </div>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                {options[option]}
              </Typography>
            </Stack>
          </Touchable>
        )
      })}
    </Stack>
  )
}

InputRadio.defaultProps = {
  value: undefined,
  onChange: undefined,
}

export default InputRadio
