import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { ModalStandard } from '@components/molecules'
import { CardRadio } from '@components/organisms/Cards'
import {
  NomosProductCodeEnum,
  NomosProductDesc,
  NomosProductLabel,
} from '@enums/NomosProductEnum'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

export function PurchaseSeatModalView(): JSX.Element {
  const [product, setProduct] = useState<NomosProductCodeEnum>(
    NomosProductCodeEnum.MONITOR_PRO
  )
  return (
    <ModalStandard title="Opções de assento" maxWidth={500}>
      <Stack direction="column" spacing={3}>
        <Typography variant="$font-body-1" color="$color-text-secondary">
          Selecione as opções desejadas para adquirir assentos a novos usuários
        </Typography>
        <CardRadio
          name="frequency"
          value={product}
          onChange={(_e, value) => setProduct(value as NomosProductCodeEnum)}
          options={[
            {
              value: NomosProductCodeEnum.MONITOR_PRO,
              title: NomosProductLabel[NomosProductCodeEnum.MONITOR_PRO],
              description: NomosProductDesc[NomosProductCodeEnum.MONITOR_PRO],
            },
            {
              value: NomosProductCodeEnum.MONITOR_LIGHT,
              title: NomosProductLabel[NomosProductCodeEnum.MONITOR_LIGHT],
              description: NomosProductDesc[NomosProductCodeEnum.MONITOR_LIGHT],
            },
          ]}
        />
        <Stack direction="row" justifyContent="flex-end">
          <WhatsAppPaymentLink
            size="medium"
            text="Confirmar"
            title="Confirmar"
            method={product}
          />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
