import { SVGProps } from 'react'

function PlusUserIcon({
  width = 20,
  height = 20,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33334 16.6667V15.8333C3.33334 13.5321 5.19882 11.6667 7.50001 11.6667H10.625M14.6129 11.6371V14.5833M14.6129 14.5833V17.5296M14.6129 14.5833H17.5592M14.6129 14.5833H11.6667M12.5 5.83333C12.5 7.67428 11.0076 9.16667 9.16668 9.16667C7.32573 9.16667 5.83334 7.67428 5.83334 5.83333C5.83334 3.99238 7.32573 2.5 9.16668 2.5C11.0076 2.5 12.5 3.99238 12.5 5.83333Z"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusUserIcon
