import styled from 'styled-components'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/material'
import { theme } from '@theme/index'

export const Container = styled(Box)`
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  padding: 16px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`

export const Content = styled(Box)`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const IconClose = styled(Close)`
  font-size: 16px !important;
  color: ${theme.palette['$color-border-dark']};
`

export const ContentAction = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 31px;
`
