const capitalize = (str: string) => {
  if (typeof str !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

const capitalizeAllWords = (phrase: string) => {
  if (typeof phrase !== 'string') {
    return ''
  }

  const worlds = phrase.split(' ')
  return worlds.map((name) => capitalize(name)).join(' ')
}

const capitalizeName = (fullName: string) => {
  if (typeof fullName !== 'string') {
    return ''
  }

  const splitedFullName = fullName.split(' ')
  const firstName = splitedFullName[0]
  if (splitedFullName.length === 1) {
    return capitalize(firstName)
  }

  const lastName = splitedFullName[splitedFullName.length - 1]

  return [firstName, lastName].map((name) => capitalize(name)).join(' ')
}

export { capitalize, capitalizeAllWords, capitalizeName }
