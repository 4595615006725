import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { CardTemplate } from '../CardTemplate'
import { BucketsEnum } from '@utils/buckets/type'
import { Chip, Stack, Typography } from '@mui/material'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import {
  formatDate,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { Button, SaveBucketButton, TextWrap } from '@components/atoms'
import { RegulatoryAgencyEntity } from '@services/nomos_api/entities/regulatory_agency'

type Props = { regulatoryAgency: RegulatoryAgencyEntity }

export function CardRegulatoryAngency({
  regulatoryAgency: regulatoryAgencyState,
}: Props) {
  const [regulatoryAgency, setRegulatoryAgency] =
    useState<RegulatoryAgencyEntity>(regulatoryAgencyState)

  const { url } = useSearchResultsUrl(
    `/regulatory_agencies/${regulatoryAgency.id}`
  )

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setRegulatoryAgency(
        produce(regulatoryAgency, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setRegulatoryAgency, regulatoryAgency]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setRegulatoryAgency({
        ...regulatoryAgency,
        savedBuckets: regulatoryAgency.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setRegulatoryAgency, regulatoryAgency]
  )

  const title = useMemo(() => {
    return formatterHighlight(regulatoryAgency, ['title'], 'Não Informado')
  }, [])

  const subject = useMemo(() => {
    return formatterHighlight(
      regulatoryAgency,
      ['subject', 'content'],
      'Não Informado'
    )
  }, [])

  return (
    <CardTemplate
      content={[
        <>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              sx={{ paddingX: 1 }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secondary"
                >
                  {regulatoryAgency?.openDataResource?.toUpperCase() ||
                    'Não Informado'}
                </Typography>
              }
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography color="$color-text-primary" variant="$font-body-1">
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextWrap lines={2}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                {subject}
              </Typography>
            </TextWrap>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="$color-text-secondary" variant="$font-body-2">
                Data de publicação:
              </Typography>
              <Typography color="$color-text-primary" variant="$font-body-2">
                {formatDate(regulatoryAgency?.date) || 'Não Informado'}
              </Typography>
            </Stack>
          </Stack>
        </>,
      ]}
      actions={[
        <SaveBucketButton
          id={regulatoryAgency?.id}
          bucket={BucketsEnum.regulatory_agencies}
          isSaved={regulatoryAgency?.savedBuckets?.length > 0}
          openDataResource={regulatoryAgency.openDataResource}
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
