/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'
import { SaveSearchEntity } from '@services/nomos_api/entities/saved_searches'

export type SaveSearchContextProps = {
  form: any
  step: string
  search: SaveSearchEntity
  activeBucket: string
  savingMonitorSearch: boolean
  handleSubmit: () => void
  setActiveBucket: (bucket: string) => void
  handleUpdateStep: (step: string) => void
}

export const SaveSearchContext = createContext<SaveSearchContextProps>(
  {} as SaveSearchContextProps
)
