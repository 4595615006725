import { Filter } from '@utils/buckets/type'
import { List, ListItem } from '@mui/material'
import { EditBucketSearchContext } from '../../context'
import { SideFilterSelect } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'

export function SideFilterList() {
  const search = useContextSelector(EditBucketSearchContext, (s) => s.search)

  const bucket = useContextSelector(
    EditBucketSearchContext,
    (s) => s.bucketItems
  )

  const selectedBucket = useContextSelector(
    EditBucketSearchContext,
    (s) => s.selectedBucket
  )

  const handleUpdateSearch = useContextSelector(
    EditBucketSearchContext,
    (s) => s.handleUpdateSearch
  )

  return (
    <List>
      {bucket?.filters?.map((filter: Filter) => (
        <ListItem key={filter.attr} disablePadding>
          <SideFilterSelect
            filter={filter}
            search={search}
            selectedBucket={selectedBucket}
            handleUpdateSearch={handleUpdateSearch}
          />
        </ListItem>
      ))}
    </List>
  )
}
