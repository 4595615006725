import { useContextSelector } from 'use-context-selector'
import { TagManagerList } from './containers/TagManagerList'
import { TagManagerContext } from './context'
import { TagManagerCreate } from './containers/TagManagerCreate'

export function TagManagerView(): JSX.Element {
  const viewmode = useContextSelector(TagManagerContext, (s) => s.viewmode)
  if (viewmode === 'listing') return <TagManagerList />
  if (viewmode === 'editing') return <TagManagerCreate />
  return <div>unknown</div>
}
