/* eslint-disable no-unused-vars, prettier/prettier  */
import { Typography } from '@mui/material'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { MonitorContext } from '../../context'
import { isMobile } from 'react-device-detect'
import { PropositionsPanelContext } from './context'
import { Container, Content, Header } from './styles'
import { Propositions } from './components/propositions'
import { useContextSelector } from 'use-context-selector'
import { ExportPropositionModal } from '@containers/ExportPropositionModal'
import { ImportPropositionModal } from '@containers/ImportPropositionModal'
import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material'

export function PropositionsPanelView() {
  const { openModal } = useModal()
  const id = useContextSelector(PropositionsPanelContext, (s) => s?.id)
  const monitor = useContextSelector(MonitorContext, (s) => s.monitor)

  return (
    <Container>
      <Header>
        <Typography variant="$font-title-4" color="$color-text-primary">
          Veja abaixo as proposições que você acompanha
        </Typography>

        {!isMobile && (
          <Button
            variant="text"
            text="Importar Proposições"
            endIcon={<FileUploadOutlined />}
            onClick={() =>
              openModal(
                <ImportPropositionModal
                  monitorId={String(id)}
                  // onImportFinished={() => resetList()}
                />
              )
            }
          />
        )}
        {!isMobile && (
          <Button
            variant="text"
            text="Exportar Proposições"
            endIcon={<FileDownloadOutlined />}
            // disabled={isLoading || rows.length === 0}
            onClick={() =>
              openModal(
                <ExportPropositionModal
                  monitorId={String(id)}
                  monitorName={monitor?.data?.name || 'monitor'}
                />
              )
            }
          />
        )}
      </Header>
      <Content>
        <Propositions title="Proposições" expanded />
      </Content>
    </Container>
  )
}
