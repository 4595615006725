import { SVGProps } from 'react'

function UserIcon({
  width = 20,
  height = 20,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16666 16.6667V15.8333C4.16666 13.5321 6.03214 11.6667 8.33332 11.6667H11.6667C13.9678 11.6667 15.8333 13.5321 15.8333 15.8333V16.6667M13.3333 5.83333C13.3333 7.67428 11.8409 9.16667 9.99999 9.16667C8.15904 9.16667 6.66666 7.67428 6.66666 5.83333C6.66666 3.99238 8.15904 2.5 9.99999 2.5C11.8409 2.5 13.3333 3.99238 13.3333 5.83333Z"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserIcon
