import { AppBarBlank } from '@components/molecules/AppBarBlank'
import { Container } from './AuthPage.styles'

export type AuthPageProps = {
  children: React.ReactChild
}

export default function AuthPage({ children }: AuthPageProps) {
  return (
    <>
      <AppBarBlank />
      <Container>{children}</Container>
    </>
  )
}
