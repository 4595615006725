import styled from 'styled-components'

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2db;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`
