import React from 'react'
import { Stack, Typography } from '@mui/material'
import { KeyValuePair } from '@utils/buckets/type'
import { InputMultiSelectChip } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'
import { searchOptionsAPI } from '@services/nomos_api/resources/search/options'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'

export function MonitorFormOrganPropositionProceeding(): JSX.Element | null {
  const [data, setData] = React.useState<KeyValuePair[]>([])
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [selecteds, setSelecteds] = React.useState<MultiSelectChipOption[]>([])
  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )

  const handleLoadOptions = () => {
    setLoading(true)
    searchOptionsAPI('propositions', 'types', [])
      .then(setData)
      .finally(() => setLoading(false))
  }

  const options = React.useMemo(() => {
    return (
      data?.map((m) => ({ key: m.key, label: capitalizeAllWords(m.value) })) ||
      []
    )
  }, [data])

  // update form when selected changes
  React.useEffect(() => {
    formSavedBucket.setFieldValue(
      'trackedPropositionTypes',
      selecteds.map((i) => i.key)
    )
  }, [selecteds])

  React.useEffect(() => {
    if (
      data.length === 0 &&
      formSavedBucket.values.trackedUpdates &&
      formSavedBucket.values.trackedUpdates.includes(
        'updated_proposition_proceeding'
      )
    ) {
      handleLoadOptions()
    }
  }, [data, formSavedBucket.values.trackedUpdates])

  if (
    !(formSavedBucket.values.trackedUpdates || []).includes(
      'updated_proposition_proceeding'
    )
  ) {
    return null
  }

  return (
    <Stack direction="column" spacing={3} pb={3}>
      <Stack direction="column" spacing={1}>
        <Typography variant="$font-title-5" color="$color-text-secondary">
          Entrada de proposições
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Selecione os tipos de proposições que deseja ser alertado(a) sempre
          que a mesma tramitar para este Órgão.
        </Typography>
      </Stack>
      <InputMultiSelectChip
        id="organ-proposition-proceeding"
        label="Tipos de proposição"
        options={options}
        value={selecteds}
        loading={isLoading}
        disabled={false}
        maxRenderTags={3}
        groupAllSelectedOptions="Todos os tipos"
        onChangeOptions={(_, options) => setSelecteds(options)}
      />
    </Stack>
  )
}
