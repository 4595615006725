import {
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { Wrapper, LeftGroup } from './FormCountResults.styles'
import { useContextSelector } from 'use-context-selector'
import { pageview } from '@utils/functions/pageview/pageview'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import { decodeKeywords } from '@utils/functions/keyword/serialize'
import { KeywordRender } from '@components/atoms'
import colors from '@theme/colors'

function FormCountResultsComponent() {
  const keyword = useContextSelector(SearchResultsContext, (s) => s.keyword)
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)
  const search = useContextSelector(SearchResultsContext, (s) => s.search)
  const selectAll = useContextSelector(SearchResultsContext, (s) => s.getSelectedAll)
  const keywords = decodeKeywords(keyword)

  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )

  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const pagination = useContextSelector(
    SearchResultsContext,
    (s) => s.pagination
  )

  const setSelectedAll = useContextSelector(
    SearchResultsContext,
    (s) => s.setSelectedAll
  )

  const keyPrefix = `buckets.${selectedBucket}`

  return (
    <Wrapper>
      <LeftGroup>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          <Typography variant="$font-body-base" color="$color-text-secondary" sx={{ float: "left" }}>
            Exibindo {pageview(pagination || {})} resultados{' '}
            {keyword && `para`} &nbsp;
          </Typography>
          <KeywordRender value={{ keywords }} inline envolve={[`"`, `"`]} />
        </Typography>
        {selectedBucket === 'propositions' &&
          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                sx={{
                  '&.MuiCheckbox-root': {
                    color: selectAll() ? "$color-action-border" : colors.scale.gray20,
                  },
                }}
                size="small"
                checked={selectAll()}
                onChange={() => {setSelectedAll(!selectAll())}}
              />}
              label={
                <Typography variant="$font-body-base" color="$color-text-secondary" sx={{ float: "left" }}>
                  Selecionar todos
                </Typography>
              } />
          </FormGroup>
        }
      </LeftGroup>
      <TextField
        select
        size="tiny"
        label="Ordenar por"
        value={_.get(search, `${keyPrefix}.sort`, '')}
        onChange={(e) =>
          handleUpdateSearch({
            [`${keyPrefix}.sort`]: e.target.value,
          })
        }
        sx={{ width: { xs: '100%', md: 205 } }}
      >
        {(bucket?.sorts || []).map((option) => (
          <MenuItem
            key={option.attr}
            value={option.attr}
            sx={{ fontSize: '14px', color: '#556282' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Wrapper>
  )
}

export default FormCountResultsComponent
