import { UsersContext } from './context'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '@contexts/Snackbar'
import { useCallback, useMemo, useState } from 'react'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { deleteUserAPI } from '@services/nomos_api/resources/users/delete'

import {
  GetAllUsersFilters,
  useGetAllUsers,
} from '@services/nomos_api/resources/users/read'
import { useGetSeats } from '@services/nomos_api/resources/user/seats'

export type UsersProps = {
  children: React.ReactNode
}

export function UsersController({ children }: UsersProps) {
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [filters, setFilters] = useState<GetAllUsersFilters>({})
  const { data: seats, isLoading: loadingSeats } = useGetSeats()

  const handleFilterChange = useCallback(
    (filter: GetAllUsersFilters) => {
      setFilters((prevState) => ({
        ...prevState,
        ...filter,
      }))
    },
    [setFilters]
  )

  const handleClickNewUser = useCallback(() => {
    navigate('/new-user')
  }, [])

  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()

  const { data, isFetching, refetch } = useGetAllUsers({
    page,
    limit,
    filters,
  })

  const handleDelete = async (ids: string[]) => {
    try {
      await Promise.all(ids.map((id) => deleteUserAPI(id)))
      showSnackbarSuccess('Usuário(s) deletado(s) com sucesso')
    } catch (error) {
      const e = error as AxiosErrorResponse
      showSnackbarError(
        e?.response?.data?.message || 'Não foi possível deletar o(s) usuário(s)'
      )
    } finally {
      refetch()
    }
  }

  const store = useMemo(
    () => ({
      seats,
      users: data,
      loadingSeats,
      isLoading: isFetching,
      setPage,
      setLimit,
      handleDelete,
      handleClickNewUser,
      handleFilterChange,
    }),
    [
      data,
      seats,
      isFetching,
      loadingSeats,
      setPage,
      setLimit,
      handleDelete,
      handleClickNewUser,
      handleFilterChange,
    ]
  )

  return <UsersContext.Provider value={store}>{children}</UsersContext.Provider>
}
