import HTTPClient from '../..'
import { createCompanyEvent } from '@utils/metrics'
import { UserAuth } from '@services/nomos_api/entities/user'
import { Company } from '@services/nomos_api/entities/company'

export type CompanyMD = Company & {
  metadata?: {
    page_url?: string
    page_title?: string
    hubspotutk?: string
    utm_source?: string
    utm_medium?: string
    utm_content?: string
    utm_campaign?: string
  }
}

export const createCompanyAPI = async (
  company: CompanyMD
): Promise<UserAuth> => {
  const { data } = await HTTPClient.post<UserAuth>('companies', company)
  createCompanyEvent(company)
  return data
}
