import { ListItem, styled } from '@mui/material'

export const AttachmentItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 0,
  paddingRight: 0,
  borderBottom: `1px solid ${theme.palette.muted.light}`,
  [`& a`]: {
    textDecoration: 'none',
  },
  [`& .link-attachment`]: {
    color: `${theme.palette.info.main} !important`,
    textDecoration: 'underline',
  },
}))
