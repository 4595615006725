/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModal } from '@contexts/Modal'
import { isMobile } from 'react-device-detect'
import { User } from '@services/nomos_api/entities/user'
import CompanyInfoModal from '@containers/CompanyInfoModal'
import { getSession } from '@services/nomos_api/resources/user/session'
import {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react'
import produce from 'immer'
import _ from 'lodash'
import OnboardModalView from '@containers/OnboardModal'
import { useNavigate } from 'react-router-dom'

type ContextType = {
  auth: boolean
  user: User | null | undefined
  token: string | null | undefined
  isLoadingSession: boolean
  isLogout: () => void
  handleUpdateUser: (user: User) => void
  updateUserName: (newName: string) => void
  updateUserData: (newuser: Partial<User>) => void
  isAuthenticated: (user: User, token: string) => void
}

const Context = createContext<ContextType>({} as ContextType)

type AuthProviderProps = {
  children: React.ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [auth, setAuth] = useState(false)
  const [user, setUser] = useState<User | null | undefined>()
  const [token, setToken] = useState<string | null | undefined>()
  const [isLoadingSession, setLoadingSession] = useState<boolean>(true)

  const handleUpdateUser = (user: User) => {
    setUser(user)
    localStorage.setItem('@NOMOS:user', JSON.stringify(user))
  }

  const updateUserData = useCallback(
    (newuser: Partial<User>) => {
      if (!user) return
      handleUpdateUser(
        produce(user, (draft) => {
          for (const field in newuser) {
            _.set(draft, field, _.get(newuser, field))
          }
        })
      )
    },
    [user, handleUpdateUser]
  )

  const updateUserName = useCallback(
    (newName: string) => {
      if (!user) return
      handleUpdateUser(
        produce(user, (draft) => {
          _.set(draft, 'name', newName)
        })
      )
    },
    [user, handleUpdateUser]
  )

  const authenticated = () => {
    const isAuth = localStorage.getItem('@NOMOS:auth')
    const isUser = localStorage.getItem('@NOMOS:user')
    const isToken = localStorage.getItem('@NOMOS:token')
    const pushToken = localStorage.getItem('@NOMOS:pushToken')

    if (isAuth === 'true') {
      const user = JSON.parse(isUser || '{}') as User
      setAuth(Boolean(isAuth))
      setUser(user)
      setToken(isToken || '')
      setLoadingSession(true)
      getSession(pushToken)
        .then((userSession) => {
          // check if initialUser has undefined lastPresenceDate
          // it means that is the first time of user on application
          // and we need to show-up the onboarding screen
          if (user.complete && !user.lastPresenceDate && !isMobile) {
            openModal(<OnboardModalView />, { lockclose: true })
          }

          // even new user state is received every time a
          // session is called, then is a good approach to
          // override it on application state and localstorage
          // to get the most recent updated state of user
          // (admin, active, subscription status, etc)
          setUser(userSession)
          localStorage.setItem('@NOMOS:user', JSON.stringify(userSession))
          if (
            userSession.complete &&
            (!userSession.company || !userSession.company.id)
          ) {
            openModal(<CompanyInfoModal />, { lockclose: true })
          }
          if (
            userSession.temporary_password !== undefined &&
            userSession.temporary_password !== ''
          ) {
            navigate(
              `/reset-initial-password/${userSession.temporary_password}`,
              {
                replace: true,
              }
            )
          }
        })
        .finally(() => setLoadingSession(false))

      const w = window as any
      if (w.smartlook) {
        w.smartlook('identify', user.id, {
          name: user.name,
          email: user.email,
          company: user.company?.name,
          trialExpireAt: user.company?.trialExpireAt,
        })
      }
    } else {
      setLoadingSession(false)
    }
  }

  const isAuthenticated = (user: User, token: string) => {
    localStorage.setItem('@NOMOS:auth', 'true')
    localStorage.setItem('@NOMOS:user', JSON.stringify(user))
    localStorage.setItem('@NOMOS:token', token)
    authenticated()
  }

  const isLogout = () => {
    localStorage.clear()
    setAuth(false)
    setUser(null)
    setToken(null)
    window.location.replace(window.location.origin)
  }

  useEffect(() => {
    authenticated()
  }, [])

  const authProviderValues = useMemo(
    () => ({
      auth,
      user,
      token,
      isLoadingSession,
      isLogout,
      isAuthenticated,
      handleUpdateUser,
      updateUserName,
      updateUserData,
    }),
    [
      auth,
      user,
      token,
      isLoadingSession,
      isLogout,
      isAuthenticated,
      handleUpdateUser,
      updateUserName,
      updateUserData,
    ]
  )

  return (
    <Context.Provider value={authProviderValues}>{children}</Context.Provider>
  )
}

const useAuth = () => {
  const context = useContext(Context)
  return context
}

export { AuthProvider, useAuth }
