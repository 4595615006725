import { Card } from '@components/atoms'
import { RadioGroup, Stack, styled as styledMui } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 82px;
`

export const Cards = styled.div`
  display: flex;
  gap: 46px;
`

export const Panels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 358px;
  max-height: 264px;
  overflow-y: scroll;
`

export const Panel = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  background-color: ${(props) =>
    props.color === 'active' ? '#F1F4F5' : 'transparent'};
`

export const PanelLabel = styledMui(Stack)`
  min-width: 300px;
`

export const Checkbox = styled.div`
  display: flex;
  gap: 24px;
`

export const Actions = styled.div`
  display: flex;
  gap: 23px;
  align-self: flex-end;
  padding-top: 32px;
`

export const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 21px;
  gap: 32px;
`

export const Options = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Option = styled(Card)`
  display: flex;
  gap: 16px;
  padding: 12px;
`

export const OptionLabel = styled(Card)`
  display: flex;
  flex-direction: column;
`
