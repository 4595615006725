import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Member } from '@services/nomos_api/entities/user'

export const getUserAPI = async (id?: string) => {
  if (!id) return {} as Member
  const { data } = await HTTPClient.get(`users/${id}`)
  return data as Member
}

export const useGetUser = (id?: string) =>
  useQuery(['get-user', id], () => getUserAPI(id), {
    refetchOnWindowFocus: false,
  })
