import { Typography, styled } from '@mui/material'

export const TypographyLink = styled(Typography)<{ maxlines?: number }>`
  ${(props) =>
    props.maxlines &&
    `
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${String(props.maxlines)};
  -webkit-box-orient: vertical;
  `}
`
