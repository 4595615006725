import { useMemo } from 'react'
import { PushPin } from '@components/icons'
import { useDrawer } from '@contexts/Drawer'
import { getBucketName } from '@utils/buckets'
import { BucketsEnum } from '@utils/buckets/type'
import { Button, KeywordRender } from '@components/atoms'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Chip, IconButton, Stack, Typography } from '@mui/material'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'

import { Container } from './styles'

type DataFoundCardProps = {
  id: string
  title: string
  createdAt: string
  originId: string
  content: string
  bucket: BucketsEnum
  keyword: string
  keywords: KeywordConditionsType
  name: string
  // link?: string
}

export default function DataFoundCard({
  id,
  title,
  createdAt,
  originId,
  content,
  bucket,
  keyword,
  keywords,
  name,
}: // link,
DataFoundCardProps) {
  const { openDrawer } = useDrawer()

  const shouldDisplayPin = useMemo(() => {
    return ['propositions', 'events', 'stakeholders', 'organs'].includes(bucket)
  }, [bucket])

  return (
    <Container
      data-cy={`dataFoundCard-${id}`}
      key={id}
      bgcolor="$color-background-secondary"
    >
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack
            spacing={{ xs: 1, md: 3 }}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Chip
              data-cy="chip-bucket"
              size="small"
              color="primary"
              variant="outlined"
              sx={{ display: 'flex', alignItems: 'center', maxWidth: '92px' }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secondary"
                  lineHeight={2}
                >
                  {getBucketName(bucket, 'N/D')}
                </Typography>
              }
            />
            <Typography
              noWrap
              variant="$font-body-2"
              color="$color-text-primary"
              maxWidth="294px"
            >
              {title || '-'}
            </Typography>

            <Stack direction="row" spacing={1}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Encontrado em:
              </Typography>
              <Typography
                noWrap
                variant="$font-body-base"
                color="$color-text-primary"
              >
                {formatDate(createdAt) || '-'}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Pesquisa Associada:
              </Typography>
              <Typography
                noWrap
                variant="$font-body-2"
                color="$color-text-primary"
              >
                {name || '-'}
              </Typography>
            </Stack>
          </Stack>
          {shouldDisplayPin && (
            <IconButton
              size="small"
              onClick={() =>
                openDrawer(<MonitoringDrawer origin={bucket} id={originId} />)
              }
            >
              <PushPin />
            </IconButton>
          )}
        </Stack>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography
            variant="$font-body-base"
            color="$color-text-secondary"
            sx={{
              overflow: 'hidden',
              wordWrap: 'break-word',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            Descrição: {content || '-'}
          </Typography>
          <Typography
            variant="$font-body-base"
            color="$color-text-secondary"
            sx={{
              overflow: 'hidden',
              wordWrap: 'break-word',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            Palavra-chave:{' '}
            <KeywordRender keyword={keyword} value={{ keywords }} />
          </Typography>
          {/* <Table>
            <Tr>
              <Td></Td>
              <Td>
                <TypographyDescription
                  variant="$font-body-base"
                  color="$color-text-primary"
                ></TypographyDescription>
              </Td>
            </Tr>
            <Tr>
              <Td></Td>
              <Td>
                <Typography
                  variant="$font-body-2"
                  color="$color-text-primary"
                ></Typography>
              </Td>
            </Tr>
          </Table> */}
          <Button
            sx={{ px: 2 }}
            variant="text"
            text="Ver detalhes"
            target="_blank"
            href={`/${bucket}/${originId}`}
          />
        </Stack>
      </Stack>
    </Container>
  )
}
