import { Card } from '@components/atoms'
import { styled as StyledMui } from '@mui/material'

export const Wrapper = StyledMui(Card)(({ theme }) => ({
  padding: '16px',
  maxWidth: '800px',

  '& .MuiTabs-scroller': {
    maxWidth: '300px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '32px',

    '& .MuiTabs-scroller': {
      maxWidth: 'unset',
    },
  },
}))
