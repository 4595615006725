import { createContext } from 'use-context-selector'

import {
  GetAllStakeholdersResult,
  GetAllStakeholdersPaginationFilters,
} from '@services/nomos_api/resources/stakeholders/stakeholders'

export type StakeholdersContextType = {
  isLoading: boolean
  stakeholders?: GetAllStakeholdersResult
  handleRefetch: () => void
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleFilterChange: (filter: GetAllStakeholdersPaginationFilters) => void
}

export const StakeholdersContext = createContext<StakeholdersContextType>(
  {} as StakeholdersContextType
)
