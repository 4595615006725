import Header from './containers/Header/Header'
import Actions from './containers/Actions/Actions'
import { PrimaryPage } from '@components/templates'
import ListMontitors from './containers/ListMonitors/ListMonitors'

export default function MonitorsView() {
  return (
    <PrimaryPage
      id="monitors-page"
      title="Monitoramento"
      description={`Acompanhe eventos, proposições e stakeholders através de painéis criados para 
      contextos específicos de monitoramento`}
      actions={<Actions />}
    >
      <>
        <Header />
        <ListMontitors />
      </>
    </PrimaryPage>
  )
}
