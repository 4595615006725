import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { RegulatoryAgencyEntity } from '@services/nomos_api/entities/regulatory_agency'

export const getRegulatoryAgencyByIdAPI = async (
  id: string
): Promise<RegulatoryAgencyEntity> => {
  const { data } = await HTTPClient.get(`regulatory_agencies/${id}`)
  return data as RegulatoryAgencyEntity
}

export const useGetRegulatoryAgencyDetails = (id: string) =>
  useQuery(
    ['get-regulatory-agencies-details'],
    () => getRegulatoryAgencyByIdAPI(id),
    {
      refetchOnWindowFocus: false,
    }
  )
