import { AxiosResponse } from 'axios'
import HTTPClient from '../..'

type Props = {
  monitorId: string
  file: File
}

export const importPropositions = async (
  props: Props
): Promise<AxiosResponse> => {
  const endpoint = `monitor/${props.monitorId}/import`
  const response = await HTTPClient.post(
    endpoint,
    { document: props.file },
    { responseType: 'blob' }
  )
  return response.data
}
