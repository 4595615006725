import { useFormik } from 'formik'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { StateModalHeader } from './StateModalHeader'
import { useContextSelector } from 'use-context-selector'
import { ExportPropositionModalContext } from '../context'
import { Stack, DialogContent, DialogActions } from '@mui/material'
import {
  ExportPropositionModalFieldKeys,
  ExportPropositionModalFields,
} from '../types'
import Input from '@components/atoms/Input/Input'
import _ from 'lodash'

export function StateModalFields() {
  const { closeModal } = useModal()

  const fields = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.fields
  )

  const setFields = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.setFields
  )

  const form = useFormik({
    initialValues: {
      fields: Object.keys(ExportPropositionModalFields).reduce(
        (draft, field) => {
          _.set(draft, field, _.get(fields, field, true))
          return draft
        },
        {}
      ) as ExportPropositionModalFieldKeys,
    },
    onSubmit: (values) => setFields(values.fields),
  })

  return (
    <>
      <StateModalHeader description="Selecione abaixo o as colunas que deseja exportar." />
      <DialogContent sx={{ maxHeight: 155 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          paddingY={1}
          spacing={2}
        >
          {Object.keys(ExportPropositionModalFields).map((field) => (
            <Stack direction="row" key={`field-${field}`}>
              <Input
                type="checkbox"
                name={`fields.${field}`}
                label={_.get(ExportPropositionModalFields, field)}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={_.get(form, `values.fields.${field}`, true)}
                checked={_.get(form, `values.fields.${field}`, true) === true}
              />
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
          <Button
            text="Próximo"
            variant="contained"
            onClick={() => form.handleSubmit()}
          />
        </Stack>
      </DialogActions>
    </>
  )
}
