import { useParams } from 'react-router-dom'
import { Header } from '@components/organisms'
import Resume from './containers/Resume/Resume'
import { PageContainer, CardsContainer } from './styles'
import Guidelines from './containers/Guidelines/Guidelines'
import Stakeholders from './containers/Stakeholders/Stakeholders'
import { PropositionVotingEntity } from '@services/nomos_api/entities/proposition'
import { useGetVoting } from '@services/nomos_api/resources/proposition/get_voting'

export default function VotingDetails() {
  const { propositionId, votingId } = useParams()
  const { data, isFetching } = useGetVoting(propositionId || '', votingId || '')
  const votings = data || ({} as PropositionVotingEntity)

  const breadcrumbs = [
    {
      name: 'Início',
      location: '/',
    },
    {
      name: 'Proposição',
      location: `/propositions/${propositionId}`,
    },
    {
      name: votings?.title || '',
      location: `/propositions/${propositionId}?tab=votings`,
    },
    {
      name: `Votos ${votings?.organName}`,
      location: '',
    },
  ]

  return (
    <PageContainer>
      <Header
        paths={breadcrumbs}
        title={votings?.organName}
        description={votings?.description}
        isLoading={isFetching}
      />

      <Resume votings={votings} />

      <CardsContainer>
        <Guidelines votings={votings} />
        <Stakeholders votings={votings} />
      </CardsContainer>
    </PageContainer>
  )
}
