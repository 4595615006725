import { useState } from 'react'
import { Stack } from '@mui/material'
import { Button } from '@components/atoms'
import { useModal } from '@contexts/Modal'
import { SeatIndicator } from '../SeatIndicator'
import { TabSelector } from '@components/organisms'
import { PurchaseSeatModal } from '@containers/PurchaseSeatModal'

export function SeatManager(): JSX.Element {
  const { openModal } = useModal()
  const [selectedTab, setSelectedTab] = useState<string>('users')

  const handleSeatCheckout = () => {
    openModal(<PurchaseSeatModal />, { lockscroll: true })
  }

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <TabSelector
        tabs={[
          { label: 'Usuários', value: 'users' },
          {
            disabled: true,
            label: 'Grupo de usuários (em breve)',
            value: 'groups',
          },
        ]}
        initialTab={selectedTab}
        onChangeTab={setSelectedTab}
      />
      <Stack direction="row" alignItems="center" spacing={3}>
        <SeatIndicator />
        <Button
          variant="outlined"
          text="Contratar assento"
          onClick={handleSeatCheckout}
        />
      </Stack>
    </Stack>
  )
}
