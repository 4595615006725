import { useEffect, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { PropositionStatusNotificationContext } from '../../context'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'

type Props = {
  disabled: boolean
}

export function FormNotificationChannel({ disabled }: Props) {
  const alertChannel = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.alertChannel
  )

  const setAlertChannel = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.setAlertChannel
  )

  const [localAlertChannel, setLocalAlertChannel] = useState(alertChannel)

  useEffect(() => {
    setLocalAlertChannel(alertChannel)
  }, [alertChannel])

  const [emailChecked, setEmailChecked] = useState(
    localAlertChannel.includes('email')
  )

  const handleEmailChange = (event: { target: { checked: boolean } }) => {
    const { checked } = event.target
    setEmailChecked(checked)
    if (checked) {
      setLocalAlertChannel([...localAlertChannel, 'email'])
    } else {
      setLocalAlertChannel(
        localAlertChannel.filter((channel) => channel !== 'email')
      )
    }
  }
  useEffect(() => {
    setAlertChannel(localAlertChannel)
  }, [localAlertChannel])

  return (
    <div>
      <Typography variant="$font-title-5" color="$color-text-secondary">
        Canais de Notificação
      </Typography>

      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              value="email"
              name="alert.destinations"
              data-testid="email-checkbox"
              checked={emailChecked}
              onChange={handleEmailChange}
              disabled={disabled}
            />
          }
          label={
            <Typography variant="$font-body-base" color="$color-text-primary">
              Email
            </Typography>
          }
        />
        <FormControlLabel
          sx={{ opacity: 0.5 }}
          control={
            <Checkbox
              value="whatsApp"
              name="alert.destinations"
              data-testid="whatsapp-checkbox"
              checked={false}
              disabled
            />
          }
          label={
            <Typography variant="$font-body-base" color="$color-text-primary">
              Whatsapp (em breve)
            </Typography>
          }
        />
      </FormGroup>
    </div>
  )
}
