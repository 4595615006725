import styled from 'styled-components'

export const MenuContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding-left: 0px;
  margin-right: 32px;
  padding-bottom: 26px;
  background: transparent;

  overflow-y: scroll;
  overflow-x: hidden;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
`
