/* eslint-disable no-unused-vars */
import SavedBucketsView from './view'
import SavedBucketsController from './controller'

export type SavedBucketsListProps = {
  monitorId?: string
}

function SavedBucketsList(props: SavedBucketsListProps) {
  return (
    <SavedBucketsController {...props}>
      <SavedBucketsView />
    </SavedBucketsController>
  )
}

SavedBucketsList.defaultProps = {
  monitorId: null,
}

export default SavedBucketsList
