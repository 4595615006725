/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab'
import { getBucketName } from '@utils/buckets'
import { Stack, Typography } from '@mui/material'
import TrashSVG from '@assets/icons/trash-red.svg'
import { SideFilterList } from '../SideFilterList'
import { AlertAction } from '@components/molecules'
import { ScrollBox, StepContainer } from '../../styles'
import { EditBucketSearchContext } from '../../context'
import { useContextSelector } from 'use-context-selector'

export function StepSettings() {
  const bucket = useContextSelector(EditBucketSearchContext, (s) => s.bucket)

  const handleSubmit = useContextSelector(
    EditBucketSearchContext,
    (s) => s.handleSubmit
  )

  const isLoading = useContextSelector(
    EditBucketSearchContext,
    (s) => s.isLoading
  )

  const handleDeleteBucket = useContextSelector(
    EditBucketSearchContext,
    (s) => s.handleDeleteBucket
  )

  return (
    <StepContainer>
      <Stack direction="column">
        <Typography variant="$font-body-base" maxWidth={380}>
          Veja abaixo os destalhes dos filtros aplicados para essa pesquisa na
          categoria de dados proposição. Você pode editá-los por aqui ou
          acessando:
        </Typography>

        <Typography variant="$font-body-base" maxWidth={380}>
          Resultado de Pesquisa
        </Typography>
      </Stack>

      <Stack marginTop={4}>
        <AlertAction
          type="error"
          size="medium"
          icon={<img src={TrashSVG} alt="Remove categoria da pesquisa salva" />}
          onClick={handleDeleteBucket}
        >
          <Typography variant="$font-body-1" color="$color-text-secondary">
            Remover <b>{getBucketName(bucket)}</b> desta pesquisa
          </Typography>
        </AlertAction>
      </Stack>

      <ScrollBox>
        <Stack direction="column" spacing={2} sx={{ paddingRight: 3 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="$font-body-1" color="$color-text-primary">
              Filtros Selecionados
            </Typography>
          </Stack>

          <SideFilterList />
        </Stack>
      </ScrollBox>

      <Stack direction="row" spacing={1}>
        <LoadingButton
          size="large"
          variant="contained"
          color="primary"
          sx={{ p: '12px 0', flex: 1 }}
          onClick={handleSubmit}
          loading={isLoading}
        >
          <Typography variant="body1" color="common.white">
            Salvar
          </Typography>
        </LoadingButton>
      </Stack>
    </StepContainer>
  )
}
