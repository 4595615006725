import styled from 'styled-components'

export const Content = styled.div`
  gap: 16px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const PictureContainer = styled.div`
  width: 233px;
  min-width: 233px;
  max-width: 233px;
  height: 166px;
  background-color: #f9fafc;
`

export const Picture = styled.img`
  aspect-ratio: auto;
  max-width: 233px;
  max-height: 166px;
  height: 100%;
  width: 100%;
  object-fit: cover;
`
