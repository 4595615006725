import styled from 'styled-components'
import { Box, styled as styledMaterial, Grid } from '@mui/material'

export const EmptyData = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: '#858FA6',
  margin: 'auto 0',
}))

export const List = styledMaterial(Grid)(() => ({}))

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 280px;

  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  gap: 32px;

  cursor: pointer;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  svg {
    color: #606162;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-top: 23px;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
`
