import _ from 'lodash'

/* eslint-disable no-restricted-syntax */
export function removeUndefineds(
  initialData: Record<string, string | null | undefined>
): Record<string, string> {
  const data = _.cloneDeep(initialData)
  for (const key in data) {
    if (data[key] === undefined || data[key] === null) {
      _.unset(data, key)
    } else {
      _.set(data, key, String(_.get(data, key)))
    }
  }
  return data as Record<string, string>
}
