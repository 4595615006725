import { periodType } from './controller'
import { createContext } from 'use-context-selector'
import { AlertsResult } from '@services/nomos_api/resources/alerts/getAll'

type DataFoundContextType = {
  isLoading: boolean
  data: AlertsResult | undefined
  setPage: (page: number) => void
  setLimit: (page: number) => void
  monitorId: string | undefined

  setTextSearchKeyword: (text: string) => void
  textSearchKeyword: string

  selectedRelatedSavedSearches: string[]
  setSelectedRelatedSavedSearches: (options: string[]) => void

  selectedBuckets: string[]
  setSelectedBuckets: (options: string[]) => void

  selectedMonitors: string[]
  setSelectedMonitors: (options: string[]) => void

  periodFilter: periodType | null
  setPeriodFilter: (value: periodType) => void

  displayMonitorFilter: boolean
}

export const DataFoundContext = createContext<DataFoundContextType>(
  {} as DataFoundContextType
)
