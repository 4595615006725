import colors from '@theme/colors'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${colors['$color-background-primary']};
  padding-top: 52px;
  padding-right: 72px;
  padding-bottom: 32px;
  padding-left: 46px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`
