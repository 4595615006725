import { theme } from '@theme/index'
import { useAuth } from '@contexts/Auth'
import { useMemo, useState } from 'react'
import { Close } from '@mui/icons-material'
import WhiteWarningSVG from '@assets/icons/white-warning.svg'
import { StripePortalLink } from '@containers/StripePortalLink'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { BannerLabel } from './styles'

export function AppBannerUnpaid() {
  const { user } = useAuth()
  const localStorageKey = `@NOMOS:hideUnpaidBanner`

  const [hideBanner, setHideBanner] = useState<boolean>(
    localStorage.getItem(localStorageKey) === 'true'
  )

  const handleCloseBanner = () => {
    localStorage.setItem(localStorageKey, 'true')
    setHideBanner(true)
  }

  const hidden = useMemo(() => {
    return hideBanner || user?.company?.subscription?.status !== 'unpaid'
  }, [hideBanner, user?.company?.subscription?.status])

  //   return null if banner shuld not be displayed
  if (hidden) return null

  return (
    <Box bgcolor="$color-semantic-error" id="app-banner-unpaid">
      <Stack
        width="88%"
        margin="0 auto"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="55px"
        spacing={3}
      >
        <Stack spacing={2} alignItems="center" direction="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={WhiteWarningSVG} alt="Countdown" width={24} height={24} />
            <BannerLabel variant="$font-body-4" color="$color-text-light">
              Urgente: Pagamento pendente
            </BannerLabel>
          </Stack>
          <Divider
            flexItem
            orientation="vertical"
            color={theme.palette['$color-border-light']}
          />
          <Typography variant="$font-body-2" color="$color-text-light">
            O acesso da {user?.company?.name || 'unknown'} pode ser bloqueado a
            qualquer momento. Regularize o pagamento o quanto antes na área de
            assinatura ou fale conosco.
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          {!!user?.admin && (
            <StripePortalLink
              size="medium"
              variant="outlined"
              title="Atualizar Pagamento"
              text="Atualizar Pagamento"
            />
          )}
          <IconButton size="medium" onClick={() => handleCloseBanner()}>
            <Close
              fontSize="small"
              sx={{ color: theme.palette['$color-border-light'] }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}
