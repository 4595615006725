import _ from 'lodash'
import { useDrawer } from '@contexts/Drawer'
import { EmptyState } from '@components/molecules'
import { TabRemoveFromMonitorContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { Button, ContainerScroll } from '@components/atoms'

import {
  Box,
  ListItem,
  Checkbox,
  Typography,
  Stack,
  LinearProgress,
} from '@mui/material'

import {
  DrawerBottom,
  CheckListWrapper,
  UserLabel,
  BpIcon,
  BpCheckedIcon,
} from './styles'

export function RemovePropositionTabView() {
  const { closeDrawer } = useDrawer()
  const monitoring = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.monitoring
  )

  const dataToUnmonitor = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.dataToUnmonitor
  )

  const handleCheck = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.handleCheck
  )

  const handleRemoveData = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.handleRemoveData
  )

  const isLoading = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.isLoading
  )

  const isRemoving = useContextSelector(
    TabRemoveFromMonitorContext,
    (s) => s.isRemoving
  )

  return (
    <Stack
      flex={1}
      spacing={3}
      width="100%"
      direction="column"
      overflow="hidden"
    >
      <CheckListWrapper>
        <ContainerScroll auto="true" spacing={0}>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="$font-title-5" color="$color-text-secondary">
                Selecione o painel
              </Typography>
            </Stack>
            <Typography variant="$font-body-base" color="$color-text-secondary">
              O dado está salvo nos painéis listados abaixo.
              <br />
              Selecione os painéis que deseja <b>remover</b> o dado.
            </Typography>
          </Stack>

          {isLoading && (
            <Box mt={2}>
              <LinearProgress />
            </Box>
          )}

          {monitoring?.length === 0 && !isLoading && (
            <Box sx={{ px: 4, mb: 2 }}>
              <EmptyState text="Essa informação não está sendo monitorada em nenhum painel no momento" />
            </Box>
          )}

          {monitoring?.length > 0 && !isLoading && (
            <Box mt={2}>
              {monitoring?.map((data) => (
                <ListItem
                  key={_.get(data, 'monitorName') as string}
                  disablePadding
                >
                  <Checkbox
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    name={_.get(data, 'monitorName') as string}
                    value={_.get(data, '_id')}
                    onChange={handleCheck}
                  />
                  <UserLabel
                    id={data.monitorName as string}
                    primary={data.monitorName as string}
                  />
                </ListItem>
              ))}
            </Box>
          )}
        </ContainerScroll>
      </CheckListWrapper>

      <DrawerBottom direction="row" spacing={2}>
        <Box flex={1}>
          <Button
            text="Sair"
            size="large"
            variant="outlined"
            onClick={closeDrawer}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box flex={1}>
          <Button
            text="Salvar"
            size="large"
            sx={{ width: '100%' }}
            onClick={handleRemoveData}
            loading={isRemoving || undefined}
            disabled={dataToUnmonitor.length === 0}
          />
        </Box>
      </DrawerBottom>
    </Stack>
  )
}
