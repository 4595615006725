import CardSum from '../../CardSum'
import EmptyState from '../../EmptyState'
import { TextLink } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { DividerIcon, MonitorIcon } from '@components/icons'
import { CircularProgress, Typography } from '@mui/material'
import { MonitorDashboard } from '@services/nomos_api/entities/dashboard'

import {
  MonitorCard,
  CardHeader,
  CardTitle,
  CardContentSum,
} from '../../styles'

export type MonitorsProps = {
  monitors?: MonitorDashboard
  isLoading: boolean
}

function Monitors({ monitors, isLoading }: MonitorsProps) {
  const navigate = useNavigate()

  const handleClick = () => navigate('/monitors')

  return (
    <MonitorCard type="bordored" height={140}>
      <CardHeader>
        <CardTitle>
          <MonitorIcon width={32} height={32} />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Monitoramento
          </Typography>
          {isLoading && <CircularProgress size={15} />}
        </CardTitle>

        <TextLink text="Ver monitores" href="/monitors" />
      </CardHeader>

      {!isLoading && monitors?.count_monitors === 0 ? (
        <EmptyState
          text="Seus painéis, assim como as proposições e eventos que você acompanha aparecerão aqui após criados."
          action="Criar Painel"
          onClick={handleClick}
        />
      ) : (
        <CardContentSum>
          <CardSum
            count={monitors?.count_monitors || 0}
            label="Total de painéis criados"
          />
          <DividerIcon />
          <CardSum
            count={monitors?.count_events || 0}
            label="Eventos monitorados"
          />
          <DividerIcon />
          <CardSum
            count={monitors?.count_propositions || 0}
            label="Proposições monitoradas"
          />
        </CardContentSum>
      )}
    </MonitorCard>
  )
}

Monitors.defaultProps = {
  monitors: null,
}

export default Monitors
