import { useMemo, useState } from 'react'
import { GridSortModel } from '@mui/x-data-grid'
import { useDebounce } from '@hooks/useDebounce'
import { SelectChangeEvent } from '@mui/material'
import { PropositionsPanelContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { PropositionPositioningContext } from './context'
import { useResourcesList } from '@services/nomos_api/resources/resources/all'
import { useListSavedPropositions } from '@services/nomos_api/resources/monitor/list-saved-propositions'

import {
  PropositionPositioningControllerProps,
  PropositionPositioningPeriodType,
} from './types'

export function PropositionPositioningController({
  children,
}: PropositionPositioningControllerProps): JSX.Element {
  const [page, handlePageChange] = useState(0)
  const [size, handlePageSizeChange] = useState(10)
  const [textSearch, setTextSearch] = useState('')
  const [filter, handleFilterChange] = useState({})

  const [house, setHouse] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])

  const [period, setPeriod] = useState<
    PropositionPositioningPeriodType | undefined
  >(undefined)
  const [sort, setSort] = useState('proceedingDate')
  const [sortDirection, setSortDirection] = useState<string | undefined | null>(
    'desc'
  )
  const debounceTextSearch = useDebounce(textSearch, 500)
  const id = useContextSelector(PropositionsPanelContext, (s) => s?.id)

  const { data: resources } = useResourcesList()

  const {
    refetch,
    data: propositions,
    isFetching: isLoading,
  } = useListSavedPropositions({
    id,
    house,
    filter,
    limit: size,
    page: page + 1,
    q: debounceTextSearch,
    sort,
    sortDirection,
    period,
    tags,
  })

  const resetList = () => {
    if (page > 1) handlePageChange(1)
    else refetch()
  }

  const handleChangeResource = (
    event: SelectChangeEvent<string[] | string>
  ) => {
    const {
      target: { value },
    } = event
    setHouse(typeof value === 'string' ? value.split(', ') : value)
  }

  const renderResourceValue = (selectedResources: string[]) => {
    return selectedResources
      .map((selected) => resources?.filter((r) => r.key === selected)[0].name)
      .join(',')
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model && model.length > 0) {
      setSort(model[0].field)
      setSortDirection(model[0].sort)
    }
  }

  const state = useMemo(
    () => ({
      tags,
      page,
      house,
      period,
      refetch,
      isLoading,
      resources,
      textSearch,
      propositions,
      setTags,
      setHouse,
      resetList,
      setPeriod,
      setTextSearch,
      handlePageChange,
      handleFilterChange,
      renderResourceValue,
      handleChangeResource,
      handleSortModelChange,
      handlePageSizeChange,
    }),
    [
      page,
      house,
      refetch,
      isLoading,
      resources,
      textSearch,
      propositions,
      setHouse,
      resetList,
      setTextSearch,
      handlePageChange,
      handleFilterChange,
      renderResourceValue,
      handleChangeResource,
      handleSortModelChange,
      handlePageSizeChange,
    ]
  )

  return (
    <PropositionPositioningContext.Provider value={state}>
      {children}
    </PropositionPositioningContext.Provider>
  )
}
