/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import _ from 'lodash'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { HubspotEmitEventType } from './types'
import { useCookies } from '@contexts/Cookies'

export const useHubspot = () => {
  const { getCookie } = useCookies()
  const emitHsEvent = ({ formName, payload }: HubspotEmitEventType) => {
    const formData: Record<string, unknown> = {}
    const formValues: Record<string, string> = {}
    const labelToNameMap: Record<string, string> = {}
    for (const fieldName in payload) {
      if (payload[fieldName]) {
        if (['firstName', 'email', 'phone'].includes(fieldName)) {
          _.set(formData, fieldName, payload[fieldName])
        } else {
          _.set(formValues, fieldName, payload[fieldName])
          _.set(labelToNameMap, fieldName, fieldName)
        }
      }
    }
    axios.post(
      `https://forms.hscollectedforms.net/collected-forms/submit/form`,
      {
        collectedFormClasses: formName,
        collectedFormId: formName,
        contactFields: formData,
        formSelectorClasses: `.${formName}`,
        formSelectorId: `#${formName}`,
        formValues,
        labelToNameMap,
        pageTitle: document.title,
        pageUrl: window.location.href,
        portalId: 39613680,
        type: 'SCRAPED',
        utk: getCookie('hubspotutk'),
        uuid: uuidv4(),
        version: 'collected-forms-embed-js-static-1.433',
      }
    )
  }
  return { emitHsEvent }
}
