import { useMemo, useState } from 'react'
import { EventsCalendarContext } from './context'
import { useModal } from '@contexts/Modal'
import { EventModal } from '@containers/EventModal'
import { EventEntity } from '@services/nomos_api/entities/event'

type EventsCalendarControllerProps = {
  children: React.ReactNode
}

export default function EventsCalendarController({
  children,
}: EventsCalendarControllerProps) {
  const { openModal, cleanStack } = useModal()
  const [eventsMode, setEventsMode] = useState('Lista')

  const handleOpenModalDetailsEvent = (event: EventEntity) => {
    openModal(<EventModal event={event} />)
  }

  const handleCloseModalDetailsEvent = () => {
    cleanStack()
  }

  const store = useMemo(
    () => ({
      eventsMode,
      setEventsMode,
      handleOpenModalDetailsEvent,
      handleCloseModalDetailsEvent,
    }),
    [
      eventsMode,
      setEventsMode,
      handleOpenModalDetailsEvent,
      handleCloseModalDetailsEvent,
    ]
  )

  return (
    <EventsCalendarContext.Provider value={store}>
      {children}
    </EventsCalendarContext.Provider>
  )
}
