/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import _ from 'lodash'
import produce from 'immer'
import { Stack } from '@mui/material'
import { InputPinField } from './styles'
import { useEffect, useRef } from 'react'
import { ErrorLabel } from '../ErrorLabel'

type InputPinCodeProps = {
  id: string
  value: string
  length: number
  error?: string
  onChange: (value: string) => void
  onPressEnter?: () => void
}
export default function InputPinCode({
  id,
  value = '',
  length = 5,
  error,
  onChange,
  onPressEnter,
}: InputPinCodeProps): JSX.Element {
  // Create an array of refs with the specified length
  const refs = Array.from({ length }, () =>
    useRef<HTMLInputElement | null>(null)
  )

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.clipboardData
      .getData('text')
      .split('')
      .filter((key) => /^\d$/.test(key))
      .slice(0, length)
      .join('')
    onChange(value)
  }

  const handleKey = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { key } = event
    if (key === 'Backspace') {
      onChange(
        produce(value, (draft) => {
          refs?.[draft.length - 1]?.current?.focus()
          return [...(draft || '').slice(0, -1)].join('')
        })
      )
    } else if (key === 'Enter') {
      if (value.length === length) {
        onPressEnter?.()
      }
    } else if (!/^\d$/.test(key)) {
      event.stopPropagation()
    } else {
      onChange(
        produce(value, (draft) => {
          const arr = (draft || '').split('')
          if (arr.length > index) {
            arr[index] = key
            refs?.[index + 1]?.current?.focus()
          } else {
            arr.push(key)
            refs?.[arr.length]?.current?.focus()
          }

          return arr.join('')
        })
      )
    }
  }

  useEffect(() => {
    if (refs?.[0]?.current) {
      refs?.[0]?.current?.focus()
    }
  }, [])

  return (
    <Stack direction="column" alignItems="center">
      <Stack direction="column" alignItems="flex-start">
        <Stack direction="row" spacing={1} justifyContent="center">
          {Array.from(Array(length)).map((_, i) => {
            return (
              <InputPinField
                ref={refs[i]}
                key={`input-pin-${i}`}
                autoFocus
                type="tel"
                done={value.length === length ? 'true' : 'false'}
                value={value[i] || ''}
                onKeyDown={(e) => handleKey(i, e)}
                onPaste={(e) => handlePaste(e)}
                id={`${id}-input-${i}`}
                data-cy={`${id}-input-${i}`}
              />
            )
          })}
        </Stack>
        <ErrorLabel value={error} />
      </Stack>
    </Stack>
  )
}
