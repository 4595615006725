import qs from 'qs'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useQueryState = <T extends Record<string, string>>() => {
  const location = useLocation()
  const navigate = useNavigate()

  const setQuery = useCallback(
    (newvalue: Partial<T>) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      const queryString = qs.stringify(
        { ...existingQueries, ...newvalue },
        { skipNulls: true }
      )

      navigate(`${location.pathname}?${queryString}`)
    },
    [navigate, location]
  )

  type ResponseQueryStateProps = [T, (value: Partial<T>) => void]

  return [
    qs.parse(location.search, { ignoreQueryPrefix: true }),
    setQuery,
  ] as unknown as ResponseQueryStateProps
}
