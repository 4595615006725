import { Box, Stack, Typography, styled } from '@mui/material'

export const NoteContentFileWrapper = styled(Stack)`
  padding: 16px;
  border-radius: 6px;
  background-color: ${(ctx) => ctx.theme.palette['$color-background-primary']};

  border-radius: 6px;
  border: 1px solid white;
  cursor: pointer !important;
  &:hover {
    border: 1px solid ${(ctx) => ctx.theme.palette['$color-border-primary']};
  }
`

export const NoteContentFileName = styled(Typography)`
  line-clamp: 1;
  overflow: hidden;
  word-wrap: nowrap;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const NoteContnetFileIconWrap = styled(Box)<{ error: 'true' | 'false' }>`
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  display: flex;
  border-radius: 32px;
  align-items: center;
  background-color: white;
  justify-content: center;
  & * {
    color: ${(ctx) =>
      ctx.error === 'true'
        ? ctx.theme.palette['$color-semantic-error']
        : ctx.theme.palette['$color-action-border']};
  }
`
