/* eslint-disable no-nested-ternary */
import { Stack, styled } from '@mui/material'
import { lightenColor } from '@utils/functions/colors/colors'

export const Wrapper = styled(Stack)<{
  color: string
  size: 'medium' | 'small' | 'tiny'
  fullwidth?: 'true' | 'false'
}>`
  height: ${(props) =>
    props.size === 'medium'
      ? '32px'
      : props.size === 'small'
      ? '24px'
      : '21px'};
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-left-width: 1px;
  border-left-style: solid;
  background-color: ${(props) => lightenColor(props.color, 80)};
  width: ${(props) => (props.fullwidth === 'true' ? '100%' : 'auto')};
  border-left-color: ${(props) => props.color};
`
