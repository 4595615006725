import Tabs from './containers/Tabs'
import { OrganDetailContext } from './context'
import { isMobile } from 'react-device-detect'
import { ExpandIcon } from '@components/icons'
import { DetailsPage } from '@components/templates'
import { BucketsEnum } from '@utils/buckets/type'
import { useContextSelector } from 'use-context-selector'

export function OrganDetailView() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)
  const isLoading = useContextSelector(OrganDetailContext, (s) => s.isLoading)

  const paths = [
    {
      name: 'Órgãos',
      location: '/organs',
    },
    {
      name: organ?.initials || 'Órgão',
      location: `/organs/${organ?.id}`,
    },
    {
      name: 'Detalhes',
      location: `/organs/${organ?.id}`,
    },
  ]

  const actions =
    organ?.url && !isMobile
      ? [
          {
            name: 'Acompanhe na Íntegra',
            icon: <ExpandIcon />,
            url: organ?.url,
          },
        ]
      : []

  return (
    <DetailsPage
      id={organ?.id || ''}
      bucket={BucketsEnum.organs}
      title={organ?.name || 'Órgão'}
      isLoading={isLoading}
      paths={paths}
      actions={actions}
      isMonitored={!!organ?.savedOrgans?.length}
    >
      <Tabs />
    </DetailsPage>
  )
}
