import { useListSavedEvents } from '@services/nomos_api/resources/monitor/list-saved-events'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EventsPanelContext } from './context'

type EventsPanelControllerProps = {
  children: React.ReactNode
}

export default function EventsPanelController({
  children,
}: EventsPanelControllerProps) {
  const { id } = useParams()
  const [dateRange, setDateRange] = useState({})

  const { data: events, isLoading } = useListSavedEvents({
    id,
    dateRange,
  })

  const handleUpdateSearch = useCallback(
    (data: Record<string, string>) => {
      setDateRange({
        start: data['buckets.events.filter.date.from'],
        end: data['buckets.events.filter.date.to'],
      })
    },
    [dateRange]
  )

  const store = useMemo(
    () => ({
      events,
      isLoading,
      handleUpdateSearch,
    }),
    [events, isLoading, handleUpdateSearch]
  )

  return (
    <EventsPanelContext.Provider value={store}>
      {children}
    </EventsPanelContext.Provider>
  )
}
