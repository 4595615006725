import { Bucket, BucketsEnum } from './type'

const BucketOficialDiary: Bucket = {
  key: BucketsEnum.oficial_diary,
  name: 'Diários Oficiais',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Publicados recentemente',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: false,
      attr: 'date',
      label: 'Selecione o dia desejado',
    },
  ],
  filters: [
    {
      attr: 'origin',
      label: 'Estados',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [
        { key: 'ac', value: 'Acre' },
        { key: 'al', value: 'Alagoas' },
        { key: 'ap', value: 'Amapá' },
        { key: 'am', value: 'Amazonas' },
        // { key: 'ba', value: 'Bahia' },
        { key: 'ce', value: 'Ceará' },
        { key: 'df', value: 'Distrito Federal' },
        { key: 'es', value: 'Espírito Santo' },
        { key: 'go', value: 'Goiás' },
        // { key: 'ma', value: 'Maranhão' },
        { key: 'mt', value: 'Mato Grosso' },
        { key: 'ms', value: 'Mato Grosso do Sul' },
        { key: 'mg', value: 'Minas Gerais' },
        { key: 'pa', value: 'Pará' },
        { key: 'pb', value: 'Paraíba' },
        // { key: 'pr', value: 'Paraná' },
        { key: 'pe', value: 'Pernambuco' },
        { key: 'pi', value: 'Piauí' },
        { key: 'rj', value: 'Rio de Janeiro' },
        { key: 'rn', value: 'Rio Grande do Norte' },
        { key: 'rs', value: 'Rio Grande do Sul' },
        { key: 'ro', value: 'Rondônia' },
        { key: 'rr', value: 'Roraima' },
        { key: 'sc', value: 'Santa Catarina' },
        { key: 'sp', value: 'São Paulo' },
        // { key: 'se', value: 'Sergipe' },
        { key: 'to', value: 'Tocantins' },
      ],
    },
  ],
}

export default BucketOficialDiary
