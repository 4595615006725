import { SearchResultsController } from './controller'
import { SearchResultsView } from './view'

function SearchResultsPage() {
  return (
    <SearchResultsController>
      <SearchResultsView />
    </SearchResultsController>
  )
}

export default SearchResultsPage
