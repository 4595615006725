import theme from '@theme/colors'
import { UserRoles } from '../UserRoles'
import { useModal } from '@contexts/Modal'
import { Close } from '@mui/icons-material'
import { Button } from '@components/atoms'
import { KeyIcon } from '@components/icons'
import { useState, ChangeEvent } from 'react'
import { useSnackbar } from '@contexts/Snackbar'
import { Card, Stack, Typography } from '@mui/material'
import { MonitorUsers } from '@services/nomos_api/entities'
import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { putMonitorUsersApi } from '@services/nomos_api/resources/monitor_users/put'

import {
  ModalContent,
  ModalToolbar,
  ModalUser,
  RadioGroupHeader,
  Footer,
} from './styles'

export function EditMonitorUser({
  user,
  id,
  refetch,
}: {
  user: MonitorUsers
  id: string
  refetch: () => void
}) {
  useByPassSubscription()
  const { closeModal } = useModal()
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const [loading, setLoading] = useState(false)
  const [selectedRole, setSelectedRole] = useState(user.role)

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value)
  }

  const handleEdit = async () => {
    setLoading(true)

    const data = [
      {
        userId: user.id,
        role: selectedRole,
        delete: false,
      },
    ]

    await putMonitorUsersApi(data, id)
      .then(() => {
        showSnackbarSuccess('Usuário editado com sucesso')
        refetch()
      })
      .catch((error) => {
        const err = error as { response: { data: { message: string } } }

        showSnackbarError(
          err?.response?.data?.message || 'Erro ao editar usuário'
        )
      })
      .finally(() => {
        setTimeout(closeSnackbar, 3000)
        setLoading(false)
      })

    closeModal()
  }

  return (
    <>
      <ModalContent>
        <ModalToolbar>
          <Typography
            variant="$font-body-base"
            fontSize="24px"
            fontWeight={600}
            color={theme.brand.black}
            data-testid="edit-monitor-user-title"
          >
            Editar permissão de acesso
          </Typography>
          <Close onClick={closeModal} />
        </ModalToolbar>

        <Typography
          variant="$font-body-base"
          fontWeight={400}
          color="text.secondary"
          margin="10px 0"
        >
          Defina a permissão de acesso do usuário:
        </Typography>

        <ModalUser>
          <Typography fontSize={18} color="text.secondary" fontWeight={600}>
            {user?.name}
          </Typography>
        </ModalUser>

        <Card variant="outlined">
          <RadioGroupHeader>
            <KeyIcon />
            <Typography
              variant="$font-body-base"
              fontWeight={500}
              color="text.secondary"
              marginLeft="14px"
            >
              Permissão de acesso
            </Typography>
          </RadioGroupHeader>

          <UserRoles
            handleRadioChange={handleRadioChange}
            selectedRole={selectedRole}
          />
        </Card>
      </ModalContent>
      <Footer>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" onClick={closeModal} variant="outlined" />

          <Button
            id="edit-panel-confirm-btn"
            text="Salvar"
            onClick={handleEdit}
            loading={loading || undefined}
          />
        </Stack>
      </Footer>
    </>
  )
}
