import { columns } from '../../columns'
import { CardContent } from '../../styles'
import { CardProposition, FilterContainer } from './styles'
import { Table } from '@components/organisms'

import { PropositionPositioningProps } from './types'

import { PropositionPositioningContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { Accordion, DateRangePicker, Input } from '@components/atoms'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { PropositionPositioningFilterTags } from './components/filter-tags'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export function Propositionsiew({
  title,
  expanded,
}: PropositionPositioningProps): JSX.Element {
  const navigate = useNavigate()
  const page = useContextSelector(PropositionPositioningContext, (s) => s.page)
  const tags = useContextSelector(PropositionPositioningContext, (s) => s.tags)
  const house = useContextSelector(
    PropositionPositioningContext,
    (s) => s.house
  )
  const period = useContextSelector(
    PropositionPositioningContext,
    (s) => s.period
  )
  const resources = useContextSelector(
    PropositionPositioningContext,
    (s) => s.resources
  )
  const isLoading = useContextSelector(
    PropositionPositioningContext,
    (s) => s.isLoading
  )
  const textSearch = useContextSelector(
    PropositionPositioningContext,
    (s) => s.textSearch
  )
  const propositions = useContextSelector(
    PropositionPositioningContext,
    (s) => s.propositions
  )
  const setHouse = useContextSelector(
    PropositionPositioningContext,
    (s) => s.setHouse
  )
  const setTags = useContextSelector(
    PropositionPositioningContext,
    (s) => s.setTags
  )
  const setPeriod = useContextSelector(
    PropositionPositioningContext,
    (s) => s.setPeriod
  )
  const setTextSearch = useContextSelector(
    PropositionPositioningContext,
    (s) => s.setTextSearch
  )
  const handlePageChange = useContextSelector(
    PropositionPositioningContext,
    (s) => s.handlePageChange
  )
  const handleFilterChange = useContextSelector(
    PropositionPositioningContext,
    (s) => s.handleFilterChange
  )

  const handleSortModelChange = useContextSelector(
    PropositionPositioningContext,
    (s) => s.handleSortModelChange
  )

  const handlePageSizeChange = useContextSelector(
    PropositionPositioningContext,
    (s) => s.handlePageSizeChange
  )

  const rows = propositions?.results || []

  const handleOpenProposition = (id: string) => {
    navigate(`/propositions/${id}`)
  }

  const handleChangeResource = (
    event: SelectChangeEvent<string[] | string>
  ) => {
    const {
      target: { value },
    } = event
    setHouse(typeof value === 'string' ? value.split(', ') : value)
  }

  const renderResourceValue = (selectedResources: string[]) => {
    return selectedResources
      .map((selected) => resources?.filter((r) => r.key === selected)[0].name)
      .join(',')
  }

  return (
    <Accordion title={title} expanded={expanded}>
      <>
        <FilterContainer>
          <Input
            type="search"
            size="tiny"
            label="Busque por proposição"
            value={textSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTextSearch(e.target.value)
            }
          />

          <FormControl variant="outlined" size="tiny" sx={{ width: '100%' }}>
            <InputLabel>Casa</InputLabel>
            <Select
              multiple
              size="tiny"
              label="Casa"
              value={house}
              onChange={handleChangeResource}
              renderValue={renderResourceValue}
            >
              {resources?.map((resource) => {
                return (
                  <MenuItem key={resource.id} value={resource.key}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Input
                        type="checkbox"
                        size="small"
                        checked={house.indexOf(resource.key) > -1}
                      />
                      <Typography
                        variant="$font-body-base"
                        color="$color-text-secondary"
                      >
                        {resource.name}
                      </Typography>
                    </Stack>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <PropositionPositioningFilterTags
            initialFilter={tags}
            onChangeFilter={setTags}
          />

          <DateRangePicker
            size="tiny"
            label="Período de movimentação"
            startDate={period?.from}
            endDate={period?.to}
            onChange={(from, to) => setPeriod({ from, to })}
          />
        </FilterContainer>

        <CardContent>
          {isMobile ? (
            propositions?.results?.length > 0 &&
            propositions.results.map((item) => (
              <CardProposition
                key={item.id}
                onClick={() => handleOpenProposition(item.id)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="$font-body-base"
                    color="$color-action-link"
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    variant="$font-body-base"
                    color="$color-action-link"
                  >
                    Ver detalhes
                  </Typography>
                </Stack>
                <Typography variant="$font-body-4">
                  Prioridade:{' '}
                  <Typography
                    variant="$font-body-base"
                    color="$color-semantic-error"
                  >
                    {item.savedProposition?.priorityLabel}
                  </Typography>
                </Typography>
                <Typography
                  variant="$font-body-base"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '4',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {item.summary}
                </Typography>
              </CardProposition>
            ))
          ) : (
            <Table
              height={800}
              paginationMode="server"
              filterMode="server"
              loading={isLoading}
              rowsPerPageOptions={[10, 20, 30, 50]}
              pagination
              rows={rows}
              columns={columns}
              page={page}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              pageSize={propositions?.pagination?.limit}
              rowCount={propositions?.pagination?.total || 0}
              onPageChange={handlePageChange}
              onFilterModelChange={handleFilterChange}
              onSortModelChange={handleSortModelChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
        </CardContent>
      </>
    </Accordion>
  )
}
