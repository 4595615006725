import React from 'react'

type Props = {
  videoId: string
  // eslint-disable-next-line react/no-unused-prop-types
  width: string
  // eslint-disable-next-line react/no-unused-prop-types
  height: string
  // eslint-disable-next-line react/no-unused-prop-types
  title: string
}

// eslint-disable-next-line react/function-component-definition
const YoutubeEmbed: React.FC<Props> = ({ videoId, width, height, title }) => {
  return (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      title={title}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}

export default YoutubeEmbed
