import styled from 'styled-components'
import { Stack, Box, styled as styledMaterial, Grid } from '@mui/material'

export const Container = styledMaterial(Box)(() => ({
  backgroundColor: '#F9FAFC'
}))

export const Wrapper = styledMaterial(Stack)(() => ({
  margin: '0 auto',
  padding: '40px 0 48px 0',
  minHeight: '76vh',
  height: '100%',
  backgroundColor: '#F9FAFC',
  maxWidth: '88%',
  flexWrap: 'wrap',
}))

export const EmptyData = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: '#858FA6',
  margin: 'auto 0',
}))

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`

export const Content = styled.div``

export const HeaderContent = styled.div`
  width: 33%;
  padding: 42px 0;
  color: #b2bbbf;

  svg {
    color: #b2bbbf;
  }

  label {
    color: #b2bbbf;
  }
`

export const List = styledMaterial(Grid)(() => ({}))

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 300px;

  background-color: #fff;
  border: 1px solid #e7e9ed;

  border-radius: 8px;
  padding: 24px;
  gap: 36px;

  cursor: pointer;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  svg {
    color: #4070F4;
  }

  /* span {
    font-weight: 600;
    line-height: 24px;
  } */
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;

  div {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 12px;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
`
