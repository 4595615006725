import { Stack, Typography } from '@mui/material'
import AlertErrorSVG from '@assets/icons/info-white.svg'
import LockPage from '@components/templates/LockPage/LockPage'
import { BlockedScreenIcon } from './styles'

type StripeBlockedScreenProps = {
  color: string
  title: string
  content: string[]
  cta: string
  actions?: JSX.Element[]
}

export function StripeBlockedScreen({
  color,
  title,
  content,
  cta,
  actions,
}: StripeBlockedScreenProps): JSX.Element {
  return (
    <LockPage>
      <Stack alignItems="center" justifyContent="center" spacing={3}>
        <BlockedScreenIcon color={color}>
          <img src={AlertErrorSVG} alt="" width={36} height={36} />
        </BlockedScreenIcon>
        <Typography
          variant="$font-title-2"
          color="$color-text-primary"
          textAlign="center"
        >
          {title}
        </Typography>
        {content.map((contentIn) => (
          <Typography
            variant="$font-body-base"
            color="$color-text-secondary"
            textAlign="center"
          >
            {contentIn}
          </Typography>
        ))}
        <Typography
          variant="$font-body-2"
          color="$color-text-primary"
          textAlign="center"
        >
          {cta}
        </Typography>
        <Stack direction="row" spacing={1}>
          {actions && actions}
        </Stack>
      </Stack>
    </LockPage>
  )
}
