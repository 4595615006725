import colors from '@theme/colors'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${colors['$color-background-primary']};
  padding-top: 52px;
  max-width: 88%;
  margin: 0 auto;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
