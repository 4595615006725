/* eslint-disable react/no-array-index-key */
import { Typography } from '@mui/material'
import { Container, Header } from './CrumbedPage.styles'
import Breadcrumb, { Path } from '@components/molecules/Breadcrumb/Breadcrumb'

export type TabbedPageProps = {
  id: string
  title: string
  description: string
  paths: Path[]
  actions?: React.ReactElement
  children?: React.ReactChild
  'data-cy'?: string
  style?: React.CSSProperties
}

const defaultProps = {
  actions: null,
}

function CrumbedPage({
  id,
  title,
  description,
  paths,
  actions,
  children,
  'data-cy': dataCy,
  style,
}: TabbedPageProps) {
  return (
    <Container style={style} data-testid={id} data-cy={dataCy}>
      <Breadcrumb paths={paths} />

      <Header>
        <div>
          <Typography variant="$font-title-1" color="$color-text-primary">
            {title}
          </Typography>

          <Typography variant="$font-title-4" color="$color-text-secondary">
            {description.split('\n').map((text, index) => {
              return <p key={index}>{text}</p>
            })}
          </Typography>
        </div>

        <div>{actions}</div>
      </Header>

      {children}
    </Container>
  )
}

CrumbedPage.defaultProps = defaultProps

export default CrumbedPage
