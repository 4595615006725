import { Stack } from '@mui/material'
import { Button } from '@components/atoms'
import { useParams } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { BucketsEnum } from '@utils/buckets/type'
import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'

import NotesIcon from '@assets/icons/notes.svg'

export default function Monitor() {
  const { id } = useParams()
  const { openDrawer } = useDrawer()

  const stakeholder = useContextSelector(
    StakeholderDetailContext,
    (s) => s.stakeholder
  )

  const handleMonitor = () => {
    openDrawer(
      <MonitoringDrawer
        id={id || ''}
        origin={BucketsEnum.stakeholders}
        isSaved={stakeholder?.savedStakeholders?.length > 0}
      />
    )
  }

  return (
    <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
      <Button
        text="Opções de monitoramento"
        variant="outlined"
        sx={{
          backgroundColor: 'white',
        }}
        onClick={handleMonitor}
      />
      <Button
        text="Notas"
        color="$color-extended-red1"
        variant="contained"
        endIcon={<img src={NotesIcon} alt="Notas" />}
        onClick={() => {
          openDrawer(
            <NoteManagerDrawer
              id={stakeholder.id}
              bucket={BucketsEnum.stakeholders}
            />
          )
        }}
      />
    </Stack>
  )
}
