import Detail from './Details'
import Documents from './Documents'
import SumMembers from './SumMember'
import { Grid } from '@mui/material'
import CloseEvents from './CloseEvents'
import LastVotings from './LastVotings'
import LastPropositions from './LastPropositions'

export default function General() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Detail />
      </Grid>
      <Grid item xs={12} md={4}>
        <SumMembers />
      </Grid>
      <Grid item xs={12} md={6}>
        <Documents />
      </Grid>
      <Grid item xs={12} md={6}>
        <CloseEvents />
      </Grid>
      <Grid item xs={12}>
        <LastVotings />
      </Grid>
      <Grid item xs={12}>
        <LastPropositions />
      </Grid>
    </Grid>
  )
}
