import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Container = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;

  @media (min-width: 768px) {
    padding: 32px;
    gap: 32px;
  }
`

export const Content = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Elements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Element = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
