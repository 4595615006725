import styled from 'styled-components'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 64px;
  flex-wrap: wrap;
`
