import { Button } from '@components/atoms'
import { WhatsAppPaymentLinkProps } from './types'
import { WhatsAppPaymentLinkContext } from './context'
import { useContextSelector } from 'use-context-selector'

export function WhatsAppPaymentLinkView({
  title,
  text,
  size,
  variant,
  fullWidth,
  loadingLight,
}: WhatsAppPaymentLinkProps): JSX.Element {
  const handlePaymentContact = useContextSelector(
    WhatsAppPaymentLinkContext,
    (s) => s.handlePaymentContact
  )
  return (
    <Button
      size={size}
      text={text}
      title={title}
      variant={variant}
      light={loadingLight}
      fullWidth={fullWidth}
      onClick={handlePaymentContact}
    />
  )
}
