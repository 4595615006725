/* eslint-disable react/no-unknown-property */
import { SVGProps } from 'react'

function ExpandIcon({
  width = 24,
  height = 24,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="actions-control/sliders">
        <path
          id="Vector 66"
          d="M4 6C4 6 9.57574 6 13 6M16 4V6M16 8V6M16 6H20"
          stroke="#2970FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector 68"
          d="M4 18C4 18 6.57574 18 10 18M13 16V18M13 20V18M13 18H20"
          stroke="#2970FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector 67"
          d="M20 12C20 12 14.4243 12 11 12M8 14L8 12M8 10L8 12M8 12L4 12"
          stroke="#2970FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default ExpandIcon
