import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 482px;
  align-self: center;
  margin-top: 3px;

  justify-content: center;
  align-items: center;
  gap: 2px;

  text-align: center;
`
