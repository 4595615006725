import { ArrowBack, Close } from '@mui/icons-material'
import { Box, Stack, styled } from '@mui/material'

export const Wrapper = styled(Box)<{ compact: string }>`
  flex: 1;
  gap: ${(props) => (props.compact === 'true' ? '16px' : '32px')};
  max-width: 460px;
  display: flex;
  padding: ${(props) => (props.compact === 'true' ? '16px' : '32px')};
  padding-top: ${(props) => (props.compact === 'true' ? '24px' : '32px')};
  overflow-y: hidden;
  flex-direction: column;
`

export const Header = styled(Stack)`
  gap: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const IconClose = styled(Close)`
  font-size: 16px;
  color: ${(props) => props.theme.palette['$color-border-dark']};
`

export const IconBack = styled(ArrowBack)`
  font-size: 16px;
  color: ${(props) => props.theme.palette['$color-border-dark']};
`

export const Content = styled(Stack)`
  flex: 1;
  gap: 24px;
  overflow-y: hidden;
`

export const ActionContainer = styled(Stack)`
  gap: 16px;
  flex-direction: row;
  align-items: center;
`

export const InputGroup = styled(Stack)`
  flex-direction: column;
  gap: 16px;
`

export const InlineTagsContainer = styled(Box)`
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`
