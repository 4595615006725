import colors from '@theme/colors'
import { TextWrap } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import CalendarSVG from '@assets/icons/calendar.svg'
import { Container, CardEventDate } from './MiniEvent.styles'
import { EventEntity } from '@services/nomos_api/entities/event'
import { Indicator } from '@components/molecules/Status/Status.styles'
import { formatDate, formatTime } from '@utils/functions/formatter/formatter'

export type MiniEventCardProps = {
  event: EventEntity
  onClick: () => void
}

export default function MiniEventCard({ event, onClick }: MiniEventCardProps) {
  return (
    <Container onClick={onClick}>
      <Stack
        pr={2}
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="start"
      >
        <TextWrap lines={1}>
          <Typography variant="$font-title-5" color="$color-text-secondary">
            {event.description}
          </Typography>
        </TextWrap>
        {event.situation && (
          <Typography
            variant="$font-body-2"
            color="$color-action-text-secondary"
          >
            {event.situation}
          </Typography>
        )}
      </Stack>

      {event?.savedPropositions && event?.savedPropositions?.length > 0 ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Indicator color={colors['$color-semantic-success']} />
          <Typography variant="$font-body-base" color="$color-text-primary">
            {event?.savedPropositions?.length}{' '}
            {event?.savedPropositions?.length === 1
              ? 'proposição'
              : 'proposições'}
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            que você monitora{' '}
            {event?.savedPropositions?.length === 1 ? 'está' : 'estão'} na pauta
          </Typography>
        </Stack>
      ) : (
        <Typography variant="$font-body-2" color="#556282">
          Não há proposições deste painel em pauta
        </Typography>
      )}

      <CardEventDate>
        <div>
          <img src={CalendarSVG} alt="Eventos" />

          <Typography variant="$font-body-base" color="$color-text-primary">
            {formatDate(event?.dateStart)}
          </Typography>
        </div>

        <div>
          <img src={CalendarSVG} alt="Eventos" />

          <Typography variant="$font-body-base" color="$color-text-primary">
            {formatTime(event?.dateStart)}
          </Typography>
        </div>
      </CardEventDate>
    </Container>
  )
}
