import { Box, Typography, styled } from '@mui/material'
import { Close } from '@mui/icons-material'

export const Container = styled(Box)`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 460px;
`

export const Content = styled(Box)`
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;  
  flex-direction: column;
  align-items: flex-start;
  margin: 32px 32px 0px 32px;
`

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
`

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2d3a40;
`

export const IconClose = styled(Close)`
  font-size: 24px;
  color: #6d787d;
`

export const TextLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: '#2d3a40'
})

export const ContentAction = styled(Box)`
  gap: 12px;
  width: 100%;
  display: flex;
  height: 60px;
  margin-top: 24px;
  margin-bottom: 32px;
  align-items: center;
`
