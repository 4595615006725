import moment from 'moment-timezone'
import { NoDataIcon } from '@components/icons'
import { Box, CircularProgress } from '@mui/material'
import { CardEvent } from '@components/organisms/Cards'
import { useNavigate, useParams } from 'react-router-dom'
import { EventEntity } from '@services/nomos_api/entities/event'
import { useSchedule } from '@services/nomos_api/resources/search/events'

export default function Events() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: events, isFetching: isLoadingEvents } = useSchedule(
    {
      stakeholders: [id],
      date: {
        from: moment().startOf('day').format('YYYY-MM-DD'),
        to: moment().add(7, 'days').format('YYYY-MM-DD'),
      },
    },
    'older'
  )

  const handleClick = (id: string) => {
    navigate(`/events/${id}`)
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          maxHeight: '235px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          overflow: 'scroll',
        }}
      >
        {isLoadingEvents && (
          <CircularProgress data-testid="loading" color="primary" size={15} />
        )}

        {events?.results?.map((event: EventEntity) => (
          <CardEvent
            key={event.id}
            event={event}
            handleOpenModalDetailsEvent={() => handleClick(event.id)}
          />
        ))}

        {!isLoadingEvents && events?.results?.length === 0 && (
          <NoDataIcon
            text="Não foram encontrados eventos nos próximos 7 dias para o stakeholder"
            height={30}
          />
        )}
      </Box>
    </Box>
  )
}
