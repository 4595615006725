/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { add, lightFormat, parse, sub } from 'date-fns'
import { CalendarMonth, Close } from '@mui/icons-material'
import { DateRangePicker } from './DateRangePicker.styles'

import {
  Stack,
  Divider,
  InputAdornment,
  Popover,
  TextField,
  IconButton,
  MenuList,
  MenuItem,
  Typography,
} from '@mui/material'
import { Range, RangeKeyDict } from 'react-date-range'

export type DateRangePickerOption = {
  label: string
  days: number
}

export type DateRangePickerProps = {
  sx?: any
  size: any
  label: string
  startDate: string | undefined
  endDate: string | undefined
  disabled?: boolean
  onChange: (startDate: string | undefined, endDate: string | undefined) => void
  options?: DateRangePickerOption[]
  axisDirection?: 'left' | 'right'
}

const detectMob = () => window.innerWidth <= 800

function DateRangePickerComponent({
  sx,
  size,
  label,
  onChange,
  startDate,
  endDate,
  disabled,
  axisDirection = 'left',
  options = [
    { label: 'Última semana', days: -7 },
    { label: 'Últimos 30 dias', days: -30 },
    { label: 'Últimos 60 dias', days: -60 },
    { label: 'Últimos 90 dias', days: -90 },
    { label: 'Últimos 120 dias', days: -120 },
  ],
}: DateRangePickerProps) {
  // <!-- define popopver controller -->
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? 'daterange-popover' : undefined

  // <!-- defines initial data -->
  const today = new Date()
  const [clear, setClear] = useState(false)
  const [selection, setSelection] = React.useState<Range>({
    startDate:
      (startDate && parse(startDate, 'yyyy-MM-dd', today)) || undefined,
    endDate: (endDate && parse(endDate, 'yyyy-MM-dd', today)) || undefined,
    key: 'selection',
  })

  // <!-- handle select by last n days -->
  const handleSelectLastDays = (days: number) => {
    const currentDay = new Date()

    const fromDay =
      days < 0 ? sub(currentDay, { days: Math.abs(days) }) : currentDay

    const toDay =
      days < 0 ? new Date() : add(currentDay, { days: Math.abs(days) })

    setSelection({
      startDate: fromDay,
      endDate: toDay,
      key: 'selection',
    })
  }

  // <!-- handle picker selection -->
  const handleSelect = ({ selection }: RangeKeyDict) => {
    setSelection(selection)
    setClear(false)
  }

  // <!-- handle clear selected date -->
  const handleClear = () => {
    setClear(true)
    setSelection({
      key: 'selection',
    })
  }

  // <!-- watch selection changed to dispach -->
  React.useEffect(() => {
    if (clear && !selection.startDate && !selection.endDate) {
      handleClose()
      onChange(undefined, undefined)
    } else if (selection.startDate !== selection.endDate && onChange) {
      handleClose()
      onChange(
        selection.startDate
          ? lightFormat(selection.startDate, 'yyyy-MM-dd')
          : undefined,
        selection.endDate
          ? lightFormat(selection.endDate, 'yyyy-MM-dd')
          : undefined
      )
    }
  }, [clear, selection])

  // <!-- mount display value -->
  const displayValue = React.useMemo(() => {
    if (!selection.startDate) return ''
    if (!selection.endDate) return ''
    return `${lightFormat(selection.startDate, 'dd/MM/yyyy')} - ${lightFormat(
      selection.endDate,
      'dd/MM/yyyy'
    )}`
  }, [selection])

  return (
    <>
      <TextField
        data-cy="dateRangePicker"
        fullWidth
        size={size}
        sx={sx}
        label={label}
        type="text"
        variant="outlined"
        value={displayValue}
        onClick={handleOpen}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: '-8px' }}>
              {displayValue.length > 0 ? (
                <IconButton size="small" onClick={handleClear}>
                  <Close fontSize={size} sx={{ color: '#B6BCC9' }} />
                </IconButton>
              ) : (
                <IconButton size="small">
                  <CalendarMonth fontSize={size} sx={{ color: '#B6BCC9' }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: axisDirection,
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: axisDirection,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            marginLeft: '1px',
            marginTop: '8px',
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.15) 0px 0px 0px 1px',
          },
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <MenuList sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuItem disabled>
              <Typography variant="$font-body-base" color="muted.dark">
                Períodos pré definidos
              </Typography>
            </MenuItem>
            {options.map(({ label, days }) => (
              <MenuItem
                key={`predef-period-${label}`}
                onClick={() => handleSelectLastDays(days)}
              >
                <Typography variant="$font-body-base" color="muted.dark">
                  {label}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
          <DateRangePicker
            months={detectMob() ? 1 : 2}
            locale={ptBR}
            ranges={[selection]}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            weekdayDisplayFormat="EEEEE"
            onChange={handleSelect}
            direction="horizontal"
          />
        </Stack>
      </Popover>
    </>
  )
}

DateRangePickerComponent.defaultProps = {
  sx: undefined,
}

export default DateRangePickerComponent
