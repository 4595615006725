import qs from 'qs'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { PagiantedType } from '@services/nomos_api/entities/paginated'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'

export type FindSavedBucketsFilterProps = Partial<
  Pick<SavedBucketEntity, 'monitorId' | 'originId'>
> & {
  buckets?: string[]
  period?: {
    from?: string | undefined
    to?: string | undefined
  }
}

export type FindSavedBucketsProps = {
  page?: number
  limit?: number
  filters?: FindSavedBucketsFilterProps
}

export const findSavedBucketsApi = async (
  props: FindSavedBucketsProps
): Promise<PagiantedType<SavedBucketEntity> | SavedBucketEntity[]> => {
  const query = ['sort=desc']
  if (props.page) {
    query.push(`page=${props.page}`)
  }
  if (props.limit) {
    query.push(`limit=${props.limit}`)
  }
  if (props.filters?.buckets && props.filters?.buckets?.length > 0) {
    query.push(`bucket=${props.filters.buckets.join(',')}`)
  }
  if (props.filters?.monitorId && props.filters?.monitorId.length > 0) {
    query.push(`monitorId=${props.filters.monitorId}`)
  }
  if (props.filters?.originId && props.filters?.originId.length > 0) {
    query.push(`originId=${props.filters.originId}`)
  }
  if (props.filters?.period?.from && props.filters?.period?.from.length > 0) {
    query.push(`from=${props.filters.period.from}`)
  }
  if (props.filters?.period?.to && props.filters?.period?.to.length > 0) {
    query.push(`to=${props.filters.period.to}`)
  }
  const { data } = await HTTPClient.get(`/favorites?${query.join('&')}`)
  return data as PagiantedType<SavedBucketEntity> | SavedBucketEntity[]
}

export const useFindSavedBuckets = (props: FindSavedBucketsProps) =>
  useQuery(
    ['find-saved-buckets', qs.stringify(props)],
    () => findSavedBucketsApi(props),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
