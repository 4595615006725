import { Bucket, BucketsEnum } from './type'

const BucketRegulatoryAgencies: Bucket = {
  key: BucketsEnum.regulatory_agencies,
  name: 'Agências Reguladoras',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'openDataResource',
      label: 'Agência',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [
        { key: 'anac', value: 'ANAC' },
        { key: 'ans', value: 'ANS' },
        { key: 'anvisa', value: 'ANVISA' },
      ],
    },
  ],
}

export default BucketRegulatoryAgencies
