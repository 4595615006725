import { styled as StyledMui, Box } from '@mui/material'

export const PageContainer = StyledMui(Box)(({ theme }) => ({
  backgroundColor: '#F9FAFC',
  padding: 16,
  [theme.breakpoints.up('md')]: {
    padding: '72px',
  },
}))

export const CardsContainer = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  flexWrap: 'wrap',

  justifyContent: 'space-between',
  marginTop: '19px',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
  },
}))
