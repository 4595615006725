import { Card } from '@components/atoms'
import { styled as StyledMui, Box } from '@mui/material'

export const CardInfo = StyledMui(Card)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'white',
  padding: 16,
  flexWrap: 'wrap',
  gap: 24,
  [theme.breakpoints.up('md')]: {
    paddingLeft: '32px',
    paddingTop: '16px',
    paddingBottom: '16px',
    gap: 64,
  },
}))

export const Info = StyledMui(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  gap: 'unset',
  [theme.breakpoints.up('md')]: {
    gap: '20px',
  },
}))

export const Status = StyledMui(Box)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
}))

export const CardContainer = StyledMui(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '19px',
}))

export const CardFilter = StyledMui(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '592px',
  height: '490px',
  padding: '24px',
}))

export const ContainerColumn = StyledMui(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
}))
