/* eslint-disable no-unused-vars */
import React from 'react'
import MonitorController from './controller'
import MonitorView from './view'

export default function Monitor() {
  return (
    <MonitorController>
      <MonitorView />
    </MonitorController>
  )
}
