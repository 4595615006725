import { ModalMonitorReportController } from './controller'
import { ModalMonitorReportProps } from './types'
import { ModalMonitorReportView } from './view'

export function ModalMonitorReport(
  props: ModalMonitorReportProps
): JSX.Element {
  return (
    <ModalMonitorReportController {...props}>
      <ModalMonitorReportView />
    </ModalMonitorReportController>
  )
}
