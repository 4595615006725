import {
  styled,
  Table,
  Box,
  Stack,
  tableHeadClasses,
  tableCellClasses,
} from '@mui/material'
import { theme } from '@theme'

export const Wrapper = styled(Box)(() => ({
  minHeight: '100vh',
  height: 'auto',
  backgroundColor: '#F9FAFC',
}))

export const Container = styled(Box)(() => ({
  margin: '0 auto',
  height: '100%',
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '88%',
}))

export const ContainerPadding = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 40,
  paddingBottom: 24,
}))

export const Content = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  width: '100%',
  marginTop: 24,
}))

export const TabContent = styled(Box)(() => ({
  display: 'flex',
  minHeight: 300,
  borderRadius: 6,
  backgroundColor: theme.palette.common.white,
}))

export const TabContentInner = styled((props) => <Stack {...props} direction="column" spacing={3} />)(({theme}) => ({
  flex:1,
  width: '100%',
  padding: 16,
  [theme.breakpoints.up("md")]: {
    paddingTop: '32px',
    paddingLeft: '47px',
    paddingRight: '47px',
    paddingBottom: '32px',
  },
}))

export const NomosTable = styled(Table)(({ theme }) => ({
  [`& .${tableHeadClasses.root}`]: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 3,
    [`& .${tableCellClasses.root}`]: {
      fontWeight: 'bold',
    },
  },
}))
