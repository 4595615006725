import { Chip } from '@components/atoms'
import emotionStyled from '@emotion/styled'
import { styled } from '@mui/material'

export const Wrapper = emotionStyled.div`
  padding-top: 0px;
`

export const AdaptiveChip = styled(Chip)`
  margin-right: 8px;
  padding-left: 3px;
  padding-right: 3px;
  max-height: 24px;
  font-size: 12px;
  margin-bottom: 6px;
  max-width: 170px !important;
  color: ${(props) => props.theme.palette['$color-text-primary']};
  background-color: ${(props) =>
    props.theme.palette['$color-background-tertiary']};
  border: none;
`

export const CountChip = styled(Chip)`
  color: white;
  height: 24px;
  font-size: 10px;
  font-weight: 600;
  border: none;
  background-color: ${(props) =>
    props.theme.palette['$color-background-alternative']};
  & > span {
    padding-left: 6px;
    padding-right: 6px;
  }
`
