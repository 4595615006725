import { SpeechDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function SpeechDetailsPage() {
  return (
    <SpeechDetailsController>
      <SpeechDetailsView />
    </SpeechDetailsController>
  )
}

export default SpeechDetailsPage
