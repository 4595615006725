import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { HighlightEntity } from '@services/nomos_api/entities/highlight'

export type SpeechEntity = {
  id: string
  acronymParty: string
  content: string
  createdAt: string
  date: string
  openDataResource: string
  speaker: string
  summary: string
  type: string
  uf: string
  updatedAt: string
  camara: {
    insertionNumber: string
    sessionNumber: string
    roomNumber: string
    code: string
  }
  savedBuckets: SavedBucketEntity[]
  highlights?: HighlightEntity
}

export const getSpeechByIdAPI = async (id: string): Promise<SpeechEntity> => {
  const { data } = await HTTPClient.get(`speeches/${id}`)
  return data as SpeechEntity
}

export const useGetSpeechDetails = (id: string) =>
  useQuery(['get-speech-details'], () => getSpeechByIdAPI(id), {
    refetchOnWindowFocus: false,
  })
