import { theme } from '@theme/index'
import styled from '@emotion/styled'

export const HiddenInput = styled.input`
  display: none;
`

export const Touchable = styled.label<{ outlined: 'true' | 'false' }>`
  cursor: pointer;
  user-select: none;
  & input[type='radio']:checked + div {
    border-color: ${theme.palette['$color-action-background-primary-default']};
    ::before {
      content: ' ';
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      background-color: ${theme.palette[
        '$color-action-background-primary-default'
      ]};
      border-radius: 20px;
    }
  }
  ${({ outlined }) =>
    outlined === 'true' &&
    `
    padding: 6px 12px;
    border: 1px solid ${theme.palette['$color-border-tertiary']};
    border-radius: 4px;
  `}
`

export const Radio = styled.div<{ size: '12' | '16' }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.palette['$color-border-tertiary']};
  position: relative;
`
