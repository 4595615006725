import { MonitoringContext } from '../../context'
import { TabSelector } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'
import { MonitoringTab, MonitoringTabKeys } from '../../types'

export function MonitoringTabsPanels(): JSX.Element {
  const setSelectedTab = useContextSelector(
    MonitoringContext,
    (s) => s.setSelectedTab
  )

  return (
    <TabSelector
      tabs={[
        { value: MonitoringTab.monitor, label: 'Monitorar' },
        { value: MonitoringTab.unmonitor, label: 'Deixar de Monitorar' },
      ]}
      initialTab={MonitoringTab.monitor}
      onChangeTab={(tab) => setSelectedTab(tab as unknown as MonitoringTabKeys)}
    />
  )
}
