import React from 'react'
import { signatureEvent } from '@utils/metrics'
import { WhatsAppPaymentLinkContext } from './context'
import { WhatsAppPaymentLinkControllerProps } from './types'
import { useWhatsapp } from '@hooks/useWhatsapp'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'
import { useAuth } from '@contexts/Auth'

export function WhatsAppPaymentLinkController({
  method,
  children,
}: WhatsAppPaymentLinkControllerProps): JSX.Element {
  const { user } = useAuth()
  const { handleSendMessage } = useWhatsapp()

  const getProductSeats = (product?: NomosProductCodeEnum): number => {
    if (!product) return 0
    return (
      user?.company?.subscription?.products.find((p) => p.code === product)
        ?.quantity || 0
    )
  }

  const generateMessageFromMethod = (method?: NomosProductCodeEnum): string => {
    // return an interest message if user not registered
    if (!user) return 'Olá, tenho interesse em testar a *Nomos* gratuitamente.'

    // return other communication messages if user registered
    const messages = [
      `Olá, sou *${user?.name}* da empresa *${user?.company?.name}*.`,
    ]

    try {
      const seats = getProductSeats(method)
      switch (method) {
        case NomosProductCodeEnum.MONITOR_PRO:
          messages.push(
            !user?.company?.subscription
              ? `Ainda não possuo uma assinatura ativa na *Nomos*, mas gostaria de adquirir assentos no plano *Monitor Pro*.`
              : `Atualmente possuo ${seats} assento(s) no meu plano *Monitor Pro* e gostaria de aumentar essa quantidade.`
          )
          break
        case NomosProductCodeEnum.MONITOR_LIGHT:
          messages.push(
            !user?.company?.subscription
              ? `Ainda não possuo uma assinatura ativa na *Nomos*, mas gostaria de adquirir assentos no plano *Monitor Light*.`
              : `Atualmente possuo ${seats} assento(s) no meu plano *Monitor Light* e gostaria de aumentar essa quantidade.`
          )
          break
        default:
          throw new Error('Method not defined')
      }
    } catch (error) {
      messages.push(
        !user?.company?.subscription
          ? `Ainda não possuo uma assinatura ativa na *Nomos*, e gostaria de ajuda para encontrar a melhor opção de plano para a minha empresa.`
          : `Já possuo uma assinatura ativa na *Nomos* e gostaria de fazer um upgrade no meu plano.`
      )
    }
    return messages.join('\n')
  }

  const handlePaymentContact = () => {
    signatureEvent()
    handleSendMessage(
      process.env.REACT_APP_PHONE_CONTACT!,
      generateMessageFromMethod(method)
    )
  }

  const state = React.useMemo(
    () => ({
      handlePaymentContact,
    }),
    [handlePaymentContact]
  )

  return (
    <WhatsAppPaymentLinkContext.Provider value={state}>
      {children}
    </WhatsAppPaymentLinkContext.Provider>
  )
}
