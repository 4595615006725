import { styled, Typography } from '@mui/material'

const TypographyWrap = styled(Typography)((props) => ({
  width: 'auto',
  maxWidth: props.maxWidth ? `${props.maxWidth}px` : '220px',
  overflow: 'hidden',
  wordWrap: 'break-word',
  textOverflow: 'ellipsis',
}))

export default TypographyWrap
