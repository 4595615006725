import { Box, styled } from '@mui/material'
import { theme } from '@theme/index'

export const BlockedScreenIcon = styled(Box)<{ color: string }>`
  background-color: ${(props) => `${props.color || theme.palette.primary}`};
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 64px;
  justify-content: center;
`
