import { createContext } from 'use-context-selector'
import { ListMonitorResult } from '@services/nomos_api/resources/monitor/list'

export type MonitorsContextType = {
  isLoading: boolean
  type: string
  monitors?: ListMonitorResult
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleRefetchMonitors: () => void
  handleTypeChange: (type: string) => void
  handleTextChange: (text: string) => void
}

export const MonitorsContext = createContext<MonitorsContextType>(
  {} as MonitorsContextType
)
