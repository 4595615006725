import { TagPopover } from '../../styles'
import { createRef, useState } from 'react'
import { TagManager } from '@containers/TagManager'
import { PopoverActions } from '@mui/material/Popover'
import { SizedComponent, Tag } from '@components/atoms'
import { CircularProgress, Typography } from '@mui/material'
import { TagEntity } from '@services/nomos_api/entities/tag'
import {
  ArrowRightSmall,
  CountChip,
  InlineTagsContainer,
  InlineTagsContent,
  InlineTagsSpread,
} from './styles'
import { useUpdateSavedProposition } from '@services/nomos_api/resources/monitor/update-saved-proposition'
import { useContextSelector } from 'use-context-selector'
import { PropositionsPanelContext } from '../../context'
import { useAuth } from '@contexts/Auth'
import { useGetMonitorUsers } from '@services/nomos_api/resources/monitor_users/get-monitors-users'

type PropositionCellTagsProps = {
  initialTags?: TagEntity[]
  savedPropositionId: string
}

export function PropositionCellTags({
  initialTags,
  savedPropositionId,
}: PropositionCellTagsProps): JSX.Element {
  const popoverRef = createRef<PopoverActions>()
  const [tags, setTags] = useState<TagEntity[]>(initialTags || [])
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? 'proposition-tags-popover' : undefined
  const monitorId = useContextSelector(PropositionsPanelContext, (s) => s?.id)
  const { user } = useAuth()
  const { data: users } = useGetMonitorUsers({ id: monitorId, q: '' })
  const { isLoading, mutate } = useUpdateSavedProposition()

  const userHasEditAccess = !!users?.find(
    (monitorUser) =>
      monitorUser.id === user?.id &&
      ['ADMIN', 'EDITOR'].includes(monitorUser.role)
  )

  const setAnchorClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLIElement>
  ) => setAnchorEl(event.currentTarget)

  const handleClick = userHasEditAccess ? setAnchorClick : undefined

  const handleUpdateTags = (tags?: TagEntity[]) => {
    if (!tags) return
    setTags(tags)
    handleClose()
    mutate({ tagIds: tags.map((tag) => tag.id), savedPropositionId })
  }

  return (
    <>
      <InlineTagsContainer onClick={handleClick}>
        <InlineTagsContent>
          {tags.length === 0 && (
            <Typography variant="$font-body-3" sx={{ px: 1 }}>
              Gerenciar tags
            </Typography>
          )}
          {tags.length > 0 && (
            <InlineTagsSpread>
              <Tag {...tags?.[0]} size="tiny" fullwidth />
            </InlineTagsSpread>
          )}
          {tags.length > 1 && (
            <CountChip label={`+${tags.length - 1}`} color="primary" />
          )}
        </InlineTagsContent>
        {!isLoading && <ArrowRightSmall />}
        {isLoading && <CircularProgress size={16} />}
      </InlineTagsContainer>
      <TagPopover
        id={id}
        open={open}
        action={popoverRef}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <SizedComponent onChange={() => popoverRef?.current?.updatePosition()}>
          <TagManager
            compact
            initialTags={tags}
            onTags={handleUpdateTags}
            handleClose={handleClose}
          />
        </SizedComponent>
      </TagPopover>
    </>
  )
}
