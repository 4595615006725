import { fetchSecaoOptions } from '@services/dados_abertos/resources/secao_options'
import { searchOptionsAPI } from '@services/nomos_api/resources/search/options'
import { Bucket, BucketsEnum, FilterOptionsProps } from './type'

const BucketDou: Bucket = {
  key: BucketsEnum.dou,
  name: 'Diário Oficial da União',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Publicados recentemente',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: false,
      attr: 'date',
      label: 'Selecione o dia desejado',
    },
  ],
  filters: [
    {
      attr: 'section',
      label: 'Seções',
      enabled_input: false,
      enabled_online_filter: false,
      options: fetchSecaoOptions,
    },
    {
      attr: 'principal_organization',
      label: 'Organização Principal',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async (pagination: FilterOptionsProps) => {
        return searchOptionsAPI('dou', 'hierarchy', [], pagination)
      },
    },
  ],
}

export default BucketDou
