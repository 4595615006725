import { AutocompleteRenderInputParams, TextField } from '@mui/material'

type Props = {
  label: string
  params: AutocompleteRenderInputParams
  onChangeTextSearch: ((text: string) => void) | undefined
}

export function RenderInput({
  label,
  params,
  onChangeTextSearch,
}: Props): JSX.Element {
  return (
    <TextField
      onChange={(e) => onChangeTextSearch?.(e.target.value)}
      label={label}
      {...params}
    />
  )
}
