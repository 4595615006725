import { MonitorContext } from './context'
import { useSnackbar } from '@contexts/Snackbar'
import { useState, useMemo, useCallback } from 'react'
import { useGetMonitorById } from '@services/nomos_api/resources/monitor/getById'
import { cloneMonitorByIdAPI } from '@services/nomos_api/resources/monitor/clone'
import { deleteMonitorByIdAPI } from '@services/nomos_api/resources/monitor/delete'
import { useParams, useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'

export default function MonitorsController({ children }) {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const clone = searchParams.get('clone')

  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState(tab || 'general')

  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()

  const monitor = useGetMonitorById({
    id,
  })

  const handleExcludeMonitor = useCallback(async () => {
    try {
      await deleteMonitorByIdAPI(id)

      showSnackbarSuccess('Painel excluido com sucesso')
      navigate('/monitors')
    } catch (error) {
      showSnackbarError(
        error?.response?.data?.message || 'Erro ao excluir o painel'
      )
    }

    setTimeout(closeSnackbar, 3000)
  }, [id])

  const handleCloneMonitor = useCallback(async (databases) => {
    try {
      await cloneMonitorByIdAPI(id, { databases })
      showSnackbarSuccess('Painel clonado com sucesso')
      navigate('/monitors')
    } catch (error) {
      showSnackbarError(
        error?.response?.data?.message || 'Erro ao clonar o painel'
      )
    }

    setTimeout(closeSnackbar, 3000)
  }, [id])

  const handleChangeTab = useCallback((tab) => {
    setSelectedTab(tab)
    navigate({
      search: `${createSearchParams({ tab })}`
    }, {
      replace: true
    })
  }, [])

  const handleUpdateMonitor = useCallback(() => {
    monitor.refetch()
  }, [monitor])

  const state = useMemo(
    () => ({
      clone,
      monitor,
      selectedTab,
      handleChangeTab,
      handleCloneMonitor,
      handleUpdateMonitor,
      handleExcludeMonitor,
    }),
    [
      clone,
      monitor,
      selectedTab,
      handleChangeTab,
      handleCloneMonitor,
      handleUpdateMonitor,
      handleExcludeMonitor,
    ]
  )

  return (
    <MonitorContext.Provider value={state}>
      {children}
    </MonitorContext.Provider>
  )
}
