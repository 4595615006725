import { Typography, Link } from '@mui/material'
import { SignUpContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { Button, Input } from '@components/atoms'
import PasswordRequirements from '@components/atoms/PasswordRequirements'

import {
  SignUpForm,
  ColumnElements,
  InputBox,
  TermsBox,
  TitleContainer,
  ButtonContainer,
} from './FormSignupEmail.styles'
import InputPhone from '@components/atoms/InputPhone'

export function FormSignUpEmail(): JSX.Element {
  const formik = useContextSelector(SignUpContext, (s) => s.formikSU)

  return (
    <SignUpForm>
      <ColumnElements>
        <TitleContainer>
          <Typography
            data-testid="title"
            variant="$font-title-2"
            color="$color-text-primary"
            sx={{
              fontSize: '24px',
              lineHeight: '32px',
            }}
          >
            Cadastre-se
          </Typography>
          <Typography
            variant="$font-body-2"
            color="#606162"
            sx={{ fontWeight: 400 }}
          >
            O e-mail corporativo é necessário para cadastro.
          </Typography>
        </TitleContainer>

        <InputBox>
          <Typography
            variant="$font-body-2"
            color="$color-text-primary"
            sx={{ fontWeight: 400 }}
          >
            Nome e Sobrenome
          </Typography>
          <Input
            id="name"
            name="name"
            label="Nome e sobrenome"
            placeholder="Digite seu nome"
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helpertext={formik.touched.name && formik.errors.name}
          />
        </InputBox>

        <InputBox>
          <Typography
            variant="$font-body-2"
            color="$color-text-primary"
            sx={{ fontWeight: 400 }}
          >
            E-mail corporativo
          </Typography>
          <Input
            type="email"
            id="email"
            name="email"
            label="E-mail corporativo"
            placeholder="nome@empresa.com.br"
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helpertext={formik.touched.email && formik.errors.email}
          />
        </InputBox>

        <InputBox>
          <Typography
            variant="$font-body-2"
            color="$color-text-primary"
            sx={{ fontWeight: 400 }}
          >
            Número de telefone
          </Typography>
          <InputPhone
            id="phone"
            name="phone"
            label="Telefone"
            placeholder="(__) _____-____"
            size="small"
            value={formik.values.phone}
            onBlur={(e) => formik.handleBlur(e)}
            onChange={(value) => formik.setFieldValue('phone', value)}
            error={
              (formik.touched.phone &&
                formik.errors.phone) as unknown as undefined
            }
          />
        </InputBox>

        <InputBox>
          <Typography
            variant="$font-body-2"
            color="$color-text-primary"
            sx={{ fontWeight: 400 }}
          >
            Senha de acesso
          </Typography>
          <Input
            type="password"
            id="password"
            name="password"
            label="Senha de acesso"
            placeholder="Senha (pelo menos 8 caracteres)"
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.password &&
                formik.errors.password) as unknown as undefined
            }
          />
          {formik.values.password?.length > 0 && (
            <PasswordRequirements password={formik.values.password} />
          )}
        </InputBox>

        <TermsBox>
          <Input
            type="checkbox"
            id="termsOfUse"
            name="termsOfUse"
            checked={formik.values.termsOfUse}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.termsOfUse && Boolean(formik.errors.termsOfUse)
            }
            helpertext={formik.touched.termsOfUse && formik.errors.termsOfUse}
          />
          <Typography variant="$font-body-base" color="$color-text-tertiary">
            Concordo com{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://nomos-documentos.s3.sa-east-1.amazonaws.com/termos_de_uso.pdf"
              underline="none"
            >
              Termos de Uso
            </Link>{' '}
            e{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://nomos-documentos.s3.sa-east-1.amazonaws.com/politica_de_privacidade.pdf"
              underline="none"
            >
              Política de Privacidade
            </Link>
          </Typography>
        </TermsBox>

        <ButtonContainer>
          <Button
            type="submit"
            id="create-account-bnt"
            size="large"
            text="Criar conta"
            loading={formik.isSubmitting}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={() => formik.handleSubmit()}
            sx={{ width: '260px' }}
          />
        </ButtonContainer>
      </ColumnElements>
    </SignUpForm>
  )
}
