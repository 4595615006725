import { Bucket, BucketsEnum } from './type'

const BucketBacen: Bucket = {
  key: BucketsEnum.bacen,
  name: 'Banco Central',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'type',
      label: 'Tipo de documento',
      enabled_input: false,
      enabled_online_filter: false,
      options: async () => [
        { key: 'Ato de Diretor', value: 'Ato de Diretor' },
        { key: 'Ato Normativo Conjunto', value: 'Ato Normativo Conjunto' },
        { key: 'Ato do Presidente', value: 'Ato do Presidente' },
        { key: 'Carta Circular', value: 'Carta Circular' },
        { key: 'Circular', value: 'Circular' },
        { key: 'Comunicado', value: 'Comunicado' },
        { key: 'Comunidado Conjunto', value: 'Comunidado Conjunto' },
        { key: 'Decisão Conjunta', value: 'Decisão Conjunta' },
        { key: 'Instrução Normativa BCB', value: 'Instrução Normativa BCB' },
        {
          key: 'Instrução Normativa Conjunta',
          value: 'Instrução Normativa Conjunta',
        },
        { key: 'Portaria Conjunta', value: 'Portaria Conjunta' },
        { key: 'Resolução BCB', value: 'Resolução BCB' },
        { key: 'Resolução CMN', value: 'Resolução CMN' },
        { key: 'Resolução Conjunto', value: 'Resolução Conjunto' },
        { key: 'Resolução Coremec', value: 'Resolução Coremec' },
      ],
    },
  ],
}

export default BucketBacen
