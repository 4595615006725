import { TextWrap } from '@components/atoms'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

type PropositionCellTextProps = {
  value: string
  tooltipValue?: string
}

export function PropositionCellText({
  value,
  tooltipValue,
}: PropositionCellTextProps): JSX.Element {
  return (
    <Tooltip title={tooltipValue || value || ''}>
      <span>
        <TextWrap lines={2}>
          <Typography variant="$font-body-3" color="$color-text-primary">
            {value}
          </Typography>
        </TextWrap>
      </span>
    </Tooltip>
  )
}
