import React from 'react'
import { uid } from 'uid/single'
import { theme } from '@theme/index'
import { Button } from '@components/atoms'
import { isMobile } from 'react-device-detect'
import { Stack, Typography } from '@mui/material'
import { PropositionDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { formatDateTime } from '@utils/functions/formatter/formatter'

import {
  TimelineItem,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
} from '@mui/lab'

import { TimelineTramitation } from './styles'

const LAST_FIVE = 5

export function Tramitations() {
  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  const proceedings = React.useMemo(() => {
    if (!proposition || !proposition.proceedings) return []
    return (proposition.proceedings || [])
      .slice(proposition.proceedings.length - LAST_FIVE, proposition.proceedings.length)
      .reverse()
  }, [proposition])

  return (
    <TimelineTramitation>
      {proceedings.map((proceeding) => (
        <TimelineItem key={`proceeding-${uid()}`}>
          <TimelineSeparator>
            <TimelineDot variant="filled" color="$color-background-dark" sx={{ backgroundColor: theme.palette['$color-background-dark']}} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
              <Typography variant="$font-title-5" color="$color-text-primary">
                {proceeding?.organ?.initials || '--'}
              </Typography>
              <Typography variant="$font-body-3" color="$color-text-primary">
                {formatDateTime(proceeding?.date)}
              </Typography>
              <Typography variant="$font-body-base" color="$color-text-primary">
                {proceeding?.dispatch || proceeding?.description || '--'}
              </Typography>
              {proceeding?.url && !isMobile && (
                <div>
                  <Button
                    variant="text"
                    size="tiny"
                    text="Inteiro Teor"
                    href={proceeding?.url}
                    target="_blank"
                    sx={{ px: 1, marginLeft: "-8px"}}
                  />
                </div>
              )}
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineTramitation>
  )
}
