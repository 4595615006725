import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { ArrowOutwardOutlined } from '@mui/icons-material'
import { MouseEvent, HTMLAttributeAnchorTarget } from 'react'
import { Button, circularProgressClasses } from '@mui/material'

const defaultProps = {
  loading: undefined,
  target: '_blank',
  rel: 'noreferrer',
  icon: undefined,
  light: undefined,
}

interface ButtonComponentProps extends ButtonProps {
  loading?: boolean
  text?: string | JSX.Element
  target?: HTMLAttributeAnchorTarget
  rel?: string
  icon?: string
  light?: boolean
  badgeCount?: number
  'data-cy'?: string
}

function ButtonComponent(props: ButtonComponentProps) {
  const navigate = useNavigate()

  const {
    id,
    text,
    loading,
    icon,
    light = false,
    variant = 'contained',
    href,
    target,
    onClick,
  } = props

  const handleOnClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    // execute custom onclick if available
    if (onClick) onClick(e)
    // return if href is not setted
    if (!href) return
    const absoluteURLPattern = /^(?:[a-z]+:)?\/\//i
    // Create a RegExp object using the pattern
    const regex = new RegExp(absoluteURLPattern)
    // Check if the URL matches the regular expression
    const isAbsolute = regex.test(href)
    if ((!target && !e.ctrlKey) || isAbsolute || isMobile) {
      e.preventDefault()
      if (isAbsolute) window.open(href, '_blank')
      else navigate(href)
    }
  }

  if (loading === true) {
    return (
      <LoadingButton
        id={id}
        {...props}
        variant={variant}
        data-testid={id || 'button-loading-component'}
        sx={{
          [`& .${circularProgressClasses.root}`]: {
            color: light ? 'white' : undefined,
          },
          backgroundColor: variant === 'outlined' ? 'white' : undefined,
          whiteSpace: 'nowrap',
          // eslint-disable-next-line react/destructuring-assignment
          ...(props.sx || {}),
        }}
        loading
        href={href}
        onClick={handleOnClick}
      >
        {text}
      </LoadingButton>
    )
  }

  if (icon === 'arrow') {
    return (
      <Button
        {...props}
        id={id}
        variant={variant}
        data-testid={id || 'button-component'}
        sx={{
          backgroundColor: variant === 'outlined' ? 'white' : undefined,
          whiteSpace: 'nowrap',
          // eslint-disable-next-line react/destructuring-assignment
          ...(props.sx || {}),
        }}
        endIcon={
          <ArrowOutwardOutlined
            sx={{
              '&.MuiSvgIcon-root': {
                fontSize: '12px',
              },
            }}
          />
        }
        href={href}
        onClick={handleOnClick}
      >
        {text}
      </Button>
    )
  }

  return (
    <Button
      {...props}
      id={id}
      data-testid={id || 'button-component'}
      variant={variant}
      sx={{
        backgroundColor: variant === 'outlined' ? 'white' : undefined,
        ':hover': {
          backgroundColor: variant === 'outlined' ? 'white' : undefined,
        },
        whiteSpace: 'nowrap',
        paddingLeft: text ? undefined : '9px',
        paddingRight: text ? undefined : '9px',
        minWidth: 0,
        '& .MuiButton-endIcon': { margin: text ? undefined : 0 },
        '& .MuiButton-startIcon': { margin: text ? undefined : 0 },
        // eslint-disable-next-line react/destructuring-assignment
        ...(props.sx || {}),
      }}
      href={href}
      onClick={handleOnClick}
    >
      {text}
    </Button>
  )
}

ButtonComponent.defaultProps = defaultProps

export default ButtonComponent
