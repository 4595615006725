import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 32px;
    gap: 32px;
  }
`

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow: auto;
  overflow-y: scroll;
  gap: 14px;
`
