import { uniqueId } from 'lodash'
import { Shimmer } from '@components/atoms'
import { Box, Breadcrumbs, Link } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

export type Path = {
  name: string
  location: string
}

export type BreadcrumbProps = {
  paths: Path[]
  isLoading?: boolean
}

function Breadcrumb({ paths, isLoading }: BreadcrumbProps) {
  return (
    <Box display="flex" alignItems="center">
      <NavigateBeforeIcon
        fontSize="inherit"
        color="disabled"
        sx={{ mr: '10px' }}
      />
      <Breadcrumbs separator="/">
        {paths.map((path, index) => {
          return (
            <Shimmer
              key={uniqueId()}
              width={100}
              height={10}
              isLoading={isLoading || false}
            >
              <Link
                key={path.name}
                variant="$font-body-base"
                underline="none"
                color={index < paths.length - 1 ? 'muted.main' : 'secondary'}
                href={path.location}
              >
                {path.name}
              </Link>
            </Shimmer>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}

Breadcrumb.defaultProps = {
  isLoading: false,
}

export default Breadcrumb
