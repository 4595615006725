import { useState } from 'react'
import { NoticeDetailsContext } from '../../context'
import { HighlightedText } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'
import { Divider, Stack, Typography } from '@mui/material'
import { Input, Shimmer, ShimmerText } from '@components/atoms'
import { formatDate, formatTime } from '@utils/functions/formatter/formatter'

import { Container } from './styles'

function ContentTextComponent() {
  const notice = useContextSelector(NoticeDetailsContext, (s) => s.notice)
  const keyword = useContextSelector(NoticeDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(NoticeDetailsContext, (s) => s.isLoading)

  const [highlighted, setHighlight] = useState(keyword || '')
  const text =
    notice?.content && notice?.content.length > 0
      ? notice.content
      : notice?.description

  return (
    <Container>
      <Stack direction="column" spacing={3}>
        <Stack
          gap={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="column" spacing={2}>
            <Shimmer isLoading={isLoading} height={26} width={120}>
              <Typography variant="$font-title-3" color="$color-text-primary">
                {notice?.title}
              </Typography>
            </Shimmer>
            <Stack direction="row" gap={3} flexWrap="wrap">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Data de publicação:
                </Typography>
                <Shimmer isLoading={isLoading} height={20}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {formatDate(notice?.date)}
                  </Typography>
                </Shimmer>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  Hora:
                </Typography>
                <Shimmer isLoading={isLoading} height={20}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {formatTime(notice?.date)}
                  </Typography>
                </Shimmer>
              </Stack>
            </Stack>
          </Stack>

          <Input
            type="search"
            size="small"
            label="Digite as palavras que deseja buscar"
            sx={{ width: { xs: '100%', md: '300px' } }}
            value={highlighted}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setHighlight(e.target.value)
            }
          />
        </Stack>

        <Divider />

        <ShimmerText isLoading={isLoading} height={14}>
          <HighlightedText text={text} highlight={highlighted} />
        </ShimmerText>
      </Stack>
    </Container>
  )
}

export default ContentTextComponent
