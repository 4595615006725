import MonitorLayout from '@layouts/monitor'
import OrganoGovLayout from '@layouts/organogov'
import InsightsLayout from '@layouts/insights'

// public
import SigninPageEmail from '@pages/public/Signinemail'
import SignupPageEmail from '@pages/public/Signupemail'
import NotFoundPage from '@pages/public/NotFound'
import FeedbackPage from '@pages/public/Feedback'
import AcceptUserPage from '@pages/public/AcceptUser'
import ResetPasswordPage from '@pages/public/ResetPassword'
import ForgotPasswordPage from '@pages/public/ForgotPassword'
import EmailConfirmationPage from '@pages/public/EmailConfirmation'

// private

import HomePage from '@pages/private/Home'
import UsersPage from '@pages/private/Users'
import OrgansPage from '@pages/private/Organs'
import MonitorPage from '@pages/private/Monitor'
import EventsPage from '@pages/private/Schedule'
import NewUserPage from '@pages/private/NewUser'
import MonitorsPage from '@pages/private/Monitors'
import EditUserPage from '@pages/private/EditUser'
import MyAccountPage from '@pages/private/MyAccount'
import IrsDetailsPage from '@pages/private/IrsDetails'
import UserDetailPage from '@pages/private/UserDetail'
import DouDetailsPage from '@pages/private/DouDetails'
import OrganDetailPage from '@pages/private/OrganDetail'
import SavedSearches from '@pages/private/SavedSearches'
import BacenDetailsPage from '@pages/private/BacenDetails'
import NoticeDetailPage from '@pages/private/NoticeDetail'
import MonitorUsersPage from '@pages/private/MonitorUsers'
import EventDetailsPage from '@pages/private/EventDetails'
import StakeholdersPage from '@pages/private/Stakeholders'
import SpeechDetailsPage from '@pages/private/SpeechDetails'
import SearchResultsPage from '@pages/private/SearchResults'
import VotingDetailsPage from '@pages/private/VotingDetails'
import StakeholderDetailsPage from '@pages/private/StakeholderDetail'
import PropositionDetailsPage from '@pages/private/PropositionDetails'
import OficialDiaryDetailPage from '@pages/private/OficialDiaryDetails'
import RegulatoryAgencyDetailsPage from '@pages/private/RegulatoryAgencyDetails'

import OrganoGovPage from '@pages/private/OrganoGov'

import InsightsPage from '@pages/private/Insights'
import InsightsBrazilPage from '@pages/private/InsightsBrazil'
import MonitorInsightsPage from '@pages/private/MonitorInsights'

import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import SharedPaymentLinkPage from '@pages/public/SharedPaymentLink'
import { pageViewEvent } from '@utils/metrics'
import SocialDetailsPage from '@pages/private/SocialDetails'
import CvmDetailsPage from '@pages/private/CvmDetails'
import { isMobile } from 'react-device-detect'
import { authSignAuthAPI } from '@services/nomos_api/resources/auth/signauth'
import { useAuth } from '@contexts/Auth'
import 'core-js/stable/atob'
import { jwtDecode } from 'jwt-decode'
import { createHash } from 'crypto'

// const detectMob = () => window.innerWidth <= 800

const saveLastPage = () =>
  localStorage.setItem('@NOMOS:lastPage', window.location.pathname)

function PublicRoutes() {
  pageViewEvent(true)
  const isAuth = localStorage.getItem('@NOMOS:auth')
  if (isAuth) {
    return isMobile ? (
      <Navigate to="/search-results" />
    ) : (
      <Navigate to="/home" />
    )
  }

  return <Outlet />
}

function PrivateRoutes() {
  pageViewEvent()
  saveLastPage()

  // if (detectMob()) {
  //   return <Navigate to="/responsive/mobile" />
  // }

  const isAuth = localStorage.getItem('@NOMOS:auth')
  return isAuth ? <Outlet /> : <Navigate to="/" />
}

function PublicPrivateMonitorRoutes() {
  pageViewEvent()
  saveLastPage()
  return <MonitorLayout />
}

function PrivateMonitorRoutes() {
  pageViewEvent()
  saveLastPage()

  const isAuth = localStorage.getItem('@NOMOS:auth')
  return isAuth ? <MonitorLayout /> : <Navigate to="/" />
}

function PrivateMonitorAutoLoginRoutes() {
  const params = useParams()
  const path = useLocation()
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  pageViewEvent()
  saveLastPage()
  const position = path.pathname.indexOf('/', 7)
  const isAuth = localStorage.getItem('@NOMOS:auth')
  const lastPage = path.pathname.substring(position)

  type jwtData = {
    email: string
    sub: string
  }

  if (!isAuth && params.token) {
    const decoded = jwtDecode<jwtData>(params.token)
    const now = new Date()
    const requestCode =
      decoded.email + now.getDay() + now.getMonth() + now.getFullYear()
    const hash = createHash('sha1').update(requestCode).digest('hex')
    authSignAuthAPI({
      request_code: hash,
      request_id: decoded.sub,
    })
      .then((response) => {
        if (response.kind === 'AUTHENTICATION') {
          isAuthenticated(response.data.user, response.data.jwt)
          navigate(lastPage || (isMobile ? 'search-results' : '/home'))
        }
      })
      .catch(() => {
        navigate('/')
      })
  }
  if (isAuth) {
    return <Navigate to={lastPage || (isMobile ? 'search-results' : '/home')} />
  }

  return <MonitorLayout />
}

function PrivateOrganoGovRoutes() {
  pageViewEvent()
  saveLastPage()

  // if (detectMob()) {
  //   return <Navigate to="/responsive/mobile" />
  // }

  const isAuth = localStorage.getItem('@NOMOS:auth')
  return isAuth ? <OrganoGovLayout /> : <Navigate to="/" />
}

function PrivateInsightsRoutes() {
  pageViewEvent()
  saveLastPage()

  // if (detectMob()) {
  //   return <Navigate to="/responsive/mobile" />
  // }

  const isAuth = localStorage.getItem('@NOMOS:auth')
  return isAuth ? <InsightsLayout /> : <Navigate to="/" />
}

function ResponsiveRoute() {
  // return detectMob() ? <Outlet /> : <Navigate to="/" />
  return <Navigate to="/" />
}

function Router() {
  return (
    <Routes>
      <Route path="/404" element={<NotFoundPage />} />

      <Route path="*" element={<Navigate to="/404" />} />

      <Route path="/share/:token" element={<SharedPaymentLinkPage />} />

      <Route
        path="/reset-initial-password/:token"
        element={<ResetPasswordPage initial />}
      />

      <Route element={<PublicRoutes />}>
        <Route path="/" element={<SigninPageEmail />} />

        <Route path="/invite/:token" element={<EmailConfirmationPage />} />

        <Route path="/signup" element={<SignupPageEmail />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/feedback/:type" element={<FeedbackPage />} />

        <Route path="/activation/:token" element={<EmailConfirmationPage />} />

        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      </Route>

      <Route element={<ResponsiveRoute />}>
        <Route path="/responsive/:type" element={<FeedbackPage />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/accept/:token" element={<AcceptUserPage />} />
      </Route>

      <Route element={<PrivateOrganoGovRoutes />}>
        <Route path="/organogov" element={<OrganoGovPage />} />
      </Route>

      <Route element={<PrivateInsightsRoutes />}>
        <Route path="/insights" element={<InsightsPage />} />
        <Route path="/insights/brazil" element={<InsightsBrazilPage />} />
        <Route path="/insights/monitor/:id" element={<MonitorInsightsPage />} />
      </Route>

      <Route element={<PrivateMonitorRoutes />}>
        <Route path="/home" element={<HomePage />} />

        <Route path="/my-account" element={<MyAccountPage />} />

        <Route path="/users" element={<UsersPage />} />

        <Route path="/users/:id" element={<UserDetailPage />} />

        <Route path="/users/:id/edit" element={<EditUserPage />} />

        <Route path="/new-user" element={<NewUserPage />} />

        <Route path="/organs" element={<OrgansPage />} />

        <Route path="/organs/:id" element={<OrganDetailPage />} />

        <Route path="/monitors" element={<MonitorsPage />} />

        <Route path="/monitors/:id" element={<MonitorPage />} />

        <Route path="/monitors/:id/users" element={<MonitorUsersPage />} />

        <Route path="/keywords" element={<SavedSearches />} />

        <Route path="/events" element={<EventsPage />} />

        <Route path="/search-results" element={<SearchResultsPage />} />

        <Route
          path="/propositions/:openDataResource/:openDataId"
          element={<PropositionDetailsPage />}
        />

        <Route
          path="/proposition/:propositionId/voting/:votingId"
          element={<VotingDetailsPage />}
        />

        <Route path="/stakeholders" element={<StakeholdersPage />} />
        <Route path="/stakeholders/:id" element={<StakeholderDetailsPage />} />
      </Route>

      <Route path="auth/:token/*" element={<PrivateMonitorAutoLoginRoutes />} />

      <Route element={<PublicPrivateMonitorRoutes />}>
        <Route path="/propositions/:id" element={<PropositionDetailsPage />} />
        <Route path="/speeches/:id" element={<SpeechDetailsPage />} />
        <Route path="/events/:id" element={<EventDetailsPage />} />
        <Route path="/notices/:id" element={<NoticeDetailPage />} />
        <Route path="/dou/:id" element={<DouDetailsPage />} />
        <Route path="/oficial-diary/:id" element={<OficialDiaryDetailPage />} />
        <Route path="/bacen/:id" element={<BacenDetailsPage />} />
        <Route path="/social/:id" element={<SocialDetailsPage />} />
        <Route path="/irs/:id" element={<IrsDetailsPage />} />
        <Route path="/cvm/:id" element={<CvmDetailsPage />} />
        <Route
          path="/regulatory_agencies/:id"
          element={<RegulatoryAgencyDetailsPage />}
        />
      </Route>
    </Routes>
  )
}

export default Router
