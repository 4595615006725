import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-left: 8px;
`

export const InputSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  opacity: 0 !important;
  cursor: pointer;
  font-size: 12px;
`

export const SelectionPointer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  width: auto;
  padding-left: 8px;
  padding-right: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
`
