import { ChevronRight } from '@mui/icons-material'
import {
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'

export const AccordionWrapper = styled(Box)``

export const AccordionStyled = styled(Accordion)`
  box-shadow: none !important;
  overflow: hidden !important;
  margin-top: 0px !important;
  border-radius: 8px !important;
  align-items: center;
`

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0 32px;
  }
`

export const AccordionSummaryIcon = styled(ChevronRight)`
  font-size: 24px;
  color: ${(props) =>
    props.theme.palette['$color-action-background-primary-default']};
`

export const AccordionSummaryStyled = styled(AccordionSummary)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important;
  align-items: flex-start;
  & > .MuiAccordionSummary-content {
    margin: 0px;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(-90deg);
  }
`
