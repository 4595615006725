/* eslint-disable @typescript-eslint/no-unused-vars */
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getStakeholderLegislativeProposalsAPI = async (id: string) => {
  const { data } = await HTTPClient.get(`stakeholders/${id}/count/propositions`)
  return data
}

export const useStakeholderLegislativeProposals = (id: string) =>
  useQuery(
    ['get-stakeholder-lesgislative-proposals', id],
    () => getStakeholderLegislativeProposalsAPI(id),
    {
      refetchOnWindowFocus: false,
    }
  )
