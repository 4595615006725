import { DialogTitle, Stack, Typography } from '@mui/material'

type Props = {
  description: string
}

export function StateModalHeader({ description }: Props): JSX.Element {
  return (
    <DialogTitle padding={3} sx={{ maxWidth: 350, minWidth: 350, width: 350 }}>
      <Stack direction="column" spacing={1}>
        <Typography variant="$font-title-4" color="$color-text-primary">
          Exportar Proposições
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-primary">
          {description}
        </Typography>
      </Stack>
    </DialogTitle>
  )
}
