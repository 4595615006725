/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import _ from 'lodash'
import axios from 'axios'
import { useSnackbar } from '@contexts/Snackbar'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AutoUploadFileContext } from './AutoUploadFile.context'
import { NoteAllwedExtensions } from '@enums/NoteAllowedExtensions'
import { AutoUploadFileControllerProps } from './AutoUploadFile.types'
import {
  PresignedUrlResponse,
  presignedUrlNoteFileApi,
} from '@services/nomos_api/resources/notes/presign'

export function AutoUploadFileController({
  children,
  file,
  bucket,
  originId,
  onUploadAttachedName,
}: AutoUploadFileControllerProps): JSX.Element {
  const startedUpload = useRef<boolean>(false)
  const { showSnackbarError } = useSnackbar()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string | undefined>(undefined)

  const handleUploadFile = useCallback(
    (signed: PresignedUrlResponse) => {
      setStatus('Carregando...')
      const formData = new FormData()
      for (const fieldKey in signed.fields) {
        formData.append(fieldKey, signed.fields[fieldKey])
      }
      formData.append('file', file as File, file?.name)
      axios
        .post(signed.url, formData, {
          headers: { 'headers.Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          onUploadAttachedName?.(_.get(signed, 'fields.key'))
          setStatus('Completo')
        })
        .catch(() => {
          setError(true)
          setStatus('Falha')
        })
        .finally(() => setLoading(false))
    },
    [setStatus]
  )

  const handlePresignedUpload = useCallback(() => {
    if (!file) return
    const extension = _.get(NoteAllwedExtensions, String(file.type))
    if (!extension) {
      setError(true)
      setLoading(false)
      setStatus('Extensão inválida')
      return
    }
    setError(false)
    setLoading(true)
    setStatus('Preparando...')
    presignedUrlNoteFileApi({
      bucket,
      originId,
      extension,
    })
      .then(handleUploadFile)
      .catch((error) => {
        setError(true)
        setLoading(false)
        setStatus('Falha')
        showSnackbarError(
          'Falha ao preparar aquivo',
          error.response.data.message ||
            'Não foi possível iniciar o upload do arquivo.'
        )
      })
  }, [setStatus, file])

  useEffect(() => {
    if (!startedUpload.current) {
      startedUpload.current = true
      handlePresignedUpload()
    }
  }, [])

  const state = useMemo(
    () => ({
      error,
      loading,
      status,
    }),
    [status, error, loading]
  )
  return (
    <AutoUploadFileContext.Provider value={state}>
      {children}
    </AutoUploadFileContext.Provider>
  )
}
