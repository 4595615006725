import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { Stack, Typography } from '@mui/material'
import { Button, Shimmer } from '@components/atoms'
import { NoticeDetailsContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { ArrowForwardIos, LaunchOutlined } from '@mui/icons-material'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { Container, ContentPaths, TextLabel, TextDescription } from './styles'

import NotesIcon from '@assets/icons/notes.svg'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()
  const notice = useContextSelector(NoticeDetailsContext, (s) => s.notice)
  const buckets = useContextSelector(NoticeDetailsContext, (s) => s.buckets)
  const keyword = useContextSelector(NoticeDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(NoticeDetailsContext, (s) => s.isLoading)

  const params = `?buckets=${getSortedBucket(
    buckets,
    'notices'
  )}&keyword=${keyword}`

  return (
    <Container>
      { auth && (
        <ContentPaths>
          <NavLink to={isMobile ? "/search-results" : "/home"}>
            <TextLabel>Inicio</TextLabel>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <TextLabel>Notícias</TextLabel>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <Shimmer isLoading={isLoading} width={80} height={18}>
            <TextDescription>{notice?.title}</TextDescription>
          </Shimmer>
        </ContentPaths>
      )}
      <Shimmer isLoading={isLoading} width={240} height={38}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {notice?.title}
        </Typography>
      </Shimmer>

      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flexWrap='wrap'>
        {!isMobile && <Button
          variant="text"
          href={notice?.url}
          target="_blank"
          rel="noreferrer"
          text="Acessar notícia"
          startIcon={<LaunchOutlined />}
        />}
        <Button
        text="Notas"
        color="$color-extended-red1"
        variant="contained"
        endIcon={<img src={NotesIcon} alt="Notas" />}
        onClick={() => {
          openDrawer(
            <NoteManagerDrawer id={notice?.id} bucket="notices" />
          )
        }}
      />
      </Stack>

    </Container>
  )
}

export default HeaderComponent
