import { styled } from '@mui/system'
import { Typography, buttonClasses } from '@mui/material'
import { Button } from '..'
import { Add } from '@mui/icons-material'

export const Label = styled(Typography)`
  display: flex;
  padding-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
`

export const ChipButton = styled(Button)`
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  .${buttonClasses.endIcon} {
    margin: 0px;
  }
`

export const ButtonIcon = styled(Add)`
  font-size: 18px !important;
`
