import { useDrawer } from '@contexts/Drawer'
import { Container, ContentBody, Header, IconBack, IconClose } from './styles'
import { IconButton, Typography } from '@mui/material'
import { ManageContent } from './containers/manage-content'

export function PropositionStatusNotificationView() {
  const { closeDrawer, cleanStack } = useDrawer()

  return (
    <Container>
      <Header>
        <IconButton onClick={closeDrawer} size="small">
          <IconBack />
        </IconButton>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Opções de Monitoramento
        </Typography>
        <IconButton onClick={cleanStack}>
          <IconClose />
        </IconButton>
      </Header>
      <ContentBody>
        <ManageContent />
      </ContentBody>
    </Container>
  )
}
