import { ReactElement } from 'react'
import { Skeleton, SkeletonProps } from '@mui/material'

export interface ShimmerProps extends SkeletonProps {
  isLoading: boolean
}

export default function Shimmer({
  isLoading,
  children,
  width = 0,
  height = 0,
}: ShimmerProps) {
  if (!isLoading) return children as ReactElement

  return (
    <Skeleton variant="rounded" animation="wave" width={width} height={height}>
      {children}
    </Skeleton>
  )
}
