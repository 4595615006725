/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext } from 'use-context-selector'

export type EditBucketSearchContextProps = {
  form: any
  bucket: string
  isLoading: boolean
  search: any
  selectedBucket: any
  bucketItems: any
  handleSubmit: () => void
  handleUpdateSearch: (object: any) => void
  handleDeleteBucket: () => void
}

export const EditBucketSearchContext =
  createContext<EditBucketSearchContextProps>(
    {} as EditBucketSearchContextProps
  )
