import { StripePortalLinkProps } from './types'
import { useContextSelector } from 'use-context-selector'
import { StripePortalLinkContext } from './context'
import { Button } from '@components/atoms'

export function StripePortalLinkView({
  title,
  text,
  size,
  variant,
  fullWidth,
}: StripePortalLinkProps): JSX.Element {
  const isLoading = useContextSelector(
    StripePortalLinkContext,
    (s) => s.isLoading
  )
  const handleGenerateStripePortalLink = useContextSelector(
    StripePortalLinkContext,
    (s) => s.handleGenerateStripePortalLink
  )
  return (
    <Button
      size={size}
      title={title}
      text={text}
      variant={variant || 'contained'}
      loading={isLoading}
      fullWidth={fullWidth}
      onClick={handleGenerateStripePortalLink}
    />
  )
}
