import { HomeContext } from '../../context'
import { BucketSelector } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'

export default function BucketHandler(): JSX.Element {
  const buckets = useContextSelector(HomeContext, (s) => s.buckets)
  const setBuckets = useContextSelector(HomeContext, (s) => s.setBuckets)
  return (
    <BucketSelector
      label="Buscar em:"
      buckets={buckets}
      onChange={setBuckets}
    />
  )
}
