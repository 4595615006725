import { Stack, Typography } from '@mui/material'
import SocialTwitterSVG from '@assets/icons/social-twitter.svg'

export enum SocialEnum {
  twitter = 'twitter',
}

export const SocialIcon: Record<SocialEnum, JSX.Element> = {
  twitter: (
    <Stack direction="row" spacing={1} alignItems="center">
      <img src={SocialTwitterSVG} alt="Twitter" height={16} />
      <Typography variant="$font-body-base" color="$color-text-secondary">
        Twitter
      </Typography>
    </Stack>
  ),
}
