/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModal } from '@contexts/Modal'
import { CircularProgress, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Button, TextLink } from '@components/atoms'
import { useGetPropositionAttacheds } from '@services/nomos_api/resources/proposition/attacheds'

import { Label, ModalContainer } from './style'

type PropositionCellAttachedProps = {
  proposition: any
}

function Attacheds({ proposition, total }: any) {
  const { data, isFetching } = useGetPropositionAttacheds(proposition.id)

  return (
    <ModalContainer>
      <Label>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Apensadas
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Veja abaixo as {total} proposições apensadas ao {proposition.title}
        </Typography>
      </Label>

      {isFetching && <CircularProgress size={25} />}

      {data?.map((p) => (
        <Tooltip title={p.summary}>
          <span>
            <TextLink
              text={p.title}
              key={p.openDataId}
              href={`/propositions/${p.openDataResource}/${p.openDataId}`}
            />
          </span>
        </Tooltip>
      ))}
    </ModalContainer>
  )
}

export function PropositionCellAttached({
  proposition,
}: PropositionCellAttachedProps): JSX.Element {
  const { openModal } = useModal()

  const total = proposition.attacheds.length

  const handleModal = () => {
    openModal(<Attacheds proposition={proposition} total={total} />)
  }

  return (
    <Typography variant="$font-body-3" color="$color-text-primary">
      {proposition.attacheds[0]}{' '}
      <Button
        size="tiny"
        sx={{ px: 1 }}
        variant="text"
        text={`ver mais ${total > 1 ? total - 1 : ''}`}
        onClick={handleModal}
        aria-describedby={proposition.id}
      />
    </Typography>
  )
}
