import { DataFoundContext } from './context'
import { useDebounce } from '@hooks/useDebounce'
import { useEffect, useMemo, useState } from 'react'
import {
  AlertsResult,
  useGetAllAlerts,
} from '@services/nomos_api/resources/alerts/getAll'

export type periodType = {
  from: string
  to: string
}

export type DataFoundControllerProps = {
  children: React.ReactNode
  monitorId?: string
}

export function DataFoundController({
  children,
  monitorId,
}: DataFoundControllerProps) {
  const [textSearchKeyword, setTextSearchKeyword] = useState('')
  const debouncedTextSearchMonitor = useDebounce(textSearchKeyword, 500)

  const [selectedRelatedSavedSearches, setSelectedRelatedSavedSearches] =
    useState<string[]>([])

  const [selectedBuckets, setSelectedBuckets] = useState<string[]>([])

  const [selectedMonitors, setSelectedMonitors] = useState<string[]>(
    monitorId ? [monitorId] : []
  )

  const [periodFilter, setPeriodFilter] = useState<periodType | null>(null)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const { data, isLoading } = useGetAllAlerts({
    page,
    limit,
    keyword: debouncedTextSearchMonitor,
    buckets: selectedBuckets,
    only: monitorId ? undefined : ['owner'],
    monitorSearchIds: selectedRelatedSavedSearches,
    monitorIds: selectedMonitors,
    ...(periodFilter && {
      periodFrom: periodFilter.from,
      periodTo: periodFilter.to,
    }),
  })

  useEffect(() => {
    setPage(1)
  }, [
    debouncedTextSearchMonitor,
    selectedBuckets,
    selectedRelatedSavedSearches,
    selectedMonitors,
    periodFilter,
  ])

  const displayMonitorFilter = useMemo(() => {
    return !monitorId
  }, [monitorId])

  const store = useMemo(
    () => ({
      data: data as AlertsResult,
      setPage,
      setLimit,
      isLoading,
      setTextSearchKeyword,
      textSearchKeyword,
      selectedMonitors,
      setSelectedMonitors,
      selectedRelatedSavedSearches,
      setSelectedRelatedSavedSearches,
      selectedBuckets,
      setSelectedBuckets,
      periodFilter,
      setPeriodFilter,
      displayMonitorFilter,
      monitorId,
    }),
    [
      data,
      setPage,
      setLimit,
      isLoading,
      setTextSearchKeyword,
      textSearchKeyword,
      selectedMonitors,
      setSelectedMonitors,
      selectedRelatedSavedSearches,
      setSelectedRelatedSavedSearches,
      selectedBuckets,
      setSelectedBuckets,
      periodFilter,
      setPeriodFilter,
      displayMonitorFilter,
      monitorId,
    ]
  )

  return (
    <DataFoundContext.Provider value={store}>
      {children}
    </DataFoundContext.Provider>
  )
}
