import { useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { EventsCalendar } from '@containers/EventsCalendar'
import { OrganDetailContext } from '@pages/private/OrganDetail/context'
import { searchEventsAPI } from '@services/nomos_api/resources/search/events'

import { Container } from './styles'

export default function Schedule() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)
  if (!organ) return null

  const [events, setEvents] = useState([])

  const handleChangeDate = async (date: Record<string, string>) => {
    const events = await searchEventsAPI({
      limit: date['buckets.events.limit'],
      filter: {
        date: {
          from: date['buckets.events.filter.date.from'],
          to: date['buckets.events.filter.date.to'],
        },
        organs: [organ.id],
      },
    })

    setEvents(events?.results)
  }

  return (
    <Container>
      <EventsCalendar
        events={events}
        headerLocation="right"
        handleUpdateSearch={handleChangeDate}
      />
    </Container>
  )
}
