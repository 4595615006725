import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import colors from '@theme/colors'

export const SignUpWrapper = styled.div`
  display: flex;
  height: 100vh;
`

export const SignUpMainForm = muiStyled((props) => (
  <Box
    {...props}
    sx={{
      paddingLeft: { xs: 2, sm: 8 },
      paddingRight: { xs: 2, sm: 8 },
    }}
  />
))`
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  background-color: ${colors['$color-background-primary']};
`

export const SignUpFormSpacer = styled.div`
  flex: 1;
  width: 100%;
`

export const SignUpFormWrapper = styled.div`
  gap: 40px;
  display: flex;
  width: 100%;
  max-width: 490px;
  padding-top: 32px;
  padding-bottom: 32px;
  flex-direction: column;
`

export const SignUpFormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(230, 232, 240, 0.8);
  background-color: #fff;
  border-color: #e6e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`

export const SignUpFormContainerPadding = muiStyled((props) => (
  <Box
    {...props}
    sx={{
      paddingLeft: { xs: 2, sm: 4 },
      paddingRight: { xs: 2, sm: 4 },
    }}
  />
))`
  height: auto;
  width: 100%;
  display: flex;
  padding-top: 24px;
  padding-bottom: 28px;
`

export const SignUpForm = styled.div`
  gap: 32px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
