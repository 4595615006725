import colors from '@theme/colors'
import styled from 'styled-components'
import { Card } from '@components/atoms'
import { Nomos } from '@components/icons'

export const AppBar = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NomosLogo = styled(Nomos)`
  width: 15%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  margin: 0 auto;
  padding: 0 1rem;
  padding-top: 40px;
  background-color: ${colors['$color-background-primary']};
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 60px 150px;
  background-color: white;
`
