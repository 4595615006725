import { UserUpsertView } from './view'
import { UserUpsertController } from './controller'
import { Member } from '@services/nomos_api/entities/user'

export type UserUpsertProps = {
  user?: Member
}

function UserUpsert({ user }: UserUpsertProps) {
  return (
    <UserUpsertController user={user}>
      <UserUpsertView />
    </UserUpsertController>
  )
}

UserUpsert.defaultProps = {
  user: undefined,
}

export default UserUpsert
