import { Box } from '@mui/material'
import { styled as styledMaterial } from '@mui/material/styles'

export const Wrapper = styledMaterial(Box)(() => ({
  backgroundColor: '#F9FAFC',
  '@media print': {
    '.notPrintable': { display: 'none !important', height: '0px !important' },
    '.print-content': { maxHeight: '100% !important' },
    '.print-content-box': { paddingTop: '0px !important' },
  },
}))

export const Container = styledMaterial(Box)(() => ({
  margin: '0 auto',
  padding: '40px 0 60px 0',
  minHeight: '82vh',
  maxWidth: '88%',
}))
