/* eslint-disable react/function-component-definition */
import HouseInfoComponent from './HouseInfo'
import { ComponentStory, ComponentMeta } from '@storybook/react'

export default {
  title: 'Components/Atoms/HouseInfo',
  component: HouseInfoComponent,
} as ComponentMeta<typeof HouseInfoComponent>

const Template: ComponentStory<typeof HouseInfoComponent> = (args) => (
  <HouseInfoComponent {...args} />
)

export const HouseInfo = Template.bind({})
HouseInfo.args = {
  text: 'Senado',
}
