import _ from 'lodash'
import React from 'react'
import FileSaver from 'file-saver'
import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'
import { ExportPropositionModalContext } from './context'
import { exportPropositions } from '@services/nomos_api/resources/monitor/export-propositions'
import {
  ExportPropositionModalControllerType,
  ExportPropositionModalFieldKeys,
  ExportPropositionModalResourcesType,
  ExportPropositionModalFormatsType,
} from './types'

export function ExportPropositionModalController({
  children,
  monitorId,
  monitorName,
}: ExportPropositionModalControllerType): JSX.Element {
  const { closeModal } = useModal()
  const { showSnackbarSuccess } = useSnackbar()

  const [loading, setLoading] = React.useState<boolean>(false)

  const [resources, setResources] = React.useState<
    ExportPropositionModalResourcesType | undefined
  >(undefined)
  const [fields, setFields] = React.useState<
    ExportPropositionModalFieldKeys | undefined
  >(undefined)
  const [format, setFormat] = React.useState<
    ExportPropositionModalFormatsType | undefined
  >(undefined)

  const handleDownloadedDocument = React.useCallback(
    (blob: Blob) => {
      FileSaver.saveAs(blob, `[Proposições] ${monitorName}.xlsx`)
      showSnackbarSuccess('Exportação Concluída')
      closeModal()
    },
    [monitorName, showSnackbarSuccess, closeModal]
  )

  const handleRequestExport = React.useCallback(() => {
    if (!format || !fields || !resources) return
    setLoading(true)
    exportPropositions({
      monitorId,
      format,
      fields: Object.keys(fields).filter(
        (field) => _.get(fields, field) === true
      ),
      resources: Object.keys(resources).filter(
        (field) => _.get(resources, field) === true
      ),
    })
      .then((blob) => handleDownloadedDocument(blob))
      .finally(() => setLoading(false))
  }, [
    format,
    fields,
    resources,
    setLoading,
    exportPropositions,
    handleDownloadedDocument,
  ])

  const state = React.useMemo(
    () => ({
      format,
      fields,
      resources,
      loading,
      setResources,
      setFields,
      setFormat,
      handleRequestExport,
    }),
    [format, fields, resources, setResources, setFields, setFormat]
  )

  return (
    <ExportPropositionModalContext.Provider value={state}>
      {children}
    </ExportPropositionModalContext.Provider>
  )
}
