/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'use-context-selector'

export type MyAccountContextType = {
  selectedMenuOption: string
  setSelectedMenuOption: (value: string) => void
  formik: any
  slim: boolean
}

export const MyAccountContext = createContext<MyAccountContextType>(
  {} as MyAccountContextType
)
