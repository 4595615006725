import { SideFilterSelectView } from './SideFilterSelectView'
import { SideFilterSelectProps } from './SideFilterSelectTypes'
import { SideFilterSelectController } from './SideFilterSelectController'

function SideFilterSelectComponent(props: SideFilterSelectProps) {
  return (
    <SideFilterSelectController {...props}>
      <SideFilterSelectView />
    </SideFilterSelectController>
  )
}

SideFilterSelectComponent.defaultProps = {
  search: {},
  selectedBucket: null,
}

export default SideFilterSelectComponent
