import Monitor from './containers/Monitor/Monitor'
import StatusCard from './containers/StatusCard/StatusCard'
import StakeholderCard from './containers/StakeholderCard/StakeholderCard'

import { Container, Elements, Actions, Content } from './styles'

export default function StakeholderDetailView() {
  return (
    <Container>
      <Elements>
        <Actions>
          <Monitor />
        </Actions>
        <Content>
          <StakeholderCard />
          <StatusCard />
        </Content>
      </Elements>
    </Container>
  )
}
