import { useState } from 'react'
import { Container } from './styles'
import { SocialDetailsContext } from '../../context'
import { HighlightedText } from '@components/molecules'
import { Shimmer, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'

function ContentTextComponent() {
  const social = useContextSelector(SocialDetailsContext, (s) => s.social)
  const keyword = useContextSelector(SocialDetailsContext, (s) => s.keyword)
  const isLoading = useContextSelector(SocialDetailsContext, (s) => s.isLoading)
  const [highlighted] = useState(keyword || '')

  return (
    <Container direction="column" spacing={3}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="column" spacing={2} flex={1}>
          <Box>
            <Shimmer isLoading={isLoading} height={24} width={80}>
              <Chip
                size="small"
                color="primary"
                label={social?.openDataResource?.toUpperCase()}
                variant="outlined"
                className="notPrintable"
              />
            </Shimmer>
          </Box>
          <ShimmerText isLoading={isLoading} words={6}>
            <Typography variant="$font-title-3" color="$color-text-primary">
              {social?.stakeholder?.name || 'Stakeholder Desconhecido'}
            </Typography>
          </ShimmerText>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Data de publicação:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {formatDate(social?.postedAt, 'Á definir')}
                </Typography>
              </Shimmer>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <ShimmerText isLoading={isLoading} height={14}>
        <HighlightedText
          text={social?.text}
          highlight={highlighted}
          className="print-content"
        />
      </ShimmerText>
    </Container>
  )
}

export default ContentTextComponent
