import { Box } from '@mui/material'
import styled from 'styled-components'
import { Close } from '@mui/icons-material'

export const Container = styled(Box)`
  height: 100%;
  max-height: 100%;
  display: flex;
  max-width: 450px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`
export const Header = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`

export const HeaderTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const IconClose = styled(Close)`
  font-size: 24px;
  color: #6d787d;
`

export const ContentBody = styled(Box)`
  flex: 1;
  display: flex;
  overflow: hidden;
  margin-top: 24px;
  flex-direction: column;
`
