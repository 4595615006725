import { useMutation } from '@tanstack/react-query'

import HTTPClient from '../..'

export const patchMonitorSearchAPI = async (data) => {
  const { data: responseData } = await HTTPClient.patch(
    `/monitor/${data.monitorId}/searches/${data.id}`,
    data
  )

  return responseData
}

export const usePatchMonitorSearch = () =>
  useMutation(['patch-monitor-search'], patchMonitorSearchAPI)
