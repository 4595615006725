import colors from '@theme/colors'
import styled from 'styled-components'
import { styled as styledMui, Box } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 28px 0;
  background: linear-gradient(
    180deg,
    ${colors['$color-action-background-primary-default']} 15%,
    ${colors['$color-background-tertiary']} 0%
  );

  @media (min-width: 768px) {
    background: linear-gradient(
      180deg,
      ${colors['$color-action-background-primary-default']} 30%,
      ${colors['$color-background-tertiary']} 0%
    );
  }
`

export const Elements = styledMui(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '0px 32px',
  },
}))

export const Actions = styledMui(Box)({
  alignSelf: 'flex-end',
  flexWrap: 'wrap',
})

export const Content = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
