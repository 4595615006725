import { Bucket, BucketsEnum } from './type'

const BucketIrs: Bucket = {
  key: BucketsEnum.irs,
  name: 'Receita Federal',
  mode: 'favorite',
  sorts: [
    {
      attr: 'newest',
      label: 'Novas',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    // {
    //   attr: 'type',
    //   label: 'Tipo de documento',
    //   enabled_input: false,
    //   enabled_online_filter: false,
    //   options: async () => [
    //     { key: 'Solução de Consulta', value: 'Solução de Consulta' },
    //   ],
    // },
  ],
}

export default BucketIrs
