import { Box } from '@mui/material'
import theme from '@theme/colors'
import { styled as styledMaterial } from '@mui/material/styles'

export const ModalContent = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '24px',
}))

export const ModalToolbar = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const GroupHeader = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '12px 20px',
}))

export const Footer = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  backgroundColor: `${theme.brand.offwhite}`,
  padding: '20px',
}))
