import React from 'react'
import { useState } from 'react'
import { TabContext } from '@mui/lab'
import { Badge, Typography } from '@mui/material'

import {
  BadgeWrapper,
  TabSelectorTab,
  TabSelectorTabs,
} from './TabSelector.styles'

type Tab = {
  cy?: string
  value: string
  label: string
  alerts?: number
  action?: JSX.Element
  disabled?: boolean
}

export type TabSelectorProps = {
  tabs: Tab[]
  initialTab?: string
  onChangeTab: (tab: string) => void
}

const defaultProps = {
  initialTab: '',
}

function TabSelector({ tabs, initialTab, onChangeTab }: TabSelectorProps) {
  const [selectedTab, setSelectedTab] = useState(initialTab || tabs?.[0]?.value)

  const handleChangeTab = (_event: React.ChangeEvent<object>, key: string) => {
    setSelectedTab(key)
    onChangeTab(key)
  }

  const buildTabIcon = (tab: Tab): JSX.Element | undefined => {
    if (tab.action) return tab.action
    if (tab.alerts) {
      return (
        <BadgeWrapper>
          <Badge color="error" badgeContent={tab.alerts} max={999} />
        </BadgeWrapper>
      )
    }
    return undefined
  }

  return (
    <TabContext value={selectedTab}>
      <TabSelectorTabs
        onChange={handleChangeTab}
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {tabs.map((tab) => (
          <TabSelectorTab
            data-cy={tab.cy}
            key={tab.value}
            value={tab.value}
            disabled={tab.disabled === true}
            label={
              <Typography
                variant={
                  selectedTab === tab.value ? '$font-body-4' : '$font-body-base'
                }
              >
                {tab.label}
              </Typography>
            }
            disableRipple
            icon={buildTabIcon(tab)}
            iconPosition="end"
            sx={{ paddingRight: tab.action ? 0 : undefined }}
          />
        ))}
      </TabSelectorTabs>
    </TabContext>
  )
}

TabSelector.defaultProps = defaultProps

export default TabSelector
