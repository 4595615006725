import { useState } from 'react'
import { Input } from '@components/atoms'
import { Typography, Stack } from '@mui/material'
import { NoDataIcon } from '@components/icons'
import { PropositionVotingEntity } from '@services/nomos_api/entities/proposition'

import {
  Checkbox,
  ContainerColumn,
  ContainerEnd,
  ContainerRow,
  FilterableCard,
  Guideline,
  Header,
  List,
  NoData,
} from './Guidelines.styles'

export type GuidelinesProps = {
  votings: PropositionVotingEntity
}

export default function Guidelines({ votings }: GuidelinesProps) {
  const [textFilter, setTextFiler] = useState('')
  const [checkFilter, setCheckFilter] = useState('')

  const guidelines = (votings?.guidelines || [])
    .filter((guideline) =>
      textFilter
        ? guideline.acronymParty
            .toLowerCase()
            .indexOf(textFilter.toLowerCase()) !== -1
        : true
    )
    .filter((guideline) =>
      checkFilter ? guideline.positioning === checkFilter : true
    )

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckFilter(e.target.value)
    } else {
      setCheckFilter('')
    }
  }

  return (
    <FilterableCard>
      <Header>
        <ContainerColumn>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Partidos
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Veja abaixo o posicionamento <br />
            por partido
          </Typography>
        </ContainerColumn>

        <Stack gap={2} direction={{ xs: 'row', lg: 'column' }} flexWrap="wrap">
          <Input
            size="small"
            type="search"
            label="Partido"
            sx={{ width: { xs: '100%', lg: '236px' } }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextFiler(e.target.value)
            }}
          />
          <ContainerEnd>
            <Checkbox>
              <Input
                type="checkbox"
                value="Sim"
                checked={checkFilter === 'Sim'}
                onChange={handleCheckbox}
              />
              Sim
            </Checkbox>
            <Checkbox>
              <Input
                type="checkbox"
                value="Não"
                checked={checkFilter === 'Não'}
                onChange={handleCheckbox}
              />
              Não
            </Checkbox>
          </ContainerEnd>
        </Stack>
      </Header>

      {guidelines.length === 0 && (
        <NoData>
          <NoDataIcon
            height={40}
            text="Não há posicionamento de partidos a serem exibidos"
          />
        </NoData>
      )}

      <List>
        {guidelines?.map((guideline) => (
          <Guideline key={guideline.acronymParty}>
            <Typography variant="$font-title-5" color="$color-text-primary">
              {guideline.acronymParty}
            </Typography>

            <ContainerRow>
              <Typography variant="$font-body-2" color="$color-text-secondary">
                Posicionamento:
              </Typography>

              <Typography
                variant="$font-body-base"
                color={
                  guideline.positioning === 'Sim'
                    ? '$color-semantic-success'
                    : '$color-semantic-error'
                }
              >
                {guideline.positioning}
              </Typography>
            </ContainerRow>
          </Guideline>
        ))}
      </List>
    </FilterableCard>
  )
}
