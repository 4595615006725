import { Box, Stack, styled } from '@mui/material'

export const Header = styled(Box)`
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

export const HeaderContent = styled(Box)`
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const RowContent = styled(Box)`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const Content = styled(Stack)`
  display: flex;
  gap: 80px;
  flex-direction: row;
  justify-content: space-between;
`
