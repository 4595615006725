import { Filter, FilterOptionsProps } from '@utils/buckets/type'
import { OrganEntity } from '@services/nomos_api/entities/organ'
import { PropositionEntity } from '@services/nomos_api/entities'
import { MonitorEntity } from '@services/nomos_api/entities/monitor'
import { getOrgansAPI } from '@services/nomos_api/resources/organs/organs'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { listMonitorsAPI } from '@services/nomos_api/resources/monitor/list'
import { getAllSavedPropositions } from '@services/nomos_api/resources/saved_proposition/getAll'

const filterMonitors = async ({ text, page, limit }: FilterOptionsProps) => {
  const monitors = await listMonitorsAPI({ page, limit, q: text })
  return {
    pagination: monitors.pagination,
    results: monitors.results.map((monitor: MonitorEntity) => ({
      key: monitor.id,
      value: monitor.name,
    })),
  }
}

const filterOrgans = async ({ text, page, limit }: FilterOptionsProps) => {
  const organs = await getOrgansAPI({ page, limit, filters: { q: text } })
  return {
    pagination: organs.pagination,
    results: organs.results.map((organ: OrganEntity) => ({
      key: organ.id,
      value: capitalizeAllWords(organ.name),
    })),
  }
}

const filterPropositions = async ({ text }: FilterOptionsProps) => {
  const savedPropositions = await getAllSavedPropositions(text)
  return savedPropositions.map(
    (savedProposition: { proposition: PropositionEntity }) => ({
      key: savedProposition.proposition.openDataId,
      value: savedProposition.proposition.title,
    })
  )
}

export default [
  {
    label: 'Painel',
    attr: 'monitors',
    enabled_input: true,
    enabled_pagination: true,
    enabled_online_filter: true,
    options: filterMonitors,
  },
  {
    label: 'Órgãos',
    attr: 'organs',
    enabled_input: true,
    enabled_pagination: true,
    enabled_online_filter: true,
    options: filterOrgans,
  },
  {
    label: 'Proposições',
    attr: 'propositions',
    enabled_input: true,
    enabled_pagination: false,
    enabled_online_filter: true,
    options: filterPropositions,
  },
] as Filter[]
