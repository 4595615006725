import { ChangeEvent } from 'react'
import { Stack } from '@mui/system'
import { CardRadio } from '@components/organisms/Cards'

import { roles } from './roles'

type UserRolesProps = {
  handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedRole: string
}

export function UserRoles({ handleRadioChange, selectedRole }: UserRolesProps) {
  return (
    <Stack spacing={2} margin="12px 20px 20px">
      <CardRadio
        name="role"
        onChange={handleRadioChange}
        value={selectedRole}
        options={roles.map((option) => ({
          title: option.title,
          value: option.value,
          description: option.description,
        }))}
      />
    </Stack>
  )
}
