import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  overflow: none;
  background-color: white;
  flex-wrap: wrap;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #e6e8f0;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
  flex-wrap: wrap;
`

export const OrganName = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    gap: 12px;
    flex-direction: row;
  }
`
