import { Wrapper } from './Tag.styles'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { TagEntity } from '@services/nomos_api/entities/tag'
import TypographyWrap from '../TypographyWrap/TypographyWrap'

type Props = Partial<Pick<TagEntity, 'createdAt' | 'updatedAt' | 'id'>> &
  Required<Pick<TagEntity, 'name' | 'color'>> & {
    fullwidth?: boolean
    size?: 'medium' | 'small' | 'tiny'
    onRemove?: (id: string) => void | undefined
  }

function Tag({
  id,
  name,
  color,
  size,
  fullwidth,
  onRemove,
}: Props): JSX.Element {
  return (
    <Wrapper
      color={color}
      size={size || 'medium'}
      fullwidth={fullwidth ? 'true' : 'false'}
    >
      <TypographyWrap
        variant={size === 'tiny' ? '$font-body-3' : '$font-body-base'}
        color="$color-text-primary"
      >
        {name}
      </TypographyWrap>
      {onRemove && id && (
        <IconButton sx={{ width: 18, height: 18 }} onClick={() => onRemove(id)}>
          <Close fontSize="small" sx={{ fontSize: 12 }} />
        </IconButton>
      )}
    </Wrapper>
  )
}

Tag.defaultProps = {
  size: undefined,
  fullwidth: undefined,
  onRemove: undefined,
}

export default Tag
