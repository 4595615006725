import { createContext } from 'use-context-selector'
import { CvmEntity } from '@services/nomos_api/entities/cvm'

export type CvmDetailsContextType = {
  cvm: CvmEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const CvmDetailsContext = createContext<CvmDetailsContextType>(
  {} as CvmDetailsContextType
)
