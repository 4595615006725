import { useState } from 'react'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { useSnackbar } from '@contexts/Snackbar'
import { Content, Footer, Header } from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { ErrorOutline, Close } from '@mui/icons-material'
import { MonitorUsers } from '@services/nomos_api/entities'
import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { putMonitorUsersApi } from '@services/nomos_api/resources/monitor_users/put'

export function RemoveMonitorUser({
  user,
  id,
  refetch,
}: {
  user: MonitorUsers
  id: string
  refetch: () => void
}) {
  useByPassSubscription()
  const { closeModal } = useModal()
  const { showSnackbarSuccess, showSnackbarError, closeSnackbar } =
    useSnackbar()
  const [loading, setLoading] = useState(false)

  const handleRemove = async () => {
    setLoading(true)

    const data = [
      {
        userId: user.id,
        role: user.role,
        delete: true,
      },
    ]

    await putMonitorUsersApi(data, id)
      .then(() => {
        showSnackbarSuccess('Usuário removido com sucesso')
        refetch()
      })
      .catch((error) => {
        const err = error as { response: { data: { message: string } } }

        showSnackbarError(
          err?.response?.data?.message || 'Erro ao remover usuário'
        )
      })
      .finally(() => {
        setTimeout(closeSnackbar, 3000)
        setLoading(false)
      })

    closeModal()
  }

  return (
    <Box>
      <Header>
        <Close onClick={closeModal} />
      </Header>

      <Content>
        <div>
          <ErrorOutline />
          <Typography
            variant="$font-title-3"
            color="$color-text-primary"
            data-testid="monitor-remove-user-title"
          >
            Remover Usuário?
          </Typography>
        </div>

        <Typography
          variant="$font-body-base"
          color="$color-text-primary"
          maxWidth={436}
        >
          Ao excluir o usuário
          <Typography variant="$font-body-base" color="primary">
            {' '}
            {user?.name}{' '}
          </Typography>
          ele deixará de visualizar e editar esse painel
        </Typography>

        <Typography variant="$font-body-2" color="muted.dark" marginTop="16px">
          Você tem certeza que deseja Remover o usuário
        </Typography>
      </Content>

      <Footer>
        <Stack direction="row" spacing={2}>
          <Button
            id="delete-panel-confirm-btn"
            text="Excluir"
            variant="outlined"
            onClick={() => handleRemove()}
            loading={loading || undefined}
          />

          <Button text="Cancelar" onClick={closeModal} />
        </Stack>
      </Footer>
    </Box>
  )
}
