import styled from '@emotion/styled'
import { theme } from '@theme/index'

import ReactPhoneNumberInput from 'react-phone-number-input/input'
import { sizeconfig } from './config'

export const InputLegend = styled.legend<{ error: 'true' | 'false' }>`
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  color: ${(props) =>
    props.error === 'true'
      ? theme.palette.error.main
      : theme.palette.grey[800]};
`

export const InputFieldset = styled.fieldset<{
  size: 'medium' | 'small'
  error: 'true' | 'false'
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.error === 'true'
      ? theme.palette.error.main
      : theme.palette.grey[400]};
  padding: 0px !important;
  margin: 0px;
  margin-top: -8px;
  border-radius: 4px;
  height: ${(props) => sizeconfig[props.size].fieldsetHeight}px;
  background-color: ${theme.palette.common.white};
  :hover {
    border-color: ${(props) =>
      props.error === 'true'
        ? theme.palette.error.main
        : theme.palette.grey[700]};
  }
  :has(input:focus) {
    border-width: 2px;
    margin-left: -1px;
    height: ${(props) => sizeconfig[props.size].fieldsetHeightFocus}px;
    width: calc(100% - 1px);
    border-color: ${(props) =>
      props.error === 'true'
        ? theme.palette.error.main
        : theme.palette.primary.main};
  }
  :has(input:focus) > .phone-input-legend {
    color: ${(props) =>
      props.error === 'true'
        ? theme.palette.error.main
        : theme.palette.primary.main} !important;
  }
  * {
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  }
`

export const InputWrapper = styled.label`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -8px;
  cursor: text !important;
`

export const CountryCodeLabel = styled.span`
  color: ${theme.palette.text.primary};
`

export const InputText = styled(ReactPhoneNumberInput)<{
  size: 'medium' | 'small'
}>`
  flex: 1;
  height: 100%;
  height: calc(100% - 2px);
  border-width: 0px;
  outline: none !important;
  padding-left: 4px;
  font-size: ${(props) => sizeconfig[props.size].inputFontSize}px;
  background-color: transparent;
  color: ${theme.palette.text.primary};
`
