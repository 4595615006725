import { OficialDiaryEntity } from '@services/nomos_api/resources/oficial_diary/getById'
import { createContext } from 'use-context-selector'

export type OficialDiaryDetailsContextType = {
  dou: OficialDiaryEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const OficialDiaryDetailsContext =
  createContext<OficialDiaryDetailsContextType>(
    {} as OficialDiaryDetailsContextType
  )
