import { Box, Grid } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SearchResultsContext } from '@pages/private/SearchResults/context'

function EventsHeaderComponent() {
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
      <Grid
        container
        columns={bucket?.periods?.length > 1 ? bucket?.periods?.length : 2}
        columnSpacing={2}
        sx={{ flex: 1 }}
      />
    </Box>
  )
}

export default EventsHeaderComponent
