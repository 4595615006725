import { PrimaryPage } from '@components/templates'
import { SavedSearchesList } from '@containers/SavedSearchesList'

export default function SavedSearches() {
  return (
    <PrimaryPage
      id="saved-search-page"
      title="Pesquisas Salvas"
      description="Visualize e edite todas as pequisas salvas e tenha acesso a pesquisas recomendadas pela inteligência Nomos."
    >
      <SavedSearchesList />
    </PrimaryPage>
  )
}
