import { AdvancedSearchModalView } from './AdvancedSearchModal.view'
import { AdvancedSearchModalProps } from './AdvancedSearchModal.types'
import { AdvancedSearchModalController } from './AdvancedSearchModal.controller'

export default function AdvancedSearchModal(props: AdvancedSearchModalProps) {
  return (
    <AdvancedSearchModalController {...props}>
      <AdvancedSearchModalView />
    </AdvancedSearchModalController>
  )
}
