import { AuthPage } from '@components/templates'
import { EmailConfirmationContext } from './context'
import { Feedback } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import IconCircleSolidWarning from '@assets/icons/circle-solid-warning.svg'
import IconCircleSolidSuccess from '@assets/icons/circle-solid-success.svg'

import { Container } from './styles'

export function EmailConfirmationView() {
  const response = useContextSelector(
    EmailConfirmationContext,
    (s) => s.response
  )

  const isError = useContextSelector(EmailConfirmationContext, (s) => s.isError)

  const isLoading = useContextSelector(
    EmailConfirmationContext,
    (s) => s.isLoading
  )

  return (
    <AuthPage>
      <Container>
        <Feedback
          icon={isError ? IconCircleSolidWarning : IconCircleSolidSuccess}
          title={
            response?.title ||
            (isError ? 'Algo de errado aconteceu' : 'Verificando...')
          }
          text={
            response?.message ||
            (isError
              ? 'Confirme o envio novamente em seu email'
              : 'Verificando ...')
          }
          action={{
            path: '/',
            text: 'Retornar para a tela de Login',
          }}
          isLoading={isLoading}
        />
      </Container>
    </AuthPage>
  )
}
