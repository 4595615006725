import { TabPanels } from './containers/TabPanels'
import { HeaderComponent } from './containers/Header'
import { TabSelectorComponent } from './containers/TabSelectorComponent'
import { Wrapper, Container, ContainerPadding, Content } from './styles'

export function PropositionDetailsView() {
  return (
    <Wrapper>
      <Container>
        <ContainerPadding>
          <HeaderComponent />
          <TabSelectorComponent />
          <Content>
            <TabPanels />
          </Content>
        </ContainerPadding>
      </Container>
    </Wrapper>
  )
}
