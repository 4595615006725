import PropositionOptions from './components/proposition-options'
import { formatDateTime } from '@utils/functions/formatter/formatter'
import { PropositionCellText } from './components/proposition-cell-text'
import { PropositionCellLink } from './components/proposition-cell-link'
import { PropositionCellTags } from './components/proposition-cell-tags'
import { getGridStringOperators, GridCellParams } from '@mui/x-data-grid'
import { PropositionCellOpnion } from './components/proposition-cell-opinion'
import { PropositionCellPriority } from './components/proposition-cell-priority'
import { PropositionCellPositioning } from './components/proposition-cell-positioning'
import { PropositionCellAttached } from './components/proposition-cell-attached'

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains' /* add more over time */].includes(value)
)

export const columns = [
  {
    field: 'action',
    headerName: '',
    width: 50,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <PropositionOptions proposition={params.row} />
    ),
  },
  {
    field: 'priority',
    headerName: 'Prioridade',
    width: 150,
    sortable: true,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <PropositionCellPriority
        savedPropositionId={params.row.savedProposition.id}
        priority={params.row.savedProposition.priority}
      />
    ),
  },
  {
    field: 'positioning',
    headerName: 'Posicionamento',
    width: 150,
    sortable: true,
    filterable: true,
    renderCell: (params: GridCellParams) => (
      <PropositionCellPositioning
        savedPropositionId={params.row.savedProposition.id}
        positioning={params.row.savedProposition.positioning}
      />
    ),
  },
  {
    field: 'title',
    headerName: 'Proposição',
    width: 160,
    sortable: true,
    filterOperators,
    renderCell: ({ row, value }: GridCellParams) => (
      <PropositionCellLink
        value={value}
        tooltipValue={row.summary}
        enabledLink={!!row.id}
        url={`/propositions/${row.id}`}
      />
    ),
  },
  {
    field: 'summary',
    headerName: 'Ementa',
    width: 300,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'proceedingDate',
    headerName: 'Ultima atualização',
    width: 200,
    sortable: true,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return formatDateTime(params.value)
    },
  },
  {
    field: 'situation',
    headerName: 'Situação',
    width: 300,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'dispatch',
    headerName: 'Despacho',
    width: 240,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'organ',
    headerName: 'Orgão',
    width: 130,
    sortable: true,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellLink
        value={value?.initials || value}
        enabledLink={!!value?.id}
        tooltipValue={value?.initials || value}
        url={`/organs/${value?.id}`}
      />
    ),
  },
  {
    field: 'appreciation',
    headerName: 'Forma de Apreciação',
    width: 200,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 200,
    sortable: true,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return (
        <PropositionCellTags
          savedPropositionId={params.row.savedProposition.id}
          initialTags={params.row.savedProposition.tags}
        />
      )
    },
  },
  {
    field: 'rapporteur',
    headerName: 'Relator',
    width: 130,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'rapporteurParty',
    headerName: 'Partido relator',
    width: 130,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value?.split(',')?.join(', ')} />
    ),
  },
  {
    field: 'rapporteurUf',
    headerName: 'UF relator',
    width: 130,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value?.split(',')?.join(', ')} />
    ),
  },
  {
    field: 'rapporteurVote',
    headerName: 'Parecer do relator',
    width: 160,
    sortable: true,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return params.value ? (
        <PropositionCellOpnion proposition={params.row} />
      ) : null
    },
  },
  {
    field: 'theme',
    headerName: 'Tema',
    width: 220,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'author',
    headerName: 'Autor',
    width: 210,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value?.split(',')?.join(', ')} />
    ),
  },
  {
    field: 'authorParty',
    headerName: 'Partido',
    width: 150,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value?.split(',')?.join(', ')} />
    ),
  },
  {
    field: 'authorUf',
    headerName: 'UF',
    width: 130,
    sortable: true,
    filterOperators,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value?.split(',')?.join(', ')} />
    ),
  },
  {
    field: 'nextStep',
    headerName: 'Próximos Passos',
    width: 200,
    sortable: true,
    filterable: false,
    renderCell: ({ value }: GridCellParams) => (
      <PropositionCellText value={value} />
    ),
  },
  {
    field: 'attacheds',
    headerName: 'Apensados',
    width: 200,
    sortable: true,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return params.value && params.value.length > 0 ? (
        <PropositionCellAttached proposition={params.row} />
      ) : null
    },
  },
]
