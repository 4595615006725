import styled from 'styled-components'

export const Grid = styled.div.attrs((props: { height: number }) => props)`
  display: flex;
  height: ${(props) => props.height || 400}px;

  .MuiDataGrid-columnHeaders {
    background-color: #f9fafc;
    border: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
    color: #0a2156;
  }

  .MuiDataGrid-root {
    border: 0;
  }

  .MuiDataGrid-selectedRowCount {
    opacity: 0;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }
`

export const Sx = {
  color: '#0a2156',
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
    py: 1,
  },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
    py: '15px',
  },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
    py: '22px',
  },
}
