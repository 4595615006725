import { FormWarning } from '../FormWarning'
import { useDrawer } from '@contexts/Drawer'
import IconButton from '@mui/material/IconButton'
import { Header, IconBack, IconClose, Title } from '../../styles'

type Props = {
  onClickBack?: () => void
}

export function SaveSearchHeader({ onClickBack }: Props): JSX.Element {
  const { closeDrawer } = useDrawer()
  return (
    <>
      <Header>
        {!!onClickBack && (
          <IconButton onClick={onClickBack} size="small">
            <IconBack />
          </IconButton>
        )}
        <Title variant="$font-title-3" color="$color-text-primary">
          Salvar pesquisa
        </Title>
        <IconButton data-testid="close-save-search-btn" onClick={closeDrawer}>
          <IconClose />
        </IconButton>
      </Header>
      <FormWarning />
    </>
  )
}
