import { EventDetailsView } from './view'
import { EventDetailsController } from './controller'
import { EventDetailsProps } from './types'

function EventDetailsPage(props: EventDetailsProps) {
  return (
    <EventDetailsController {...props}>
      <EventDetailsView />
    </EventDetailsController>
  )
}

export default EventDetailsPage
