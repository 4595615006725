import { useAuth } from '@contexts/Auth'
import { useDrawer } from '@contexts/Drawer'
import { useCallback, useEffect } from 'react'
import BlockedFeature from '@containers/SharedDrawers/BlockedFeatureDrawer'
import { BlockedFeatureDrawerProps } from '@containers/SharedDrawers/BlockedFeatureDrawer/types'
import { useModal } from '@contexts/Modal'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'

export function useByPassSubscription(props?: BlockedFeatureDrawerProps) {
  const { user } = useAuth()
  const { closeModal } = useModal()
  const { replaceDrawer } = useDrawer()
  const requiredRole = props?.requiredRole || NomosProductCodeEnum.MONITOR_PRO

  const handleByPass = useCallback(() => {
    if (
      user?.role !== requiredRole ||
      (user.company?.subscription &&
        !user.company?.subscription?.products?.some?.(
          (p) => p.code === requiredRole
        ))
    ) {
      closeModal()
      replaceDrawer(<BlockedFeature {...props} requiredRole={requiredRole} />, {
        lockclose: false,
        lockscroll: true,
      })
    }
  }, [user])

  useEffect(() => {
    handleByPass()
  }, [])
}
