import { ContentPaths } from './styles'
import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import NotesIcon from '@assets/icons/notes.svg'
import { Stack, Typography } from '@mui/material'
import { Button, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { RegulatoryAgencyDetailsContext } from '../../context'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { ArrowForwardIos, Print, LaunchOutlined } from '@mui/icons-material'
import { BucketsEnum } from '@utils/buckets/type'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()

  const regulatoryAgency = useContextSelector(
    RegulatoryAgencyDetailsContext,
    (s) => s.regulatoryAgency
  )
  const keyword = useContextSelector(
    RegulatoryAgencyDetailsContext,
    (s) => s.keyword
  )
  const buckets = useContextSelector(
    RegulatoryAgencyDetailsContext,
    (s) => s.buckets
  )
  const isLoading = useContextSelector(
    RegulatoryAgencyDetailsContext,
    (s) => s.isLoading
  )

  const params = `?buckets=${getSortedBucket(
    buckets,
    'regulatory_agencies'
  )}&keyword=${keyword}`

  return (
    <Stack direction="column" className="notPrintable" spacing={2}>
      {auth && (
        <ContentPaths direction="row" alignItems="center" spacing={1}>
          <NavLink to={isMobile ? '/search-results' : '/home'}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Inicio
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Agências Reguladoras
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <ShimmerText isLoading={isLoading} height={18} words={4}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {regulatoryAgency?.openDataResource?.toUpperCase()}
            </Typography>
          </ShimmerText>
        </ContentPaths>
      )}

      <ShimmerText isLoading={isLoading} height={38} words={5}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {regulatoryAgency?.title}
        </Typography>
      </ShimmerText>

      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            text="Imprimir"
            startIcon={<Print />}
            onClick={() => window.print()}
          />

          {regulatoryAgency?.link && !isMobile && (
            <Button
              variant="text"
              href={regulatoryAgency?.link}
              target="_blank"
              rel="noreferrer"
              text="Acessar na integra"
              startIcon={<LaunchOutlined />}
            />
          )}
        </Stack>
        {regulatoryAgency?.id && (
          <Button
            text="Notas"
            color="$color-extended-red1"
            variant="contained"
            endIcon={<img src={NotesIcon} alt="Notas" />}
            onClick={() => {
              openDrawer(
                <NoteManagerDrawer
                  id={regulatoryAgency.id}
                  bucket={BucketsEnum.regulatory_agencies}
                />
              )
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default HeaderComponent
