import styled from 'styled-components'
import { Card } from '@components/atoms'
import { RadioGroup } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding-top: 45px;
  padding-bottom: 35px;

  width: 100%;
  border-bottom: 1px solid #d7dde0;
`

export const InfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 216px;
  max-width: 216px;
`

export const Roles = styled(RadioGroup)`
  display: flex;
  flex-direction: row !important;
  gap: 32px;
`

export const RoleCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;

  height: 100px;
  width: 297px;
`

export const RoleLabel = styled.div`
  display: flex;
  flex-direction: column;
`

export const Inputs = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Contacts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`
