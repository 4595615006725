import { Button } from '@components/atoms'
import { useModal } from '@contexts/Modal'
import CreateMonitor from '../CreateMonitor'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import { MonitorsContext } from '../../context'
import { FileUploadOutlined } from '@mui/icons-material'
import { useContextSelector } from 'use-context-selector'
import { ImportPropositionModal } from '@containers/ImportPropositionModal'

export default function Actions() {
  const { openModal } = useModal()
  const { openDrawer } = useDrawer()

  const handleRefetchMonitors = useContextSelector(
    MonitorsContext,
    (s) => s.handleRefetchMonitors
  )

  return (
    <>
      {!isMobile && (
        <Button
          variant="text"
          text="Importar Painel"
          endIcon={<FileUploadOutlined />}
          onClick={() =>
            openModal(
              <ImportPropositionModal
                onImportFinished={() => handleRefetchMonitors()}
              />
            )
          }
        />
      )}

      <Button
        id="create-panel-btn"
        text="Criar Painel"
        sx={{ p: '8px 24px' }}
        onClick={() =>
          openDrawer(<CreateMonitor onSavedMonitor={handleRefetchMonitors} />)
        }
      />
    </>
  )
}
