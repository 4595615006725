import _ from 'lodash'
import { useMemo } from 'react'
import colors from '@theme/colors'
import { Stack, Typography } from '@mui/material'
import { EventEntity } from '@services/nomos_api/entities/event'
import { Indicator } from '@components/molecules/Status/Status.styles'

type Props = {
  event: EventEntity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  index: number
}

export function SavedPropositionScheduled({
  event,
  data,
  index,
}: Props): JSX.Element | null {
  if (!event) return null

  const { savedPropositions } = event

  const monitors = useMemo((): string[] => {
    return (savedPropositions || []).reduce<string[]>((list, item) => {
      if (
        [
          _.get(data, `${index}.openDataPropositionId`),
          _.get(data, `${index}.relatedOpenDataPropositionId`),
        ].includes(item.propositionOpenDataId || 'unknown')
      ) {
        list.push(item.monitorName)
      }
      return list
    }, [])
  }, [savedPropositions])

  if (!monitors || monitors.length === 0) return null

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Indicator color={colors['$color-semantic-success']} />
      <Typography variant="$font-body-5" color="$color-text-primary">
        {monitors.join(', ')}
      </Typography>
    </Stack>
  )
}
