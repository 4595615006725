import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { CardTemplate } from '../CardTemplate'
import { HouseInfo } from '@components/molecules'
import { BucketsEnum } from '@utils/buckets/type'
import { Stack, Tooltip, Typography } from '@mui/material'
import { ArrowOutwardOutlined } from '@mui/icons-material'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import {
  formatDateTime,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { SpeechEntity } from '@services/nomos_api/resources/speech/getById'
import {
  Button,
  SaveBucketButton,
  TextWrap,
  TypographyWrap,
} from '@components/atoms'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'

type Props = { speech: SpeechEntity }

export function CardSpeeches({ speech: speechState }: Props) {
  const [speech, setSpeech] = useState<SpeechEntity>(speechState)
  const { url } = useSearchResultsUrl(`/speeches/${speech.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setSpeech(
        produce(speech, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setSpeech, speech]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setSpeech({
        ...speech,
        savedBuckets: speech.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setSpeech, speech]
  )

  const dateSpeechAt = formatDateTime(speech.date)

  const labelSpeaker = [speech.speaker, speech.acronymParty]
    .filter((i) => !!i)
    .join(' - ')

  const summary = useMemo(() => {
    return formatterHighlight(speech, ['summary'], 'Não Informado')
  }, [])

  function truncateText(
    text: string | number | JSX.Element[] | null | undefined,
    maxLength: number
  ): string | number | JSX.Element[] | null | undefined {
    if (typeof text === 'string') {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
    }
    return text
  }

  return (
    <CardTemplate
      cy="card-speeche"
      content={[
        <>
          <Stack direction="row" alignItems="center" spacing={2}>
            <HouseInfo text={speech?.openDataResource || 'N/A'} />
          </Stack>
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-2" color="$color-text-primary">
                Orador:
              </Typography>
              <Tooltip title={labelSpeaker} placement="top">
                <TextWrap lines={1}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {labelSpeaker || '--'}
                  </Typography>
                </TextWrap>
              </Tooltip>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TypographyWrap
                variant="$font-body-2"
                color="$color-text-primary"
              >
                Data de Publicação:
              </TypographyWrap>
              <Tooltip title={dateSpeechAt} placement="top">
                <TypographyWrap
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {dateSpeechAt}
                </TypographyWrap>
              </Tooltip>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TypographyWrap
                variant="$font-body-2"
                color="$color-text-primary"
              >
                Sessao:
              </TypographyWrap>
              <TypographyWrap
                variant="$font-body-base"
                color="$color-text-primary"
              >
                {speech?.camara?.code || speech?.type || '--'}
              </TypographyWrap>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextWrap lines={3}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                {truncateText(summary, 2000)}
              </Typography>
            </TextWrap>
          </Stack>
        </>,
      ]}
      actions={[
        <SaveBucketButton
          id={speech?.id}
          bucket={BucketsEnum.speeches}
          isSaved={speech?.savedBuckets?.length > 0}
          openDataResource={speech?.openDataResource}
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Discurso</Typography>}
          size="medium"
          endIcon={
            <ArrowOutwardOutlined
              sx={{
                '&.MuiSvgIcon-root': {
                  fontSize: '12px',
                },
              }}
            />
          }
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
