import colors from '@theme/colors'
import styled from 'styled-components'
import CircleIcon from '@mui/icons-material/Circle'

export const Container = styled.div`
  display: flex;
  gap: 13px;
`

export const DotIcon = styled(CircleIcon)`
  height: 10px !important;
  width: 10px !important;
  margin-top: 5px;
  color: ${(props) =>
    props.color === 'error'
      ? colors['$color-semantic-error']
      : colors['$color-border-primary']};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
`

export const Title = styled.div`
  width: auto;
`

export const Content = styled.div`
  display: flex;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
`

export const CardElements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
