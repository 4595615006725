import { styled, Typography } from '@mui/material'

export const TagConnector = styled((props) => (
  <Typography
    {...props}
    variant="$font-body-base"
    color="$color-text-secondary"
  />
))<{ tiny?: boolean }>`
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold !important;
  font-size: ${(props) => (String(props.tiny) === 'true' ? '12px' : 'inherit')};
`

export const TagLabel = styled((props) => (
  <Typography
    {...props}
    variant="$font-body-base"
    color="$color-text-secondary"
  />
))<{ tiny?: boolean }>`
  float: left;
  font-size: ${(props) => (String(props.tiny) === 'true' ? '12px' : 'inherit')};
`
