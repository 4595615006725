import {
  SignUpWrapper,
  SignUpMainForm,
  SignUpFormSpacer,
  SignUpFormWrapper,
  SignUpFormContainer,
  SignUpFormContainerPadding,
} from '@pages/public/Signupemail/styles'
import { FormRegistrationStep2Controller } from './controller'
import { FormRegistrationStep2View } from './view'
import LogoNomosArkoSvg from '../../../assets/logos/logo-nomos-arko.svg'
import { Presentation } from '@components/organisms/Presentation/Presentation'

function FormRegistrationStep2() {
  return (
    <FormRegistrationStep2Controller>
      <SignUpWrapper>
        <Presentation />
        <SignUpMainForm>
          <SignUpFormSpacer />
          <SignUpFormWrapper>
            <img
              height={54}
              src={LogoNomosArkoSvg}
              alt="Logotipo Nomos com slogan Arko"
            />
            <SignUpFormContainer>
              <SignUpFormContainerPadding>
                <FormRegistrationStep2View />
              </SignUpFormContainerPadding>
            </SignUpFormContainer>
          </SignUpFormWrapper>
          <SignUpFormSpacer />
        </SignUpMainForm>
      </SignUpWrapper>
    </FormRegistrationStep2Controller>
  )
}

export default FormRegistrationStep2
