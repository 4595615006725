import { Box } from '@mui/material'
import { styled as styledMaterial } from '@mui/material/styles'

export const Wrapper = styledMaterial(Box)(() => ({
  backgroundColor: '#F9FAFC',
}))

export const Container = styledMaterial(Box)(() => ({
  margin: '0 auto',
  padding: '40px 0 10px 0',
  minHeight: '82vh',
  maxWidth: '88%',
}))
