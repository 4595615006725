import { List, ListItemButton, ListItemText, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { MyAccountContext } from '../../context'
import { MenuContainerWrapper } from './SideMenu.styles'

export function SideMenu() {
  const setSelectedMenuOption = useContextSelector(
    MyAccountContext,
    (context) => context.setSelectedMenuOption
  )

  const menuOptions = [{ label: 'Dados de Acesso', value: 'option1' }]

  return (
    <MenuContainerWrapper>
      <Typography
        data-testid="title"
        variant="$font-title-2"
        color="$color-text-primary"
        sx={{ fontSize: '24px', lineHeight: '32px' }}
      >
        Minha conta
      </Typography>
      <List>
        {menuOptions.map((option) => (
          <ListItemButton
            key={option.value}
            onClick={() => setSelectedMenuOption(option.value)}
            sx={{
              backgroundColor: '#ffffff',
              color: '#2970ff',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            <ListItemText primary={option.label} />
          </ListItemButton>
        ))}
      </List>
    </MenuContainerWrapper>
  )
}
