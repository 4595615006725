import { useQuery } from '@tanstack/react-query'

import HTTPClient from '../..'

export const getPropositionApi = async (
  propositionId,
  openDataResource,
  openDataId
) => {
  if (openDataResource && openDataId) {
    const { data } = await HTTPClient.get(
      `propositions/${openDataResource}/${openDataId}`
    )
    return data
  }
  const { data } = await HTTPClient.get(`propositions/${propositionId}`)
  return data
}

export const useGetProposition = (
  propositionId,
  openDataResource,
  openDataId
) =>
  useQuery(['get-proposition'], () =>
    getPropositionApi(propositionId, openDataResource, openDataId)
  )
