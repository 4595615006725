import { NewUserView } from './view'
import { NewUserController } from './controller'

export default function Users() {
  return (
    <NewUserController>
      <NewUserView />
    </NewUserController>
  )
}
