import { EmailConfirmationView } from './view'
import { EmailConfirmationController } from './controller'

export default function EmailConfirmation() {
  return (
    <EmailConfirmationController>
      <EmailConfirmationView />
    </EmailConfirmationController>
  )
}
