/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import NoDataSVG from '@assets/icons/nodata.svg'
import InputLabel from '@mui/material/InputLabel'
import { ContainerScroll, Input } from '@components/atoms'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { Container, Content } from './FilterSelectDropdown.styles'
import { EmptyData } from '@pages/private/Monitor/containers/GeneralPanel/styles'
import { FilterSelectDropdownLoading } from './components/FilterSelectDropdownLoading'

export interface FilterSelectDropdownOption {
  label: string
  value: string
}

type FilterSelectDropdownProps = {
  label: string
  options: FilterSelectDropdownOption[]
  values: string[]
  loading?: boolean
  onOpen?: () => void
  onFilter?: (value: string) => void
  onChangeOptions: (option: string[] | any) => void
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<void>
  size?: 'medium' | 'small' | 'tiny'
}

export default function FilterSelectDropdown({
  onChangeOptions,
  options,
  hasNextPage,
  label,
  loading,
  fetchNextPage,
  values,
  onFilter,
  onOpen,
  size,
  ...rest
}: FilterSelectDropdownProps) {
  const [searchText, setSearchText] = useState('')

  const handleScroll = useCallback(() => {
    if (fetchNextPage && !loading && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, loading])

  const handleSearchTextChange = useCallback(
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchText(value)
      onFilter?.(value)
    },
    [options]
  )

  const handleCheckboxClick = useCallback(
    (value: string) => {
      if (values.includes(value)) {
        onChangeOptions((prevValues: string[]) =>
          prevValues?.filter((v) => v !== value)
        )
      } else {
        onChangeOptions((prev: string[]) => [...prev, value])
      }
    },
    [values, onChangeOptions]
  )

  const handleSelectAllClick = useCallback(() => {
    if (values.length === options.length) {
      onChangeOptions([])
    } else {
      const allValues = options.map((option) => option.value)
      onChangeOptions(allValues)
    }
  }, [options, values, onChangeOptions])

  return (
    <Container {...rest} variant="outlined" size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={label}
        size={size || 'small'}
        value={values}
        multiple
        label={label}
        onOpen={() => onOpen?.()}
        renderValue={(selected) =>
          options
            .filter((option: FilterSelectDropdownOption) =>
              selected.includes(option.value)
            )
            .map((option: FilterSelectDropdownOption) => option.label)
            .join(',')
        }
      >
        {onFilter && (
          <Stack px={1} pb={1}>
            <Input
              label="Buscar"
              type="Search"
              value={searchText}
              size={size || 'small'}
              onChange={handleSearchTextChange}
            />
          </Stack>
        )}

        {loading === true && options.length === 0 && (
          <FilterSelectDropdownLoading
            visible={loading || false}
            size="regular"
          />
        )}

        {loading === false && options.length === 0 && (
          <EmptyData>
            <Stack p={3} spacing={2} direction="column" alignItems="center">
              <img src={NoDataSVG} alt="Sem dados" />
              <Typography
                variant="$font-body-base"
                color="$color-text-tertiary"
              >
                Não há opções disponíveis
              </Typography>
            </Stack>
          </EmptyData>
        )}

        {options.length > 0 && (
          <Content>
            <ContainerScroll onScrollBottom={handleScroll} spacing={0} pr={0}>
              {options.length > 0 && (
                <MenuItem onClick={handleSelectAllClick}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Input
                      type="checkbox"
                      size="small"
                      checked={
                        values.length > 0 && values.length === options.length
                      }
                    />
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Selecionar Todas
                    </Typography>
                  </Stack>
                </MenuItem>
              )}
              {options?.map((option: FilterSelectDropdownOption) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={() => handleCheckboxClick(option?.value)}
                >
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Input
                      type="checkbox"
                      size="small"
                      data-cy={option?.value}
                      checked={values.indexOf(option?.value) > -1}
                    />
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {option.label}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
              <FilterSelectDropdownLoading
                visible={loading || false}
                size="small"
              />
            </ContainerScroll>
          </Content>
        )}
      </Select>
    </Container>
  )
}

FilterSelectDropdown.defaultProps = {
  hasNextPage: false,
  fetchNextPage: undefined,
}
