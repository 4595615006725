import { useParams } from 'react-router-dom'
import { SecondaryPage } from '@components/templates'
import SavedBucketsList from '@containers/SavedBucketsList'

export function SavedBucketsPanel() {
  const { id } = useParams()
  return (
    <SecondaryPage
      id="saved-search-page"
      title="Dados Favoritados"
      description="Veja páginas dos Diários Oficiais, discursos, notícias, tweets entre outros dados favoritados no painel"
      helper="Diferente dos dados monitorados, os dados favoritados não sofrem
      alterações ou atualizações como proposições, Eventos, Órgãos,
      Pesquisa Salva e Stakeholders"
    >
      <SavedBucketsList monitorId={id} />
    </SecondaryPage>
  )
}
