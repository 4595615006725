import { createContext } from 'use-context-selector'
import { SocialEntity } from '@services/nomos_api/entities/social'

export type SocialDetailsContextType = {
  social: SocialEntity | undefined
  keyword: string
  buckets: string
  isLoading: boolean
}

export const SocialDetailsContext = createContext<SocialDetailsContextType>(
  {} as SocialDetailsContextType
)
