import React from 'react'
import { CountryCode } from 'libphonenumber-js/types'
import { getCountries } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import ptBR from 'react-phone-number-input/locale/pt-BR.json'
import { InputSelect, SelectionPointer, Wrapper } from './styles'
import { CaretDown } from '@phosphor-icons/react'
import { theme } from '@theme/index'

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  onValue: (country: CountryCode | undefined) => void
}

export function CountrySelect({ value, onValue }: Props): JSX.Element {
  return (
    <Wrapper>
      <InputSelect
        value={value}
        tabIndex={-1}
        onChange={(event) =>
          onValue((event.target.value || undefined) as CountryCode | undefined)
        }
      >
        {getCountries().map((country) => (
          <option key={country} value={country}>
            {ptBR[country]}
          </option>
        ))}
      </InputSelect>
      <SelectionPointer>
        {flags[(value as CountryCode) || 'BR']?.({
          title: (value as string) || 'BR',
        })}
        <CaretDown
          weight="duotone"
          size={12}
          color={theme.palette.primary.dark}
        />
      </SelectionPointer>
    </Wrapper>
  )
}
