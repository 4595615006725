import { styled, Box } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const Content = styled(Box)`
  flex: 1;
`
