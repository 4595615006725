import styled from 'styled-components'
import { Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { theme } from '@theme/index'

export const Container = styled(Box)`
  height: 100%;
  max-height: 100%;
  display: flex;
  max-width: 715px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

export const HeaderWrapper = styled(Box)`
  max-width: 649px;
  background-color: ${theme.palette['$color-background-tertiary']};
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
`

export const Header = styled(Box)`
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const IconClose = styled(Close)`
  font-size: 16px !important;
  color: ${theme.palette['$color-border-dark']};
`

export const Body = styled(Box)`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

export const BodyScroll = styled(Body)`
  position: relative;
`

export const BodyContainerFill = styled(Box)`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BodyInputContainerWrapper = styled(Box)`
  height: auto;
  padding-left: 32px;
  padding-right: 32px;
`

export const BodyInputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  height: auto;
  min-height: 24px;
  padding-top: 21px;
  padding-bottom: 21px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${theme.palette['$color-border-primary']};
`
