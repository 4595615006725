import styled from 'styled-components'
import { FormControl } from '@mui/material'

export const Container = styled.div`
  gap: 32px;
  padding: 32px;
  display: flex;
  max-width: 650px;
  flex-direction: column;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const SelectContainer = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Chips = styled.div`
  display: flex;
  gap: 16px;
`
