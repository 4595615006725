/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NoteManagerDrawerContext } from './context'
import { NoteManagerDrawerControllerProps } from './types'
import { NoteEntity } from '@services/nomos_api/entities/note'
import { findNotesApi } from '@services/nomos_api/resources/notes/find'
import { deleteNoteApi } from '@services/nomos_api/resources/notes/delete'
import {
  UpdateNoteEntity,
  updateNoteApi,
} from '@services/nomos_api/resources/notes/update'
import { useGetAllUsers } from '@services/nomos_api/resources/users/read'
import { readNoteFileApi } from '@services/nomos_api/resources/notes/file'
import { ContainerScrollRef } from '@components/atoms/ContainerScroll/ContainerScroll'
import { MentionType } from '@type/mention'
import { produce } from 'immer'

export function NoteManagerDrawerController({
  children,
  bucket,
  id,
}: NoteManagerDrawerControllerProps): JSX.Element {
  const scrollRef =
    useRef<ContainerScrollRef>() as React.MutableRefObject<ContainerScrollRef>
  const pageLimit = 10
  const firstLoad = useRef<boolean>(true)
  const [filterBy, setFilterBy] = useState<'note' | 'file'>('note')
  const [notes, setNotes] = useState<NoteEntity[]>([])
  const [ended, setEnded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { data: users } = useGetAllUsers({ limit: 1000, page: 1 })

  const mentions: MentionType[] = useMemo(
    () =>
      users?.results?.map?.((user) => ({
        id: user.id,
        display: user.name,
      })) || [],
    [users]
  )

  const handleDeleteNote = (noteId: string) => {
    return deleteNoteApi(noteId).then((deletedNote) => {
      setNotes((prev) =>
        produce(prev, (draft) => {
          const index = draft.findIndex((note) => note.id === deletedNote.id)
          if (index !== -1) draft.splice(index, 1)
        })
      )
    })
  }

  const handleAddNote = (note: NoteEntity) => {
    scrollRef.current.scrollTop()
    setNotes((prev) =>
      produce(prev, (draft) => {
        draft.unshift(note)
      })
    )
  }

  const handleUpdateNote = (
    id: string,
    note: UpdateNoteEntity
  ): Promise<NoteEntity> => {
    return updateNoteApi(id, note).then((updatedNote) => {
      setNotes(
        notes.map((n) => {
          if (n.id === updatedNote.id) return updatedNote
          return n
        })
      )
      return updatedNote
    })
  }

  const handleOpenNoteFile = (note: NoteEntity) => {
    return readNoteFileApi({ id: note.id }).then((data) => {
      window.open(data.url, '_blank')
      return data
    })
  }

  const handleFindNotes = (cursor?: string) => {
    setLoading(true)
    findNotesApi({ bucket, originId: id, limit: 10, cursor, only: filterBy })
      .then((results) => {
        const pageNotes = results as NoteEntity[]
        if (!cursor) setNotes(pageNotes)
        else setNotes((prevNotes) => [...prevNotes, ...pageNotes])
        setEnded(pageNotes.length < pageLimit)
        firstLoad.current = false
      })
      .finally(() => setLoading(false))
  }

  const handleLoadMoreNotes = useCallback(() => {
    // return if first page load not called yet
    if (firstLoad.current === true) return
    // return if notes is already loading
    if (loading) return
    // return if data load was ended
    if (ended) return
    // get cursor to next page
    const [cursor] = notes.slice(-1)
    // invoke next page function
    handleFindNotes(cursor?.id)
  }, [notes, ended, loading, filterBy])

  const handleRefreshNotes = useCallback(() => {
    firstLoad.current = true
    setNotes([])
    setEnded(false)
    handleFindNotes()
  }, [notes, ended, filterBy])

  useEffect(() => {
    handleRefreshNotes()
  }, [filterBy])

  // add side-effect to display/hide hubspot button
  useEffect(() => {
    const element = document.getElementById('hubspot-messages-iframe-container')
    if (element) element.classList.toggle('hidden', true)
    return () => {
      if (element) element.classList.toggle('hidden', false)
    }
  }, [])

  const state = useMemo(
    () => ({
      notes,
      loading,
      scrollRef,
      filterBy,
      mentions,
      setFilterBy,
      handleAddNote,
      handleDeleteNote,
      handleRefreshNotes,
      handleUpdateNote,
      handleOpenNoteFile,
      handleLoadMoreNotes,
    }),
    [
      notes,
      loading,
      scrollRef,
      filterBy,
      mentions,
      setFilterBy,
      handleDeleteNote,
      handleRefreshNotes,
      handleUpdateNote,
      handleLoadMoreNotes,
    ]
  )
  return (
    <NoteManagerDrawerContext.Provider value={state}>
      {children}
    </NoteManagerDrawerContext.Provider>
  )
}
