import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { CardTemplate } from '../CardTemplate'
import { SocialIcon } from '@enums/SocialIcon'
import { Stack, Typography } from '@mui/material'
import { BucketsEnum } from '@utils/buckets/type'
import { CalendarBlank } from '@phosphor-icons/react'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import { SocialEntity } from '@services/nomos_api/entities/social'
import { Avatar, Button, SaveBucketButton } from '@components/atoms'
import {
  formatDateTime,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { Content, Header, HeaderContent, RowContent } from './styles'
import { capitalizeName } from '@utils/functions/capitalize/capitalize'

export type CardSocialProps = { social: SocialEntity }

export function CardSocial({ social: socialState }: CardSocialProps) {
  const [social, setSocial] = useState<SocialEntity>(socialState)
  const { url } = useSearchResultsUrl(`/social/${social.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setSocial(
        produce(social, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setSocial, social]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setSocial({
        ...social,
        savedBuckets: social.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setSocial, social]
  )

  const text = useMemo(() => {
    return formatterHighlight(social, ['text'], 'Não Informado')
  }, [])

  return (
    <CardTemplate
      content={[
        <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
          <Header>
            <HeaderContent>
              <Avatar
                alt={social.stakeholder.name}
                src={social.stakeholder.photoUrl}
                width={56}
                height={56}
              />
              <RowContent>
                <Typography variant="$font-title-5" color="$color-text-primary">
                  {capitalizeName(social.stakeholder.name)}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  ({social.stakeholder.title})
                </Typography>
              </RowContent>
              <RowContent>
                <Typography variant="$font-body-2" color="$color-text-primary">
                  Partido:
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  {social.stakeholder.acronymParty} -{' '}
                  {social.stakeholder.electoralUF}
                </Typography>
              </RowContent>
              <RowContent>
                <CalendarBlank size={18} />
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  {formatDateTime(social.postedAt)}
                </Typography>
              </RowContent>
              <RowContent>{SocialIcon[social.resource]}</RowContent>
            </HeaderContent>
          </Header>
          <Content alignItems="end">
            <Typography variant="$font-body-base" color="$color-text-secondary">
              {text}
            </Typography>
          </Content>
        </Stack>,
      ]}
      actions={[
        <SaveBucketButton
          id={social?.id}
          bucket={BucketsEnum.social}
          isSaved={social?.savedBuckets?.length > 0}
          openDataResource={social?.openDataResource}
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '30px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
