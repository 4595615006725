import HTTPClient from '../..'
import { searchEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'

export type SearchCvmAPIProps = {
  page: number
  limit: number
  keyword: string
  sort: string
  modes: string[]
  filter: object
  keywords: AdvancedSearchType
}

export const searchCvmAPI = async (props: SearchCvmAPIProps) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=${props.limit || 10}`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(`/search/cvm?${params.join('&')}`, {
    ...props.filter,
    keywords: removeEmptyArrays(props.keywords),
  })
  return data
}

export const useSearchCvmMutation = () =>
  useMutation(['search-cvm'], searchCvmAPI)
