import {
  Box,
  Chip,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import { FixedSizeList } from 'react-window'

import styled from 'styled-components'

export const FilterWrapper = styled(Box)`
  width: 100%;
`
export const FilterAccordion = styled(Accordion)`
  background-color: transparent !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e7e9ed;
`
export const FilterAccordionSummary = styled(AccordionSummary)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`
export const FilterAccordionTitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const FilterAccordionTitle = styled(Typography)`
  font-size: 16px !important;
  color: #3c4c70 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`
export const FilterAccordionChip = styled(Chip)`
  padding: 0px !important;
  font-size: 12px !important;
  margin-right: 5px;
  margin-left: 20px;
  min-width: 20px !important;
  width: auto !important;
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  & label {
    overflow: none;
    text-overflow: none;
  }
`
export const FilterAccordionDetails = styled(AccordionDetails)`
  padding: 0px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
`
export const FilterAccordionInput = styled(Box)`
  padding-bottom: 0px !important;
`
export const FilterAccordionInline = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FilterAccordionLabel = styled(Typography)`
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #6d787d !important;
`

export const FilterListVirtualized = styled(FixedSizeList)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow-x: hidden !important;
`
