import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const TooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background?.default,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background?.default,
    color: theme.palette.muted?.main,
    boxShadow: theme.shadows[6],
    padding: 12,
  },
}))
