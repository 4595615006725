import HTTPClient from '../..'
import { User, UserUpdate } from '@services/nomos_api/entities/user'
import { FormConfirmTokenType } from '@services/nomos_api/types/form_confirm_token_type'
import { FormUserPatchType } from '@services/nomos_api/types/form_user_patch_type'

export const updateUser = async (
  user: UserUpdate
): Promise<FormUserPatchType> => {
  const { data } = await HTTPClient.patch<FormUserPatchType>(`/user`, user)
  return data
}

export const updateConfirmUser = async (
  props: FormConfirmTokenType
): Promise<User> => {
  const { data } = await HTTPClient.post<User>(`/user/confirm`, props)
  return data
}
