import { Button, Input, TextLink } from '@components/atoms'
import InputPhone from '@components/atoms/InputPhone'
import { CardRadio } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import { Grid, Stack, Switch, Typography } from '@mui/material'
import { UserUpsertContext } from '@containers/UserUpsert/context'
import { Contacts, Container, Content, InfoLabel, Inputs } from './styles'

import {
  NomosProductCodeEnum,
  NomosProductDesc,
  NomosProductRoleName,
} from '@enums/NomosProductEnum'
import { RoleSeatCTA } from '../RoleSeatCTA'
import { useAuth } from '@contexts/Auth'

export default function GeneralData() {
  const { user: authUser } = useAuth()
  const user = useContextSelector(UserUpsertContext, (s) => s.user)
  const isEditting = useContextSelector(UserUpsertContext, (s) => s.isEditting)
  const isLoading = useContextSelector(UserUpsertContext, (s) => s.isLoading)
  const formik = useContextSelector(UserUpsertContext, (s) => s.formik)
  const seats = useContextSelector(UserUpsertContext, (s) => s.seats)
  const loadingSeats = useContextSelector(
    UserUpsertContext,
    (s) => s.loadingSeats
  )

  return (
    <Container>
      <Content>
        <InfoLabel>
          <Typography variant="$font-title-5" color="$color-text-primary">
            Administrador
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Acesso administrativo
          </Typography>
        </InfoLabel>
        <Inputs>
          <Grid container>
            <Grid item xs={12} xl={8}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Switch
                  name="admin"
                  defaultChecked={formik.values.admin}
                  onChange={formik.handleChange}
                />
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  Conceder permissão para o usuário adicionar novos membros,
                  gerenciar a organização, cobranças e assinaturas.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Inputs>
      </Content>

      <Content>
        <InfoLabel>
          <Typography variant="$font-title-5" color="$color-text-primary">
            Permissão
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Defina a papel do usuário
          </Typography>
        </InfoLabel>

        <Grid container>
          <Grid item xs={12} xl={8}>
            <CardRadio
              name="role"
              direction="row"
              value={formik.values.role}
              onChange={formik.handleChange}
              options={[
                {
                  title: NomosProductRoleName[NomosProductCodeEnum.MONITOR_PRO],
                  value: NomosProductCodeEnum.MONITOR_PRO,
                  description:
                    NomosProductDesc[NomosProductCodeEnum.MONITOR_PRO],
                  legendElement: (
                    <RoleSeatCTA
                      loading={loadingSeats}
                      role={NomosProductCodeEnum.MONITOR_PRO}
                      isTrial={seats?.isTrial}
                      seats={
                        seats?.products?.[NomosProductCodeEnum.MONITOR_PRO]
                      }
                    />
                  ),
                  disabled:
                    loadingSeats ||
                    !seats?.products?.[NomosProductCodeEnum.MONITOR_PRO]
                      ?.enabled,
                },
                {
                  title:
                    NomosProductRoleName[NomosProductCodeEnum.MONITOR_LIGHT],
                  value: NomosProductCodeEnum.MONITOR_LIGHT,
                  description:
                    NomosProductDesc[NomosProductCodeEnum.MONITOR_LIGHT],
                  legendElement: (
                    <RoleSeatCTA
                      loading={loadingSeats}
                      role={NomosProductCodeEnum.MONITOR_LIGHT}
                      isTrial={seats?.isTrial}
                      seats={
                        seats?.products?.[NomosProductCodeEnum.MONITOR_LIGHT]
                      }
                    />
                  ),
                  disabled:
                    loadingSeats ||
                    !seats?.products?.[NomosProductCodeEnum.MONITOR_LIGHT]
                      ?.enabled,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Content>

      <Content>
        <InfoLabel>
          <Typography variant="$font-title-5" color="$color-text-primary">
            Informações do usuário
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            {isEditting ? (
              <>
                Apenas os usuários podem
                <br />
                alterar suas próprias
                <br />
                informações
              </>
            ) : (
              <>
                Preencha os dados do usuário <br /> para criá-lo
              </>
            )}
          </Typography>
          {user?.id === authUser?.id && (
            <TextLink href="/my-account" text="Alterar meus dados" />
          )}
        </InfoLabel>

        <Inputs>
          <Grid container spacing={4}>
            <Grid item xs={12} xl={8}>
              <Input
                id="name"
                name="name"
                label="Nome do Usuário"
                size="small"
                disabled={isEditting}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helpertext={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} xl={8}>
              <Contacts>
                <Input
                  id="email"
                  name="email"
                  label="Email"
                  size="small"
                  disabled={isEditting}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helpertext={formik.touched.email && formik.errors.email}
                />
                <InputPhone
                  id="phone"
                  size="small"
                  name="phone"
                  label="Telefone"
                  placeholder="(__) _____-____"
                  disabled
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('phone', value)}
                  error={formik.touched.phone && formik.errors.phone}
                />
              </Contacts>
            </Grid>
          </Grid>
        </Inputs>
      </Content>

      <Stack flexDirection="row" justifyContent="flex-end" mt={3}>
        <Button
          type="button"
          text="Avançar"
          variant="outlined"
          sx={{ minWidth: 160 }}
          loading={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
    </Container>
  )
}
