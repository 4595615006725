import React from 'react'
import { useUpdateSavedProposition } from '@services/nomos_api/resources/monitor/update-saved-proposition'

import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

type Props = {
  priority: number
  savedPropositionId: string
}

export function PropositionCellPriority({
  priority,
  savedPropositionId,
}: Props): JSX.Element {
  const [value, setValue] = React.useState<number>(priority)
  const { isLoading, mutate } = useUpdateSavedProposition()

  const handleUpdateValue = (newValue: number) => {
    if (newValue === value) return
    setValue(newValue)
    mutate({ priority: newValue, savedPropositionId })
  }

  return (
    <Select
      size="tiny"
      fullWidth
      value={value}
      disabled={isLoading}
      onChange={(event) =>
        handleUpdateValue(Number.parseInt(String(event.target.value), 10))
      }
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        '& fieldset': { border: 'none' },
      }}
      endAdornment={
        isLoading && (
          <InputAdornment position="end" sx={{ pr: 3 }}>
            <CircularProgress size={16} />
          </InputAdornment>
        )
      }
    >
      <MenuItem value={0}>
        <Typography color="semantic.error.main">Alta</Typography>
      </MenuItem>
      <MenuItem value={1}>
        <Typography color="semantic.warning.main">Média</Typography>
      </MenuItem>
      <MenuItem value={2}>
        <Typography color="semantic.success.main">Baixa</Typography>
      </MenuItem>
    </Select>
  )
}
