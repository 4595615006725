import { useModal } from '@contexts/Modal'
import Button from '@components/atoms/Button/Button'
import { IconButton, Stack, Typography } from '@mui/material'
import { ErrorOutline, Close } from '@mui/icons-material'
import { Container, Content, Footer, Header } from './ConfirmModal.styles'

type ConfirmModalProp = {
  title: string
  info: string
  confirmText: string
  actionText: string
  onConfirm: () => void
}

export default function ConfirmModal({
  onConfirm,
  title,
  info,
  confirmText,
  actionText,
}: ConfirmModalProp) {
  const { closeModal } = useModal()

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    closeModal()
  }

  return (
    <Container data-testid="confirm-modal-component">
      <Header>
        <IconButton onClick={closeModal} size="small">
          <Close />
        </IconButton>
      </Header>

      <Content>
        <div>
          <ErrorOutline />
          <Typography variant="$font-title-3" color="$color-text-primary">
            {title}
          </Typography>
        </div>

        <Typography
          variant="$font-body-base"
          color="$color-text-primary"
          maxWidth={420}
        >
          {info}
        </Typography>

        <Typography variant="$font-body-2" color="muted.dark">
          {confirmText}
        </Typography>
      </Content>

      <Footer>
        <Stack direction="row" spacing={2}>
          <Button
            id="confirm-action-button"
            text={actionText}
            variant="outlined"
            onClick={() => handleConfirm()}
          />

          <Button text="Cancelar" onClick={closeModal} />
        </Stack>
      </Footer>
    </Container>
  )
}
