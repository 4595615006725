import React from 'react'
import { Stack, Pagination, Typography, Select, MenuItem } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import _ from 'lodash'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import { Wrapper } from './FormPagination.styles'

function FormPaginationComponent() {
  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )
  const pagination = useContextSelector(
    SearchResultsContext,
    (s) => s.pagination
  )
  
  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )
  
  const search = useContextSelector(SearchResultsContext, (s) => s.search)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if ((pagination?.pages || 0) === 0) return <></>

  return (
    <Wrapper justifyContent={{xs: 'center', md:'flex-end'}}>
      <Stack direction={{xs:'column-reverse', md: "row"}} alignItems="center" gap={2}>
        <Pagination
          shape="rounded"
          size='small'
          count={pagination.pages}
          page={_.get(search, ['buckets', selectedBucket, 'page'].join('.'))}
          onChange={(_, page) =>
            handleUpdateSearch(
              {
                [['buckets', selectedBucket, 'page'].join('.')]: page,
              },
              true
            )
          }
        />
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Itens por página:
          </Typography>
          <Select
            size="tiny"
            value={_.get(
              search,
              ['buckets', selectedBucket, 'limit'].join('.')
            )}
            onChange={(e) =>
              handleUpdateSearch({
                [['buckets', selectedBucket, 'limit'].join('.')]:
                  e.target.value,
              })
            }
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </Wrapper>
  )
}

export default FormPaginationComponent
