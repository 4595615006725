import { db } from '../..'
import { onValue, ref } from 'firebase/database'

export const listenCountTotal = (
  userId: string,
  callback: (count: number) => void
) => {
  const query = ref(db, `alerts/${userId}`)
  return onValue(query, (snapshot) => {
    if (snapshot.exists()) callback(snapshot.size)
    else callback(0)
  })
}
