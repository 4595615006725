import { Box, Typography, styled } from '@mui/material'

export const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`
export const SearchTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`
export const SearchTitleMuted = styled(Typography)`
  color: #9ea5b8;
  font-size: 14px;
  margin-left: 6px;
  line-height: 150%;
`
export const SearchTitleHilight = styled(Typography)`
  color: #3c4c70;
  font-size: 14px;
  margin-left: 6px;
  line-height: 150%;
`
