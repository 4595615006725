import { useCallback, useRef } from 'react'

export function useRefWidth(): [
  Record<number, number>,
  (node: HTMLDivElement | null, index: number) => void
] {
  const nodeRef = useRef<Record<number, number>>({})

  const setRef = useCallback((node: HTMLDivElement | null, index: number) => {
    if (!nodeRef.current[index]) nodeRef.current[index] = node?.clientWidth || 0

    const getDimensions = () => {
      nodeRef.current = Object.values(nodeRef.current).map((element, index) => {
        return nodeRef?.current?.[index] && nodeRef?.current?.[index] !== 0
          ? nodeRef?.current?.[index] || 0
          : node?.getBoundingClientRect().width || 0
      })
    }

    if (nodeRef.current) {
      getDimensions()
    }

    window.addEventListener('resize', getDimensions)

    // return () => {
    //   window.removeEventListener('resize', getDimensions)
    // }
  }, [])

  return [nodeRef.current, setRef]
}
