import { PanelVotings } from '../PanelVotings'
import { PanelGeneral } from '../PanelGeneral'
import { PanelRelateds } from '../PanelRelateds'
import { PanelAttacheds } from '../PanelAttacheds'
import { PanelRapporteurs } from '../PanelRapporteurs'
import { PanelProceedings } from '../PanelProceedings'
import { PanelAttachments } from '../PanelAttachments'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'

export function TabPanels() {
  const selectedTab = useContextSelector(
    PropositionDetailsContext,
    (s) => s.selectedTab
  )

  if(selectedTab === "general") return <PanelGeneral visible/>
  if(selectedTab === "relators") return <PanelRapporteurs visible/>
  if(selectedTab === "relateds") return <PanelRelateds visible/>
  if(selectedTab === "tramitations") return <PanelProceedings visible/>
  if(selectedTab === "attacheds") return <PanelAttacheds visible/>
  if(selectedTab === "attachments") return <PanelAttachments visible/>
  if(selectedTab === "votings") return <PanelVotings visible/>

  return <></>
}
