/* eslint-disable no-unused-vars */
import { GeneralPanelView } from './view'
import GeneralPanelController from './controller'

export function GeneralPanel() {
  return (
    <GeneralPanelController>
      <GeneralPanelView />
    </GeneralPanelController>
  )
}
