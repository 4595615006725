import { SVGProps } from 'react'

function LockIcon({
  width = 32,
  height = 32,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 13.3333C21.3333 8.91507 20.418 5.33334 15.9997 5.33334C11.5814 5.33334 10.6667 8.91507 10.6667 13.3333"
        stroke="#202122"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17.3333C8 15.1242 9.79086 13.3333 12 13.3333H20C22.2091 13.3333 24 15.1242 24 17.3333V22.6667C24 24.8758 22.2091 26.6667 20 26.6667H12C9.79086 26.6667 8 24.8758 8 22.6667V17.3333Z"
        stroke="#202122"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="16" cy="20" r="1.33333" fill="#2D3A40" />
    </svg>
  )
}
export default LockIcon
