import { SVGProps } from 'react'

function MonitorIcon({
  width = 24,
  height = 24,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <rect
        x="4"
        y="4"
        width="16"
        height="12"
        rx="2"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V20"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20H16"
        stroke="#202122"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MonitorIcon
