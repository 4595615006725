import { StripePortalLinkController } from './controller'
import { StripePortalLinkProps } from './types'
import { StripePortalLinkView } from './view'

export function StripePortalLink(props: StripePortalLinkProps): JSX.Element {
  return (
    <StripePortalLinkController {...props}>
      <StripePortalLinkView {...props} />
    </StripePortalLinkController>
  )
}
