import { ExportPropositionModalView } from './view'
import { ExportPropositionModalType } from './types'
import { ExportPropositionModalController } from './controller'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export function ExportPropositionModal(props: ExportPropositionModalType) {
  useByPassSubscription()
  return (
    <ExportPropositionModalController {...props}>
      <ExportPropositionModalView />
    </ExportPropositionModalController>
  )
}
