import { PurchaseSeatModalController } from './controller'
import { PurchaseSeatModalProps } from './types'
import { PurchaseSeatModalView } from './view'

export function PurchaseSeatModal(props: PurchaseSeatModalProps): JSX.Element {
  return (
    <PurchaseSeatModalController {...props}>
      <PurchaseSeatModalView {...props} />
    </PurchaseSeatModalController>
  )
}
