import { Avatar, IconButton, Stack, Typography } from '@mui/material'
import {
  Body,
  Container,
  Header,
  HeaderWrapper,
  IconClose,
  BodyInputContainer,
  BodyInputContainerWrapper,
  BodyScroll,
} from './styles'
import { useAuth } from '@contexts/Auth'
import { useDrawer } from '@contexts/Drawer'
import { NoteManagerDrawerProps } from './types'
import { TabSelector } from '@components/organisms'
import { ContainerScroll, LoadingFloat } from '@components/atoms'
import { generateInitials } from '@utils/functions/string/string'
import { InputNote } from '@components/organisms/InputNote/InputNote'
import { textToColor } from '@utils/functions/colors/colors'
import { NoteContentSelector } from './components/note_content_selector'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawerContext } from './context'

export function NoteManagerDrawerView({
  bucket,
  id,
}: NoteManagerDrawerProps): JSX.Element {
  const { user } = useAuth()

  const { closeDrawer } = useDrawer()

  const loading = useContextSelector(NoteManagerDrawerContext, (s) => s.loading)
  const notes = useContextSelector(NoteManagerDrawerContext, (s) => s.notes)

  const mentions = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.mentions
  )

  const filterBy = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.filterBy
  )

  const setFilterBy = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.setFilterBy
  )

  const handleLoadMoreNotes = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.handleLoadMoreNotes
  )

  const handleAddNote = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.handleAddNote
  )

  const scrollRef = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.scrollRef
  )

  return (
    <Container>
      <HeaderWrapper>
        <Header>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="$font-title-3" color="$color-text-primary">
              Anotações e Comentários
            </Typography>
            <IconButton onClick={closeDrawer}>
              <IconClose />
            </IconButton>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Utilize este recurso para criar notas e anexar arquivos. Atualize
              sua equipe sobre os projetos que você monitora ou sobre o
              andamento do seu trabalho em uma determinada legislação.
            </Typography>
            <TabSelector
              initialTab={filterBy}
              onChangeTab={(tab) => setFilterBy(tab as 'note' | 'file')}
              tabs={[
                { value: 'note', label: 'Anotações' },
                { value: 'file', label: 'Arquivos' },
              ]}
            />
          </Stack>
        </Header>
      </HeaderWrapper>
      <Body>
        <BodyScroll>
          <ContainerScroll
            ref={scrollRef}
            auto="true"
            disableScrollIndicator
            onScrollBottom={handleLoadMoreNotes}
          >
            <NoteContentSelector />
          </ContainerScroll>
          <LoadingFloat
            visible={notes.length > 0 && loading}
            label="Carregando..."
          />
        </BodyScroll>
        <BodyInputContainerWrapper>
          <BodyInputContainer>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                backgroundColor: textToColor(String(user?.id)),
              }}
            >
              <Typography variant="$font-body-2">
                {generateInitials(String(user?.name))}
              </Typography>
            </Avatar>
            <InputNote
              bucket={bucket}
              originId={id}
              onNoteCreated={handleAddNote}
              options={mentions}
            />
          </BodyInputContainer>
        </BodyInputContainerWrapper>
      </Body>
    </Container>
  )
}
