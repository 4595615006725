import colors from '@theme/colors'
import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  margin-top: 64px;
  margin-bottom: 67px;

  padding-top: 32px;
  padding-left: 64px;
  padding-right: 64px;
  padding-bottom: 32px;
`

export const Timeline = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const Step = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Circle = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  color: ${colors['$color-background-primary']};
  background-color: ${(props) =>
    props.color === 'active'
      ? colors['$color-action-background-primary-default']
      : '#CED2DB'};
`

export const Line = styled.div`
  background-color: #ced2db;
  width: 106px;
  height: 1px;
`
