import * as yup from 'yup'
import { useFormik } from 'formik'
import { UserUpsertContext } from './context'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '@contexts/Snackbar'
import { useCallback, useMemo, useState } from 'react'
import { name, email } from '@utils/forms/validation'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { createUserAPI } from '@services/nomos_api/resources/users/create'
import { updateUserAPI } from '@services/nomos_api/resources/users/update'

import {
  Member,
  Monitor,
  UpsertMember,
} from '@services/nomos_api/entities/user'
import { useAuth } from '@contexts/Auth'
import { useGetSeats } from '@services/nomos_api/resources/user/seats'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'

export type UsersProps = {
  user?: Member
  children: React.ReactNode
}

function UserUpsertController({ user, children }: UsersProps) {
  const navigate = useNavigate()
  const { user: authUser, updateUserName } = useAuth()
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()

  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const { data: seats, isLoading: loadingSeats } = useGetSeats()
  const [upsertUser, setUpsertUser] = useState<Partial<UpsertMember>>({})
  const isEditting = !!user

  const handleChangeUser = useCallback(
    (filter: Partial<UpsertMember>) => {
      setUpsertUser((prevState) => ({
        ...prevState,
        ...filter,
      }))
    },
    [setUpsertUser]
  )

  const validationSchema = yup.object().shape({
    name,
    email,
  })

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      admin: user?.admin || false,
      role: user?.role || '',
      phone: user?.phone || '',
      monitors: user?.monitors || [],
    },
    validationSchema,
    onSubmit: (values) => {
      handleChangeUser({
        name: values.name,
        email: values.email,
        admin: values.admin,
        role: values.role as unknown as NomosProductCodeEnum,
        // phone: `+${values.phone.match(/\d+/g)?.join('').replace(/\s+/g, '')}`,
      })
      setPage(2)
    },
  })

  const handleSubmit = async (monitors: Monitor[]) => {
    try {
      setLoading(true)
      const data = Object.assign(upsertUser, { monitors }) as UpsertMember

      if (!user) {
        await createUserAPI(data)
        navigate('/users')
      } else {
        const { id, name } = await updateUserAPI(user.id, data)
        // update context user name if user updated is authenticated
        if (name && id === authUser?.id) {
          updateUserName(name)
          window.location.replace(`/users/${authUser.id}`)
        } else {
          navigate(`/users/${user.id}`)
        }
      }
      showSnackbarSuccess('Ação realizada com sucesso')
    } catch (error) {
      const e = error as AxiosErrorResponse
      showSnackbarError(
        e?.response?.data?.message || 'Erro ao tentar salvar dados do usuário'
      )
    } finally {
      setLoading(false)
    }
  }

  const store = useMemo(
    () => ({
      user,
      page,
      seats,
      formik,
      setPage,
      isLoading,
      isEditting,
      loadingSeats,
      handleSubmit,
    }),
    [user, page, formik, setPage, isLoading, handleSubmit]
  )

  return (
    <UserUpsertContext.Provider value={store}>
      {children}
    </UserUpsertContext.Provider>
  )
}

UserUpsertController.defaultProps = {
  user: undefined,
}

export { UserUpsertController }
