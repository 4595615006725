import { NoteManagerDrawerContext } from '../context'
import { useContextSelector } from 'use-context-selector'
import { NoteContentListItem } from './note_content_list_item'
import { Stack } from '@mui/material'

export function NoteContentList(): JSX.Element {
  const notes = useContextSelector(NoteManagerDrawerContext, (s) => s.notes)
  return (
    <Stack direction="column" spacing={2} pl={3} py={4}>
      {notes?.map?.((item) => (
        <NoteContentListItem data={item} />
      ))}
    </Stack>
  )
}
