import { IconButton } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { getBucketName } from '@utils/buckets'
import { FormAlert } from './containers/FormAlert'
import { EditBucketSearchContext } from './context'
import { StepSettings } from './containers/StepSettings'
import { useContextSelector } from 'use-context-selector'
import { Container, Header, Title, IconClose, ContentBody } from './styles'

export function EditBucketSearchView() {
  const { closeDrawer } = useDrawer()

  const bucket = useContextSelector(EditBucketSearchContext, (s) => s.bucket)

  return (
    <Container>
      <Header>
        <Title variant="$font-title-3">{getBucketName(bucket)}</Title>
        <IconButton onClick={closeDrawer}>
          <IconClose />
        </IconButton>
      </Header>
      <FormAlert />
      <ContentBody>
        <StepSettings />
      </ContentBody>
    </Container>
  )
}
