import { ContainerScroll } from '@components/atoms'
import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 32px;
  max-width: 50rem;
  overflow-y: hidden;
`

export const Content = styled(ContainerScroll)`
  flex: 1;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;
`
