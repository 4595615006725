import { HighlightEntity } from '@services/nomos_api/entities/highlight'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'

export type OficialDiaryEntity = {
  id: string
  type: string
  origin: string
  book: string
  content: string
  file: string
  page: number
  date: string
  highlights?: HighlightEntity[]
  createdAt: string
  updatedAt: string
  savedBuckets: SavedBucketEntity[]
}

export const getOficialDiaryByIdAPI = async (
  id: string
): Promise<OficialDiaryEntity> => {
  const { data } = await HTTPClient.get(`oficial_diary/${id}`)
  return data as OficialDiaryEntity
}

export const useGetOficialDiaryDetails = (id: string) =>
  useQuery(['get-oficial-diary-details'], () => getOficialDiaryByIdAPI(id))
