import { ImportPropositionModalView } from './view'
import { ImportpropositionModalProps } from './types'
import { ImportPropositionModalController } from './controller'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export function ImportPropositionModal(
  props: ImportpropositionModalProps
): JSX.Element {
  useByPassSubscription()
  return (
    <ImportPropositionModalController {...props}>
      <ImportPropositionModalView />
    </ImportPropositionModalController>
  )
}
