import { useQuery } from '@tanstack/react-query'

import HTTPClient from '@services/nomos_api'
import { MonitorCompany } from '@services/nomos_api/entities'

type MonitorUserProps = {
  q?: string | undefined
}

export const getCompanylUsersApi = async (
  props: MonitorUserProps
): Promise<MonitorCompany[]> => {
  const searchByName = props.q ? `?q=${props?.q}` : ''

  const data = await HTTPClient.get(`users${searchByName}`)

  return (data?.data || []) as MonitorCompany[]
}

export const useGetCompanylUsers = (props: MonitorUserProps) =>
  useQuery(['get-company-users', props], () => getCompanylUsersApi(props))
