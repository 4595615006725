/* eslint-disable @typescript-eslint/no-explicit-any */
import colors from '@theme/colors'
import { ScheduleContext } from './context'
import { Filter } from '@utils/buckets/type'
import { useCallback, useMemo, useState } from 'react'
import { useSchedule } from '@services/nomos_api/resources/search/events'

import {
  GetAllSavedEventsProps,
  useGetAllSavedEvents,
} from '@services/nomos_api/resources/saved_event/getAll'

type ScheduleControllerProps = {
  children: React.ReactChild
}

export default function ScheduleController({
  children,
}: ScheduleControllerProps) {
  const [eventParams, setEventParams] = useState<GetAllSavedEventsProps>({})
  const [scheduleParams, setScheduleParams] = useState({} as any)

  const handleUpdateFilters = useCallback(
    (filters: GetAllSavedEventsProps | Filter) => {
      setEventParams((prevState) => ({ ...prevState, ...filters }))
    },
    []
  )

  const handleUpdateSchedule = useCallback((filters: any) => {
    setScheduleParams((prevState: any) => ({ ...prevState, ...filters }))
  }, [])

  const handleUpdateCalendar = useCallback(
    (calendarParams: Record<string, string>) => {
      const fromDate = calendarParams['buckets.events.filter.date.from']
      const toDate = calendarParams['buckets.events.filter.date.to']

      handleUpdateFilters({ fromDate, toDate })
      handleUpdateSchedule({
        date: {
          from: fromDate,
          to: toDate,
        },
      })
    },
    []
  )

  const { data: events, isFetching: isLoadingEvents } =
    useGetAllSavedEvents(eventParams)

  const { data: schedules, isFetching: isLoadingSchedules } =
    useSchedule(scheduleParams)

  const eventsToList = [
    ...(events || []).map((r: any) =>
      Object.assign(r, {
        borderColor: colors['$blue1-medium-dark'],
        background: colors['$color-background-dark'],
      })
    ),
    ...(schedules?.results || []).map((r: any) =>
      Object.assign(r, {
        borderColor: colors['$color-border-secondary'],
        background: colors['$color-background-alternative'],
      })
    ),
  ]

  const store = useMemo(
    () => ({
      events: eventsToList,
      isLoading: isLoadingEvents || isLoadingSchedules,
      handleUpdateFilters,
      handleUpdateSchedule,
      handleUpdateCalendar,
    }),
    [
      eventsToList,
      isLoadingEvents,
      isLoadingSchedules,
      handleUpdateFilters,
      handleUpdateSchedule,
      handleUpdateCalendar,
    ]
  )

  return (
    <ScheduleContext.Provider value={store}>
      {children}
    </ScheduleContext.Provider>
  )
}
