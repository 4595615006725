import { Shimmer } from '@components/atoms'
import { MonitorContext } from '../../context'
import { Stack, Typography } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import { useContextSelector } from 'use-context-selector'

import {
  NavLink,
  TextLink,
  Container,
  ContentPaths,
  LeftSide,
} from './styles'


export default function Header() {

  const { data: monitor, isLoading } = useContextSelector(MonitorContext, (s) => s.monitor)

  return (
    <Container>
      <LeftSide>
        <ContentPaths>
          <NavLink to="/monitors">
            <TextLink variant="$font-body-base">Monitoramento</TextLink>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '10px', color: '#B2BBBF' }} />
          <Typography variant="$font-body-base" color="$color-text-primary">
            Painel
          </Typography>
        </ContentPaths>

        <Stack direction="row">
          <Shimmer isLoading={isLoading} width={240}>
            <Typography variant="$font-title-1" color="$color-text-primary">
              {monitor?.name || '--'}
            </Typography>
          </Shimmer>
        </Stack>
      </LeftSide>
    </Container>
  )
}
