import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { CardSummarized } from '@components/organisms/Cards'
import { useStakeholderPartyLoyalty } from '@services/nomos_api/resources/stakeholders/partyLoyalty'

export default function PartyLoyalty() {
  const id = useContextSelector(StakeholderDetailContext, (s) => s.id)

  const { data, isFetching } = useStakeholderPartyLoyalty(id || '')

  return (
    <CardSummarized
      title="Fidelidade partidaria"
      isLoading={isFetching}
      values={[
        {
          count: data?.loyalty ? `${data?.loyalty}` : 'N/A',
          label:
            'Porcentagem de vezes em que o stakeholder seguiu orientação de seu partido',
        },
      ]}
    />
  )
}
