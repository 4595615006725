/* eslint-disable react/destructuring-assignment */
import { DataGrid, DataGridProps } from '@mui/x-data-grid'

import { Grid, Sx } from './Table.styles'

export interface TableProps extends DataGridProps {
  height?: number
}

export default function Table(props: TableProps) {
  return (
    <Grid height={props.height}>
      <DataGrid
        sx={{ ...Sx, ...props.sx }}
        {...props}
        componentsProps={{
          pagination: {
            labelRowsPerPage: 'Linhas por página',
          },
        }}
      />
    </Grid>
  )
}
