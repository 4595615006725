import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { IrsDetailsContext } from './context'
import { useGetIrsDetails } from '@services/nomos_api/resources/irs/getById'

type SpeechDetailsControllerProps = {
  children: React.ReactNode
}

export function SpeechDetailsController({
  children,
}: SpeechDetailsControllerProps) {
  const { id } = useParams()
  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const { data: irs, isLoading } = useGetIrsDetails(id || '')

  const state = useMemo(
    () => ({
      irs,
      keyword,
      buckets,
      isLoading,
    }),
    [irs, isLoading, keyword, buckets]
  )

  return (
    <IrsDetailsContext.Provider value={state}>
      {children}
    </IrsDetailsContext.Provider>
  )
}
