import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;
`

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
`
