import { Wrapper } from './TextWrap.styles'

type TextWrapProps = {
  lines: number
  children: JSX.Element | string
}

function TextWrap({ lines, children }: TextWrapProps): JSX.Element {
  return <Wrapper lines={lines}>{children}</Wrapper>
}

export default TextWrap
