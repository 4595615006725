import Router from './routes'
import { NomosTheme } from './theme'
import { Snackbar } from '@components/atoms'
import { AuthProvider } from './contexts/Auth'
import { ModalProvider } from './contexts/Modal'
import { DrawerProvider } from './contexts/Drawer'
import { ThemeProvider } from '@mui/material/styles'
import { Drawer, Modal } from '@components/molecules'
import { SnackBarProvider } from './contexts/Snackbar'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from '@contexts/Cookies'

const queryClient = new QueryClient()

export default function App() {
  return (
    <ThemeProvider theme={NomosTheme}>
      <BrowserRouter>
        <CookiesProvider>
          <ModalProvider>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <SnackBarProvider>
                  <DrawerProvider>
                    <Router />
                    <Modal />
                    <Drawer />
                    <Snackbar />
                  </DrawerProvider>
                </SnackBarProvider>
              </QueryClientProvider>
            </AuthProvider>
          </ModalProvider>
        </CookiesProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
