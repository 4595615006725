import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-left: 8px;

  border-left: 4px solid #4070f4;
  cursor: pointer;
`

export const CardEventDate = styled.div`
  display: flex;
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`
