import { IrsDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function IrsDetailsPage() {
  return (
    <SpeechDetailsController>
      <IrsDetailsView />
    </SpeechDetailsController>
  )
}

export default IrsDetailsPage
