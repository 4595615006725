import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { TagManagerController } from './controller'
import { TagManagerProps } from './types'
import { TagManagerView } from './view'

export function TagManager(props: TagManagerProps): JSX.Element {
  useByPassSubscription()
  return (
    <TagManagerController {...props}>
      <TagManagerView />
    </TagManagerController>
  )
}
