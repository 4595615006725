/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '@components/atoms'
import { AttachFile } from '@mui/icons-material'
import { InputNoteProps } from './InputNote.types'
import { InputNoteContext } from './InputNote.context'
import useOnClickOutside from '@hooks/useOnClickOutsite'
import { useContextSelector } from 'use-context-selector'
import { Stack, Switch, Typography } from '@mui/material'
import {
  AttachmentButton,
  MentionInputWrapper,
  MentionStyled,
  MentionsInputStyle,
} from './InputNote.styles'
import { AutoUploadFile } from '../AutoUploadFile/AutoUploadFile'
import { NoteAllwedExtensions } from '@enums/NoteAllowedExtensions'

export function InputNoteView({
  bucket,
  originId,
  options,
}: InputNoteProps): JSX.Element {
  const inputFocusRef = useRef(null)
  const file = useContextSelector(InputNoteContext, (s) => s.file)
  const form = useContextSelector(InputNoteContext, (s) => s.form)
  const focused = useContextSelector(InputNoteContext, (s) => s.focused)
  const submiting = useContextSelector(InputNoteContext, (s) => s.submiting)
  const setFile = useContextSelector(InputNoteContext, (s) => s.setFile)
  const setFocused = useContextSelector(InputNoteContext, (s) => s.setFocused)

  const inputCleaner = useContextSelector(
    InputNoteContext,
    (s) => s.inputCleaner
  )

  const [noteValue, setNoteValue] = useState<string>(form.values.note)

  useEffect(() => {
    form.setFieldValue('note', noteValue)
  }, [noteValue])

  useEffect(() => {
    setNoteValue('')
  }, [inputCleaner])

  const handleOnClickOutside = useCallback(() => {
    if ((!form.values.note || form.values.note.length === 0) && !file) {
      setFocused(false)
    }
  }, [form])

  useOnClickOutside(inputFocusRef, handleOnClickOutside)

  return (
    <MentionInputWrapper ref={inputFocusRef} direction="column" spacing={2}>
      <MentionsInputStyle
        value={noteValue}
        allowSpaceInQuery
        allowSuggestionsAboveCursor
        className="mentions_input"
        onFocus={() => setFocused(true)}
        onChange={(e) => setNoteValue(e.target.value)}
        placeholder="Escreva uma nota ou faça uma menção utilizando “@”"
      >
        <MentionStyled
          trigger="@"
          data={options}
          displayTransform={(_, display) => `@${display}`}
        />
      </MentionsInputStyle>

      {file && (
        <AutoUploadFile
          bucket={bucket}
          originId={originId}
          file={file}
          onCancel={() => setFile(undefined)}
          onUploadAttachedName={(filename: string) => {
            form.setFieldValue('file', filename)
          }}
        />
      )}

      {focused && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <AttachmentButton htmlFor="input-file">
              <AttachFile sx={{ fontSize: 16 }} />
              <input
                id="input-file"
                type="file"
                accept={Object.values(NoteAllwedExtensions).join(', ')}
                onChange={(e) => setFile(e.target?.files?.[0])}
              />
            </AttachmentButton>
            <Typography variant="$font-body-3" color="$color-text-tertiary">
              Tamanho max de arquivo suportado 5MB.
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack direction="row" alignItems="center">
              <Switch
                size="small"
                checked={form?.values?.public || false}
                onChange={(e) => form.setFieldValue('public', e.target.checked)}
              />
              <Typography
                variant="$font-body-3"
                color="$color-text-secondary"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Tornar Publico
              </Typography>
            </Stack>
            <Button
              type="submit"
              size="small"
              text="Publicar"
              sx={{ px: 2 }}
              loading={submiting}
              onClick={() => form.submitForm()}
            />
          </Stack>
        </Stack>
      )}
    </MentionInputWrapper>
  )
}
