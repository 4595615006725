import { Wrapper, Container } from './styles'
import { HeaderComponent, ContentTextComponent } from './containers'

export function SpeechDetailsView() {
  return (
    <Wrapper>
      <Container>
        <HeaderComponent />
        <ContentTextComponent />
      </Container>
    </Wrapper>
  )
}
