import { useContextSelector } from 'use-context-selector'
import { OrgansPanel } from '../OrgansPanel'
import { EventsPanel } from '../EventsPanel'
import { GeneralPanel } from '../GeneralPanel'
import { MonitorContext } from '../../context'
import { SavedSearchPanel } from '../SavedSearchPanel'
import { PropositionsPanel } from '../PropositionsPanel'
import { StakeholdersPanel } from '../StakeholdersPanel'
import { SavedBucketsPanel } from '../SavedBucketsPanel'

export function TabPanels() {
  const selectedTab = useContextSelector(MonitorContext, (s) => s.selectedTab)

  switch (selectedTab) {
    case 'proposition':
      return <PropositionsPanel />
    case 'events':
      return <EventsPanel />
    case 'savedsearch':
      return <SavedSearchPanel />
    case 'stakeholders':
      return <StakeholdersPanel />
    case 'organs':
      return <OrgansPanel />
    case 'savedbuckets':
      return <SavedBucketsPanel />
    default:
      return <GeneralPanel />
  }
}
