import { CvmDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function CvmDetailsPage() {
  return (
    <SpeechDetailsController>
      <CvmDetailsView />
    </SpeechDetailsController>
  )
}

export default CvmDetailsPage
