import { useContextSelector } from 'use-context-selector'
import { NoteContentEmpty } from './note_content_empty'
import { NoteManagerDrawerContext } from '../context'
import { NoteContentList } from './note_content_list'
import { NoteContentLoading } from './note_content_loading'

export function NoteContentSelector(): JSX.Element {
  const loading = useContextSelector(NoteManagerDrawerContext, (s) => s.loading)
  const notes = useContextSelector(NoteManagerDrawerContext, (s) => s.notes)
  if (loading && notes.length === 0) return <NoteContentLoading />
  if (notes.length > 0) return <NoteContentList />
  return <NoteContentEmpty />
}
