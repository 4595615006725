import { Shimmer } from '@components/atoms'
import { Stack, Typography } from '@mui/material'
import { Container, RightSide, LeftSide } from './Header.styles'
import Breadcrumb from '@components/molecules/Breadcrumb/Breadcrumb'

type Path = {
  name: string
  location: string
}

export type HeaderProps = {
  title: string
  description?: string
  paths: Path[]
  isLoading: boolean
  actions?: React.ReactChild
}

const defaultProps = {
  description: null,
  actions: null,
}

function Header({
  paths,
  title,
  description,
  isLoading,
  actions,
}: HeaderProps) {
  return (
    <Container>
      <LeftSide>
        <Breadcrumb paths={paths} />

        <Stack direction="column" gap="9px">
          <Shimmer isLoading={isLoading} width={240}>
            <Typography variant="$font-title-2" color="$color-text-primary">
              {title}
            </Typography>

            {description && (
              <Typography variant="$font-title-4" color="$color-text-secondary">
                {description}
              </Typography>
            )}
          </Shimmer>
        </Stack>
      </LeftSide>

      {actions && <RightSide>{actions}</RightSide>}
    </Container>
  )
}

Header.defaultProps = defaultProps

export default Header
