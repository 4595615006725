/* eslint-disable no-param-reassign */

import { InputMultiSelectChip } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { InputPickMonitorContext } from './InputPickMonitor.context'
import { InputPickMonitorProps } from './InputPickMonitor.types'

export function InputPickMonitorView({
  placeholder,
}: InputPickMonitorProps): JSX.Element {
  const isLoading = useContextSelector(
    InputPickMonitorContext,
    (s) => s.isLoading
  )

  const selectedMonitors = useContextSelector(
    InputPickMonitorContext,
    (s) => s.selectedMonitors
  )

  const unselectedMonitors = useContextSelector(
    InputPickMonitorContext,
    (s) => s.unselectedMonitors
  )

  const textSearchMonitor = useContextSelector(
    InputPickMonitorContext,
    (s) => s.textSearchMonitor
  )

  const setTextSearchMonitor = useContextSelector(
    InputPickMonitorContext,
    (s) => s.setTextSearchMonitor
  )

  const handleChangeOptions = useContextSelector(
    InputPickMonitorContext,
    (s) => s.handleChangeOptions
  )

  const fetchMonitors = useContextSelector(
    InputPickMonitorContext,
    (s) => s.fetchMonitors
  )

  return (
    <InputMultiSelectChip
      label={placeholder || ''}
      id="monitors-input"
      options={unselectedMonitors || []}
      disabled={false}
      loading={isLoading}
      loadingLabel="Buscando Monitores..."
      value={selectedMonitors}
      enableNewValueLabel="Novo Monitor"
      textSearchValue={textSearchMonitor}
      onChangeOptions={handleChangeOptions}
      onChangeTextSearch={setTextSearchMonitor}
      onFocus={() => fetchMonitors()}
      groupAllSelectedOptions="Todos os Painéis"
    />
  )
}
