import { Alert, Box } from '@mui/material'
import { SaveSearchContext } from '../../context'
import { useContextSelector } from 'use-context-selector'

export function FormWarning() {
  const form = useContextSelector(SaveSearchContext, (s) => s.form)
  const errors = form?.errors || {}

  if (Object.keys(errors).length > 0) {
    return (
      <Box sx={{ mb: 3 }} data-testid="error-alert">
        <Alert severity="error">
          Revise os campos do formulário para salvar a pesquisa
        </Alert>
      </Box>
    )
  }

  return <div />
}
