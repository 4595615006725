import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { NoteEntity } from '@services/nomos_api/entities/note'
import { PagiantedType } from '@services/nomos_api/entities/paginated'

export type FindNotesProps = Pick<NoteEntity, 'bucket' | 'originId'> & {
  page?: number
  limit?: number
  cursor?: string
  only?: 'file' | 'note'
}

export const findNotesApi = async (
  props: FindNotesProps
): Promise<PagiantedType<NoteEntity> | NoteEntity[]> => {
  const query = ['sort=desc']
  if (props.only) query.push(`only=${props.only}`)
  if (props.limit) query.push(`limit=${props.limit}`)
  query.push(`cursor=${props.cursor || 'nill'}`)
  const { data } = await HTTPClient.get(
    `/notes/${props.bucket}/${props.originId}?${query.join('&')}`
  )
  return data as PagiantedType<NoteEntity> | NoteEntity[]
}

export const useFindNotes = (props: FindNotesProps) =>
  useQuery(['find-notes'], () => findNotesApi(props))
