/* eslint-disable no-unused-vars */
import PropositionsPanelController from './controller'
import { PropositionsPanelView } from './view'

export function PropositionsPanel() {
  return (
    <PropositionsPanelController>
      <PropositionsPanelView />
    </PropositionsPanelController>
  )
}
