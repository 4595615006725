import { Card } from '@components/atoms'
import { Box, styled } from '@mui/material'

export const Container = styled(Card)`
  display: flex;
  padding: 24px;
  justify-content: space-between;
`

export const CardBaseline = styled(Box)`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const CardActions = styled(Box)`
  height: 100%;
  width: auto;
  gap: 12px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
`
