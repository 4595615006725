/* eslint-disable no-inline-styles/no-inline-styles */
import { Drawer } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { DrawerBackdrop } from './Drawer.styles'
import { useMemo } from 'react'

function DrawerComponent() {
  // const { component } = useDrawer()
  const { stack } = useDrawer()

  const currentOptions = useMemo(() => {
    if (stack.length <= 0) return undefined
    return stack[stack.length - 1].options
  }, [stack])

  // <!-- mount display stack component to be rendered on modal -->
  const component = useMemo(() => {
    // <!-- return undefined component to prevent render modal -->
    // <!-- if does not exists any component on stack -->
    if (stack.length <= 0) return undefined
    // <!-- return stack components, but display only the last one -->
    // * must to envolve the components with a <div>
    // - to prevent component from lost state and
    // - reset the stack component render
    // ****************************************** //
    // this is a special case to use pure html    //
    // and cannot be used as styled component     //
    // because it becomes a react component and   //
    // lost its state when drawer stack change    //
    // --- WE DONT WANT TO LOST STACK STATE! ---  //
    // ****************************************** //
    return (
      <>
        {stack.map((stackItem, index) => {
          const key = `drawer-stack-${index}`
          if (index < stack.length - 1) {
            return (
              <div style={{ display: 'none' }} key={key}>
                {stackItem.component}
              </div>
            )
          }
          return (
            <div
              key={key}
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: !currentOptions?.lockscroll ? 'scroll' : 'hidden',
              }}
            >
              {stackItem.component}
            </div>
          )
        })}
      </>
    )
  }, [stack])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!component) return <></>

  return (
    <DrawerBackdrop open>
      <Drawer variant="permanent" open anchor="right">
        {component}
      </Drawer>
    </DrawerBackdrop>
  )
}

export default DrawerComponent
