/* eslint-disable no-restricted-syntax */
import { useModal } from '@contexts/Modal'
import { HomeContext } from '../../context'
import { InputAdvancedSearch } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { AdvancedSearchModal } from '@components/organisms'

function InputKeyword() {
  const { openModal } = useModal()

  const modes = useContextSelector(HomeContext, (s) => s.modes)
  const search = useContextSelector(HomeContext, (s) => s.search)
  const buckets = useContextSelector(HomeContext, (s) => s.buckets)
  const setModes = useContextSelector(HomeContext, (s) => s.setModes)
  const setSearch = useContextSelector(HomeContext, (s) => s.setSearch)
  const setBuckets = useContextSelector(HomeContext, (s) => s.setBuckets)

  return (
    <InputAdvancedSearch
      value={search.keywords}
      placeholder="Digite a palavra que deseja buscar"
      onChangeSearch={setSearch}
      helper="Clique ENTER para inserir mais de uma palavra chave"
      action={{
        label: 'Pesquisa Avançada',
        onClick: () => {
          openModal(
            <AdvancedSearchModal
              initialForm={{
                modes,
                buckets,
                keywords: search.keywords,
              }}
              onSaveOptions={(advanced) => {
                setModes(advanced.modes)
                setBuckets(advanced.buckets)
                setSearch({ keywords: advanced.keywords })
              }}
            />,
            { scroll: 'body' }
          )
        },
      }}
    />
  )
}

export default InputKeyword
