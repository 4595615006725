import { Stack, Typography } from '@mui/material'
import { SidePage } from '@components/templates'
import Calendar from './components/Calendar/Calendar'
import SideFilter from './components/SideFilter/SideFilter'
import { isMobile } from 'react-device-detect'

export default function ScheduleView() {
  return (
    <SidePage
      id="schedule-page"
      sideComponent={<SideFilter />}
      leftVisible={!isMobile}
    >
      <>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Minha Agenda
          </Typography>
          <Typography variant="$font-body-1" color="$color-text-primary">
            Aqui você acompanha todos os seus eventos monitorados
          </Typography>
        </Stack>
        <Calendar />
      </>
    </SidePage>
  )
}
