import { Bucket, BucketsEnum, FilterOptionsProps } from './type'
import { OrganEntity } from '@services/nomos_api/entities/organ'
import { getOrgansAPI } from '@services/nomos_api/resources/organs/organs'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { StakeholderEntity } from '@services/nomos_api/entities/stakeholder'
import { searchOptionsAPI } from '@services/nomos_api/resources/search/options'
import { getAllStakeholdersPagination } from '@services/nomos_api/resources/stakeholders/stakeholders'
import { fetchCasaOptions } from '@services/dados_abertos/resources/casa_options'

const BucketEvents: Bucket = {
  key: BucketsEnum.events,
  name: 'Eventos',
  mode: 'monitor',
  sorts: [
    {
      attr: 'newest',
      label: 'Iniciados recentemente',
    },
    {
      attr: 'older',
      label: 'Mais antigos',
    },
  ],
  periods: [
    {
      range: true,
      attr: 'date',
      label: 'Selecione o período desejado',
    },
  ],
  filters: [
    {
      attr: 'resources',
      label: 'Casa',
      enabled_input: false,
      enabled_online_filter: false,
      options: fetchCasaOptions,
    },
    {
      attr: 'situations',
      label: 'Status',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        return searchOptionsAPI('events', 'situation', [], {
          page,
          limit,
          text,
        })
      },
    },
    {
      attr: 'types',
      label: 'Tipo de Evento',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        return searchOptionsAPI('events', 'types', [], {
          page,
          limit,
          text,
        })
      },
    },
    {
      attr: 'stakeholders',
      label: 'Stakeholders',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const stakeholders = await getAllStakeholdersPagination({
          page,
          limit,
          filters: { name: text },
        })
        return {
          pagination: stakeholders.pagination,
          results: stakeholders.results.map(
            (stakeholder: StakeholderEntity) => ({
              key: stakeholder.id,
              value: `${capitalizeAllWords(stakeholder.name)} - ${
                stakeholder.title
              }`,
            })
          ),
        }
      },
    },
    {
      attr: 'organs',
      label: 'Órgãos',
      enabled_input: true,
      enabled_pagination: true,
      enabled_online_filter: true,
      options: async ({ text, page, limit }: FilterOptionsProps) => {
        const organs = await getOrgansAPI({ page, limit, filters: { q: text } })
        return {
          pagination: organs.pagination,
          results: organs.results.map((organ: OrganEntity) => ({
            key: organ.id,
            value: capitalizeAllWords(organ.name),
          })),
        }
      },
    },
  ],
}

export default BucketEvents
