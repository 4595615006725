import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export type GetAllSavedEventsProps = {
  q?: string
  organs?: string[]
  monitors?: string[]
  propositions?: string[]
  fromDate?: string
  toDate?: string
}

export const getAllSavedEvents = async (filters: GetAllSavedEventsProps) => {
  const params = Object.entries(filters).reduce((accumulator, [key, value]) => {
    const stringValue = value?.toString()
    return stringValue ? `${accumulator}&${key}=${stringValue}` : accumulator
  }, '')

  const { data } = await HTTPClient.get(`saved_events?${params}`)
  return data || []
}

export const useGetAllSavedEvents = (filters: GetAllSavedEventsProps) =>
  useQuery(
    ['get-all-saved-events', filters],
    () => getAllSavedEvents(filters),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
