import { ArrowRight } from '@mui/icons-material'
import { Box, Chip, MenuItem, styled } from '@mui/material'

export const InlineTagsContainer = styled(MenuItem)`
  gap: 4px;
  width: 100%;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
`

export const InlineTagsContent = styled(Box)`
  gap: 4px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const InlineTagsSpread = styled(Box)`
  display: flex;
  overflow: hidden;
  padding-right: 8px;
`

export const CountChip = styled(Chip)`
  height: 20px;
  font-size: 10px;
  font-weight: 600;
  background-color: ${(props) =>
    props.theme.palette['$color-background-alternative']};
  & > span {
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const ArrowRightSmall = styled(ArrowRight)`
  font-size: 16px;
  color: ${(props) => props.theme.palette['$color-text-secondary']};
`
