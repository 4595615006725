import { FixedSizeList } from 'react-window'
import { styled, styled as styledMaterial } from '@mui/material/styles'
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import { Chip } from '@components/atoms'
import { ExtendedPaletteColors } from '@theme/colors'

// To choose modal center, change ModalContainer left to 50%

export const ModalContainer = styledMaterial(Box)(() => ({
  width: 500,
  backgroundColor: '#fff',
  boxShadow:
    ' 0px 2px 12px 2px rgba(0, 0, 0, 0.04), 0px 8px 16px 2px rgba(11, 31, 77, 0.06) ',
  borderRadius: '4px',
  '@media (max-height: 768px)': {
    top: '10%',
  },
}))

export const ModalToolbar = styledMaterial(Box)(() => ({
  height: '37px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: `#F9FAFC`,
  padding: '0 5px',
}))

export const ModalContent = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 0 10px 0',
}))

export const Title = styledMaterial(Typography)(() => ({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '34px',
  color: '#0A2156',
  marginBottom: '-15px',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
}))

export const Separator = styledMaterial(Divider)(() => ({
  height: '1px',
  margin: '0 20px',
}))

export const TextLabel = styledMaterial(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#3c4c70',
}))

export const TextDescription = styledMaterial(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#3c4c70',
}))

export const TextChip = styledMaterial(Chip)(() => ({
  height: '24px',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '21px',
  color: '#4070F4',
  backgroundColor: '#ffffff',
}))

export const TextParagraph = styledMaterial(Typography)(() => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  color: '#6D7994',
}))

export const ContainerRow = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 20px',
}))

export const ContentRow = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '21px',
}))

export const ContentAccordion = styledMaterial(Box)<{ expanded: boolean }>(
  ({ expanded }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: expanded ? '-25px 0 0 0' : '-10px 0',
  })
)

export const ModalAccordion = styledMaterial(Accordion)(() => ({
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: 0,
  boxShadow: 'none',
  margin: '0px !important',
}))

export const ModalAccordionSummary = styledMaterial(AccordionSummary)<{
  number: boolean
}>(({ number }) => ({
  maxWidth: number ? '250px' : '210px',
  display: 'flex',
  padding: '0 20px',
}))

export const FilterListVirtualized = styledMaterial(FixedSizeList)(() => ({
  padding: '5px 20px',
  overflowX: 'hidden',
  overflowY: 'scroll',
  /* width */
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  /* Track */
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#4a8eff20',
  },
  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#4a8eff50',
  },
  a: {
    textAlign: 'start !important',
  },
}))

export const FilterListVirtualizedItem = styledMaterial('div')<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customStyle: any
}>(({ customStyle }) => ({
  ...customStyle,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  svg: {
    fontSize: '16px',
    color: '#556282',
    cursor: 'pointer',
  },
  a: {
    color: '#4070F4',
  },
}))

export const ModalAccordionDetails = styledMaterial(AccordionDetails)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px;
  margin-top: -15px;
  background-color: #f9fafc;
  overflow-y: none;
  overflow-x: none;
`

export const ContentColumn = styledMaterial(Box)(() => ({
  maxHeight: '130px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '6px',
  padding: '4px 20px',
}))

export const ContentIconText = styledMaterial(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

export const ModalFooter = styledMaterial(Box)(() => ({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid #E7E9ED',
  padding: '0 5px 0 20px',
}))

export const TextFooter = styledMaterial(Typography)(() => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#0A2156',
  textDecorationLine: 'underline',
  cursor: 'pointer',
}))

export const HighlightedSchedule = styled(Box)`
  padding-left: 8px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: ${ExtendedPaletteColors['$color-extended-yellow5']};
`
