import { Header, Content } from './containers'
import { Wrapper, WrapperContent } from './styles'

export function EventDetailsView() {
  return (
    <Wrapper>
      <WrapperContent direction="column" spacing={3}>
        <Header />
        <Content />
      </WrapperContent>
    </Wrapper>
  )
}
