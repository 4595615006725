import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { UserAuth } from '@services/nomos_api/entities/user'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'

export type ResetPasswordProps = {
  token: string
  password: string
  password_confirmation: string
}

export const resetPasswordAPI = async (body: ResetPasswordProps) => {
  const { data } = await HTTPClient.post('/reset-password', body)
  return data
}

export const useResetPassword = () =>
  useMutation<UserAuth, AxiosErrorResponse, ResetPasswordProps>(
    ['reset-password'],
    resetPasswordAPI
  )
