/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'
import libphonenumber from 'google-libphonenumber'
import sectorOptions from './sectors.json'
import positionOptions from './positions.json'
import employeeOptions from './employees.json'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const name = yup
  .string()
  .min(2, 'Deve ter no mínimo 2 caracteres')
  .max(64, 'Deve ter no máximo 64 caracteres')
  .required('Nome obrigatório')

export const surname = yup
  .string()
  .min(2, 'Deve ter no mínimo 2 caracteres')
  .max(64, 'Deve ter no máximo 64 caracteres')
  .required('Sobrenome obrigatório')

export const nameSurname = yup
  .string()
  .required('Nome e Sobrenome obrigatórios')
  .test('nome-sobrenome', 'Digite seu nome e sobrenome', (value) => {
    if (!value) return false
    return value.trim().split(' ').length >= 2
  })

export const email = yup
  .string()
  .email('Endereço de email inválido')
  .required('Email obrigatório')

export const password = yup
  .string()
  .min(8, 'A senha deve ter no mínimo 8 caracteres')
  .matches(/^(?=.*\d)/, 'A senha deve ter no mínimo 1 número')
  .matches(/^(?=.*[A-Z])/, 'A senha deve ter no mínimo 1 letra maiúscula')
  .matches(/^(?=.*[a-z])/, 'A senha deve ter no mínimo 1 letra minúscula')
  .matches(
    /^(?=.*[!@#$%^&*(),.?":{}|<>])/,
    'A senha deve ter no mínimo 1 caractere especial'
  )
  .required('Senha obrigatória')

export const passwordConfirmation = yup
  .string()
  .min(8, 'Deve ter no mínimo 8 caracteres')
  .oneOf([yup.ref('password'), null], 'As senhas devem corresponder')
  .required('Confirmar senha obrigatório')

export const phone = yup
  .string()
  .required('Telefone obrigatório')
  .test('e164', 'Número incompatível com o país selecionado', (value) => {
    try {
      return phoneUtil.isPossibleNumber(phoneUtil.parse(value))
    } catch (e) {
      return false
    }
  })

export const officePosition = yup
  .mixed()
  .oneOf(positionOptions)
  .required('Selecione um cargo válido')

export const officeEmployees = yup
  .mixed()
  .oneOf(employeeOptions)
  .required('Selecione uma quantidade válida')

export const officeSectors = yup
  .mixed()
  .oneOf(Object.keys(sectorOptions))
  .required('Selecione um segmento válido')

export const termsOfUse = yup
  .boolean()
  .isTrue()
  .required('O termo de uso e a politica de privacidade devem ser aceitos')
