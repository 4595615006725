import { useState } from 'react'
import DataFound from './containers/DataFound'
import { TabSelector } from '@components/organisms'
import { SavedSearches } from './containers/SavedSearches'

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Container, Content } from './styles'

const tabs = [
  {
    value: 'savedsearch',
    label: 'Pesquisas Salvas',
  },
  {
    value: 'dataFound',
    label: 'Dados Encontrados',
  },
]

export type SavedSearchesListProps = {
  monitorId?: string
}

function SavedSearchesList({ monitorId }: SavedSearchesListProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const initialTab = searchParams.get('tab')
  const [tab, setTab] = useState(initialTab || 'savedsearch')

  const handleChangeTab = (tab: string) => {
    setTab(tab)

    navigate(
      {
        search: `${createSearchParams({ tab })}`,
      },
      {
        replace: true,
      }
    )
  }

  return (
    <Container>
      <TabSelector tabs={tabs} initialTab={tab} onChangeTab={handleChangeTab} />

      <Content>
        {tab === 'savedsearch' && <SavedSearches monitorId={monitorId} />}
        {tab === 'dataFound' && <DataFound monitorId={monitorId} />}
      </Content>
    </Container>
  )
}

SavedSearchesList.defaultProps = {
  monitorId: null,
}

export { SavedSearchesList }
