import { Stack, Switch, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { PropositionStatusNotificationContext } from '../../context'
import { useEffect, useState } from 'react'

type props = {
  onToggle: (e: boolean) => void
}

export function FormNotification({ onToggle }: props) {
  const checkNotification = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.enabledNotification
  )
  const setCheckNotification = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.setEnabledNotification
  )
  const [localCheckNotification, setLocalCheckNotification] =
    useState(checkNotification)

  useEffect(() => {
    setLocalCheckNotification(checkNotification)
  }, [checkNotification])

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="$font-body-base" color="$color-text-primary">
        Habilitar Notificações
      </Typography>
      <Switch
        value="notification"
        name="notification"
        checked={localCheckNotification}
        // onBlur={formikPainel.handleBlur}
        onChange={(e) => {
          setLocalCheckNotification(e.target.checked)
          setCheckNotification(e.target.checked)
          onToggle(e.target.checked)
        }}
        sx={{ ml: '-12px' }}
      />
    </Stack>
  )
}
