import { createContext } from 'react'
import { GetAllSavedSearchesResult } from '@services/nomos_api/resources/saved_searches/getAll'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'

export type SavedSearchesListContextType = {
  isLoading: boolean
  savedSearches?: GetAllSavedSearchesResult
  refetch: () => void
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleExcludeSearch: (savedSearch: SavedSearchEntity) => void
}

export const SavedSearchesListContext =
  createContext<SavedSearchesListContextType>(
    {} as SavedSearchesListContextType
  )
