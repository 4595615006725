import font from '@theme/font'
import colors from '@theme/colors'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default styled(Link)`
  font-weight: ${font.weight.regular};
  font-size: ${font.size.base};
  line-height: ${font.height.fontelinham};
  color: ${colors['$color-action-link']};
`
