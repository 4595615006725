import { Wrapper } from './Pagination.styles'
import { Pagination as PaginationEntity } from '@services/nomos_api/entities'

import {
  Stack,
  Pagination as MUIPagination,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'

export type PaginationProps = {
  pagination?: PaginationEntity
  handleUpdatePage: (page: number) => void
  handleUpdateLimit: (limit: number) => void
}

function Pagination({
  pagination,
  handleUpdatePage,
  handleUpdateLimit,
}: PaginationProps) {
  if (!pagination?.pages) return null

  return (
    <Wrapper data-cy="pagination">
      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <MUIPagination
          color="primary"
          shape="rounded"
          count={pagination?.pages}
          page={pagination.page}
          onChange={(_, page) => handleUpdatePage(page)}
        />
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Itens por página:
          </Typography>
          <Select
            size="tiny"
            value={pagination?.limit}
            onChange={(e) =>
              handleUpdateLimit(parseInt(e.target.value.toString(), 10))
            }
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </Wrapper>
  )
}

Pagination.defaultProps = {
  pagination: null,
}

export default Pagination
