import { theme } from '@theme/index'
import { Input } from '@components/atoms'
import { useDrawer } from '@contexts/Drawer'
import { TagManagerListItems } from '../TagManagerListItems'
import { Divider, IconButton, Typography } from '@mui/material'
import { TagManagerListActions } from '../TagManagerListActions'
import { TagManagerListSelecteds } from '../TagManagerListSelecteds'
import {
  Content,
  Header,
  IconBack,
  IconClose,
  Wrapper,
} from '@containers/TagManager/styles'
import { useContextSelector } from 'use-context-selector'
import { TagManagerContext } from '@containers/TagManager/context'

export function TagManagerList(): JSX.Element {
  const { closeDrawer } = useDrawer()
  const compact = useContextSelector(TagManagerContext, (s) => s.compact)
  const searchName = useContextSelector(TagManagerContext, (s) => s.searchName)
  const setSearchName = useContextSelector(
    TagManagerContext,
    (s) => s.setSearchName
  )
  const handleClose = useContextSelector(
    TagManagerContext,
    (s) => s.handleClose
  )
  return (
    <Wrapper data-cy="tag-manager-listing-area" compact={String(compact)}>
      <Header>
        {!compact && (
          <IconButton
            onClick={handleClose || closeDrawer}
            size={compact ? 'small' : 'medium'}
          >
            <IconBack />
          </IconButton>
        )}
        <Typography
          variant={compact ? '$font-body-2' : '$font-title-3'}
          color="$color-text-primary"
        >
          Tags
        </Typography>
        <IconButton
          onClick={handleClose || closeDrawer}
          size={compact ? 'small' : 'medium'}
        >
          <IconClose />
        </IconButton>
      </Header>
      <Content>
        <TagManagerListSelecteds />
        <Divider sx={{ borderColor: theme.palette['$color-border-primary'] }} />
        <Input
          type="search"
          label="Buscar Tags"
          value={searchName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchName(e.target.value)
          }
          size={compact ? 'tiny' : 'small'}
        />
        <TagManagerListItems />
        <TagManagerListActions />
      </Content>
    </Wrapper>
  )
}
