import { UserDetailView } from './view'
import { UserDetailController } from './controller'

export default function Users() {
  return (
    <UserDetailController>
      <UserDetailView />
    </UserDetailController>
  )
}
