import { useEffect } from 'react'

type Callback = () => void

function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: Callback
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, callback])
}

export default useOnClickOutside
