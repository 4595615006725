import { NotificationView } from './view'
import { NotificationController } from './controller'

export default function Notification() {
  return (
    <NotificationController>
      <NotificationView />
    </NotificationController>
  )
}
