import { useContextSelector } from 'use-context-selector'
import { SideFilterSelectContext } from './SideFilterSelectContext'
import { SideFilterSelectOptionRowType } from './SideFilterSelectTypes'
import { Alert, ListItem } from '@mui/material'
import { FilterAccordionLabel } from './SideFilterSelect.styles'
import { Input } from '@components/atoms'

type SideFilterSelectOptionProps = {
  data: unknown
  index: number
  style: React.CSSProperties | undefined
}

export function SideFilterSelectOption({
  data,
  index,
  style,
}: SideFilterSelectOptionProps): JSX.Element {
  const row = (data as SideFilterSelectOptionRowType[])[index]

  // *********************************

  // <!-- field name -->
  const fieldName = useContextSelector(
    SideFilterSelectContext,
    (s) => s.fieldName
  )

  // <!-- field name -->
  const handleAddSelectOption = useContextSelector(
    SideFilterSelectContext,
    (s) => s.handleAddSelectOption
  )

  if (row.kind === 'label') {
    return (
      <ListItem sx={{ p: 0 }} style={style}>
        <FilterAccordionLabel>{row.value}</FilterAccordionLabel>
      </ListItem>
    )
  }

  if (row.kind === 'alert') {
    return (
      <ListItem sx={{ p: 0, height: 40, paddingTop: 0 }} style={style}>
        <Alert severity="warning" sx={{ width: '100%', py: 0, px: 2 }}>
          {row.value}
        </Alert>
      </ListItem>
    )
  }

  if (row.kind === 'option') {
    return (
      <ListItem sx={{ p: 0 }} style={style}>
        <Input
          type="checkbox"
          id={`checkbox-${fieldName}-${index}`}
          value={row.option.key}
          key={row.option.key}
          label={row.option.value}
          checked={row.option.selected}
          onChange={() => handleAddSelectOption(row.option.key)}
          sx={{ width: '100%' }}
        />
      </ListItem>
    )
  }

  return <div>unknown</div>
}
