import styled from 'styled-components'

export const HeaderContent = styled.div`
  display: flex;
  padding: 42px 0;
  justify-content: space-between;

  color: #b2bbbf;

  svg {
    color: #b2bbbf;
  }

  label {
    color: #b2bbbf;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 29px;
  }
`
