import { Stack, styled } from '@mui/material'

export const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 656px;
`

export const Content = styled(Stack)`
  display: flex;
  
  margin-bottom: 45px;

  overflow-y: auto;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 5px;
  },

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  },

  &::-webkit-scrollbar-thumb {
    background: #4a8eff20;
  },

  &::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  },
`
