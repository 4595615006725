/* eslint-disable no-unused-vars */
import ScheduleView from './view'
import ScheduleController from './controller'

export default function Schedule() {
  return (
    <ScheduleController>
      <ScheduleView />
    </ScheduleController>
  )
}
