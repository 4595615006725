import { motion } from 'framer-motion'
import { Chip, CircularProgress, Stack, Typography } from '@mui/material'

export interface LoadingChipProps {
  label: string
  visible: boolean
}

export default function LoadingFloat({
  label,
  visible,
}: LoadingChipProps): JSX.Element | null {
  if (!visible) return null
  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0.8,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 12,
        marginBottom: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        marginBottom: 12,
      }}
      transition={{ duration: 0.3 }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Chip
          label={
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress
                sx={{ color: 'white' }}
                thickness={6}
                size={12}
              />
              <Typography variant="$font-body-2">{label}</Typography>
            </Stack>
          }
          variant="filled"
          color="primary"
          size="medium"
        />
      </Stack>
    </motion.div>
  )
}
