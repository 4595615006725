import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { Dashboard } from '@services/nomos_api/entities/dashboard'

export const getSummaryAPI = async () => {
  const { data } = await HTTPClient.get('dashboard/summary')
  return data as Dashboard
}

export const useGetSummary = () =>
  useQuery(['get-summary'], () => getSummaryAPI(), {
    refetchOnWindowFocus: false,
  })
