import { TextLink } from '@components/atoms'
import { CardMedia, CircularProgress, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { FeedbackCard, Media, Content } from './Feedback.styles'

export type FeedbackProps = {
  icon: string
  title: string
  subtitle?: string
  text: string
  action?: {
    path: string
    text: string
  }
  isLoading?: boolean
  sx?: {
    title?: SxProps
    subtitle?: SxProps
    text?: SxProps
  }
}

function Feedback({
  icon,
  title,
  text,
  subtitle,
  action,
  isLoading,
  sx = {},
}: FeedbackProps) {
  return (
    <FeedbackCard>
      <Media>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <CardMedia
            image={icon}
            title="Icon Circle Success"
            sx={{ height: '100%', width: '100%', alignSelf: 'center' }}
          />
        )}
      </Media>
      <Content>
        <Typography
          data-testid="title"
          variant="$font-body-base"
          sx={{
            color: '#0A2156',
            fontWeight: 700,
            fontSize: '40px',
            lineHeight: '48px',
            ...(sx.title || {}),
          }}
        >
          {title}
        </Typography>

        <Typography
          data-testid="message"
          variant="subtitle1"
          sx={{
            color: '#858FA6',
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '20px',
            ...(sx.subtitle || {}),
          }}
        >
          {subtitle}
        </Typography>

        <Typography
          data-testid="message"
          variant="subtitle1"
          sx={{
            color: '#858FA6',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            whiteSpace: 'pre-line',
            ...(sx.text || {}),
          }}
        >
          {text}
        </Typography>

        {action && <TextLink href={action.path} text={action.text} />}
      </Content>
    </FeedbackCard>
  )
}

Feedback.defaultProps = {
  isLoading: false,
}

export default Feedback
