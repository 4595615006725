import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { FormSignAuthType } from '@services/nomos_api/types/form_signauth_type'
import { AuthResponseSignAuthType } from '@services/nomos_api/types/auth_response_signauth_type'

export const authSignAuthAPI = async (body: FormSignAuthType) => {
  const { data } = await HTTPClient.post<AuthResponseSignAuthType>(
    '/signauth',
    body
  )
  return data
}

export const useAuthSignAuth = () => {
  return useMutation<
    AuthResponseSignAuthType,
    AxiosErrorResponse,
    FormSignAuthType
  >(['auth-signauth'], authSignAuthAPI)
}
