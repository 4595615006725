import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { HighlightEntity } from '@services/nomos_api/entities/highlight'

export type DouEntity = {
  content: string
  createdAt: string
  date: string
  file: string
  hierarchy: string
  id: string
  ingestionOrigin: string
  location: string
  section: string
  signatures: string[]
  title: string
  updatedAt: string
  savedBuckets: SavedBucketEntity[]
  highlights?: HighlightEntity[]
}

export const getDouByIdAPI = async (id: string): Promise<DouEntity> => {
  const { data } = await HTTPClient.get(`dou/${id}`)
  return data as DouEntity
}

export const useGetDouDetails = (id: string) =>
  useQuery(['get-dou-details'], () => getDouByIdAPI(id))
