import { SocialDetailsView } from './view'
import { SpeechDetailsController } from './controller'

function SocialDetailsPage() {
  return (
    <SpeechDetailsController>
      <SocialDetailsView />
    </SpeechDetailsController>
  )
}

export default SocialDetailsPage
