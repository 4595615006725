import styled from 'styled-components'
import { Card } from '@components/atoms'

export const Content = styled.div`
  width: 488px;
  height: auto;
  display: flex;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(230, 232, 240, 0.8);
  background-color: #fff;
  border-color: #e6e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`

export const ResetPasswordCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  gap: 24px;
  width: 100%;
`

export const RequirementsContainer = styled.div`
  margin-top: -7px;
  text-align: left;
`
