/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeedbackMessage } from '@services/nomos_api/entities/axios'
import { createContext } from 'react'

export type CompanyInfoModalType = {
  formik: any
  submitting: boolean
  review: FeedbackMessage | undefined
}

export const CompanyInfoModalContext = createContext<CompanyInfoModalType>(
  {} as CompanyInfoModalType
)
