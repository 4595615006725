import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getSession = async (pushToken?: string | null) => {
  const args = []
  if (pushToken) args.push(`push_token=${pushToken}`)
  const { data } = await HTTPClient.get(`session?${args.join('&')}`)
  return data
}

export const useGetSession = (pushToken?: string | null) =>
  useQuery(['get-session'], () => getSession(pushToken))
