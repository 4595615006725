import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { Search, ErrorOutlineRounded } from '@mui/icons-material'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 2px 12px rgba(0, 0, 0, 0.08), 0px 2px 2px 2px rgba(11, 31, 77, 0.02)',
  borderRadius: 6,
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
}))

export const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styled(Typography)(() => ({
  paddingTop: 10,
  padingRight: 0,
  paddingBottom: 10,
  paddingLeft: 20,
  fontSize: 14,
  textAlign: 'start',
}))

export const TextTiny = styled((props) => (
  <Typography {...props} color="$color-text-primary" variant="$font-body-3" />
))``

export const TextTinyHelper = styled(TextTiny)`
  float: left;
  padding-left: 4px;
  line-height: 1.7em;
`

export const HelperContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '10px',
  paddingRight: '0px',
  paddingBottom: '10px',
  paddingLeft: '20px',
}))

export const HelperIcon = styled(ErrorOutlineRounded)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  marginRight: 10,
}))

export const CountResultContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CountResultIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.muted.main,
  marginRight: '6px',
}))
