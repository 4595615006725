import { InputNoteController } from './InputNote.controller'
import { InputNoteProps } from './InputNote.types'
import { InputNoteView } from './InputNote.view'

export function InputNote(props: InputNoteProps) {
  return (
    <InputNoteController {...props}>
      <InputNoteView {...props} />
    </InputNoteController>
  )
}
