import _ from 'lodash'
import font from './font'
import { TextField } from './TextField/TextField'
import colors, { ExtendedPaletteColors } from './colors'
import { PaletteColor, PaletteOptions, createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    '$font-title-display': React.CSSProperties
    '$font-title-1': React.CSSProperties
    '$font-title-2': React.CSSProperties
    '$font-title-3': React.CSSProperties
    '$font-title-4': React.CSSProperties
    '$font-title-5': React.CSSProperties
    '$font-body-base': React.CSSProperties
    '$font-body-1': React.CSSProperties
    '$font-body-2': React.CSSProperties
    '$font-body-3': React.CSSProperties
    '$font-body-4': React.CSSProperties
    '$font-body-5': React.CSSProperties
    '$font-text-link': React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    '$font-title-display': React.CSSProperties
    '$font-title-1': React.CSSProperties
    '$font-title-2': React.CSSProperties
    '$font-title-3': React.CSSProperties
    '$font-title-4': React.CSSProperties
    '$font-title-5': React.CSSProperties
    '$font-body-base': React.CSSProperties
    '$font-body-1': React.CSSProperties
    '$font-body-2': React.CSSProperties
    '$font-body-3': React.CSSProperties
    '$font-body-4': React.CSSProperties
    '$font-body-5': React.CSSProperties
    '$font-text-link': React.CSSProperties
  }

  type CustomStringColors = {
    [K in keyof typeof colors]: string
  }

  type CustomPaletteColors = {
    [K in keyof typeof ExtendedPaletteColors]: PaletteColor
  }

  interface Palette extends CustomPaletteColors, CustomStringColors {
    muted: Palette['primary']
    brand: typeof colors.brand
  }

  interface PaletteOptions extends CustomPaletteColors, CustomStringColors {
    muted: PaletteOptions['primary']
    brand: typeof colors.brand
  }

  interface SimplePaletteColorOptions {
    hint?: string
  }
}

declare module '@mui/material/Select' {
  interface SelectProps {
    size: 'tiny' | 'small' | 'medium' | undefined
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/FormControl' {
  interface FormControlPropsSizeOverrides {
    tiny: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    '$font-title-display': true
    '$font-title-1': true
    '$font-title-2': true
    '$font-title-3': true
    '$font-title-4': true
    '$font-title-5': true
    '$font-body-base': true
    '$font-body-1': true
    '$font-body-2': true
    '$font-body-3': true
    '$font-body-4': true
    '$font-body-5': true
    '$font-text-link': true
  }
}

// update Tabs indicatorColor prop options
declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    '$color-text-secondary': true
  }
}

const paletteBuiltColors: PaletteOptions = Object.keys(
  ExtendedPaletteColors
).reduce((palette, color) => {
  _.set(palette, color, {
    contrastText: '#ffffff',
    light: _.get(colors, color),
    dark: _.get(colors, color),
    main: _.get(colors, color),
  })
  return palette
}, {}) as unknown as PaletteOptions

// update Button color prop options

type ColorFlags = {
  [K in keyof typeof ExtendedPaletteColors]: true
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides extends ColorFlags {
    ['primary']: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides extends ColorFlags {
    ['primary']: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    '$font-title-display': {
      fontWeight: font.weight.bold,
      fontSize: font.size.display,
      lineHeight: font.height.fontelinha4xg,
    },
    '$font-title-1': {
      fontWeight: font.weight.bold,
      fontSize: font.size.h1,
      lineHeight: font.height.fontelinha3xg,
    },
    '$font-title-2': {
      fontWeight: font.weight.semibold,
      fontSize: font.size.h2,
      lineHeight: font.height.fontelinha2xg,
    },
    '$font-title-3': {
      fontWeight: font.weight.semibold,
      fontSize: font.size.h3,
      lineHeight: font.height.fontelinhaxg,
    },
    '$font-title-4': {
      fontWeight: font.weight.semibold,
      fontSize: font.size.h4,
      lineHeight: font.height.fontelinhag,
    },
    '$font-title-5': {
      fontWeight: font.weight.semibold,
      fontSize: font.size.g,
      lineHeight: font.height.fontelinhag,
    },
    '$font-body-base': {
      fontWeight: font.weight.regular,
      fontSize: font.size.base,
      lineHeight: font.height.fontelinham,
    },
    '$font-body-1': {
      fontWeight: font.weight.regular,
      fontSize: font.size.g,
      lineHeight: font.height.fontelinhag,
    },
    '$font-body-2': {
      fontWeight: font.weight.medium,
      fontSize: font.size.base,
      lineHeight: font.height.fontelinham,
    },
    '$font-body-3': {
      fontWeight: font.weight.regular,
      fontSize: font.size.p,
      lineHeight: font.height.fontelinhap,
    },
    '$font-body-4': {
      fontWeight: font.weight.bold,
      fontSize: font.size.base,
      lineHeight: font.height.fontelinham,
    },
    '$font-body-5': {
      fontWeight: font.weight.medium,
      fontSize: font.size.p,
      lineHeight: font.height.fontelinhap,
    },
    '$font-text-link': {
      fontWeight: font.weight.regular,
      fontSize: font.size.base,
      lineHeight: font.height.fontelinham,
    },
  },
  palette: {
    ...colors,
    ...paletteBuiltColors,
  },
})

export const NomosTheme = createTheme(theme, {
  components: {
    ...TextField,
    MuiDialog: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        paper: {
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.15) 0px 0px 0px 1px',
        },
      },
    },
    MuiMenu: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        paper: {
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
          backgroundColor: theme.palette.brand.offwhite,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        shadow: 'none',
      },
      variants: [
        {
          props: { size: 'tiny' },
          style: {
            height: '26px',
            fontSize: '12px',
            lineHeight: '14px',
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: '48px',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 4,
          borderWidth: 1,
          textTransform: 'none',
          boxShadow: 'none',
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
})
