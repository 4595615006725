import { HomeContext } from './context'
import { searcheableBuckets } from '@utils/buckets'
import { useNavigate } from 'react-router-dom'
import { useMemo, useState, useCallback } from 'react'
import { useSearchCount } from '@services/nomos_api/resources/search/count'
import {
  Filter,
  SearchCountEntity,
} from '@services/nomos_api/entities/saved_searches'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { encodeKeywords } from '@utils/functions/keyword/serialize'

export type HomeControllerProps = {
  children: React.ReactChild
}

export function HomeController({ children }: HomeControllerProps) {
  const navigate = useNavigate()

  const [modes, setModes] = useState<'sensitive'[]>([])
  const [buckets, setBuckets] = useState(searcheableBuckets)
  const [search, setSearch] = useState<AdvancedSearchType>({
    keywords: { and: [], not: [], or: [] },
  })

  const { data, isFetching } = useSearchCount(search, buckets, modes)
  const searchCount = data || ({} as SearchCountEntity)

  const clearLocalStorageFilters = () => {
    const search = localStorage.getItem('@NOMOS:defaultFilters')
    if (search) localStorage.removeItem('@NOMOS:defaultFilters')
  }

  const handleExecuteSearch = useCallback(
    (
      keywords?: AdvancedSearchType,
      filters?: Filter,
      searchId?: string,
      monitorId?: string
    ) => {
      const initBuckets =
        !filters || Object.keys(filters).length === 0
          ? buckets
          : Object.keys(filters)
      if (initBuckets.length === 0) return

      clearLocalStorageFilters()
      const args = []

      if (keywords?.keywords !== undefined)
        args.push(`keyword=${encodeKeywords(keywords.keywords)}`)

      if (modes?.length > 0)
        args.push(`modes=${encodeURIComponent(modes.join(','))}`)

      if (initBuckets?.length > 0)
        args.push(`buckets=${encodeURIComponent(initBuckets.join(','))}`)

      if (searchId) args.push(`searchId=${searchId}`)

      if (monitorId) args.push(`monitorId=${monitorId}`)

      navigate(`/search-results?${args.join('&')}`, {
        state: { filters },
      })
    },
    [buckets, search, modes]
  )

  const state = useMemo(
    () => ({
      modes,
      search,
      buckets,
      setModes,
      setSearch,
      setBuckets,
      searchCount,
      isFetching,
      handleExecuteSearch,
    }),
    [
      modes,
      search,
      buckets,
      setModes,
      setSearch,
      setBuckets,
      searchCount,
      isFetching,
      handleExecuteSearch,
    ]
  )

  return <HomeContext.Provider value={state}>{children}</HomeContext.Provider>
}
