/* eslint-disable */

export const TextField = {
  MuiInputBase: {
    defaultProps: {},
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          height: 36,
          fontSize: 14,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        },
      },
      {
        props: { size: 'small' },
        style: {
          height: 48,
          fontSize: 14,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    ],
  },
  MuiTextField: {
    defaultProps: {},
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          height: 36,
        },
      },
    ],
  },
  MuiFormControl: {
    defaultProps: {},
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          height: 36,
        },
      },
    ],
  },
  MuiInputLabel: {
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          fontSize: '14px',
          top: -8,
        },
        styleOverrides: {
          shrink: ({ ownerState }: any) => ({
            ...(ownerState.shrink && {
              top: '1px !important',
            }),
          }),
        },
      },
      {
        props: { size: 'small' },
        style: {
          fontSize: '14px',
          top: 4,
        },
      },
    ],
    styleOverrides: {
      shrink: ({ ownerState }: any) => ({
        ...(ownerState.shrink && {
          // fontSize: '16px !important',
          top: '2px !important',
        }),
      }),
    },
  },
}
