import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)(() => ({
  flex: 1,
  gap: 12,
  width: '100%',
  display: 'flex',
  paddingTop: '128px',
  paddingBottom: '128px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}))
