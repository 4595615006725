import { useState, useMemo, useCallback } from 'react'
import { Tramitations } from '../Tramitations'
import { Status } from '@components/molecules'
import { Description } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'
import { TabContent, TabContentInner } from '../../styles'
import {
  Button,
  ContainerScroll,
  Shimmer,
  ShimmerText,
  SizedComponent
} from '@components/atoms'
import { Box, Chip, Divider, Grid, Stack, Typography } from '@mui/material'
import { PropositionProcessingLegend } from '@services/nomos_api/entities/proposition'

import {
  formatDate,
  formatDateTime,
} from '@utils/functions/formatter/formatter'
import { useNavigate } from 'react-router-dom'
import { theme } from '@theme/index'
import PropositionAuthorsModal from '../AuthorsModal'
import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'

export function PanelGeneral({ visible = false }) {
  const navigate = useNavigate()
  const { showSnackbarError } = useSnackbar()
  const { openModal } = useModal()
  const [maxHeight, setMaxHeight] = useState(0)

  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  const isLoading = useContextSelector(
    PropositionDetailsContext,
    (s) => s.isLoading
  )

  const handleOpenStakeholder = (stakeholderId) => {
    if(!stakeholderId) {
      showSnackbarError(`Stakeholder sem identificador associado`, undefined, { "position": "center"})
    }else {
      navigate(`/stakeholders/${stakeholderId}`)      
    }
  }

  const handleOpenOrgan = () => {
    if(!proposition?.lastProceeding?.organ?.id) {
      showSnackbarError(`Orgão sem identificador associado`, undefined, { "position": "center"})
    }else {
      navigate(`/organs/${proposition?.lastProceeding?.organ?.id}`)
    }
  }

  const handleOpenAuthors = useCallback(() => {
    openModal(<PropositionAuthorsModal authors={proposition?.authors}/>)
  }, [proposition?.authors])

  const author = useMemo(() => {
    return proposition?.authors?.[0]
  }, [proposition?.authors])

  return (
    <Grid container spacing={2} sx={{ display: visible ? 'flex' : 'none' }}>
      <Grid item xs={12} md={8}>
        <SizedComponent onChange={({ height }) => setMaxHeight(height)}>
          <TabContent>
            <TabContentInner>
              <Stack direction="column" spacing={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Typography
                    variant="$font-title-3"
                    color="$color-text-primary"
                  >
                    Dados Gerais
                  </Typography>
                  <Shimmer isLoading={isLoading} width={20} height={20}>
                    <Status
                      text={
                        PropositionProcessingLegend?.[
                         proposition?.lastProceeding?.processing
                        ]?.text
                      }
                      color={
                        PropositionProcessingLegend?.[
                          proposition?.lastProceeding?.processing
                        ]?.color
                      }
                    />
                  </Shimmer>
                </Stack>
                <Shimmer isLoading={isLoading} width={100}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="$font-body-base" color="$color-text-primary">
                      Órgão:
                    </Typography>
                    {(proposition?.lastProceeding?.organ?.id) && (
                      <Button
                        variant="text"
                        disabled={!proposition?.lastProceeding?.organ?.id}
                        text={proposition?.lastProceeding?.organ?.initials || '--'}
                        onClick={handleOpenOrgan}
                      />
                    )}
                    {(!proposition?.lastProceeding?.organ?.id) && (
                      <Typography variant="$font-body-2" color="$color-text-primary">
                        {proposition?.lastProceeding?.organ?.initials || '--'}
                      </Typography>
                    )}                        
                  </Stack>
                </Shimmer>
                <Shimmer isLoading={isLoading} width={90}>
                  <Description
                    label="Situação:"
                    value={proposition?.lastProceeding?.situation || '--'}
                  />
                </Shimmer>
              </Stack>
              <Divider color={theme.palette['$color-border-primary']} />
              <Box>
                <Grid container rowSpacing={5}>
                  <Grid item xs={12} md={6}>
                    <Stack
                      direction="column"
                      spacing={1}
                      position="relative"
                      justifyContent="flex-end"
                    >
                      <Typography
                        variant="$font-body-1"
                        color="$color-text-secondary"
                      >
                        Autores:
                      </Typography>
                      <Shimmer isLoading={isLoading} width={180}>
                        {author && (
                          <Stack direction="row" spacing={2} alignItems="center">
                            {(proposition?.authors?.length === 1 && !!author.stakeholderId) && (
                              <Button
                                variant="text"
                                sx={{ marginLeft: '-8px' }}
                                text={[ author.name,author.acronymParty,author.uf].filter((i) => !!i).join(' - ') || '--' }
                                onClick={() => handleOpenStakeholder(author?.stakeholderId)}
                              />
                            )}
                            {(proposition?.authors?.length > 1 || !author.stakeholderId) && (
                              <Typography variant="$font-body-2" color="$color-text-primary">
                                {[ 
                                  proposition?.authors?.[0]?.name,
                                  proposition?.authors?.[0]?.acronymParty,
                                  proposition?.authors?.[0]?.uf
                                ].filter((i) => !!i).join(' - ') || '--'}
                              </Typography>
                            )}
                            {proposition?.authors?.length > 1 && (
                              <Button
                                variant="text"
                                onClick={handleOpenAuthors}
                                text="Ver outros autores"
                              />
                            )}
                          </Stack>
                        )}                          
                      </Shimmer>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="$font-body-1"
                        color="$color-text-secondary"
                      >
                        Apresentação:
                      </Typography>
                      <Shimmer isLoading={isLoading} width={80}>
                        <Typography
                          variant="$font-body-2"
                          color="$color-text-primary"
                        >
                          {formatDate(proposition?.datePresentation)}
                        </Typography>
                      </Shimmer>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="$font-body-1"
                        color="$color-text-secondary"
                      >
                        Forma de Apreciação:
                      </Typography>
                      <Shimmer isLoading={isLoading} width={120}>
                        <Typography
                          variant="$font-body-2"
                          color="$color-text-primary"
                        >
                          {proposition?.lastProceeding?.appreciation ||
                            'Indefinido'}
                        </Typography>
                      </Shimmer>
                    </Stack>
                  </Grid>
                  <Grid item  columns={{xs: 12, md: 6}} spacing={0}>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="$font-body-1"
                        color="$color-text-secondary"
                      >
                        Regime de Tramitação:
                      </Typography>
                      <Shimmer isLoading={isLoading} width={100}>
                        <Typography
                          variant="$font-body-2"
                          color="$color-text-primary"
                        >
                          {proposition?.lastProceeding?.regime ||
                            proposition?.lastProceeding?.regimeCategory ||
                            '--'}
                        </Typography>
                      </Shimmer>
                    </Stack>
                  </Grid>
                  {proposition?.summary && (
                    <Grid item xs={12}>
                      <Stack direction="column" spacing={1}>
                        <Typography
                          variant="$font-body-1"
                          color="$color-text-secondary"
                        >
                          Ementa
                        </Typography>
                        <ShimmerText
                          isLoading={isLoading}
                          height={16}
                          words={40}
                        >
                          <Typography
                            variant="$font-body-2"
                            color="$color-text-primary"
                          >
                            {proposition?.summary}
                          </Typography>
                        </ShimmerText>
                      </Stack>
                    </Grid>
                  )}

                    {!isLoading && proposition?.lastProceeding?.dispatch && (
                      <Grid item xs={12}>
                        <Stack direction="column" spacing={1}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography
                              variant="$font-body-1"
                              color="$color-text-secondary"
                            >
                              Despacho Atual
                            </Typography>
                            <Typography
                              variant="$font-body-2"
                              color="$color-text-primary"
                            >
                              -
                            </Typography>
                            <Typography
                              variant="$font-body-2"
                              color="$color-text-primary"
                            >
                              {formatDateTime(proposition?.lastProceeding?.date)}
                            </Typography>
                          </Stack>
                          <Typography
                            variant="$font-body-2"
                            color="$color-text-primary"
                          >
                            {proposition?.lastProceeding?.dispatch || '--'}
                          </Typography>
                        </Stack>
                      </Grid>
                    )}

                    {(proposition?.themes || []).length > 0 && (
                      <Grid item xs={12}>
                        <Stack direction="column" spacing={1}>
                          <Typography
                            variant="$font-body-1"
                            color="$color-text-secondary"
                          >
                            Temas Relacionados
                          </Typography>
                          <Stack direction="row"  flexWrap='wrap' gap={2}>
                            <ShimmerText
                              isLoading={isLoading}
                              words={3}
                              height={20}
                            >
                              {proposition.themes.map((theme) => (
                                <Chip
                                  key={`theme-${theme.openDataId}`}
                                  color="primary"
                                  size="medium"
                                  label={theme.theme}
                                />
                              ))}
                            </ShimmerText>
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Box>
            </TabContentInner>
          </TabContent>
        </SizedComponent>
      </Grid>
      <Grid item xs={12} md={4}>
        <TabContent
          sx={{ height: maxHeight, maxHeight: maxHeight }}
        >
          <TabContentInner>
            <Stack direction="column" spacing={1}>
              <Typography variant="$font-title-3" color="$color-text-primary">
                Resumo de Tramitação
              </Typography>
              <Shimmer isLoading={isLoading} width="100%" height={20}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  Veja as últimas tramitações que ocorreram em{' '}
                  {proposition?.title}
                </Typography>
              </Shimmer>
            </Stack>
            <Shimmer isLoading={isLoading} width="100%" height={60}>
              <ContainerScroll auto='true'>
                <Tramitations />
              </ContainerScroll>
            </Shimmer>
          </TabContentInner>
        </TabContent>
      </Grid>
    </Grid>
  )
}
