import { NoteManagerDrawerView } from './view'
import { NoteManagerDrawerProps } from './types'
import { NoteManagerDrawerController } from './controller'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

export function NoteManagerDrawer(props: NoteManagerDrawerProps) {
  useByPassSubscription()
  return (
    <NoteManagerDrawerController {...props}>
      <NoteManagerDrawerView {...props} />
    </NoteManagerDrawerController>
  )
}
