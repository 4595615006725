export enum NomosProductCodeEnum {
  ALERT = 'ALERT',
  INSIGHTS = 'INSIGHTS',
  ORGANOGOV = 'ORGANOGOV',
  MONITOR_PRO = 'MONITOR_PRO',
  MONITOR_LIGHT = 'MONITOR_LIGHT',
}

export type NomosProductCode = keyof typeof NomosProductCodeEnum

export const NomosProductLabel: Record<NomosProductCode, string> = {
  [NomosProductCodeEnum.ALERT]: 'Alerta',
  [NomosProductCodeEnum.INSIGHTS]: 'Insights',
  [NomosProductCodeEnum.ORGANOGOV]: 'Organogov',
  [NomosProductCodeEnum.MONITOR_PRO]: 'Monitor Pro',
  [NomosProductCodeEnum.MONITOR_LIGHT]: 'Monitor Light',
}

export const NomosProductRoleName: Record<NomosProductCode, string> = {
  [NomosProductCodeEnum.ALERT]: 'Alerta',
  [NomosProductCodeEnum.INSIGHTS]: 'Insights',
  [NomosProductCodeEnum.ORGANOGOV]: 'Organogov',
  [NomosProductCodeEnum.MONITOR_PRO]: 'Monitoramento',
  [NomosProductCodeEnum.MONITOR_LIGHT]: 'Visualização',
}

export const NomosProductDesc: Record<NomosProductCode, string> = {
  [NomosProductCodeEnum.ALERT]: 'Sem descrição',
  [NomosProductCodeEnum.INSIGHTS]: 'Sem descrição',
  [NomosProductCodeEnum.ORGANOGOV]: 'Sem descrição',
  [NomosProductCodeEnum.MONITOR_PRO]:
    'Acesso total os painéis, pode gerenciar usuários dos painéis, salvar dados e pesquisas para monitoramento.',
  [NomosProductCodeEnum.MONITOR_LIGHT]:
    'Pode ver e acessar painéis públicos, sugeridos e aqueles em que está inserido. Não pode monitorar nem editar.',
}
