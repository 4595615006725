import React from 'react'
import { useModal } from '@contexts/Modal'
import { BackgroundIcon } from '../styles'
import { FileUploader } from 'react-drag-drop-files'
import { useContextSelector } from 'use-context-selector'
import { ImportPropositionModalContext } from '../context'
import { Button, InputFileHidden } from '@components/atoms'
import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material'

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'

export function StateModalStandard(): JSX.Element {
  const { closeModal } = useModal()

  const [typeError, setTypeError] = React.useState<boolean>(false)

  const setFile = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.setFile
  )

  const modelLink = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.modelLink
  )

  return (
    <>
      <DialogTitle sx={{ p: 3 }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-4" color="$color-text-primary">
            Importar Proposições
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Envie um arquivo XLS ou CSV para importar as proposições
            <br />
            que você acompanha neste painel.
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <FileUploader
            name="file"
            types={['XLSX', 'CSV']}
            onTypeError={() => setTypeError(true)}
            handleChange={(file: File) => setFile(file)}
          >
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <BackgroundIcon>
                <FileUploadOutlined color="primary" />
              </BackgroundIcon>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button text="Click to upload" variant="text" size="medium">
                  <InputFileHidden
                    onChange={(e) => setFile(e?.target?.files?.[0])}
                  />
                </Button>
                <Typography variant="$font-title-5" color="$color-text-primary">
                  or drag and drop
                </Typography>
              </Stack>
              {typeError && (
                <Typography variant="caption" color="error">
                  O arquivo deve conter a extensão <b>.xlsx</b> ou <b>.csv</b>
                </Typography>
              )}
            </Stack>
          </FileUploader>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography variant="$font-body-3" color="$color-text-primary">
              O arquivo deve ser gerado a partir do seguinte modelo
            </Typography>
            <Button
              text="Baixar Modelo"
              variant="text"
              size="small"
              endIcon={<FileDownloadOutlined fontSize="small" />}
              href={modelLink}
              target="_blank"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
        </Stack>
      </DialogActions>
    </>
  )
}
