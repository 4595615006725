import { AutoUploadFileController } from './AutoUploadFile.controller'
import { AutoUploadFileProps } from './AutoUploadFile.types'
import { AutoUploadFileView } from './AutoUploadFile.view'

export function AutoUploadFile(props: AutoUploadFileProps): JSX.Element {
  return (
    <AutoUploadFileController {...props}>
      <AutoUploadFileView {...props} />
    </AutoUploadFileController>
  )
}
