/* eslint-disable @typescript-eslint/no-explicit-any */
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { createContext } from 'use-context-selector'

export type EditSavedSearchContextProps = {
  form: any
  step: string
  search: SavedSearchEntity
  isLoading: boolean
  handleSubmit: () => void
  handleUpdateStep: (step: string) => void
}

export const EditSavedSearchContext =
  createContext<EditSavedSearchContextProps>({} as EditSavedSearchContextProps)
