import { ReactNode } from 'react'
import { TooltipProps } from '@mui/material'
import { TooltipStyled } from './Tooltip.styles'

type Props = {
  id?: string
  title: string | NonNullable<ReactNode>
  children: JSX.Element
} & Partial<Pick<TooltipProps, 'placement'>>

export default function Tooltip({
  id = 'tooltip',
  title,
  children,
  placement,
}: Props): JSX.Element {
  return (
    <TooltipStyled
      data-testid={id}
      title={title}
      placement={placement || 'right'}
      arrow
    >
      {children}
    </TooltipStyled>
  )
}
