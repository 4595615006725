/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent, useMemo } from 'react'
import { AdaptiveChip, CountChip, Wrapper } from './render-tags.styles'
import { AutocompleteRenderGetTagProps } from '@mui/material'
import { MultiSelectChipOption } from '../InputMultiSelectChip'

type Props = {
  maxRenderTags?: number
  values: MultiSelectChipOption[]
  options: MultiSelectChipOption[]
  groupAllSelectedOptions?: string | undefined
  getTagProps: AutocompleteRenderGetTagProps
  onChangeOptions: (event: SyntheticEvent<Element, Event>, value: any) => void
}

export function RenderTags({
  values,
  options,
  getTagProps,
  maxRenderTags,
  onChangeOptions,
  groupAllSelectedOptions,
}: Props): JSX.Element {
  const selectedOptions = useMemo(() => {
    return values.filter((o) => !!o.key)
  }, [values])

  const newOptions = useMemo(() => {
    return values.filter((o) => !o.key)
  }, [values])

  const renderTags = useMemo(() => {
    if (maxRenderTags && selectedOptions.length > maxRenderTags)
      return selectedOptions.slice(0, maxRenderTags)
    return selectedOptions
  }, [maxRenderTags, selectedOptions])

  const RenderSelectedTags = useMemo(() => {
    if (
      selectedOptions.length > 1 &&
      selectedOptions.length === options.length &&
      groupAllSelectedOptions
    ) {
      return (
        <AdaptiveChip
          size="small"
          sx={{ marginTop: '6px' }}
          label={groupAllSelectedOptions}
          onDelete={(e) => onChangeOptions(e, newOptions)}
        />
      )
    }
    return (
      <>
        {renderTags.map((tag, index) => {
          const props = getTagProps({ index })
          return <AdaptiveChip {...props} size="small" label={tag.label} />
        })}
        {renderTags.length !== selectedOptions.length && (
          <CountChip label={`+${selectedOptions.length - renderTags.length}`} />
        )}
      </>
    )
  }, [selectedOptions, renderTags, newOptions])

  return (
    <Wrapper>
      {RenderSelectedTags}
      {newOptions.map((tag, index) => {
        const props = getTagProps({ index })
        return <AdaptiveChip {...props} size="small" label={tag.label} />
      })}
    </Wrapper>
  )
}
