import * as yup from 'yup'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { SigninEmailContext } from './context'
import { SignInEmailAPI } from '@services/nomos_api/resources/signin_email'
import { useAuth } from '@contexts/Auth'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useEmailLogin } from '@services/nomos_api/resources/signin_email'
import { email, password } from '@utils/forms/validation'
import { useSnackbar } from '@contexts/Snackbar'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { ResendConfirmationEmailAPI } from '@services/nomos_api/resources/resend_confirmation'

export type SigninEmailProps = {
  children: React.ReactNode
}

export default function SigninEmailController({ children }: SigninEmailProps) {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { isLoading, error } = useEmailLogin()
  const { showSnackbarError, showSnackbarInfo } = useSnackbar()

  let resendConfirmationLink: () => void

  const validationSchema = yup.object({
    email,
    password,
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      SignInEmailAPI(values)
        .then((response) => {
          if (response.kind === 'AUTHENTICATION') {
            const lastPage = localStorage.getItem('@NOMOS:lastPage')
            isAuthenticated(response.data.user, response.data.jwt)
            navigate(lastPage || (isMobile ? 'search-results' : '/home'))
          } else if (response.kind === 'ACTIVATE_ACCOUNT') {
            showSnackbarError(
              'Erro ao fazer login',
              'Identificamos que você não confirmou seu e-mail através do link enviado.',
              undefined,
              {
                text: 'Reenviar link de confirmação',
                onClick: resendConfirmationLink,
              }
            )
          }
        })
        .catch((error) => {
          const e = error as AxiosErrorResponse
          showSnackbarError(
            'Erro de Autenticação',
            e?.response?.data?.message ||
              'Erro ao tentar autenticar. Verifique seus dados e tente novamente.'
          )
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  resendConfirmationLink = () => {
    ResendConfirmationEmailAPI({
      email: formik.values.email,
    }).then(() => {
      showSnackbarInfo(
        'E-mail de confirmação',
        'Se os dados fornecidos estiverem corretos, você deve receber um novo e-mail de confirmação.'
      )
    })
  }

  const store = useMemo(
    () => ({
      formik,
      isLoading,
      error,
    }),
    [formik, isLoading, error]
  )

  return (
    <SigninEmailContext.Provider value={store}>
      {children}
    </SigninEmailContext.Provider>
  )
}
