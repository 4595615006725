import React from 'react'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { StateModalHeader } from './StateModalHeader'
import {
  Stack,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { ExportPropositionModalContext } from '../context'

export function StateModalDownload() {
  const { closeModal } = useModal()

  const handleRequestExport = useContextSelector(
    ExportPropositionModalContext,
    (s) => s.handleRequestExport
  )

  React.useEffect(() => {
    handleRequestExport()
  }, [])

  return (
    <>
      <StateModalHeader description="Exportando proposições para os filtros aplicados..." />
      <DialogContent sx={{ maxHeight: 155 }}>
        <LinearProgress />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Fechar" variant="text" onClick={closeModal} />
        </Stack>
      </DialogActions>
    </>
  )
}
