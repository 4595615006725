// import _ from 'lodash'
import { PropositionDetailsContext } from './context'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'
import { getPropositionApi } from '@services/nomos_api/resources/proposition/get'
import { getPropositionRelateds } from '@services/nomos_api/resources/proposition/relateds'
import { getPropositionAttacheds } from '@services/nomos_api/resources/proposition/attacheds'
import { useParams, useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'

export function PropositionDetailsController({ children }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const tab = searchParams.get('tab')
  const { id, openDataResource, openDataId } = useParams()

  const query = new URLSearchParams(document.location.search)
  const keyword = query.get('keyword') || ''
  const buckets = query.get('buckets') || ''

  const [isLoading, setLoading] = useState(false)
  const [relateds, setRelateds] = useState(undefined)
  const [attacheds, setAttacheds] = useState(undefined)
  const [proposition, setProposition] = useState(undefined)
  const [lastPropositionId, setLastPropositionId] = useState(id)
  const [selectedTab, setSelectedTab] = useState(tab || 'general')
  const [isLoadingRelateds, setIsLoadingRelateds] = useState(false)

  const handleChangeTab = useCallback((tab) => {
    setSelectedTab(tab)
    navigate({ search: `${createSearchParams({ tab, keyword, buckets: getSortedBucket(buckets, 'propositions') })}` }, { replace: true })
  }, [id, openDataResource, openDataId, buckets, keyword])

  const appendQueryString = useMemo(
    () =>
      `?buckets=${getSortedBucket(buckets, 'propositions')}&keyword=${keyword}`,
    [id, openDataResource, openDataId, buckets, keyword]
  )

  const handleLoadRelateds = useCallback(() => {
    // return if proposition does not have relateds
    // return if proposition already have loaded relateds
    if (!proposition?.related || !!relateds) return
    setIsLoadingRelateds(true)
    getPropositionRelateds(proposition.id)
      .then(setRelateds).finally(() => setIsLoadingRelateds(false))
  }, [id, openDataResource, openDataId, proposition, relateds])

  const handleLoadAttacheds = useCallback(() => {
    // return if proposition does not have relateds
    // return if proposition already have loaded relateds
    if (!proposition?.attached || !!attacheds) return
    setIsLoadingRelateds(true)
    getPropositionAttacheds(proposition.id)
      .then(setAttacheds)
      .finally(() => setIsLoadingRelateds(false))
  }, [id, openDataResource, openDataId,, proposition, attacheds])

  useEffect(() => {
    if(!!proposition && !!lastPropositionId && proposition.id !== lastPropositionId){
      handleChangeTab("general")
      setLastPropositionId(proposition.id)
    }
  }, [proposition, lastPropositionId])

  useEffect(() => {
    setLoading(true)
    setRelateds(undefined)
    setAttacheds(undefined)
    setProposition(undefined)
    getPropositionApi(id, openDataResource, openDataId)
      .then(setProposition)
      .finally(() => setLoading(false))
  }, [id, openDataResource, openDataId])

  const state = useMemo(
    () => ({
      keyword,
      buckets,
      proposition,
      relateds,
      attacheds,
      isLoading,
      isLoadingRelateds,
      selectedTab,
      handleChangeTab,
      appendQueryString,
      handleLoadRelateds,
      handleLoadAttacheds,
    }),
    [
      keyword,
      buckets,
      proposition,
      relateds,
      attacheds,
      isLoading,
      isLoadingRelateds,
      selectedTab,
      handleChangeTab,
      appendQueryString,
      handleLoadRelateds,
      handleLoadAttacheds,
    ]
  )
  return (
    <PropositionDetailsContext.Provider value={state}>
      {children}
    </PropositionDetailsContext.Provider>
  )
}
