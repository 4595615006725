import { useQuery } from '@tanstack/react-query'
import HTTPClient from '../..'

export const getMonitorByIdAPI = async (props) => {
  const { data } = await HTTPClient.get(`monitor/${props.id}`)

  return data
}

export const useGetMonitorById = (props) =>
  useQuery(['get-monitor-by-id', props], () => getMonitorByIdAPI(props), {
    refetchOnWindowFocus: false,
  })
