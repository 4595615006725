import colors from '@theme/colors'
import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
`

export const DashboardHero = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 88%;
  margin: 0 auto;
  background-color: ${colors['$color-background-primary']};
`
