import produce from 'immer'
import { useDrawer } from '@contexts/Drawer'
import { TagManagerContext } from './context'
import { useCallback, useMemo, useState } from 'react'
import { useFindTags } from '@services/nomos_api/resources/tags/find'
import { TagManagerControllerProps, TagManagerViewModeType } from './types'
import { useDebounce } from '@hooks/useDebounce'
import { TagEntity } from '@services/nomos_api/entities/tag'

export function TagManagerController({
  children,
  onTags,
  compact,
  handleClose,
  initialTags,
}: TagManagerControllerProps): JSX.Element {
  const { closeDrawer } = useDrawer()
  const [selecteds, setSelecteds] = useState<TagEntity[]>(initialTags || [])
  const [viewmode, setViewmode] = useState<TagManagerViewModeType>('listing')

  const [searchName, setSearchName] = useState<string>('')
  const searchDebounce = useDebounce(searchName, 500)
  const {
    data: tags,
    isLoading,
    refetch: refetchTags,
  } = useFindTags(searchDebounce)

  const handleTagSelection = useCallback(
    (tag: TagEntity, selected: boolean) => {
      setSelecteds(
        produce(selecteds, (draft) => {
          if (!selected) {
            const index = draft.findIndex((i) => i.id === tag.id)
            if (index >= 0) draft.splice(index, 1)
          } else {
            draft.push(tag)
          }
        })
      )
    },
    [selecteds, setSelecteds]
  )

  const handleSubmitSelectedTags = useCallback(() => {
    onTags(selecteds)
    closeDrawer()
  }, [selecteds])

  const state = useMemo(
    () => ({
      tags,
      compact: compact === true,
      isLoading,
      viewmode,
      selecteds,
      searchName,
      setSearchName,
      refetchTags,
      setViewmode,
      handleClose,
      handleTagSelection,
      handleSubmitSelectedTags,
    }),
    [
      tags,
      compact,
      isLoading,
      viewmode,
      selecteds,
      searchName,
      setSearchName,
      refetchTags,
      setViewmode,
      handleClose,
      handleTagSelection,
      handleSubmitSelectedTags,
    ]
  )
  return (
    <TagManagerContext.Provider value={state}>
      {children}
    </TagManagerContext.Provider>
  )
}
