import { AxiosResponse } from 'axios'
import { EditBucketSearchView } from './view'
import { EditBucketSearchController } from './controller'
import { SavedSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { BucketsEnum } from '@utils/buckets/type'

export type EditBucketSavedSearchProps = {
  id: string
  monitorId: string
  selectedBucket: BucketsEnum
  search: SavedSearchEntity
  refetchBuckets: () => void
  onSuccess: (response: AxiosResponse) => void
}

export default function EditBucketSavedSearch(
  props: EditBucketSavedSearchProps
) {
  return (
    <EditBucketSearchController {...props}>
      <EditBucketSearchView />
    </EditBucketSearchController>
  )
}
