import styled from 'styled-components'
import { Card } from '@components/atoms'

export const CardContainer = styled(Card)`
  display: flex;
  margin-top: 12px;
  padding: 16px;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

export const CardInfo = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`

export const CardOptions = styled.div`
  display: flex;
  width: 100%;
  max-width: 320px;
  @media (min-width: 768px) {
    width: auto;
  }
`

export const CardElement = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  @media (min-width: 768px) {
    gap: 24px;
  }
`

export const CardPin = styled.div`
  margin-right: 16px;
  position: absolute;
  right: 0;
  top: 16px;
  @media (min-width: 768px) {
    position: unset;
    bottom: 16px;
    top: unset;
  }
`
