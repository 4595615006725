import { theme } from '@theme'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import NotesIcon from '@assets/icons/notes.svg'
import { Stack, Typography } from '@mui/material'
import { Button, Shimmer } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { PropositionDetailsContext } from '../../context'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { NoteManagerDrawer } from "@containers/NoteManagerDrawer"
import { DownloadRounded, ArrowForwardIos, LaunchOutlined } from '@mui/icons-material'

import {
  NavLink,
  TextLink,
  Container,
  ContentPaths,
  ContentActions,
  ContentActionsSide,
} from './styles'
import { HouseInfo } from '@components/molecules'
import { useAuth } from '@contexts/Auth'

export function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()

  const isLoading = useContextSelector(
    PropositionDetailsContext,
    (s) => s.isLoading
  )

  const appendQueryString = useContextSelector(
    PropositionDetailsContext,
    (s) => s.appendQueryString
  )

  const proposition = useContextSelector(
    PropositionDetailsContext,
    (s) => s.proposition
  )

  return (
    <Container>
      {auth && (
        <ContentPaths>
          <NavLink to={isMobile ? "/search-results" : "/home"}>
            <TextLink variant="$font-body-base">Inicio</TextLink>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${appendQueryString}`}>
            <TextLink variant="$font-body-base">Proposição</TextLink>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <Shimmer isLoading={isLoading} width={60} height={14}>
            <Typography data-cy="display-proposition-title" variant="$font-body-base" color="$color-text-primary">
              {proposition?.title || '--'}
            </Typography>
          </Shimmer>
        </ContentPaths>
      )}

      <Stack direction="row">
        <Shimmer isLoading={isLoading} width={240} height={60}>
          <Typography
            variant="$font-title-1"
            color="$color-text-primary"
            sx={{ borderBottom: `3px solid ${theme.palette.secondary.main}` }}
          >
            {proposition?.title || '--'}
          </Typography>
        </Shimmer>
      </Stack>

      <ContentActions >
        <ContentActionsSide>
          <Shimmer isLoading={isLoading} width={100} height={24}>
            <Typography variant="$font-title-4" color="$color-text-primary">
              {proposition?.type || '--'}
            </Typography>
          </Shimmer>
          <HouseInfo sx={{display: {xs: 'none', md:'flex'}}} text={proposition?.openDataResource || 'N/A'} />
        </ContentActionsSide>

        <ContentActionsSide>
          <HouseInfo sx={{display: {xs: 'flex', md:'none'}}} text={proposition?.openDataResource || 'N/A'} />
          {proposition?.link && !isMobile && (
            <Button
              variant="text"
              size="medium"
              sx={{px:1, whiteSpace: "nowrap", display:{xs:'none', md:'flex'}}}
              endIcon={<LaunchOutlined />}
              text="Acompanhe na Íntegra"
              href={proposition?.link}
              target="_blank"
            />
          )}

         {proposition?.url && !isMobile && (
            <Button
              variant="text"
              text="Inteiro Teor"
              endIcon={<DownloadRounded />}
              href={proposition.url}
              target="_blank"
              rel="noreferrer"
            />
          )}

          <Button
            variant="outlined"
            text="Opções de Monitoramento"
            disabled={isLoading}
            sx={{display:{xs:'none', md:'flex'}}}
            onClick={() => {
              openDrawer(
                <MonitoringDrawer 
                  id={proposition?.id} 
                  origin="propositions"
                  house={proposition?.openDataResource}
                  isSaved={proposition?.savedPropositions?.length > 0}
                />
              )
            }}
          />

          <Button
            text={`Notas`}
            color="$color-extended-red1"
            variant="contained"
            sx={{display:{xs:'none', md:'flex'}}}
            endIcon={<img src={NotesIcon} alt="Notas" />}
            disabled={isLoading}
            onClick={() => {
              openDrawer(
                <NoteManagerDrawer 
                  id={proposition?.id} 
                  bucket="propositions"
                />
              )
            }}
          />
        </ContentActionsSide>
      </ContentActions>
    </Container>
  )
}
