import { EventEntity } from '@services/nomos_api/entities/event'
import { createContext } from 'react'

export type EventsCalendarContextType = {
  eventsMode: string
  setEventsMode: (mode: string) => void
  handleCloseModalDetailsEvent: () => void
  handleOpenModalDetailsEvent: (event: EventEntity) => void
}

export const EventsCalendarContext =
  createContext<EventsCalendarContextType | null>(null)
