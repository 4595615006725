import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  LinearProgress,
} from '@mui/material'
import { useModal } from '@contexts/Modal'
import { BackgroundIcon } from '../styles'
import { Button } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { ImportPropositionModalContext } from '../context'
import { LoadingButton } from '@mui/lab'
import { Close, FileUploadOutlined } from '@mui/icons-material'

export function StateModalUpload(): JSX.Element {
  const { closeModal } = useModal()

  const file = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.file
  ) as File

  const submiting = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.submiting
  )

  const handleSubmitFile = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.handleSubmitFile
  )

  const handleResetForm = useContextSelector(
    ImportPropositionModalContext,
    (s) => s.handleResetForm
  )

  return (
    <>
      <DialogTitle sx={{ p: 3 }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-4" color="$color-text-primary">
            Importar Proposições
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Envie um arquivo XLS ou CSV para importar as proposições
            <br />
            que você acompanha neste painel.
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <BackgroundIcon>
              <FileUploadOutlined color="primary" />
            </BackgroundIcon>
            <Stack direction="column">
              <Typography variant="$font-title-5" color="$color-text-primary">
                {file.name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="$font-title-5" color="$color-text-primary">
                  {`${Math.round(file.size / 1024)}kb`}
                </Typography>
                <Typography variant="caption" color="$color-text-primary">
                  •
                </Typography>
                <Button
                  text="cancelar"
                  size="small"
                  variant="text"
                  color="primary"
                  disabled={submiting}
                  onClick={handleResetForm}
                  endIcon={<Close fontSize="small" />}
                />
              </Stack>
            </Stack>
          </Stack>
          {submiting && <LinearProgress variant="indeterminate" />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="text" onClick={closeModal} />
          <LoadingButton
            loading={submiting}
            onClick={handleSubmitFile}
            variant="contained"
            color="primary"
          >
            Enviar
          </LoadingButton>
        </Stack>
      </DialogActions>
    </>
  )
}
