import { PageContainer } from './styles'
import SavedBucketsList from './components/SavedBucketsList'
import SavedBucketsFilter from './components/SavedBucketsFilter'

function SavedBucketsView() {
  return (
    <PageContainer>
      <SavedBucketsFilter />
      <SavedBucketsList />
    </PageContainer>
  )
}

export default SavedBucketsView
