import { Container, Content } from './styles'
import AppBar from '@containers/AppBar/AppBar'
import { Insights } from '@components/icons'
import { BaseLayoutSelector } from '@layouts/base'
import React from 'react'
import { Outlet } from 'react-router-dom'

const pages = [
  { name: 'Para sua empresa', path: 'insights', options: [] },
  { name: 'Brasil', path: 'insights/brazil', options: undefined },
]

export default function OrganoGovLayout() {
  const [top, setTop] = React.useState<number>(0)
  return (
    <BaseLayoutSelector>
      <Container>
        <AppBar
          module="insights"
          logo={<Insights width={130} height={20} />}
          pages={pages}
          onChangeHeight={setTop}
        />
        <Content sx={{ marginTop: `${top}px` }}>
          <Outlet />
        </Content>
      </Container>
    </BaseLayoutSelector>
  )
}
