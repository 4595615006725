import { useAuth } from '@contexts/Auth'
import { Button } from '@components/atoms'
import { useDrawer } from '@contexts/Drawer'
import { useNavigate } from 'react-router-dom'
import { BlockedFeatureContext } from '../context'
import LockIcon from '@components/icons/Lock/Lock'
import { useContextSelector } from 'use-context-selector'
import {
  NomosProductCodeEnum,
  NomosProductLabel,
} from '@enums/NomosProductEnum'
import { Stack, Alert, Divider, Typography } from '@mui/material'

export function BlockedFeatureStateMissingRole(): JSX.Element {
  const { closeDrawer } = useDrawer()
  const navigate = useNavigate()

  const { user } = useAuth()

  const requiredRole = useContextSelector(
    BlockedFeatureContext,
    (s) => s.requiredRole
  )

  const handleChangeUserRole = () => {
    closeDrawer()
    navigate(`/users/${user?.id}/edit`)
  }

  const ctaLabel =
    user?.admin === true
      ? 'Você é um administrador da conta, acesse o gerenciamento de usuários para alterar suas permissões.'
      : 'Contate um dos administradores da conta para obter accesso.'

  return (
    <Stack direction="column" spacing={3}>
      <Alert variant="standard" color="info" icon={false}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LockIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Seu usuário precisa da permissão de{' '}
            <Typography variant="$font-title-5" color="$color-text-primary">
              {
                NomosProductLabel[
                  requiredRole || NomosProductCodeEnum.MONITOR_PRO
                ]
              }
            </Typography>{' '}
            para acessar esse recurso
          </Typography>
        </Stack>
      </Alert>
      <Divider />
      <Stack direction="column">
        <Typography variant="$font-title-4" color="$color-text-secondary">
          {ctaLabel}
        </Typography>
      </Stack>
      {user?.admin === true && (
        <Stack direction="row" spacing={1}>
          <Button
            size="large"
            variant="outlined"
            text="Gerenciar permissões"
            onClick={handleChangeUserRole}
          />
        </Stack>
      )}
    </Stack>
  )
}
