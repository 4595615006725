/* eslint-disable @typescript-eslint/no-unused-vars */
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getStakeholderRollCallVotesAPI = async (id: string) => {
  const { data } = await HTTPClient.get(`stakeholders/${id}/count/votes`)
  return data
}

export const useStakeholderRollCallVotes = (id: string) =>
  useQuery(
    ['get-stakeholder-roll-call-votes', id],
    () => getStakeholderRollCallVotesAPI(id),
    {
      refetchOnWindowFocus: false,
    }
  )
