import { useEffect } from 'react'
import { theme } from '@theme/index'
import { useAuth } from '@contexts/Auth'
import { Button } from '@components/atoms'
import { AuthPage } from '@components/templates'
import { PaymentStatusEnum } from '@enums/PaymentStatusEnum'
import { StripePortalLink } from '@containers/StripePortalLink'
import LoadingTag from '@components/atoms/LoadingTag/LoadingTag'
import { StripeBlockedScreen } from '@containers/StripeBlockedScreen'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

type Props = { children: JSX.Element }

export function BaseLayoutSelector({ children }: Props): JSX.Element {
  const { auth, isLoadingSession, user } = useAuth()

  // attach userreport script
  // only for auth sessions
  useEffect(() => {
    // add userreport tool for any environment different from cypress
    if (!window.Cypress) {
      const scriptId = 'userreport-launcher-script'
      const script = document.getElementById(scriptId)
      if (!script) {
        // only add script if it does not exists yet
        const userreport = document.createElement('script')
        userreport.async = true
        userreport.id = scriptId
        userreport.src = 'https://sak.userreport.com/nomo1/launcher.js'
        document.getElementsByTagName('head')[0].appendChild(userreport)
      }
    }
  }, [])

  if (auth && isLoadingSession) {
    return (
      <AuthPage>
        <LoadingTag message="Verificando sessão do usuário..." />
      </AuthPage>
    )
  }

  if (user?.company?.blocked === true) {
    return (
      <StripeBlockedScreen
        color={theme.palette['$color-semantic-error']}
        title="Empresa bloqueada"
        content={[`O acesso ao Nomos-Pro para a sua empresa foi bloqueado.`]}
        cta="Entre em contato com o time de suporte da nomos para regularizar o status da empresa."
        actions={[
          <Button
            href="https://www.nomosapp.com.br/contato"
            size="large"
            variant="outlined"
            text="Fale com um especialista"
          />,
        ]}
      />
    )
  }

  if (
    user?.company?.subscription &&
    [PaymentStatusEnum.paused, PaymentStatusEnum.canceled].includes(
      user?.company?.subscription?.status
    )
  ) {
    return (
      <StripeBlockedScreen
        color={theme.palette['$color-semantic-error']}
        title="Assinatura Cancelada"
        content={[
          `Sua assinatura foi cancelada recentemente em nosso sistema. Recebemos a solicitação de cancelamento por parte do administrador da conta da ${user.company.name} no Nomos.`,
          // `Sua assinatura foi cancelada recentemente em nosso sistema. Recebemos a solicitação de cancelamento por parte do administrador da conta da ${user.company.name} no Nomos. O Acesso ficou disponível até o dia 10/05/2023, data de renovação automática da assinatura.`,
        ]}
        cta="Caso queira voltar a ter acesso ao Nomos realize uma nova assinatura ou fale com um de nossos especialistas"
        actions={[
          <StripePortalLink
            size="large"
            title="Ver assinatura"
            text="Ver assinatura"
          />,
          <Button
            href="https://www.nomosapp.com.br/contato"
            size="large"
            variant="outlined"
            text="Fale com um especialista"
          />,
        ]}
      />
    )
  }

  if (
    user?.company?.expired &&
    user?.company?.subscription &&
    [
      PaymentStatusEnum.incomplete,
      PaymentStatusEnum.incomplete_expired,
    ].includes(user.company?.subscription?.status)
  ) {
    return (
      <StripeBlockedScreen
        color={theme.palette['$color-semantic-warning']}
        title="Checkout Pendente"
        content={[
          `Seu checkout encontra-se em estado de espera, aguardando a finalização ou o processamento do pagamento. Dessa forma, é possível que o pagamento esteja em processo de autorização ou análise.`,
        ]}
        cta="Você pode acompanhar a situação da sua assinatura bem como o status do pagamento clicando no botão abaixo."
        actions={[
          <StripePortalLink
            size="large"
            title="Gerenciar plano de Assinatura"
            text="Gerenciar plano de Assinatura"
          />,
          <Button
            href="https://www.nomosapp.com.br/contato"
            size="large"
            variant="outlined"
            text="Fale com um especialista"
          />,
        ]}
      />
    )
  }

  if (user?.company?.expired && !user?.company?.subscription) {
    return (
      <StripeBlockedScreen
        color={theme.palette['$color-semantic-warning']}
        title="Seu período de teste expirou"
        content={[
          `Seu período de teste em nossa plataforma chegou ao fim. Esperamos que tenha conseguido extrair o máximo da nossa solução durante este tempo. `,
        ]}
        cta="Para continuar aproveitando todas as funcionalidades e benefícios que o Nomos oferece, você pode assinar um de nossos planos clicando no botão abaixo ou falando com um de nossos especialistas."
        actions={[
          <WhatsAppPaymentLink
            size="large"
            title="Assinar agora"
            text="Assinar agora"
          />,
          <Button
            href="https://www.nomosapp.com.br/contato"
            size="large"
            variant="outlined"
            text="Fale com um especialista"
          />,
        ]}
      />
    )
  }

  return children
}
