import {
  TextField,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'

export const TooltipDisabledAlert = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background?.default,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background?.default,
    color: theme.palette.muted?.main,
    boxShadow: theme.shadows[6],
    padding: 12,
  },
}))

export const KeywordInputText = styled(TextField)`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette['$color-background-tertiary']
      : 'white'};
  & .MuiInputBase-input {
    display: ${(props) => (props.disabled ? 'none' : 'inherit')};
  }
`
