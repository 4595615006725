import { useMemo } from 'react'
import { EditUserContext } from './context'
import { useGetUser } from '@services/nomos_api/resources/users/find'
import { useParams } from 'react-router-dom'

export type UsersProps = {
  children: React.ReactNode
}

export function EditUserController({ children }: UsersProps) {
  const { id } = useParams()
  const { data, isFetching } = useGetUser(id || '')

  const store = useMemo(
    () => ({
      isLoading: isFetching,
      user: data,
    }),
    [isFetching, data]
  )

  return (
    <EditUserContext.Provider value={store}>
      {children}
    </EditUserContext.Provider>
  )
}
