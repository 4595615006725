/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { useMemo } from 'react'
import { LoadingButton } from '@mui/lab'
import { TabSelector } from '../TabSelector'
import { StepContainer } from '../../styles'
import { Typography, Stack, Switch } from '@mui/material'
import { EditSavedSearchContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { SavedSearchBucketsEntity } from '@services/nomos_api/entities/saved_searches'
import { SavedSearchFormNotification } from '@containers/SharedDrawers/SaveSearch/components/FormNotification'
import { FormDisabledAlertMsg } from '../FormDisabledAlertMsg'

export function StepNotification() {
  const form = useContextSelector(EditSavedSearchContext, (s) => s.form)
  const search = useContextSelector(EditSavedSearchContext, (s) => s.search)

  const isLoading = useContextSelector(
    EditSavedSearchContext,
    (s) => s.isLoading
  )

  const handleSubmit = useContextSelector(
    EditSavedSearchContext,
    (s) => s.handleSubmit
  )

  const availableBuckets = useMemo(() => {
    return search.searches.reduce((agg, { bucket, ...curr }) => {
      _.set(agg, bucket, curr)
      return agg
    }, {} as SavedSearchBucketsEntity)
  }, [search])

  return (
    <StepContainer>
      <TabSelector />
      <Stack
        spacing={1}
        direction="column"
        sx={{ height: '100%', overflow: 'hidden' }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="$font-body-base" color="$color-text-primary">
              Habilitar Notificações
            </Typography>
            <Switch
              name="muteuser"
              checked={form.values.muteuser !== true}
              defaultChecked={form.values.muteuser !== true}
              onChange={(e) =>
                form.setFieldValue('muteuser', !e.target.checked)
              }
            />
          </Stack>
          <FormDisabledAlertMsg disabled={form.values.muteuser === true} />
        </Stack>
        <SavedSearchFormNotification
          form={form}
          availableBuckets={availableBuckets}
          disabled={form.values.muteuser === true}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <LoadingButton
          size="large"
          variant="contained"
          color="primary"
          sx={{ p: '12px 0', flex: 1 }}
          onClick={handleSubmit}
          loading={isLoading}
        >
          <Typography variant="body1" color="common.white">
            Salvar
          </Typography>
        </LoadingButton>
      </Stack>
    </StepContainer>
  )
}
