import { Box, Typography, Divider, BoxProps, styled } from '@mui/material'
import { ExtendedPaletteColors } from '@theme/colors'

type ChildProps = { children: React.ReactChild | React.ReactChild[] }

interface CardContainerProps extends BoxProps {
  borderColor?: string
}

export const CardContainer = styled(Box)<CardContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  border-left: 3px solid
    ${(props) =>
      props.borderColor || props.theme.palette['$color-background-dark']};
  cursor: pointer;
  background-color: white;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid
    ${(props) => props.theme.palette['$color-border-primary']};
`

export const CardContent = styled(Box)`
  gap: 17px;
  display: flex;
  flex-direction: column;
`

export const CardRow = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 24px;
  }
`

export const HighlightedSchedule = styled(Box)`
  padding-left: 8px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: ${ExtendedPaletteColors['$color-extended-yellow5']};
`

export const ContentIconText = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Separator = styled(Divider)`
  width: 100%;
`

export function TextTitle({ children }: ChildProps) {
  return (
    <Typography variant="$font-title-4" color="$color-text-primary">
      {children}
    </Typography>
  )
}

export function TextLabel({ children }: ChildProps) {
  return (
    <Typography variant="$font-body-base" color="$color-text-secondary">
      {children}
    </Typography>
  )
}

export function TextValue({ children }: ChildProps) {
  return (
    <Typography variant="$font-body-base" color="$color-text-primary">
      {children}
    </Typography>
  )
}

export function TextChip({ children }: ChildProps) {
  return (
    <Typography
      variant="$font-body-3"
      color="$color-action-text-secondary"
      lineHeight={2}
    >
      {children}
    </Typography>
  )
}
