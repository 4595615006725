import { MonitoringContext } from '../../context'
import SaveInMonitor from '../tab-save-in-monitor'
import { useContextSelector } from 'use-context-selector'
import RemoveFromMonitor from '../tab-remove-from-monitor'
import { MonitoringTab } from '../../types'

export function MonitoringTabsSelector(): JSX.Element {
  const selectedTab = useContextSelector(
    MonitoringContext,
    (s) => s.selectedTab
  )
  if (selectedTab === MonitoringTab.monitor) return <SaveInMonitor />
  if (selectedTab === MonitoringTab.unmonitor) return <RemoveFromMonitor />
  return <div>unselected panel</div>
}
