import IconRecoveryError from '@assets/icons/recovery-email-error.svg'
import IconRecoverySuccess from '@assets/icons/recovery-email-success.svg'
import IconCircleSolidWarning from '@assets/icons/circle-solid-warning.svg'

import { FeedbackProps } from '@components/organisms/Cards/Feedback/Feedback'

export default {
  mobile: {
    icon: IconCircleSolidWarning,
    title: 'Aplicação não disponível para esta dimensão de tela',
    text: 'Estamos trabalhando para melhorar a experiência de uso em dispositivos móveis e tablets. Para ter a melhor experiência possível, recomendamos que você acesse o Nomos em um desktop.',
  },
  'signup-success': {
    icon: IconRecoverySuccess,
    title: 'Enviamos um e-mail de confirmação para',
    subtitle: '{email}',
    text: 'Siga as instruções no e-mail para ativar sua conta',
    sx: {
      title: {
        color: '#202122',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
      },
      subtitle: {
        color: '#606162',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      text: {
        color: '#606162',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
  },
  'reset-success': {
    icon: IconRecoverySuccess,
    title: 'Enviamos um e-mail de recuperação para',
    subtitle: '{email}',
    text: 'Siga as instruções no e-mail para cadastrar sua nova senha',
    sx: {
      title: {
        color: '#202122',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
      },
      subtitle: {
        color: '#606162',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      text: {
        color: '#606162',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
  },
  'reset-error': {
    icon: IconRecoveryError,
    title: 'Email inválido',
    text: 'Parece que há algo de errado com o email informado!\nInforme um email válido ou entre em contato conosco',
    sx: {
      title: {
        color: '#202122',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
      },
      text: {
        color: '#606162',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
  },
} as Record<string, FeedbackProps>
