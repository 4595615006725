import { Pagination } from '@services/nomos_api/entities'
import { AlertEntity } from '@services/nomos_api/entities/alert'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { MonitorOnwerFilterType } from '@services/nomos_api/entities/monitor'
import HTTPClient from '../..'

export type AlertsResult = {
  pagination: Pagination
  results: AlertEntity[]
}

export type GetAllAlertsAPIProps = {
  page?: number
  limit?: number
  keyword?: string
  monitorSearchIds?: string[]
  monitorIds?: string[]
  buckets?: string[]
  periodFrom?: string
  periodTo?: string
  only?: MonitorOnwerFilterType[]
}

export const infinityAlertsAPI = async (pageParam: number) => {
  const { data } = await HTTPClient.get(`alerts?page=${pageParam}&only=owner`)
  return data as AlertsResult
}

export const getAllAlertsAPI = async ({
  page,
  limit,
  keyword,
  monitorSearchIds,
  monitorIds,
  buckets,
  periodFrom,
  periodTo,
  only,
}: GetAllAlertsAPIProps) => {
  const params = []
  if (page) {
    params.push(`page=${page || 1}`)
  }
  if (limit) {
    params.push(`limit=${limit || 10}`)
  }
  if (keyword) {
    params.push(`keyword=${keyword}`)
  }
  if (monitorSearchIds) {
    monitorSearchIds.forEach((monitorSearchId) =>
      params.push(`monitor_search_ids=${monitorSearchId}`)
    )
  }
  if (buckets) {
    buckets.forEach((bucket) => params.push(`buckets=${bucket}`))
  }
  if (periodFrom) {
    params.push(`periodFrom=${periodFrom}`)
  }
  if (periodTo) {
    params.push(`periodTo=${periodTo}`)
  }
  if (monitorIds) {
    monitorIds.forEach((monitorId) => params.push(`monitor_ids=${monitorId}`))
  }
  if (only && only.length > 0) {
    params.push(`only=${only.join(',')}`)
  }

  const { data } = await HTTPClient.get(`alerts?${params.join('&')}`)
  return data as AlertsResult | AlertEntity[]
}

export const useInfinityAlerts = () =>
  useInfiniteQuery(
    ['infinity-alerts'],
    ({ pageParam = 1 }) => infinityAlertsAPI(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        if (
          lastPage.pagination.total / lastPage.pagination.limit >
          pages.length
        ) {
          return pages.length + 1
        }

        return undefined
      },
    }
  )

export const useGetAllAlerts = (props: GetAllAlertsAPIProps) =>
  useQuery(['get-all-alerts', props], () => getAllAlertsAPI(props))
