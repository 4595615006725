import { AlertEntity } from '@services/nomos_api/entities/alert'
import { EventEntity } from '@services/nomos_api/entities/event'
import { createContext } from 'react'

type Monitor = {
  id: string
  count: {
    searches: number
    events: number
    propositions: {
      camara: number
      congresso: number
      senado: number
    }
    stakeholders: Record<string, number>
    organs: {
      senado: number
      camara: number
      executivo: number
    }
    favorites: {
      irs: number
      dou: number
      cvm: number
      bacen: number
      social: number
      notices: number
      speeches: number
      oficial_diary: number
      regulatory_agencies: number
    }
  }
}

export type DiscoveredSearch = {
  id: string
  searchId: string
  originId: string
  bucket: string
  content: string
  search: {
    name: string
    keyword: string
  }
  title: string
  createdAt: string
}

export type PropositionResult = {
  results: {
    id: string
    title: string
    summary: string
    situation: string
    theme: string
    author: string
    party: string
    uf: string
    proceedingDate: string
    organ: {
      id: string
      name: string
      initials: string
    }
  }[]
}

export type GeneralPanelContextType = {
  monitor: Monitor
  eventsLoading: boolean
  searchesLoading: boolean
  isLoadingMonitor: boolean
  propositionsLoading: boolean
  monitorNextEvents: EventEntity[]
  monitorDiscoveredSearches: AlertEntity[]
  monitorProceedingPropositions: PropositionResult
}

export const GeneralPanelContext = createContext<GeneralPanelContextType>(
  {} as GeneralPanelContextType
)
