import styled from 'styled-components'

export const SignUpForm = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ColumnElements = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 10px;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: start;
`

export const TermsBox = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`
