import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  gap: 24px;
  height: 100%;
  display: flex;
  overflow: auto;
  margin-top: 24px;
  position: relative;
  flex-direction: column;
  max-width: calc(100vw - 64px);
`
