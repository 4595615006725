import HTTPClient from '../..'
import { monitorEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'


export const savePropositionAPI = async (body) => {
  monitorEvent('Proposition')
  if(body.priority) {
    body.priority = parseInt(body.priority)
  }
  const { data } = await HTTPClient.post('/proposition', body)
  return data
}

export const useSaveProposition = () =>
  useMutation(['save-proposition'], savePropositionAPI)
