import { AuthPage } from '@components/templates'
import { AcceptUserContext } from './context'
import { Feedback } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import IconCircleSolidError from '@assets/icons/circle-solid-error.svg'
import IconCircleSolidSuccess from '@assets/icons/circle-solid-success.svg'

import { Container } from './styles'

export function AcceptUserView() {
  const isError = useContextSelector(AcceptUserContext, (s) => s.isError)
  const response = useContextSelector(AcceptUserContext, (s) => s.response)
  const isLoading = useContextSelector(AcceptUserContext, (s) => s.isLoading)

  return (
    <AuthPage>
      <Container>
        <Feedback
          icon={isError ? IconCircleSolidError : IconCircleSolidSuccess}
          title={response?.title || 'Aceitando convite'}
          text={response?.message || 'Verificando ...'}
          action={{
            path: '/',
            text: 'Retornar para aplicação',
          }}
          isLoading={isLoading}
        />
      </Container>
    </AuthPage>
  )
}
