import { forwardRef } from 'react'
import { InputPickMonitorView } from './InputPickMonitor.view'
import {
  InputPickMonitorProps,
  InputPickMonitorRef,
} from './InputPickMonitor.types'
import InputPickMonitorController from './InputPickMonitor.controller'

function InputPickMonitor(
  props: InputPickMonitorProps,
  ref: React.Ref<InputPickMonitorRef>
) {
  return (
    <InputPickMonitorController {...props} ref={ref}>
      <InputPickMonitorView {...props} />
    </InputPickMonitorController>
  )
}

export default forwardRef(InputPickMonitor)
