import _ from 'lodash'
import { Typography, Box } from '@mui/material'
import { HouseIcon } from '@components/icons'
import { Container } from './HouseInfo.styles'
import { OpenDataResourceEnum } from '@enums/OpenDataResourceEnum'

type HouseInfoProps = {
  text: string
  sx?: object
}

function HouseInfo({ text, sx }: HouseInfoProps) {
  return (
    <Container sx={sx} data-testid="house-info">
      <Box minWidth={21}>
        <HouseIcon />
      </Box>
      <Typography variant="$font-body-base" color="$color-text-secondary">
        {_.get(OpenDataResourceEnum, text, text)}
      </Typography>
    </Container>
  )
}

export default HouseInfo
