import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const getStakeholderAPI = async (id: string) => {
  const { data } = await HTTPClient.get(`stakeholders/${id}`)
  return data
}

export const useGetStakeholder = (id: string) =>
  useQuery(['get-stakeholder', id], () => getStakeholderAPI(id), {
    refetchOnWindowFocus: false,
  })
