import {
  Box,
  CardContent,
  Stack,
  Typography,
  styled as styledMaterial,
} from '@mui/material'
import theme from '@theme/colors'

export const Container = styledMaterial(Box)(() => ({
  backgroundColor: theme.brand.offwhite,
}))

export const Wrapper = styledMaterial(Stack)(() => ({
  margin: '0 auto',
  padding: '40px 0 48px 0',
  minHeight: '76vh',
  height: '100%',
  backgroundColor: theme.brand.offwhite,
}))

export const SearchBox = styledMaterial(Box)(() => ({
  width: '33%',
  padding: '42px 0',
}))

export const Loader = styledMaterial(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '40px 0',
}))

export const EmptyData = styledMaterial(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: '#858FA6',
  margin: 'auto 0',
}))

export const CardListItem = styledMaterial(CardContent)(() => ({
  padding: '0',

  '&.MuiCardContent-root': {
    paddingBottom: '0',
  },
}))

export const CardListTitle = styledMaterial(Typography)(() => ({
  width: '200px',
  fontSize: '14px',
  fontWeight: '500',
  marginBottom: '16px',
}))

export const CardListContent = styledMaterial(Typography)(() => ({
  width: '300px',
  lineBreak: 'anywhere',
  color: theme.brand.black,
}))
