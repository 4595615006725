import { Stack, styled } from '@mui/material'

export const WrapperInputEditStyle = styled(Stack)<{
  disabled: 'true' | 'false'
}>`
  background-color: ${(ctx) =>
    ctx.disabled === 'true' ? 'transparent' : '#f9f9f9'};
  padding: ${(ctx) => (ctx.disabled === 'true' ? '0px' : '8px')};
  border-radius: 4px;
  border-color: ${(ctx) => ctx.theme.palette['$color-border-tertiary']};
  border-style: solid;
  border-width: ${(ctx) => (ctx.disabled === 'true' ? '0px' : '1px')};
  & .edit-note-actions {
    padding-top: 8px;
    border-color: ${(ctx) => ctx.theme.palette['$color-border-primary']};
    border-style: solid;
    border-width: 1px 0px 0px 0px;
  }
`
