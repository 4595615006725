import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { EmptyState } from '@components/molecules'
import { Shimmer, TextLink } from '@components/atoms'
import { useGetOrganDocumentsCount } from '@services/nomos_api/resources/organs/documents'

import { Container, DocumentsList, Document } from './styles'

export default function Documents() {
  const { id } = useParams()
  const { data, isFetching } = useGetOrganDocumentsCount(id)

  const rows = data || []

  return (
    <Container>
      <Typography variant="$font-title-4" color="$color-text-primary">
        Documentos
      </Typography>

      <Shimmer height={250} width="100%" isLoading={isFetching}>
        {!isFetching && rows?.length === 0 && (
          <EmptyState data-cy="empty-state-docs" text="Sem documentos" />
        )}

        <DocumentsList>
          {rows.map((row, index) => (
            <Document data-cy={`documents-${index}`}>
              <Typography variant="$font-body-2" color="$color-text-primary">
                {row.name} ({row.count})
              </Typography>

              <TextLink
                href={`?tab=documents&type=${row.name}`}
                text="Ver detalhes"
              />
            </Document>
          ))}
        </DocumentsList>
      </Shimmer>
    </Container>
  )
}
