import { FilterSelectDropdown } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { DataFoundContext } from '../../context'
import { useMemo, useState } from 'react'
import { useGetAlltMonitors } from '@services/nomos_api/resources/monitor/list'
import { useDebounce } from '@hooks/useDebounce'

export function FilterMonitors(): JSX.Element | null {
  const displayMonitorFilter = useContextSelector(
    DataFoundContext,
    (s) => s.displayMonitorFilter
  )

  if (!displayMonitorFilter) return null

  const [filterMonitors, setFilterMonitors] = useState<string>('')
  const debouncedFilterMonitor = useDebounce(filterMonitors, 300)
  const { data, isLoading } = useGetAlltMonitors({
    q: debouncedFilterMonitor,
    type: 'mine',
  })

  const selectedMonitors = useContextSelector(
    DataFoundContext,
    (s) => s.selectedMonitors
  )

  const setSelectedMonitors = useContextSelector(
    DataFoundContext,
    (s) => s.setSelectedMonitors
  )

  const options = useMemo(
    () => data?.map?.((i) => ({ label: i.name, value: i.id })) ?? [],
    [data]
  )

  return (
    <FilterSelectDropdown
      data-cy="monitors"
      label="Painel"
      size="tiny"
      options={options}
      loading={isLoading}
      values={selectedMonitors}
      onFilter={setFilterMonitors}
      onChangeOptions={setSelectedMonitors}
    />
  )
}
