import HTTPClient from '../..'
import { createMonitorEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'


export const createMonitorAPI = async (body) => {
  createMonitorEvent()
  const { data } = await HTTPClient.post('/monitor', body)
  return data
}

export const createMonitorsAPI = async (body) => {
  createMonitorEvent()
  const { data } = await HTTPClient.post('/monitors', body)
  return data
}

export const useCreateMonitor = () =>
  useMutation(['create-monitor'], createMonitorAPI)
