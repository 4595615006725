import { CreateMonitorDrawerView } from './view'
import { CreateMonitorController } from './controller'
import { useByPassSubscription } from '@hooks/useByPassSubscription'

function CreateMonitor(props) {
  useByPassSubscription()
  return (
    <CreateMonitorController {...props}>
      <CreateMonitorDrawerView {...props} />
    </CreateMonitorController>
  )
}

export default CreateMonitor
