import _ from 'lodash'
import React from 'react'
import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingTag } from '@components/atoms'
import { AuthPage } from '@components/templates'
import { Feedback } from '@components/organisms/Cards'
import IconCircleSolidError from '@assets/icons/circle-solid-error.svg'
import { generatePaymentLink } from '@services/payment/resources/payment_link'

export default function SharedPaymentLinkPage() {
  const { token } = useParams<{ token: string }>()
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const handleError = (error: unknown) => {
    setError(_.get(error, 'response.data.message'))
  }

  const handleGeneratePaymentLink = () => {
    setLoading(true)
    setError(undefined)
    generatePaymentLink(token)
      .then((res) => window.location.replace(res.url))
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    handleGeneratePaymentLink()
  }, [])

  return (
    <AuthPage>
      <Stack>
        {isLoading && (
          <LoadingTag message="verificando token de pagamento..." />
        )}
        {error && (
          <Feedback icon={IconCircleSolidError} title="Erro" text={error} />
        )}
      </Stack>
    </AuthPage>
  )
}
