import { CreateNoteEntity } from '@services/nomos_api/entities/note'
import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'

export const createNoteApi = async (body: CreateNoteEntity) => {
  const { bucket, originId } = body
  const { data } = await HTTPClient.post(`/notes/${bucket}/${originId}`, body)
  return data
}

export const useCreateNote = () => useMutation(['create-note'], createNoteApi)
