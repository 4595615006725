export type ExportPropositionModalResourcesType = {
  camara: boolean
  senado: boolean
  congresso: boolean
}

export type ExportPropositionModalFormatsType = 'csv' | 'xlsx' | 'pdf'

export enum ExportPropositionModalFields {
  priority = 'Prioridade',
  positioning = 'Posicionamento',
  resource = 'Casa',
  type = 'Tipo',
  number = 'Nº',
  year = 'Ano',
  theme = 'Tema',
  subtheme = 'Subtema',
  summary = 'Ementa',
  author = 'Autor',
  author_party = 'Partido',
  author_uf = 'UF',
  progress_data = 'Progresso',
  progress_label = 'Último progresso',
  dispatch = 'Despachos',
  location = 'Localização Atual',
  situation = 'Situação',
  rapporteur = 'Relator',
  rapporteur_party = 'Partido',
  rapporteur_uf = 'UF',
  rapporteur_vote = 'Parecer do Relator',
  proceedings = 'Histórico Tramitação',
  next_steps = 'Próximos Passos',
  appreciation = 'Forma de Apreciação',
  attached_numbers = 'Nº de Apensados',
  attacheds = 'Apensados',
  url = 'Inteiro Teor',
  link = 'Link da Casa',
  tags = 'Tags',
}

export type ExportPropositionModalFieldKeys = Partial<
  Record<keyof typeof ExportPropositionModalFields, boolean>
>

export type ExportPropositionModalType = {
  monitorId: string
  monitorName: string
}
export type ExportPropositionModalContextType = {
  loading: boolean
  fields?: ExportPropositionModalFieldKeys
  resources?: ExportPropositionModalResourcesType
  format?: ExportPropositionModalFormatsType
  setFields: React.Dispatch<
    React.SetStateAction<ExportPropositionModalFieldKeys | undefined>
  >
  setResources: React.Dispatch<
    React.SetStateAction<ExportPropositionModalResourcesType | undefined>
  >
  setFormat: React.Dispatch<
    React.SetStateAction<ExportPropositionModalFormatsType | undefined>
  >
  handleRequestExport: () => void
}
export type ExportPropositionModalControllerType =
  ExportPropositionModalType & { children: JSX.Element }
