import EmptyState from '../EmptyState'
import { TextLink } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { StakeholdersIcon } from '@components/icons'
import { CircularProgress, Typography } from '@mui/material'
import { CardStakeholder } from '@components/organisms/Cards'
import { useGetAllStakeholders } from '@services/nomos_api/resources/stakeholders/stakeholders'

import { MonitorCard, CardHeader, CardTitle, CardContent } from '../styles'
import { StakeholdersContainer } from './styles'

export default function Stakeholders() {
  const navigate = useNavigate()

  const { data, isFetching } = useGetAllStakeholders({
    following: 'true',
  })

  const stakeholders = data || []

  const handleClick = () => navigate('/stakeholders')

  return (
    <MonitorCard type="bordored" height={540}>
      <CardHeader>
        <CardTitle>
          <StakeholdersIcon width={32} height={32} />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Stakeholders
          </Typography>
          {isFetching && <CircularProgress size={15} />}
        </CardTitle>

        <TextLink text="Ver todos" href="/stakeholders" />
      </CardHeader>

      {!isFetching && stakeholders?.length > 0 && (
        <CardHeader>
          <Typography variant="$font-body-base" color="$color-text-secondary">
            Stakeholders que você acompanha
          </Typography>

          <Typography variant="$font-title-1" color="$color-action-link">
            {stakeholders.length}
          </Typography>
        </CardHeader>
      )}

      {stakeholders.length > 0 && (
        <StakeholdersContainer>
          {stakeholders.map((stakeholder) => (
            <div key={stakeholder.id}>
              <CardStakeholder stakeholder={stakeholder} />
            </div>
          ))}
        </StakeholdersContainer>
      )}

      {!isFetching && stakeholders.length === 0 && (
        <CardContent>
          <EmptyState
            text="Os stakeholders que você acompanha serão exibidos aqui após monitorados"
            action="Pesquisar Stakeholders"
            onClick={handleClick}
          />
        </CardContent>
      )}
    </MonitorCard>
  )
}
