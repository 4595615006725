import { FeedbackView } from './view'
import { FeedbackController } from './controller'

export default function Feedback() {
  return (
    <FeedbackController>
      <FeedbackView />
    </FeedbackController>
  )
}
