import { StakeholdersContext } from './context'
import { useState, useMemo, useCallback } from 'react'

import {
  GetAllStakeholdersPaginationFilters,
  GetAllStakeholdersProps,
  useGetAllStakeholdersPagination,
} from '@services/nomos_api/resources/stakeholders/stakeholders'

export type StakeholdersControllerProps = {
  monitorId?: string
  children: React.ReactChild
}

function StakeholdersController({
  monitorId,
  children,
}: StakeholdersControllerProps) {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const [filters, setFilters] = useState<GetAllStakeholdersProps>({
    active: 'true',
  })

  const handleFilterChange = useCallback(
    (filter: GetAllStakeholdersPaginationFilters) => {
      setFilters((prevState) => ({
        ...prevState,
        ...filter,
      }))
    },
    []
  )

  const { data, isFetching, refetch } = useGetAllStakeholdersPagination({
    page,
    limit,
    monitor: monitorId,
    filters,
  })

  const handleRefetch = useCallback(() => {
    refetch()
  }, [])

  const store = useMemo(
    () => ({
      isLoading: isFetching,
      stakeholders: data,
      handleFilterChange,
      handleRefetch,
      setPage,
      setLimit,
    }),
    [isFetching, data, handleFilterChange, handleRefetch, setPage, setLimit]
  )

  return (
    <StakeholdersContext.Provider value={store}>
      {children}
    </StakeholdersContext.Provider>
  )
}

StakeholdersController.defaultProps = {
  monitorId: null,
}

export default StakeholdersController
