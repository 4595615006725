export const states = [
    { key: "AC", value: "Acre"},
    { key: "AL", value: "Alagoas"},
    { key: "AM", value: "Amazonas"},
    { key: "AP", value: "Amapá"},
    { key: "BA", value: "Bahia"},
    { key: "CE", value: "Ceará"},
    { key: "DF", value: "Distrito Federal"},
    { key: "ES", value: "Espírito Santo"},
    { key: "GO", value: "Goiás"},
    { key: "MA", value: "Maranhão"},
    { key: "MG", value: "Minas Gerais"},
    { key: "MS", value: "Mato Grosso do Sul"},
    { key: "MT", value: "Mato Grosso"},
    { key: "PA", value: "Pará"},
    { key: "PB", value: "Paraíba"},
    { key: "PE", value: "Pernambuco"},
    { key: "PI", value: "Piauí"},
    { key: "PR", value: "Paraná"},
    { key: "RJ", value: "Rio de Janeiro"},
    { key: "RN", value: "Rio Grande do Norte"},
    { key: "RO", value: "Rondônia"},
    { key: "RR", value: "Roraima"},
    { key: "RS", value: "Rio Grande do Sul"},
    { key: "SC", value: "Santa Catarina"},
    { key: "SE", value: "Sergipe"},
    { key: "SP", value: "São Paulo"},
    { key: "TO", value: "Tocantins"},
    ]
    
export const fetchEstadoOptions = async () => states