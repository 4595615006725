/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { theme } from '@theme/index'
import 'react-phone-number-input/style.css'
import { InputPhoneProps } from './types'
import { Stack, Typography } from '@mui/material'
import { CountrySelect } from './components/select'
import { CountryCode } from 'libphonenumber-js/types'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import {
  CountryCodeLabel,
  InputLegend,
  InputText,
  InputFieldset,
  InputWrapper,
} from './styles'
import { sizeconfig } from './config'

export default function InputPhone({
  value: inputValue,
  disabled,
  label,
  error,
  placeholder,
  onChange,
  onBlur,
  name,
  id,
  size = 'medium',
  ...rest
}: InputPhoneProps): JSX.Element {
  const inputRef = React.useRef<any>()

  const [value, setValue] = React.useState<string>(inputValue)
  const [country, setCountry] = React.useState<CountryCode | undefined>('BR')

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      <InputFieldset
        size={size}
        disabled={disabled}
        error={String(Boolean(error)) as 'true' | 'false'}
      >
        <InputLegend
          className="phone-input-legend"
          error={String(Boolean(error)) as 'true' | 'false'}
        >
          <Typography
            variant="caption"
            fontSize={sizeconfig[size].legendFontSize}
            color={error ? theme.palette.error.main : 'inherit'}
          >
            {label}
          </Typography>
        </InputLegend>
        <InputWrapper htmlFor="input-phone">
          <CountrySelect value={country} onValue={setCountry} />
          <CountryCodeLabel>
            <Typography
              variant="caption"
              fontSize={sizeconfig[size].inputFontSize}
            >
              +{getCountryCallingCode(country || 'BR')}
            </Typography>
          </CountryCodeLabel>
          <InputText
            name={name}
            size={size}
            id={id || 'input-phone'}
            data-cy={id || 'input-phone'}
            ref={inputRef}
            placeholder={placeholder}
            type="tel"
            disabled={disabled}
            country={country}
            value={value}
            international={false}
            withCountryCallingCode={false}
            onChange={(value) => setValue(String(value))}
            onBlur={onBlur}
            {...rest}
          />
        </InputWrapper>
      </InputFieldset>
      {error && (
        <Typography
          data-cy={`${id}-error-msg`}
          variant="caption"
          color="error.main"
          fontSize={12}
          py={0.5}
        >
          {error}
        </Typography>
      )}
    </Stack>
  )
}
