import { VotePositioningKeys } from '@enums/VotePositioningEnum'
import { SavedPropositionEntity } from './saved_proposition'
import { OrganEntity } from '@entities/OrganEntity'
import { HighlightEntity } from './highlight'

export interface Organ {
  id: string
  initials: string
  name: string
  openDataId: string
  openDataResource: string
  type: string
}

export interface Parliamentarian {
  openDataId: string
  name: string
  photoUrl: string
  acronymParty: string
  uf: string
  stakeholderId: string
}

export type Proceeding = {
  openDataId: string
  openDataSituationId?: string
  date: string
  organ: OrganEntity | string
  description: string
  situation: string
  regime: string
  dispatch: string
  url?: string
}

export type PropositionEntity = {
  _id: string
  id: string
  openDataId: string
  openDataResource: string
  title: string
  situation: string
  theme: string
  authors: Parliamentarian[]
  rapporteurs?: Parliamentarian[]
  party: string
  uf: string
  proceedingDate: string
  organ: string | Organ
  type: string
  summary?: string
  lastProceeding?: Proceeding
  savedPropositions?: SavedPropositionEntity[]
  savedProposition?: SavedPropositionEntity
  highlights?: HighlightEntity[]
}

export type Proposition = {
  title: string
  number: number
  openDataId: string
  openDataTypeId: string
  summary: string
  type: string
  year: string
}

export type PropositionAttached = {
  openDataId: string
  openDataResource: string
  summary: string
  title: string
}

export type PropositionVoteEntity = {
  approved: boolean
  house: string | null
  id: string
  date: string
  isCurrentOrgan: boolean
  isNominalVoting: boolean
  openDataId: string
  openDataResource: string
  organ: Organ
  organName: string | null
  rapporteurVoteUrl: string | null
  proposition: Proposition
}

export interface Vote {
  positioningType: VotePositioningKeys
  positioning: string
  date: string
  parliamentarian: Parliamentarian
}

export interface Guideline {
  positioning: string
  acronymParty: string
}

export interface Positioning {
  positive: number
  negative: number
  total: number
}

export interface PropositionVotingEntity {
  approved: string | boolean
  openDataResource: string
  openDataId: string
  date: string
  description: string
  organ: string
  votes: Vote[]
  guidelines: Guideline[]
  rapporteurVoteUrl: string
  organName: string
  title: string
  summary: string
  positioning: Positioning
  proposition: Proposition
}

type PropositionProcessingLegendType = {
  false: { text: string; color: string }
  true: { text: string; color: string }
}

export const PropositionProcessingLegend: PropositionProcessingLegendType = {
  false: {
    text: 'Não Tramitando',
    color: '#E87070',
  },
  true: {
    text: 'Tramitando',
    color: '#0BB07C',
  },
}
