import { useState } from 'react'
import { useModal } from '@contexts/Modal'
import { DatabaseIcon } from '@components/icons'
import { Stack, Typography } from '@mui/material'
import { Button, Card, Input } from '@components/atoms'
import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { Container, Content, Footer, Option, Options } from './styles'

export type RemoveMonitorModalProps = {
  onConfirm: (databases: string[]) => void
}

export default function RemoveMonitorModal({
  onConfirm,
}: RemoveMonitorModalProps) {
  useByPassSubscription()
  const { closeModal } = useModal()
  const defaultOptions = [
    'all',
    'propositions',
    'events',
    'organs',
    'stakeholders',
    'saved_searches',
  ]
  const [loading, setLoading] = useState(false)
  const [databases, setDatabases] = useState(defaultOptions)

  const handleClone = async () => {
    if (onConfirm) {
      setLoading(true)
      await onConfirm(databases)
    }

    setLoading(false)
    closeModal()
  }

  const handleClickOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const database = event.target.value

    if (databases.indexOf(database) !== -1) {
      setDatabases(databases.filter((e) => e !== database))
      if (database === 'all') {
        setDatabases([])
      }
    } else {
      setDatabases([...databases, database])
      if (database === 'all') {
        setDatabases(defaultOptions)
      }
    }
  }

  return (
    <Container>
      <Content>
        <Typography variant="$font-title-3" color="$color-text-primary">
          Clonar Painel?
        </Typography>

        <Typography
          variant="$font-body-base"
          color="$color-text-scundary"
          maxWidth={436}
        >
          Ao clonar o painel você poderá editá-lo de acordo com suas
          preferências de monitoramento
        </Typography>

        <Typography variant="$font-body-2" color="$color-text-scundary">
          Selecione os dados que deseja manter no painel ao cloná-lo?
        </Typography>

        <Card type="bordored" width="100%">
          <Options>
            <Option>
              <DatabaseIcon />
              <Typography variant="$font-body-2" color="$color-text-scundary">
                Dados monitorados
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="all"
                checked={databases.indexOf('all') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Todos
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="propositions"
                checked={databases.indexOf('propositions') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Proposições
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="events"
                checked={databases.indexOf('events') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Eventos
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="stakeholders"
                checked={databases.indexOf('stakeholders') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Stakeholders
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="saved_searches"
                checked={databases.indexOf('saved_searches') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Pesquisas Salvas
              </Typography>
            </Option>

            <Option>
              <Input
                type="checkbox"
                value="organs"
                checked={databases.indexOf('organs') !== -1}
                onClick={handleClickOption}
              />
              <Typography
                variant="$font-body-base"
                color="$color-text-primary"
                maxWidth={436}
              >
                Orgãos
              </Typography>
            </Option>
          </Options>
        </Card>
      </Content>

      <Footer>
        <Stack direction="row" spacing={2}>
          <Button text="Cancelar" variant="outlined" onClick={closeModal} />

          <Button
            id="delete-panel-confirm-btn"
            text="Clonar"
            loading={loading}
            onClick={() => handleClone()}
          />
        </Stack>
      </Footer>
    </Container>
  )
}
