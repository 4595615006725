/* eslint-disable no-unused-vars */
import CompanyInfoModaView from './view'
import CompanyInfoModalController from './controller'

function CompanyInfoModal() {
  return (
    <CompanyInfoModalController>
      <CompanyInfoModaView />
    </CompanyInfoModalController>
  )
}

export default CompanyInfoModal
