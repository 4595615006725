import { Stack } from '@mui/material'
import { SearchResultsContext } from './context'
import { SidePage } from '@components/templates'
import { useContextSelector } from 'use-context-selector'
import SideFilterContainerComponent from './containers/SideFilterContainer/SideFilterContainer'


import {
  FormFiltersComponent,
  EventsHeaderComponent,
  FormLoadingComponent,
  FormSubtitlesComponent,
  FormPaginationComponent,
  FormBreadcrumbComponent,
  SelectedBucketsComponent,
  FormListResultsComponent,
  FormCountResultsComponent,
  FormInputKeywordComponent,
  FormExportComponent,
} from './containers'

export function SearchResultsView() {
  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const eventsMode = useContextSelector(
    SearchResultsContext,
    (s) => s.eventsMode
  )

  const filterVisible = useContextSelector(
    SearchResultsContext,
    (s) => s.filterVisible
  )

  const selected = useContextSelector(
    SearchResultsContext,
    (s) => s.selected
  )

  const isCalendarMode = selectedBucket === 'events' && eventsMode !== 'Lista'

  return (
    <SidePage
      id="search-results-page"
      leftVisible={filterVisible}
      sideComponent={<SideFilterContainerComponent />}
      headComponent={<FormInputKeywordComponent />}
      breadcrumbComponent={<FormBreadcrumbComponent/>}
    >
      <SelectedBucketsComponent />
      {selectedBucket === 'events' && <FormLoadingComponent /> }
      {selectedBucket === 'events' && <EventsHeaderComponent /> }
      {selectedBucket !== 'events' && <FormFiltersComponent /> }
      <FormSubtitlesComponent />
      {selectedBucket !== 'events' && <FormCountResultsComponent />}
      {selectedBucket === 'propositions' && selected.size > 0 && <FormExportComponent />}
      <FormLoadingComponent />
      <Stack direction="column" gap={3}>
        <FormListResultsComponent />
      </Stack>
      {!isCalendarMode && <FormPaginationComponent />}
    </SidePage>
  )
}
