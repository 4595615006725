import { Typography } from '@mui/material'
import { useDrawer } from '@contexts/Drawer'
import { TextLink } from '@components/atoms'
import AllMessages from '@containers/AllMessages'
import { BellNotificationIcon } from '@components/icons'
import Notification from '@containers/SharedDrawers/Notification'

import { MonitorCard, CardHeader, CardTitle } from '../styles'
import { NotificationsContainer } from './styles'

export default function Notifications() {
  const { openDrawer } = useDrawer()

  const handleClickNotifications = () => {
    openDrawer(<Notification />)
  }

  return (
    <MonitorCard type="bordored" height={540}>
      <CardHeader>
        <CardTitle>
          <BellNotificationIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Notificações
          </Typography>
        </CardTitle>

        <TextLink text="Ver todas" onClick={handleClickNotifications} />
      </CardHeader>

      <NotificationsContainer>
        <AllMessages />
      </NotificationsContainer>
    </MonitorCard>
  )
}
