/* eslint-disable no-inline-styles/no-inline-styles */
import { useCallback, useMemo, useState } from 'react'
import { Page } from '@containers/AppBar/AppBar'
import { SizedComponent } from '@components/atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  HeaderTab,
  HeaderTabs,
  HeaderTabOuter,
  HeaderTabsWrapper,
  Wrapper,
  LinkNav,
} from './DesktopMenu.styles'
import { useRefWidth } from '@hooks/useRefWidth'
import { ArrowDropDown } from '@mui/icons-material'
import { Menu, MenuItem, Stack, Typography } from '@mui/material'

type Props = { pages: Page[] }

export function DesktopMenu({ pages }: Props): JSX.Element {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [dimensions, setRef] = useRefWidth()
  const [width, setWidth] = useState<number>(0)
  const [anchorElNav, setAnchorElNav] = useState<Element | null | undefined>()

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleChange = (newPageIndex: number) => {
    handleCloseNavMenu()
    if (pages && pages.length > 0) {
      navigate(`/${pages[newPageIndex].path}`)
    }
  }

  // recalc visible menus for each
  // menu item width vs entire menu width
  const visibleMenu = useMemo(() => {
    let total = 0
    return Object.values(dimensions).map((dimensionWidth, index) => {
      total += dimensionWidth
      if (total > width - (dimensions[pages.length] || 0)) {
        if (index === pages.length) return true
        return false
      }
      return true
    })
  }, [pages, dimensions, width])

  // identify visually menu
  // selection based on item pathname
  const pageIndex = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, el] of pages.entries()) {
      if (pathname.indexOf(el.path) !== -1) {
        return index
      }
    }
    return undefined
  }, [pathname, pages])

  const isVisible = useCallback(
    (index: number) => {
      // return visible as true if menu is the latest (see more >)
      // and one of the firsts menu should not be visible
      if (index === visibleMenu.length - 1) {
        return visibleMenu
          .slice(0, -1)
          .some((i) => i === undefined || i === false)
          ? 'true'
          : 'false'
      }
      // return visible as default if ref to menu index is undefined
      if (visibleMenu[index] === undefined) return 'true'
      // return true/false if menu index should be visible or not
      return visibleMenu[index] === true ? 'true' : 'false'
    },
    [visibleMenu]
  )

  return (
    <>
      <Wrapper>
        <SizedComponent onChange={({ width }) => setWidth(width)} fullwidth>
          <HeaderTabsWrapper>
            <HeaderTabs value={pageIndex}>
              {pages.map((page, index) => (
                <HeaderTabOuter
                  key={page.path}
                  ref={(element) => setRef(element, index)}
                  visible={isVisible(index)}
                >
                  <HeaderTab
                    data-testid={`tab-${index}`}
                    value={index}
                    label={page.name}
                    onClick={() => handleChange(index)}
                  />
                </HeaderTabOuter>
              ))}
              <HeaderTabOuter
                visible={isVisible(pages.length)}
                ref={(element) => setRef(element, pages.length)}
              >
                <HeaderTab
                  data-testid="tab-more"
                  label={
                    <Stack direction="row" spacing={0} alignItems="center">
                      <Typography
                        variant="$font-body-4"
                        color="$color-text-primary"
                      >
                        Ver mais
                      </Typography>
                      <ArrowDropDown color="primary" />
                    </Stack>
                  }
                  onClick={handleOpenNavMenu}
                />
              </HeaderTabOuter>
            </HeaderTabs>
          </HeaderTabsWrapper>
        </SizedComponent>
      </Wrapper>
      <Menu
        data-testid="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{ mt: -1.5 }}
      >
        {pages?.map((page, index) => (
          <MenuItem
            key={page.path}
            data-testid={`tab-dropdown-${index}`}
            onClick={() => handleChange(index)}
            sx={{
              display: visibleMenu?.[index] === false ? 'flex' : 'none',
              minWidth: '150px',
            }}
          >
            <LinkNav
              data-testid={`menu-tab-${index}`}
              active={String(pageIndex === index) as 'true' | 'false'}
            >
              <Typography variant="$font-body-2" color="$color-text-primary">
                {page.name}
              </Typography>
            </LinkNav>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
