import { useContextSelector } from 'use-context-selector'
import { SideFilterSelectContext } from './SideFilterSelectContext'
import { FilterAccordionInput } from './SideFilterSelect.styles'
import { Input } from '@components/atoms'

export function SideFilterSelectInputSearch(): JSX.Element {
  const filter = useContextSelector(SideFilterSelectContext, (s) => s.filter)
  const textFilter = useContextSelector(
    SideFilterSelectContext,
    (s) => s.textFilter
  )
  const setTextFilter = useContextSelector(
    SideFilterSelectContext,
    (s) => s.setTextFilter
  )
  if (!filter.enabled_input) return <div />
  return (
    <FilterAccordionInput>
      <Input
        fullWidth
        type="search"
        size="tiny"
        label="Pesquisar"
        value={textFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTextFilter(e.target.value)
        }}
      />
    </FilterAccordionInput>
  )
}
