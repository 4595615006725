import { Typography } from '@mui/material'

export function TextError({ value }: { value: string }) {
  if (!value) return <div />
  return (
    <Typography variant="caption" color="error">
      {value}
    </Typography>
  )
}
