import { Button } from '@components/atoms'
import { ErrorLabel } from '@components/atoms/ErrorLabel'
import InputPinCode from '@components/atoms/InputPinCode'
import { TextTimeOut } from '@components/atoms/TextTimeOut/TimeOut'
import { ModalStandard } from '@components/molecules'
import { useModal } from '@contexts/Modal'
import { useSnackbar } from '@contexts/Snackbar'
import { Stack, Typography } from '@mui/material'
import { User } from '@services/nomos_api/entities/user'
import { updateConfirmUser } from '@services/nomos_api/resources/user/update'
import { FormConfirmTokenType } from '@services/nomos_api/types/form_confirm_token_type'
import { TokenResponseType } from '@services/nomos_api/types/token_response_type'
import colors from '@theme/colors'
import { useFormik } from 'formik'

type ConfirmPhoneModalProps = {
  token: TokenResponseType
  onUpdatedUser: (user: User) => void
}

export function ConfirmPhoneModal({
  token,
  onUpdatedUser,
}: ConfirmPhoneModalProps): JSX.Element {
  const { closeModal } = useModal()
  const { showSnackbarError } = useSnackbar()
  const LENGTH = 5

  const formik = useFormik<FormConfirmTokenType>({
    initialValues: {
      request_id: token.request_id,
      request_code: '',
    },
    onSubmit: (payload) => {
      updateConfirmUser(payload)
        .then(onUpdatedUser)
        .catch((error) => {
          showSnackbarError(
            error.response.data.title,
            error.response.data.message
          )
          formik.setErrors(error?.response?.data?.errors)
        })
        .finally(() => formik.setSubmitting(false))
    },
  })

  return (
    <ModalStandard title="Código de verificação" closeable maxWidth={400}>
      <Stack direction="column" spacing={3}>
        <Typography variant="$font-body-base">
          Você deve receber um código de verificação no seu{' '}
          <strong>whatsapp</strong>, após confirma-lo, o telefone da sua conta
          será alterado.
        </Typography>
        <Stack direction="column" alignItems="center">
          <InputPinCode
            id="input-pin"
            value={formik.values.request_code}
            length={LENGTH}
            onChange={(value) => formik.setFieldValue('request_code', value)}
          />
          <ErrorLabel value={formik.errors.request_code} />
        </Stack>

        <Stack alignItems="center">
          <TextTimeOut
            expireTime={token.exp}
            countDownLabelTemplate="Sua sessão expira em {{time}}"
            timedOutLabelTemplate="Sua sessão expirou"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-end"
          pt={3}
          sx={{
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: colors['$color-background-alternative-light'],
          }}
        >
          <Button
            variant="text"
            text="Cancelar"
            disabled={formik.isSubmitting}
            onClick={closeModal}
          />
          <Button
            variant="contained"
            text="Confirmar"
            loading={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
