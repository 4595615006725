import { NoteEntity } from '@services/nomos_api/entities/note'
import HTTPClient from '../..'
import { useMutation } from '@tanstack/react-query'

export type UpdateNoteEntity = Partial<Pick<NoteEntity, 'public' | 'note'>>

export const updateNoteApi = async (
  id: string,
  body: UpdateNoteEntity
): Promise<NoteEntity> => {
  const { data } = await HTTPClient.put(`/notes/${id}`, body)
  return data
}

export const useUpdateNote = (id: string, body: UpdateNoteEntity) =>
  useMutation(['update-note'], () => updateNoteApi(id, body))
