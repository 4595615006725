import { AppBar } from './styles'
import { Stack } from '@mui/material'
import { MonitorProduct, Nomos } from '@components/icons'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'
import { useAuth } from '@contexts/Auth'

type Props = {
  showWhatsAppContact?: boolean
}

export function AppBarBlank({ showWhatsAppContact = false }: Props) {
  const { isLoadingSession } = useAuth()
  return (
    <AppBar type="bordored" height={80} data-testid="root-authpage">
      <Stack
        width="88%"
        margin="0 auto"
        direction="row"
        alignItems="center"
        justifyContent={showWhatsAppContact ? 'space-between' : 'center'}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <MonitorProduct width={28} height={28} />
          <Nomos />
        </Stack>
        {showWhatsAppContact && (
          <div>
            {isLoadingSession ? (
              <div />
            ) : (
              <WhatsAppPaymentLink
                text="Teste Gratuitamente"
                title="Teste Gratuitamente"
              />
            )}
          </div>
        )}
      </Stack>
    </AppBar>
  )
}
