import { StakeholderDetailContext } from '../../context'
import { useContextSelector } from 'use-context-selector'
import { CardSummarized } from '@components/organisms/Cards'
import { useStakeholderRollCallVotes } from '@services/nomos_api/resources/stakeholders/rollCallVotes'

export default function RollCallVotes() {
  const id = useContextSelector(StakeholderDetailContext, (s) => s.id)

  const { data, isFetching } = useStakeholderRollCallVotes(id || '')

  return (
    <CardSummarized
      title="Votos nominais"
      isLoading={isFetching}
      values={[
        {
          count: data?.plenary || 0,
          label: 'Plenário',
        },
        {
          count: data?.comission || 0,
          label: 'Comissões',
        },
      ]}
    />
  )
}
