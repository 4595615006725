import { FeedbackContext } from './context'
import { AuthPage } from '@components/templates'
import { Feedback } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import { FeedbackWrapper, FeedbackContainer } from './styles'
import { FeedbackPromptMessage } from './containers/FeedbackPromptMessage'

export function FeedbackView() {
  const data = useContextSelector(FeedbackContext, (s) => s.data)
  const type = useContextSelector(FeedbackContext, (s) => s.type)

  return (
    <AuthPage>
      <FeedbackWrapper>
        <FeedbackContainer>
          <Feedback {...data} />
        </FeedbackContainer>
        <FeedbackPromptMessage type={type || 'default'} />
      </FeedbackWrapper>
    </AuthPage>
  )
}
