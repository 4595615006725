import { ContentPaths } from './styles'
import { useAuth } from '@contexts/Auth'
import { NavLink } from 'react-router-dom'
import { useDrawer } from '@contexts/Drawer'
import { isMobile } from 'react-device-detect'
import NotesIcon from '@assets/icons/notes.svg'
import { Stack, Typography } from '@mui/material'
import { BucketsEnum } from '@utils/buckets/type'
import { Button, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { OficialDiaryDetailsContext } from '../../context'
import { ArrowForwardIos, Print } from '@mui/icons-material'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'
import { getSortedBucket } from '@utils/functions/buckets/getSortedBucket'

function HeaderComponent() {
  const { auth } = useAuth()
  const { openDrawer } = useDrawer()
  const dou = useContextSelector(OficialDiaryDetailsContext, (s) => s.dou)
  const keyword = useContextSelector(
    OficialDiaryDetailsContext,
    (s) => s.keyword
  )
  const buckets = useContextSelector(
    OficialDiaryDetailsContext,
    (s) => s.buckets
  )
  const isLoading = useContextSelector(
    OficialDiaryDetailsContext,
    (s) => s.isLoading
  )

  const params = `?buckets=${getSortedBucket(
    buckets,
    'dou'
  )}&keyword=${keyword}`

  const title = `Diário Oficial do ${
    dou?.type === 'state' ? 'Estado' : 'Município'
  } de ${dou?.origin?.toUpperCase()}`

  return (
    <Stack direction="column" className="notPrintable" spacing={2}>
      {auth && (
        <ContentPaths direction="row" alignItems="center" spacing={1}>
          <NavLink to={isMobile ? '/search-results' : '/home'}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Inicio
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <NavLink to={`/search-results${params}`}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              Diário Oficial
            </Typography>
          </NavLink>
          <ArrowForwardIos sx={{ fontSize: '12px', color: '#B2BBBF' }} />
          <ShimmerText isLoading={isLoading} height={18} words={4}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {title}
            </Typography>
          </ShimmerText>
        </ContentPaths>
      )}

      <ShimmerText isLoading={isLoading} height={38} words={5}>
        <Typography variant="$font-title-2" color="$color-text-primary">
          {title}
        </Typography>
      </ShimmerText>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          text="Imprimir"
          startIcon={<Print />}
          onClick={() => window.print()}
        />

        <Button
          text="Notas"
          color="$color-extended-red1"
          variant="contained"
          endIcon={<img src={NotesIcon} alt="Notas" />}
          onClick={() => {
            openDrawer(
              <NoteManagerDrawer
                id={dou?.id as unknown as string}
                bucket={BucketsEnum.oficial_diary}
              />
            )
          }}
        />

        {/* <Button
          variant="text"
          href={dou?.file}
          target="_blank"
          rel="noreferrer"
          text="Acessar página Diário Oficial"
          startIcon={<LaunchOutlined />}
        /> */}
      </Stack>
    </Stack>
  )
}

export default HeaderComponent
