import { db } from '../../'
import {
  ref,
  query,
  get,
  endBefore,
  orderByKey,
  limitToLast,
} from 'firebase/database'
import { AlertEntity } from '@services/realtime_db/entities/alert'
import { AlertEventType } from '@services/realtime_db/types'

export const listAlertsPaginated = (
  userId: string,
  page?: string
): Promise<AlertEventType> => {
  const limit = 10
  const collection = ref(db, `alerts/${userId}`)
  const lastnotificationsRef = page
    ? query(collection, orderByKey(), endBefore(page), limitToLast(limit))
    : query(collection, orderByKey(), limitToLast(limit))
  return new Promise((resolve, reject) => {
    get(lastnotificationsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val() as Record<string, AlertEntity>
          const alerts = Object.values(data).reverse()
          const nextPage = Object.keys(data)?.[0]
          resolve({ alerts, nextPage })
        } else {
          resolve({ alerts: [], nextPage: page })
        }
      })
      .catch((error) => reject(error))
  })
}
