/* eslint-disable no-unreachable-loop */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable no-else-return */
import moment from 'moment-timezone'
import {
  HighlightEntity,
  HighlightText,
} from '@services/nomos_api/entities/highlight'
import { isSameDay } from 'date-fns'
import colors from '@theme/colors'
import _ from 'lodash'

// Moment
export function formatDate(
  date: string | number | undefined | null,
  defaultValue = '--'
) {
  if (!date) return defaultValue
  const momentDate = moment.utc(
    typeof date === 'number' ? new Date(date).toISOString() : date,
    moment.ISO_8601
  )
  if (!momentDate.isValid()) return defaultValue
  return momentDate.locale('pt-br').format('DD/MM/YYYY')
}

export function formatDateTime(
  date: string | number | undefined | null,
  defaultValue = '--'
) {
  if (!date) return defaultValue
  const momentDate = moment.utc(
    typeof date === 'number' ? new Date(date).toISOString() : date,
    moment.ISO_8601
  )
  if (!momentDate.isValid()) return defaultValue
  return momentDate.format('DD/MM/YYYY [às] HH:mm')
}

export function formatTime(
  date: string | number | undefined | null,
  defaultValue = '--'
) {
  if (!date) return defaultValue
  const momentDate = moment.utc(
    typeof date === 'number' ? new Date(date).toISOString() : date,
    moment.ISO_8601
  )
  if (!momentDate.isValid()) return defaultValue
  return momentDate.locale('pt-br').format('LT')
}

export function formatTimeLight(
  date: string | number | undefined | null,
  defaultValue = '--'
) {
  if (!date) return defaultValue
  const momentDate = moment(new Date(date).toISOString())
  if (!momentDate.isValid()) return defaultValue
  return momentDate.locale('pt-br').format('HH:mm')
}

export const formatHighlights = (highlights?: HighlightEntity[]) => {
  return highlights && highlights.length > 0
    ? highlights
        .map((h) => `${h.texts[0].value} **${h.texts[1].value}** ...`)
        .join(' | ')
    : 'Sem match com as palavras chaves buscadas'
}

export function formatPastDate(inputDate?: Date): string {
  if (!inputDate || inputDate instanceof Date !== true) return '--'

  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  const timeDifference = today.getTime() - inputDate.getTime()
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))

  if (isSameDay(inputDate, today)) {
    return `Hoje às ${formatTimeLight(inputDate.toISOString())}`
  } else if (isSameDay(inputDate, yesterday)) {
    return `Ontem às ${formatTimeLight(inputDate.toISOString())}`
  } else if (daysDifference > 0 && daysDifference <= 28) {
    return `${daysDifference} dia${
      daysDifference > 1 ? 's' : ''
    } atrás às ${formatTimeLight(inputDate.toISOString())}`
  } else {
    return formatDateTime(inputDate.toISOString())
  }
}

export function calculateRemainingTime(
  startTime: number,
  endTime: number
): string {
  const diff = endTime - startTime
  const hours = Math.floor(diff / 3600000)
  const minutes = Math.floor((diff % 3600000) / 60000)
  const seconds = Math.floor((diff % 60000) / 1000)

  const timeComponents = []

  if (hours > 1) timeComponents.push(`${hours} horas`)
  if (minutes > 1) timeComponents.push(`${minutes} minutos`)
  if (hours <= 0 && minutes <= 0) timeComponents.push(`${seconds} segundos`)

  return timeComponents.join(' e ')
}

export function formatterHighlight(
  data: Record<string, any>,
  paths: string[],
  defaultValue?: string
): JSX.Element[] | string | number | null | undefined {
  // get data highlights property
  const highlights = _.get(data, 'highlights', []) as HighlightEntity[]
  // try to fing highlight by paths priority order
  // if first path does not contains higlight,
  // then find it on next path
  const hilightTexts = highlights.reduce((grouped, i) => {
    for (const path of paths) if (i.path === path) grouped.push(...i.texts)
    return grouped
  }, [] as HighlightText[])

  // return defualts value if highlights does not have contents
  if (hilightTexts.length === 0) {
    for (const path of paths) if (_.has(data, path)) return _.get(data, path)
    return defaultValue
  }

  // build content based on founded highlights
  return hilightTexts.map((text) => {
    if (text.type === 'text') return <span>{text.value}</span>
    else
      return (
        <b
          style={{
            borderRadius: 3,
            paddingLeft: 3,
            paddingRight: 3,
            color: colors['$color-text-primary'],
            backgroundColor: colors['$color-extended-yellow2'],
          }}
        >
          {text.value}
        </b>
      )
  })
}
