import { useState } from 'react'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material/'
import {
  CreateOutlined,
  DeleteOutlineOutlined,
  MoreVert,
} from '@mui/icons-material'
import theme from '@theme/colors'

export type VerticalActionsProps = {
  actionEdit: () => void
  actionRemove: () => void
}

export default function VerticalActions({
  actionEdit,
  actionRemove,
}: VerticalActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleActionEdit = () => {
    if (actionEdit) {
      actionEdit()
    }

    handleClose()
  }

  const handleActionRemove = () => {
    if (actionRemove) {
      actionRemove()
    }

    handleClose()
  }

  return (
    <Box>
      <IconButton
        data-testid="icon-action-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert sx={{ color: theme.brand.black }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '198px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)',
          },
        }}
      >
        <MenuItem onClick={handleActionEdit}>
          <ListItemIcon>
            <CreateOutlined fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleActionRemove}>
          <ListItemIcon>
            <DeleteOutlineOutlined fontSize="medium" color="error" />
          </ListItemIcon>
          <ListItemText color="text.secondary">Remover</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}
