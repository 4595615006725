import { SavedSearchView } from './view'
import { SavedSearchController } from './controller'
import { SaveSearchEntity } from '@services/nomos_api/entities/saved_searches'

export type SavedSearchProps = {
  search: SaveSearchEntity
}

function SavedSearch(props: SavedSearchProps) {
  return (
    <SavedSearchController {...props}>
      <SavedSearchView />
    </SavedSearchController>
  )
}

export default SavedSearch
