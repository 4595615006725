/* eslint-disable no-nested-ternary */
import { useMemo, useState } from 'react'
import { MonitoringContext } from './context'
import {
  MonitoringControllerProps,
  MonitoringTab,
  MonitoringTabKeys,
} from './types'

function MonitoringController({
  id,
  title,
  house,
  origin,
  isSaved,
  children,
  onFinish,
  onRemove,
}: MonitoringControllerProps) {
  // define statesconsole.log('id', id)
  const [selectedTab, setSelectedTab] = useState<MonitoringTabKeys>(
    id
      ? id.split(',').length > 1
        ? MonitoringTab.alerta
        : MonitoringTab.monitor
      : MonitoringTab.monitor
  )

  // expose shared states
  const store = useMemo(
    () => ({
      id,
      title,
      house,
      origin,
      isSaved,
      selectedTab,
      onFinish,
      onRemove,
      setSelectedTab,
    }),
    [
      id,
      title,
      origin,
      house,
      isSaved,
      selectedTab,
      onFinish,
      onRemove,
      setSelectedTab,
    ]
  )

  return (
    <MonitoringContext.Provider value={store}>
      {children}
    </MonitoringContext.Provider>
  )
}

MonitoringController.defaultProps = {
  onFinish: null,
}

export default MonitoringController
