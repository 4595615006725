import { ReactNode } from 'react'
import { Box, CircularProgress, Stack } from '@mui/material'
import { TypographyLink } from './TextLink.styles'
import { theme } from '@theme/index'
import { Link } from './..'

const defaultProps = {
  id: undefined,
  icon: null,
  underline: 'always',
  href: undefined,
  onClick: undefined,
  loading: false,
  maxlines: undefined,
  target: undefined,
}

type TextLinkComponentProps = {
  id?: string
  text: string
  href?: string
  onClick?: () => void
  underline?: 'none' | 'hover' | 'always' | undefined
  icon?: ReactNode
  loading?: boolean
  maxlines?: number
  'data-cy'?: string
  target?: React.HTMLAttributeAnchorTarget | undefined
}

function TextLinkComponent({
  id,
  text,
  href,
  onClick,
  underline,
  icon,
  loading,
  maxlines,
  target,
  'data-cy': dataCy,
}: TextLinkComponentProps) {
  return (
    <>
      {icon ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight=".5rem"
        >
          {icon}
        </Box>
      ) : null}

      <Stack direction="row" alignItems="center" spacing={1}>
        <Link
          href={href}
          data-cy={dataCy}
          target={target}
          underline={underline}
          onClick={loading ? undefined : onClick}
          data-testid={id || 'text-link-component'}
          sx={{ opacity: loading ? 0.8 : 1 }}
          label={
            <TypographyLink
              variant="$font-text-link"
              color="$color-action-link"
              maxlines={maxlines}
            >
              {text}
            </TypographyLink>
          }
        />

        {loading && (
          <CircularProgress
            size={12}
            disableShrink
            thickness={6}
            color="inherit"
            sx={{ color: theme.palette['$color-action-placeholder'] }}
          />
        )}
      </Stack>
    </>
  )
}

TextLinkComponent.defaultProps = defaultProps

export default TextLinkComponent
