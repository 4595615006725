import './index.css'
import App from './App'
import React from 'react'
import '@fontsource/inter'
import ReactDOM from 'react-dom/client'
import 'react-date-range/dist/styles.css' // main style file
import { initMetrics } from '@utils/metrics'
import reportWebVitals from './reportWebVitals'
import 'react-date-range/dist/theme/default.css' // theme css file
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

initMetrics()

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
)

reportWebVitals()
