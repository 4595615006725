import { createContext } from 'react'

export type Event = {
  dateStart: string
  dateEnd: string | null
  description: string
  id: string
  situation: string
  type: string
  organs: {
    initials: string
  }[]
}

export type EventsPanelContextType = {
  events: Event[]
  isLoading: boolean
  handleUpdateSearch: (data: Record<string, string>) => void
}

export const EventsPanelContext = createContext<EventsPanelContextType | null>(
  null
)
