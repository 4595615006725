import { useState } from 'react'
import { getBucketName } from '@utils/buckets'
import { useContextSelector } from 'use-context-selector'
import { CardActions, CardBaseline, Container } from './styles'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Button, Chip, Input, TextWrap } from '@components/atoms'
import { SavedBucketsContext } from '@containers/SavedBucketsList/context'
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TrashCan } from '@components/icons'

type CardSavedBucketProps = {
  savedBucket: SavedBucketEntity
}
export function SavedBucketsCard({
  savedBucket,
}: CardSavedBucketProps): JSX.Element {
  const [anchorElOptions, setAnchorElOptions] = useState<
    Element | null | undefined
  >(null)

  const selecteds = useContextSelector(SavedBucketsContext, (s) => s.selecteds)

  const handleDeleteSavedBucket = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleDeleteSavedBucket
  )

  const handleToggleSelection = useContextSelector(
    SavedBucketsContext,
    (s) => s.handleToggleSelection
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElOptions(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorElOptions(null)
  }

  const handleDelete = () => {
    handleCloseMenu()
    handleDeleteSavedBucket([savedBucket.id])
  }

  return (
    <Container>
      <CardBaseline>
        <Stack sx={{ pt: 1 }}>
          <Input
            type="checkbox"
            checked={selecteds.includes(savedBucket.id)}
            onChange={() => handleToggleSelection(savedBucket)}
          />
        </Stack>
        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {savedBucket.title && (
              <Typography variant="$font-body-base" color="$color-text-primary">
                {savedBucket.title}
              </Typography>
            )}
            {savedBucket.metadata
              .filter((metadata) => !!metadata.value)
              .map((metadata) => (
                <Stack
                  key={`metadata-${metadata.label}`}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    {metadata.label}:
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {metadata.value}
                  </Typography>
                </Stack>
              ))}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Data de Publicação:
              </Typography>
              <Typography variant="$font-body-base" color="$color-text-primary">
                {formatDate(savedBucket.publishedAt)}
              </Typography>
            </Stack>
            <Chip
              size="small"
              sx={{ px: 2, py: 1 }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secondary"
                >
                  {getBucketName(savedBucket.bucket)}
                </Typography>
              }
            />
          </Stack>
          <TextWrap lines={3}>
            <Typography variant="$font-body-base" color="$color-text-secondary">
              {savedBucket.content}
            </Typography>
          </TextWrap>
        </Stack>
        <CardActions>
          <IconButton data-cy="button-favorite" onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            sx={{ mt: '45px' }}
            anchorEl={anchorElOptions}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElOptions)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleDelete}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TrashCan />
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secondary"
                >
                  Remover do Painel
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
          <Button
            text={<Typography variant="$font-body-2">Ver mais</Typography>}
            size="medium"
            icon="arrow"
            sx={{ height: '34px', m: 0 }}
            target="_blank"
            href={`/${savedBucket.bucket}/${savedBucket.originId}`}
          />
        </CardActions>
      </CardBaseline>
    </Container>
  )
}
