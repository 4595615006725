import colors from '@theme/colors'
import styled from 'styled-components'
import { CardWrapperProps } from './Card'

export const CardWrapper = styled.div`
  box-shadow: none;
  border-radius: 8px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: white;

  @media (min-width: 768px) {
    height: ${(props: CardWrapperProps) =>
      props.height ? `${props.height}px` : 'auto'};
  }

  border: 1px solid
    ${(props: CardWrapperProps) =>
      props.type === 'bordored'
        ? colors['$color-border-primary']
        : colors['$color-background-secondary']};
`
