import styled from 'styled-components'
import { Card } from '@components/atoms'

export const FeedbackCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  gap: 24px;
  padding-top: 107px;
  padding-bottom: 107px;
`

export const Media = styled.div`
  height: 140px;
  width: 242px;
  align-self: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
