import { styled, Box } from '@mui/material'

export const CardWrapper = styled(Box)(({ theme }) => ({
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.divider,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 24,
  paddingBottom: 24,
}))
