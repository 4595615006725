import remarkGfm from 'remark-gfm'
import { Container } from './styles'
import ReactMarkdown from 'react-markdown'
import { Shimmer, ShimmerText } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { OficialDiaryDetailsContext } from '../../context'
import { formatDate } from '@utils/functions/formatter/formatter'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'

function ContentTextComponent() {
  const dou = useContextSelector(OficialDiaryDetailsContext, (s) => s.dou)

  const isLoading = useContextSelector(
    OficialDiaryDetailsContext,
    (s) => s.isLoading
  )

  const title = `Diário Oficial do ${
    dou?.type === 'state' ? 'Estado' : 'Município'
  } de ${dou?.origin?.toUpperCase()}`

  return (
    <Container direction="column" spacing={3}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" gap={2} flex={1} flexWrap="wrap">
          <Box>
            <Shimmer isLoading={isLoading} height={24} width={80}>
              <Chip
                size="small"
                color="primary"
                label={title}
                variant="outlined"
                className="notPrintable"
              />
            </Shimmer>
          </Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {dou?.type === 'state' ? 'Estado' : 'Município'}:
            </Typography>
            <Shimmer isLoading={isLoading} height={18} width={80}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                {dou?.origin?.toUpperCase()}
              </Typography>
            </Shimmer>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Data de publicação:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {formatDate(dou?.date, 'Á definir')}
                </Typography>
              </Shimmer>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Livro:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {dou?.book}
                </Typography>
              </Shimmer>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Página:
              </Typography>
              <Shimmer isLoading={isLoading} height={18} width={80}>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {dou?.page}
                </Typography>
              </Shimmer>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <ShimmerText isLoading={isLoading} height={14}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {dou?.content || ''}
        </ReactMarkdown>
      </ShimmerText>
    </Container>
  )
}

export default ContentTextComponent
