import HTTPClient from '../..'
import { monitorEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'

export const saveOrganAPI = async (body: unknown) => {
  monitorEvent('Organ')
  const { data } = await HTTPClient.post('/saved_organs', body)
  return data
}

export const useSaveOrgan = () => useMutation(['save-organ'], saveOrganAPI)
