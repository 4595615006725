import { Card } from '@components/atoms'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const LoadingContainer = styled.div`
  align-self: center;
`

export const DeleteUserContainer = styled.div`
  display: flex;
  gap: 6px;
`

export const UserCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const CardInfo = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  gap: 16px;
`
